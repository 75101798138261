module.exports = function(ngModule) {
  ngModule.service('preventDirtyNav', function($rootScope, cfpLoadingBar, confirmModal, $state, $window, $log) {
    'ngInject';

    let isIdle = false;

    return function(form, body, saveCb, discardCb, options) {
      options = options || {};
      if (!form) {
        throw new Error('Unable to establish preventDirtyNav. Form undefined.');
      }

      $window.onbeforeunload = () => {
        return form.$pristine ? undefined : 'You have unsaved changes.';
      };

      const idleHandle = $rootScope.$on('IdleTimeout', () => {
        $log.warn('Idle timeout with unsaved data');
        isIdle = true;
      });

      const unsubscribeHandle = $rootScope.$on('$stateChangeStart',
        function(event, toState, toParams) {
          if (isIdle || form.$pristine) {
            unsubscribeHandle();
            idleHandle();
            $window.onbeforeunload = null;
            return;
          }

          event.preventDefault();
          cfpLoadingBar.complete();

          if (form.$invalid) {
            let promise = options.requiredForm ? confirmModal({
              title: 'Required Information Missing',
              body: 'Please enter all required information before proceeding.',
              okText: 'Ok',
              hideCancelButton: true
            }) : confirmModal({
              title: 'Fix Changes?',
              body: body + ' One or more errors prevent it from being saved.',
              okText: 'Yes, Fix Changes',
              cancelText: 'Discard Changes'
            });

            promise.catch(() => {
              unsubscribeHandle();
              idleHandle();
              $window.onbeforeunload = null;
              $state.go(toState, toParams);
            });
            return;
          }

          confirmModal({
            title: 'Save Changes?',
            body: body,
            okText: 'Save Changes',
            cancelText: 'Discard Changes'
          }).then(() => {
            return saveCb();
          }).catch(() => {
            if (discardCb) {
              return discardCb();
            }
          }).finally(() => {
            unsubscribeHandle();
            idleHandle();
            $window.onbeforeunload = null;
            $state.go(toState, toParams);
          });
        });

      return unsubscribeHandle;
    };
  });
};
