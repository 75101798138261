require('./_css');

module.exports = function(parentModuleName) {
  const moduleName = parentModuleName + '.planAnalysis',
    ngModule = angular.module(moduleName, []);

  require('./_config')(ngModule);
  require('./_components')(ngModule);

  return moduleName;
};
