class Controller {
  constructor($log, growl, $q, $state, orgPerspectives, marketplaceService, organizations, messageServices, $filter,
              marketplaceRfqStatus, marketplaceRfqQuoteStatus, requestForQuotes, $uibModal, notifications, utils,
              $timeout, confirmModal, users, moment, CF_ORGANIZATION_ID, authorization) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.$q = $q;
    this.$state = $state;
    this.orgPerspectives = orgPerspectives;
    this.marketplaceService = marketplaceService;
    this.organizations = organizations;
    this.messageServices = messageServices;
    this.$filter = $filter;
    this.requestForQuotesService = requestForQuotes;
    this.marketplaceRfqQuoteStatus = marketplaceRfqQuoteStatus;
    this.marketplaceRfqStatus = marketplaceRfqStatus;
    this.$uibModal = $uibModal;
    this.notifications = notifications;
    this.utils = utils;
    this.$timeout = $timeout;
    this.confirmModal = confirmModal;
    this.users = users;
    this.moment = moment;
    this.authorization = authorization;
    this.CF_ORGANIZATION_ID = CF_ORGANIZATION_ID;

    this.FIRST_ASSIGNMENT_THRESHOLD = this.moment().subtract(3, 'days').startOf('day');
    this.SECOND_ASSIGNMENT_THRESHOLD = this.moment().subtract(7, 'days').startOf('day');
    this.searchText = '';
    this.searching = false;

    this.rfqFilterTypes = {
      OPEN: 'Active RFQs',
      CLOSED: 'Inactive RFQs',
      ALL: 'All RFQs'
    };

    this.rfqOpenStatuses = {
      SUBMITTED: 1,
      ACCEPTED: 2,
      QUOTED: 3
    };

    this.EXPAND_TOUR_ID = 'rfqExpand';
  }

  $onInit() {
    // Marketplace consumers are on the requester side of the RFQ.
    // Providers are the subject org - the org requested for a response.
    this.isRequester = this.marketplaceService.isConsumer(this.user, this.organization);
    this.currentFilterType = this.rfqFilterTypes.ALL;
    //this.expandMiniTourShown = this.miniTourService.wasShown(this.user ,this.EXPAND_TOUR_ID);

    // Fill the mini-profile for the organizations on the other side of the transaction.
    this.orgProfiles = {};
    this.orgClaims = {};

    this._search(this.organization, this.isRequester).then(rfqs => this.noRfqs = _.isEmpty(rfqs));
    this.search = _.debounce(this._search, 300);
  }

  _search(organization, isRequester, searchText, from, size) {
    let rfqs = [];
    let additionalFilters = {};
    let orgId = this.user.isCfAdmin() && organization.$id === this.CF_ORGANIZATION_ID ? null : organization.$id;

    if (this.currentFilterType === this.rfqFilterTypes.OPEN) { additionalFilters.isOpen = true; }
    if (this.currentFilterType === this.rfqFilterTypes.CLOSED) { additionalFilters.isClosed = true; }

    this.searching = true;
    return this.requestForQuotesService.get(orgId, isRequester, searchText, from, size,
      _.isEmpty(additionalFilters) ? null : additionalFilters)
      .then((result) => {
        rfqs = isRequester ? _.filter(_.get(result, 'data', []), (rfq) => rfq.hidden !== true) :
          _.get(result, 'data', []);

        // fill the mini-profile for the organizations on the other side of the transaction (unless CF Admin).
        _.each(rfqs, (rfq) => {
          if (!this.orgProfiles[rfq.subjectOrgId] && this.isRequester || this.user.isCfAdmin() &&
            organization.$id === this.CF_ORGANIZATION_ID) {
            this.organizations.getMiniProfile(rfq.subjectOrgId)
              .then(profile => this.orgProfiles[profile.id] = profile);
          }

          if (!this.orgProfiles[rfq.requesterOrgId] && !this.isRequester || this.user.isCfAdmin() &&
            organization.$id === this.CF_ORGANIZATION_ID) {
            this.organizations.getMiniProfile(rfq.requesterOrgId)
              .then(profile => this.orgProfiles[profile.id] = profile);
          }

          if (_.get(rfq, 'assignment.rejectedByOrgId') && !this.orgProfiles[rfq.assignment.rejectedByOrgId] &&
            organization.$id === this.CF_ORGANIZATION_ID) {
            this.organizations.getMiniProfile(rfq.assignment.rejectedByOrgId)
              .then(profile => this.orgProfiles[profile.id] = profile);
          }

          if (!this.orgClaims[rfq.requesterOrgId] && !this.isRequester || !this.user.isCfAdmin()) {
            this.authorization.getClaims(rfq.requesterOrgId, this.user.getRole(rfq.requesterOrgId))
              .then((result) => this.orgClaims[rfq.requesterOrgId] = result.claims);
          }
        });

        _.each(rfqs, (rfq, i) => {
          // get the mini profiles for the provider organizations
          _.each(rfq.quotes, (q) => this.orgProfiles[q.providerOrgId] ||
            this.organizations.getMiniProfile(q.providerOrgId)
              .then(profile => this.orgProfiles[profile.id] = profile));

          let collapse = i !== 0 || _.isEmpty(rfq.quotes);

          _.assign(rfq,
            {
              isCollapsed: collapse,  // Expand the first rfq if quotes are present.
              trStyle: collapse ? {display: 'none'} : '',
              createdOn: new Date(rfq.createdOn)
            });
        });

        this.requestForQuotes = rfqs;
        this.anyQuotes = _.some(this.requestForQuotes, (rfq) => !_.isEmpty(rfq.quotes));
/*
        if (this.anyQuotes && !this.expandMiniTourShown) {
          this.expandMiniTourShown = true;
          this.$timeout(() => {
            this.miniTourService.enqueueTour(this.user, {
              id: this.EXPAND_TOUR_ID,
              selector: '.orders .expand-col-tour',
              title: 'You Received Some Quotes!',
              contentHtml: 'View submitted quotes by clicking the <i class="far fa-plus-square"></i>.'
            });
          }, 600);
        }*/

        return this.requestForQuotes;
      })
      .finally(() => this.searching = false);
  }

  searchKeyPress($event) {
    if (this.utils.isBenignKeyUp($event.keyCode)) { return; }

    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) {
        this.search(this.organization, this.isRequester, this.searchText.toLowerCase());
      }

      return;
    }

    if (this.searchText && this.searchText.length <= 3) { return; }

    this.search(this.organization, this.isRequester, this.searchText.toLowerCase());
  }

  getOpenStatus(rfq) {
    if (!rfq.quotes || _.isEmpty(rfq.quotes)) {
      return this.rfqOpenStatuses.SUBMITTED;
    }

    if (_.some(rfq.quotes, q => q.status === this.marketplaceRfqQuoteStatus.ACCEPTED)) {
      return this.rfqOpenStatuses.ACCEPTED;
    }

    return this.rfqOpenStatuses.QUOTED;
  }

  changeFilter(filterType) {
    this.currentFilterType = filterType;
    this._search(this.organization, this.isRequester, this.searchText.toLowerCase());
  }

  cancelRfq(rfq) {
    this.requestForQuotesService.setStatus(this.user, rfq.$id, this.marketplaceRfqStatus.CANCELLED)
      .then(() => {
        this.$timeout(() => rfq.status = this.marketplaceRfqStatus.CANCELLED);
      })
      .catch(err => {
        this.$log.error('An error occurred cancelling the RFQ', err);
        this.growl.error('Unable to cancelling the RFQ. Please contact customer support.');
      });
  }

  declineRfq(rfq) {
    this.requestForQuotesService.setStatus(this.user, rfq.$id, this.marketplaceRfqStatus.DECLINED)
      .then(() => {
        this.$timeout(() => rfq.status = this.marketplaceRfqStatus.DECLINED);
        this.notifications.postToOrg({
          from: this.user.uid,
          to: rfq.requesterOrgId,
          message: 'Your RFQ was declined. Service: <i>"' + rfq.service.title + '"</i>',
          link: {
            state: 'marketplace.requestForQuote.list'
          }
        });
      })
      .catch(err => {
        this.$log.error('An error occurred declining the RFQ', err);
        this.growl.error('Unable to declining the RFQ. Please contact customer support.');
      });
  }

  suspendRfq(rfq) {
    this.requestForQuotesService.setStatus(this.user, rfq.$id, this.marketplaceRfqStatus.SUSPEND)
      .then(() => {
        this.$timeout(() => rfq.status = this.marketplaceRfqStatus.SUSPEND);
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred suspending the RFQ'));
  }

  resumeRfq(rfq) {
    this.requestForQuotesService.setStatus(this.user, rfq.$id, this.marketplaceRfqStatus.OPEN)
      .then(() => {
        this.$timeout(() => rfq.status = this.marketplaceRfqStatus.OPEN);
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred resuming the RFQ'));
  }

  assign(rfq) {
    this.$uibModal.open({
      component: 'cfMarketplaceAssignRfq',
      resolve: {
        user: () => this.user,
        rfq: () => rfq
      }
    }).result
      .then((result) => {
        rfq.subjectOrgId = result.subjectOrgId;
        rfq.assignment = result.assignment;

        this.notifications.postToOrg({
          from: this.user.uid,
          to: rfq.subjectOrgId,
          message: 'A request for quote has been assigned to you for service <i>"' + rfq.service.title + '"</i>',
          link: {state: 'marketplace.requestForQuote.list'}
        });
      })
      .then(() => this.growl.success('RFQ assigned successfully.'))
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.growl.error('An error occurred assigning the RFQ.');
        this.$log.error('An error occurred assigning an RFQ.', this.$log.toString(reason));
      });
  }

  unAssign(rfq) {
    this.confirmModal({
      title: 'Remove RFQ Assignment?',
      body: 'Are you sure you want to remove <strong>' +
        _.get(this.orgProfiles, [rfq.subjectOrgId] + '.name', 'the provider') + '</strong> from this RFQ? ' +
        (!_.isEmpty(rfq.quotes) ? '<br/><br/><strong>Note: One or more quotes have been submitted.</strong>' : ''),
      okText: 'Yes',
      cancelText: 'No'
    })
      .then(() => this.requestForQuotesService.unAssign(rfq.$id))
      .then(() => {
        delete rfq.assignment;
        rfq.subjectOrgId = this.CF_ORGANIZATION_ID;

        this.growl.success('RFQ un-assigned successfully.');
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.growl.error('An error occurred assigning the RFQ.');
        this.$log.error('An error occurred assigning an RFQ.', this.$log.toString(reason));
      });
  }

  submitQuote(rfq) {
    this.$uibModal.open({
      component: 'cfMarketplaceSubmitQuote',
      resolve: {
        user: () => this.user,
        requestForQuote: () => rfq,
        quote: () => this.requestForQuotesService.$pushQuote(this.user, rfq)
      }
    }).result.then($newQuote => {
      rfq.quotes = rfq.quotes || [];
      rfq.quotes.push(_.assign(_.cloneDeep($newQuote), {id: $newQuote.$id}));
      $newQuote.$destroy();

      this.notifications.postToOrg({
        from: this.user.uid,
        to: rfq.requesterOrgId,
        message: 'A quote has been submitted for service <i>"' + rfq.service.title + '"</i>',
        link: {
          state: 'marketplace.requestForQuote.list'
        }
      });

      return rfq.hidden ? this.requestForQuotesService.setHidden(rfq.$id, false) : this.$q.resolve();
    })
    .then(() => this.growl.success('Quote submitted.'))
    .catch((reason) => {
      if (this.utils.isModalDismissalByUser(reason)) { return; }

      this.growl.error('An error occurred submitting the quote.');
      this.$log.error('An error occurred submitting the quote.', reason);
    });
  }

  acceptQuote(rfq, quote) {
    if (this.user.isCfAdmin()) {
      return this.acceptQuoteForUser(rfq, quote);
    }

    this.confirmModal({
      title: 'Add to Cart?',
      body: 'Are you sure you want to accept this quote and add an order to your shopping cart? ' +
        'All other quotes will be declined.',
      okText: 'Yes, Add to Cart',
      cancelText: 'Cancel'
    }).then(() =>
      this.requestForQuotesService
        .acceptQuote(this.user, rfq, _.find(rfq.quotes, q => q.id === quote.id))
        .then(() => {
          this.$log.info('A RFQ was accepted.', {id: rfq.$id}, {notify: true, type: 'marketplace'});

          rfq.status = this.marketplaceRfqStatus.CLOSED;
          quote.status = this.marketplaceRfqQuoteStatus.ACCEPTED;

          return this.$q.all(_.map(rfq.quotes, q => {
            if (q.id === quote.id) { return; }
            return this.declineQuote(rfq, q);
          }));
        })
        .then(() => this.$state.go('shoppingCart.review'))
      )
      .catch(reason => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.growl.error('An error occurred accepting the quote.');
        this.$log.error('An error occurred accepting the quote.', this.$log.toString(reason));
      });
  }

  acceptQuoteForUser(rfq, quote) {
    this.$uibModal.open({
      component: 'cfSelectRfqUser',
      resolve: {
        user: () => this.user,
        organizationId: () => rfq.requesterOrgId
      }
    }).result
      .then(user =>
        this.requestForQuotesService
          .acceptQuote(user, rfq, _.find(rfq.quotes, q => q.id === quote.id))
          .then(() => {
            this.$log.info('A RFQ was accepted.', {id: rfq.$id}, {notify: true, type: 'marketplace'});

            rfq.status = this.marketplaceRfqStatus.CLOSED;
            quote.status = this.marketplaceRfqQuoteStatus.ACCEPTED;

            return this.$q.all(_.map(rfq.quotes, q => {
              if (q.id === quote.id) { return; }
              return this.declineQuote(rfq, q);
            }));
          })
          .then(() => this.growl.success('Quote successfully accepted. Please have the user check their ' +
            'shopping cart to initiate the service order.'))
      )
      .catch(reason => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.growl.error('An error occurred accepting the quote.');
        this.$log.error('An error occurred accepting the quote.', this.$log.toString(reason));
      });
  }

  declineQuote(rfq, quote) {
    return this.requestForQuotesService
      .setQuoteStatus(this.user, rfq.$id, quote.id, this.marketplaceRfqQuoteStatus.DECLINED)
      .then(() => {
        this.$timeout(() => quote.status = this.marketplaceRfqQuoteStatus.DECLINED);

        this.notifications.postToOrg({
          from: this.user.uid,
          to: rfq.subjectOrgId,
          message: 'Your quote has been declined.',
          link: {
            state: 'marketplace.requestForQuote.list'
          }
        });
      })
      .catch(err => {
        this.$log.error('An error occurred declining a quote', err);
        this.growl.error('Unable to decline the quote. Please contact customer support.');
      });
  }

  cancelQuote(rfq, quote) {
    this.requestForQuotesService.setQuoteStatus(this.user, rfq.$id, quote.id, this.marketplaceRfqQuoteStatus.CANCELLED)
      .then(() => {
        this.$timeout(() => {
          quote.status = this.marketplaceRfqQuoteStatus.CANCELLED;
        });
      })
      .catch(err => {
        this.$log.error('An error occurred cancelling a quote', err);
        this.growl.error('Unable to cancel the quote. Please contact customer support.');
      });
  }

  truncate(text, length) {
    return _.truncate(text, {'length': length});
  }

  view(rfq) {
    this.user.setOrgContext(rfq.requesterOrgId, {
      noPersist: true,
      perspective: this.orgPerspectives.PROVIDER_ANALYST
    }).then(() => {
      if (rfq.product) {
        this.$state.go('products.edit.planAnalysis.questionnaire', {productId: rfq.product.key});
      } else {
        this.$state.go('checklists.gmps.edit.category');
      }
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to view client.'));
  }

  clientInfoModal(rfq) {
    if (!(this.user.isCfAdmin() || rfq.createdBy &&
      this.orgClaims[rfq.requesterOrgId][this.authorization.claims.USER_PROFILES_READ])) {
      return false;
    }

    this.$uibModal.open({
      component: 'cfMarketplaceClientInfoModal',
      size: 'lg',
      resolve: {
        user: () => this.user,
        requesterOrgId: () => rfq.requesterOrgId,
        clientUserProfile: () => this.users.getClientProfile(rfq.createdBy),
        onboardingQuestionnaire: () => this.organizations.getOnboardingQuestionnaire(rfq.requesterOrgId)
      }
    });
  }

  viewFiles(rfq) {
    if (!this.user.isCfAdmin() && !this.orgClaims[rfq.requesterOrgId][this.authorization.claims.FILES_READ]) {
      return false;
    }

    this.$state.go('marketplace.requestForQuote.files', {
      orgId: rfq.requesterOrgId,
      productId: rfq.product ? rfq.product.key : undefined,
      returnState: this.$state.current.name,
      marketplacePocUid: rfq.createdBy
    });
  }

  getAssignedIconClass(rfq) {
    return _.get(rfq, 'assignment.rejectedOn') ? 'far fa-times-square' : 'far fa-check-square';
  }

  getAssignedTextClass(rfq) {
    if (_.get(rfq, 'assignment.rejectedOn')) {
      return 'text-danger';
    }

    if (!_.isEmpty(rfq.quotes) && (this.organization.$id === this.CF_ORGANIZATION_ID ||
      _.find(rfq.quotes, {providerOrgId: this.organization.$id}))) {
      return '';
    }

    let mDate = this.moment(_.get(rfq, 'assignment.assignedOn'));

    if (mDate.isBefore(this.SECOND_ASSIGNMENT_THRESHOLD)) {
      return 'text-danger';
    } else if (mDate.isBefore(this.FIRST_ASSIGNMENT_THRESHOLD)) {
      return 'text-warning';
    } else {
      return 'text-success';
    }
  }

  getAssignedMarkup(rfq) {
    let dateClass = this.getAssignedTextClass(rfq),
      rejectedOn = _.get(rfq, 'assignment.rejectedOn');

    if (rejectedOn) {
      let rejectedByOrgId = _.get(rfq, 'assignment.rejectedByOrgId'),
        rejectedReason = _.get(rfq, 'assignment.rejectedReason');

      return '<i class="far fa-user-times fa-fw g-mr-3 ' + dateClass + '"></i><span class="' + dateClass + '">' +
        (!this.orgProfiles[rejectedByOrgId] ? '...' : this.orgProfiles[rejectedByOrgId].name) + '</span>' +
        '<br/><i class="far fa-clock fa-fw g-mr-3"></i>' + this.moment(rejectedOn).format('ll') +
        '<br/><i class="far fa-comment-alt fa-fw g-mr-3"></i>' + rejectedReason;
    }

    return '<i class="far fa-user-check fa-fw g-mr-3"></i>' + (!this.orgProfiles[rfq.subjectOrgId] ? '...' :
      this.orgProfiles[rfq.subjectOrgId].name) + '<br/><i class="far fa-clock fa-fw g-mr-3 ' + dateClass + '"></i>' +
      '<span class="' + dateClass + '">' + this.moment(_.get(rfq, 'assignment.assignedOn')).format('ll') + '</span>';
  }

  canRejectAssignment(rfq) {
    return this.organization.$id !== this.CF_ORGANIZATION_ID && (_.isEmpty(rfq.quotes) ||
      !_.find(rfq.quotes, {providerOrgId: this.organization.$id}));
  }

  rejectAssignment(rfq) {
    this.$uibModal.open({
      component: 'cfRejectRfqAssignment',
      resolve: {
        user: () => this.user,
        rfq: () => rfq
      }
    }).result
      .then(() => {
        _.remove(this.requestForQuotes, (existingRfq) => existingRfq.$id === rfq.$id);

        this.notifications.postToOrg({
          from: this.user.uid,
          to: this.CF_ORGANIZATION_ID,
          message: 'A request for quote has been <span class="text-danger">rejected</span> for service <i>"' +
            rfq.service.title + '"</i>',
          link: {state: 'marketplace.requestForQuote.list'}
        });
      })
      .then(() => this.growl.success('RFQ rejected.'))
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.growl.error('An error occurred rejecting an RFQ.');
        this.$log.error('An error occurred rejecting an RFQ.', this.$log.toString(reason));
      });
  }
}

module.exports = Controller;
