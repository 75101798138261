class Constructor {
  constructor(productSearch, products) {
    'ngInject';

    this.productSearchService = productSearch;
    this.products = products;
  }

  $onInit() {
    this.onPremiumPlan = !this.user.onPayAsGoPlan();
    this.productSearch = new this.productSearchService.ProductSearch(this.user, [this.user.orgContext.id]);
    this.productSearch.searchFields(['brandName']);
    this.productSearch.search().then(results => {
      this.selectedProduct = _.first(results);
      if (results && results.length) {
        this.selectProduct(this.selectedProduct);
      }
      return results;
    });
  }

  selectProduct(searchProduct) {
    this.products.$get(searchProduct.$id).then($product => {
      this.$product = $product;
    });
  }
}

module.exports = Constructor;