require('./users.settings.account.scss');

module.exports = function(ngModule) {
  ngModule.component('cfAccount', {
    bindings: {
      user: '<'
    },
    template: require('./users.settings.account.template.html'),
    controller: require('./users.settings.account.controller.js'),
    controllerAs: 'vm'
  });
};
