class Constructor {
  constructor(drift, authentication) {
    'ngInject';
    this.drift = drift;
    this.authentication = authentication;
  }

  $onInit() {
    this.step = 'contactInfo';
    this.cancelModal = this.resolve.cancelModal;
  }

  chat() {
    this.drift.startInteraction(362394);
  }
  logOut() {
    this.modalInstance.dismiss('cancel');
    this.authentication.logout();
  }
}

module.exports = Constructor;
