'use strict';

module.exports = function($log, $timeout, preventDirtyNav, growl, utils, $uibModal, $q,
                          supplierService, organizations, fbutil, $state) {
  'ngInject';

  let vm = this;

  vm.$onInit = function() {
    $timeout(() => {
      preventDirtyNav(vm.supplierOverviewForm, 'You have unsaved overview changes.', vm.save);
    });
    vm.newRec = vm.supplier.$value === null;
  };

  vm.save = function() {
    vm.saving = true;
    supplierService.saveSupplier(vm.user, vm.supplier, vm.company)
      .then(() => {
        if (vm.newRec) {
          // Update the supplier list view
          vm.supplierSearch.searchResults = vm.supplierSearch.searchResults || [];
          vm.supplierSearch.searchResults.push(vm.supplier);
          vm.supplierSearch.total++;
        }
        vm.newRec = false;
      })
      .then(() => {
        if (vm.supplierOverviewForm) {
          vm.supplierOverviewForm.$setPristine();
        }
        $state.go($state.current.name, {supplierId: vm.supplier.$id}, {reload: false});
        growl.success('Supplier general information saved successfully!');
      })
      .catch((err) => {
        $log.error(err);
        growl.error('Unable to save supplier general information. Please try ' +
          'again or contact FoodReady customer support.');
      })
      .finally(() => { vm.saving = false; });
  };

  vm.cancel = function() {
    if (vm.supplierOverviewForm) {
      vm.supplierOverviewForm.$setPristine();
    }

    vm.close();
  };

  vm.close = function() {
    $state.go('ingredients.suppliers.list');
  };
};
