'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfIngredientsMain', {
    bindings: {
      ingredientSearch: '<',
      user: '<'
    },
    template: require('./ingredients.main.template.html'),
    controller: require('./ingredients.main.controller'),
    controllerAs: 'vm'
  });
};
