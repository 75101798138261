require('./_css');

module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.sops',
    ngModule = angular.module(moduleName, [
    ]);

  require('./_components')(ngModule);
  require('./_services')(ngModule);
  require('./_config')(ngModule);

  return moduleName;
};
