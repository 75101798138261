class Controller {
  constructor(confirmDeleteModal, growl, $state, recallEventsService, utils, $uibModal) {
    'ngInject';

    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.$state = $state;
    this.recallEventsService = recallEventsService;
    this.utils = utils;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.customerTypesMap = _.keyBy(this.customerTypes, 'value');
  }

  remove(fbArray, entry, name) {
    this.confirmDeleteModal(name).then(() => {
      if (entry) {
        fbArray.$remove(entry).then(() => {
          this.growl.success('Customer removed successfully.');
        }).catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete consignee'));
      }
    });
  }

  editImpact(customer) {
    this.$uibModal.open({
      component: 'cfRecallEventsConsigneeImpactEdit',
      resolve: {
        $consigneeImpact: () => this.recallEventsService.$getConsigneeImpact(this.$event, customer.$id),
        customer: () => customer
      }
    }).result.then(() => {
      this.growl.success('Consignee impact updated');
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to update consignee impact.'));
  }

  boolToText(bool) {
    if (bool === true) {
      return 'Yes';
    } else if (bool === false) {
      return 'No';
    } else {
      return '';
    }
  }

  newCustomer() {
    this.$state.go('ingredients.customers.customer.overview', {productCustomersId: 'new'})
      .catch(() => this.growl.error('Unable to create new Customer'));
  }

  save() {
    this.$event.$save()
      .then(() => this.growl.success('Customer added'))
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to add Customer'));
  }
}

module.exports = Controller;
