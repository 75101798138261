'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfOrgUploadFiles', {
    bindings: {
      files: '=',
      organization: '<',
      user: '<'
    },
    template: require('./company.upload-files.template.html'),
    controller: require('./company.upload-files.controller'),
    controllerAs: 'vm'
  });
};