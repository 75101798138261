'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfMediaManager', {
    bindings: {
      organization: '<',
      newFiles: '=',
      removeFiles: '=',
      existingFiles: '=',
      header: '@',
      buttonText: '@',
      onUpdate: '&?'
    },
    template: require('./media-manager.template.html'),
    controller: require('./media-manager.controller'),
    controllerAs: 'vm'
  });
};
