'use strict';

module.exports = function(ngModule) {
  ngModule.component('frBatchLogsReportModal', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./reports.batch-logs-modal.template.html'),
    controller: require('./reports.batch-logs-modal.controller.js'),
    controllerAs: 'vm'
  });
};
