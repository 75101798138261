class Constructor {
  constructor(utils, $q, $scope) {
    'ngInject';

    this.utils = utils;
    this.$q = $q;
    this.$scope = $scope;
  }

  $onInit() {
    this.header = this.resolve.header;
  }

  submit(val) {
    this.modalInstance.close(val);
  }
}

module.exports = Constructor;
