module.exports = function(ngModule) {
  ngModule.component('cfSopLogsList', {
    bindings: {
      user: '<',
      sopLogs: '<'
    },
    template: require('./operations.sop-logs.list.template.html'),
    controller: require('./operations.sop-logs.list.controller'),
    controllerAs: 'vm'
  });
};