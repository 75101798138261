class Controller {
  constructor(preventDirtyNav, $timeout, $q, S3, recallEventsService, growl, $log) {
    'ngInject';

    this.preventDirtyNav = preventDirtyNav;
    this.$timeout = $timeout;
    this.$q = $q;
    this.S3 = S3;
    this.recallEventsService = recallEventsService;
    this.growl = growl;
    this.$log = $log;
  }

  $onInit() {
    this.$timeout(() => {
      if (this.$event.$value === null) {
        this.recallReasonForm.$setDirty();
      }

      this.preventDirtyNav(this.recallReasonForm, 'You have unsaved recall reason changes.',
        () => this.save());
    });

    this.uploadProgress = 0;
    this.newRecallReasonFiles = [];
    this.removeRecallReasonFiles = [];
    this.existingRecallReasonFiles = this.recallEventsService.$recallReasonFiles(this.$event);
    this.isCfAdmin = this.user.isCfAdmin();
  }

  save() {
    if (this.$event.$value === null) {
      this.$event.createdOn = firebase.database.ServerValue.TIMESTAMP;
      this.$event.createdBy = this.user.uid;
    } else {
      this.$event.updatedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$event.updatedBy = this.user.uid;
    }

    this.$event.$save()
      .then(() => {
        let filePromises = [];

        this.uploadProgress = 0;
        this.uploadProgressObj = {};
        _.each(this.newRecallReasonFiles, (file, idx) => {
          filePromises.push(
            this.S3.uploadOrgFile(this.company.$id, `/recallReason/${file.name}`, file,
              (event) => {
                this.uploadProgressObj[idx] = parseInt(100.0 * event.loaded / event.total, 10);
                this._refreshUploadProgress();
              }).then((result) => this.existingRecallReasonFiles.$add(result))
          );
        });

        _.each(this.removeRecallReasonFiles, (file) => {
          filePromises.push(
            this.S3.deleteFile(this.company.$id, file.key).then(() => {
              return this.existingRecallReasonFiles.$remove(file);
            })
          );
        });

        return this.$q.all(filePromises);
      })
      .then(() => {
        this.removeRecallReasonFiles = [];
        this.newRecallReasonFiles = [];
        this.uploadProgress = 0;
        if (this.recallReasonForm) {
          this.recallReasonForm.$setPristine();
        }

        this.growl.success('Recall reason saved.');
        this.$log.info('Recall reason saved.');
      });
  }

  _refreshUploadProgress() {
    if (!this.newRecallReasonFiles.length) {
      return 0;
    }

    let progress = _.reduce(this.uploadProgressObj, (sum, n) => {
      return sum + n;
    }, 0);

    this.uploadProgress = _.round(progress / this.newRecallReasonFiles.length);
  }

}

module.exports = Controller;
