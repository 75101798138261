module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.facility', {
        url: '/facility',
        template: '<cf-facility user="$resolve.user" company="$resolve.company" close-btn="true"></cf-facility>',
        resolve: {
          company: (user, organizations) => organizations.$get(user.currentOrgContext().id),
        }
      });
  });
};
