'use strict';

module.exports = function (parentModuleName) {
  var moduleName = parentModuleName + '.email',
    ngModule = angular.module(moduleName, []);

  require('./_services')(ngModule);

  return moduleName;
};
