'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsVolume', {
    bindings: {
      $event: '=',
      user: '<'
    },
    template: require('./recall.events.volume.template.html'),
    controller: require('./recall.events.volume.controller'),
    controllerAs: 'vm'
  });
};
