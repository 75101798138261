class Controller {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.user = this.resolve.user;
    this.category = this.resolve.category;
    this.now = moment().format('MMMM Do YYYY, h:mm:ss a');
  }

  ok() {
    this.modalInstance.close();
  }
}

module.exports = Controller;
