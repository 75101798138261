module.exports = function(ngModule) {
  ngModule.factory('supplierIngredientService', function(fbutil, $q, accessRequests, authorization, $firebaseArray,
                                                         suppliers, organizations, $log, S3, orgInteractionService,
                                                         $http) {
    'ngInject';

    return {
      getAll: (ingredientId) => {
        return fbutil.ref('ingredients', ingredientId, 'supplierIngredients').once('value')
          .then(supplierIngredients => {
            return $q.all(_.mapValues(supplierIngredients.val(), (val, key) => {
              return fbutil.ref('supplierIngredients', key).once('value').then(snap => {
                let supplierIngredient = snap.exists() && snap.val();

                return fbutil.ref('suppliers', supplierIngredient.supplierId, 'name').once('value')
                  .then(supplierName => {
                    supplierIngredient.supplierName = supplierName.val();
                    return supplierIngredient;
                  });
              });
            }));
          });
      },

      getLots: (supplierIngredientId) => {
        return fbutil.ref('supplierIngredients', supplierIngredientId).child('lots').once('value')
          .then((snap) => {
            let lotsVal = fbutil.getValueOrDefault(snap);
            if (!lotsVal) { return null; }

            let resultLots = _.mapValues(lotsVal, (lot, id) => {
              lot.$id = id;
              return lot;
            });

            return Object.values(resultLots);
          });
      },

      getTransactions: (supplierIngredientId) => {
        return fbutil.ref('supplierIngredients', supplierIngredientId).child('transactions').once('value')
          .then((snap) => {
            let transactionsVal = fbutil.getValueOrDefault(snap);
            if (!transactionsVal) { return null; }

            let lotTransactionsMap = {};

            for (const [key, value] of Object.entries(transactionsVal)) {
              value.$id = key;

              lotTransactionsMap[value.lotRefId] = lotTransactionsMap[value.lotRefId] ? lotTransactionsMap[value.lotRefId] : [];
              lotTransactionsMap[value.lotRefId].push(value);
            }

            return lotTransactionsMap;
          });
      },

      get: (supplierIngredientId) => {
        return fbutil.ref('supplierIngredients', supplierIngredientId).once('value').then(fbutil.getValueOrDefault);
      },

      remove: (user, supplierIngredientId) => {
        let supplierIngredient;

        return fbutil.ref('supplierIngredients', supplierIngredientId).once('value')
          .then(supplierIngredientSnap => {
            if (!supplierIngredientSnap.exists()) {
              return $q.reject('SupplierIngredient not found: ' + supplierIngredientId);
            }
            supplierIngredient = supplierIngredientSnap.val();

            return $q.all([
              fbutil.ref('ingredients', supplierIngredient.ingredientId, 'supplierIngredients', supplierIngredientId)
                .remove(),
              fbutil.ref('suppliers', supplierIngredient.supplierId, 'ingredients', supplierIngredientId).remove()
            ]);
          })
          .then(() => {
            return $q.all(_.map(supplierIngredient.files, (file) => {
              return S3.deleteFile(supplierIngredient.organizationId, file.key);
            })).catch($log.error);
          })
          .then(() => {
            return $q.when([
              fbutil.ref('supplierIngredients', supplierIngredientId, 'deleted').set(new Date().getTime()),
              fbutil.ref('supplierIngredients', supplierIngredientId, 'deletedOn').set(new Date().getTime()),
            ]);
          })
          .then(() => {
            if (supplierIngredient.accessRequestId) {
              return orgInteractionService.cancelAccessRequest(user, supplierIngredient.accessRequestId)
                .catch(err => {
                  $log.error('Unable to cancel access request', {id: supplierIngredient.accessRequestId, error: err});
                });
            }
          });
      },

      getFiles: function(orgId, supplierId, supplierIngredientId, excludeAssociatedProductFiles) {
        return $http.get(`/organizations/${orgId}/suppliers/${supplierId}/ingredients/${supplierIngredientId}/files${excludeAssociatedProductFiles ? '?excludeAssociatedProductFiles=true' : ''}`)
          .then(response => response.data.files);
      },


      add: function(supplierIngredient, supplierId, ingredientId) {
        return $q.all([
          fbutil.ref('ingredients', ingredientId, 'supplierIngredients', supplierIngredient.$id).set(true),
          fbutil.ref('suppliers', supplierId, 'ingredients', supplierIngredient.$id).set(true)
        ]);
      },

      /**
       * Create an association between a supplierIngredient and a product.
       * @param {string} supplierIngredientId Supplier Ingredient Id.
       * @param {string} productOrgId Product's org Id
       * @param {string} productId  Product Id
       * @returns {Promise} A promise that resolves when the association is created.
       */
      createAssociation(supplierIngredientId, productOrgId, productId) {
        return $q.all([
          fbutil.ref(`supplierIngredients/${supplierIngredientId}/productId`).set(productId),
          fbutil.ref(`supplierIngredients/${supplierIngredientId}/productOrganizationId`).set(productOrgId)
        ]);
      },

      /**
       * Link to a given product by requesting an organization-level access exception request for the given product.
       * Also send a notification with a token that allows click-approval.
       * @param {object} user  User requesting access.
       * @param {object} $supplierIngredient  Supplier Ingredient object that includes the product to be linked.
       * @returns {Promise} A promise that resolves once access is requested.
       */
      linkToProduct: function(user, $supplierIngredient) {
        return authorization.hasOrgLevelProductException(user, $supplierIngredient.productOrganizationId,
          $supplierIngredient.productId, authorization.claims.REPORTS_READ)
          .then(exceptionRecExists => {
            if (exceptionRecExists) { return; }
            const notificationSettings = {
              template: 'supplier_product_access',
              message: `<b>${user.currentOrgContext().companyName}</b> requested read-only access for your <i>${$supplierIngredient.brandName}</i> documents and reports.`,
              link: {
                state: 'ingredients.ingredient.supplierIngredient.supplierFiles',
                params: {
                  supplierIngredientId: $supplierIngredient.$id,
                  ingredientId: $supplierIngredient.ingredientId
                }
              },
              msgSubject: $supplierIngredient.brandName
            };

            return orgInteractionService.requestIngredientAccess(user, $supplierIngredient,
              [authorization.claims.REPORTS_READ, authorization.claims.FILES_READ], notificationSettings)
              .then(accessRequestId => {
                $supplierIngredient.accessRequestId = accessRequestId;
                return $supplierIngredient.$save();
              });
          });
      },

      stopReminders(supIngId) {
        return fbutil.ref(`supplierIngredients/${supIngId}/supplierTrackingOptOut`).set(true);
      },

      /**
       * Users will be presented with the option to associate a supplierIngredient to a product. Once that association
       * decision is made, don't redisplay it.
       * @param {string} supIngId The supplierIngredient ID to mark.
       * @returns {Promise} A promise that is resolved when the supIng is marked appropriately.
       */
      setAssociationDecisionMade(supIngId) {
        return fbutil.ref(`supplierIngredients/${supIngId}/associationDecisionMade`).set(true);
      },

      getAllSupplierIngredients(orgId) {
        return $http.get(`/supplierIngredients/organization/${orgId}`).then(result => _.map(result.data, v => v.supplierIngredients).flat(1));
      }
    };
  });
};
