module.exports = function(ngModule) {
  ngModule.component('cfReassignProvider', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./marketplace.reassign-provider.template.html'),
    controller: require('./marketplace.reassign-provider.controller'),
    controllerAs: 'vm'
  });
};