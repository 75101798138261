module.exports = function(ngModule) {
  ngModule.component('cfSavePlanAsTemplateModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./products.save-as-template-modal.template.html'),
    controller: require('./products.save-as-template-modal.controller'),
    controllerAs: 'vm'
  });
};
