class Constructor {
  constructor(utils, $q, $scope, constantsService) {
    'ngInject';

    this.utils = utils;
    this.$q = $q;
    this.$scope = $scope;
    this.steps = {
      PICK_TYPE: 1,
      VIEW_ITEM: 2
    };
    this.logSheetsPromise = constantsService.get('logSheets').then(sheets => {
      this.logSheetNames = _.reduce(
        sheets,
        (map, {name}, id) => {
          map[id] = name;
          return map;
        },
        {}
      );
      return sheets;
    });
  }

  $onInit() {
    this.header = this.resolve.header;
    this.step = this.steps.PICK_TYPE;
    this.itemsArray = this.resolve.itemsArray;
    this.searchObject = this.resolve.searchObject;
    this.itemName = this.resolve.itemName;
    this.instructionsHtml = this.resolve.instructionsHtml;
    this.htmlColumns = this.resolve.htmlColumns;
    this.columns = this.resolve.columns;
    this.multiple = this.resolve.multiple;
    this.noneFoundHtml = this.resolve.noneFoundHtml || `No ${this.itemName}s found.`;
    this.clonedItemsArray = _.compact(_.cloneDeep(this.itemsArray));
    this.url = null;

    // If searchObject was sent, this will be an async search.
    if (this.searchObject) {
      if (this.itemsArray) {
        throw new Error('Invalid arguments to chooseFromList component.');
      }

      this.search = _.debounce(this._search, 300);
      this._search();
    }
  }

  submit() {
    if (this.multiple) {
      this.modalInstance.close(_.filter(this.clonedItemsArray, (val, index) => this.clonedItemsArray[index].checked));
    } else {
      this.modalInstance.close(this.selectedItem);
    }
  }

  rowClicked(item) {
    if (this.multiple) {
      item.checked = !item.checked;
    } else {
      this.selectedItem = item;
    }
  }

  getMore() {
    this.loading = true;
    this.$q
      .when(this.searchObject.getMore())
      .then(results => {
        this.clonedItemsArray = results;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  searchKeyPress($event) {
    if (!this.searchObject) {
      return;
    }
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) {
        this.search();
      }

      this.search.flush();

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) || (this.searchText && this.searchText.length <= 3)) {
      return;
    }

    this.search();
  }

  _search() {
    this.searching = true;
    return this.searchObject
      .search(this.searchText)
      .then(results => {
        this.clonedItemsArray = results;
      })
      .catch(err => this.utils.defaultErrorHandler('Unable to perform search', err))
      .finally(() => {
        this.searching = false;
      });
  }

  isValid() {
    if (this.multiple) {
      return _.findIndex(this.clonedItemsArray, item => item.checked) > -1;
    } else {
      return this.selectedItem;
    }
  }
  openLogSheet(sheetId) {
    this.step = this.steps.VIEW_ITEM;
    this.logSheetsPromise.then(sheets => {
      let chosen = _.find(sheets, (s, id) => id === sheetId);
      this.url = `https://static.foodready.ai/static/resources/logFormScreenshot/${chosen.imgName ? chosen.imgName : 'General-Log.jpg'}`;
    });
  }
  previousStep() {
    this.step = this.steps.PICK_TYPE;
  }
}
module.exports = Constructor;
