module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.logs', {
        url: '/logs',
        template: '<fr-logs></fr-logs>',
      });
  });
};
