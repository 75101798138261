module.exports = function(ngModule) {
  ngModule.component('fileViewModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./file-view-modal.template.html'),
    controller: require('./file-view-modal.controller'),
    controllerAs: 'vm'
  });
};
