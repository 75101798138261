module.exports = function($state, productAccess, preventDirtyNav, growl, $log, fbutil, $timeout, miniTourService) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    $timeout(function() {
      preventDirtyNav(vm.propertiesForm, 'You have unsaved changes to ' + vm.product.brandName + '.', vm.save);
    }, 0);

    $timeout(() => {
      miniTourService.enqueueTour(vm.user, {
        id: 'poExpertServices',
        selector: '.mega-menu #expertServices',
        title: 'Need expert help?',
        contentHtml: 'Need a thorough expert review, process authority letter, or would you like an expert to ' +
        'create the plan for you? Visit <b>Expert Services</b> anytime an submit a request for quote. ' +
        'It\'s fast and easy and our food safety experts are waiting to help!'
      });
    }, 600);
  };

  vm.save = function() {
    vm.product.$save()
      .then(() => {
        if (vm.propertiesForm) { vm.propertiesForm.$setPristine(); }
        growl.success('Product characteristics saved.');
      });
  };
};
