class CfUploadBtn {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-upload-file-btn.template.html');
    this.replace = true;
    this.bindToController = {
      label: '@?',
      savingLabel: '@?',
      organization: '@',      // The org owning the files
      remoteDirectory: '@',   // The S3 directory to store these files
      files: '=',             // A $firebaseArray representing where to store the file(s) in FB
      categories: '=?',       // File categories to allow the user to assign
      chosenCategory: '@?',   // Categories to initialize to
      acceptFilter: '@?',     // File extension filter (e.g. 'pdf')
      btnClass: '@?',         // ex. 'btn-u btn btn-md u-btn-primary'
      iconClass: '@?',        // ex. 'far fa-check'
      iconAlign: '@?',        // ex. 'right', 'left'
      saving: '=',
      isDisabled: '=?',
      onClick: '&?',
      onUpload: '&?'
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller($uibModal, growl, utils, $firebaseObject, $q) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.growl = growl;
    this.utils = utils;
    this.$firebaseObject = $firebaseObject;
    this.$q = $q;

    this.label = this.label || 'Upload File';
    this.savingLabel = this.savingLabel || 'Uploading...';
    this.iconClass = this.iconClass || 'far fa-file-upload';
    this.iconAlign = this.iconAlign ? this.iconAlign.toLowerCase() : 'left';

    this.edit = function(item) {
      return this.$q.when(_.isFunction(this.onClick) ? this.onClick() : null)
          .then(() => this.$uibModal.open({
            component: 'cfMedia',
            controllerAs: 'vm',
            backdrop: 'static',
            resolve: {
              item: () => this.$firebaseObject(item ? this.files.$ref().child(item.$id) :
                  this.files.$ref().push()).$loaded(),
              remoteDirectory: () => this.remoteDirectory,
              media: () => this.files,
              organization: () => ({$id: this.organization}),
              categories: () => this.categories,
              acceptFilter: () => this.acceptFilter,
              warning: () => this.warning,
              chosenCategory: () => this.chosenCategory
            }
          }).result).then(() => {
            this.growl.success('Files ' + (item ? 'updated' : 'added') + ' successfully.', {});
            if (_.isFunction(this.onUpload)) {
              this.onUpload();
            }
          }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new file'));
    };
  }
}

module.exports = CfUploadBtn;
