module.exports = function(ngModule) {
  ngModule.factory('subscriptionService', function($state, $uibModal, confirmModal, $q, constantsService, growl) {
    let stripeConstantsPromise = constantsService.get('stripe');

    return {

      /**
       * Prompt the user to upgrade to a premium plan.
       * @param {object} user The logged in user
       * @param {string} title A string used in the modal title to describe the limitation to their current subscription.
       * @param {function<Promise>} requiredProductPromise A function that returns a promise resolving to
       * the required stripe product.
       * @return {Promise} A promise that is resolve when the upgrade completes or is cancelled.
       */
      promptForUpgrade: function(user, title, requiredProductPromise) {
        if (!user.onPrimaryOrg()) {
          growl.error('Please contact your administrator to change your subscription.');
          return;
        }

        return $uibModal.open({
          component: 'cfUpgradeSubscription',
          size: 'lg',
          resolve: {
            user: () => user,
            title: () => title,
            requiredProduct: () => requiredProductPromise,
            stripeConstants: () => stripeConstantsPromise
          }
        }).result;
      },

      /**
       * Prompt the user to upgrade to a premium plan.
       * @param {object} user The logged in user
       * @param {string} title A string used in the modal title to describe the limitation to their current subscription.
       * @param {string} [desiredStateName] An optional ui-router state to go to if they successfully upgrade.
       * @param {object} [desiredStateParams] The ui-router params to use if they successfully upgrade.
       * @return {Promise} A promise that is resolve when the upgrade completes. Note, a state transmission may have
       * started if they choose to upgrade. If they choose not to upgrade, the promise rejects with reason = 'cancel'.
       */
      promptForChange: function(user, title, desiredStateName, desiredStateParams) {
        if (!user.onPrimaryOrg()) {
          growl.error('Please contact your administrator to change your subscription.');
          return;
        }

        return $uibModal.open({
          component: 'cfChangeSubscriptionModal',
          backdrop: 'static',
          size: 'xl',
          resolve: {
            user: () => user,
            stripeConstants: () => stripeConstantsPromise
          }
        }).result.then(newSubscription => {
          if (!_.isEmpty(desiredStateName)) { $state.go(desiredStateName, desiredStateParams); }
          return newSubscription;
        });
      },

      /**
       * Prompt the user to upgrade to a premium plan.
       * @param {object} user The logged in user
       * @param {string} title A string used in the modal title to describe the limitation to their current subscription.
       * @param {string} [desiredStateName] An optional ui-router state to go to if they successfully upgrade.
       * @param {object} [desiredStateParams] The ui-router params to use if they successfully upgrade.
       * @return {Promise} A promise that is resolve when the upgrade completes. Note, a state transmission may have
       * started if they choose to upgrade. If they choose not to upgrade, the promise rejects with reason = 'cancel'.
       */
      promptForActivation: function(user) {
        if (!user.onPrimaryOrg()) {
          growl.error('Please contact your administrator to activate your subscription.');
          return;
        }

        return $uibModal.open({
          component: 'cfActivateSubscription',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            user: () => user,
            stripeConstants: () => stripeConstantsPromise
          }
        }).result;
      },

      /**
       * Prompt the user to buy plan or activate trial.
       * @param {object} user The logged in user
       * @param {string} [desiredStateName] An optional ui-router state to go to if they successfully upgrade.
       * @param {object} [desiredStateParams] The ui-router params to use if they successfully upgrade.
       * @return {Promise} A promise that is resolve when the upgrade completes. Note, a state transmission may have
       * started if they choose to upgrade. If they choose not to upgrade, the promise rejects with reason = 'cancel'.
       */
      promptForPlanOptions: function(user, product) {
        if (!user.onPrimaryOrg()) {
          growl.error('Please contact your administrator to change your subscription.');
          return;
        }

        return $uibModal.open({
          component: 'cfChangeSubscriptionModal',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            user: () => user,
            product: ()=> product,
            stripeConstants: () => stripeConstantsPromise,
            isPlanPrompt: true
          }
        }).result;
      },

    };
  });
};
