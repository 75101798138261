module.exports = {
  ALLERGEN_STATEMENT: {id: 8, text: 'Allergen Grid / Statement'},
  AUDIT_CERTIFICATE: {id: 17, text: 'Audit Certificate, Report & Corrective Actions'},
  CERT_ANALYSIS: {id: 2, text: 'Certificate of Analysis'},
  ORIGIN: {id: 6, text: 'Country of Origin'},
  SAFETY_PLAN: {id: 1, text: 'Food Safety Plan (HACCP or Preventive Controls)'},
  GMO: {id: 10, text: 'GMO/Non-GMO Statement'},
  HALAL: {id: 11, text: 'Halal Certificate (If Applicable)'},
  KOSHER: {id: 12, text: 'Kosher Certificate'},
  LABEL: {id: 18, text: 'Label Information'},
  NUTRITIONAL_INFORMATION: {id: 7, text: 'Nutritional Information'},
  PRODUCT_FORMULATION: {id: 16, text: 'Product Formulation'},
  PRODUCT_SPECIFICATION: {id: 15, text: 'Product Specification'},
  RECALL_PLAN: {id: 3, text: 'Recall Plan'},
  SDS_STATEMENT: {id: 9, text: 'SDS/MSDS Statement'},
  VALIDATION: {id: 13, text: 'Validation of each product and/or process and Ready-To-Eat statements (if applicable)'},
  VERIFICATION: {id: 14, text: 'Verification Study'},
  OTHER: {id: 0, text: 'Other'},
  GMP: {id: 4, text: 'Good Manufacturing Practices', companyLevel: true},
  SUPPLIER_QUESTIONNAIRE: {id: 19, text: 'Supplier Questionnaire', companyLevel: true},
  NDA: {id: 20, text: 'Mutual NDA Agreement', companyLevel: true},
  DEFENSE: {id: 21, text: 'Food Defense/Business Continuity Plan', companyLevel: true},
  FSMA: {id: 22,
    text: 'Food Safety Modernization Act (FSMA) documentation (including Foreign Supplier Verification Program)'},
  GUARANTY: {id: 23, text: 'Supplier Guaranty and Indemnification Agreement', companyLevel: true},
  OTHER_CO: {id: 99, text: 'Other', companyLevel: true}
};
