class Constructor {
  constructor(products, $q, $log, $state, utils, fbutil) {

    'ngInject';
    this.products = products;
    this.$q = $q;
    this.$log = $log;
    this.$state = $state;
    this.utils = utils;
    this.fbutil = fbutil;
  }

  $onInit() {
    this.hazardsSelected = [];
  }

  addHazards() {
    this.$product.hazards = _.reduce(this.hazardsSelected, (hazardObj, val, index) => {
      hazardObj[this.fbutil.generateArrayKey()] = _.pick(this.typicalHazards[index], ['name', 'type']);

      return hazardObj;
    }, {});

    this.addingHazards = true;
    return this.$product.$save()
        .then(() => {
          this.$state.go('onboarding.needs');
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to add hazards.')).finally(() => {
          this.addingHazards = false;
        });
  }

  skip() {
    this.$log.info('onboarding:addHazards - skip.');
    this.$state.go('onboarding.needs');
  }
}

module.exports = Constructor;
