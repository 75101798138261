require('./image-cropper.scss');

module.exports = function (ngModule) {
  ngModule.component('cfImageCropper', {
    bindings: {
      file: '<',
      croppedImage: '=',
      cropWidth: '<',
      cropHeight: '<',
      areaType: '@',
      aspectRatio: '=',
      maxRatio: '@'
    },
    template: require('./image-cropper.template.html'),
    controller: require('./image-cropper.controller.js'),
    controllerAs: 'vm'
  });
};
