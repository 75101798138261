class Constructor {
  constructor(growl, products, utils, $uibModal) {
    'ngInject';

    this.growl = growl;
    this.products = products;
    this.utils = utils;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.isCfAdmin = this.user.isCfAdmin();
  }

  async transferTemplate(template) {
    this.$uibModal.open({
      component: 'cfChooseOrganization',
      resolve: {
        user: () => this.user,
        titleHtml: () => '<i class="far fa-handshake fa-fw" aria-hidden="true"></i> Please choose recipient organization',
        okButtonText: () => 'Choose Organization'
      }
    }).result
      .then(async id => {
        template.organizationId = id;
        template.transferredFrom = this.user.orgContext.id;

        await this.products.transferPlanTemplate(id, template);
        this.growl.success('Template transferred successfully.');
      })
  }

  remove(template) {
    this.products.removePlanTemplate(template.organizationId, template.$id)
      .then(() => {
        _.remove(this.templates, {$id: template.$id});
        this.growl.success('Template deleted successfully.');
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete plan template.'));
  }
}

module.exports = Constructor;
