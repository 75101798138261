'use strict';

module.exports = function($firebaseArray, $q, fbutil, S3, $log, growl, states, countries) {
  'ngInject';

  var vm = this, uploadProgress = {};

  function refreshUploadProgress() {
    if (!vm.newFiles.length) { return 0; }

    var progress = _.reduce(uploadProgress, (sum, n) => {
      return sum + n;
    }, 0);

    vm.uploadProgress = _.round(progress / vm.newFiles.length);
  }

  vm.$onInit = function() {
    vm.laboratory = vm.resolve.laboratory;
    vm.company = vm.resolve.company;

    vm.states = states();
    vm.countries = countries();

    vm.USA = 'US';
    vm.laboratory.country = vm.laboratory.country || vm.USA;
    vm.laboratory.mailingCountry = vm.laboratory.mailingCountry || vm.USA;

    vm.uploadProgress = 0;
    vm.newFiles = [];
    vm.removeFiles = [];

    $firebaseArray(fbutil.ref('organizations', vm.company.$id, 'laboratories', vm.laboratory.$id, 'media')).$loaded()
      .then((files) => {
        vm.existingFiles = files;
      });
  };

  vm.save = function() {
    var filePromises = [];

    vm.laboratory.$save().then(function() {
      vm.uploading = vm.newFiles.length > 0;
      _.each(vm.newFiles, (file, idx) => {
        filePromises.push(
          S3.uploadOrgFile(vm.company.$id, 'laboratories/' + vm.laboratory.$id + '/media/' + file.name, file,
            (event) => {
              uploadProgress[idx] = parseInt(100.0 * event.loaded / event.total, 10);
              refreshUploadProgress();
            })
            .then((result) => {
              return vm.existingFiles.$add(result);
            })
        );
      });

      _.each(vm.removeFiles, (file) => {
        filePromises.push(
          S3.deleteFile(vm.company.$id, file.key).then(() => {
            return vm.existingFiles.$remove(file);
          })
        );
      });

      return $q.all(filePromises);
    })
    .then(() => {
      vm.modalInstance.close(vm.facility);
    })
    .catch((err) => {
      vm.modalInstance.dismiss(err);
    })
    .finally(() => {
      vm.uploading = false;
    });
  };

  vm.cancel = function() {
    vm.modalInstance.dismiss('cancel');
  };

  vm.resetState = function() {
    if (vm.laboratory.country === vm.USA) { vm.laboratory.state = null; }
  };

  vm.resetMailingState = function() {
    if (vm.laboratory.mailingCountry === vm.USA) { vm.laboratory.mailingState = null; }
  };
};
