class Constructor {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  $onInit() {
    this.isNew = this.$sopLog.$value === null;
  }

  onSave(sopLog) {
    if (this.isNew) {
      this.sopLogs.unshift(sopLog);
      this.isNew = false;
    }

    this.$state.go('operations.sopLogs.list');
  }

  onCancel() {
    this.$state.go('operations.sopLogs.list');
  }
}

module.exports = Constructor;
