module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsDestruction', {
    bindings: {
      $event: '=',
      user: '<'
    },
    template: require('./recall.events.destruction.template.html'),
    controller: require('./recall.events.destruction.controller'),
    controllerAs: 'vm'
  });
};
