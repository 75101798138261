module.exports = function(ngModule) {
  require('./states.constant')(ngModule);
  require('./countries.constant')(ngModule);
  require('./email-template-ids.constant')(ngModule);
  require('./countryStates.constant')(ngModule);
  require('./units.constant')(ngModule);
  require('./unit-containers.constant')(ngModule);
  require('./timer-units.constant')(ngModule);
  require('./frequency-constant')(ngModule);
  require('./inequalities.constant')(ngModule);
  require('./companyTypes.constant')(ngModule);
  require('./countryPhoneCodes.constant')(ngModule);
};
