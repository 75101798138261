module.exports = function(ngModule) {
  ngModule.component('cfOnboardingQuestionnaire', {
    bindings: {
      user: '<',
      service: '<',
      company: '<',
      onboarding: '<'
    },
    template: require('./onboarding.questionnaire.template.html'),
    controller: require('./onboarding.questionnaire.controller'),
    controllerAs: 'vm'
  });
};
