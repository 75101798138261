module.exports = function(ngModule) {
  ngModule.component('cfRecallPlanEditCustomer', {
    bindings: {
      user: '<',
      customerTypes: '<',
      $customer: '='
    },
    template: require('./recall-plan.edit-customer.template.html'),
    controller: require('./recall-plan.edit-customer.controller'),
    controllerAs: 'vm'
  });
};
