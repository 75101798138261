class Controller {
  constructor($timeout, $state, $scope, $log, preventDirtyNav, growl, users, batchService, utils, products, $q, ingredientService, constantsService,
              organizations, $uibModal, confirmModal, $window, confirmDeleteModal, foodProductService) {
    'ngInject';

    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$state = $state;
    this.$log = $log;
    this.preventDirtyNav = preventDirtyNav;
    this.growl = growl;
    this.users = users;
    this.batchService = batchService;
    this.utils = utils;
    this.products = products;
    this.foodProductService = foodProductService;
    this.$q = $q;
    this.ingredientService = ingredientService;
    this.constantsService = constantsService;
    this.$uibModal = $uibModal;
    this.confirmModal = confirmModal;
    this.confirmDeleteModal = confirmDeleteModal;
  }

  async $onInit() {
    this.$timeout(() => this.preventDirtyNav(this.batchForm, 'You have unsaved batch changes.', () => this._save()));
    this.psLogsSelected = {};
    this.procedureProcessStepMap = {};
    this.totalSizeString = '';

    this.isStarted = !!this.$batch.startedOn;
    this.isNew = this.$batch.$value === null;
    this.$batch.organizationId = this.$batch.organizationId || this.user.orgContext.id;
    await this.loadConstants();
    this.unitMap = {};
    this.unitList.forEach((unit)=>{
      this.unitMap[unit.value] = unit.name;
    });
    this.unitTypeMap = {};
    this.unitTypes = _.sortBy(this.unitTypes, 'name');
    this.unitTypes.forEach((container)=>{
      this.unitTypeMap[container.value] = container.name;
    });

    if (this.$batch.productId) {
      if (this.$batch.foodProductName) {
        this.productName = this.$batch.foodProductName + ' - ' + this.$batch.brandName;
      } else {
        this.productName = this.$batch.brandName;
      }

      // For historical data, put batch title as 'Food Product Name - Plan Name'
      if (!this.$batch.batchTitle) {
        this.$batch.batchTitle = this.productName;
      }

      this.fetchProduct().then(() => {
        if (this.$batch.billOfMaterials) {
          // Update ingredientName field in billOfMaterials
          this.updateIngredientNames();
        }
      });
    } else {
      this.lookupProduct();
    }

    if (this.$batch.endedBy) {
      this.users.getName(this.$batch.endedBy)
        .then((name) => this.endedBy = name);
    } else if (this.$batch.startedBy) {
      this.users.getName(this.$batch.startedBy)
        .then((name) => this.startedBy = name);
    }

    this.$scope.$watchGroup(['vm.$batch.noOfUnits', 'vm.$batch.sizeOfUnit', 'vm.$batch.measurementUnit', 'vm.$batch.unitType'],
      (newVals) => {
        if (newVals[0] && newVals[1] && newVals[2] && newVals[3]) {
          let singularPlural = newVals[0] > 1 ? newVals[3] === 'box' ? 'es' : newVals[3] === 'each' ? '' : 's' : '';

          this.totalSizeString = newVals[0] + ' ' + this.unitTypeMap[newVals[3]] + singularPlural +
            ' (' + newVals[0] * newVals[1] + ' ' + this.unitMap[newVals[2]] + ')';
          this.$batch.size = newVals[0];
        } else {
          this.totalSizeString = '';
        }
      }, true);
  }

  async loadConstants() {
    this.unitListPromise = this.constantsService.get('units')
      .then((unitsArray) => {
        this.unitList = unitsArray;
      });

    this.unitTypesPromise = this.constantsService.get('unitTypes')
      .then((unitTypes) => {
        this.unitTypes = unitTypes;
      });

    await this.$q.all([this.unitListPromise, this.unitTypesPromise]);
  }

  genBatchNumber() {
    const today = new Date();
    const todayTime = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();

    return `${this.foodProductNumber}-${Math.floor(parseInt(todayTime))}`;
  }

  addAllIngredients() {
    let sortedIngredients = [];

    _.mapValues(this.ingredients,(ingredient, id)=>{
      ingredient.id = id;
      sortedIngredients.push(ingredient);
    });
    sortedIngredients.sort((ingredient1, ingredient2)=>{
      return ingredient1.name > ingredient2.name ? 1 : -1;
    });
    sortedIngredients.forEach((ingredient) => {
      this.$batch.billOfMaterials[ingredient.id] = {ingredientId: ingredient.id, ingredientName: ingredient.name};
    });
  }

  fetchProduct() {
    return this.products.$get(this.$batch.productId).then(async $product => {
      this.$product = $product;
      return await this.getMaterials();
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to retrieve plan details.'));
  }

  getMaterials() {
    this.ingredients = {};
    let ingredientIds = {};

    if(!_.isEmpty(this.$batch.billOfMaterials)) {
      _.map(this.$batch.billOfMaterials, (material) => {
        ingredientIds[material.ingredientId] = true;
      });
    } else {
      _.map(this.$product.ingredients, ({processSteps}, ingredientKey) => {
        ingredientIds[ingredientKey] = true;
      });
    }

    return this.$q.all(_.map(ingredientIds, (value, ingredientKey) => {
      return this.ingredientService.get(ingredientKey)
          .then(ingredient => {
            this.ingredients[ingredientKey] = ingredient;
          });
    })).then(result => _.flatten(result));
  }

  lookupProduct() {
    this.gettingProduct = true;
    this.batchService.chooseFoodProduct(this.foodProducts).then((foodProduct) => {
      if(!foodProduct) {
        return this.addFoodProduct().then(()=>{
          return this.lookupProduct();
        });
      } else if(this.$batch.productId !== foodProduct.productId) {
        this.$batch.brandName = foodProduct.brandName;
        this.$batch.foodProductId = foodProduct.$id;
        this.$batch.productId = foodProduct.productId;
        this.$batch.foodProductName = foodProduct.name;
        this.foodProductNumber = foodProduct.externalNumber;
        this.productName = foodProduct.name + ' - ' + foodProduct.brandName;
        this.$batch.batchTitle = this.productName;
        if (!this.$batch.batchNumber) {
          this.$batch.batchNumber = this.genBatchNumber();
        }

        this.$batch.billOfMaterials = {};
        return this.fetchProduct().then(() => {
          this.addAllIngredients();
        });
      }
    }).then(() => {
      this._save();
    })
      .catch(err => {
        return this.utils.defaultErrorHandler(err, 'An error occurred selecting the food safety plan.');
      })
    .finally(() => {
      this.gettingProduct = false;
    });
  }

  saveBatch() {
    this.saving = true;
    if(!this.$batch.startedOn) {
      this.$batch.startedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$batch.startedBy = this.user.uid;
      this.users.getName(this.$batch.startedBy)
        .then((name) => this.startedBy = name);
    } else {
      this.$batch.updatedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$batch.updatedBy = this.user.uid;
    }

    this._save().then(() => { this.saving = false; });
  }

  _save() {
    this.saving = true;
    if (this.$batch.$value === null) {
      this.$batch.createdOn = firebase.database.ServerValue.TIMESTAMP;
      this.$batch.createdBy = this.user.uid;
    } else {
      this.$batch.updatedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$batch.updatedBy = this.user.uid;
    }

    return this.$batch.$save()
      .then(() => {
        this.batchForm.$setPristine();

        if (this.isNew) {
          if (this.batches) {
            this.batches.unshift(this.$batch);
          }
        } else {
          let listBatch = _.find(this.batches, {$id: this.$batch.$id});

          _.assign(listBatch, _.pick(this.$batch, ['batchNotes', 'brandName',
            'startedBy', 'startedOn', 'endedBy', 'endedOn']));
        }

        this.growl.success('Batch saved successfully.');
        this.isNew = false;
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save batch.'))
      .finally(() => {
        this.saving = false;
      });
  }

  cancel() {
    this.batchForm.$setPristine();

    return this.$state.go('operations.batches.list');
  }

  updateIngredientNames() {
    _.map(this.$batch.billOfMaterials, (material) => {
      material.ingredientName = this.ingredients[material.ingredientId].name;
    });
  }

  addFoodProduct() {
    return this.$uibModal.open({
      component: 'cfEditFoodProduct',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        foodProduct: () => this.foodProductService.$push(this.user.orgContext.id, '', ''),
        company: () => ({$id: this.user.orgContext.id}),
        showProductList: () => true
      }
    }).result
      .then(({foodProduct, product})=> {
        foodProduct.brandName = product.brandName;
        foodProduct.productId = product.$id;
        foodProduct.$save();
        this.products.addFoodProduct(product.$id, foodProduct.$id);
        this.foodProducts.push(foodProduct);
        return foodProduct.$save();
      })
      .catch(err => {
        return this.utils.defaultErrorHandler(err, 'Error editing foodProduct.');
      });
  }
}

module.exports = Controller;
