'use strict';

module.exports = function(ngModule) {
  ngModule.constant('billingCodes', {
    HACCP_REVIEW: {
      id: 1,
      description: 'HACCP Plan Review',
      defaultRateField: 'haccpPlanRate',
      rushRateField: 'haccpPlanRushRate'
    },
    HACCP_DERIVATIVE: {
      id: 3,
      description: 'HACCP Product Variation Plan Review',
      defaultRateField: 'haccpPlanDerivativeRate',
      rushRateField: 'haccpPlanDerivativeRushRate'
    },
    PC_REVIEW: {
      id: 5,
      description: 'FSMA Preventive Control Plan Review',
      defaultRateField: 'pcPlanRate',
      rushRateField: 'pcPlanRushRate'
    },
    PC_DERIVATIVE: {
      id: 7,
      description: 'FSMA Preventive Control Product Variation Plan Review',
      defaultRateField: 'pcPlanDerivativeRate',
      rushRateField: 'pcPlanDerivativeRushRate'
    },
    SAFETY_TEST_DISCOUNT20: {
      id: 9,
      description: 'Lab Safety Test - 20% discount',
      defaultRateField: ''
    },
    SAFETY_TEST: {
      id: 10,
      description: 'Lab safety test',
      defaultRateField: ''
    },
    LAB_TEST: {
      id: 11,
      description: 'Lab Test'
    },
    LAB_TEST_RUSH: {
      id: 12,
      description: 'Rush lab Test'
    },
    HACCP_REORDER: {
      id: 13,
      description: 'HACCP Product Variation Plan Review Reorder',
      defaultRateField: 'haccpPlanReorderRate',
      rushRateField: 'haccpPlanReorderRushRate'
    },
    HACCP_DERIVATIVE_REORDER: {
      id: 15,
      description: 'HACCP Product Variation Plan Review Reorder',
      defaultRateField: 'haccpPlanReorderDerivativeRate',
      rushRateField: 'haccpPlanReorderDerivativeRushRate',
    },
    PC_REORDER: {
      id: 17,
      description: 'FSMA Preventive Control Plan Review Reorder',
      defaultRateField: 'pcPlanReorderRate',
      rushRateField: 'pcPlanReorderRushRate'
    },
    PC_DERIVATIVE_REORDER: {
      id: 19,
      description: 'FSMA Preventive Control Product Variation Plan Review Reorder',
      defaultRateField: 'pcPlanReorderDerivativeRate',
      rushRateField: 'pcPlanReorderDerivativeRushRate',
    },
    MISC_SERVICE: {
      id: 20,
      description: 'Miscellaneous service'
    }
  });
};