module.exports = function (ngModule) {
  require('./db')(ngModule);
  require('./searches')(ngModule);
  require('./cache')(ngModule);
  require('./firebase.service')(ngModule);
  require('./utils.service')(ngModule);
  require('./s3.service')(ngModule);
  require('./search.service')(ngModule);
  require('./firebase.key-generate.service')(ngModule);
  require('./confirm-modals.service')(ngModule);
  require('./menus.service')(ngModule);
  require('./image-cropper-modal')(ngModule);
  require('./prevent-dirty-nav.service')(ngModule);
  require('./csv-import-service')(ngModule);
  require('./idle.service')(ngModule);
  require('./org-file-manager.service')(ngModule);
  require('./http-interceptor.service')(ngModule);
  require('./org-interaction.service')(ngModule);
  require('./mini-tour-manager.service')(ngModule);
  require('./gtm.service')(ngModule);
  require('./drift.service')(ngModule);
  require('./single-exit-form.service')(ngModule);
  require('./profitwell.service')(ngModule);
  require('./history.service')(ngModule);
  require('./ai.service')(ngModule);
};
