class Controller {
  constructor(utils, $state) {
    'ngInject';

    this.utils = utils;
    this.$state = $state;
  }

  $onInit() {
    this.types = [];
    _.each(this.complaintTypes, (value,key)=> {
      value.id = key;
      this.types.push(value);
    });
    this.remoteDirectory = 'recallPlans/' + this.$productComplaint.$id + '/files';
    this.branchName = 'recallPlans/' + this.company.$id + '/productComplaints';
  }

  onSave() {
    this.$productComplaint.$save()
      .then(()=> this.$state.go('recall.productComplaints.list'))
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to save Product Complaint.'));
  }

  onCancel() {
    this.$state.go('recall.productComplaints.list');
  }
}

module.exports = Controller;
