const Quagga = require('quagga/dist/quagga');
// https://github.com/serratus/quaggaJS

class Constructor {
  constructor($q, $log, $uibModal) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.$uibModal = $uibModal;

    this.config = {
      inputStream: {
        //name: 'Live',
        type: 'LiveStream',
        constraints: {
          width: {min: 640},
          height: {min: 480},
          facingMode: 'environment',
          aspectRatio: {
            min: 1,
            max: 2
          }
        },
        target: '.barcode-display'
      },
      locator: {
        patchSize: 'medium',
        halfSample: true
      },
      numOfWorkers: 2,
      frequency: 10,
      decoder: {
        readers: [{
          format: 'I2of5',
          config: {}
        }]
      },
      locate: true
    };
  }

  $onInit() {
    this.start();
  }

  cancel() {
    Quagga.stop();
    this.modalInstance.dismiss('cancel');
  }

  onDetected(result) {
    Quagga.stop();
    this.modalInstance.close(result.codeResult.code);
  }

/*
  getCapabilities() {
    const track = Quagga.CameraAccess.getActiveTrack();

    if (_.isFunction(track.getCapabilities)) {
      return track.getCapabilities();
    }
    //this.applySettingsVisibility('zoom', capabilities.zoom);
    //this.applySettingsVisibility('torch', capabilities.torch);
    return null;
  }
*/

  start() {
    Quagga.init(this.config, (err) => {
      if (err) {
        this.$log.error('An error occurred opening the barcode reader.', this.$log.toString(err));
        this.modalInstance.dismiss(err);
        return;
      }

      //this.cameraInfo = this.getCameraInformation();
      //this.capabilities = this.getCapabilities();


      Quagga.onDetected(result => {
        this.onDetected(result);
      });
      Quagga.start();
    });
  }

  getCameraInformation() {
    const streamLabel = Quagga.CameraAccess.getActiveStreamLabel();

    return {
      streamLabel,
      devices: Quagga.CameraAccess.enumerateVideoDevices()
    };
  }
}

module.exports = Constructor;
