class Controller {
  constructor($scope, $timeout, $log, states, User, billing, growl, $state) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$log = $log;
    this.states = states();
    this.User = User;
    this.billing = billing;
    this.growl = growl;
    this.$state = $state;
  }

  $onInit() {
    this.orderCtrl.step = this.orderCtrl.step || 0;
    this.sendEmail = true;
    this.setReceiptEmail();

    let pricing = _.get(this.orderCtrl.order, 'team.pricing');

    _.each(this.orderCtrl.order.items, (item) => {
      if (item.coupon) {
        this.applyPromo(item.coupon);
      }
      if (pricing && !item.amount && !_.isEmpty(item.billingCode)) {
        item.amount = {
          standard: pricing[_.get(item, 'billingCode.defaultRateField')],
          rush: pricing[_.get(item, 'billingCode.rushRateField')]
        };
      }
    });

    if (!_.every(this.orderCtrl.order.items, i => { return i.amount && i.amount.standard; })) {
      this.growl.error('Unable to process order. Please contact customer support.');
      this.$log.error('Order attempted but amount missing' + this.orderCtrl.order.items);
      this.orderCtrl.onPaymentFailed();
    }

    this.dehydrateOrder(this.orderCtrl.order);
    this.orderCtrl.order.createdOn = firebase.database.ServerValue.TIMESTAMP;
    this.orderCtrl.order.userId = this.orderCtrl.user.uid;

    if (!this.orderCtrl.user.customerRec || this.orderCtrl.user.customerRec.deleted) {
      this.$log.error('Customer rec not usable for order payment in order-payment.content.controller.');
      this.growl.error('An error occurred loading your payment data. Please contact customer ' +
        'support if this continues.');
      this.$state.go('user.dashboard');
    }

    _.set(this, 'orderCtrl.payment.customer', this.orderCtrl.user.customerRec);
    let sources = _.get(this.orderCtrl.payment.customer, 'sources.data', []);

    _.remove(sources, s => this.isAch(s) && s.status !== 'verified');
    if (sources.length) {
      _.each(sources, (source) => {
        const nickname = _.get(source, 'metadata.nickname') || '';

        source.description = nickname + (nickname && ' (') + (this.isAch(source) ?
          'bank account' : source.brand) + ' ending in ' + source.last4 + (nickname && ')');
      });
    } else {
      _.set(this, 'orderCtrl.payment.newCard', true);
    }

    this.$scope.$watch('vm.paymentForm.$invalid', (newVal) => {
      if (this.orderCtrl.payment) { this.orderCtrl.payment.invalid = newVal; }
    });
  };

  dehydrateOrder(order) {
    if (order.team) {
      order.teamId = order.team.id;
      delete order.team;
    }

    _.each(order.items, item => {
      if (item.billingCode) {
        item.billingCodeId = item.billingCode.id;
        delete item.billingCode;
      }
    });
  }

  applyPromo(couponId) {
    delete this.invalidPromo;
    delete this.coupon;
    this.gettingCoupon = true;
    this.billing.getCoupon(couponId)
      .then(coupon => {
        let itemWithCoupon = _.find(this.orderCtrl.order.items, {coupon: couponId}) ||
          _.find(this.orderCtrl.order.items, {couponType: coupon.metadata.type}) &&
          _.find(this.orderCtrl.order.items, {planId: coupon.metadata.planId});

        if (itemWithCoupon) {
          this.coupon = coupon;
          itemWithCoupon.coupon = coupon.id;
        } else {
          this.invalidPromo = 'Promo code does not apply to item(s) in this order.';
        }
      })
      .catch(err => {
        if (err.status === 404) {
          this.invalidPromo = 'Promo code not found!';
        } else {
          this.$log.error('Error retrieving coupon: ', err);
          this.invalidPromo = 'An error occurred while applying the promo code.';
        }
      })
      .finally(() => this.gettingCoupon = false);
  }

  getTotalAmount(items) {
    return _.reduce(items, (total, n) => {
      if (!n.amount) { return total; }
      let percent = 1;

      if (this.coupon && this.coupon['percent_off_precise'] && n.coupon === this.coupon.id) {
        percent = 1 - this.coupon['percent_off_precise'] / 100;
      }
      return total + (n.isRushOrder ? n.amount.rush : n.amount.standard * percent);
    }, 0);
  }

  getAmountOff(coupon) {
    const itemWithCoupon = _.find(this.orderCtrl.order.items, {coupon: coupon.id});

    if (!itemWithCoupon) { return; }
    if (coupon['percent_off_precise']) {
      return itemWithCoupon.isRushOrder ? itemWithCoupon.amount.rush : itemWithCoupon.amount.standard *
        coupon['percent_off_precise'] / 100;
    }
  }

  toggleNewCard() {
    this.orderCtrl.payment.newCard = !this.orderCtrl.payment.newCard;
    if (this.orderCtrl.payment.newCard) {
      this.orderCtrl.payment.source = {};
      delete this.orderCtrl.payment.sourceId;
    }
  }

  setReceiptEmail() {
    this.orderCtrl.order.receiptEmail = this.sendEmail ? this.orderCtrl.user.email : null;
  };

  setSavedSource(id) {
    if (!id) { return; }

    this.orderCtrl.payment.source = _.find(this.orderCtrl.payment.customer.sources.data, {id: id});
  }

  isAch(source) {
    return source.object === 'bank_account';
  }
}

module.exports = Controller;
