module.exports = function(ngModule) {
  ngModule.component('cfSupplierOnboardingModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./suppliers.onboard-supplier-modal.template.html'),
    controller: require('./suppliers.onboard-supplier-modal.controller'),
    controllerAs: 'vm'
  });
};