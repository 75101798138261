class CfInputGroup {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-input-group.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      type: '@?',            // The type of input group (options: ['input'], default = 'input)
      label: '@?',           // The label text
      class: '@?',           // The container class
      icon: '@',             // The icon to append
      name: '@',             // The name of the form control
      appendDirection: '@?', // Append the icon to the right or the left (options: ['left', 'right'], default = 'left')
      helpText: '@?',        // Optional subscript helper text
      popoverPlacement: '@', // The placement of the popover help text
      popoverHelpText: '@',  // Optional question mark icon to right of label with popover helper text
      isRequired: '=',       // Is the field required (true / false)
      isDisabled: '=',       // Is the field disabled (true / false)
      isReadonly: '=',       // Is the field read-only (true / false)
      placeholder: '@',      // Input placeholder
      appendBtn: '=',        // Append an action button
      btnText: '@',          // Action button text
      btnIconClass: '@',     // Action button icon class (e.g. fa fa-ellipsis)
      size: '@',             // Size of input/button (default to 'md')
      btnLoadingText: '@',   // Action button loadingtext
      btnDisabled: '=',      // Is the button disabled
      click: '&btnClick',    // Button click action
      loading: '=',          // Button loading indicator
      pattern: '@'
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';
  }

  link(scope, elem) {
    let vm = scope.vm;

    vm.containerClass = vm.class || 'g-mb-20';
    vm.type = vm.type || 'text';
    vm.appendDirection = vm.appendDirection || 'left';
    vm.btnText = vm.btnText || vm.btnIconClass ? null : 'Apply';
    vm.btnLoadingText = vm.btnLoadingText || 'Applying...';
    vm.size = vm.size || 'md ';

    let $formElement = elem.find('input');

    $formElement.focus(() => $formElement.parent().addClass('g-state-focus'));
    $formElement.blur(() => $formElement.parent().removeClass('g-state-focus'));
  }
}

module.exports = CfInputGroup;
