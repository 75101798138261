'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfCreateOrg', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./administration.create-org.template.html'),
    controller: require('./administration.create-org.controller'),
    controllerAs: 'vm'
  });
};