module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseObject, $uibModal, $q, $log, SopLibrarySearch, utils, constantsService, $http,
                SAMPLE_ORGANIZATION_ID, confirmModal, cfpLoadingBar, csvImportModal) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseObject = $firebaseObject;
      this.$uibModal = $uibModal;
      this.$q = $q;
      this.$log = $log;
      this.SopLibrarySearch = SopLibrarySearch;
      this.utils = utils;
      this.constantsService = constantsService;
      this.$http = $http;
      this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
      this.confirmModal = confirmModal;
      this.cfpLoadingBar  = cfpLoadingBar;
      this.csvImportModal = csvImportModal;

      this.sopTypes = null;
      this.sopLibrarySearch = {};
    }

    /**
     * Get a SOP Library Item.
     * @param {string} id The SOP Library Item ID
     * @return {Promise} A promise that resolves to a $firebaseObject
     */
    $get(id) {
      return this.$firebaseObject(this.fbutil.ref('sopLibraryItems', id)).$loaded();
    }

    /**
     * Get a SOP Library Item.
     * @param {string} id The SOP Library Item ID
     * @return {Promise} A promise that resolves to a SOP Library Item
     */
    get(id) {
      return this.fbutil.ref('sopLibraryItems', id).once('value')
        .then(snap => snap.exists() ? _.assign(snap.val(), {$id: snap.key}) : null);
    }

    /**
     * Soft delete a SOP Library Item.
     * @param {string} id The SOP Library Item ID
     * @return {Promise} A promise that resolves when soft deleted
     */
    remove(id) {
      return this.fbutil.ref('sopLibraryItems', id, 'deleted').set(true);
    }

    /**
     * Query the SOP Library
     * @param {object} user The logged in user
     * @param {string} searchText The query text
     * @param {object} options Additional search options
     * @param {string} options.organizationId An override for the organization owning the library.
     * @param {string} options.suggestedType 'facility' or 'plan'.
     * @param {array<string>} options.orgTypes Restrict to these org types.
     * @param {Array} options.fields Only return these fields in the result set
     * @param {boolean} options.asFile Return result as a file download
     * @param {number} from From index
     * @param {number} size Number of results to return
     * @returns {Promise} A promise that resolves to an array of SOP library items
     */
    query(user, searchText, options, from = 0, size = 100) {
      options = options || {};
      searchText = searchText || '';

      this.sopLibrarySearch = new this.SopLibrarySearch(options.organizationId);

      if (options.suggestedType) {
        this.sopLibrarySearch = this.sopLibrarySearch.hasSuggestedType(options.suggestedType);
      }
      if (options.orgTypes) {
        this.sopLibrarySearch = this.sopLibrarySearch.hasOrgTypes(options.orgTypes);
      }
      if (options.fields) { this.sopLibrarySearch = this.sopLibrarySearch.fields(options.fields); }
      if (options.asFile) { this.sopLibrarySearch = this.sopLibrarySearch.asFile(); }

      return this.sopLibrarySearch.startFrom(from).size(size).search(searchText);
    }

    loadMore(text) {
      return this.sopLibrarySearch.getMore(text);
    }

    push(rec) {
      return this.fbutil.ref('sopLibraryItems').push(rec);
    }

    $push(rec) {
      return this.$firebaseObject(this.fbutil.ref('sopLibraryItems').push(rec)).$loaded();
    }

    import(user) {
      let config = {
        title: 'Import SOP CSV file',
        fields: {
          id: {description: 'A unique identifier for the SOP.'},
          title: {required: true, description: 'A shorter title or policy description for this SOP.'},
          description: {description: 'A description for this SOP.'},
          instructions: {required: true, description: 'The SOP instructions in HTML format.'},
          correctiveAction: {description: 'The SOP corrective action in HTML format.'},
          assignedToRole: {description: 'The role responsible for executing the SOP (e.g. supervisor).'},
          managerInstructions: {description: 'Optional manager instructions.'},
          frequency: {description: 'How often must this SOP be performed (e.g. every day)?'},
          scope: {description: 'The scope of this SOP.'}
        }
      };

      if (user.isCfAdmin()) {
        config.fields.description =
            {description: 'A description field for SOP Library Items to help the user select one.'};
      }

      return this.csvImportModal(config, user).then(importedRecords => {
        return this.$uibModal.open({
          component: 'cfImportSopsModal',
          backdrop: 'static',
          resolve: {
            toSopLibrary: () => true,
            records: () => importedRecords,
            user: () => user
          }
        }).result;
      });
    }

  }

  ngModule.service('sopLibraryService', Service);
};
