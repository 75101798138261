'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUploadPortalIntro', {
    bindings: {
      token: '<'
    },
    template: require('./file-portal.intro.template.html'),
    controller: require('./file-portal.intro.controller.js'),
    controllerAs: 'vm'
  });
};