const requireAuth = require('../../_services/firebase-resolve.service').requireAuth;

module.exports = function (ngModule) {
  ngModule
    .config(function ($stateProvider) {
      'ngInject';

      $stateProvider
        .state('administration', {
          abstract: true,
          url: '/administration',
          template: '<div data-ui-view class="h-100"></div>',
          resolve: {
            currentAuth: requireAuth,
            user: function (currentAuth) {
              return currentAuth && currentAuth.$loaded();
            },
          },
          data: {menuId: 'dashboard'},
        })
        .state('administration.users', {
          abstract: true,
          url: '/users',
          template: '<div data-ui-view></div>',
        })
        .state('administration.users.list', {
          url: '',
          template:
            '<cf-list-users users="$resolve.users" user="$resolve.user" $invitations="$resolve.$invitations" ' +
            'product-constraints="$resolve.productConstraints"></cf-list-users>',
          resolve: {
            users: function (fbutil, UsersSearch, user, CF_ROLES) {
              let userSearch = new UsersSearch(user, {});

              if (!user.isCfAdmin()) {
                userSearch.setInverseRoleFilter(CF_ROLES.ANALYST);
              }

              return userSearch;
            },
            $invitations: ($q, user, organizations) =>
              user.isCfAdmin() || user.hasPermission('admin') ?
                organizations.$getInvitations(user.orgContext.id) :
                $q.resolve([]),
            search: ($q, user, users) =>
              user.isCfAdmin() || user.hasPermission('admin') ? users.search() : $q.resolve([]),
            productConstraints: (constantsService) =>
              constantsService.get('stripe').then((stripeConstants) => stripeConstants.constraints),
          },
        })
        .state('administration.users.cards', {
          url: '/cards',
          template: '<div cf-list-users-card data-users="vm.users"></div>',
          controller: function (users) {
            this.users = users;
          },
          controllerAs: 'vm',
          resolve: {
            users: (fbutil, $firebaseArray) => $firebaseArray(fbutil.ref('users')).$loaded(),
          },
        })
        .state('administration.users.edit', {
          url: '/:userId/edit?full',
          template:
            '<cf-edit-user edit-user="$resolve.editUser" user="$resolve.user" user-search="$resolve.userSearch"' +
            'products="$resolve.userProductsSearch" reviews-access="$resolve.reviewsAccess" ' +
            'answers-access="$resolve.answersAccess" org-role="{{$resolve.orgRole}}"></cf-edit-user>',
          resolve: {
            editUser: function ($stateParams, users, user) {
              return $stateParams.full === 'true' || user.isCfAdmin() ?
                users.$get($stateParams.userId) :
                users.getPublicProfile($stateParams.userId);
            },
            reviewsAccess: function ($stateParams, fbutil, $firebaseObject, user) {
              return (
                user.isCfAdmin() && $firebaseObject(fbutil.ref('userAccess', $stateParams.userId, 'reviews')).$loaded()
              );
            },
            orgRole: function ($stateParams, fbutil, user) {
              return (
                !user.isCfAdmin() &&
                fbutil
                  .ref('userAccess', $stateParams.userId, 'reviews/organizations', user.currentOrgContext().id, 'role')
                  .once('value')
                  .then(fbutil.getValueOrDefault)
              );
            },
            userSearch: function (fbutil, UsersSearch, user, CF_ROLES) {
              let userSearch = new UsersSearch(user, {});

              if (!user.isCfAdmin()) {
                userSearch.setInverseRoleFilter(CF_ROLES.ANALYST);
              }

              return userSearch;
            },
          },
        })
        .state('administration.orgAccess', {
          url: 'orgAccess',
          template: '<cf-list-org-access organizations="$resolve.orgs" user="$resolve.user"></cf-list-org-access>',
          resolve: {
            orgs: ($http, user, $q, organizations) =>
              $http.get('organizations/' + user.orgContext.id + '/memberOrgs').then((result) => {
                return $q.all(
                  _.mapValues(result.data, (val, id) => {
                    return organizations.getName(id).then((name) => {
                      val.name = name;
                      return val;
                    });
                  })
                );
              }),
          },
        })
        .state('administration.roles', {
          url: '/roles',
          template: '<cf-roles user="$resolve.user"></cf-roles>',
        })
        .state('administration.foodCategories', {
          url: '/foodCategories',
          template:
            '<cf-food-categories user="$resolve.user" $food-categories="$resolve.$foodCategories" ' +
            'hazards-library="$resolve.hazardsLibrary" templates="$resolve.templates"></cf-food-categories>',
          resolve: {
            $foodCategories: (productCategories) => productCategories.$getAll(),
            hazardsLibrary: (hazardsService) => hazardsService.getHazardLibrary(),
            templates: (products, SAMPLE_ORGANIZATION_ID) => products.getPlanTemplates(SAMPLE_ORGANIZATION_ID),
          },
        })
        .state('administration.clients', {
          abstract: true,
          url: '/clients',
          template: '<div data-ui-view></div>',
        })
        .state('administration.clients.list', {
          url: '',
          template: '<cf-list-clients user="$resolve.user"></cf-list-clients>',
        })
        .state('administration.clients.client', {
          url: '/:organizationId',
          template: '<div ui-view></div>',
          resolve: {
            company: (organizations, $stateParams) => organizations.$get($stateParams.organizationId),
          },
        })
        .state('administration.clients.client.edit', {
          url: '/edit',
          template: '<cf-facility user="$resolve.user" company="$resolve.company" close-btn="true"></cf-facility>',
          resolve: {
            company: (organizations, $stateParams) => organizations.$get($stateParams.organizationId),
          },
        })
        .state('administration.clients.client.questionnaires', {
          url: '/questionnaires?type',
          template:
            '<cf-questionnaire user="$resolve.user" questionnaire="$resolve.questionnaire" ' +
            'type="$resolve.type" read-only="true"></cf-questionnaire>',
          resolve: {
            type: ($stateParams) => $stateParams.type,
            questionnaire: (questionnaireService, type, $stateParams) =>
              questionnaireService.getQuestionnaire($stateParams.organizationId, type),
          },
        })
        .state('administration.organizations', {
          abstract: true,
          url: '/organizations',
          template: '<div data-ui-view></div>',
        })
        .state('administration.organizations.list', {
          url: '',
          template: '<cf-list-organizations user="$resolve.user" plans="$resolve.plans"></cf-list-organizations>',
          resolve: {
            plans: (billing, user) => billing.getPlans(user.orgContext.id),
          },
        })
        .state('administration.organizations.edit', {
          url: '/:organizationId/edit',
          abstract: true,
          template: '<cf-organization organization="$resolve.organization"></cf-organization>',
          resolve: {
            organization: ($firebaseObject, fbutil, $stateParams) =>
              $firebaseObject(fbutil.ref('organizations', $stateParams.organizationId)).$loaded(),
          },
        })
        .state('administration.organizations.edit.overview', {
          url: '/overview',
          template:
            '<cf-organization-overview user="$resolve.user" organization="$resolve.organization" ' +
            'analyst-orgs="$resolve.analystOrgs" subscription-plans="$resolve.subscriptionPlans">' +
            '</cf-organization-overview>',
          resolve: {
            subscriptionPlans: (constantsService) =>
              constantsService.get('stripe').then((stripeConstants) => stripeConstants.subscriptionPlans),
          },
        })
        .state('administration.organizations.edit.users', {
          url: '/users',
          template:
            '<cf-organization-users user="$resolve.user" organization="$resolve.organization" ' +
            '</cf-organization-users>',
        })
        .state('administration.organizations.edit.address', {
          url: '/address',
          template:
            '<cf-organization-address user="$resolve.user" organization="$resolve.organization">' +
            '</cf-organization-address>',
        })
        .state('administration.organizations.edit.contact', {
          url: '/contact',
          template:
            '<cf-organization-contact user="$resolve.user" organization="$resolve.organization">' +
            '</cf-organization-contact>',
        })
        .state('administration.logs', {
          abstract: true,
          url: '/logs',
          template: '<div data-ui-view></div>',
        })
        .state('administration.logs.list', {
          url: '',
          template: '<fr-list-logs user="$resolve.user""></fr-list-logs>',
        });
      /*todo: remove me and remove the underlying components:
     .state('administration.teams', {
      url: '/teams',
      template: '<div cf-list-teams data-teams="vm.teams"></div>',
      controller: function(teams) {
        this.teams = teams;
      },
      controllerAs: 'vm',
      resolve: {
        teams: function(fbutil) {
          return fbutil.$firebaseArrayExt(fbutil.ref('teams')).$loaded();
        }
      },
      data: {
        titleBar: {
          title: 'Teams',
          breadcrumbs: [
            {name: 'Home', state: 'user.dashboard'},
            {name: 'Administration', state: 'administration.users.list'},
            {name: 'Teams', active: true}
          ]
        }
      }
    }).state('administration.team', {
      url: '/teams/:teamId',
      template: '<div data-ui-view=""></div>',
      abstract: true,
      controller: function(team) {
        this.team = team;
      },
      controllerAs: 'vm',
      resolve: {
        team: function($stateParams, fbutil, $firebaseObject) {
          return $firebaseObject(fbutil.ref('teams', $stateParams.teamId)).$loaded();
        }
      }
    }).state('administration.team.edit', {
      url: '/edit',
      template: '<div cf-edit-team data-team="vm.team" data-team-analysts="vm.teamAnalysts"></div>',
      controller: function(team, teamAnalysts) {
        this.team = team;
        this.teamAnalysts = teamAnalysts;
      },
      controllerAs: 'vm',
      resolve: {
        teamAnalysts: function(team, fbutil, $firebaseObject) {
          return _.mapValues(team.analysts, function(analyst, analystId) {
            return $firebaseObject(fbutil.ref('users', analystId));
          });
        }
      },
      data: {
        titleBar: {
          title: 'Edit Team',
          breadcrumbs: [
            {name: 'Home', state: 'user.dashboard'},
            {name: 'Administration', state: 'administration.users.list'},
            {name: 'Teams', state: 'administration.teams'},
            {name: 'Edit', active: true}
          ]
        }
      }
    }).state('administration.team.analysts', {
      url: '/analysts',
      template: '<cf-edit-team-analysts all-analysts="vm.allAnalysts" team="vm.team"></cf-edit-team-analysts>',
      controller: function(allAnalysts, team) {
        this.allAnalysts = allAnalysts;
        this.team = team;
      },
      controllerAs: 'vm',
      resolve: {
        allAnalysts: function(fbutil, UsersSearch, user) {
          var analystSearch = new UsersSearch(user, {});

          analystSearch.setRoleFilter('analyst');
          return analystSearch;
        },
        search: function(allAnalysts) {
          return allAnalysts.search();
        }
      }
    }).state('administration.team.orgs', {
      url: '/orgs',
      template: '<cf-edit-team-orgs organizations="$resolve.organizations" team="$resolve.team" ' +
      'teams="$resolve.teams" user="$resolve.user"></cf-edit-team-orgs>',
      resolve: {
        organizations: function(fbutil, $firebaseArray) {
          return $firebaseArray(fbutil.ref('organizations')).$loaded();
        },
        teams: (organizations, user) => organizations.getAnalystOrgs(user)
      }
    })*/
    })
    .run(function (menus, authorization) {
      'ngInject';

      /*    menus.addMenuItem('dashboard', {
      title: 'Admin',
      state: 'administration.users',
      type: 'dropdown',
      position: 6,
      isPublic: false,
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP],
      claims: [authorization.claims.ADMIN]
    });*/

      menus.addSubMenuItem('dashboard', 'administration.users', {
        title: 'Company',
        state: 'company.overview',
        icon: 'fa fa-building',
        position: 1,
      });

      menus.addSubMenuItem('dashboard', 'administration.users', {
        title: 'Users',
        state: 'administration.users.list',
        icon: 'fa fa-user',
        position: 2,
      });

      menus.addSubMenuItem('dashboard', 'administration.users', {
        title: 'Safety Team',
        state: 'company.safetyTeam',
        icon: 'fa fa-life-ring',
        position: 3,
      });

      menus.addSubMenuItem('dashboard', 'administration.users', {
        title: 'Docs & Images',
        state: 'company.upload',
        icon: 'far fa-copy',
        position: 4,
      });

      menus.addSubMenuItem('dashboard', 'administration.users', {
        title: 'External Org Permissions',
        icon: 'fa fa-lock',
        state: 'administration.orgAccess',
        position: 5,
      });

      menus.addSubMenuItem('dashboard', 'administration.users', {
        title: 'Organizations',
        state: 'administration.organizations.list',
        claims: [authorization.claims.CF_ADMIN],
        position: 7,
      });

      menus.addSubMenuItem('dashboard', 'administration.users', {
        title: 'Reindex Elastic Search',
        state: 'administration.reindexElasticSearch',
        claims: [authorization.claims.CF_ADMIN],
        position: 8,
      });
    });
};
