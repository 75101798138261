module.exports = function(ngModule) {
  ngModule.component('frOperationalChecklistEdit', {
    bindings: {
      user: '<',
      company: '<',
      checklist: '<'
    },
    template: require('./checklists.operational.edit.template.html'),
    controller: require('./checklists.operational.edit.controller.js'),
    controllerAs: 'vm'
  });
};
