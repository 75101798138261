module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseObject, $uibModal, $q, $log, SopLogsSearch, utils, products) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseObject = $firebaseObject;
      this.$uibModal = $uibModal;
      this.$q = $q;
      this.$log = $log;
      this.SopLogsSearch = SopLogsSearch;
      this.utils = utils;
      this.products = products;
    }

    /**
     * Get a sopLog.
     * @param {string} id The SopLogs ID
     * @return {Promise} A promise that resolves to a $firebaseObject
     */
    $get(id) {
      return this.$firebaseObject(this.fbutil.ref('sopLogs', id)).$loaded();
    }

    /**
     * Soft delete a SopLogs.
     * @param {string} id The SopLogs ID
     * @return {Promise} A promise that resolves when the sopLogs is removed
     */
    delete(id) {
      return this.fbutil.ref('sopLogs', id, 'deleted').set(true);
    }

    /**
     * Get all sopLogs for a given organization.
     * @param {string} orgId The owning org's id
     * @param {string} searchText The query text
     * @param {object} opts Additional search options
     * @param {string} opts.batchId Limit to logs for this batch
     * @param {string} opts.sopId Limit to logs for this SOP
     * @param {boolean} opts.noBatches Limit to logs without a batch id
     * @param {number} from From index
     * @param {number} size Number of results to return
     * @returns {Promise} A promise that resolves to a sopLogs array
     */
    query(orgId, searchText, opts, from = 0, size = 100) {
      let sopLogsSearch = new this.SopLogsSearch(orgId);

      sopLogsSearch = sopLogsSearch.startFrom(from).size(size);

      opts = opts || {};
      if (opts.batchId) {
        sopLogsSearch = sopLogsSearch.batchId(opts.batchId);
      }
      if (opts.noBatches) {
        sopLogsSearch = sopLogsSearch.noBatches();
      }
      if (opts.sopId) {
        sopLogsSearch = sopLogsSearch.sopId(opts.sopId);
      }

      return sopLogsSearch.search(searchText);
    }

    $push() {
      return this.$firebaseObject(this.fbutil.ref('sopLogs').push()).$loaded();
    }

    approve(user, logId) {
      return this.$get(logId).then($sopLog => {
        $sopLog.approvedOn = new Date().getTime();
        $sopLog.approvedBy = user.uid;
        $sopLog.approvedByName = user.fullName();
        return $sopLog.$save().finally(() => $sopLog.$destroy());
      });
    }
  }

  ngModule.service('sopLogsService', Service);
};
