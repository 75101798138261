module.exports = function(ngModule) {
  ngModule.factory('S3', function($http, Upload, $q, $window, $state, S3_URL, utils) {
    'ngInject';

    function cleanKey(key) {
      return key.replace(/[%#]/g, '');
    }

    let getPolicy = function(file) {
        return $http.get('/s3/uploadPolicy?mimeType=' + file.type);
      },
      uploadToS3 = function(policyUri, key, acl, file, onFileProcessed) {
        return $http.get(policyUri)
          .then((policy) => {
            return Upload.upload({
              url: S3_URL,
              method: 'POST',
              data: {
                key: cleanKey(key),
                acl: acl,
                'Content-Type': file.type,
                AWSAccessKeyId: policy.data.AWSAccessKeyId,
                'success_action_status': '201',
                Policy: policy.data.s3Policy,
                Signature: policy.data.s3Signature,
                file: file
              }
            });
          })
          .then((response) => {
            const key = _.get(response, 'config.data.key');

            return {
              url: _.get(response, 'config.url'),
              key,
              name: key ? key.substring(key.lastIndexOf('/') + 1, key.length) : undefined,
              size: _.get(response, 'config.data.file.size'),
              type: _.get(response, 'config.data.file.type')
            };
          }, $q.reject, onFileProcessed);
      },
      escapeKey = function(key) {
        return key.replace(/\//g, '%2F');
      };

    return {
      getPolicy: getPolicy,
      generateRandomKey: function(folder, fileSuffix) {
        return folder + '/' + Math.round(Math.random() * 10000) + '$' + fileSuffix;
      },
      deleteFile: function(ownerId, key, ownerType) {
        ownerType = ownerType || 'organization';
        return $http.delete('/s3/' + ownerType + '/' + ownerId + '/' + escapeKey(key));
      },
      getSignedUrl: function(orgId, key) {
        return $http.get('/s3/organization/' + orgId + '/' + escapeKey(key) + '/link')
          .then((response) => response.data);
      },
      getSignedUrlForProductFile: function(orgId, productId, key) {
        return $http.get(`/s3/organization/${orgId}/product/${productId}/${escapeKey(key)}/link`)
          .then((response) => response.data);
      },
      getSignedUrlForProjectFile: function(orgId, projectId, key) {
        return $http.get(`/s3/organization/${orgId}/project/${projectId}/${escapeKey(key)}/link`)
          .then((response) => response.data);
      },
      openSignedUrlInNewTab: function(orgId, key, loadingUri, productId, projectId) {
        let fileWindow = $window.open(loadingUri || '/api/s3/loading');
        let getUrlPromise;

        if(projectId) {
          getUrlPromise = this.getSignedUrlForProjectFile(orgId, projectId, key);
        } else {
          getUrlPromise = productId ? this.getSignedUrlForProductFile(orgId, productId, key) :
            this.getSignedUrl(orgId, key);
        }
        getUrlPromise
          .then((result) => {
            try {
              fileWindow.location.replace(result);
            } catch (err) {
              return $q.reject(err);
            }
          })
          .catch(err => {
            utils.defaultErrorHandler(err, 'An error occurred loading your file.');
            fileWindow && fileWindow.close();
            throw err;
          });
      },
      downloadPdf: function(orgId, key, productId) {
        let getUrlPromise = productId ? this.getSignedUrlForProductFile(orgId, productId, key) :
          this.getSignedUrl(orgId, key);

        getUrlPromise
          .then((result) => {
            $window.location.href = result;
          })
          .catch(err => {
            utils.defaultErrorHandler(err, 'An error occurred loading your file.');
            throw err;
          });
      },
      getPublicFileUrl: function(key) {
        return S3_URL + '/' + (_.startsWith(key, '/') ? _.drop(key) : key);
      },
      uploadOrgFile: function(orgId, uploadPath, file, onFileProcessed) {
        uploadPath = _.endsWith(uploadPath, '/') ? _.dropRight(uploadPath) : uploadPath;

        let key = 'organizations/' + orgId + '/' + uploadPath,
          policyUri = '/s3/organization/' + orgId + '/uploadPolicy?mimeType=' + file.type + '&acl=private';

        return uploadToS3(policyUri, key, 'private', file, onFileProcessed);
      },
      uploadPublicFile: function(uploadPath, file, onFileProcessed) {
        uploadPath = _.endsWith(uploadPath, '/') ? _.dropRight(uploadPath) : uploadPath;

        let key = 'public/' + uploadPath,
          policyUri = '/s3/public/uploadPolicy?mimeType=' + file.type + '&acl=public-read';

        return uploadToS3(policyUri, key, 'public-read', file, onFileProcessed);
      },
      parseKey: function(key) {
        let parts = key.split('/');
        let retObj = {};

        if (parts.length > 2 && parts[0] === 'organizations') {
          retObj.organizationId = parts[1];
        }
        if (parts.length > 4 && parts[2] === 'products') {
          retObj.productId = parts[3];
        }
        if (parts.length > 6 && parts[4] === 'externalFiles') {
          retObj.externalFiles = parts[5];
        }
        return retObj;
      },

      copyFile(sourceOrg, sourceKey, destKey) {
        return $http.post(`/s3/organization/${sourceOrg}/${escapeKey(sourceKey)}/copy?newKey=${escapeKey(destKey)}`);
      }
    };
  });
};
