module.exports = function(ngModule) {
  ngModule.component('cfSopLogEditFormContent', {
    bindings: {
      user: '<',
      $sopLog: '<',
      $sopLogs: '<',
      $sop: '<',
      logForm: '<'
    },
    template: require('./operations.sop-logs.edit.form.form-content.template.html'),
    controller: require('./operations.sop-logs.edit.form.form-content.controller'),
    controllerAs: 'vm'
  });
};
