'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfPurchasePlan', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./purchase-plan.template.html'),
    controller: require('./purchase-plan.controller.js'),
    controllerAs: 'vm'
  });
};