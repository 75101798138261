require('./operations.sop-logs.types.temperature.scss');

module.exports = function(ngModule) {
  ngModule.component('cfLogTypeTemperature', {
    bindings: {
      user: '<',
      $sop: '<',
      $sopLog: '='
    },
    template: require('./operations.sop-logs.types.temperature.template.html'),
    controller: require('./operations.sop-logs.types.temperature.controller'),
    controllerAs: 'vm'
  });
};
