'use strict';

module.exports = function(ngModule) {
  ngModule.factory('marketing', function($http, $q, organizations, $log) {
    'ngInject';

    const baseUri = 'marketing',
      supplierInvite = 'Supplier Invite',
      gettingStarted = 'Getting Started',
      subscriber = 'Subscriber',
      supplier = 'Supplier',
      subscriberFree = 'Subscriber-Free',
      symposium = 'symposium-2017',
      SubscriberStandard = 'Subscriber-Standard',
      SubscriberPremium = 'Subscriber-Premium',
      trialStandard = 'Trial-Standard',
      trialPremium = 'Trial-Premium',
      MobileSignup = 'mobile-signup';

    return {
      /**
       * The campaign names available to CF Reviews.
       */
      campaignNames: {
        SUPPLIER_INVITE: supplierInvite,
        GETTING_STARTED: gettingStarted
      },

      /**
       * The campaign names available to CF Reviews.
       */
      tagNames: {
        SUBSCRIBER: subscriber,
        SUPPLIER: supplier,
        SYMPOSIUM: symposium,
        SUBSCRIBER_FREE: subscriberFree,
        SUBSCRIBER_STANDARD: SubscriberStandard,
        SUBSCRIBER_PREMIUM: SubscriberPremium,
        MOBILE_SIGNUP: MobileSignup,
        TRIAL_STANDARD: trialStandard,
        TRIAL_PREMIUM: trialPremium
      },

      /**
       * Add the current user to the CF marketing system. Note: only CFAdmin's can specify a uid. If the user already
       * exists, the existing marketing record will be returned.
       * @param {string=} uid The <tt>UID</tt> of the CF user (CFAdmin only).
       * @param {string=} supplierInviteOrgId The <tt>$id</tt> of the inviting organization (e.g. distributor, etc.).
       * @returns {Promise} The resulting promise for the Add Contact operation.
       */
      addContact: function(uid, supplierInviteOrgId) {
        let params  = uid ? {uid: uid} : {}, orgPromise;

        if (supplierInviteOrgId) {
          orgPromise = organizations.getName(supplierInviteOrgId);
        } else {
          orgPromise = $q.resolve();
        }

        return orgPromise.then((orgName) => {
          if (orgName) {
            params.customFields = [{name: 'Supplier Invite Source Company', value: orgName}];
          }
          let uri = '/contacts';

          if (uid) {
            uri += '/contacts?uid=' + uid;
          }
          return $http.get(baseUri + uri)
            .then((result) => {
              if (result.data.length) {
                if (result.data.length !== 1) {
                  throw new Error('Multiple marketing contacts found for user.');
                }
                $log.info('Attempted to add a new marketing contact but found an existing one.');
                return _.first(result.data);
              }
              return $http.post(baseUri + '/contacts', params)
                .then((result) => {
                  $log.info('Added new marketing contact.');
                  return result.data;
                });
            });
        });
      },

      /**
       * Update the current user in the marketing system. Note: only CFAdmin's can specify a uid. If the user already
       * exists, the existing marketing record will be returned.
       * @param {string=} uid The <tt>UID</tt> of the CF user (CFAdmin only).
       * @returns {Promise} The resulting promise for the Update Contact operation.
       */
      updateContact: function(uid) {
        let params  = uid ? {uid: uid} : {};

        return $http.put(baseUri + '/contacts', params)
          .then((result) => {
            $log.info('Updated marketing contact.');
            return result.data;
          });
      },

      /**
       * Start a marketing campaign for the current CF user. Note: only CFAdmin's can specify a uid.
       * @param {string} campaignName The name of the marketing campaign.
       * @param {string=} uid The <tt>UID</tt> of the CF user (CFAdmin only).
       * @returns {Promise} The resulting promise for the Start Campaign operation.
       */
      startCampaign: function(campaignName, uid) {
        let params = {campaignName: campaignName};

        if (uid) { params.uid = uid; }

        return $http.post(baseUri + '/campaign', params)
          .then((result) => result.data);
      },

      /**
       * Start a marketing campaign for the current CF user. Note: only CFAdmin's can specify a uid.
       * @param {Object|Object[]} tags A tag or an array of tags.
       * @param {string=} uid The <tt>UID</tt> of the CF user (CFAdmin only).
       * @example
       * addTags([{name: 'Subscriber'}, {name: 'Subscriber-Free'}]);
       * @returns {Promise} The resulting promise for the Add Tags operation.
       */
      addTags: function(tags, uid) {
        let params = {tags: _.isArray(tags) ? tags : [tags]};

        if (uid) { params.uid = uid; }

        return $http.post(baseUri + '/tags', params)
          .then((result) => {
            $log.info('Successfully added marketing tags: ', {tags: tags});
            return result.data;
          });
      },

      /**
       * Stop a marketing campaign for the current CF user. Note: only CFAdmin's can specify a uid.
       * @param {Object|Object[]} tags A tag or an array of tags.
       * @param {string=} uid The <tt>UID</tt> of the CF user (CFAdmin only).
       * @example
       * removeTags([{name: 'Subscriber'}, {name: 'Subscriber-Free'}]);
       * @returns {Promise} The resulting promise for the Add Tags operation.
       */
      removeTags: function(tags, uid) {
        let params = {tags: _.isArray(tags) ? tags : [tags]};

        if (uid) { params.uid = uid; }

        return $http({
          url: baseUri + '/tags',
          method: 'DELETE',
          data: params,
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        }).then((result) => {
          $log.info('Successfully removed marketing tags: ', {tags: tags});
          return result.data;
        });
      }
    };
  });
};
