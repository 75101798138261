module.exports = function(ngModule) {
  ngModule.component('cfFileStatusIndicator', {
    bindings: {
      requestFilesTokens: '<',
      onEmailStatusFailed: '&?'
    },
    template: require('./suppliers.file-status-indicator.template.html'),
    controller: require('./suppliers.file-status-indicator.controller'),
    controllerAs: 'vm'
  });
};