module.exports = function(ngModule) {
  ngModule.component('cfPlanTemplates', {
    bindings: {
      templates: '=',
      user: '<'
    },
    template: require('./users.settings.plan-templates.template.html'),
    controller: require('./users.settings.plan-templates.controller.js'),
    controllerAs: 'vm'
  });
};
