module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations', {
        url: '/operations',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          company: (user, organizations) => organizations.$get(user.orgContext.id)
        },
        data: {menuId: 'dashboard'}
      });
  }).run(function(menus, orgPerspectives, authorization) {
    'ngInject';

    menus.addMenuItem('dashboard', {
      title: 'Operation',
      state: 'operations',
      type: 'dropdown',
      position: 6,
      isPublic: false,
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP],
      claims: [authorization.claims.LOG_READ, authorization.claims.SOP_READ]
    });

    menus.addSubMenuItem('dashboard', 'operations', {
      title: 'Procedures (SOPs)',
      redirectPath: '/operations/sops',
      icon: 'fa fa-clipboard-list',
      position: 1
    });

    menus.addSubMenuItem('dashboard', 'operations', {
      title: 'Facility Setup',
      redirectPath: '/operations/facility',
      icon: 'fa fa-building',
      position: 2
    });

    menus.addSubMenuItem('dashboard', 'operations', {
      title: 'Environmental Monitoring',
      redirectPath: '/environmental-monitoring',
      icon: 'fa fa-flask',
      position: 3
    });

    menus.addSubMenuItem('dashboard', 'operations', {
      title: 'File Manager',
      redirectPath: '/file-manager',
      icon: 'fa fa-folder-open',
      position: 4
    });

    menus.addSubMenuItem('dashboard', 'operations', {
      title: 'SOP Library (Admin)',
      redirectPath: '/operations/sopLibrary',
      icon: 'fa fa-books',
      shouldRender: function(user) {
        return user.hasPermission(authorization.claims.CF_ADMIN) || user.isFrSopLibAccess();
      },
      position: 5
    });
  });
};
