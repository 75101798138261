class Controller {
  constructor($scope, $log, utils, growl) {
    'ngInject';

    this.$scope = $scope;
    this.$log = $log;
    this.growl = growl;
  }

  $onInit() {
    this.user = this.user || {};

    this.$scope.$watch('vm.pwStrength', val => {
      switch (_.round(val / 33)) {
      case 0:
      case 1:
        this.pwStrengthExt = {description: 'Weak', css: 'text-danger', icon: 'fa-ban'};
        return;
      case 2:
        this.pwStrengthExt =  {description: 'Ok', css: 'text-warning', icon: 'fa-exclamation-triangle'};
        return;
      case 3:
        this.pwStrengthExt =  {description: 'Good!', css: 'text-success', icon: 'fa-check'};
        return;
      default:
        this.pwStrengthExt = null;
      }
    });
  }
}

module.exports = Controller;
