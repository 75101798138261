class Controller {
  constructor(drift) {
    'ngInject';

    this.drift = drift;
  }

  scheduleMeeting() {
    this.drift.openSidebar();
  }
}
module.exports = Controller;
