module.exports = function(ngModule) {
  // firebase
  ngModule.constant('FB_AUTH_DOMAIN', process.env.FB_AUTH_DOMAIN);
  ngModule.constant('FB_URL', process.env.FB_URL);
  ngModule.constant('FB_API', process.env.FB_API_KEY);

  // elastic search
  ngModule.constant('ES_URL', process.env.ELASTIC_SEARCH_URL);
  ngModule.constant('ES_PORT', 9243);
  ngModule.constant('ES_INDICES', {
    logs: 'fr-dev-logs',
    verifications: 'fr-dev-verifications',
    notifications: 'fr-dev-notifications',
    ingredients: 'fr-dev-ingredients',
    //messages: 'fr-dev-messages',
    orders: 'fr-dev-orders',
    users: 'fr-dev-users',
    rfqs: 'fr-dev-rfqs',
    sops: 'fr-dev-sops',
    equipment: 'fr-dev-equipment',
    batches: 'fr-dev-batches',
    sopLogs: 'fr-dev-sop-logs',
    hazards: 'fr-dev-hazards',
    leads: 'fr-dev-leads',
    sopLibraryItems: 'fr-dev-sop-library-items',
    suppliers: 'fr-dev-suppliers',
    supplierIngredients: 'fr-dev-supplier-ingredients',
    productGroups: 'fr-dev-product-groups',
    organizations: 'fr-dev-organizations',
    products: 'fr-dev-products',
    recallEvents: 'fr-dev-recall-events',
    foodProducts: 'fr-dev-food-products',
    checklists: 'fr-dev-checklists',
    checklistTemplates: 'fr-dev-checklist-templates',
    batchLogs: 'fr-dev-batch-logs'
  });

  // ***********************************
  // * STRIPE
  // ***********************************
  ngModule.constant('STRIPE_PUBLIC_KEY', process.env.STRIPE_PUBLIC_KEY);
  ngModule.constant('CANCEL_SUBSCRIPTION_PROMO', 'cancel_discount');

  // ***********************************

  // S3
  ngModule.constant('S3_URL', process.env.S3_URL);

  // foodready specifics
  ngModule.constant('SAMPLE_PRODUCT_ID', '-KW8I3efguaHfrgMzO-s');
  ngModule.constant('SAMPLE_ORGANIZATION_ID', '-KW8H-kEhaaBaQmu62rR');
  ngModule.constant('CF_ORGANIZATION_ID', '-JZAI1vKy_QQIZkiQM0x');
  ngModule.constant('CF_CHANNEL_ID', 'CFChannel');
  ngModule.constant('ENV', process.env.NODE_ENV);
  ngModule.constant('FR_SUPPORT_PHONE', '(844)-596-0962');

  // idle timeout (30 minutes)
  ngModule.constant('IDLE_DURATION', 30 * 60);
  ngModule.constant('IDLE_TIMEOUT', 5);

  // supplier/client invite expiration
  ngModule.constant('INVITE_EXPIRE_DAYS', 5);

  // Google recaptcha
  ngModule.constant('CAPTCHA_KEY', process.env.CAPTCHA_KEY);

  // GoJs License Key (not valid in staging)
  ngModule.constant('GO_LICENSE', null);

  // Fake org to hold staged files from the file upload portal
  ngModule.constant('FILE_PORTAL_ORG', require('../../../config/constants/miscConstants').UPLOAD_PORTAL_DUMMY_ORG);
  // ngModule.constant('GTAG_ID', null);
  ngModule.constant('GAD_ID', null);

  ngModule.constant('UETTAG_ID', null);
  ngModule.constant('GTM_ID', null);

  // LinkedIn Insight Tag
  ngModule.constant('LINKEDIN_PARTNER_ID', null);

  // Profitwell Public Key
  ngModule.constant('PROFITWELL_PUBLIC_KEY', null);
  ngModule.constant('V2_URL', process.env.V2_URL);
  ngModule.constant('LOCAL_DEVELOPMENT', process.env.LOCAL_DEVELOPMENT);
};
