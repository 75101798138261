exports.CF_ADMIN = 'cfAdmin';
exports.SERVICE_PROVIDER = 'serviceProvider'; // Important: This claim states the user can be assigned to a client as
// their service provider. These individuals will be granted permission
// to the client organization.
exports.ADMIN = 'admin';
exports.ADMIN_REPORTS_READ = 'adminReportsRead';
exports.ALL_TEAMS_WRITE = 'allTeamsWrite';
exports.BILLING_READ = 'billingRead';
exports.BATCH_READ = 'batchRead';
exports.BATCH_WRITE = 'batchWrite';
exports.BILLING_WRITE = 'billingWrite';
exports.BULK_IMPORT = 'bulkImport';
exports.FILES_READ = 'filesRead';
exports.FILES_WRITE = 'filesWrite';
exports.GRANT_LINK_REQUESTS = 'grantLinkRequests';
exports.INGREDIENT_READ = 'ingredientRead';
exports.INGREDIENT_WRITE = 'ingredientWrite';
exports.LETTER_READ = 'letterRead';
exports.LETTER_WRITE = 'letterWrite';
exports.LIST_PRODUCT_NAMES = 'listProductNames';
exports.MESSAGE_READ = 'messageRead';
exports.MESSAGE_WRITE = 'messageWrite';
exports.NOTIFICATION_READ = 'notificationRead';
exports.NOTIFICATION_WRITE = 'notificationWrite';
exports.ORGANIZATION_READ = 'organizationRead';
exports.ORGANIZATION_REPORTS_READ = 'orgReportsRead';
exports.ORGANIZATION_WRITE = 'organizationWrite';
exports.PRIVATE_SEARCH_RESULTS = 'privateSearchResults';
exports.PRODUCT_READ = 'productRead';
exports.PRODUCT_WRITE = 'productWrite';
exports.PROJECT_READ = 'projectRead';
exports.PROJECT_WRITE = 'projectWrite';
exports.READ_PROFILE = 'readProfile';
exports.READ_FACILITIES = 'readFacilities';
exports.REPORTS_READ = 'reportsRead';
exports.REPORTS_WRITE = 'reportsWrite';
exports.SERVICES_READ = 'servicesRead';
exports.SERVICES_WRITE = 'servicesWrite';
exports.SOP_READ = 'sopRead';
exports.SOP_WRITE = 'sopWrite';
exports.SOP_LOGS_READ = 'sopLogRead';
exports.SOP_LOGS_WRITE = 'sopLogWrite';
exports.SOP_LOGS_APPROVE = 'sopLogApprove';
exports.SUPPLIER_READ = 'supplierRead';
exports.SUPPLIER_WRITE = 'supplierWrite';
exports.SWITCH_TO = 'switchTo';
exports.USERS_READ = 'usersRead';
exports.USERS_WRITE = 'usersWrite';
exports.USER_PROFILES_READ = 'userProfilesRead';
exports.VERIFICATION_READ = 'verificationRead';
exports.VERIFICATION_WRITE = 'verificationWrite';
exports.SOP_WRITE = 'sopWrite';
exports.SOP_READ = 'sopRead';
exports.TEMPLATE_READ = 'templateRead';
exports.LOG_WRITE = 'logWrite';
exports.LOG_READ = 'logRead';
exports.CORRECTIVE_ACTION_WRITE = 'correctiveActionWrite';
exports.CORRECTIVE_ACTION_APPROVE = 'correctiveActionApprove';
exports.SUPPLIER_FILE_REMINDERS = 'supplierFileReminders';
exports.QUESTIONNAIRES_READ = 'questionnairesRead';
exports.RECALL_READ = 'recallRead';
exports.RECALL_WRITE = 'recallWrite';
exports.INGREDIENT_LOG_READ = 'ingredientLogRead';
exports.INGREDIENT_LOG_WRITE = 'ingredientLogWrite';
exports.BATCH_RUN_READ = 'batchRunRead';
exports.BATCH_RUN_WRITE = 'batchRunWrite';
exports.CHECKLIST_READ = 'checklistRead';
exports.CHECKLIST_WRITE = 'checklistWrite';
exports.CHECKLIST_TEMPLATE_READ = 'checklistTemplateRead';
exports.CHECKLIST_TEMPLATE_WRITE = 'checklistTemplateWrite';
exports.LOCATION_READ = 'locationRead';
exports.LOCATION_WRITE = 'locationWrite';
exports.LOCATION_DELETE = 'locationDelete';
exports.EQUIPMENT_READ = 'equipmentRead';
exports.EQUIPMENT_WRITE = 'equipmentWrite';
exports.EQUIPMENT_DELETE = 'equipmentDelete';
exports.AREA_READ = 'areaRead';
exports.AREA_DELETE = 'areaDelete';
exports.AREA_WRITE = 'areaWrite';
exports.TEST_TEMPLATES_READ = 'testTemplatesRead';
exports.TEST_TEMPLATES_WRITE = 'testTemplatesWrite';
exports.ENVIRONMENT_TEST_READ = 'environmentTestsRead';
exports.ENVIRONMENT_TEST_WRITE = 'environmentTestsWrite';
exports.ENVIRONMENT_TEST_SAMPLE_READ = 'environmentTestSamplesRead';
exports.ENVIRONMENT_TEST_SAMPLE_WRITE = 'environmentTestSamplesWrite';
exports.HISTORY_READ = 'historyRead';
exports.HISTORY_DELETE = 'historyDelete';
exports.HISTORY_WRITE = 'historyWrite';
