class Constructor {
  constructor(gmps, utils, $state, confirmDeleteModal, growl, reportsService, subscriptionService,
              constantsService, $q) {
    'ngInject';

    this.gmps = gmps;
    this.utils = utils;
    this.$state = $state;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.reportsService = reportsService;
    this.subscriptionService = subscriptionService;
    this.constantsService = constantsService;
    this.$q = $q;

    this.PAGE_SIZE = 30;
  }

  $onInit() {
    this.audits = [];
    this.loadAudits();
  }

  loadAudits() {
    if (this.loading) { return; }
    this.loading = true;
    this.gmps.fetchAudits(this.user.orgContext.id, this.PAGE_SIZE, this.lastPageDate).then(audits => {
      this.endReached = _.isEmpty(audits);
      if (_.isEmpty(this.audits) && this.endReached) {
        this.noGmps = true;
      }
      if (this.endReached) { return; }

      _.each(audits, audit => {
        this.getAuditSummary(audit);
      });

      this.lastPageDate = _.last(audits).createdOn - 1;
      this.audits = _.orderBy(_.concat(this.audits, audits), ['createdOn'], ['desc']);
    }).catch(err => {
      this.utils.defaultErrorHandler(err, 'Unable to retrieve GMP audits');
      this.endReached = true; // Prevent looping
    }).finally(() => {
      this.loading = false;
    });
  }

  newGmpAudit() {
    let upgradePromise;

    if (this.audits.length && !this.user.doesSubscriptionPermit('multiGmps')) {
      upgradePromise = this.subscriptionService.promptForUpgrade(this.user, 'Multiple GMP Audits',
        this.constantsService.get('stripe').then(c => c.stripeProducts.STANDARD));
    }

    this.$q.when(upgradePromise).then(() => {
      this.gmps.push(this.user)
        .then(auditId => this.$state.go('checklists.gmps.edit.category', {auditId}))
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to add a new GMP audit.'));
    }).catch(err => {
      this.utils.defaultErrorHandler(err, 'Unable to upgrade user.');
    });

  }

  editGmpAudit(gmpAudit) {
    this.$state.go('checklists.gmps.edit.category', {auditId: gmpAudit.$id});
  }

  deleteGmpAudit(gmpAudit) {
    this.confirmDeleteModal('GMP Audit')
      .then(() => this.gmps.delete(this.user, gmpAudit.$id))
      .then(() => {
        _.remove(this.audits, g => g.$id === gmpAudit.$id);
        this.noGmps = _.isEmpty(this.audits);
        this.growl.success('GMP Audit Deleted');
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete GMP audit.'));
  }

  getAuditSummary(audit) {
    audit.stats = _.reduce(this.$gmps, (stats, category) => {
      _.each(category.sections, (section) => {
        _.each(section.questions, (question, questionKey) => {
          let orgQuestion = _.get(audit, `${category.$id}.questions.${questionKey}`);

          stats.totalQuestions++;

          if (orgQuestion) {
            let answer = _.parseInt(orgQuestion.answer);

            stats.answeredQuestions++;
            if (answer !== question.compliantAnswer && !orgQuestion.comment && answer !== -1) {
              stats.nonCompliantAnswers++;
            }
          }
        });
      });
      return stats;
    }, {
      totalQuestions: 0,
      answeredQuestions: 0,
      nonCompliantAnswers: 0
    });
  }

  fetchReport(reportType, auditId) {
    this.reportsService.fetchCompanyReport(this.user, {$id: this.user.orgContext.id},
      reportType, {auditId});
  }
}

module.exports = Constructor;
