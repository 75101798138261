module.exports = function(ngModule) {
  class UrlTokens {
    constructor($firebaseObject, fbutil, $http, $q) {
      'ngInject';

      this.$firebaseObject = $firebaseObject;
      this.fbutil = fbutil;
      this.$http = $http;
      this.$q = $q;
    }

    get(tokenId, accessKey) {
      return this.$http({
        method: 'get',
        url: `/urlTokens/${tokenId}`,
        headers: {
          AccessKey: accessKey
        }
      }).then(response => {
        if (response.status === 204) {
          return this.$q.reject(204);
        }
        return response.data;
      });
    }

    getFiles(tokenId) {
      return this.$http.get(`urlTokens/${tokenId}/files`).then(response => {
        if (response.status === 204) {
          return this.$q.reject(204);
        }
        return response.data;
      });
    }

    resendPortalEmail(tokenId) {
      return this.$http.post(`urlTokens/${tokenId}/resendPortalEmail`);
    }

    cancelPortal(tokenId) {
      return this.$http.delete(`urlTokens/${tokenId}`).then(response => {
        if (response.status === 204) {
          return this.$q.reject(204);
        }
        return response.data;
      });
    }

    /**
     * This is a deprecated method to get token contents. Going forward, the token is created and retrieved on the
     * server (due to its need of crypto.js to generate/validate an accessKey for better security).
     * @param {string} tokenId The token Id to retrieve
     * @returns {*} A promise that resolves to the token contents.
     */
    legacyGet(tokenId) {
      return this.fbutil.ref('urlTokens', tokenId).once('value')
        .then(tokenSnap => {
          if (!tokenSnap.exists()) { return null; }
          return _.assign({token: tokenId}, tokenSnap.val());
        });
    }

    saveState(tokenId, accessKey, body) {
      if (!body.state) { throw new Error('State is undefined and cannot be saved'); }
      return this.$http({
        method: 'put',
        url: `/urlTokens/${tokenId}/state`,
        headers: {
          AccessKey: accessKey
        },
        data: body.state
      }).then(response => response.data);
    }

    saveMetadata(tokenId, accessKey, prop, value) {
      return this.$http({
        method: 'put',
        url: `/urlTokens/${tokenId}/metadata/${prop}`,
        headers: {
          AccessKey: accessKey
        },
        data: value
      }).then(response => response.data);
    }

    submitAndClose(tokenId, accessKey, type) {
      return this.$http({
        method: 'post',
        url: `/urlTokens/${tokenId}/submitAndClose?type=${type}`,
        headers: {
          AccessKey: accessKey
        }
      }).then(response => response.data);
    }

    remove(tokenId) {
      return this.fbutil.ref('urlTokens', tokenId).remove();
    }

    $create(tokenData) {
      tokenData.key = Math.random().toString(36).slice(2);
      tokenData.legacy = true;  // Going forward, tokens should be created on the server.
      return this.fbutil.ref('urlTokens').push(tokenData)
        .then(tokenRef => this.$firebaseObject(tokenRef).$loaded());
    }
  }

  ngModule.service('urlTokens', UrlTokens);
};
