'use strict';

module.exports = function(ngModule) {
  ngModule.factory('IngredientSearch', function($timeout, Search) {
    'ngInject';

    class IngredientSearch extends Search {

      constructor(user, orgId, options) {
        options = options || {};
        super(user, {
          uri: 'search/ingredient',
          type: 'ingredient',
          max: options.size || 3000, // Maximum number of recs to pull back
          pageLimit: 30,    // Number of recs to show per page
          body: {},
          context: {
            organizationId: [orgId]
          }
        }, 'ingredients', false);
        this.orgId = orgId;
        this.search();
      }

      omitIds(idsArray) {
        this.idsToOmit = idsArray;

        return this;
      }

      search(text) {
        var body = {
          query: {
            bool: {
              must: [{
                term: {
                  organizationId: this.orgId
                }
              }]
            }
          }
        };

        if (text) {
          body.query.bool.should = [
            {
              'match_phrase_prefix': {
                name: {
                  query: text,
                  slop: 3,            // Allow terms to appear out of order by this many positions
                  'max_expansions': 20  // To improve performance, limit results of the final search term to this many
                }
              }
            },
            {
              term: {
                externalNumber: text
              }
            }
          ];

          body.query.bool['minimum_should_match'] = 1;
        } else {
          body.sort = [
            {externalNumber: {order: 'asc'}},
            {'name.keyword': {order: 'asc'}}
          ];
        }

        if (this.idsToOmit && this.idsToOmit.length) {
          body.query.bool['must_not'] = [{
            ids: {
              values: this.idsToOmit
            }
          }];
        }

        this.setSearchQuery(body);

        return super.search()
          .then(results => {
            // helper for view animations
            $timeout(() => {
              _.each(results, i => { i.initialized = true; });
            }, 1000);
            return results;
          });
      }

      /**
       * Wait for the initial search to finish and then return the search object.
       * @return {object} The search object
       */
      $initialized() {
        return this.searchPromise.then(() => this);
      }

      exactSearch(text) {
        if (!text) {
          throw new Error('Exact search requires search text');
        }
        let body = {
          '_source': false,
          query: {
            bool: {
              must: [{
                term: {
                  organizationId: this.orgId
                }
              },
                {
                  'match_phrase': {
                    name: _.lowerCase(text)
                  }
                }
              ]
            }
          }
        };

        this.setSearchQuery(body);

        return super.search();
      }
    }

    return IngredientSearch;
  });
};
