class Controller {
  constructor(utils) {
    'ngInject';

    this.utils = utils;
  }

  $onInit() {
    this.$report = this.resolve.$report;
  }

  save() {
    this.$report.$save()
      .then(() => this.modalInstance.close(this.$report))
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to save report.'));
  }
}

module.exports = Controller;
