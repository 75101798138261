class Constructor {
  constructor($log, orgPerspectives, growl) {
    'ngInject';

    this.$log = $log;
    this.orgPerspectives = orgPerspectives;
    this.growl = growl;
  }

  $onInit() {
  }

  save() {
    this.saving = true;

    this.organization.$save()
      .then(() => {
        this.growl.success('Organization general information saved successfully!');
      })
      .catch((err) => {
        this.$log.error('Unable to save organization general info.', this.$log.toString(err));
        this.growl.error('Unable to save organization general info. Please try ' +
          'again or contact FoodReady customer support.');
      });
  }
}

module.exports = Constructor;
