'use strict';

module.exports = function($log, growl, S3, $q) {
  'ngInject';

  const vm = this, ALREADY_EXISTS = 'File with this name already exists. Please choose another file ' +
    'or remove the existing file to continue.';

  function uploadFile(file) {
    let fileAlreadyExists = false;
    const uploadKey = _.endsWith(vm.uploadKey, '/') ? vm.uploadKey.slice(0, -1) : vm.uploadKey,
      uploadPath = vm.includeFilenameInUploadKey ? uploadKey + '/' + file.name : uploadKey;

    _.each(vm.existingFiles, (f) => { if (f.name === file.name) { fileAlreadyExists = true; } });

    if (fileAlreadyExists) {
      return $q.reject(ALREADY_EXISTS);
    }

    vm.uploading = true;
    return S3.uploadOrgFile(vm.orgId, uploadPath, file, (event) => {
      vm.uploadProgress = parseInt(100.0 * event.loaded / event.total, 10);
    });
  }

  vm.$onInit = function() {
    vm.title = vm.resolve.title;
    vm.file = vm.resolve.file;
    vm.existingFiles = vm.resolve.existingFiles;
    vm.orgId = vm.resolve.orgId;
    vm.includeFilenameInUploadKey = vm.resolve.includeFilenameInUploadKey;
    vm.uploadKey = vm.resolve.uploadKey;
    vm.alertHtml = vm.resolve.alertHtml;
    vm.alertClass = vm.resolve.alertClass;

    vm.isNewFile = vm.file.$value === null;

    vm.uploadProgress = 0;
  };

  vm.save = function(uploadedFile) {
    let uploadPromise = vm.isNewFile ? uploadFile(uploadedFile) : $q.when();

    uploadPromise
      .then(result => {
        _.assign(vm.file, result);
        return vm.file.$save();
      })
      .then(() => {
        vm.modalInstance.close(vm.file);
      })
      .catch((err) => {
        if (err === ALREADY_EXISTS) {
          growl.error(ALREADY_EXISTS);
          return;
        }
        vm.modalInstance.dismiss(err);
      });
  };
};
