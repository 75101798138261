module.exports = function(ngModule) {
  ngModule.component('cfSignup', {
    bindings: {
      tokenId: '<',
      tokenData: '=',
      companyName: '=',
      profileType: '<',
      orgType: '<',
      activateCapcha: '<'
    },
    template: require('./auth.signup.template.html'),
    controller: require('./auth.signup.controller.js'),
    controllerAs: 'vm'
  });

  require('./signup_form')(ngModule);
};
