module.exports = function($state, confirmDeleteModal, $uibModal, csvImportModal, growl, $log, fbutil, utils,
  authorization, supplierService, $q, $timeout, urlTokens, confirmModal, email,
  cfpLoadingBar, suppliers, messageServices, constantsService, subscriptionService,
  featureIntroModal, moment) {
  'ngInject';

  const vm = this;

  function search() {
    vm.searching = vm.loading = true;
    return vm.supplierSearch.search(vm.supplierSearch.searchText)
      .then(results => postSearch(results))
      .catch(err => {
        $log.error(err);
        growl.error('Error occurred during search.');
      })
      .finally(() => {
        vm.searching = vm.loading = false;
      });
  }

  function postSearch(results) {
    const currentDate = moment().valueOf();
    const nextMonth = moment(currentDate).add(1, 'M').valueOf();

    _.each(results, rec => {
      vm.ingredients[rec.$id] = vm.ingredients[rec.$id] || '';
    });
    if (vm.filesNeeded) {
      return vm.externalCompanyFileTypesPromise.then(externalCompanyFileTypes => {
        return $q.all(_.map(results, rec => {
          if (vm.missingFilesMap[rec.$id]) { return; }
          return supplierService.$getFiles(rec.$id).then($files => {
            let numFound = 0;
            let markup = _.reduce(vm.filesNeeded, (result, fileCat) => {
              let found = _.find($files, f => f.category === fileCat);
              let entry = _.find(externalCompanyFileTypes, f => f.id === fileCat);
              let name = entry ? entry.text : 'File Name Not Found';
              let message = '<span class="text-danger">Missing</span>';

              if(found) {
                if (currentDate < Number(found.expirationDate) &&  Number(found.expirationDate) < nextMonth) {
                  message = '<span class="text-warning">Expired Soon</span>';
                }else if(Number(found.expirationDate) < currentDate) {
                  message = '<span class="text-danger">Expired</span>';
                }else {
                  message = '<span class="text-success">Valid</span>';
                }
              }
              if (found) { numFound++; }
              return `${result}<li>${name} ${found ? `- ${found.name}` : '' } - ${message}</li>`;
            }, '<ol>') + '</ol>';

            vm.missingFilesMap[rec.$id] = {
              text: `${numFound}/${vm.filesNeeded.length}`,
              markup: markup,
              allFound: numFound === vm.filesNeeded.length,
            };
            $files.$destroy();
          });
        }));
      });
    }
  }

  function ingredientsMarkup(supplier) {
    return supplierService.getIngredients(supplier.owningOrg, supplier.$id)
      .then(ingredients => {
        return _.reduce(ingredients, (result, ingredient) => {
          return result + '<li>' + ingredient.brandName + '</li>';
        }, '<ol>') + '</ol>';
      });
  }

  vm.$onInit = function() {
    vm.isPremium = vm.user.doesSubscriptionPermit('supplierManagement');
    vm.$state = $state;
    vm.utils = utils;
    // With the ingredient import, we probably shouldn't have this supplier import.
    vm.importAccess = false;  //vm.user.hasPermission(authorization.claims.BULK_IMPORT);
    vm.supplierInvitationStatus = {};
    vm.noSuppliers = !vm.supplierSearch.total;
    vm.filesNeeded = _.get(vm.company, 'supplierTracking.companyFileCategories');
    vm.isTracking = !!vm.filesNeeded;
    vm.filesNeeded = vm.filesNeeded && _.map(vm.filesNeeded.split(','), _.toNumber);
    vm.missingFilesMap = {};
    vm.ingredients = {};
    vm.externalCompanyFileTypesPromise = constantsService.get('fileTypes').then(fileTypes => {
      return _.pickBy(fileTypes, fc => fc.companyLevel);
    });
    const stripeProductId = vm.user.subscription.plan.product;

    vm.supplierConstraint = _.get(this.productConstraints, stripeProductId + '.SUPPLIERS');
    if (_.isUndefined(vm.supplierConstraint)) {
      $log.warn(`Supplier constraints not defined for stripe product: ${stripeProductId}`);
      vm.supplierConstraint = -1; // Force check off
    }

    postSearch(vm.supplierSearch.searchResults);
    featureIntroModal(vm.user, {
      helpTitle: 'Add Your Suppliers and Ingredients',
      //helpTitle: 'GMPs & Prerequisite Programs',
      helpText: ' Supplier verification is required when a supplier controls a hazard that can materially affect ' +
        'the food’s safety (as determined through a Hazard Analysis).' +
        '<br><br>Enter your suppliers and their products in the <b>Suppliers</b> and <b>Ingredients</b> menus. Afterward, visit the <b>Supplier Management</b> menu to start a <i>Supplier File Tracking Program</i>. We will periodically send your suppliers a link to the <b>File Upload Portal</b>, an easy-to-use site where they can upload all of the documents belonging to your ingredients.',
      tipName: 'featureIntro_supplier',
      // helpVideoUrl: ''
    });
  };

  vm.uploadCsv = function() {
    csvImportModal({
      title: 'Import supplier CSV file',
      fields: {
        name: {required: true, description: 'Supplier name'},
        number: {description: 'Supplier Number'},

        contactEmail: {required: true, description: 'Contact Email'},
        contactName: {description: 'Supplier Contact Name'},
        contactTitle: {description: 'Supplier Contact Title'},
        contactPhone: {description: 'Contact Phone'},

        description: {description: 'Description'},
        supplierVetted: {description: 'Has the supplier been vetted? (yes/no)', type: 'yesNo'},

        physicalAddress: {description: 'Physical Address Line 1'},
        physicalAddress2: {description: 'Physical Address Line 2'},
        city: {description: 'Physical Address City'},
        state: {description: 'Physical Address State'},
        postalCode: {description: 'Physical Address Postal Code'},
        country: {description: 'Physical Address Country'},

        mailingAddressSameAsPhysical: {description: 'Mailing Same as Physical? (yes/no)', type: 'yesNo'},
        mailingAddress: {description: 'Mailing Address Line 1'},
        mailingAddress2: {description: 'Mailing Address Line 2'},
        mailingCity: {description: 'City'},
        mailingState: {description: 'State Code (e.g. IL)'},
        mailingPostalCode: {description: 'Mailing Postal Code'},
        mailingCountry: {description: 'Mailing Country'}
      }
    }).then(importedRecords => {
      return $uibModal.open({
        component: 'cfImportSuppliersModal',
        backdrop: 'static',
        resolve: {
          records: () => importedRecords,
          company: () => this.company,
          user: () => this.user
        }
      }).result;
    }).then(result => {
      if (result.errors === result.total) {
        growl.error('All import records failed.');
      } else if (result.errors) {
        growl.warning(result.total - result.errors + ' records imported. ' + result.errors + ' records failed.');
      } else {
        vm.supplierSearch.searchResults = vm.supplierSearch.searchResults || [];
        vm.supplierSearch.searchResults = _.concat([], result.newSuppliers, vm.supplierSearch.searchResults);
        growl.success(result.total + ' records imported.');
      }
    }).catch(function(reason) {
      if (utils.isModalDismissalByUser(reason)) { return; }

      growl.error('Error importing records.', {});
      $log.error(reason);
    });
  };

  vm.message = function(supplier) {
    messageServices.getOrgToOrgTopic(vm.user, supplier.organizationId).then(topicId => {
      this.$state.go('user.messages.message', {topicId: topicId});
    });
  };

  vm.remove = function(supplier, supplierId) {
    let opts = {body: 'Are you sure you want to permanently delete <i>' + supplier.name + '</i>?<br>' +
      '<b>ALL ingredients entered under this supplier will also be deleted. This cannot be undone.</b>'};

    if (vm.user.hasPermission(authorization.claims.SUPPLIER_WRITE && authorization.claims.INGREDIENT_WRITE)) {
      confirmDeleteModal(supplier.name, opts)
        .then(() => {
          cfpLoadingBar.start();
          supplierService.remove(vm.user, supplierId)
            .then(() => {
              $timeout(() => {
                _.remove(vm.supplierSearch.searchResults, s => s.$id === supplierId);
                vm.noSuppliers = !vm.supplierSearch.searchResults.length;
              });

              cfpLoadingBar.complete();
            })
            .catch(function(err) {
              $log.error('Error deleting supplier ' + supplierId + ' from ' + vm.company.$id + '. ' + err);
              cfpLoadingBar.complete();
            });
        });
    }
  };

  vm.help = function() {
    $uibModal.open({
      // template: require('../../views/modals/suppliers-help.modal.html'),
      controller: function($scope, $uibModalInstance) {
        $scope.ok = function() {
          $uibModalInstance.close();
        };
      }
    });
  };

  vm.openCommentWindow = function(supplier) {
    $uibModal.open({
      template: require('../../_modals/discussion/suppliers.discussion.template.html'),
      controller: require('../../_modals/discussion/suppliers.discussion.controller.js'),
      controllerAs: 'vm',
      size: 'lg',
      windowClass: 'scrolling-modal',
      backdrop: 'static',
      resolve: {
        supplier: function() {
          return supplier;
        },
        user: function() {
          return vm.user;
        }
      }
    }).result.catch(function(reason) {
      if (utils.isModalDismissalByUser(reason)) { return; }

      $log.error(reason);
    });
  };

  vm.getIngredientsMarkup = function(supplier) {
    if (_.isEmpty(vm.ingredients[supplier.$id])) {
      vm.ingredients[supplier.$id] = '<p class="text-muted text-center">Loading...</p>';
      ingredientsMarkup(supplier).then(markup => {
        $timeout(() => {
          vm.ingredients[supplier.$id] = markup;
        });
      });
    }
    return vm.ingredients[supplier.$id];
  };

  vm.searchKeyPress = function($event) {
    if ($event.keyCode === 13) {
      if (!vm.supplierSearch.searchText || vm.supplierSearch.searchText.length <= 3) {
        vm.search();
      }

      vm.search.flush();

      return;
    }

    if (utils.isBenignKeyUp($event.keyCode) ||
      vm.supplierSearch.searchText && vm.supplierSearch.searchText.length <= 3) { return; }

    vm.search();
  };

  vm.search = _.debounce(search, 300);

  vm.getMore = function() {
    vm.loading = true;
    $q.when(vm.supplierSearch.getMore())
      .then(results => postSearch(results))
      .finally(() => {
        vm.loading = false;
      });
  };

  vm.requestFiles = function(supplier) {
    if (!vm.company.supplierTracking) {
      confirmModal({
        title: 'No Supplier Management Program Defined',
        body: 'You need to <a href="" ng-click="vm.go(\'ingredients.fileTracking\')">define a supplier management program</a> before you can request files. Your program includes a list of the files you require from your suppliers.',
        okText: 'Ok',
        hideCancelButton: true
      });
      return;
    }
    suppliers.$get(supplier.$id).then($supplier => {
      if (!supplier.numIngredients) {
        confirmModal({
          title: 'No Ingredients Found',
          body: `You haven't entered any ingredients for supplier <b>${supplier.name}</b>. Go to the <a ng-click="vm.go('ingredients.list')" href="">ingredients tab</a> to add ingredients that belong to that supplier first.`,
          okText: 'Ok',
          hideCancelButton: true
        });
        return;
      }

      return $uibModal.open({
        component: 'cfRequestSupplierFiles',
        size: 'lg',
        resolve: {
          $supplier: $supplier,
          numIngredients: supplier.numIngredients
        }
      }).result.then(tokens => {
        supplier.requestFilesTokens = _.map(tokens, (token, id) => _.assign(token, {id}));
        $supplier.$destroy;
      }).catch(reason => {
        if (utils.isModalDismissalByUser(reason)) { return; }
        utils.defaultErrorHandler(reason, 'An error occurred sending the files request.');
      });
    });
  };

  vm.cancelRequestFiles = function(supplier) {
    confirmModal({
      title: 'Cancel Request for Files?',
      body: `Are you sure you want to cancel the outstanding request for files? It will void the link(s) sent to your 
      supplier contact(s).`,
      okText: 'Yes, Cancel Request',
      cancelText: 'No'
    }).then(() => {
      return supplierService.cancelFilesRequest(supplier);
    }).then(() => {
      growl.success('Files request successfully cancelled.');
      supplier.requestFilesTokens = undefined;
    }).catch(reason => {
      if (utils.isModalDismissalByUser(reason)) { return; }
      utils.defaultErrorHandler(reason, 'An error occurred sending the files request.');
    });
  };

  vm.dropSupMgmt = function(supplier) {
    supplierService.fileRequestOptOut(supplier.$id).then(() => {
      supplier.requestFilesOptOut = true;
      growl.success('Supplier dropped from supplier mgmt program.');
    }).catch(err =>
      utils.defaultErrorHandler(err, 'Could not drop supplier from tracking program.'));
  };

  vm.addSupMgmt = function(supplier) {
    supplierService.fileRequestOptIn(supplier.$id).then(() => {
      supplier.requestFilesOptOut = undefined;
      growl.success('Supplier added back to supplier mgmt program.');
    }).catch(err =>
      utils.defaultErrorHandler(err, 'Could not drop supplier from tracking program.'));
  };


  vm.checkConstraint = function() {
    if (vm.supplierConstraint === -1) { return $q.resolve(); }
    return supplierService.getCount(this.user.orgContext.id).then(count => {
      if (vm.supplierConstraint > count) { return $q.resolve(); }

      let title = `Your subscription only permits ${vm.supplierConstraint} suppliers.`;

      return subscriptionService.promptForUpgrade(this.user, title);
    });
  };

  vm.addSupplier = function() {
    this.checkConstraint().then(() => {
      $state.go('ingredients.suppliers.supplier.overview', {supplierId: 'new'});
    }).catch(err => utils.defaultErrorHandler(err, 'An error occurred checking your subscription status.'));
  };

  vm.incrementEmailCheckFailed = function(supplier, tokenId) {
    supplierService.incrementBadEmailResult(supplier, tokenId);
  };

  vm.bulkDelete = function() {
    return confirmDeleteModal('Bulk Suppliers', {
      body: 'Are you sure you want to bulk delete up to 30 suppliers' +
        ` for <strong>${vm.company.companyName}</strong>?`,
      confirmText: 'Yes, Bulk Delete'
    }).then(() => {
      cfpLoadingBar.start();
      vm.supplierSearch.search().then(suppliers => {
        return _.reduce(suppliers, (removePromises, supplier) => {
          return removePromises.then(() => supplierService.remove(vm.user, supplier.$id))
            .then(() => console.log(`Deleted ${supplier.name}`));
        }, $q.resolve()).then(() => growl.success(`Deleted ${suppliers.length} suppliers.`));
      }).then(() => $timeout(() => vm.search(), 2000))
        .catch(err => utils.defaultErrorHandler(err, 'Unable to bulk delete suppliers.'))
        .finally(() => cfpLoadingBar.complete());
    });
  };
};
