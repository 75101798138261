'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfSupplierIngredient', {
    bindings: {
      user: '<',
      ingredient: '<',
      hasReportAccess: '<',
      supplierIngredient: '<',
      externalFiles: '=?',
    },
    template: require('./ingredients.supplier-ingredient.template.html'),
    controller: require('./ingredients.supplier-ingredient.controller.js'),
    controllerAs: 'vm'
  });

  require('./overview')(ngModule);
  require('./files')(ngModule);
  require('./lots')(ngModule);
};
