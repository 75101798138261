class Controller {
  constructor(billing, growl, $log, $uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }
  $onInit() {
    this.processStepId = this.resolve.processStepId;
    this.procedureIdsArray = this.resolve.procedureIdsArray;
    this.procedureLogMap = this.resolve.procedureLogMap;
    this.logArray = this.resolve.logArray;

    this.allowAll = true;
    this.changedFlag = false;
    this.clonedItemsArray = [];
    _.each(this.procedureIdsArray, procId => {
      _.each(this.procedureLogMap[procId + this.processStepId].logs, procLogMap => {
        this.clonedItemsArray.push(Object.assign({}, procLogMap));
      });
    });

    this.noArrayItems = _.isEmpty(this.clonedItemsArray);
  }

  rowClicked(item) {
    item.checked = !item.checked;
    this.changedFlag = true;
  }

  isValid() {
    return _.findIndex(this.clonedItemsArray, (item) => item.checked) > -1;
  }

  changeFlag() {
    this.changedFlag = true;
  }

  chooseAllClick() {
    _.each(this.clonedItemsArray, item => {
      if(!item.checked) {
        item.checked = true;
        this.changedFlag = true;
      }
    });
  }

  filterNewLogArray() {
    let cloneItemSet = new Set();
    _.each(this.clonedItemsArray, item => {
      cloneItemSet.add(item.value);
    });

    return _.filter(this.logArray, log => !cloneItemSet.has(log.value));
  }

  addNewLogs() {
    this.logArray = this.filterNewLogArray();

    return this.$uibModal.open({
      component: 'cfChooseFromListModal',
      size: 'lg',
      resolve: {
        itemName: () => 'Log Forms',
        instructionsHtml: () => '<p>Select one or more Log forms you wish to add. </p> </br> ' +
          '<i><strong>Note:</strong> Adding new logs here will not add it Food Safety Plan</i> </br>',
        header: () => '<i class="far fa-drafting-compass fa-fw"></i> Add New Log',
        itemsArray: () => this.logArray,
        multiple: () => true,
        columns: () => [{
          title: 'Name',
          property: 'name'
        }]
      }
    }).result.then(result => {
      if (!_.isEmpty(result)){
        this.changedFlag = true;
        this.noArrayItems = false;
      }
      return _.map(result, item => {
        item['new'] = true
        this.clonedItemsArray.push(item);
      });
    });
  }

  cancelModal() {
    this.modalInstance.dismiss('cancel');
  }

  submit() {
    // this.modalInstance.close(_.filter(this.clonedItemsArray, (val, index) => this.clonedItemsArray[index].checked));
    this.modalInstance.close(this.clonedItemsArray);
  }
}

module.exports = Controller;
