class Constructor {
  constructor(UsersSearch) {
    'ngInject';

    this.UsersSearch = UsersSearch;

    this.orgUsers = [];
  }

  $onInit() {
    this.user = this.resolve.user;
    this.organizationId = this.resolve.organizationId;

    this.userSearch = new this.UsersSearch(this.user, {});
    this.userSearch.setOrg(this.organizationId);

    this.userSearch.search()
      .then((results) => {
        this.searchResults = results;
        this.orgUsers = _.map(this.searchResults, (result) => {
          return {
            name: `${result.firstName} ${result.lastName}`,
            value: result.$id
          };
        });
      });
  }

  ok(selectedUserId) {
    this.modalInstance.close(_.assign(_.find(this.searchResults, {$id: selectedUserId}), {uid: selectedUserId}));
  }
}

module.exports = Constructor;
