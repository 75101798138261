module.exports = function($log, growl, fbutil, S3, $q, constantsService, moment, organizations, $scope) {
  'ngInject';

  let vm = this, ALREADY_EXISTS = 'File with this name already exists. Please choose another file ' +
    'or remove the existing file to continue.';

  function saveNew(file) {
    let fileExtension = file.name.split('.').pop();

    let sameFileNameCount = 0;
    let fileName = vm.item.name;

    _.each(vm.media, (f) => {
      let s3FileName = f.key.split('/').pop();

      if (s3FileName.startsWith(fileName)) {
        sameFileNameCount += 1;
      }
    });

    fileName += sameFileNameCount > 0 ? sameFileNameCount.toString() + '.' + fileExtension : '.' + fileExtension;

    vm.uploading = true;
    return S3
      .uploadOrgFile(vm.organization.$id, vm.remoteDirectory + '/' + fileName,
        file, (event) => {
          vm.uploadProgress = parseInt(100.0 * event.loaded / event.total, 10);
        })
      .then((result) => {
        _.assign(vm.item, result);
        return vm.item.$save();
      });
  }

  vm.$onInit = function() {
    vm.item = vm.resolve.item;
    vm.media = vm.resolve.media;
    vm.product = vm.resolve.product;
    vm.organization = vm.resolve.organization;
    vm.categories = vm.resolve.categories;
    vm.warning = vm.resolve.warning;
    vm.askInPlan = vm.resolve.askInPlan;
    vm.acceptFilter = vm.resolve.acceptFilter;
    vm.remoteDirectory = vm.resolve.remoteDirectory;
    vm.stageFilesVerbiage = vm.resolve.stageFilesVerbiage;
    vm.item.category = vm.resolve.chosenCategory || vm.item.category || null;
    vm.user = vm.resolve.user;
    vm.expiryDate = vm.resolve.expiryDate;

    constantsService.get('fileTypes').then(fileTypes => {
      vm.externalFileTypes = _.omitBy(fileTypes, fc => fc.companyLevel);
      vm.externalCompanyFileTypes = _.pickBy(fileTypes, fc => fc.companyLevel);
      vm.fileTypesLoaded = true;
    });
    vm.dateOptions = {
      startingDay: 1
    };
    vm.altDateFormats = ['MM/dd/yyyy', 'MM/dd/yy'];
    vm.newItem = vm.item.$value === null;
    vm.expirationDate = vm.item.expirationDate ? new Date(vm.item.expirationDate) : null;
    vm.datePlaceholder = 'e.g. ' + moment(new Date()).format('MM-DD-YYYY');

    vm.uploadProgress = 0;

    if (vm.askInPlan) {
      $scope.$watch('vm.file', () => {
        vm.item.inPlan = false;
      });
    }
  };

  vm.cancel = function() {
    vm.modalInstance.dismiss('cancel');
  };

  vm.save = function(file) {
    vm.item.expirationDate = vm.expirationDate ? new Date(vm.expirationDate.getTime()).setHours(23, 59, 59, 999) : null;
    vm.item.name = vm.item.name || null;
    vm.item.description = vm.item.description || null;
    let dateProp = vm.newItem ? 'createdOn' : 'modifiedOn';

    if (vm.user) {
      vm.item.uid = vm.user.uid;
    }
    vm.item[dateProp] = new Date().getTime();
    if(file) {
      let reader = new FileReader();

      reader.readAsText(file);
      reader.onload = (event) => {
        if (event.target.result.indexOf('/Encrypt') !== -1 && file.type === 'application/pdf') {
          growl.error('File is Encrypted');
          return;
        } else {
          let promise = saveNew(file);
          vm.savePromise(promise);
        }
      };
    } else {
      let promise = vm.item.$save();

      vm.savePromise(promise);
    }
  };

  vm.savePromise = function(promise) {
    promise.then(() => {
      if (vm.item.category === vm.externalFileTypes.RECALL_PLAN.id) {
        organizations.setMilestoneAchieved(vm.organization.$id, organizations.milestones.RECALL_PLAN);
      } else if (vm.externalCompanyFileTypes.GMP && vm.item.category === vm.externalCompanyFileTypes.GMP.id) {
        organizations.setMilestoneAchieved(vm.organization.$id, organizations.milestones.GMP);
      }
      vm.modalInstance.close(vm.item);
    }).catch((err) => {
      if (err === ALREADY_EXISTS) {
        growl.error(ALREADY_EXISTS);
        return;
      }
      vm.modalInstance.dismiss(err);
    });
  };

  vm.isSafetyPlan = function(fileId) {
    return _.find(vm.externalFileTypes, ft => ft.id === fileId) === vm.externalFileTypes.SAFETY_PLAN;
  };
};
