module.exports = function(ngModule) {
  require('./list_organizations')(ngModule);
  require('./list_clients')(ngModule);
  require('./edit_team_analysts')(ngModule);
  require('./organization')(ngModule);
  require('./list_org_access')(ngModule);
  require('./roles')(ngModule);
  require('./food_categories')(ngModule);
  require('./list_logs')(ngModule);
};
