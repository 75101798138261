module.exports = function(ngModule) {
  ngModule.component('cfSelectRfqUser', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./select-rfq-user.template.html'),
    controller: require('./select-rfq-user.controller'),
    controllerAs: 'vm'
  });
};
