'use strict';

module.exports = function(labTestStatus, verificationTypes, fbutil, $state, $uibModal, email, $log, S3,
                          confirmModal, billing, growl, billingCodes, notifications) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    vm.vvName = vm.substituteName || 'verification';
    vm.vvNameTitle = vm.vvName.charAt(0).toUpperCase() + vm.vvName.slice(1);
    vm.isProperties = vm.vvName === 'properties';
    vm.labTestStatus = labTestStatus;
    vm.verificationTypes = verificationTypes;

    if (!vm.verification.type && vm.externalOnly) {
      vm.verification.type = verificationTypes.EXTERNAL;
    }

    // if (vm.verification.type === verificationTypes.EXTERNAL) {
    //   vm.verification.labTestStatus = vm.verification.labTestStatus || labTestStatus.INIT.id;
    //   $timeout(advanceSteps, 0);
    // }

    // vm.teams = {};
    // _.each(vm.verification.bids, (bid) => {
    //   Team.getProfile(bid.teamId)
    //     .then((teamProfile) => {
    //       vm.teams[bid.teamId] = teamProfile;
    //     });
    // });
  };

  vm.close = function() {
    vm.$uibModalInstance.close(vm.verification);
  };

  vm.cancel = function() {
    vm.$uibModalInstance.dismiss('cancel');
  };

  // vm.stepTitle = function(testStatusId) {
  //   switch (testStatusId) {
  //   case vm.labTestStatus.INIT.id:
  //     return 'Choose Partner';
  //   case vm.labTestStatus.TEST_REQUESTED.id:
  //   case vm.labTestStatus.BID_SUBMITTED.id:
  //     return 'Accept Bid';
  //   case vm.labTestStatus.TEST_IN_PROGRESS.id:
  //   case vm.labTestStatus.RESULTS_PUBLISHED.id:
  //     return 'View Results';
  //   case vm.labTestStatus.COMPLETED_BUT_UNPAID.id:
  //     return 'Accepted';
  //   default:
  //     throw new Error('Invalid lab status.');
  //   }
  // };

  vm.setVerificationType = function(type) {
    vm.verification.type = type;
    vm.verification.$save();
  };

  vm.cancelTestRequest = function() {
    let title = 'Cancel Lab ' + vm.vvNameTitle + ' Request?',
      body = 'Are you sure you want to cancel your request for lab ' + vm.vvName + '? ';

    confirmModal({
      title: title,
      body: body,
      okText: 'Cancel Request',
      cancelText: 'Cancel'
    }).then(function() {
      //todo: How to notify if it's a partner lab
      if (vm.verification.bids) {
        var selectedLab = vm.verification.bids[vm.verification.selectedBid];

        notifications.postToOrg({
          from: vm.user.uid,
          to: selectedLab.teamId,
          message: 'Lab test cancelled by <i>' +
          vm.productOrganization.companyName + '</i> for <b>' + vm.product.brandName + '</b>.',
          link: {
            state: 'analysis.lab.tests'
          }
        });
      }
      vm.verification.testRequestCancelled = firebase.database.ServerValue.TIMESTAMP;
      vm.verification.labTestStatus = labTestStatus.CANCELLED.id;
      delete vm.verification.laboratory;
      vm.verification.$save();
      vm.$uibModalInstance.close(vm.verification);
    });
  };

  vm.askQuestion = function() {
    fbutil.ref('teams', vm.verification.laboratory, 'channelId').once('value').then(function(snap) {
      $state.go('answers.ask', {channelId: snap.val()});
    });
  };

  // vm.requestChanges = function() {
  //   vm.openCommentWindow().then(function(commentsSaved) {
  //     if (commentsSaved) {
  //       let title = 'Verification Testing Changes Requested for ' + vm.product.brandName,
  //         description = 'Changes have been requested for ' + vm.product.brandName + 'by ' + vm.user.email;
  //
  //       email.sendTemplate({
  //         email: 'william@foodready.ai',
  //         name: 'William',
  //         subject: 'Verification Testing Changes Requested'
  //       }, 'generic_email', [
  //         {'name': 'titleSection', 'content': title},
  //         {'name': 'bodyText', 'content': description}
  //       ]).error($log.error);
  //     }
  //   });
  // };

  // vm.openCommentWindow = function() {
  //   return $uibModal.open({
  //     windowClass: 'scrolling-modal',
  //     template: require('../../../../_modals/discussion.template.html'),
  //     controller: function($scope, $uibModalInstance, fbutil, $firebaseArray) {
  //       let commentsSaved = false;
  //
  //       $scope.comments = $firebaseArray(fbutil.ref('verifications', vm.verification.$id, 'testComments'));
  //
  //       $scope.addComment = function() {
  //         $scope.comments.$add({
  //           from: vm.user.fullName(),
  //           content: $scope.letterComment,
  //           timestamp: firebase.database.ServerValue.TIMESTAMP
  //         }).then(function() {
  //           commentsSaved = true;
  //           var selectedLab = vm.verification.bids[vm.verification.selectedBid];
  //
  //           notifications.postToTeam({
  //             from: vm.user.uid,
  //             to: selectedLab.teamId,
  //             message: 'Comment made by <i>' +
  //             vm.productOrganization.companyName + '</i> regarding <b>' + vm.product.brandName + '</b>.',
  //             link: {
  //               state: 'analysis.products.list'
  //             }
  //           });
  //         });
  //         $scope.comment = '';
  //       };
  //       $scope.title = 'Test Report Comments';
  //       $scope.ok = function() {
  //         $uibModalInstance.close(commentsSaved);
  //       };
  //     }
  //   }).result;
  // };

  // vm.acceptTestResults = function() {
  //   let selectedLab = vm.verification.bids[vm.verification.selectedBid], amount, billingCode;
  //
  //   if (vm.verification.rush) {
  //     amount = selectedLab.labRushRate;
  //     billingCode = billingCodes.LAB_TEST_RUSH;
  //   } else {
  //     amount = selectedLab.labRate;
  //     billingCode = billingCodes.LAB_TEST;
  //   }
  //
  //   return billing.createInvoiceItem({
  //     user: vm.user,
  //     org: vm.productOrganization,
  //     product: vm.product,
  //     billingCode: billingCode,
  //     amount: amount,
  //   })
  //     .then(function(invoices) {
  //       vm.verification.testAcceptedDate = firebase.database.ServerValue.TIMESTAMP;
  //       vm.verification.labTestStatus = labTestStatus.COMPLETED_BUT_UNPAID.id;
  //       vm.verification.$save();
  //
  //       vm.user.invoices = invoices;
  //       growl.success('Invoice for Labs Results successfully created.', {});
  //
  //       //TODO: product could be Paid already, but changes made that need to be accepted again.
  //       var title = 'A test report has been ACCEPTED',
  //         description = 'An invoice for product:' + vm.product.brandName + ',  has been created.';
  //
  //       var selectedLab = vm.verification.bids[vm.verification.selectedBid];
  //
  //       notifications.postToTeam({
  //         from: vm.user.uid,
  //         to: selectedLab.teamId,
  //         message: 'Test report accepted by <i>' +
  //         vm.productOrganization.companyName + '</i> for <b>' + vm.product.brandName + '</b>.',
  //         link: {
  //           state: 'analysis.lab.tests'
  //         }
  //       });
  //
  //
  //       email.sendTemplate({
  //         email: 'william@foodready.ai',
  //         name: 'William',
  //         subject: 'A Lab Test report has been ACCEPTED'
  //       }, 'generic_email', [
  //         {'name': 'titleSection', 'content': title},
  //         {'name': 'bodyText', 'content': description}
  //       ]).success(function() {
  //         angular.noop();
  //       }).error($log.error);
  //     })
  //     .catch(function(err) {
  //       $log.error('Unable to approve test. ' + err);
  //       growl.error('Unable to approve test results');
  //     });
  // };

  // vm.openFile = function() {
  //   S3.openSignedUrlInNewTab(vm.verification.organizationId, vm.verification.testResults.key);
  // };

  // vm.canChooseLab = function() {
  //   return vm.submitTo && (vm.submitTo === 'partners' || vm.verification.laboratory);
  // };

  // vm.chooseLab = function() {
  //   if (vm.submitTo !== 'partners') {
  //     vm.verification.labTestStatus = labTestStatus.TEST_IN_PROGRESS.id;
  //     $timeout(advanceSteps, 0);
  //     // Todo: send email to lab partner??
  //     vm.verification.$save();
  //   }
  //
  //   vm.verification.$save();
  // };

  // vm.submitTestRequst = function() {
  //   let plateCountTest = false,
  //     chemicalTest = false,
  //     productCharacteristicTest = false,
  //     rush = false;
  //
  //   if (!vm.isProperties) {
  //     if (vm.verification.plateCountTest) { plateCountTest = true; }
  //     if (vm.verification.chemicalTest) { chemicalTest = true; }
  //   } else {
  //     productCharacteristicTest = true;
  //   }
  //
  //   if (vm.verification.rush) { rush = true; }
  //
  //   email.sendTemplate({
  //     email: 'william@foodready.ai',
  //     name: 'William',
  //     subject: 'Laboratory Test Requested'
  //   }, 'lab_test_request', [
  //     {'name': 'titleSection', 'content': 'Lab Request for: ' + vm.product.brandName},
  //     {'name': 'companyName', 'content': vm.productOrganization.companyName},
  //     {'name': 'clientName', 'content': vm.user.fullName()},
  //     {'name': 'productDescription', 'content': vm.product.description},
  //     {'name': 'plateCountTest', 'content': plateCountTest},
  //     {'name': 'chemicalTest', 'content': chemicalTest},
  //     {'name': 'plateCountTest', 'content': plateCountTest},
  //     {'name': 'productCharacteristicTest', 'content': productCharacteristicTest},
  //     {'name': 'rush', 'content': rush},
  //     {'name': 'lotNumber', 'content': vm.verification.lotNumber},
  //     {'name': 'sampleDescription', 'content': vm.verification.sample},
  //     {'name': 'specialInstructions', 'content': vm.verification.comments}
  //   ]).error($log.error);
  //
  //   vm.verification.labTestStatusId = labTestStatus.TEST_REQUESTED.id;
  //   vm.verification.bidsRequested = true;
  //   vm.verification.bidRequestDate = new Date().getTime();
  //
  //   notifications.postToAllLabs({
  //     from: vm.user.uid,
  //     message: 'Lab bid request placed by ' +
  //     vm.productOrganization.companyName + ' for "' + vm.product.brandName + '".',
  //     link: {state: 'analysis.lab.tests'}
  //   });
  //
  //   vm.verification.$save();
  // };

  vm.cancelRequestBids = function() {
    let title = 'Cancel Lab ' + vm.vvNameTitle + ' Request?',
      body = 'Are you sure you want to cancel your request for lab ' + vm.vvName + '? ';

    confirmModal({
      title: title,
      body: body,
      okText: 'Cancel Request',
      cancelText: 'Cancel'
    }).then(function() {
      vm.verification.bidsRequested = false;
      vm.verification.bidRequestCancelDate = new Date().getTime();
      vm.verification.labTestStatus = labTestStatus.CANCELLED.id;
      vm.verification.$save().then(function() {
        vm.$uibModalInstance.close(vm.verification);
        _.forEach(vm.verification.bids, function(bid) {
          notifications.postToOrg({
            from: vm.user.uid,
            to: bid.teamId,
            message: 'Bids cancelled by <i>' +
            vm.productOrganization.companyName + '</i> for <b>' + vm.product.brandName + '</b>.',
            link: {
              state: 'analysis.lab.tests'
            }
          });
        });
      });
    });
  };

  vm.finished = function() {
    $state.go('user.billing');
    vm.$uibModalInstance.dismiss();
  };

  // vm.canEnterStep = function(stepTitle, verification) {
  //   switch(stepTitle) {
  //   case 'Choose Partner':
  //     if (verification.labTestStatus > 1) { return false; }
  //     break;
  //   case 'Submit Request':
  //     if (verification.labTestStatus > 1) { return false; }
  //     break;
  //   default:
  //     return true;
  //   }
  // };
};
