class Controller {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.service = this.resolve.service || {};
    this.titleMap = this.resolve.titleMap || [];
    this.newService = _.isEmpty(this.service);
    this.fixedPrice = !this.newService && !!this.service.price;
  }

  ok() {
    if (!this.fixedPrice) {
      this.service.price = null;
    }
    this.modalInstance.close(this.service);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
