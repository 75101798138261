'use strict';

module.exports = function(ngModule) {
  ngModule.factory('foodProductService', function(fbutil, $q, $firebaseObject, $firebaseArray, $http) {
    'ngInject';

    return {
      /**
       * Fetch an foodProduct object.
       * @param {string} foodProductId The foodProducts unique identifier
       * @return {*}  A promise that resolves to an foodProduct object.
       */
      get: (foodProductId) => {
        return fbutil.ref('foodProducts', foodProductId).once('value').then(fbutil.getValueOrDefault);
      },

      /**
       * Fetch an foodProduct FB object.
       * @param {string} foodProductId The foodProducts unique identifier
       * @return {*}  A promise that resolves to an angularFire foodProduct object.
       */
      $get: (foodProductId) => {
        return $firebaseObject(fbutil.ref('foodProducts', foodProductId)).$loaded();
      },


      /**
       * Fetch an foodProducts FB array.
       * @param {string} orgId The organization who owns the foodProduct.
       * @return {*}  A promise that resolves to an angularFire foodProducts list.
       */
      $getAllFoodProducts: (orgId) => {
        return $firebaseArray(fbutil.ref('foodProducts').orderByChild('organizationId').equalTo(orgId)).$loaded()
          .then((foodProducts)=>{
            return foodProducts
              .filter((foodProduct)=> !foodProduct.deleted).reverse();
          });
      },

      /**
       * Push a new empty foodProduct.
       * @param {string} orgId The organization who owns the foodProduct.
       * @param {string} productId The plan associated with the food product.
       * @param {string} brandName The brand name of the product.
       * @return {*}  A promise that resolves to an angularFire foodProduct object.
       */
      $push: (orgId, productId, brandName) => {
        return $firebaseObject(fbutil.ref('foodProducts').push()).$loaded()
          .then($newfoodProduct => {
            $newfoodProduct.organizationId = orgId;
            $newfoodProduct.createdOn = firebase.database.ServerValue.TIMESTAMP;
            $newfoodProduct.productId = productId;
            $newfoodProduct.brandName = brandName;
            return $newfoodProduct;
          });
      },

      remove: (foodProductId) => {
        return fbutil.ref('foodProducts', foodProductId).once('value')
          .then(foodProductSnap => {
            if (!foodProductSnap.exists()) {
              return $q.reject('foodProduct not found');
            }
          })
          .then(function() {
            return fbutil.ref('foodProducts', foodProductId).remove();
          });
      },

      softDelete: (foodProductId) => {
        return fbutil.ref('foodProducts', foodProductId).once('value')
          .then(foodProductSnap => {
            if (!foodProductSnap.exists()) {
              return $q.reject('foodProduct not found');
            }
          })
          .then(function() {
            return fbutil.ref('foodProducts', foodProductId,'deleted').set(true);
          });
      },

      getFoodProductName: function(foodProductId) {
        return fbutil.ref(`foodProducts/${foodProductId}/name`).once('value').then(fbutil.getValueOrDefault);
      },

      getFoodProductNumber: function(foodProductId) {
        return fbutil.ref(`foodProducts/${foodProductId}/externalNumber`).once('value').then(fbutil.getValueOrDefault);
      },

      /**
       * Fetch an foodProducts array.
       * @param {string} orgId The organization who owns the foodProduct.
       * @return {*}  A promise that resolves to a foodProducts list.
       */
      getAllFoodProducts: (orgId) => {
        return $http.get('foodproducts?from=0&size=1000&orgId=' + orgId).then((res)=> res.data);
      },

      getFoodProducts: (orgId, productId) => {
        return $http.get(`/organizations/${orgId}/foodproducts?productId=${productId}`)
          .then((res) => res.data.foodProducts);
      }
    };
  });
};
