class Controller {
  constructor(billing, growl, $log, $uibModal, organizations, $q, $timeout, services, orders, moment, utils) {
    'ngInject';

    this.billing = billing;
    this.growl = growl;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.$timeout = $timeout;
    this.services = services;
    this.orders = orders;
    this.moment = moment;
    this.organizations = organizations;
    this.utils = utils;
  }

  $onInit() {
    this.customer = this.user.customer;
    this.openInvoices = _.filter(this.invoices, i => this.services.isSent(i) && !this.services.isPaid(i));
    this.paidInvoices = _.filter(this.invoices, i => this.services.isPaid(i));
    this.processorDown = this.user.customerId && !this.customer;
    this.lookupOrders();

    if (!this.openInvoices.length) {
      this.noOpenInvoices = true;
      return;
    }

    this.openInvoices = _.groupBy(this.openInvoices, i => i.providerOrgId);
    this.$q.all(_.map(this.openInvoices, (val, providerOrgId) => {
      return this.organizations.getProfile(providerOrgId);
    })).then(profiles => {
      this.$timeout(() => {
        this.teamProfiles = profiles;
        this.selectedTeamId = profiles[0].id;
      });
    });
  }

  lookupOrders() {
    this.invoiceOrders = {};
    _.each(this.paidInvoices, i => {
      if (!i.orderId || this.invoiceOrders[i.orderId]) { return; }
      this.orders.$get(i.orderId).then(order => {
        order.timestamp = this.moment(order.chargedDate || order.createdOn).format('MM-DD-YYYY');
        this.$timeout(() => {
          this.invoiceOrders[i.orderId] = order;
        });
      });
    });
  }

  payInvoices(invoices) {
    this._payInvoices(invoices);
  }

  payInvoice(invoice) {
    this._payInvoices([invoice]);
  }

  _payInvoices(invoices) {
    if (!invoices) {
      this.$log.error('No invoices found');
      return;
    }
    this.$uibModal.open({
      component: 'cfPayInvoice',
      size: 'lg',
      resolve: {
        user: () => this.user,
        team: () => _.find(this.teamProfiles, t => t.id === invoices[0].teamId),
        invoices: () => invoices
      }
    }).result.then(() => {
      this.$timeout(() => {
        _.each(invoices, (i, index) => {
          this.paidInvoices.push(i);
          this.openInvoices[this.selectedTeamId].splice(index, 1);
          if (!this.openInvoices[this.selectedTeamId].length) {
            delete this.openInvoices[this.selectedTeamId];
            if (_.isEmpty(this.openInvoices)) {
              this.noOpenInvoices = true;
              delete this.selectedTeamId;
              return;
            }
            this.selectedTeamId = _.first(this.openInvoices);
          }
        });
      });
    }).catch(reason => {
      if (this.utils.isModalDismissalByUser(reason)) { return; }

      this.growl.error(reason);
    });
  }

}

module.exports = Controller;
