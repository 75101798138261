module.exports = function(ngModule) {
  ngModule.component('cfPortalFinish', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./portal.finish-modal.template.html'),
    controller: require('./portal.finish-modal.controller'),
    controllerAs: 'vm'
  });
};