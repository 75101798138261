module.exports = function(ngModule) {
  ngModule.component('cfLaboratories', {
    bindings: {
      company: '=',
      laboratories: '=',
      user: '='
    },
    template: require('./company.laboratories.template.html'),
    controller: require('./company.laboratories.controller.js'),
    controllerAs: 'vm'
  });
};
