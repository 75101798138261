'use strict';

module.exports = function (ngModule) {
  ngModule.component('cfEditableTextarea', {
    transclude: true,
    bindings: {
      ngModel: '<',
      placeholder: '@?',
      onUpdate: '&'
    },
    template: require('./editable-textarea.template.html'),
    controller: require('./editable-textarea.controller.js'),
    controllerAs: 'vm'
  });
};
