class Controller {
  constructor($log, $timeout, preventDirtyNav, growl, confirmModal, confirmDeleteModal, S3, utils) {
    'ngInject';

    this.$log = $log;
    this.$timeout = $timeout;
    this.growl = growl;
    this.preventDirtyNav = preventDirtyNav;
    this.confirmModal = confirmModal;
    this.confirmDeleteModal = confirmDeleteModal;
    this.S3 = S3;
    this.utils = utils;

    this.sources = {
      CF: 'cf',
      CUSTOM: 'custom'
    };
  }

  $onInit() {
    this.$timeout(() => {
      this.preventDirtyNav(this.consigneeInstructionsForm, 'You have unsaved recall notification changes.',
        () => this.save());
    });
    if (_.isUndefined(this.recall.letterSource)) {
      this.recall.letterSource = this.sources.CF;
    }
    if (_.isUndefined(this.recall.phoneScriptSource)) {
      this.recall.phoneScriptSource = this.sources.CF;
    }
    if (_.isUndefined(this.recall.customerInstructionsSource)) {
      this.recall.customerInstructionsSource = this.sources.CF;
    }
  }

  save() {
    this.recall.$save().then(() => {
      if (this.consigneeInstructionsForm) { this.consigneeInstructionsForm.$setPristine(); }
      this.growl.success('Customer notification instructions saved');
    }).catch(err => {
      this.$log.error(err);
      this.growl.error('An error occurred saving changes to the recall plan');
    });
  }

  saveBeforeContinue() {
    if (this.consigneeInstructionsForm && this.consigneeInstructionsForm.$pristine) { return; }

    return this.confirmModal({
      title: '<span class="far fa-exclamation-triangle fa-fw"></span> Unsaved Changes',
      body: 'You have unsaved changes to the recall letter configuration. ' +
        'They will be saved before continuing.',
      okText: 'Save & Continue',
      hideCancelButton: true
    }).then(() => this.save());
  }

  openTemplate() {
    this._open(_.first(this.$letterTemplate).key);
  }

  openPhoneScript() {
    this._open(_.first(this.$phoneScript).key);
  }

  openCustomerInstructions() {
    this._open(_.first(this.$customerInstructions).key);
  }

  _open(key) {
    return this.S3.openSignedUrlInNewTab(this.user.orgContext.id, key);
  }

  removeTemplate() {
    const template = _.first(this.$letterTemplate);

    this._remove(template, () => this.$letterTemplate.$remove(0));
  }

  removePhoneScript() {
    const script = _.first(this.$phoneScript);

    this._remove(script, () => this.$phoneScript.$remove(0));
  }

  removePhoneScript() {
    const script = _.first(this.$phoneScript);

    this._remove(script, () => this.$phoneScript.$remove(0));
  }

  _remove(file, onDeleteFn) {
    this.confirmDeleteModal(file.name).then(() => this.saveBeforeContinue()).then(() => {
      return this.S3.deleteFile(this.user.orgContext.id, file.key).then(() => onDeleteFn())
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove file'));
    });
  }
}

module.exports = Controller;
