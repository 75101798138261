class Controller {
  constructor(fbutil, growl, $state, $log, authentication, $scope) {
    'ngInject';

    this.auth = fbutil.auth;
    this.growl = growl;
    this.$state = $state;
    this.$log = $log;
    this.authentication = authentication;
    this.$scope = $scope;
  }

  $onInit() {
    this.auth.verifyPasswordResetCode(this.actionCode)
      .then(email => this.accountEmail = email)
      .catch(err => this._handleError(err));

    this.$scope.$watch('vm.pwStrength', val => {
      switch (Math.round(val / 33)) {
      case 0:
      case 1:
        this.pwStrengthExt = {description: 'Weak', css: 'text-danger', icon: 'fa-ban'};
        return;
      case 2:
        this.pwStrengthExt =  {description: 'Ok', css: 'text-warning', icon: 'fa-exclamation-triangle'};
        return;
      case 3:
        this.pwStrengthExt =  {description: 'Good!', css: 'text-success', icon: 'fa-check'};
        return;
      default:
        this.pwStrengthExt = null;
      }
    });
  }

  reset() {
    delete this.err;
  }

  updatePassword(newPassword) {
    this.reset();
    if (this.password !== this.confirmPw) {
      this.err = 'Passwords do not match!';
      return;
    }

    this.auth.confirmPasswordReset(this.actionCode, newPassword).then(() => {
      this.authentication.loginLocal(this.accountEmail, newPassword).then(user => {
        this.$log.info('Password reset completed: ' + user.uid);
        this.growl.success('New password set.');
        this.$state.go('user.dashboard');
      });
    }).catch(err => this._handleError(err));
  }

  _handleError(error) {
    this.growl.error('Could not reset your password. The link is invalid or expired.');
    this.$log.error('Invalid reset pw actionCode: ' + error);
    this.$state.go('authentication.signin');
  }
}

module.exports = Controller;
