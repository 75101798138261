class Controller {
  constructor($timeout, miniTourService, constantsService) {
    'ngInject';

    this.$timeout = $timeout;
    this.miniTourService = miniTourService;
    this.constantsService = constantsService;
  }

  $onInit() {
    this.remoteDirectory = 'organizations/' + this.organization.$id + '/externalFiles';
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.categories = _.pickBy(fileTypes, fc => fc.companyLevel);
    });

    if (this.user.onPayAsGoPlan()) {
      this.$timeout(() => {
        this.miniTourService.enqueueTour(this.user, {
          id: 'gmpSurvey',
          selector: '#gmpSurvey',
          width: 500,
          title: 'Use the Good Manufacturing Practices (GMPs) Survey to Unlock More Features!',
          contentHtml: '<div>You can store your old GMPs or SOPs here in the <b>Documents and Images</b> tab. ' +
          'However, if you upgrade to the Premium Level you can use the dynamic GMP Survey, which has ' +
          'excellent on-demand reporting and tracks who changed your GMPs and when. There\'s no easier way ' +
          'to stay in compliance!</div><div style="padding: 30px">' +
          '<img style="width: 400px" src="' + require('../../_img/cf_screenshot_gmp.png') + '"></div>'
        });
      }, 600);
    }
  }
}

module.exports = Controller;
