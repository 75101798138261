'use strict';

// require('./_css');

module.exports = function(parentModuleName) {
  var moduleName = parentModuleName + '.foodProducts',
    ngModule = angular.module(moduleName, []);

  require('./_components/edit')(ngModule);
  require('./_services')(ngModule);

  return moduleName;
} ;
