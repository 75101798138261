module.exports = function(ngModule) {
  ngModule.factory('ChecklistsLibrarySearch', function($http, $q, utils, users) {
    'ngInject';

    const DEFAULT_PAGE_SIZE = 30;

    class ChecklistsLibrarySearch {
      constructor(orgId ,type, noBatches, adminTemplates = false) {
        this.baseUri = 'v2/checklists/templates/search';
        this.orgId = orgId;
        this.maxReach = false;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.type = type;
        this.userNames = {};
        this.users = users;
        this.noBatches = noBatches;
        this.adminTemplates = adminTemplates;
      }
      maxReached() {
        return this.maxReach;
      }
      /**
       * Sort by a property on the procedure entry
       * @param {string} sortBy Sort field
       * @param {string} sortDirection Sort direction
       * @returns {ChecklistsLibrarySearch} The ChecklistsLibrarySearch object
       */
      sortBy(sortBy, sortDirection = 'asc') {
        this.sortByValue = sortBy;
        this.sortDirection = sortDirection;

        return this;
      }

      /**
       * Set the max page size of the result set
       * @param {number} size Max page size
       * @returns {ChecklistsLibrarySearch} The ChecklistsLibrarySearch object
       */
      size(size) {
        this.pageSize = size;

        return this;
      }

      /**
       * Start from index
       * @param {number} index Max page size
       * @returns {ChecklistsLibrarySearch} The ChecklistsLibrarySearch object
       */
      startFrom(index) {
        this.from = index;

        return this;
      }

      /**
       * Set the search text (currently will try to match key name)
       * @param {string=} text The search text
       * @returns {Promise} The ChecklistsLibrarySearch object
       */
      search(text = '') {
        this.from = 0;
        this.maxReach = false;
        this.searching = true;
        this.badSearch = false;

        return this._search(text)
          .finally(() => { this.searching = false; });
      }

      getMore(text) {
        if (this.badSearch) { throw new Error('Failed search...cannot get more'); }
        if (this.maxReach || this.searching) { return; }
        this.from += this.pageSize;

        return this._search(text);
      }

      $loaded() {
        return this.httpPromise;
      }

      async usersName(items) {
        await Promise.all(_.map(items, async item => {

          let uid = item.updatedBy || item.createdBy;
          let date = item.updatedOn || item.createdOn;

          if (!uid) { return; }
          if (!this.userNames[uid]) {
            this.userNames[uid] = this.users.getName(uid).catch(() => '');
          }
          if(date) {
            item.date = new Date(date).toLocaleString('en-us',{month: '2-digit',day: '2-digit',year: 'numeric'});
          }
          item.updatedByName = await this.userNames[uid];
        }));
      }

      _search(text) {
        let queryParmArray = [];

        queryParmArray.push(`organizationId=${this.orgId}`);
        queryParmArray.push(`from=${this.from}`);
        queryParmArray.push(`size=${this.pageSize}`);
        queryParmArray.push(`sortBy=${this.sortByValue || 'updatedOn'}`);
        queryParmArray.push(`sortDirection=${this.sortDirection || 'desc'}`);
        if(this.type) {
          queryParmArray.push(`type=${this.type}`);
        }
        if(this.adminTemplates) {
          queryParmArray.push(`adminTemplates=${this.adminTemplates}`);
        }
        if (text) { queryParmArray.push(`searchText=${text}`); }

        const url = `${this.baseUri}${utils.toQueryParm(queryParmArray)}`;

        this.searching = true;
        this.httpPromise = $http.get(url)
          .then(async result => {
            if (result.status === 204) {
              this.maxReach = true;
              return [];
            }

            let recs = result.data.data;

            // await this.usersName(recs);
            if (recs.length < this.pageSize) {
              this.maxReach = true;
            }

            return recs;
          })
          .catch(err => {
            this.badSearch = true;

            return $q.reject(err);
          })
          .finally(() => this.searching = false);

        return this.httpPromise;
      }
    }

    return ChecklistsLibrarySearch;
  });
};
