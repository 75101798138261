module.exports = function(ngModule) {
  class AuthorizationService {
    constructor($q, $firebaseObject, fbutil, users, urlTokens, CF_CLAIMS) {
      'ngInject';

      this.$q = $q;
      this.$firebaseObject = $firebaseObject;
      this.fbutil = fbutil;
      this.users = users;
      this.urlTokens = urlTokens;
      this.claims = CF_CLAIMS;
    }

    /**
     * Initialize a user's userAccess record (authorization properties) by setting createdOn to "now"
     * @param {string} uid The user's uid
     * @returns {Promise} A promise resolving to the firebase ref of the set operation.
     */
    initialize(uid) {
      return this.fbutil.ref('userAccess', uid, 'createdOn').set(firebase.database.ServerValue.TIMESTAMP);
    }

    /**
     * Remove a user's authorization record completely
     * @param {string} uid The user's uid
     * @returns {Promise} A promise resolving to the firebase ref of the remove operation.
     */
    remove(uid) {
      this.fbutil.ref('userAccess', uid).remove();
    }

    /**
     * Set the user role for an organization
     * @param {string} uid The user's uid
     * @param {string} orgId The organization ID
     * @param {string} role The user role to be set.
     * @returns {Promise} A promise resolving to the firebase ref of the set operation.
     */
    setOrganizationRole(uid, orgId, role) {
      return this.fbutil.ref('userAccess', uid, 'reviews/organizations/', orgId, 'role').set(role);
    }

    /**
     * Set a user's current org context
     * @param {string} uid The user's uid
     * @param {string} orgId The organization ID
     * @returns {Promise} A promise resolving to the firebase ref of the set operation.
     */
    setOrgContext(uid, orgId) {
      return this.fbutil.ref('userAccess', uid, 'reviews/currentOrgContext').set(orgId);
    }

    /**
     * Get a user's perspective for an organization
     * @param {string} uid The user's uid
     * @param {string} orgId The organization ID
     * @returns {Promise<string>} A promise resolving to the perspective.
     */
    getPerspective(uid, orgId) {
      return this.fbutil.ref('userAccess', uid, 'reviews/organizations', orgId, 'perspective')
        .once('value')
        .then(this.fbutil.getValueOrDefault);
    }

    /**
     * Get the claims for a given org's role.
     * @param {string} orgId OrgId to check
     * @param {string} role  Role to check
     * @returns {Promise} A promise that resolves to the role record.
     */
    getClaims(orgId, role) {
      if (!role) { return this.$q.resolve({claims: {}}); }

      return this.fbutil.ref('roles', orgId, role).once('value')
        .then(orgRolesSnap => {
          if (orgRolesSnap.exists()) { return orgRolesSnap.val; }
          return this.fbutil.ref('roles', 'default', role)
            .once('value').then(defaultRolesSnap => defaultRolesSnap.val());
        });
    }

    /**
     * Get the user's userAccess object
     * @param {string} uid The user's uid
     * @returns {Promise<string>} A promise resolving to the userAccess object.
     */
    getUserAccess(uid) {
      return this.fbutil.ref('userAccess', uid).once('value')
        .then(this.fbutil.getValueOrDefault);
    }

    /**
     * Get the user's organization role defined on a  target organization?
     * @param {string} orgId The org whose permissions are check.
     * @param {string} targetOrganizationId The target organization to check for access to.
     * @returns {Promise<string>} A promise resolving to true if the org has the role defined.
     */
    getOrgLevelRole(orgId, targetOrganizationId) {
      return this.fbutil.ref('organizationAccess', orgId, 'reviews/organizations',
        targetOrganizationId, 'role').once('value').then(this.fbutil.getValueOrDefault);
    }

    /**
     * Does the user's organization have a role defined to the target organization?
     * @param {string} orgId The org whose permissions are check.
     * @param {string} targetOrganizationId The target organization to check for access to.
     * @param {string} role The role to check for existence.
     * @returns {Promise<string>} A promise resolving to true if the org has the role defined.
     */
    hasOrgLevelRole(orgId, targetOrganizationId, role) {
      return this.getOrgLevelRole(orgId, targetOrganizationId).then(r => r && r === role);
    }

    /**
     * Does the user's organization have an exception entry for another organization's product?
     * @param {object} user The user object
     * @param {string} productOrganizationId The product's organization.
     * @param {string} productId The product to which access is being checked.
     * @param {string} claim The exception record's claim to look for.
     * @returns {Promise<string>} A promise resolving to true if the org has the exception record.
     */
    hasOrgLevelProductException(user, productOrganizationId, productId, claim) {
      return this.$q.resolve(false);  // todo - remove all calls to this method. org access is no longer a thing.
/*
      return this.fbutil.ref('organizationAccess', user.currentOrgContext().id, 'reviews/organizations',
        productOrganizationId, 'exceptions/products', productId, claim).once('value')
        .then(snap => snap.exists());
*/
    }

    /**
     * Adds product access exceptions for an organization. The exception will grant
     * claims for an organization against another organization.
     * @param {string} orgId Organization whose permissions will be impacted.
     * @param {string} orgToBeAdded The target org who is granting access.
     * @param {string} productId  ProductId in question.
     * @param {string} claims  The claims to be added.
     * @returns {Promise} A promise that is resolved when the new exception record is added.
     */
    addOrgProductExceptions(orgId, orgToBeAdded, productId, claims) {
      return this.$q.all(_.map(claims, claim => this.fbutil.ref('organizationAccess', orgId, 'reviews/organizations',
        orgToBeAdded, 'exceptions/products', productId, claim).set(true)));
    }

    /**
     * Assign an organization to another organization. A new organizationAccess record is created that sets the
     * given role.
     * @param {string} uid Logged in user's uid.
     * @param {string} orgId Organization whose permissions will be impacted.
     * @param {string} orgToBeAdded The target org who is granting access.
     * @param {string} role The role to assign the new org to.
     * @returns {Promise} A promise that is resolved when the new org is added.
     */
    assignOrgToOrganization(uid, orgId, orgToBeAdded, role) {
      return this.fbutil.ref('organizationAccess', orgId, 'reviews/organizations', orgToBeAdded)
        .once('value')
        .then(snap =>
          snap.exists() ?
            this.fbutil.ref('organizationAccess', orgId, 'reviews/organizations', orgToBeAdded, 'role')
              .set(role) :
            this.fbutil.ref('organizationAccess', orgId, 'reviews/organizations', orgToBeAdded)
              .set({
                role: role,
                createdBy: uid,
                createdOn: new Date().getTime()
              })
        );
    }

    /**
     * Remove access of an organization from another organization. The organizationAccess record is deleted.
     * @param {string} orgId Organization whose permissions will be impacted.
     * @param {string} orgToBeRemoved  The target org who is removing access.
     * @returns {Promise} A promise that is resolved when the new org is removed.
     */
    removeOrgFromOrganization(orgId, orgToBeRemoved) {
      return this.fbutil.ref('organizationAccess', orgId, 'reviews/organizations', orgToBeRemoved).remove();
    }

    /**
     * Remove a user from an organization
     * @param {string} uid The user's uid
     * @param {string} orgId The organization ID
     * @returns {Promise<string>} A promise resolving to the result of the remove operation.
     */
    removeFromOrganization(uid, orgId) {
      return this.fbutil.ref('userAccess', uid, 'reviews/organizations', orgId).remove();
    }
  }

  ngModule.service('authorization', AuthorizationService);
};
