module.exports = function(ngModule) {
  ngModule.component('cfUploadPortal', {
    bindings: {
      token: '<',
      user: '<?',
      staged: '<'
    },
    template: require('./file-portal.main.template.html'),
    controller: require('./file-portal.main.controller.js'),
    controllerAs: 'vm'
  });
};
