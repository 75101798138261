module.exports = function(ngModule) {
  ngModule.factory('accessRequests', function($http, fbutil, $firebaseObject, $q, $log,
                                              messageServices, urlTokens, notifications, authorization) {
    'ngInject';

    const requestTypes = {
      ORG_ROLE_REQUEST: 1,
      USER_ROLE_REQUEST: 2,
      ORG_PRODUCT_EXCEPTION_REQUEST: 3,
      USER_PRODUCT_EXCEPTION_REQUEST: 4
    };

    return {
      requestTypes: requestTypes,

      $get: function(accessRequestId) {
        return $firebaseObject(fbutil.ref('accessRequests', accessRequestId)).$loaded();
      },

      /**
       * Create an access request.
       * @param {object} accessRequestData The access request object to be persisted to /accessRequests.
       * @returns {Promise.<void>}  A promise that resolves to new accessRequest key.
       */
      createAccessRequest: function(accessRequestData) {
        return fbutil.ref('accessRequests').push(accessRequestData).then(newRef => {
          return $firebaseObject(newRef).$loaded();
        });
      },

      /**
       * Removing the protectionKey property means the approved accessRequest can't be used to establish an actual
       * access record. (It also means the record is accessible and can be altered).
       * @param {string} id  Id to remove the protection key from.
       * @returns {Promise.<*>} A promise that's resolved when the key is removed.
       */
      unprotect: function(id) {
        return fbutil.ref('accessRequests', id, 'protectionKey').remove();
      },

      /**
       * Sets the protection key (also makes it inaccessible)
       * @param {string} id  Id to add the protection key to.
       * @param {string} key Protection key
       * @returns {Promise.<*>} A promise that's resolved when the key is added.
       */
      protect: function(id, key) {
        if (!key) {
          return;
        }
        return fbutil.ref('accessRequests', id, 'protectionKey').set(key);
      },

      /**
       * Execute the access request according to the token specifications
       * @param {string} accessRequestId The access request ID
       * @param {string} protectionKey The key that protects the accessRequest
       * @returns {HttpPromise<object>} A promise resolving to the result of the execute request
       */
      execute: function(accessRequestId, protectionKey) {
        return $http
          .post('/applyApprovedAccessRequest', {
            protectionKey: protectionKey,
            accessRequestId: accessRequestId
          });
      },

      /**
       * Cancel the access request
       * @param {string} accessRequestId The access request ID
       * @returns {HttpPromise<object>} A promise resolving to a cancel message.
       */
      cancel: function(accessRequestId) {
        return $http
          .post('/cancelApprovedAccessRequest', {
            accessRequestId: accessRequestId
          });
      },

      /**
       * Return the status of an access request.
       * @param {string} accessRequestId The access request ID to check.
       * @return {Promise} A promise that resolves to 'pending', false (if rejected or
       * cancelled), or null if missing or is no longer outstanding.
       */
      getStatus: function(accessRequestId) {
        return fbutil.ref('accessRequests', accessRequestId, 'status').once('value').then(fbutil.getValueOrDefault)
          .then(status => {
            switch (status) {
            case 'approved':  // 'approved-applied' means it's no longer pending
            case 'pending':
              return 'pending';
            case 'cancelled':
            case 'rejected':
              return false;
            default:
              return null;
            }
          });
      },

      /**
       * Approve an access request and delete the outstanding notification and email token
       * since they will be no longer needed.
       * @param {object} user Logged in user
       * @param {string} accessRequestId The access request Id
       * @param {string} [notificationId] The notification Id to delete once complete
       * @param {string} [emailToken] The email token to delete once complete
       * @returns {Promise} A promise that's resolved when the operation is complete
       */
      approveAccessRequestNotification(user, accessRequestId, notificationId, emailToken) {
        return this.$get(accessRequestId).then($accessRequest => {
          if (!$accessRequest) {
            return $q.reject('Request Id not found');
          }
          let authPromise;

          switch ($accessRequest.type) {
          case this.requestTypes.ORG_PRODUCT_EXCEPTION_REQUEST:
            authPromise = authorization.addOrgProductExceptions($accessRequest.organizationId,
              $accessRequest.targetOrgId, $accessRequest.productId, $accessRequest.claims);
            break;
          case this.requestTypes.ORG_ROLE_REQUEST:
            authPromise = authorization.assignOrgToOrganization(user.uid, $accessRequest.organizationId,
              $accessRequest.targetOrgId, $accessRequest.role);
            break;
          default:
            throw new Error('Invalid access request type: ' + $accessRequest.type);
          }

          return authPromise.then(() => {
            $log.info('Access request approved', {requestId: $accessRequest.$id});
            if (!$accessRequest.notificationSettings.suppressNotify) {
              notifications.postToOrg({
                from: user.uid,
                to: $accessRequest.organizationId,
                message: `"${user.orgContext.companyName}" granted your organization access to view food safety documentation for plan <i>${$accessRequest.notificationSettings.msgSubject}</i>.`,
                link: $accessRequest.notificationSettings.link
              });
            }

            $accessRequest.approvedOn = new Date().getTime();
            $accessRequest.approvedBy = user.uid;
            $accessRequest.status = 'approved-applied';

            return $q.all([
              $accessRequest.$save(),
              messageServices.getOrgToOrgTopic(user, $accessRequest.organizationId)]);
          }).then(() => {
            return $q.when(emailToken ? urlTokens.remove(emailToken) : '');
          }).then(() => {
            return $q.when(notificationId ? notifications.deleteById(notificationId) : '');
          });
        });
      },

      /**
       * Reject the access request and delete the outstanding notification and email token
       * since they will be no longer needed.
       * @param {object} user Logged in user
       * @param {string} accessRequestId The access request Id
       * @param {string} [notificationId] The notification Id to delete once complete
       * @param {string} [emailToken] The email token to delete once complete
       * @returns {Promise} A promise that's resolved when the operation is complete
       */
      rejectAccessRequestNotification(user, accessRequestId, notificationId, emailToken) {
        return this.$get(accessRequestId).then($accessRequest => {
          if (!$accessRequest) {
            return $q.reject('Request Id not found');
          }
          $accessRequest.rejectedOn = new Date().getTime();
          $accessRequest.rejectedBy = user.uid;
          $accessRequest.status = 'rejected';
          $log.info('Access request rejected', {requestId: $accessRequest.$id});
          notifications.postToOrg({
            from: user.uid,
            to: $accessRequest.organizationId,
            message: '"' + user.currentOrgContext().companyName + '" rejected your access request: ' +
            $accessRequest.notificationSettings.msgSubject,
            link: $accessRequest.notificationSettings.link
          });

          return $accessRequest.$save();
        }).then(() => {
          return $q.when(emailToken ? urlTokens.remove(emailToken) : '');
        }).then(() => {
          return $q.when(notificationId ? notifications.deleteById(notificationId) : '');
        });
      }
    };
  });
};
