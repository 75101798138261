'use strict';

module.exports = function (ngModule) {
  ngModule.directive('cfListTeams', function () {
    return {
      scope: {
        teams: '='
      },
      restrict: 'EA',
      template: require('./administration.list-teams.template.html'),
      controller: require('./administration.list-teams.controller.js')
    };
  });
};
