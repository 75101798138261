exports.ADMIN = 'admin';
exports.ANALYST = 'analyst';
exports.CLIENT = 'client';
exports.CPA = 'cpa';
exports.CPPM = 'cppm';
exports.LINKED_ORG = 'linked_org';
exports.PO = 'po';
exports.PROVIDER = 'provider';
exports.PROVIDER_WRITE = 'providerWrite';
exports.READ_ONLY_USER = 'read_only_user';
