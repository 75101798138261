require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductFiles', {
    bindings: {
      product: '=',
      files: '=',
      isReadOnly: '<',
      productOrganization: '<',
      user: '<'
    },
    template: require('./products.edit.files.template.html'),
    controller: require('./products.edit.files.controller'),
    controllerAs: 'vm'
  });
};
