module.exports = function(ngModule) {
  ngModule.component('frChecklistLibraryList', {
    bindings: {
      user: '<',
      company: '<',
      checklistTemplates: '<'
    },
    template: require('./checklist.library.list.template.html'),
    controller: require('./checklist.library.list.controller'),
    controllerAs: 'vm'
  });
};
