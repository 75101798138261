'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUploadPortalOrgFiles', {
    bindings: {
      token: '<',
      user: '<?'
    },
    template: require('./file-portal.org-files.template.html'),
    controller: require('./file-portal.org-files.controller.js'),
    controllerAs: 'vm'
  });
};