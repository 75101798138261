module.exports = function(ngModule) {
  ngModule.service('menus', function() {
    'ngInject';

    // Define a set of default roles
    this.defaultRoles = ['*'];
    this.defaultClaims = ['*'];
    this.defaultPerspectives = ['*'];
    this.defaultFeatureFlags = ['*'];

    // Define the menus object
    this.menus = {};

    // if the user is in a listed menu perspective, allow access - note: can use the not operator in a listed menu
    // perspective to remove access from one or more perspectives (e.g. '!pending').
    function checkPerspective(perspective) {
      let result = true, checks = 0;

      _.each(this.perspectives, (p) => {
        if(p.length && p[0] === '!') {
          result = result && p !== '!reviews.' + perspective;
          return result;
        } else {
          checks++;
        }
      });

      if (!result) {
        return result;
      } else if (checks) {
        return _.some(this.perspectives, p => p === 'reviews.' + perspective);
      }

      return result;
    }

    // A private function for rendering decision
    const shouldRender = function(user) {
      if (_.isFunction(this.hideFn) && this.hideFn()) { return false; }
      if (user && !user.signingUp) {
        if (!!~this.roles.indexOf('*') && !!~this.perspectives.indexOf('*') && !!~this.claims.indexOf('*')
          && !!~this.featureFlags.indexOf('*') && (!~this.isPremium || !user.onPayAsGoPlan())) {
          return true;
        }

        // check for reviews perspective/claims access. The 'role' is the user perspective, which drives the menu given
        // cosmetic preferences. Claim checks ensure the user has access to the menu item.
        let perspectiveCheckPassed = !!~this.perspectives.indexOf('*') || user.isReviewsUser() &&
            checkPerspective.call(this, user.orgContext.perspective),
          claimsCheckPassed = !!~this.claims.indexOf('*') ||
            _.every(this.claims, claim => user.hasPermission(claim) || user.isCfAdmin()),
          featureFlagCheckPassed = !!~this.featureFlags.indexOf('*') ||
            _.every(this.featureFlags, featureFlag => user.hasFeatureFlag(featureFlag)),
          premiumCheckPassed = !this.isPremium || !user.onPayAsGoPlan();

        return perspectiveCheckPassed && claimsCheckPassed && featureFlagCheckPassed && premiumCheckPassed;
      } else {
        return this.isPublic;
      }

      return false;
    };

    // Validate menu existance
    this.validateMenuExistance = function(menuId) {
      if (menuId && menuId.length) {
        if (this.menus[menuId]) {
          return true;
        }

        throw new Error('Menu does not exists');
      }

      throw new Error('MenuId was not provided');
    };

    // Get the menu object by menu id
    this.getMenu = function(menuId) {
      // Validate that the menu exists
      this.validateMenuExistance(menuId);

      // Return the menu object
      return this.menus[menuId];
    };

    // Add new menu object by menu id
    this.addMenu = function(menuId, options) {
      options = options || {};

      // Create the new menu
      this.menus[menuId] = {
        isPublic: options.isPublic === null || _.isUndefined(options.isPublic) ? false : options.isPublic,
        isPremium: options.isPremium === null || _.isUndefined(options.isPremium) ? false : options.isPremium,
        roles: options.roles || this.defaultRoles,
        claims: options.claims || this.defaultClaims,
        perspectives: options.perspectives || this.defaultPerspectives,
        featureFlags: options.featureFlags || this.defaultFeatureFlags,
        items: options.items || [],
        planConstraint: options.planConstraint,
        shouldRender: options.shouldRender || shouldRender
      };

      // Return the menu object
      return this.menus[menuId];
    };

    // Remove existing menu object by menu id
    this.removeMenu = function(menuId) {
      // Validate that the menu exists
      this.validateMenuExistance(menuId);

      // Return the menu object
      delete this.menus[menuId];
    };

    // Add menu item object
    this.addMenuItem = function(menuId, options) {
      options = options || {};

      // Validate that the menu exists
      this.validateMenuExistance(menuId);

      // Push new menu item
      this.menus[menuId].items.push({
        title: options.title || '',
        state: options.state || '',
        redirectPath: options.redirectPath || '',
        type: options.type || 'item',
        class: options.class,
        hideFn: options.hideFn,
        paramsFn: options.paramsFn || function() { return null; },
        isPublic: options.isPublic === null || _.isUndefined(options.isPublic) ?
          this.menus[menuId].isPublic : options.isPublic,
        isPremium: options.isPremium === null || _.isUndefined(options.isPremium) ?
          this.menus[menuId].isPremium : options.isPremium,
        roles: options.roles === null || _.isUndefined(options.roles) ?
          this.menus[menuId].roles : options.roles,
        claims: options.claims === null || _.isUndefined(options.claims) ?
          this.menus[menuId].claims : options.claims,
        perspectives: options.perspectives === null || _.isUndefined(options.perspectives) ?
          this.menus[menuId].perspectives : options.perspectives,
        featureFlags: options.featureFlags === null || _.isUndefined(options.featureFlags) ?
          this.menus[menuId].featureFlags : options.featureFlags,
        position: options.position || 0,
        items: [],
        scrollTo: options.scrollTo,
        duration: options.duration,
        planConstraint: options.planConstraint,
        shouldRender: options.shouldRender || shouldRender
      });

      // Add submenu items
      if (options.items) {
        for (let i in options.items) {
          this.addSubMenuItem(menuId, options.link, options.items[i]);
        }
      }

      // Return the menu object
      return this.menus[menuId];
    };

    this.addDivider = function(menuId, parentItemState, options) {
      options = options || {};

      // Validate that the menu exists
      this.validateMenuExistance(menuId);

      // Search for menu item
      _.each(this.menus[menuId].items, item => {
        if (item.state === parentItemState) {
          // Push new submenu item
          item.items.push({type: 'divider', position: options.position || 0, shouldRender: () => true});
          return false;
        }
      });

      // Return the menu object
      return this.menus[menuId];
    };

    // Add submenu item object
    this.addSubMenuItem = function(menuId, parentItemState, options) {
      options = options || {};

      // Validate that the menu exists
      this.validateMenuExistance(menuId);

      // Search for menu item
      _.each(this.menus[menuId].items, item => {
        if (item.state === parentItemState) {
          // Push new submenu item
          item.items.push({
            title: options.title || '',
            state: options.state || '',
            redirectPath: options.redirectPath || '',
            paramsFn: options.paramsFn || function() { return null; },
            isPublic: options.isPublic === null || typeof options.isPublic === 'undefined' ?
              item.isPublic : options.isPublic,
            isPremium: options.isPremium === null || typeof options.isPremium === 'undefined' ?
              item.isPremium : options.isPremium,
            roles: options.roles === null || typeof options.roles === 'undefined' ?
              item.roles : options.roles,
            claims: options.claims === null || typeof options.claims === 'undefined' ?
              item.claims : options.claims,
            perspectives: options.perspectives === null || typeof options.perspectives === 'undefined' ?
              item.perspectives : options.perspectives,
            featureFlags: options.featureFlags === null || typeof options.featureFlags === 'undefined' ?
              item.featureFlags : options.featureFlags,
            position: options.position || 0,
            planConstraint: options.planConstraint,
            shouldRender: options.shouldRender || shouldRender,
            icon: options.icon || null
          });
          return false;
        }
      });

      // Return the menu object
      return this.menus[menuId];
    };

    // Remove existing menu object by menu id
    this.removeMenuItem = function(menuId, menuItemURL) {
      // Validate that the menu exists
      this.validateMenuExistance(menuId);

      // Search for menu item to remove
      for (var itemIndex in this.menus[menuId].items) {
        if (this.menus[menuId].items[itemIndex].link === menuItemURL) {
          this.menus[menuId].items.splice(itemIndex, 1);
        }
      }

      // Return the menu object
      return this.menus[menuId];
    };

    // Remove existing menu object by menu id
    this.removeSubMenuItem = function(menuId, submenuItemURL) {
      // Validate that the menu exists
      this.validateMenuExistance(menuId);

      // Search for menu item to remove
      for (let itemIndex in this.menus[menuId].items) {
        for (let subitemIndex in this.menus[menuId].items[itemIndex].items) {
          if (this.menus[menuId].items[itemIndex].items[subitemIndex].link === submenuItemURL) {
            this.menus[menuId].items[itemIndex].items.splice(subitemIndex, 1);
          }
        }
      }

      // Return the menu object
      return this.menus[menuId];
    };

    //Adding the topbar menu
    this.addMenu('topbar', {isPublic: true});
  });
};
