module.exports = function(ngModule) {
  ngModule.component('frUserGroup', {
    bindings: {
      user: '<',
      members: '<',
      groups: '<'
    },
    template: require('./users.settings.group.template.html'),
    controller: require('./users.settings.group.controller.js'),
    controllerAs: 'vm'
  });
};
