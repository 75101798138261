module.exports = function(ngModule) {
  ngModule.component('cfProductComplaints', {
    bindings: {
      $productComplaints: '<',
      recall: '=',
      user: '<',
      complaintTypes: '<'
    },
    template: require('./recall.product-complaints.template.html'),
    controller: require('./recall.product-complaints.controller'),
    controllerAs: 'vm'
  });
};
