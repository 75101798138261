'use strict';

module.exports = function(ngModule) {
  ngModule.factory('SupplierSearch', function(Search) {
    'ngInject';

    class SupplierSearch extends Search {

      constructor(user, orgId, options) {
        options = options || {};
        super(user, {
          uri: 'search/supplier',
          type: 'supplier',
          max: options.size || 3000, // Maximum number of recs to pull back
          pageLimit: options.pageLimit || 30,    // Number of recs to show per page
          body: {
            query: {
              bool: {
                must: [{
                  term: {
                    owningOrg: orgId
                  }
                }]
              }
            },
            sort: ['name.keyword']
          },
          context: {
            owningOrg: [orgId]
          }
        }, 'suppliers', false);
        this.search();
      }

      /**
       * Wait for the initial search to finish and then return the search object.
       * @return {object} The search object
       */
      $initialized() {
        return this.searchPromise.then(() => this);
      }

      search(text) {
        var body = this.getSearchQuery();

        delete body.query.bool.should;
        delete body.query.bool['minimum_should_match'];
        if (text) {
          body.query.bool.should = [
            {
              'multi_match': {
                query: text.toLowerCase(),
                type: 'phrase_prefix',
                fields: ['name', 'description'],
                slop: 3,            // Allow terms to appear out of order by this many positions
                'max_expansions': 20  // To improve performance, limit results of the final search term to this many
              }
            },
            {
              term: {
                externalNumber: text
              }
            }
          ];
          body.query.bool['minimum_should_match'] = 1;
        }
        this.setSearchQuery(body);
        return super.search();
      }
    }

    return SupplierSearch;
  });
};
