module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.tests', {
        url: '/tests',
        template: '<fr-tests></fr-tests>',
      });
  });
};
