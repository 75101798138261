'use strict';

require('./_css');

module.exports = function (parentModuleName) {
  var moduleName = parentModuleName + '.verification',
    ngModule = angular.module(moduleName, []);

  require('./_components')(ngModule);
  require('./_constants')(ngModule);

  return moduleName;
};
