module.exports = function(ngModule) {
  ngModule.component('cfOrderPaymentControls', {
    require: {
      orderCtrl: '^^cfOrderPayment'
    },
    bindings: {
      onSubmit: '&',
      onCancel: '&',
      onBack: '&?'
    },
    template: require('./order-payment.controls.template.html'),
    controller: require('./order-payment.controls.controller.js'),
    controllerAs: 'vm'
  });
};
