module.exports = function(ngModule) {
  ngModule.component('cfProgressIntroModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./users.progress-intro-modal.template.html'),
    controller: require('./users.progress-intro-modal.controller'),
    controllerAs: 'vm'
  });
};