class Constructor {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.tour = this.resolve.tour;
  }
}

module.exports = Constructor;
