class Constructor {
  constructor(growl, $log, accessRequests, $q, supplierService) {
    'ngInject';

    this.growl = growl;
    this.$log = $log;
    this.accessRequests = accessRequests;
    this.$q = $q;
    this.supplierService = supplierService;
  }

  $onInit() {
    this.records = this.resolve.records;
    this.user = this.resolve.user;
    this.company = this.resolve.company;
  }

  import() {
    let errorCount = 0;

    this.importing = true;
    return this.$q.all(_.map(this.records, rec => {
      delete rec.index;

      return this.supplierService.$push()
        .then($supplier => {
          _.assign($supplier, rec);
          return this.supplierService.saveSupplier(this.user, $supplier, this.company)
            .then(orgCreated => {
              if (!orgCreated) {
                return this.$q.reject('Unexpected return parameter when saving an imported supplier');
              }
            }).then(() => $supplier);
        })
        .catch(err => {
          errorCount++;
          this.$log.error('Error occurred during supplier import.', {
            errStr: this.$log.toString(err),
            recStr: angular.toJson(rec)
          });
        });
    })).then(newSuppliers => {
      this.modalInstance.close({
        newSuppliers: newSuppliers,
        total: this.records.length,
        errors: errorCount
      });
    }).finally(() => {
      this.importing = false;
    });
  }
}

module.exports = Constructor;
