class Controller {
  constructor(equipmentService, S3, confirmDeleteModal, utils) {
    'ngInject';

    this.equipmentSvc = equipmentService;
    this.S3 = S3;
    this.confirmDeleteModal = confirmDeleteModal;
    this.utils = utils;
  }

  $onInit() {
    this.equipment = this.resolve.equipment;
    this.organizationId = this.resolve.organizationId;
    this.user = this.resolve.user;
    if (!this.equipment) {
      this.equipment = {};
      this.isNew = true;
    }
    this.equipment.organizationId = this.organizationId;
    this.equipment.organization = this.organizationId;
    this.remoteDirectory = `equipment/${this.equipment.id || this.equipment.$id}`;
    this.specs = this.equipment.specs || [];
  }

  cancel() {
    if (this.isNew && !_.isEmpty(this.specs)) {
      const spec = _.first(this.specs);

      this.S3.deleteFile(this.user.orgContext.id, spec.key).then(() => this.equipment.$remove())
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove file'));
    }
    this.modalInstance.dismiss('cancel');
  }

  save() {
    this.saving = true;
    if (this.isNew) {
      this.equipmentSvc.push(this.equipment)
        .then(result => this.modalInstance.close(result))
        .catch((err) => this.modalInstance.dismiss(err));
    }else {
      this.equipmentSvc.update(this.equipment.id || this.equipment.$id ,this.equipment)
        .then(result => this.modalInstance.close(result))
        .catch((err) => this.modalInstance.dismiss(err));
    }
  }
  openSpec() {
    this.S3.openSignedUrlInNewTab(this.user.orgContext.id, _.first(this.specs).key);
  }

  onUploadClick() {
    // If they are attempting to upload a file, first we gotta save the rec.
    if (this.isNew) {
      return this.equipmentSvc.push(this.equipment);
    }
  }

  removeSpec() {
    const spec = _.first(this.specs);

    this.confirmDeleteModal(spec.name).then(() => {
      return this.S3.deleteFile(this.user.orgContext.id, spec.key).then(() => this.specs.$remove(0))
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove file'));
    });
  }
}

module.exports = Controller;
