class Controller {
  constructor(
    $timeout,
    preventDirtyNav,
    $state,
    growl,
    $log,
    checklistLibraryService,
    $uibModal,
    $window,
    constantsService,
    $stateParams,
    users
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.$state = $state;
    this.growl = growl;
    this.$log = $log;
    this.checklistLibraryService = checklistLibraryService;
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.checklistTypes = [
      {name: 'Receiving Ingredients', value: 'receivingIngredients'},
      {name: 'Audit', value: 'audit'},
      {name: 'Other', value: 'other'},
    ];
    this.constantsService = constantsService;
    this.$stateParams = $stateParams;
    this.users = users;
  }

  $onInit() {
    this.sections = [];
    if (!this.checklistTemplate) {
      this.checklistTemplate = {};
      this.checklistTemplate.grid = this.$stateParams.grid === 'true';
      this.addCategory();
    } else {
      let categories = this.checklistTemplate.categories || [];

      this.checklistTemplate.questions.forEach((question) => {
        if (question.category >= 0) {
          if (this.sections[question.category]) {
            this.sections[question.category].questions.push(question);
          } else {
            let category = categories[question.category] || '';

            this.sections.push({category: category, questions: [question]});
          }
        } else {
          if (this.sections[0]) {
            this.sections[0].questions.push(question);
          } else {
            this.sections.push({category: '', questions: [question]});
          }
        }
      });
    }
    if (this.checklistTemplate.updatedBy) {
      this.users.getName(this.checklistTemplate.updatedBy)
        .then((name) => this.updatedBy = name);
    } else if (this.checklistTemplate.createdBy) {
      this.users.getName(this.checklistTemplate.createdBy)
        .then((name) => this.createdBy = name);
    }
    this.constantsService.get('tags').then((tags) => this.tags = tags);
  }

  addCategory() {
    this.sections.push({category: 'Section ' + (this.sections.length + 1), questions: []});
    this.addQuestion();
  }

  addQuestion(section, question) {
    this.$uibModal
      .open({
        component: 'frChooseFromItemModal',
        animation: true,
        resolve: {
          header: () => 'Add Form Item',
        },
        size: 'lg',
      })
      .result.then((answerType) => {
        let count = this.sortQuestionIds();
        let questions = this.sections[this.sections.length - 1].questions;
        let questionDetails = {
          question: '',
          questionId: count,
          showDescription: true,
          forms: this.formConstants[answerType],
        };

        if (answerType === 'yesNo') {
          questionDetails.compliantAnswer = '1';
        }

        if (answerType === 'multiselect' || answerType === 'dropdownSelect') {
          questionDetails.forms.config = {options: []};
        }

        if (section && question) {
          if (section.questions.length > 0) {
            section.questions.splice(
              _.findIndex(section.questions, {questionId: question.questionId}) + 1,
              0,
              questionDetails
            );
            this.sortQuestionIds();
          }
        } else {
          questions.push(questionDetails);
          // eslint-disable-next-line angular/document-service
          this.$window.scrollTo(0, document.body.scrollHeight);
        }
      });
  }

  removeQuestion(section, question) {
    if (section.questions.length > 1) {
      let questions = [];
      let count = 0;

      section.questions.forEach((checklistQuestion) => {
        if (checklistQuestion.questionId !== question.questionId) {
          checklistQuestion.questionId = count;
          count++;
          questions.push(checklistQuestion);
        }
      });
      section.questions = questions;
    }
    this.sortQuestionIds();
  }

  removeCategory(categoryId) {
    let filterSections = [];

    this.sections.forEach((category, i) => {
      if (i !== categoryId) {
        filterSections.push(category);
      }
    });
    this.sections = filterSections;
    this.sortQuestionIds();
  }

  cancel() {
    if (this.checklistLibForm && this.checklistLibForm.$dirty) {
      this.checklistLibForm.$setPristine();
      this.saveToLibrary();
    } else {
      this.$state.go('checklists.library.list');
    }
  }

  saveToLibrary() {
    delete this.checklistTemplate.$id;
    this.checklistTemplate.version = 2;
    this.checklistTemplate.questions = [];
    this.checklistTemplate.categories = [];
    this.sections.forEach((section, i) => {
      this.checklistTemplate.categories.push(section.category);
      section.questions.forEach((question) => {
        delete question.showDescription;
        question.category = i;
        this.checklistTemplate.questions.push(question);
      });
    });
    if (this.checklistTemplate.templateId) {
      this.checklistLibraryService
        .updateChecklistTemplate(this.user.orgContext.id, this.checklistTemplate.templateId, this.checklistTemplate)
        .then(() => {
          this.growl.success('Checklist Template Updated');
          this.$state.go('checklists.library.list');
        });
    } else {
      this.checklistLibraryService.addChecklistTemplate(this.user.orgContext.id, this.checklistTemplate).then(() => {
        this.growl.success('Checklist Template Added');
        this.$state.go('checklists.library.list');
      });
    }
  }

  sortQuestionIds() {
    let count = 0;

    _.forEach(this.sections, (section) => {
      section.questions.forEach((question) => {
        question.questionId = count;
        count++;
      });
    });
    return count;
  }

  editQuestion(question) {
    this.$uibModal
      .open({
        component: 'frChooseFromItemModal',
        animation: true,
        resolve: {
          header: () => 'Add Form Item',
        },
        size: 'lg',
      })
      .result.then((answerType) => {
        let questionDetails = {
          forms: this.formConstants[answerType],
        };

        if (answerType === 'yesNo') {
          questionDetails.compliantAnswer = '1';
        }

        if (answerType === 'multiselect' || answerType === 'dropdownSelect') {
          questionDetails.forms.config = {options: []};
        }
        Object.assign(question, questionDetails);
      });
  }
  // openEditor(checklistDescription) {
  // this.$uibModal.open({
  //   component: 'cfWysiwygCompressed',
  //   controllerAs: 'vm',
  //   backdrop: 'static',
  //   size: 'lg',
  //   resolve: {
  //     label: () => 'Description',
  //     ngModel: () => checklistDescription,
  //     hideToolbar: () => true,
  //     maxHeight: () => 100
  //   }
  // });
  // }
}

module.exports = Controller;
