module.exports = function(ngModule) {
  ngModule.factory('NotificationSearch', function(Search, notificationScopes, notificationTypes, moment) {
    'ngInject';

    let types = notificationTypes();

    class NotificationSearch extends Search {
      constructor(user, options) {
        options = options || {};
        super(user, {
          uri: 'search/notification',
          max: options.max || 300, // Maximum number of recs to pull back
          context: {
            or: {}
          },
          type: 'notification',
          pageLimit: 30,    // Number of recs to show per page
          body: {
            sort: [
              {createdOn: {order: 'desc'}}
            ],
            query: {
              bool: {
                should: [],
                'must_not': {
                  term: {
                    hide: user.uid
                  }
                }
              }
            }
          }
        }, 'notifications', false);

        this.scopes = notificationScopes();
      }

      setUser(uid) {
        this.options.context.or.uid = [uid];
        this.userQuery = {
          bool: {
            must: [
              {
                term: {
                  uid: uid
                }
              },
              {
                term: {
                  scope: this.scopes.USER
                }
              }
            ]
          }
        };

        this._resetNotificationQueries();
      }

      setOrganizations(orgIds) {
        this.options.context.or.organizationId = orgIds;
        this.orgQuery = {
          bool: {
            must: [
              {
                terms: {
                  organizationId: orgIds
                }
              },
              {
                term: {
                  scope: this.scopes.ORGANIZATION
                }
              }
            ]
          }
        };

        this._resetNotificationQueries();
      }

      setType(type) {
        this.typeQuery = {
          term: {
            type: type
          }
        };

        this._resetNotificationQueries();
      }

      _resetNotificationQueries() {
        this.queryData.body.query.bool.should = [];
        this.queryData.body.query.bool.must = [];
        if (this.orgQuery) {
          this.queryData.body.query.bool.should.push(this.orgQuery);
        }

        if (this.userQuery) {
          this.queryData.body.query.bool.should.push(this.userQuery);
        }

        if (this.typeQuery) {
          this.queryData.body.query.bool.must.push(this.typeQuery);
        }

        if (this.queryData.body.query.bool.should.length) {
          this.queryData.body.query.bool['minimum_should_match'] = 1;
        }
      }

      search() {
        this.searching = true;
        return super.search().then(results => {
          this.searchResults = this.onMoreResults(results);
          return this.searchResults;
        }).finally(() => {
          this.searching = false;
        });
      }

      getMore() {
        let getMorePromise = super.getMore();

        if (!getMorePromise) { return; }

        return getMorePromise
          .then(results => {
            this.searchResults = this.onMoreResults(results);

            return this.searchResults;
          });
      }

      onMoreResults(results) {
        let grouped = _.groupBy(results, 'type');

        // merge the product updated notifications
        if (grouped[types.PRODUCT_UPDATED.id]) {
          grouped[types.PRODUCT_UPDATED.id] = this.mergeProductChanged(grouped[types.PRODUCT_UPDATED.id]);
        }

        results = _.reduce(grouped, (newArray, val) => _.concat(newArray, val), []);
        return _.orderBy(results, ['createdOn'], ['desc']);
      }

      mergeProductChanged(notifications) {
        let subGroups = _.groupBy(notifications, p => {
          let date = new Date(0);

          date.setUTCMilliseconds(p.createdOn);

          return p.metaData.supplierName + '_' + p.metaData.productChanged + '_' +
            moment(date).format('YYYYMD');  // group by product for a given date
        });

        return _.map(subGroups, (groupedNotifications, groupName) => {
          let groupParts = groupName.split('_'),
            initial = {
              createdOn: -1,
              type: types.PRODUCT_UPDATED.id,
              metaData: {
                supplierName: groupParts[0],
                productName: groupParts[1],
                productId: _.first(groupedNotifications).metaData.productId,
                propertiesChanged: []
              }
            };

          return _.reduce(groupedNotifications, (newObj, notification) => {
            if (notification.createdOn > newObj.createdOn) {
              newObj.createdOn = notification.createdOn;
            }

            newObj.createdBy = newObj.createdBy || notification.createdBy;
            newObj.metaData.propertiesChanged.push(_.assign(notification.metaData, {
              id: notification.$id,
              read: notification.read
            }));

            return newObj;
          }, initial);
        });
      }
    }

    return NotificationSearch;
  });
};
