module.exports = function($uibModal, growl, $log, $state, utils) {
  'ngInject';

  const vm = this;

  vm.$state = $state;

  vm.help = function() {
    $uibModal.open({
      template: $state.current.data.titleBar.helpModal,
      controller: function($scope, $uibModalInstance) {
        $scope.ok = function() {
          $uibModalInstance.close();
        };

        $scope.cancel = function() {
          $uibModalInstance.close();
        };
      }
    }).result
    .catch((reason) => {
      if (utils.isModalDismissalByUser(reason)) { return; }

      $log.error(reason);
    });
  };
};
