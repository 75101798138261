class Controller {
  constructor(companyService, organizations, $rootScope, cfpLoadingBar, $state, CF_CLAIMS, sopService,
              gmps, $q, productSearch, utils, notifications, users, $window, $http, constantsService,
              supplierService, $interval, $stateParams, notificationTypes, notificationScopes, moment, $uibModal,
              $timeout, subscriptionService, sopLogsService, miniTourService, confirmModal) {
    'ngInject';

    this.companyService = companyService;
    this.organizations = organizations;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.CF_CLAIMS = CF_CLAIMS;
    this.sopService = sopService;
    this.gmpsService = gmps;
    this.$q = $q;
    this.productSearch = productSearch;
    this.utils = utils;
    this.notificationsService = notifications;
    this.sopLogsService = sopLogsService;
    this.users = users;
    this.$window = $window;
    this.$http = $http;
    this.constantsService = constantsService;
    this.supplierService = supplierService;
    this.$interval = $interval;
    this.$stateParams = $stateParams;
    this.moment = moment;
    this.notificationTypes = notificationTypes();
    this.notificationScopes = notificationScopes();
    this.$uibModal = $uibModal;
    this.$timeout = $timeout;
    this.subscriptionService = subscriptionService;
    this.miniTourService = miniTourService;
    this.confirmModal = confirmModal;

    this.gmps = {
      all: [],
      org: {},
      stats: {},
      data: [],
      labels: ['Unanswered Questions', 'Answered Questions', 'Non-compliant Answers'],
      colors: ['rgba(255,0,0,0.7)', 'rgba(114,192,44,0.7)', 'rgba(229,125,32,0.7)']
    };

    this.safetyPlans = {
      data: []
    };

    this.notifications = {
      unread: []
    };

    this.supplyChain = {
      files: {
        data: [0, 0],
        labels: ['Files Needed', 'Files Received'],
        colors: ['rgba(255,0,0,0.7)', 'rgba(114,192,44,0.7)'],
        options: {
          title: {
            display: true,
            text: 'Supplier Management'
          }
        }
      },
      requests: {
        data: [0, 0],
        labels: ['Requests Sent', 'Requests Delivered', 'Requests Failed', 'Requests Read'],
        colors: ['rgba(255,0,0,0.7)', 'rgba(114,192,44,0.7)'],
        options: {
          title: {
            display: true,
            text: 'Supplier Management'
          }
        }
      }
    };

  }

  $onInit() {
    const onPrimary = this.user.onPrimaryOrg();

    this.subTitle = onPrimary ? '' :
        `<span class="g-ml-10 text-uppercase text-info">${this.user.orgContext.companyName}</span>`;
    this.canReadNotifications = onPrimary || this.user.isCfAdmin();
    if (this.$stateParams.welcome === 'true' && !this.user.isTipHidden('welcomeNotification')) {
      let welcomeMsg = 'Welcome to FoodReady! Visit the <a target="_blank" href="https://player.vimeo.com/video/192506565">' +
        'Getting Started Video</a> to learn how FoodReady can help you keep your food safe!';

      welcomeMsg += _.get(this.$company, 'onboarding.noHelpNeeded', false) ? '' :
        '<br><br>A representative will be contacting you soon.  To speed things up, <a target="_blank" ' +
        'href="https://foodready.drift.com/chrismetz"><strong>schedule an appointment or chat</strong></a> with one of our food safety experts!';

      this.$timeout(() => this.showMiniTour());
      this.user.setTipHidden('welcomeNotification');

      let newNotification = {
        from: this.user.uid,
        to: this.user.uid,
        message: welcomeMsg,
        type: this.notificationTypes.DEFAULT.id
      };

      this.notifications.loading = true;
      this.notificationsService.postToUser(newNotification)
        .then((result) => {
          this.notifications.unread.push(_.assign(newNotification,
            {$id: result.key, scope: this.notificationScopes.USER}));
          return this.users.getName(this.user.uid)
            .then((name) => newNotification.createdByName = name);
        })
        .finally(() => this.loadNotifications(false));
    } else {
      this.loadNotifications(true);
    }

    this.loadGmps();
    this.loadPlans();
    this.loadSupplyChain();

    this.notificationInterval = this.$interval(() => this.loadNotifications(false), 60000);

    if (this.$stateParams.activate === 'true' && _.get(this.user, 'subscription.status') === 'trialing') {
      return this.confirmModal({
        title: 'Your Free Trial is Ending',
        body: 'Your free trial is ending soon. Would you like to activate your subscription now?',
        okText: 'Activate Trial',
        cancelText: 'No'
      }).then(() => this.subscriptionService.promptForActivation(this.user));
    }
  }

  $onDestroy() {
    this.$interval.cancel(this.notificationInterval);
  }

  goToPlan($event, plan) {
    $event.stopPropagation();
    this.$state.go('products.edit', {productId: plan.$id});
  }

  goToPlans($event) {
    if ($event) { $event.stopPropagation(); }

    this.$state.go('products.list');
  }

  goToGmps(categoryName) {
    let categoryId = _.get(_.find(this.gmps.all, {name: categoryName}), '$id');

    this.$state.go('checklists.gmps.edit.category', {auditId: this.gmps.org.$id, categoryId: categoryId});
  }

  goToGmpAudits() {
    this.$state.go('checklists.gmps.audits');
  }

  goToSupplyChain() {
    this.$state.go('ingredients.suppliers.list');
  }

  goToSupplierManagement($event) {
    $event.stopPropagation();
    this.$state.go('ingredients.fileTracking');
  }

  goToNotifications() {
    this.$state.go('user.notifications');
  }

  execNotificationAction(notification) {
    if (notification.modal) {
      return this.$uibModal.open({
        size: notification.modal.size,
        component: notification.modal.component,
        resolve: _.assign(_.reduce(notification.modal.resolve, (result, entry, entryKey) => {
          result[entryKey] = () => entry;
          return result;
        }, {}), {
          user: () => this.user
        })
      }).result
        .then(() => {
          if (notification.modal.deleteOnComplete) {
            this.notificationsService.delete(notification);
          } else {
            this.notificationRead(notification);
          }
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to open notification.'));
    }
    if (!notification.link) { return; }

    if (notification.link.state) {
      this.$state.go(notification.link.state, notification.link.params);
    } else if (notification.link.uri && notification.link.newTab) {
      let reportWindow = this.$window.open('/api/reports/loading');

      this.$http.get(notification.link.uri)
        .success((url) => reportWindow.location.replace(url));
    } else if (notification.link.url && notification.link.newTab) {
      this.$window.open(notification.link.url, '_blank');
    }
    this.notificationRead(notification);
  }

  notificationRead(notification) {
    this.notificationsService.setRead(this.user.uid, notification.$id);
    _.remove(this.notifications.unread, n => n.$id === notification.$id);
  }

  loadGmps() {
    this.gmps.loading = true;
    this.$q.all([this.gmpsService.getAll(), this.gmpsService.fetchAudits(this.user.orgContext.id, 1)])
      .then(([allGmps, orgGmps]) => {
        this.gmps.all = allGmps;
        this.gmps.org = _.isEmpty(orgGmps) ? null : _.first(orgGmps);

        _.each(allGmps, (category) => {
          this.gmps.stats[category.$id] = {
            name: category.name,
            confirmedOn: _.get(this.gmps.org, `${category.$id}.confirmedOn`),
            totalQuestions: 0,
            answeredQuestions: 0,
            nonCompliantAnswers: 0
          };

          _.each(category.sections, (section) => {
            _.each(section.questions, (question, questionKey) => {
              let orgQuestion = _.get(this.gmps.org, `${category.$id}.questions.${questionKey}`);

              this.gmps.stats[category.$id].totalQuestions++;

              if (orgQuestion) {
                let answer = _.parseInt(orgQuestion.answer);

                this.gmps.stats[category.$id].answeredQuestions++;
                if (answer !== question.compliantAnswer && !orgQuestion.comment && answer !== -1) {
                  this.gmps.stats[category.$id].nonCompliantAnswers++;
                }
              }
            });
          });
        });

        _.each(this.gmps.stats, (category) => {
          this.gmps.data.push({
            data: [
              category.totalQuestions - category.answeredQuestions,
              category.answeredQuestions,
              category.nonCompliantAnswers
            ],
            options: {
              title: {
                display: true,
                text: category.name
              }
            }
          });
        });

        this.gmps.data = _.chunk(this.gmps.data, 3);
        this.gmps.loading = false;
      });
  }

  loadPlans() {
    this.safetyPlans.loading = true;
    let productSearch = this.productSearch.getSearch(this.user);
    let body = productSearch.getSearchQuery();

    body.sort = [{lastView: {order: 'desc'}}];
    productSearch.setSearchQuery(body);
    productSearch.search()
      .then(() =>
        this.safetyPlans.data = _.take(productSearch.searchResults || [], 3))
      .catch((err) => {
        this.$log.error(err);
        this.growl.error('Error occurred during product search.');
      })
      .finally(() => this.safetyPlans.loading = false);
  }

  loadNotifications(showLoadingIndicator) {
    this.notifications.loading = showLoadingIndicator;
    this.notificationsService.getUnreadNotifications(this.user)
      .then((result) => {
        let newUnread = _.take(result || [], 3);

        if (this.notifications.unread.length &&
          _.reduce(this.notifications.unread, (idString, old) => idString + old.$id, '') ===
          _.reduce(newUnread, (idString, old) => idString + old.$id, '')) {
          return this.$q.resolve();
        } else if (this.notifications.unread.length) {
          _.each(newUnread, (notification) => {
            let oldMatch = _.find(this.notifications.unread, {$id: notification.$id});

            notification.createdByName = oldMatch ? oldMatch.createdByName : null;
          });
        }

        this.notifications.unread = newUnread;
        _.each(this.notifications.unread, (notification) => {
          if (!notification.createdByName) {
            if (this.isSystemMessage(notification)) {
              notification.createdByName = 'FoodReady';
            } else {
              this.users.getName(notification.createdBy)
                .then((name) => notification.createdByName = name);
            }
          }
        });
      })
      .finally(() => this.notifications.loading = false);
  }

  isSystemMessage(notification) {
    return notification.createdBy === 'system' ||
      notification.createdBy === 'googleFunctions-planHandler' ||
      notification.createdBy === 'lambda:1';
  };

  loadSupplyChain() {
    this.supplyChain.files.filesNeeded = _.get(this.$company, 'supplierTracking.companyFileCategories');
    this.supplyChain.files.filesNeeded = this.supplyChain.files.filesNeeded &&
      _.map(this.supplyChain.files.filesNeeded.split(','), _.toNumber);

    if (this.supplyChain.files.filesNeeded && this.supplierSearch) {
      this.supplyChain.loading = true;
      this.supplierSearch.search()
        .then((results) => this.$q.all(_.map(results, (rec) =>
            this.supplierService.$getFiles(rec.$id)
              .then(($files) => {
                let numFound = _.reduce(this.supplyChain.files.filesNeeded, (sum, fileCat) =>
                  !!_.find($files, f => f.category === fileCat) ? sum + 1 : sum, 0);

                this.supplyChain.files.data[0] += this.supplyChain.files.filesNeeded.length - numFound;
                this.supplyChain.files.data[1] += numFound;

                $files.$destroy();
              })
          )
        ))
        .catch((err) => this.$log.error(err))
        .finally(() => this.supplyChain.loading = false);
    }
  }

  deleteNotification($event, notification) {
    $event.stopPropagation();
    this.notificationsService.smartDelete(this.user.uid, notification).then(() => this.$timeout(() => {
      _.remove(this.notifications.unread, n => n.$id === notification.$id);
    })).catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete notification.'));
  }

  showMiniTour() {
    const planLoaded = this.$company.onboarding.productId;
    const sopsLoaded = this.$company.onboarding.sopsLoaded;
    const expertServicesRequested = this.$company.onboarding.helpNeeded;

    if (planLoaded) {
      this.miniTourService.enqueueTour(this.user, {
        id: 'introPlan',
        width: 500,
        title: 'Congratulations, Your New Plan is Ready!',
        selector: '#haccpPcPlans',
        //type: this.miniTourService.type.CENTERED,
        contentHtml: 'You\'ve gotten off to a quick start with your food safety / HACCP plan.' +
          (expertServicesRequested ? ' While you wait for an expert to get in touch with you, v' : ' V') +
          'isit the <strong>HACCP/PC Plans</strong> page to review it and make adjustments ' +
          'based on your product\'s specific production process.'
      });
    } else {
      this.miniTourService.enqueueTour(this.user, {
        id: 'introPlan',
        width: 500,
        selector: '#haccpPcPlans',
        title: 'You are Ready to Start Planning!',
        contentHtml:
          (expertServicesRequested ? ' While you wait for an expert to get in touch with you, v' : 'V') +
          'isit the <strong>HACCP/PC Plans</strong> page to add your first food safety / HACCP plan. ' +
          'You can choose from one of our plan templates, create one based on the sample plan, ' +
          'or build one from scratch.'
      });
    }

    if (sopsLoaded) {
      this.miniTourService.enqueueTour(this.user, {
        id: 'introSop',
        width: 500,
        selector: '#operations',
        title: 'Use FoodReady in Your Operations',
        contentHtml: 'When you finish your plan, visit the <b>Operations</b> menu ' +
          'to begin record keeping. A good HACCP / Food Safety Plan must have solid underlying ' +
          'procedures implemented across your establishment, and must demonstrate its use ' +
          'with historical logs.'
      });
    } else {
      this.miniTourService.enqueueTour(this.user, {
        id: 'introSop',
        width: 500,
        selector: '#operations',
        title: 'Use FoodReady in Your Operations',
        contentHtml: 'When you finish your plan, visit the <b>Operations</b> menu ' +
          'to complete your standard operating procedures (SOPs) and begin record keeping. ' +
          'A good HACCP / Food Safety Plan must have solid underlying procedures implemented across ' +
          'your establishment, and must demonstrate its use with historical logs. ' +
          'We have several SOP templates to help you get started.'
      });
    }
  }
}

module.exports = Controller;
