class Controller {
  constructor($state, $uibModal, $log, organizations, billing, users, $q,
    authentication, cfpLoadingBar, growl, companySubscription) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.organizations = organizations;
    this.billing = billing;
    this.users = users;
    this.$q = $q;
    this.authentication = authentication;
    this.cfpLoadingBar = cfpLoadingBar;
    this.growl = growl;
    this.companySubscription = companySubscription;
  }

  $onInit() {
    this.DEFAULT = 'default';

    this.stripeProducts = this.stripeConstants.stripeProducts;
    this.productConstraints = this.stripeConstants.constraints;
    this.subscriptionPlans = this.stripeConstants.subscriptionPlans;
    let product = _.get(this.$state, 'params.prod');
    let payFreq = _.get(this.$state, 'params.freq');
    let trial = _.get(this.$state, 'params.trial');
    let planId = _.get(this.$state, 'params.plan', null);
    let couponId = _.get(this.$state, 'params.coupon', null);


    this.payPeriod = 'monthly';
    this.plansPromise = this.billing.getPlans(this.user.orgContext.id)
      .then((result) => {
        this.plans = _.keyBy(result.data, 'id');
        return result.data;
      });
    this.getSkuPrice();
    // if (product === this.DEFAULT) {
    //   product = this.stripeConstants.defaultProduct;
    //   planId = this.stripeConstants.defaultFreePlan;
    //   trial = false;
    //   payFreq = 'monthly';
    // }
    this.startingTrial = true;
    product = this.stripeConstants.standardProduct;
    if(this.company && this.company.restrictTemplates) {
      product = this.stripeConstants.studentProduct;
    }
    payFreq = 'monthly';
    this.startSubscriptionTrial(product, payFreq, couponId);
  }

  getSkuPrice() {
    if (!this.stripeConstants.planSku) {
      throw new Error('Defaut SKU missing');
    }

    return this.billing.getSku(this.stripeConstants.planSku).then(sku => {
      this.skuPrice = sku.price / 100;
    }).catch(err => {
      this.$log.error('Unable to retrieve payAsGo sku price', this.$log.toString(err));
      this.skuPrice = 'X';
    });
  };

  getPrice(planIds) {
    if (_.isUndefined(planIds) || !this.plans) { return '...'; }
    let tierAmount;

    switch (this.payPeriod) {
      case 'semi-annual':
        tierAmount = this.billing.getPriceForTier(this.plans[planIds.SEMIANNUAL], 1);

        return Math.round(tierAmount / 600);
      case 'annual':
        tierAmount = this.billing.getPriceForTier(this.plans[planIds.ANNUAL], 1);

        return Math.round(tierAmount / 1200);
      default:
        tierAmount = this.billing.getPriceForTier(this.plans[planIds.MONTHLY], 1);
        return Math.round(tierAmount / 100);
    }
  }

  constraints(stripeProduct) {
    const constraint = this.productConstraints[stripeProduct].rateConstants || this.productConstraints[stripeProduct];
    /*
        let returnHtml =
          `<li class="g-mb-10"><b class="text-primary">${constraint.PLANS === -1 ? 'Unlimited' : constraint.PLANS}</b> ` +
          `Preventive Control/HACCP Plan${constraint.PLANS === -1 || constraint.PLANS > 1 ? 's' : ''}</li>`;
    */

    let returnHtml = stripeProduct === this.stripeProducts.PAY_AS_GO ?
      '<li class="g-mb-10">' +
      `<b class="text-primary">${constraint.FACILITIES === -1 ? 'Unlimited' : constraint.FACILITIES}</b> ` +
      `Facilit${constraint.FACILITIES === -1 || constraint.FACILITIES > 1 ? 'ies' : 'y'}</li>` : '';

/*
    returnHtml +=
      '<li class="g-mb-10">' +
      `<b class="text-primary">${constraint.SUPPLIERS === -1 ? 'Unlimited' : constraint.SUPPLIERS}</b> ` +
      `Supplier${constraint.SUPPLIERS === -1 || constraint.SUPPLIERS > 1 ? 's' : ''}</li>`;


    returnHtml +=
      '<li class="g-mb-10">' +
      `<b class="text-primary">${constraint.USERS === -1 ? 'Unlimited' : constraint.USERS}</b> ` +
      `User${constraint.USERS === -1 || constraint.USERS > 1 ? 's' : ''}</li>`;*/

    return returnHtml;
  }

  orderSubscription(product, payFreq, planId, couponId) {
    if (this.ordering) { return; }
    planId = planId || this.getPlanId(product, payFreq);

    this.choosing = true;
    this.cfpLoadingBar.start();

    return this.$q.all({
      plans: this.plansPromise,
      title: this.billing.getProductName(product)
    })
      .then(({plans, title}) => {
        let chosenPlan = _.find(plans, {id: planId});

        // Make sure we signed em up for a plan that's setup to charge them what they agree to
        if (_.get(chosenPlan, 'metadata.planSku') !== this.stripeConstants.planSku) {
          this.$log.error('Plan missing planSku metadata property. User agreed to a sku price that they wont get!');
        }

        if (!chosenPlan) {
          this.utils.defaultErrorHandler('Invalid plan chosen', {planId});
        }
        if (!chosenPlan.flat_amount_decimal) {
          return this.companySubscription.changePlan(this.user, planId,{quantity: 1, coupon: couponId});
        }

        return this.$uibModal.open({
          component: 'cfSubscriptionOrder',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            user: () => this.user,
            title: () => title,
            plan: () => chosenPlan
          }
        }).result;
      })
      .then(() => this.user.deletePerspectiveOverride())
      .then(() => this.$state.go('onboarding.firstPlan', this.commonOnboardingStateParams()))
      .catch(err => {
        this.utils.defaultErrorHandler(err, 'An error occurred placing your subscription order.');
        this.showPricing = true;
      })
      .finally(() => {
        this.choosing = false;
        this.cfpLoadingBar.complete();
      });
  };

  commonOnboardingStateParams() {
    return _.pick(this.$state.params, ['productCategory', 'template', 'serviceId']);
  }

  /**
   * Start a user on a free trial. No payment information will be captured. The payment processor's customer and
   * subscription objects will be created.
   * @param {string} product The Stripe product.
   * @param {string} payFreq The user chosen pay frequency.
   * @param {string} couponId Affiliate Discount Coupon.
   * @return {Promise} A promise that resolves to the subscription object.
   */
  startSubscriptionTrial(product, payFreq, couponId) {
    if (this.choosing) { return; }
    this.choosing = true;

    if (!this.user.onPrimaryOrg()) {
      throw new Error('Cannot start a free trial when user is not on primary company.');
    }
    const planId = this.getPlanId(product, payFreq);

    this.cfpLoadingBar.start();
    this.$log.info('users.plan-chooser.controller.js:startSubscriptionTrial: Starting Subscription.',
      {customerId: this.user.customerId, planId});

    return this.companySubscription.changePlan(this.user, planId, {'trial_from_plan': true, quantity: 1, coupon: couponId})
      .then(() => this.user.deletePerspectiveOverride())
      .then(() => this.$state.go('onboarding.firstPlan', this.commonOnboardingStateParams()))
      .catch(err => {
        this.showPricing = true;
        this.cfpLoadingBar.complete();
        this.choosing = false;
        this.$log.error('An error occurred starting a subscription.', err);
        this.growl.error('An error occurred selecting your plan. ' +
          'If this continues, please contact customer support.');
      });
  }

  getPlanId(product, payFreq) {
    switch (payFreq) {
    case 'monthly':
      return this.subscriptionPlans[product].MONTHLY;
    case 'annual':
      return this.subscriptionPlans[product].ANNUAL;
    case 'semi-annual':
      return this.subscriptionPlans[product].SEMIANNUAL;
    default:
      return this.subscriptionPlans[product].DEFAULT;
    }

  }
}

module.exports = Controller;
