module.exports = function($scope, confirmDeleteModal, S3) {
  'ngInject';

  const vm = this;

  function refreshFiles() {
    let files = _.concat(vm.newFiles, vm.existingFiles);

    _.remove(files, (file) => {
      return _.findIndex(vm.removeFiles, {name: file.name}) !== -1;
    });

    vm.files = files;
  }

  vm.$onInit = function() {
    vm.files = [];
    $scope.$watch('vm.existingFiles', (val) => {
      if (val.length && vm.newFiles.length) {
        _.each(val, (existingFile) => {
          _.remove(vm.newFiles, (newFile) => {
            return existingFile.name === newFile.name;
          });
        });
      }

      refreshFiles();
    }, true);
  };

  vm.addFiles = function($files) {
    if (vm.onUpdate) { vm.onUpdate(); }
    let filesToAdd = [], isExistingFile = false, removingFile = false, alreadyAddingFile = false;

    _.each($files, (file) => {
      isExistingFile = _.findIndex(vm.existingFiles, {name: file.name}) !== -1;
      removingFile = _.findIndex(vm.removeFiles, {name: file.name}) !== -1;
      alreadyAddingFile = _.findIndex(vm.newFiles, {name: file.name}) !== -1;

      if (removingFile) {
        _.remove(vm.removeFiles, (f) => {
          return f.name === file.name;
        });
      } else if (!isExistingFile && !alreadyAddingFile) {
        filesToAdd.push(file);
      }
    });

    Array.prototype.push.apply(vm.newFiles, filesToAdd);
    refreshFiles();
  };

  vm.removeFile = function(file) {
    let isExistingFile = _.findIndex(vm.existingFiles, {name: file.name}) !== -1,
      addingFile = _.findIndex(vm.newFiles, {name: file.name}) !== -1;

    if (vm.onUpdate) { vm.onUpdate(); }
    if (addingFile) {
      _.remove(vm.newFiles, (f) => {
        return f.name === file.name;
      });

      refreshFiles();
    } else if (isExistingFile) {
      vm.removeFiles.push(file);
      refreshFiles();
    }
  };

  vm.openFile = function(file) {
    if (file.key) {
      S3.openSignedUrlInNewTab(vm.organization.$id, file.key);
    }
  };
};
