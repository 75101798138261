module.exports = function(moment, $state, fbutil, growl, $log, $q, notifications, users) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    if (vm.isSystemMessage()) { return; }

    if (vm.notification.createdBy) {
      users.getName(vm.notification.createdBy)
        .then((name) => vm.createdByName = name);
    }
  };

  vm.isUnread = function() {
    return _.every(vm.notification.metaData.propertiesChanged, prop => _.indexOf(prop.read, vm.user.uid) === -1);
  };

  vm.go = function() {
    if (vm.deleting) { return; }

    $state.go('products.edit.reports', {
      productId: vm.notification.metaData.productId
    });

    if (vm.isUnread()) {
      _.each(vm.notification.metaData.propertiesChanged, notification => {
        notification.read = notification.read || [];
        notification.read.push(vm.user.uid);
        fbutil.ref('notifications', notification.id, 'read').push(vm.user.uid);
      });
    }
  };

  vm.delete = function() {
    vm.deleting = true;
    vm.onDelete();

    $q.all(_.map(vm.notification.metaData.propertiesChanged, notification => {
      return notifications.smartDelete(notification);
    }))
      .catch(err => {
        $log.error(err);
        growl.error('Unable to delete notification');
      });
  };

  vm.propertiesChangedMarkup = function() {
    let markup = _.reduce(vm.notification.metaData.propertiesChanged, (result, prop) => {
      return result + '<tr>' +
        '<td>' + prop.propertyChanged + '</td>' +
        '<td>' + (prop.prevVal || '<i>empty</i>') + '</td>' +
        '<td>' + (prop.newVal || '<i>empty</i>') + '</td>' +
        '</tr>';
    }, '');

    return '<div><table class="table">' +
      '<thead class="thead-dark">' +
      '<tr>' +
      '<th class="col-sm-4">Property</th>' +
      '<th class="col-sm-4">Previous Value</th>' +
      '<th class="col-sm-4">New Value</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>' +
      markup +
      '</tbody>' +
      '</table></div>';
  };
};
