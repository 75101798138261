'use strict';

module.exports = function (ngModule) {
  ngModule.component('cfEditableField', {
    bindings: {
      ngModel: '<',
      fieldType: '@?',
      placeholder: '@?',
      onUpdate: '&'
    },
    template: require('./editable-field.template.html'),
    controller: require('./editable-field.controller'),
    controllerAs: 'vm'
  });
};
