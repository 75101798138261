class Controller {
  constructor($log, growl, CompanyNameSearch, marketplaceService, marketplaceRfqQuoteStatus, CF_ORGANIZATION_ID) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.CompanyNameSearch = CompanyNameSearch;
    this.marketplaceService = marketplaceService;
    this.marketplaceRfqQuoteStatus = marketplaceRfqQuoteStatus;
    this.CF_ORGANIZATION_ID = CF_ORGANIZATION_ID;
  }

  $onInit() {
    this.requestForQuote = this.resolve.requestForQuote;
    this.$quote = this.resolve.quote;
    this.user = this.resolve.user;

    this.canAssign = this.user.isCfAdmin() && this.user.currentOrgContext().id === this.CF_ORGANIZATION_ID;
    if (!this.canAssign) {
      this.$quote.providerOrgId = this.requestForQuote.subjectOrgId;
    } else {
      this.providerSearch = new this.CompanyNameSearch(this.user, false)
        .filterByTypes(this.marketplaceService.getProviderOrgTypes()).filterByAuthorizedProvider();
    }

    this.isNew = this.quote !== null;
    this.saving = false;
    this.modalTitle = this.isNew ? 'Submit Quote' : 'Edit Quote';
  }

  ok() {
    this.$quote.updatedDate = firebase.database.ServerValue.TIMESTAMP;
    this.$quote.updatedBy = this.user.uid;
    this.$quote.status = this.marketplaceRfqQuoteStatus.SUBMITTED;
    this.saving = true;
    this.$quote.$save()
      .then(() => {
        this.modalInstance.close(this.$quote);
      })
      .catch((err) => {
        this.growl.error('Unable to submit your quote. Please try again or contact ' +
          'customer support for assistance');
        this.$log.error(err);
        this.saving = false;
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  chooseProvider(provider) {
    this.$quote.providerOrgId = provider.$id;
    this.$quote.providerName = provider.companyName;
  }
}

module.exports = Controller;
