class Controller {
  constructor($q, $log, $timeout) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.$timeout = $timeout;
    this.MAX_RECS = 300;
  }

  $onInit() {
    const savePlaceholder = this.placeholder;

    this.nameProperty = this.nameProperty || 'name';
    this.$timeout(() => {
      this.placeholder = 'Loading...';
    });
    this.searchObject.$loaded().then(results => {
      if (results) {
        this.afterSearch(this.searchObject.searchText, results);
      }
    }).finally(() => {
      this.$timeout(() => {
        this.placeholder = savePlaceholder;
      });
    });
  }

  selectedText(item) {
    if (!item) { return; }
    return this.customNameText ? this.customNameText({$item: item}) : item[this.nameProperty];
  }

  afterSearch(text, results, isGetMore) {
    this.searchResults = results || [];
    if (this.resultFilter) {
      this.searchResults = this.resultFilter({$results: this.searchResults}) || [];
    }
    this.maxReached = this.searchResults.length >= this.MAX_RECS;

    // if we have no text in the input...
    if (!text) {

      // and there are no search results...
      if (!this.searchResults.length) {
        this.searchResults = [{noRecs: true}];

      // and there are results...
      } else if (!isGetMore && this.allowRemove) {
        this.searchResults.unshift({delete: true});
      }

    // if we have text in the input...
    } else {

      // and there are no search results...
      if (!this.searchResults.length) {
        if (this.noCreate) {
          this.searchResults = [{noRecs: true}];
        } else {
          let customRec = {custom: true};

          customRec[this.nameProperty] = text;
          this.searchResults = [customRec];
        }
      }
    }

    // fetch the html for this item
    for (let item of this.searchResults) {
      item.html = this.dropdownHtml(item);
    }
  }

  dropdownHtml(item) {
    let name = _.get(item, this.nameProperty);

    if (!item) {
      return;
    } else if (item.delete) {
      return '<i class="text-muted">-- None --</i>';
    } else if (item.custom) {
      return '<i class="color-green far fa-plus fa-fw"></i> Create "' + name + '"';
    } else if (item.noRecs) {
      return this.noCreate ?  '<i><b>No results found.</b></i>' :
        '<i><b>No results found.</b><br>Enter text to create a new entry.</i>';
    } else {
      if (this.customNameText) {
        name = this.customNameText({$item: item});
      }
      let html = '<div>' + name + '</div>';

      if (item.description) {
        html += '<i><small class="text-muted">' + item.description + '</small></i>';
      }

      return html;
    }
  };

  search(text) {
    return this.searchObject.search(text)
      .then(results => this.afterSearch(text, results))
      .catch(err => {
        this.$log.error('Error during cfUiSelect search: ' + angular.toJson(err));
      });
  }

  getMore(text) {
    return this.searchObject.getMore().then(results => {
      this.afterSearch(text, results, true);
    });
  }

  selectItem(item) {
    const createItemDefined = _.isFunction(this.createItem);

    if (!item) { return; }
    if (item.delete || item.noRecs) {
      delete this.ngModel;
    } else if (item.custom) {
      this.$q.when(createItemDefined ? this.createItem({$item: item}) : item)
        .then(newItem => {
          if (createItemDefined) {
            this.searchResults = [newItem];
          }
          this.ngModel = newItem;
          this.onItemSelected({$item: newItem});
        })
        .catch(err => {
          this.$log.error(err);
          delete this.ngModel;
          this.onItemSelected();
        });

      return;
    }

    this.onItemSelected({$item: item});
  }

  /**
   * HACK: Why do we need this? The ui-select control is not smart enough to parse out the HTML from the highlight-able text
   * @param {string} text The search text
   * @returns {boolean} Whether or not the search text should be highlighted (due to ui-select limitation)
   */
  canHighlight(text) {
    if (!text) { return true; }

    let ok = true,
      matchProblems = ['class', 'text', 'muted', 'green', 'far', 'fa-fw', 'fa-plus', 'b',
        'br', 'i', 'color', 'div', 'small', 'strong', 'p'],
      badChars = ['<', '>'];

    _.each(matchProblems, (problem) => {
      if (_.includes(problem, text.toLowerCase())) {
        ok = false;
        return false;
      }
    });

    if (ok) {
      _.each(badChars, (char) => {
        if (_.includes(text, char)) {
          ok = false;
          return false;
        }
      });
    }

    return ok;
  }
}

module.exports = Controller;
