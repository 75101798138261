module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('recall', {
        url: '/recall',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          company: (user, organizations) => organizations.$get(user.orgContext.id),
          customerTypes: (constantsService) => constantsService.get('recallCustomerTypes')
            .then(o => _.map(o, (c) => ({name: c.text, value: c.id})))
        },
        data: {menuId: 'dashboard'}
      });
  }).run(function(menus, orgPerspectives, authorization, constantsService) {
    'ngInject';

    let stripeConstantsPromise = constantsService.get('stripe');

    menus.addMenuItem('dashboard', {
      title: 'Recall (Deprecated)',
      state: 'recall',
      type: 'dropdown',
      position: 7,
      isPublic: false,
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP]
    });

    menus.addSubMenuItem('dashboard', 'recall', {
      title: 'Recall Plan',
      state: 'recall.plan.team',
      icon: 'fas fa-book',
      planConstraint: {
        constraintName: 'recallPlan',
        featureName: 'Recall Plan',
        requiredProductPromise: stripeConstantsPromise.then(c => c.stripeProducts.FACILITY_PACKAGE)
      },
      position: 1
    });

    menus.addSubMenuItem('dashboard', 'recall', {
      title: 'Customers & Orders',
      state: 'ingredients.customers.list',
      icon: 'fas fa-store-alt',
      planConstraint: {
        constraintName: 'consigneesDistribution',
        featureName: 'Consignees/Distribution',
        requiredProductPromise: stripeConstantsPromise.then(c => c.stripeProducts.FACILITY_PACKAGE)
      },
      position: 2
    });

    menus.addSubMenuItem('dashboard', 'recall', {
      title: 'Recall Events',
      state: 'recall.events.list',
      icon: 'fas fa-calendar-exclamation',
      planConstraint: {
        constraintName: 'recallEvents',
        featureName: 'Recall Events',
        requiredProductPromise: stripeConstantsPromise.then(c => c.stripeProducts.FACILITY_PACKAGE)
      },
      position: 3
    });

    // menus.addSubMenuItem('dashboard', 'recall', {
    //   title: 'Problem Reports',
    //   state: 'recall.problemReports.list',
    //   icon: 'fas fa-file-exclamation',
    //   planConstraint: {
    //     constraintName: 'problemReports',
    //     featureName: 'Problem Reports',
    //     requiredProductPromise: stripeConstantsPromise.then(c => c.stripeProducts.FACILITY_PACKAGE)
    //   },
    //   position: 4
    // });

    menus.addSubMenuItem('dashboard', 'recall', {
      title: 'Product Complaints',
      state: 'recall.productComplaints.list',
      icon: 'fas fa-file-exclamation',
      planConstraint: {
        constraintName: 'productComplaints',
        featureName: 'Product Complaints',
        requiredProductPromise: stripeConstantsPromise.then(c => c.stripeProducts.FACILITY_PACKAGE)
      },
      position: 4
    });
  });
};
