require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfRecallPlanTeam', {
    bindings: {
      user: '<',
      members: '=',
      $recall: '=',
      company: '<',
      recallRoles: '<',
      recallResponsibilitiesTemplate: '<'
    },
    template: require('./recall-plan.team.template.html'),
    controller: require('./recall-plan.team.controller.js'),
    controllerAs: 'vm'
  });
};
