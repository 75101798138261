module.exports = function(ngModule) {
  ngModule.component('cfSopLibraryList', {
    bindings: {
      user: '<',
      hideHeading: '<'
    },
    template: require('./sop-library.list.template.html'),
    controller: require('./sop-library.list.controller'),
    controllerAs: 'vm'
  });
};
