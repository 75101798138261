class Controller {
  constructor(preventDirtyNav, $timeout, growl, utils) {
    'ngInject';

    this.preventDirtyNav = preventDirtyNav;
    this.$timeout = $timeout;
    this.growl = growl;
    this.utils = utils;
  }

  $onInit() {
    this.$timeout(() => this.preventDirtyNav(this.destructionForm, 'You have unsaved recall changes.',
      () => this.save()));
    this.isCfAdmin = this.user.isCfAdmin();
  }

  save() {
    this.$event.$save().then(() => {
      if (this.destructionForm) {
        this.destructionForm.$setPristine();
      }
      this.growl.success('Recall Destruction / Reconditioning Saved');
    }).catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save recall plan.'));
  }
}

module.exports = Controller;
