'use strict';

module.exports = function(ngModule) {
  ngModule.filter('cfIntervalToFrequency', function() {
    return function(input) {
      input = input || '';
      switch(input.toLowerCase()) {
      case 'day': return 'Daily';
      case 'month': return 'Monthly';
      case 'year': return 'Yearly';
      default: return 'Monthly';
      }
    };
  });
};
