class Constructor {
  constructor(filePortalService, FILE_PORTAL_ORG, $state, growl, utils, constantsService, $log, $stateParams, $q) {
    'ngInject';

    this.filePortalService = filePortalService;
    this.FILE_PORTAL_ORG = FILE_PORTAL_ORG;
    this.$state = $state;
    this.growl = growl;
    this.utils = utils;
    this.constantsService = constantsService;
    this.$log = $log;
    this.$q = $q;
    this.$stateParams = $stateParams;
  }

  $onInit() {
    this.$q.all({
      allFileTypes: this.constantsService.get('fileTypes'),
      loadStaging: this.loadStaging()
    }).then(({allFileTypes}) => {
      this.allFileTypes = allFileTypes;
      this.updateMissingFiles();
    });

    this.dummyOrg = {$id: this.FILE_PORTAL_ORG};
  }

  loadStaging() {
    this.loading = true;
    return this.filePortalService.getTempFileStagingArea(this.token, this.$stateParams.accessKey, this.key)
      .then(stagingObj => {
        this.stagingArea = stagingObj;
        this.loading = false;
      })
      .catch(err => {
        let msg = 'An error occurred setting up the file upload portal.';

        this.growl.error(msg);
        this.$log.error(msg + 'Please contact customer support if this continues.', this.$log.toString(err));
        this.$state.go('filePortal.portal');
      });
  }

  updateMissingFiles() {
    this.missing = [];
    let totalFiles = _.concat([], this.existingFiles, _.map(this.stagingArea.$files, file => file.category));
    let missingCategories = _.difference(this.requiredFiles, totalFiles);

    if (!missingCategories.length) {
      this.done = true;
      return;
    }
    this.done = false;
    this.missing = _.map(missingCategories, missingCategory =>
      ({
        category: _.find(this.categories, t => t.id === missingCategory).text,
        categoryId: missingCategory
      }));
  }

  close() {
    this.$state.go('filePortal.portal');
  }
}

module.exports = Constructor;
