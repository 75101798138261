class Controller {
  constructor($document, users, messageServices, $interval, $log, $timeout,
              confirmDeleteModal, growl, organizations, $q) {
    'ngInject';

    this.$document = $document;
    this.users = users;
    this.organizations = organizations;
    this.messageServices = messageServices;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.$log = $log;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.$q = $q;
  }

  $onInit() {
    this.userProfiles = {};
    this.userProfiles[this.user.uid] = this.user;
    this.createdOnText = {};
    if(_.get(this, 'topic.participatingOrgs.' + this.user.orgContext.id)) {
      this.createdByType = this.messageServices.ORG_TYPE;
      if (this.topic.lastMessageSent) {
        this.topic.participatingOrgs[this.user.organizationId] = this.topic.lastMessageSent;
        this.topic.$save();
      }
    }
    if (_.get(this, 'topic.participatingUsers.' + this.user.uid)) {
      this.createdByType = this.messageServices.USER_TYPE;
      if (this.topic.lastMessageSent) {
        this.topic.participatingUsers[this.user.uid] = this.topic.lastMessageSent;
        this.topic.$save();
      }
    }
    this.getHydratedParticipants(this.topic).then(participants => {
      this.participatingUsers = participants.users;
      this.participatingOrgs = participants.orgs;

    });

    this.unregisterMessageWatch = this.messageServices.startTopicMonitoring(this.topic, this.user);
    this.messageServices.$getMessages(this.topic.messageId, 50).then(messages => {
      this.messages = messages;
      _.each(this.messages, message => {
        message.content  = this.messageLink(message.content);
      });
      this.updateCreateOns();
    });

    this.$interval(() => this.updateCreateOns(), 60000);
  }

  $onDestroy() {
    if (this.unregisterMessageWatch) {
      this.unregisterMessageWatch();
    }
  }

  getHydratedParticipants(topic) {
    let users = topic.participatingUsers || {};

    return this.$q.all({
      users: this.$q.all(_.mapValues(users, (val, userId) => this.users.getPublicProfile(userId))),
      orgs: this.$q.all(_.mapValues(topic.participatingOrgs, (val, orgId) => this.organizations.getMiniProfile(orgId)))
    });
  }

  updateCreateOns() {
    _.each(this.messages, message => {
      this.createdOnText[message.$id] = this.humanizeAge(message.createdOn);
    });
  }

  addMessage() {
    if (!this.content) { return; }
    let content = this.content;

    this.topic.lastMessageByName = this.user.fullName();
    this.topic.lastMessageByCompany = this.user.orgContext.companyName;
    this.topic.lastMessageSent = firebase.database.ServerValue.TIMESTAMP;
    this.topic.participatingUsers[this.user.uid] = this.topic.lastMessageSent;

    this.topic.$save().then(() => {
      return this.messages.$add({
        content: content,
        createdBy: this.createdByType === this.messageServices.USER_TYPE ? this.user.uid : this.user.orgContext.id,
        actualCreatedBy: this.user.uid,
        createdByType: this.createdByType,
        createdOn: firebase.database.ServerValue.TIMESTAMP
      });
    }).then(newRef => {
      delete this.content;
      this.newMessage = this.messages.$getRecord(newRef.key);
      this.createdOnText[newRef.key] = 'a few seconds ago';
      this.newMessage.content = this.messageLink(content);
    }).catch(err => {
      this.$log.error('Unable to send message.', this.$log.toString(err));
    });
  }

  removeMessage(message) {
    if (!(message.createdBy === this.user.uid || message.createdBy === this.user.orgContext.id)) {
      return;
    }

    this.messages.$remove(message);
  }

  humanizeAge(submittedOn) {
    return submittedOn && moment.duration(moment(submittedOn).diff(moment())).humanize(true);
  }

  messageLink(content) {
    content = content.replace(/([--:\w?@%&+~#=]*\.[a-z]{2,4}\/{0,2})((?:[?&](?:\w+)=(?:\w+))+|[--:\w?!@%&+~#=]+)?/img,url => {
      let temp = url;
      if(!url.match('^https?:\/\/')) {
        temp = 'https://' + url;
      }
      return '<a target="_blank" href="' + temp + '">' + url + '</a>';
    });
    return content;
  }
}

module.exports = Controller;
