'use strict';

module.exports = function(ngModule) {
  ngModule.factory('idle', function($interval, $rootScope, $document, IDLE_DURATION, IDLE_TIMEOUT) {
    'ngInject';

    var svc,
      state = {idle: null, timeout: null, idling: false, running: false},
      interrupt = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';

    function timeout() {
      $interval.cancel(state.idle);
      $interval.cancel(state.timeout);

      state.idling = true;
      state.running = false;
      state.countdown = 0;

      $rootScope.$broadcast('IdleTimeout');
    }

    function countdown() {
      if (state.countdown <= 0) {
        timeout();
      } else {
        $rootScope.$broadcast('IdleWarn', state.countdown);
        state.countdown--;
      }
    }

    function toggleState() {
      state.idling = !state.idling;
      var name = state.idling ? 'Start' : 'End';

      $rootScope.$broadcast('Idle' + name);

      if (state.idling && IDLE_TIMEOUT) {
        state.countdown = IDLE_TIMEOUT;
        countdown();
        state.timeout = $interval(countdown, 1000, IDLE_TIMEOUT, false);
      }

      $interval.cancel(state.idle);
    }

    svc = {
      isExpired: function() {
        return state.expiry && state.expiry <= new Date();
      },
      running: function() {
        return state.running;
      },
      idling: function() {
        return state.idling;
      },
      watch: function() {
        $interval.cancel(state.idle);
        $interval.cancel(state.timeout);

        var tOut = !IDLE_TIMEOUT ? 0 : IDLE_TIMEOUT;

        state.expiry = new Date(new Date().getTime() + (IDLE_DURATION + tOut) * 1000);
        if (state.idling) {
          toggleState();
        }

        state.running = true;
        state.idle = $interval(toggleState, IDLE_DURATION * 1000, 0, false);
      },
      unwatch: function() {
        $interval.cancel(state.idle);
        $interval.cancel(state.timeout);

        state.idling = false;
        state.running = false;
        state.expiry = null;
      },
      interrupt: function() {
        if (!state.running) {
          return;
        }

        if (IDLE_TIMEOUT && this.isExpired()) {
          timeout();
        } else {
          this.watch();
        }
      }
    };

    $document.find('body').on(interrupt, function() {
      svc.interrupt();
    });

    return svc;
  });
};
