class Constructor {
  constructor($timeout, preventDirtyNav, organizations, growl, $log, fbutil, $uibModal) {
    'ngInject';

    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.organizations = organizations;
    this.growl = growl;
    this.$log = $log;
    this.fbutil = fbutil;
    this.$uibModal = $uibModal;
  }


  $onInit() {
    this.$timeout(() => {
      this.preventDirtyNav(this.recallTeamForm, 'You have unsaved changes to your recall team.', () => this.save());
    }, 0);

    this.isCfAdmin = this.user.isCfAdmin();

    // There's no good place to set this 'milestone achieved'. Consider refactoring this at some point.
    this.organizations.setMilestoneAchieved(this.user.orgContext.id, this.organizations.milestones.RECALL_PLAN);

    this.recallTeam = _.map(this.$recall.recallTeam, (m, $id) => {
      let mapped = _.assign(m, {$id});

      mapped.roleIds = _.isString(m.role) ? _.map(m.role.split(','), x => parseInt(x)) : [];
      return mapped;
    });

    this.numMembers = this.recallTeam.length;
    if (!this.numMembers) {
      let prefilled = false;

      _.each(this.company.safetyTeam, (member) => {
        prefilled = true;
        this.recallTeam.push({
          name: member.name,
          roleIds: []
        });
      });

      if (prefilled) {
        this.growl.info('Recall team prefilled with safety team members.');
      } else {
        this.growl.info('No safety team members. Could not prefill recall team.');
      }
    }
  };

  save() {
    this.$recall.recallTeam = _.reduce(this.recallTeam, (teamObj, member) => {
      let objKey = member.$id || this.fbutil.generateArrayKey();
      let val = _.omit(member, ['$id', 'roleIds']);

      val.role = member.roleIds.join(',');
      teamObj[objKey] = val;
      return teamObj;
    }, {});
    this.$recall.$save()
      .then(() => {
        if (this.recallTeamForm) {
          this.recallTeamForm.$setPristine();
        }
        this.growl.success('Recall team saved.');
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Error saving recall team'));
  }

  addMember() {
    this.recallTeam.push({
      name: '',
      roleIds: []
    });

    if (this.recallTeamForm) {
      this.recallTeamForm.$setDirty();
    }
  }
  viewTemplate() {
    let recallResponsibilitiesTemplate = this.recallResponsibilitiesTemplate;

    return this.$uibModal.open({
      animation: true,
      template: `
<div class="modal-header">
  <div class="headline">
    <h2>Recall Team Responsibilities:</h2>
  </div>
  <div class="d-flex justify-content-end">
    <button class="btn btn-danger btn-close" ng-click="$close(true)">&times;</button>
  </div>
</div>
<div class="modal-body px-4">
 <div class="card rounded-0 u-shadow-v1-3 g-mb-15">
   <div class="card-block" >
      <p>${recallResponsibilitiesTemplate}</p>
    </div>
  </div>
 </div>
`,
      size: 'lg'
    });

  }
  addTemplate() {
    this.$recall.recallTeamResponsibilities = this.recallResponsibilitiesTemplate;
  }
  remove(index) {
    this.recallTeam.splice(index, 1);
    this.recallTeamForm.$setDirty();
  }
}

module.exports = Constructor;

