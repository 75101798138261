module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.equipment', {
        url: '/equipment',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          equipment: ($log, equipmentService, user) => equipmentService.query(user.orgContext.id)
            .catch((err) => {
              $log.error('Unable to query equipment.', $log.toString(err));

              return [];
            })
        }
      })
      .state('operations.equipment.list', {
        url: '',
        template: '<cf-equipment-list user="$resolve.user" equipment="$resolve.equipment" ' +
          'organization="$resolve.company"></cf-equipment-list>'
      });
  });
};
