'use strict';

module.exports = function(ngModule) {
  ngModule.factory('ProductNameSearch', function($http, $q) {
    'ngInject';

    class ProductNameSearch {

      constructor(organizationId, opts) {
        opts = opts || {};

        this.organizationId = organizationId;
        this.pageSize = opts.pageSize || 30;
        this.max = opts.max || 300;
      }

      $loaded() {
        return this.searchPromise || $q.when(null);
      }

      search(searchText) {
        this.searchText = searchText;
        this.currentPosition = 0;
        this.searchResults = [];
        delete this.endReached;
        return this._search();
      }

      getMore() {
        this.currentPosition += this.pageSize;
        if (this.endReached || this.currentPosition + this.pageSize > this.max) {
          return $q.when(this.searchResults);
        }
        return this._search();
      }

      _search() {
        let url = '/organizations/' + this.organizationId + '/productNames';

        url += '?from=' + this.currentPosition;
        url += '&size=' + this.pageSize;

        if (this.searchText) {
          url += '&searchText=' + this.searchText;
        }
        this.searchPromise = $http.get(url).then(results => {
          this.endReached = results.data.length < this.pageSize;
          this.searchResults = _.concat(this.searchResults, results.data);
          return this.searchResults;
        });
        return this.searchPromise;
      }
    }

    return ProductNameSearch;
  });
};
