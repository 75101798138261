'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfChooseOrganization', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./choose-organization.template.html'),
    controller: require('./choose-organization.controller.js'),
    controllerAs: 'vm'
  });
};

