module.exports = function(ngModule) {
  require('./edit')(ngModule);
  require('./overview')(ngModule);
  require('./grant_read_request')(ngModule);
  require('./reject_read_request')(ngModule);
  require('./list_safety_team')(ngModule);
  require('./laboratories')(ngModule);
  require('./laboratory')(ngModule);
  require('./upload_files')(ngModule);
};
