'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfMediaManagerProgressBar', {
    bindings: {
      newFileCount: '<',
      uploadProgress: '<',
      noteText: '@'
    },
    template: require('./media-manager-progress-bar.template.html'),
    controller: require('./media-manager-progress-bar.controller'),
    controllerAs: 'vm'
  });
};
