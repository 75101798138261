module.exports = function(ngModule) {
  ngModule.component('cfSopLogEditFormTypeEmployeeTrain', {
    bindings: {
      user: '<',
      $sopLog: '<',
      $sopLogs: '<',
      $sop: '<',
      onSave: '&?',
      onCancel: '&?'
    },
    template: require('./operations.sop-logs.edit-form.type.employee-train.template.html'),
    controller: require('./operations.sop-logs.edit-form.type.employee-train.controller'),
    controllerAs: 'vm'
  });
};
