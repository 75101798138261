module.exports = function (ngModule) {
  class OrgInteractionService {
    constructor(
      $state,
      $log,
      $q,
      fbutil,
      email,
      emailTemplateIds,
      organizations,
      growl,
      authorization,
      marketplaceServices,
      accessRequests,
      urlTokens,
      notifications,
      INVITE_EXPIRE_DAYS,
      CF_ROLES,
      $http
    ) {
      'ngInject';

      this.$state = $state;
      this.$log = $log;
      this.$q = $q;
      this.fbutil = fbutil;
      this.email = email;
      this.emailTemplateIds = emailTemplateIds;
      this.organizations = organizations;
      this.authorization = authorization;
      this.growl = growl;
      this.marketplaceServices = marketplaceServices;
      this.accessRequests = accessRequests;
      this.urlTokens = urlTokens;
      this.notifications = notifications;
      this.CF_ROLES = CF_ROLES;
      this.INVITE_EXPIRE_DAYS = INVITE_EXPIRE_DAYS;
      this.$http = $http;
    }

    /**
     * Send an email with an action request (action to either approve or deny a request).
     * @param {string} token Token Id of a token payload in FB that persists the action data.
     * @param {object} opts  Email parameters and options
     * @returns {*} A promise that resolves when the email is sent.
     */
    emailActionRequest(token, opts) {
      let approveUrl = this.$state
          .href('grantOrgAccessRequest', {requestId: token}, {absolute: true})
          .replace('#', 'app/#'),
        rejectUrl = this.$state
          .href('rejectOrgAccessRequest', {requestId: token}, {absolute: true})
          .replace('#', 'app/#');

      let templateParameters = opts.mergeFields || [];

      templateParameters = _.concat(templateParameters, [
        {name: 'acceptInviteUrl', content: approveUrl},
        {name: 'rejectUrlInviteUrl', content: rejectUrl},
      ]);

      this.email
        .sendTemplate(
          {
            email: opts.toEmail,
            name: opts.toName,
            subject: 'FoodReady Manufacturer Access Request',
          },
          opts.template,
          templateParameters
        )
        .catch((err) => {
          this.$log.error('Error sending out access request email.', this.$log.toString(err));
        });
    }

    /**
     * Submit a request to a target organization for additional access in the form of a role being assigned at the
     * organization level (i.e. all members of the org will assume this role.)
     * @param {string} targetOrgId The organization being asked for additional access.
     * @param {object} user The user of the user making the request.
     * @param {string} role  The role to be added if approved.
     * @param {object} notificationSettings Settings that customize the notification sent to the target org.
     * @returns {object} Returns a promise that resolves to the new access request id.
     */
    submitOrgRoleRequest(targetOrgId, user, role, notificationSettings) {
      if (role !== this.CF_ROLES.LINKED_ORG) {
        throw new Error('linked_org is the only role type supported.');
      }

      return this.accessRequests
        .createAccessRequest({
          targetOrgId: targetOrgId,
          createdOn: new Date().getTime(),
          status: 'pending',
          createdBy: user.uid,
          createdByOrgName: user.currentOrgContext().companyName,
          role: role,
          organizationId: user.currentOrgContext().id,
          type: this.accessRequests.requestTypes.ORG_ROLE_REQUEST,
          notificationSettings: notificationSettings,
        })
        .then(($accessRequest) => {
          return this.urlTokens
            .$create({
              createdOn: firebase.database.ServerValue.TIMESTAMP,
              createdBy: user.uid,
              accessRequestId: $accessRequest.$id,
            })
            .then(($urlToken) => {
              $accessRequest.urlToken = $urlToken.$id;
              return $accessRequest
                .$save()
                .then(() => this.sendForApproval(user, $accessRequest))
                .then((notifyId) => {
                  $urlToken.notificationId = notifyId;
                  return $urlToken.$save();
                });
            })
            .then(() => $accessRequest.$id);
        });
    }

    /**
     * Send email and dashboard notification to recipient of an access request.
     * @param {object} user User initiating the approval request.
     * @param {object} $accessRequest  AccessRequest object to be approve.
     * @returns {Promise.<string>}  A promise that's resolved to the new notification key.
     */
    sendForApproval(user, $accessRequest) {
      return this.organizations.getProfile($accessRequest.targetOrgId).then((toCompanyProfile) => {
        if (!$accessRequest.notificationSettings.suppressEmail) {
          const emailOpts = {
            toEmail: toCompanyProfile.pointOfContactEmail,
            toName: toCompanyProfile.pointOfContactName,
            message: $accessRequest.notificationSettings.message,
            template: $accessRequest.notificationSettings.template,
            mergeFields: [
              {name: 'contactName', content: toCompanyProfile.pointOfContactName},
              {name: 'invitationCompanyName', content: user.orgContext.companyName},
              {name: 'invitationPersonName', content: user.fullName()},
            ],
          };

          if ($accessRequest.productName) {
            emailOpts.mergeFields.push({name: 'productName', content: $accessRequest.productName});
          }

          this.emailActionRequest($accessRequest.urlToken, emailOpts);
        }

        return this.notifications
          .postToOrg({
            from: user.uid,
            fromOrg: user.orgContext.companyName,
            to: toCompanyProfile.id,
            type: this.notifications.types.ACCESS_REQUEST,
            message: $accessRequest.notificationSettings.message,
            metaData: {
              accessRequestId: $accessRequest.$id,
              emailToken: $accessRequest.urlToken,
            },
          })
          .then((newNotificationRef) => newNotificationRef.key);
      });
    }

    /**
     * Grant another organization access to my organization
     * @param {object} user The user (org) from which access will be granted
     * @param {object} user.uid The user's ID
     * @param {object} user.orgContext The user's org context
     * @param {string} user.orgContext.id The user's org ID
     * @param {string} targetOrgId The resource-owning organization
     * @param {string} organizationId The organization the user is giving organization access to
     * @param {string | Array<string>} role The access claim or array of claims
     * @returns {Promise.<object>} A promise resolving to the access requests
     */
    allowAccessToOrganization(user, targetOrgId, organizationId, role) {
      return this.accessRequests
        .createAccessRequest({
          targetOrgId: targetOrgId,
          createdOn: new Date().getTime(),
          status: 'approved-applied',
          createdByOrgName: user.orgContext.companyName,
          createdBy: user.uid,
          role: role,
          organizationId: organizationId,
          type: this.accessRequests.requestTypes.ORG_ROLE_REQUEST,
        })
        .then(($accessRequest) =>
          this.authorization
            .assignOrgToOrganization(user.uid, organizationId, targetOrgId, role)
            .then(() => $accessRequest)
        )
        .then(($accessRequest) => {
          if (_.isFunction($accessRequest.$destroy)) {
            $accessRequest.$destroy();
          }

          return $accessRequest;
        });
    }

    /**
     * Allow access to a user organization's product(s)
     * @param {object} user The user (org) from which access will be granted
     * @param {object} user.uid The user's ID
     * @param {object} user.orgContext The user's org context
     * @param {string} user.orgContext.id The user's org ID
     * @param {string} targetOrgId The resource-owning organization
     * @param {string} organizationId The organization the user is giving product access to
     * @param {object | Array<object>} products The product(s) the user is exposing
     * @param {string | Array<string>} claim The access claim or array of claims
     * @returns {Promise.<object>} A promise resolving to the access requests
     */
    allowAccessToProducts(user, targetOrgId, organizationId, products, claim) {
      products = _.isArray(products) ? products : [products];

      const accessReqTemplate = {
        targetOrgId: targetOrgId,
        createdOn: new Date().getTime(),
        status: 'approved-applied',
        createdByOrgName: user.orgContext.companyName,
        createdBy: user.uid,
        claims: _.isArray(claim) ? claim : [claim],
        organizationId: organizationId,
        type: this.accessRequests.requestTypes.ORG_PRODUCT_EXCEPTION_REQUEST,
      };

      return this.$q
        .all(
          _.map(products, (product) =>
            this.accessRequests.createAccessRequest(
              _.assign({}, accessReqTemplate, {productId: product.$id || product.key, productName: product.brandName})
            )
          )
        )
        .then(($accessRequests) =>
          this.$q
            .all(
              _.map($accessRequests, ($accessRequest) =>
                this.authorization.addOrgProductExceptions(
                  $accessRequest.organizationId,
                  $accessRequest.targetOrgId,
                  $accessRequest.productId,
                  $accessRequest.claims
                )
              )
            )
            .then(() => $accessRequests)
        )
        .then(($accessRequests) => {
          _.each($accessRequests, ($accessRequest) => {
            if (_.isFunction($accessRequest.$destroy)) {
              $accessRequest.$destroy();
            }
          });

          return $accessRequests;
        });
    }

    /**
     * Submit a request to a target organization for additional access in the form of a product level exception to
     * be assigned at the organization level (i.e. all members of the org will assume this exception).
     * @param {object} user The user of the organization making the request.
     * @param {object} supplierIngredient A supplierIngredient object that points to the productId in question.
     * @param {Array|string} claim The claim(s) that will be set for this exception record.
     * @param {object} notificationSettings Settings that customize the notification sent to the target org.
     * @returns {object} Returns a promise that resolves to the new access request id.
     */
    requestIngredientAccess(user, supplierIngredient, claim, notificationSettings) {
      claim = _.isArray(claim) ? claim : [claim];

      return this.accessRequests
        .createAccessRequest({
          targetOrgId: supplierIngredient.productOrganizationId,
          createdOn: new Date().getTime(),
          status: 'pending',
          createdByOrgName: user.currentOrgContext().companyName,
          createdBy: user.uid,
          claims: claim,
          productId: supplierIngredient.productId,
          productName: supplierIngredient.brandName,
          organizationId: user.currentOrgContext().id,
          type: this.accessRequests.requestTypes.ORG_PRODUCT_EXCEPTION_REQUEST,
          notificationSettings: notificationSettings,
        })
        .then(($accessRequest) => {
          return this.urlTokens
            .$create({
              createdOn: firebase.database.ServerValue.TIMESTAMP,
              createdBy: user.uid,
              accessRequestId: $accessRequest.$id,
            })
            .then(($urlToken) => {
              $accessRequest.urlToken = $urlToken.$id;
              return $accessRequest
                .$save()
                .then(() => this.sendForApproval(user, $accessRequest))
                .then((notifyId) => {
                  $urlToken.notificationId = notifyId;
                  return $urlToken.$save();
                });
            })
            .then(() => $accessRequest.$id);
        });
    }

    /**
     * Cancel the request for access and remove all notifications.
     * @param {object} user  User initiating the request.
     * @param {string} requestId Request id of request to be cancelled.
     * @returns {Promise.<void>}  Promise that resolves when request is cancelled.
     */
    cancelAccessRequest(user, requestId) {
      return this.accessRequests.$get(requestId).then(($accessRequest) => {
        $accessRequest.cancelledOn = new Date().getTime();
        $accessRequest.cancelledBy = user.uid;
        $accessRequest.status = 'cancelled';
        this.$log.info('Access request cancelled', {requestId: $accessRequest.$id});

        return $accessRequest.$save().then(() => this.cancelActionRequest($accessRequest.urlToken));
      });
    }

    /**
     * Protect the access request with the URL token. (Note: the parameter $accessRequest is
     * destroyed before the return promise resolves)
     * @param {object} $accessRequest The access request object ($firebaseObject)
     * @param {object} $urlToken The URL token ($firebaseObject)
     * @returns {Promise} A promise that resolves to the original $urlToken ($firebaseObject)
     */
    protectAccessRequest($accessRequest, $urlToken) {
      const id = $accessRequest.$id;

      $accessRequest.urlToken = $urlToken.$id;
      return $accessRequest
        .$save()
        .then(() => {
          $accessRequest.$destroy();
          return this.accessRequests.protect(id, $urlToken.key);
        })
        .then(() => $urlToken);
    }

    /**
     * Send an email invitation to join FoodReady.
     * @param {object} user  User initiating the invitation.
     * @param {object} contact The target individual's contact information
     * @param {string} template The email template name to use.
     * @param {string} inviteUrl The invitation URL.
     * @param {object} invitationObj The invitation object.
     * @returns {Promise} A promise that resolves when the email is sent
     */
    sendNewUserInvitation(user, contact, template, inviteUrl, invitationObj) {
      return this.email
        .sendTemplate(
          {
            email: contact.contactEmail,
            name: contact.contactName,
            subject: 'FoodReady Invitation from ' + invitationObj.companyName,
          },
          template,
          [
            {name: 'inviteeContactName', content: contact.contactName},
            {name: 'inviteeCompanyName', content: invitationObj.companyName},
            {name: 'inviterPersonName', content: user.fullName()},
            {name: 'inviterCompanyName', content: user.orgContext.companyName},
            {name: 'inviteNotes', content: contact.inviteNotes},
            {name: 'acceptInviteUrl', content: inviteUrl},
          ]
        )
        .then((result) => result.data);
    }

    cancelActionRequest(tokenId) {
      return this.fbutil
        .ref('urlTokens', tokenId, 'notificationId')
        .once('value')
        .then((notificationKey) => {
          if (!notificationKey.exists()) {
            return;
          }
          return this.fbutil.ref('notifications', notificationKey.val()).remove();
        })
        .then(() => this.fbutil.ref('urlTokens', tokenId).remove());
    }

    /**
     * Invite a new user to join FoodReady. They are pre-registered to have a specific role under a specific
     * organization.
     * @param {object} user  The user initiating the invitation.
     * @param {string} organizationId  The organization under which the new user will be pre-registered.
     * @param {string} role  The role that the new user will be assigned to.
     * @param {string} [orgType] An optional org type that will be predefined for the new user's org.
     * @param {object} options Additional invitation options.
     * @param {object} contact user details for invitation
     * @param {string} options.assumeOwnership If true, the new user will assume ownership of the org with new stripe plan.
     * @param {string} options.reason The reason for the invitation (e.g. new user, transfer ownership).
     * @param {string} options.updatePrevOwner Update the previous owner role upon org owner transfer.
     * @param {object} options.urlParams Additional url parameters for the signup link.
     * @returns {Promise} A promise that is resolved to the new access Request id.
     */
    inviteNewUser(user, organizationId, role, orgType = undefined, options = undefined, contact = null) {
      options = options || {};
      contact = contact || {};
      // The request for access won't be sent because the current user is the approver.
      const notificationSettings = {
        suppressEmail: true,
        suppressNotify: true,
      };

      let $accessRequest;

      // 1. Create a pre-approved access request.
      // 2. Create the urlToken
      // 3. Tie the urlToken to the accessRequest and send the invitation
      return this.accessRequests
        .createAccessRequest({
          targetOrgId: organizationId,
          createdOn: firebase.database.ServerValue.TIMESTAMP,
          status: 'approved',
          createdByOrgName: user.orgContext.companyName,
          createdBy: user.uid,
          role: role,
          organizationId: user.orgContext.id,
          type: this.accessRequests.requestTypes.USER_ROLE_REQUEST,
          notificationSettings: notificationSettings,
        })
        .then((newAccessRequest) => {
          $accessRequest = newAccessRequest;
          let expiresOn = new Date();

          expiresOn.setDate(expiresOn.getDate() + this.INVITE_EXPIRE_DAYS);

          return this.urlTokens.$create({
            assumeOwnership: options.assumeOwnership || null,
            updatePrevOwner: options.updatePrevOwner || null,
            createdOn: firebase.database.ServerValue.TIMESTAMP,
            accessRequestId: $accessRequest.$id,
            expires: expiresOn.getTime(),
          });
        })
        .then(async ($urlToken) => {
          let queryParams = _.assign({aid: $urlToken.$id, key: $urlToken.key}, options.urlParams || {});

          if (orgType) {
            queryParams.orgType = orgType;
          }
          const inviteUrl = this.$state.href('signup.begin', queryParams, {absolute: true}).replace('#', 'app/#');

          let invitationObj = {
            createdOn: new Date().getTime() - 1000,
            token: $urlToken.$id,
            reason: options.reason || null,
            name: contact.contactName || '',
            email: contact.contactEmail || '',
            companyName: await this.organizations.getName(organizationId),
            role,
          };

          return this.protectAccessRequest($accessRequest, $urlToken)
            .then(() => this.fbutil.ref(`organizations/${organizationId}/userInvitations`).push(invitationObj))
            .then((invitationRef) => ({
              invitationObj,
              invitationUrl: inviteUrl,
              invitationKey: invitationRef.key,
            }));
        });
    }

    /**
     * Email the user invitation.
     * @param {object} user  The user initiating the invitation.
     * @param {string} organizationId  The organization under which the new user will be pre-registered.
     * @param {object} contact The contact info of the individual to be invited.
     * @param {string} emailTemplate The email template used in the invitation.
     * @param {string} invitationKey The key in FB that retrieves the invitation object.
     * @param {string} invitationUrl The invitation link.
     * @param {object} invitationObj the invitation object
     * @returns {Promise} A promise that is resolved when the email is sent.
     */
    emailInvitation(user, organizationId, contact, emailTemplate, invitationKey, invitationUrl, invitationObj) {
      const invitationRef = this.fbutil.ref(`organizations/${organizationId}/userInvitations/${invitationKey}`);

      return this.sendNewUserInvitation(user, contact, emailTemplate, invitationUrl, invitationObj).then((token) =>
        invitationRef.child('mailToken').set(token)
      );
    }

    /**
     * Send an email invitation to a prospective client to be a preferred provider for "serviceIds".
     * @param {Object} user The user sending the invitation
     * @param {string} providerOrgId The organization ID of the provider
     * @param {Object} services An key/value pair of services the provider would like to be the preferred provider for
     * @param {Object} clientContact The client contact name, email & notes
     * @returns {Promise} A promise that's resolved after the invitation is resent.
     */
    sendClientInvite(user, providerOrgId, services, clientContact) {
      let $clientAccessRequest;
      const expiresOn = new Date();

      expiresOn.setDate(expiresOn.getDate() + this.INVITE_EXPIRE_DAYS);

      return this.$q
        .all({
          // Create a pre-approved access request for the client's organization to have a role against the provider.
          clientAccess: this.accessRequests.createAccessRequest({
            organizationId: null, // Receiving org unknown. Will be known when the user receives the email and logs in.
            targetOrgId: providerOrgId,
            createdOn: firebase.database.ServerValue.TIMESTAMP,
            status: 'approved',
            createdByOrgName: user.orgContext.companyName,
            createdBy: user.uid,
            role: this.CF_ROLES.CLIENT,
            type: this.accessRequests.requestTypes.ORG_ROLE_REQUEST,
            notificationSettings: {suppressEmail: true, suppressNotify: true},
          }),

          // Create a pending access request for the provider's organization to have a role against the client.
          providerAccess: this.accessRequests.createAccessRequest({
            organizationId: providerOrgId,
            targetOrgId: null, // Target org unknown. Will be known when the user receives the email and logs in.
            createdOn: firebase.database.ServerValue.TIMESTAMP,
            status: 'pending',
            createdByOrgName: user.orgContext.companyName,
            createdBy: user.uid,
            role: 'provider',
            type: this.accessRequests.requestTypes.ORG_ROLE_REQUEST,
            notificationSettings: {suppressEmail: true, suppressNotify: true},
          }),
        })
        .then((results) => {
          $clientAccessRequest = results.clientAccess;

          return this.urlTokens.$create({
            accessRequests: {
              clientAccessRequestId: results.clientAccess.$id,
              providerAccessRequestId: results.providerAccess.$id,
            },
            createdOn: firebase.database.ServerValue.TIMESTAMP,
            expires: expiresOn.getTime(),
            providerOrgId: providerOrgId,
            serviceIds: _.keys(services),
            templateId: this.emailTemplateIds.CLIENT_INVITE,
          });
        })
        .then(($urlToken) => this.protectAccessRequest($clientAccessRequest, $urlToken))
        .then(($urlToken) => {
          const inviteUrl = this.$state
            .href('signup', {aid: $urlToken.$id, key: $urlToken.key}, {absolute: true})
            .replace('#', 'app/#');

          return this.addPendingInvitation(providerOrgId, $urlToken.$id, {
            createdOn: $urlToken.createdOn,
            createdBy: user.uid,
            createdByName: user.fullName(),
            sentToName: clientContact.name,
            sentToEmail: clientContact.email,
          })
            .then(() => {
              return this.email.sendTemplate(
                {
                  email: clientContact.email,
                  name: clientContact.name,
                  subject: 'FoodReady Client Invitation from ' + user.companyName,
                },
                this.emailTemplateIds.CLIENT_INVITE,
                [
                  {name: 'contactName', content: clientContact.name},
                  {name: 'invitationCompanyName', content: user.companyName},
                  {name: 'invitationPersonName', content: user.fullName()},
                  {name: 'inviteNotes', content: clientContact.notes},
                  {name: 'acceptInviteUrl', content: inviteUrl},
                  {name: 'serviceTitles', content: _.map(services, 'title')},
                ]
              );
            })
            .then(() => {
              let urlId = $urlToken.$id;

              $urlToken.$destroy();
              return urlId;
            })
            .catch((err) => {
              $urlToken.$destroy();
              this.$log.error('Unable to send client invitation: ' + err, {tokenId: $urlToken.$id});
              throw err;
            });
        });
    }

    /**
     * Terminate the relationship between a provider and a client, including any permissions.
     * @param {string} providerOrgId Provider organization Id
     * @param {string} clientOrgId Client organization Id
     * @return {Promise} A promise that is resolved when the relationship is terminated.
     */
    terminateClientProviderRelationship(providerOrgId, clientOrgId) {
      return this.$q
        .all([
          this.authorization.removeOrgFromOrganization(providerOrgId, clientOrgId),
          this.authorization.removeOrgFromOrganization(clientOrgId, providerOrgId),
        ])
        .then(() => {
          return this.organizations.removePreferredProvider(clientOrgId, providerOrgId);
        });
    }

    /**
     * Process a URL token when a user / recipient clicks "accept" or otherwise when viewing an invitation.
     * @param {object} user The logged in user.
     * @param {object} urlToken The URL token present on the invitation
     * @param {string} orgId The organization ID to which the invitation was sent
     * @param {boolean=} showGrowl Whether to show a growl message after the URL token is processed
     * @returns {Promise} A promise that's resolved after the URL token is processed
     */
    processUrlToken(user, urlToken, orgId, showGrowl) {
      if (_.isEmpty(urlToken)) {
        return this.$q.resolve();
      }

      let promise, growlMsg;
      const tokenId = urlToken.token;

      switch (_.get(urlToken, 'templateId')) {
      case this.emailTemplateIds.CLIENT_INVITE:
        const accessRequestKey = urlToken.key;
        const clientAccessRequest = urlToken.accessRequests.clientAccessRequestId;
        const providerAccessRequest = urlToken.accessRequests.providerAccessRequestId;

        // Execute the pre-approved access request granting the client a 'client' role against the provider.
        promise = this.accessRequests
          .execute(clientAccessRequest, accessRequestKey)
          .then(() => this.accessRequests.$get(providerAccessRequest))
          .then(($providerAccessRequest) => {
            $providerAccessRequest.targetOrgId = orgId;

            // Approve and execute the access request granting the provider a 'provider' role against the client.
            return this.approveAccessRequest(user, $providerAccessRequest);
          })
          .then(() => this.organizations.setPreferredProvider(orgId, urlToken.providerOrgId, urlToken.serviceIds))
          .then(() => this.deletePendingInvitation(urlToken.providerOrgId, tokenId))
          .then((result) => {
            if (showGrowl) {
              return this.$q
                .all([
                  this.marketplaceServices.getDefaultServices(),
                  this.marketplaceServices.getOrgServices(urlToken.providerOrgId),
                ])
                .then((result) => {
                  let services = _.merge(result[0], result[1]),
                    numServiceIds = urlToken.serviceIds.length;

                  growlMsg = 'Successfully updated your preferred provider for ';
                  _.each(urlToken.serviceIds, (id, idx) => {
                    growlMsg +=
                        '<strong>' +
                        services[id].title +
                        '</strong>' +
                        (idx < numServiceIds - 2 ? ', ' : idx < numServiceIds - 1 ? ' & ' : '.');
                  });

                  return result;
                });
            }

            return this.$q.when(result);
          })
          .then(() => this.urlTokens.remove(tokenId));
        break;
      default:
        return this.$q.resolve();
      }

      return promise.then((result) => {
        if (showGrowl) {
          this.growl.success(growlMsg);
        }
        return result;
      });
    }

    cancelInvitation(orgId, urlTokenId) {
      return this.urlTokens.$get(urlTokenId).then(($urlToken) => {
        return this.accessRequests
          .cancel($urlToken.accessRequestId)
          .then(() => $urlToken.$remove())
          .then(() => this.deletePendingInvitation(orgId, urlTokenId));
      });
    }

    /**
     * Keep track of outstanding invitations.
     * @param {string} orgId - Org id who extended the invitation.
     * @param {string} tokenId - Token id
     * @param {string} details - Invitation details (who, what when).
     * @return {Promise} - A promise that's resolved when the pending invitation is saved.
     */
    addPendingInvitation(orgId, tokenId, details) {
      return this.fbutil.ref('organizations', orgId, 'pendingInvitations', tokenId).set(details);
    }

    /**
     * Remove the pending invitation tracker.
     * @param {string} orgId - Org id who extended the invitation.
     * @param {string} tokenId - Token id
     * @return {Promise} - A promise that's resolved when the pending invitation is removed.
     */
    deletePendingInvitation(orgId, tokenId) {
      return this.fbutil.ref('organizations', orgId, 'pendingInvitations', tokenId).remove();
    }

    /**
     * Invite user to org
     * @param {string} orgId - Org id who extended the invitation.
     * @param {object} contact - Contact infos.
     * @param {string} role - Role to invite.
     * @param {{}} options - Options.
     * @return {Promise} - A promise that's resolved when the invitation is sent.
     */
    inviteUser(orgId, contact, role, options) {
      return this.$http.post(`organizations/${orgId}/send_user_invite`, {contact, role, options});
    }

    /**
     * Invite user to org
     * @param {string} orgId - Org id who extended the invitation.
     * @param {object} contact - Contact infos.
     * @param {string} role - Role to invite.
     * @param {number} passcode - passcode.
     * @return {Promise} - A promise that's resolved when the invitation is sent.
     */
    addNonEmailUser(orgId, contact, role, passcode) {
      return this.$http.post(`organizations/${orgId}/add_non_email_user`, {contact, role, passcode});
    }

    /**
     * Remove non email user from org
     * @param {string} orgId - Org id who extended the invitation.
     * @param {string} userId - User id.
     * @return {Promise} - A promise that's resolved when the user is removed.
     */
    removeNonEmailUser(orgId, userId) {
      return this.$http.delete(`organizations/${orgId}/remove_non_email_user/${userId}`);
    }
  }

  ngModule.service('orgInteractionService', OrgInteractionService);
};
