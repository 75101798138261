class Controller {
  constructor($state, $log, growl, utils, $q) {
    'ngInject';

    this.$state = $state;
    this.$log = $log;
    this.growl = growl;
    this.utils = utils;
    this.$q = $q;
  }

  $onInit() {
    this.isNew = this.$event.$value === null;
    this.$event.organizationId = this.$event.organizationId || this.user.orgContext.id;
  }

  saveAndClose() {
    if (this.eventForm && this.eventForm.$pristine) {
      this.close();
    }

    this.save().then(() => this.close());
  }

  save() {
    if (this.isNew) {
      this.$event.createdOn = firebase.database.ServerValue.TIMESTAMP;
      this.$event.createdBy = this.user.uid;
    } else {
      this.$event.updatedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$event.updatedBy = this.user.uid;
    }

    return this.$event.$save()
      .then(() => {
        if (this.eventForm) {
          this.eventForm.$setPristine();
        }

        this.isNew = false;
        this.growl.success('Recall Event saved successfully.');
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save recall event.'));
  }

  cancel() {
    if (this.eventForm) {
      this.eventForm.$setPristine();
    }

    this.close();
  }

  close() {
    this.$state.go('recall.events.list');
  }
}

module.exports = Controller;
