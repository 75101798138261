class Constructor {
  constructor(gmps, utils, $state, growl, checklistLibraryService, $uibModal) {
    'ngInject';

    this.gmps = gmps;
    this.utils = utils;
    this.$state = $state;
    this.growl = growl;
    this.checklistLibraryService = checklistLibraryService;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.noChecklistTemplates = this.checklistTemplates.length <= 0;
    this.utils.usersName(this.checklistTemplates);
  }

  cloneChecklistTemplate() {
    this.checklistLibraryService
      .promptAddChecklist(this.user, {cloneTemplate: true})
      .then((checklistTemplate) => {
        this.$state.go('checklists.library.edit', {checklistTemplate});
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to create new checklist.'));
  }

  editChecklistTemplate(checklistTemplateId) {
    this.$state.go('checklists.library.edit', {checklistTemplateId});
  }

  createChecklistTemplate() {
    return this.$uibModal
      .open({
        component: 'cfRadioListModal',
        resolve: {
          title: () => 'Select a the type of Checklist',
          message: () => 'Select a the type of Checklist',
          options: () => [
            {text: 'Basic Checklist', value: 'basic', default: true},
            {text: 'Grid Checklist', value: 'grid'},
          ],
        },
      })
      .result.then((choice) => {
        let grid = choice === 'grid';

        this.$state.go('checklists.library.edit', {grid});
      });
  }

  deleteChecklistTemplate(templateId) {
    this.checklistLibraryService
      .deleteChecklistTemplate(this.user.orgContext.id, templateId)
      .then(() => {
        this.growl.success('Checklist Deleted');
        this.checklistTemplates = this.checklistTemplates.filter(
          (checklistTemplate) => checklistTemplate.templateId !== templateId
        );
        this.noChecklistTemplates = this.checklistTemplates.length <= 0;
      })
      .catch(() => {
        this.growl.error('Unable to delete checklist');
      });
  }
}

module.exports = Constructor;
