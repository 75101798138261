module.exports = function (
  $state,
  billing,
  growl,
  $log,
  authorization,
  $uibModal,
  utils,
  organizations,
  subscriptionService,
  confirmDeleteModal,
  $q,
  confirmModal,
  $window,
  CANCEL_SUBSCRIPTION_PROMO,
  V2_URL,
  LOCAL_DEVELOPMENT,
) {
  'ngInject';

  const vm = this;

  function hydrateCardDescriptions(cards) {
    _.each(cards, (card) => {
      if (card.description) {
        return;
      }

      const nickname = _.get(card, 'metadata.nickname') || '';

      card.description = nickname + (nickname && ' (') + card.brand + ' ending in ' + card.last4 + (nickname && ')');
    });
  }
  /* eslint-disable camelcase */
  vm.$onInit = function () {
    let subscription = vm.user.subscription;

    subscription.plan.amount =
      (subscription.plan.amount || billing.getPriceForTier(subscription.plan, subscription.quantity)) / 100;
    if (subscription.discount) {
      if (subscription.discount.coupon.amount_off) {
        subscription.amount_with_discount = subscription.plan.amount - subscription.discount.coupon.amount_off / 100;
      } else if (subscription.discount.coupon.percent_off) {
        subscription.amount_with_discount =
          subscription.plan.amount - subscription.plan.amount * subscription.discount.coupon.percent_off / 100;
      }
    }
    if (vm.user.subscription?.plan?.amount && vm.user.subscription.plan?.interval === 'year') {
      vm.subscriptionsAmount = vm.user.subscription.plan.amount / 12;
    } else {
      vm.subscriptionsAmount = vm.user.subscription.plan.amount;
    }    vm.subscriptions = vm.user.subscription ? [vm.user.subscription] : [];
    vm.activating = vm.user.subscription.status === billing.subscriptionStatuses.TRIALING;
    vm.isSubscriptionOwner = vm.user.subscription && vm.user.subscription.customer === vm.user.customerId;
    vm.hasBillWrite = vm.user.hasPermission(authorization.claims.BILLING_WRITE);
    vm.refreshSources();

    vm.tab = 1;
    if (vm.customer && !vm.cards.length && vm.achSources.length) {
      vm.tab = 2;
    }

    billing.getPlans(vm.user.orgContext.id).then((result) => {
      vm.plans = result.data;
    });
    billing
      .listInvoices(vm.user.organizationId, vm.$company.subscriptionId)
      .then((result) => vm.invoiceList = result.data)
      .catch((reason) => {
        growl.error(reason.data.message);
      });
  };

  vm.$onDestroy = function () {
    vm.$company.$destroy();
  };

  vm.refreshSources = function () {
    vm.cards = [];
    vm.achSources = [];
    let defaultSource = _.get(vm.user, 'customerRec.default_source', []);

    let sources = _.get(vm.user, 'customerRec.sources.data', []);

    _.forEach(sources, (s) => {
      if (defaultSource === s.id) {
        s.default = true;
      }
      if (s.card) {
        _.assign(s, s.card);
        s.object = 'card';
      }
      if (s.object === 'card') {
        vm.cards.push(s);
      }
      if (s.object === 'bank_account') {
        vm.achSources.push(s);
      }
    });

    hydrateCardDescriptions(vm.cards);
  };

  vm.deleteCard = function (card) {
    // TODO: check to make sure an order is not outstanding using this card as payment method.

    confirmDeleteModal('Payment Method', {
      body: 'Are you sure you want to remove <strong>' + card.description + '</strong>?',
    })
      .then(() => billing.deleteCard(vm.user.orgContext.id, vm.user.customerId, card.id))
      .then(() => billing.getCustomer(vm.user.orgContext.id, vm.user.customerId))
      .then((result) => {
        vm.user.customerRec = result;
        vm.refreshSources();

        growl.success('Card removed successfully.');
      });
  };

  vm.cancelSubscription = function () {
    if (!this.user.onPrimaryOrg()) {
      growl.error('Cannot cancel subscription. You are not signed into your primary organization.');
    }
    if(vm.subscriptionsAmount < 300) {
      vm.updating = true;
      confirmDeleteModal('Subscription', {
        body:
          'Are you sure you want to cancel your subscription? You will be signed out and' +
          ' will lose access to your documents stored on FoodReady.',
        titleVerb: 'Cancel',
        confirmText: 'Yes Cancel Subscription',
        rejectText: 'No Don\'t Cancel Subscription',
      })
        .then(() => {
          let monthlyPlan = vm.plans.find(
            (plan) => plan.product === vm.user.subscription.plan.product && plan.interval === 'month'
          );

          let packagePrice = billing.getPriceForTier(monthlyPlan, vm.user.subscription.quantity);
          let discountPrice = Math.round(packagePrice - packagePrice * 0.9) / 100;

          let body =
            'Would like to get current package at <b class="text-success">$' +
            discountPrice +
            ' (90% discount) per month </b>for 3 months? or <span class="text-primary click-able" ng-click="vm.dismiss()">click here to close</span>';

          return confirmModal({
            title: 'Subscription',
            body,
            okText: 'Yes, Activate Discount',
            cancelText: 'No, Cancel Subscription',
          })
            .then(() => {
              return billing
                .updateSubscription(vm.$company.$id, vm.$company.subscriptionId, {
                  // eslint-disable-next-line camelcase
                  coupon: CANCEL_SUBSCRIPTION_PROMO,
                  items: [
                    {
                      id: vm.user.subscription.items.data[0].id,
                      plan: monthlyPlan.id,
                      quantity: vm.user.subscription.quantity,
                    },
                  ],
                })
                .then(() => {
                  growl.success('Subscription updated.');
                  $window.location.reload();
                  return false;
                })
                .catch((reason) => {
                  growl.error('Sorry, we were unable to process your update request. Please contact customer support.');
                  $log.error('Unable to update user subscription.', reason);
                });
            })
            .catch((reason) => {
              if (reason === 'cancel') {
                return true;
              }
            });
        })
        .then((cancelSubscription) => {
          if (cancelSubscription) {
            return billing.cancelSubscription(vm.$company.$id, vm.$company.subscriptionId).then(() => {
              return true;
            });
          }
        })
        .then((cancelSubscription) => {
          if (cancelSubscription) {
            if (LOCAL_DEVELOPMENT) {
              $$state.go('authentication.signout');
            } else {
              $window.open(`${V2_URL}/signin`, '_self');
            }
            growl.success('Subscription cancelled.');
          }
        })
        .catch((reason) => {
          if (utils.isModalDismissalByUser(reason)) {
            return;
          }

          growl.error('Sorry, we were unable to process your cancellation request. Please contact customer support.');
          $log.error('Unable to cancel user.', reason);
        })
        .finally(() => {
          vm.updating = false;
        });
    } else {
      return $uibModal.open({
        component: 'frPricingPage',
        size: 'xl',
        backdrop: 'static',
        resolve: {
          cancelModal: () => true,
        },
      });
    }
  };

  vm.changePlan = function () {
    vm.updating = true;
    return $uibModal
      .open({
        component: 'frPricingPage',
        size: 'xl',
        backdrop: 'static',
      })
      .result.then((result) => {
        if (result === 'modalDismissed') {
          return this.$q.reject('cancel');
        }
      })
      .finally(() => vm.updating = false);
  };

  vm.changeDefaultSource = function (activating) {
    return $uibModal
      .open({
        component: 'cfChangeDefaultBillingSource',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          user: () => vm.user,
          activating: () => activating,
        },
      })
      .result.then(() => {
        if (activating) {
          this.subscriptions = [vm.user.subscription];
          growl.success('Subscription activated.');
        } else {
          growl.success('Subscription Payment Method Updated.');
        }
        vm.refreshSources();
        vm.activating = false;
      })
      .catch((reason) => {
        if (utils.isModalDismissalByUser(reason)) {
          return;
        }

        growl.error('Sorry, we were unable to process your request. Please contact customer support.');
        $log.error('Unable to upgrade user.', reason);
      });
  };

  vm.cardExpired = function (month, year) {
    return billing.cardExpired(month, year);
  };

  vm.addCard = function () {
    $uibModal
      .open({
        component: 'cfAddCc',
        size: 'lg',
        resolve: {
          user: () => vm.user,
        },
      })
      .result.then(() => billing.getCustomer(vm.user.orgContext.id, vm.user.customerId))
      .then((result) => {
        vm.user.customerRec = result;
        this.changeDefaultSource();
        vm.refreshSources();
        growl.success('New card added successfully.');
      })
      .catch((reason) => {
        if (utils.isModalDismissalByUser(reason)) {
          return;
        }

        growl.error('Could not add card payment method.');
      });
  };

  // vm.addAch = function() {
  //   $uibModal.open({
  //     component: 'cfAddAch',
  //     resolve: {
  //       user: () => vm.user
  //     }
  //   }).result
  //   .then((source) => {
  //     vm.achSources.push(source);
  //     this.changeDefaultSource();
  //     vm.refreshSources();
  //   })
  //   .catch((reason) => {
  //     if (utils.isModalDismissalByUser(reason)) { return; }
  //
  //     growl.error('Could not add ACH payment method.');
  //   });
  // };

  // vm.verifyAch = function(source) {
  //   $uibModal.open({
  //     component: 'cfVerifyAch',
  //     resolve: {
  //       user: () => vm.user,
  //       source: () => source
  //     }
  //   }).result.then(() => {
  //     source.status = 'verified';
  //     growl.success('ACH payment method successfully verified!');
  //   }).catch(reason => {
  //     if (utils.isModalDismissalByUser(reason)) { return; }
  //
  //     growl.error('Could not verify ACH payment method.');
  //   });
  // };
  //
  // vm.deleteAch = function(source) {
  //   // TODO: check to make sure an order is not outstanding using this source as payment method.
  //
  //   confirmDeleteModal('Payment Method', {
  //     body: 'Are you sure you want to remove ACH payment method for account ending ' +
  //     'in <strong>' + source.last4 + '</strong>?'
  //   }).then(() => {
  //     return billing.deleteAch(this.user.currentOrgContext().id, this.user.customerId, source.id);
  //   }).then(() => {
  //     _.remove(vm.achSources, (s) => s.id === source.id);
  //     growl.success('ACH account deleted');
  //   }).catch(reason => {
  //     if (utils.isModalDismissalByUser(reason)) { return; }
  //
  //     growl.error('Unable to delete ACH account. Please contact customer support.');
  //     $log.error('Unable to delete ACH account: ' + reason);
  //   });
  // };
};
