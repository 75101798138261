module.exports = function(ngModule) {
  ngModule.component('frOperationalChecklistList', {
    bindings: {
      user: '<',
      company: '<',
    },
    template: require('./checklist.operational.list.template.html'),
    controller: require('./checklist.operational.list.controller'),
    controllerAs: 'vm'
  });
};
