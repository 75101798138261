module.exports = function(ngModule) {
  ngModule.factory('SopSearch', function($http, $q, utils) {
    'ngInject';

    const DEFAULT_PAGE_SIZE = 30;

    class SopSearch {
      constructor(orgIds) {
        this.baseUri = 'sops';
        this.orgIds = orgIds;
        this.maxReached = false;
        this.pageSize = DEFAULT_PAGE_SIZE;
      }

      /**
       * Add the type of the SOP rec
       * @param {string} typeId See constants for types
       * @returns {SopSearch} The SopSearch object
       */
      type(typeId) {
        this.typeIdFilter = typeId;

        return this;
      }

      /**
       * Add the productId filter
       * @param {string} productId Limit to this product id
       * @returns {SopSearch} The SopSearch object
       */
      productId(productId) {
        this.productIdFilter = productId;

        return this;
      }

      /**
       * Add the isFacilitySop filter - only sops not tied to a single plan control
       * @param {boolean} isFacilitySop If true restrict to facility SOPs. If false restrict to plan sops.
       * @returns {SopSearch} The SopSearch object
       */
      isFacilitySop(isFacilitySop) {
        this.isFacilitySopFilter = isFacilitySop;

        return this;
      }

      /**
       * Sort by a property on the procedure entry
       * @param {string} sortBy Sort field
       * @param {string} sortDirection Sort direction
       * @returns {SopSearch} The SopSearch object
       */
      sortBy(sortBy, sortDirection = 'asc') {
        this.sortByValue = sortBy;
        this.sortDirection = sortDirection;

        return this;
      }

      /**
       * Set the max page size of the result set
       * @param {number} size Max page size
       * @returns {SopSearch} The SopSearch object
       */
      size(size) {
        this.pageSize = size;

        return this;
      }

      /**
       * Start from index
       * @param {number} index Max page size
       * @returns {SopSearch} The SopSearch object
       */
      startFrom(index) {
        this.from = index;

        return this;
      }

      /**
       * Return only these fields.
       * @param {Array} fields The fields to include in the result set
       * @returns {SopSearch} The SopSearch object
       */
      fields(fields) {
        this.fieldsToReturn = fields;

        return this;
      }

      /**
       * Return the result set as a json file download.
       * @returns {SopSearch} The SopSearch object
       */
      asFile() {
        this.resultAsFile = true;

        return this;
      }

      /**
       * Set the search text (currently will try to match key name)
       * @param {string=} text The search text
       * @returns {Promise} The SopSearch object
       */
      search(text = '') {
        this.from = 0;
        this.maxReached = false;
        this.searching = true;
        this.badSearch = false;

        return this._search(text)
          .finally(() => { this.searching = false; });
      }

      getMore(text) {
        if (this.badSearch) { throw new Error('Failed search...cannot get more'); }
        if (this.maxReached || this.searching) { return; }
        this.from += this.pageSize;

        return this._search(text);
      }

      $loaded() {
        return this.httpPromise;
      }

      _search(text) {
        let queryParmArray = [];

        queryParmArray.push(`orgIds=${this.orgIds.join(',')}`);
        queryParmArray.push(`from=${this.from}`);
        queryParmArray.push(`size=${this.pageSize}`);
        if (this.resultAsFile) {
          queryParmArray.push('asFile=true');
        }
        if (!_.isUndefined(this.isFacilitySopFilter)) {
          queryParmArray.push('isFacilitySop=' + this.isFacilitySopFilter);
        }

        queryParmArray.push(`sortBy=${this.sortByValue || 'createdOn'}`);
        queryParmArray.push(`sortDirection=${this.sortDirection || 'desc'}`);
        if (this.fieldsToReturn) {
          queryParmArray.push(`fields=${angular.toJson(this.fieldsToReturn)}`);
        }

        if (text) { queryParmArray.push(`searchText=${text}`); }
        if (this.typeIdFilter) { queryParmArray.push(`typeId=${this.typeIdFilter}`); }
        if (this.productIdFilter) { queryParmArray.push(`productId=${this.productIdFilter}`); }
        const url = `${this.baseUri}${utils.toQueryParm(queryParmArray)}`;

        this.searching = true;
        this.httpPromise = $http.get(url)
          .then(result => {
            if (this.resultAsFile) {
              return result.data.sopUrl;
            }
            if (result.status === 204) {
              this.maxReached = true;
              return [];
            }

            let recs = result.data.sops;

            if (recs.length < this.pageSize) {
              this.maxReached = true;
            }

            return recs.map(rec => ({ ...rec, $id: rec.id }));
          })
          .catch(err => {
            this.badSearch = true;

            return $q.reject(err);
          })
          .finally(() => this.searching = false);

        return this.httpPromise;
      }
    }

    return SopSearch;
  });
};
