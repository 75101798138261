module.exports = function(ngModule) {
  class Users {
    constructor($q, $log, $firebaseObject, $firebaseArray, fbutil, CacheFactory) {
      'ngInject';

      this.$q = $q;
      this.$firebaseObject = $firebaseObject;
      this.$firebaseArray = $firebaseArray;
      this.$log = $log;
      this.fbutil = fbutil;
      this.CacheFactory = CacheFactory;

      this.profileCache = {};

      // Check to make sure the cache doesn't already exist
      if (!this.CacheFactory.get('clientProfileCache')) {
        this.clientProfileCache = this.CacheFactory('clientProfileCache', {
          maxAge: 30 * 60 * 1000, // 30 minutes
          deleteOnExpire: 'aggressive'
        });
      }
    }

    $get(uid) {
      return this.$firebaseObject(this.fbutil.ref('users', uid)).$loaded();
    }

    /**
     * Get a user's profile
     * @param {string} uid The user's uid
     * @returns {Promise<Object>} A promise resolving to the user's profile object.
     */
    getProfile(uid) {
      return this.fbutil.ref('users', uid).once('value')
        .then((profile) => profile.exists() ? _.assign(profile.val(), {uid: uid}) : undefined);
    }

    /**
     * Get a user's profile
     * @param {string} uid The user's uid
     * @returns {Promise<$firebaseObject>} A promise resolving to the user's firebase profile object.
     */
    $getProfile(uid) {
      return this.$firebaseObject(this.fbutil.ref('users', uid)).$loaded();
    }

    /**
     * Create a profile for the user with ID uid
     * @param {string} uid The user's uid
     * @param {Object} profile The user profile (e.g. first name, last name, email)
     * @returns {Promise} A promise to be resolved with the result of the update.
     */
    createProfile(uid, profile) {
      return this.fbutil.ref('users').child(uid).set(profile);
    }

    /**
     * Remove a profile for the user with ID uid
     * @param {string} uid The user's uid
     * @returns {Promise} A promise to be resolved with the result of the remove.
     */
    removeProfile(uid) {
      return this.fbutil.ref('users').child(uid).remove();
    }

    /**
     * Returns a mini-profile for an organization
     * @param {string} uid The user's ID
     * @returns {object} The user's client profile
     */
    getClientProfile(uid) {
      if (this.clientProfileCache.get(uid)) {
        return this.$q.resolve(this.clientProfileCache.get(uid));
      }

      return this.$q.all({
        uid: uid,
        firstName: this.fbutil.ref('users', uid, 'firstName').once('value').then(this.fbutil.getValueOrDefault),
        lastName: this.fbutil.ref('users', uid, 'lastName').once('value').then(this.fbutil.getValueOrDefault),
        email: this.fbutil.ref('users', uid, 'email').once('value').then(this.fbutil.getValueOrDefault),
        phone: this.fbutil.ref('users', uid, 'phone').once('value').then(this.fbutil.getValueOrDefault),
        marketingId: this.fbutil.ref('users', uid, 'marketingId').once('value').then(this.fbutil.getValueOrDefault)
      })
        .then((clientProfile) => {
          this.clientProfileCache.put(uid, clientProfile);

          return clientProfile;
        })
        .catch(err => {
          this.$log.error('Unable to get client profile.', this.$log.toString(err));

          return null;
        });
    }

    /**
     * Set the organizationId for a user ('users/[uid]/organizationId')
     * @param {string} uid User's uid
     * @param {string} orgId Organization ID
     * @returns {Promise} A promise to be resolved with the result of the update.
     */
    setOrganizationId(uid, orgId) {
      return this.fbutil.ref('users', uid, 'organizationId').set(orgId);
    }

    getName(uid) {
      return this.$q.all({
        firstName: this.fbutil.ref('users', uid, 'firstName').once('value').then(this.fbutil.getValueOrDefault),
        lastName: this.fbutil.ref('users', uid, 'lastName').once('value').then(this.fbutil.getValueOrDefault)
      }).then(result => `${result.firstName || ''} ${result.lastName || ''}`.trim());
    }

    getPublicProfile(uid) {
      if (this.profileCache[uid]) { return this.$q.resolve(this.profileCache[uid]); }

      return this.$q.all({
        $id: uid,
        uid: uid,
        name: this.getName(uid),
        companyName: this.fbutil.ref('users', uid, 'companyName').once('value')
          .then(this.fbutil.getValueOrDefault),
        title: this.fbutil.ref('users', uid, 'title').once('value')
          .then(this.fbutil.getValueOrDefault),
        bioDescription: this.fbutil.ref('users', uid, 'bioDescription').once('value')
          .then(this.fbutil.getValueOrDefault),
        isExpert: this.fbutil.ref('achievements', 'experts', uid).once('value')
          .then(this.fbutil.getValueOrDefault),
        isIdphExpert: this.fbutil.ref('achievements', 'idphExperts', uid).once('value')
          .then(this.fbutil.getValueOrDefault)
      })
      .then(profile => {
        this.profileCache[uid] = profile;
        return profile;
      });
    }

    /**
     * Set the marketingId on the user record.
     * @param {(Object|string)} userOrUid If this is a user obj, set the marketingId and update the obj - if uid just
     *   set the marketingId.
     * @param {string} marketingId The contact ID form the CF marketing system.
     * @returns {Promise} A promise to be resolved with the result of the update.
     */
    setMarketingId(userOrUid, marketingId) {
      if (!marketingId) { throw 'No marketingId provided.'; }

      let uid = _.isString(userOrUid) ? userOrUid : userOrUid.uid;

      return this.fbutil.ref('users', uid, 'marketingId').set(marketingId)
        .then(result => {
          if (!_.isString(userOrUid)) { userOrUid.marketingId = marketingId; }
          return result;
        });
    }

    /**
     * This call is deprecated. User.service.js will now create a customer whenever one doesn't exist, which means
     * all users will have a customer record.
     * @param {object} user Logged in user
     * @param {string} customerId Customer Id.
     * @return {Promise} A promise that results when finished.
     */
    setCustomerId(user, customerId) {
      return this.fbutil.ref('users', user.uid, 'customerId').set(customerId)
        .then(result => {
          user.customerId = customerId;
          return result;
        });
    }

    /**
     * Get an user's shopping cart.
     * @param {string} uid The user's uid
     * @returns {Promise<object>} A promise resolving to the user's shopping cart
     */
    getShoppingCart(uid) {
      return this.fbutil.ref('users', uid, 'shoppingCart').once('value')
        .then(this.fbutil.getValueOrDefault);
    }

    /**
     * Get a user's shopping cart items.
     * @param {string} uid The user's uid
     * @returns {Promise<$firebaseArray>} A firebase array of the user's shopping cart items
     */
    $getShoppingCartItems(uid) {
      return this.$firebaseArray(this.fbutil.ref('users', uid, 'shoppingCart', 'items')).$loaded();
    }

    /**
     * Update an organization's marketplace services
     * @param {object} user The user
     * @param {object | Array<object>} items The shopping cart item
     * @param {boolean} items.isRush Is the item(s) a rush item
     * @param {object} items.product The item product
     * @param {string} items.product.brandName The product brand name
     * @param {string} items.product.externalId The product external ID
     * @param {string} items.product.key The product $id
     * @param {object} items.service The item service
     * @param {string} items.service.key The service $id
     * @param {object} items.service.price The service price
     * @param {number} items.service.price.regular The service regular price
     * @param {number} items.service.price.rush The service rush price
     * @param {string} items.service.title The service title
     * @param {string} items.providerOrgId The provider that will be servicing the order item
     * @param {string} items.specialInstructions Any special instructions for the order item
     * @returns {Promise<firebase.database.Reference>} The result of the push operation
     */
    addShoppingCartItems(user, items) {
      items = _.isArray(items) ? items : [items];

      return this.$q.all(_.map(items, (item) =>
        this.fbutil.ref('users', user.uid, 'shoppingCart', 'items').push(item)
          .then((result) =>
            _.set(user, 'shoppingCart.items[' + result.key + ']', item))))
        .then(() => this.setShoppingCartUpdatedOn(user.uid));
    }

    /**
     * Remove an item from an organization's shopping cart.
     * @param {object} user The user
     * @param {string} itemId The shopping cart item ID
     * @returns {Promise<firebase.database.Reference>} The result of the push operation
     */
    removeShoppingCartItem(user, itemId) {
      if (!_.get(user, 'shoppingCart.items[' + itemId + ']')) { return this.$q.resolve(); }

      return this.fbutil.ref('users', user.uid, 'shoppingCart', 'items', itemId).remove()
        .then((result) => {
          delete user.shoppingCart.items[itemId];
          return this.setShoppingCartUpdatedOn(user.uid).then(() => result);
        });
    }

    setShoppingCartUpdatedOn(uid, timeStamp = firebase.database.ServerValue.TIMESTAMP) {
      return this.fbutil.ref('users', uid, 'shoppingCart', 'updatedOn').set(timeStamp);
    }

    setCompanyName(uid, companyName) {
      return this.fbutil.ref('users', uid, 'companyName').set(companyName);
    }
  }

  ngModule.service('users', Users);
};
