class Controller {
  constructor($q, $uibModal, products, confirmDeleteModal, IngredientSearch, utils) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.productsSvc = products;
    this.confirmDeleteModal = confirmDeleteModal;
    this.IngredientSearch = IngredientSearch;
    this.utils = utils;
  }

  $onInit() {
  }

  addIngredientToStep(ingredient) {
    let stepPromise = _.keys(this.product.processSteps).length ?
      this.$uibModal.open({
        component: 'cfChooseFromListModal',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          itemName: () => 'Process Step',
          header: () => '<i class="far fa-leaf mr-2"></i> Select Process Step',
          itemsArray: () => _.map(this.product.processSteps, (step, id) => _.assign(step, {$id: id})),
          columns: () => [
            {title: 'Name', property: 'name'},
            {title: 'Description', property: 'description'}
          ]
        }
      }).result :
      this.$uibModal
        .open({
          component: 'cfProcessStep',
          backdrop: 'static',
          size: 'md',
          resolve: {
            $processStep: () => this.productsSvc.$pushStep(this.product.$id),
            messageHtml: () =>
              '<strong>Note: </strong>It appears you haven\'t added any process steps to your process flow diagram. ' +
              'Enter new step information below and add your ingredient to a new process step.'
          }
        })
        .result;

    stepPromise
      .then(step => this.productsSvc.addIngredientProcessStep(this.product.$id, ingredient.$id || ingredient.id, step.$id)
        .then(() => {
          ingredient.processSteps = ingredient.processSteps || {};
          ingredient.processSteps[step.$id] = true;
        }));
  }

  removeIngredient(ingredient) {
    this.confirmDeleteModal('Ingredient', {
      body: `Are you sure you want to remove ingredient <strong>${ingredient.name}</strong>?`
    }).then(() => {
      this.productsSvc.removeIngredient(this.product.$id, ingredient.$id)
        .then(() => _.remove(this.ingredients, {$id: ingredient.$id}))
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove ingredient.'));
    });
  }

  removeIngredientFromStep(ingredient, stepId) {
    this.confirmDeleteModal('Ingredient', {
      headerHtml: '<span class="text-danger">Remove from <strong>Process Step</strong>?</span>',
      confirmText: 'Remove',
      body: `Are you sure you want to remove ingredient <strong>${ingredient.name}</strong> from process step ` +
        `<strong>${this.product.processSteps[stepId].name}</strong>?`
    }).then(() => {
      this.productsSvc.removeIngredientProcessStep(this.product.$id, ingredient.$id, stepId)
        .then(() => delete ingredient.processSteps[stepId])
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove ingredient from process step.'));
    });
  }
}

module.exports = Controller;
