class Constructor {
  constructor(products, marketplaceService, marketplaceRfqStatus, requestForQuotes, notifications,
              $log, $state, $q) {
    'ngInject';

    this.products = products;
    this.marketplaceService = marketplaceService;
    this.marketplaceRfqStatus = marketplaceRfqStatus;
    this.rfqService = requestForQuotes;
    this.notifications = notifications;
    this.$log = $log;
    this.$state = $state;
    this.$q = $q;

    this.expertService = null;
  }

  $onInit() {
    this.marketplaceService.getAvailableServices(this.company)
      .then((services) => {
        this.expertService = services[this.$state.params.serviceId || this.onboarding.defaultExpertServiceId];

        if (!this.expertService) {
          this.$log.error('Could not find default onboarding service; please check ' +
            'onboarding/defaultExpertServiceId for a valid expert service ID.');
          return;
        }
      });

    if (this.user.phone) {
      _.set(this.company, 'onboarding.rfqCallBack', this.user.phone);
    }
  }

/*
  submitRfq(newRecs) {
    let conflicts = [];

    return this.$q.all([this.marketplaceService.getServiceProviders(this.user.orgContext.id, [this.expertService.$id]),
      this.rfqService.get(this.user.orgContext.id, true)])
      .then(([providerProfiles, existingRequests]) => this.$q.all(_.map(newRecs, (newRec) => {
        newRec.status = this.marketplaceRfqStatus.OPEN;
        newRec.createdOn = firebase.database.ServerValue.TIMESTAMP;
        newRec.createdBy = this.user.uid;
        newRec.requesterOrgId = this.user.orgContext.id;
        newRec.subjectOrgId = providerProfiles[this.expertService.$id].$id;
        newRec.hidden = true;

        if (_.find(existingRequests.data, (existingReq) =>
          existingReq.product &&
          existingReq.product.key === newRec.product.key &&
          existingReq.status === this.marketplaceRfqStatus.OPEN &&
          existingReq.service.key === newRec.service.key)) {
          conflicts.push(newRec);

          return this.$q.resolve();
        }

        return this.rfqService.$push(newRec);
      }))
        .then(($newRfqs) => {
          if (conflicts.length < newRecs.length) {
            this.$log.info(`New RFQ submitted - Service: ${this.expertService.title}, Organization: ` +
              `${_.get(this.user, 'orgContext.companyName')}, Products: ${_.reduce($newRfqs, (result, val, idx) => {
                result += val.product.brandName + (idx < $newRfqs.length - 1 ? ', ' : '');

                return result;
              }, '')}`, {numProducts: $newRfqs.length}, {
                notify: true,
                type: 'marketplace'
              });

            this.notifications.postToOrg({
              from: this.user.uid,
              to: providerProfiles[this.expertService.$id].$id,
              message: 'A Request For Quote was submitted by: <i>"' + this.user.orgContext.companyName + '"</i>',
              link: {state: 'marketplace.requestForQuote.list'}
            });
          }
        }));
  }
*/

  finish() {
    this.company.onboarded = true;
    this.finishing = true;
    this.$log.info('onboarding:questionnaire - complete.');
    this.company.$save()
      .then(() => {
        let callBackText = this.company.onboarding.rfqCallBack ?
          `Callback Number: ${this.company.onboarding.rfqCallBack}` : 'No callback number provided.';

        this.$log.info(`RFQ Callback Requested - ${this.user.fullName()} from ${this.company.companyName}. ` +
          callBackText, null,
          {notify: true, notifyRecipient: 'sales'});
      })
      .catch(err => {
        this.$log.error('An error occurred saving onboarding questionnaire', err);
      })
      .finally(() => {
        if (this.$state.params.productId) {
          this.$state.go('products.edit', {productId: this.$state.params.productId});
        } else {
          this.$state.go('user.dashboard', {welcome: true});
        }
      });
  }
}

module.exports = Constructor;
