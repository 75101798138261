module.exports = function(ngModule) {
  ngModule.component('cfProducts', {
    bindings: {
      productSearch: '<',
      user: '<',
      productConstraints: '<'
    },
    template: require('./products.list.template.html'),
    controller: require('./products.list.controller'),
    controllerAs: 'vm'
  });
};
