module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider.state('taskManager', {
      url: '/taskManager',
      template: '<div class="col-md-12 text-center">' +
                  '<h3><i class="fas fa-sync fa-spin fa-fw"></i> Redirecting...</h3>' +
                '</div>',
    });
  }).run((menus) => {
    'ngInject';

    menus.addMenuItem('dashboard', {
      title: 'Task',
      state: 'task',
      type: 'dropdown',
      position: 3,
      isPublic: false
    });
    menus.addSubMenuItem('dashboard', 'task',{
      title: 'Analyzer',
      redirectPath: '/tasks/report',
      position: 1,
    });

    menus.addSubMenuItem('dashboard', 'task', {
      title: 'Scheduler',
      redirectPath: '/tasks',
      position: 2
    });
  });
};
