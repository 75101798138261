class Constructor {
  constructor(adminServices, CF_CLAIMS, growl, $log, $timeout) {
    'ngInject';

    this.adminServices = adminServices;
    this.growl = growl;
    this.$log = $log;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.loadingRoleNames = true;
    this.newClaim = '';
    this.loadRoleNames().finally(() => {
      this.loadingRoleNames = false;
    });
  }

  loadRoleNames() {
    return this.adminServices.getRoleNames().then(names => {
      this.roleNames = _.map(names, name => ({name, value: name}));
    });
  }

  changeRole(role) {
    this.unknownClaims = [];
    this.loadingClaims = true;
    return this.adminServices.getClaims(null, role).then(claims => {
      this.claims = _.keys(claims);
    }).finally(() => this.$timeout(() => {
      this.loadingClaims = false;
    }));
  }


  addClaim() {
    if (this.newClaim) {
      this.claims.push(this.newClaim);
      this.newClaim = '';
    }
  }

  removeSelected(selectedClaims) {
    _.remove(this.claims, c => selectedClaims === c);
  }

  save() {
    let claimsToSave = _.reduce(this.claims, (obj, claim) => {
      obj[claim] = true;
      return obj;
    }, {});

    this.adminServices.saveClaims(null, this.selectedRole, claimsToSave).then(() => {
      this.growl.success('Role saved');
    });
  }
}

module.exports = Constructor;
