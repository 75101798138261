'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfSupplierAddress', {
    bindings: {
      supplier: '<'
    },
    template: require('./ingredients.supplier.address.template.html'),
    controller: require('./ingredients.supplier.address.controller.js'),
    controllerAs: 'vm'
  });
};
