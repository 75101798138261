'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRecallPlanConsigneeList', {
    bindings: {
      user: '<',
      customerTypes: '<',
      $customers: '='
    },
    template: require('./recall-plan.consignee-list.template.html'),
    controller: require('./recall-plan.consignee-list.controller.js'),
    controllerAs: 'vm'
  });
};
