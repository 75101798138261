class CfInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-textarea.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      label: '@',            // The label text
      typeahead: '&?',       // Optional typeahead routine
      onSelect: '&?',        // If typeahead, this is called when a typeahead item is chosen.
      rows: '@',             // The number of text area rows
      name: '@',             // The name of the form control
      helpText: '@',         // Optional subscript helper text
      popoverPlacement: '@', // The placement of the popover help text
      popoverHelpText: '@',  // Optional question mark icon to right of label with popover helper text
      isRequired: '=',       // Is the field required (true / false)
      isReadonly: '=',       // Is the field readonly (true / false)
      placeholder: '@',      // Input placeholder
      onChange: '&?'         // Callback onChange
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  link(scope, element) {
    let vm = scope.vm;

    $(document).bind('click', function(event) {
      let isClickedElementChildOfPopup = element
        .find(event.target)
        .length > 0;

      if (isClickedElementChildOfPopup) { return; }

      scope.$apply(() => {
        vm.searchResults = [];
      });
    });
  }

  controller(utils, $q, $timeout) {
    'ngInject';

    const vm = this;

    vm.$onInit = function() {
      vm.doRefresh = vm.typeahead ? _.debounce(vm._doRefresh, 100, {leading: true, trailing: true}) : angular.noop();
      vm.onSelect = vm.onSelect || angular.noop();
    };

    vm.select = function(val) {
      vm.searchResults = [];
      if (_.isFunction(vm.onSelect)) {
        vm.onSelect({$item: val});
      }
    };

    vm._doRefresh = function($event) {
      if (!vm.ngModel || vm.ngModel.length <= 3) {
        vm.searchResults = [];
        return;
      }
      if (utils.isBenignKeyUp($event.keyCode)) { return; }
      vm.searching = true;

      $q.when(vm.typeahead({$search: vm.ngModel})).then(results => {
        if (!results) { return; }
        vm.searchResults = results;
      }).catch(() => {
        vm.searchResults = [];
      }).finally(() => {
        $timeout(() => {
          vm.searching = false;
        });
      });
    };
  }
}

module.exports = CfInput;
