class Controller {
  constructor($timeout, $state, $log, products, preventDirtyNav, growl, users, sopService, orgTypes, sopLogFormTypes) {
    'ngInject';

    this.$timeout = $timeout;
    this.$state = $state;
    this.$log = $log;
    this.products = products;
    this.preventDirtyNav = preventDirtyNav;
    this.growl = growl;
    this.users = users;
    this.sopService = sopService;
    this.orgTypes = _.map(orgTypes, o => o.id);
    this.suggestedTypes = [{name: 'plan', value: 'plan'}, {name: 'facility', value: 'facility'}];
    this.sopLogFormTypes = sopLogFormTypes;
  }

  $onInit() {
    this.isNew = !this.$sop.createdOn;
    // this.organization = {$id: ''};
    // this.remoteDirectory = 'sops/' + this.$sop.$id + '/files';
    this.$sop.typeId = this.$sop.typeId || 'passFail';
    this.$timeout(() => this.preventDirtyNav(this.sopForm, 'You have unsaved SOP changes.', () => this.save()));
    this.isPartner = this.user.isPartner();
    this.isCfAdmin = this.user.isCfAdmin();
    this.selectedOrgTypes = this.$sop.orgTypes ? this.$sop.orgTypes.split(',') : [];
    this.$sop.admin = this.$sop.admin || false;

    this.logFormArray = _.sortBy(this.sopLogFormTypes, 'name');

    if (this.$sop.updatedBy) {
      this.users.getName(this.$sop.updatedBy)
        .then((name) => this.updatedBy = name);
    } else if (this.$sop.createdBy) {
      this.users.getName(this.$sop.createdBy)
        .then((name) => this.createdBy = name);
    }
  }

  save() {
    if (this.isNew) {
      this.$sop.createdOn = firebase.database.ServerValue.TIMESTAMP;
      this.$sop.createdBy = this.user.uid;
      this.$sop.createdByName = this.user.fullName();
      this.$sop.organizationId = this.isPartner || this.user.isFrSopLibAccess() ? this.user.organizationId : null;
    } else {
      this.$sop.updatedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$sop.updatedBy = this.user.uid;
    }
    if (this.selectedOrgTypes.length) {
      this.$sop.orgTypes = this.selectedOrgTypes.join(',');
    }

    this.$sop.$save()
      .then(() => {
        if (this.sopForm) {
          this.sopForm.$setPristine();
        }

        if (this.isNew) {
          this.users.getName(this.$sop.createdBy)
            .then((name) => this.createdBy = name);
          if (this.sops) {
            if (_.isArray(this.sops)) {
              this.sops.push(this.$sop);
            } else {
              this.sops[this.$sop.$id] = this.$sop;
            }
          }
        } else {
          let listSOP = _.find(this.sops, {$id: this.$sop.$id});

          this.users.getName(this.$sop.updatedBy)
            .then((name) => this.updatedBy = name);

          _.assign(listSOP, _.pick(this.$sop, ['title', 'description', 'typeId', 'externalId', 'metadata',
            'createdBy', 'createdOn', 'updatedBy', 'updatedOn']));
        }

        this.isNew = false;
        this.growl.success(`${this.$sop.isTemplate ? 'Procedure template' : 'Procedure'} saved successfully.`);
      })
      .catch((err) => {
        this.$log.error('Unable to save procedure.', this.$log.toString(err));
        this.growl.error('Unable to save procedure. Please try again or contact customer support for assistance.');
      });
  }

  cancel() {
    if (this.sopForm) {
      this.sopForm.$setPristine();
    }

    return this.$state.go('operations.sopLibrary.list');
  }
}

module.exports = Controller;
