require('./dashboard.scss');

module.exports = function(ngModule) {
  ngModule.component('cfDashboard', {
    bindings: {
      user: '<',
      $user: '<',
      $company: '<',
      anyAssignments: '<',
      supplierSearch: '<'
    },
    template: require('./users.dashboard.template.html'),
    controller: require('./users.dashboard.controller.js'),
    controllerAs: 'vm'
  });
};
