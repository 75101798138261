module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider.state('products', {
      url: '/products',
      abstract: true,
      template: '<ui-view class="h-100" />',
      resolve: {
        user: (authentication) => authentication.requireAuth(),
        productSearchObj: (user, productSearch) => productSearch.getSearch(user)
      },
      data: {menuId: 'dashboard'}
    })

      .state('products.list', {
        url: '',
        template: '<cf-products product-search="$resolve.productSearchObj" user="$resolve.user" ' +
        'product-constraints="$resolve.productConstraints"></cf-products>',
        resolve: {
          productConstraints: (constantsService) => constantsService.get('stripe')
            .then(stripeConstants => stripeConstants.constraints)
        }
      })

      .state('products.create', {
        url: '/new',
        template: '<cf-create-product user="$resolve.user" hazards-library="$resolve.hazardsLibrary" ' +
        'food-categories="$resolve.foodCategories"></cf-create-product>',
        resolve: {
          foodCategories: (productCategories) => productCategories.getAll(),
          hazardsLibrary: (hazardsService) => hazardsService.getHazardLibrary()
        }
      })
      .state('products.edit', {
        url: '/:productId/edit?activeTab?activeView?hazardId?controlId',
        template: '<cf-edit-product product="$resolve.product" process-steps="$resolve.processSteps" ' +
        'user="$resolve.user" product-organization="$resolve.productOrganization" $hazards="$resolve.$hazards" ' +
        'is-read-only="$resolve.isReadOnly" process-links="$resolve.processLinks"' +
        'food-categories="$resolve.foodCategories" sops="$resolve.sops" facility-sops="$resolve.facilitySops" members="$resolve.members" > ' +
          '</cf-edit-product>',
        resolve: {
          product: ($stateParams, products) => products.$get($stateParams.productId),
          processSteps: ($stateParams, products) => products.$getProcessSteps($stateParams.productId),
          $hazards: (product, hazardsService) => hazardsService.$getAll(product),
          productOrganization: (product, organizations) => organizations.getProfile(product.organizationId),
          isReadOnly: (product, user, productAccess) => product.isSampleProduct ? true :
            user.isCfAdmin() ? false : productAccess.hasWriteAccess(user, product).then(result => !result),
          sops: (product, sopService) =>
            sopService.query(product.organizationId, '', {productId: product.$id})
              .then(sopsArray => _.keyBy(sopsArray, '$id')),
          facilitySops: (product, sopService) => sopService.query(product.organizationId, '', {isFacilitySop: true})
            .then(sopsArray => _.keyBy(sopsArray, '$id')),
          processLinks: (product, products) => products.$getProcessLinks(product.$id),
          foodCategories: (productCategories) => productCategories.getAll(),
          members: (organizations, $q, users, user) => {
            return organizations.$getMembers(user.orgContext.id)
              .then(function(members) {
                return $q.all(_.map(members, function(member) {
                  return users.getName(member.$id).then(function(name) {
                    return {
                      id: member.$id,
                      name: name
                    };
                  });
                }));
              });
          }
        }
      })
      .state('products.edit.upload', {
        url: '/upload',
        template: '<cf-upload-files product="$resolve.product" files="$resolve.files " ' +
        'organization="$resolve.productOrganization" user="$resolve.user" ' +
      'is-read-only="$resolve.isReadOnly"></cf-upload-files>',
        resolve: {
          product: ($stateParams, products) => products.$get($stateParams.productId),
          productOrganization: (product, organizations) => organizations.getProfile(product.organizationId),
          files: (product, products) => products.externalFiles(product.$id)
        }
      });
  }).run((menus, orgPerspectives, authorization, marketplaceService) => {
    'ngInject';

    menus.addMenuItem('dashboard', {
      title: 'HACCP/PC Plans',
      state: 'products.list',
      position: 1,
      isPublic: false,
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP, '!reviews.' + orgPerspectives.PROVIDER_ANALYST],
      claims: [authorization.claims.PRODUCT_READ]
    });

    // Analyst-only menu
    menus.addMenuItem('dashboard', {
      title: 'Plan',
      state: 'products.edit.planAnalysis.questionnaire',
      paramsFn: () => ({productId: _.get(marketplaceService, 'orderItem.product.key')}),
      hideFn: () => !marketplaceService.orderItem || !marketplaceService.orderItem.product,
      position: 2,
      isPublic: false,
      perspectives: ['reviews.' + orgPerspectives.PROVIDER_ANALYST],
      claims: [authorization.claims.PRODUCT_READ]
    });
  });
};
