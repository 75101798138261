module.exports = function(ngModule) {
  ngModule.component('cfNotification', {
    bindings: {
      notification: '<',
      user: '<',
      onDelete: '&'
    },
    template: require('./notifications.main.template.html'),
    controller: require('./notifications.main.controller'),
    controllerAs: 'vm'
  });
};
