module.exports = function(ngModule) {
  ngModule.component('cfChecklistItem', {
    transclude: true,
    bindings: {
      item: '<',
      title: '@'
    },
    template: require('./users.checklist-item.template.html'),
    controller: require('./users.checklist-item.controller'),
    controllerAs: 'vm'
  });
};