'use strict';

module.exports = function($document, S3, growl, confirmDeleteModal, $timeout, preventDirtyNav, $q, $log) {
  'ngInject';
  var vm = this, uploadProgress = {};

  function refreshUploadProgress() {
    if (!vm.newFiles.length) {
      return 0;
    }

    var progress = _.reduce(uploadProgress, (sum, n) => {
      return sum + n;
    }, 0);

    vm.uploadProgress = _.round(progress / vm.newFiles.length);
  }

  vm.$onInit = function() {
    $timeout(function() {
      preventDirtyNav(vm.recordForm, 'Your records have not been saved.', vm.save);
    }, 0);

    vm.newFiles = [];
    vm.removeFiles = [];
    vm.uploadProgress = 0;
  };

  vm.save = function() {
    var filePromises = [];

    vm.uploading = vm.newFiles.length > 0;
    uploadProgress = {};
    _.each(vm.newFiles, (file, idx) => {
      filePromises.push(
        S3.uploadOrgFile(vm.company.$id, 'products/' + vm.product.$id + '/records/' + file.name, file, (event) => {
          uploadProgress[idx] = parseInt(100.0 * event.loaded / event.total, 10);
          refreshUploadProgress();
        })
          .then((result) => {
            return vm.records.$add(result);
          })
      );
    });

    _.each(vm.removeFiles, (file) => {
      filePromises.push(
        S3.deleteFile(vm.company.$id, file.key).then(() => {
          return vm.records.$remove(file);
        })
      );
    });

    return $q.all(filePromises)
      .then(function() {
        vm.newFiles = [];
        vm.removeFiles = [];
        vm.uploadProgress = 0;
        if (vm.recordForm) {
          vm.recordForm.$setPristine();
        }

        growl.success('Record changes saved.');
      })
      .catch(err => $log.error('An error occurred saving product records: ', err));
  };

};