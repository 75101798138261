class Controller {
  constructor($timeout, $state, $log, products, preventDirtyNav, growl, users, sopService, utils, $q, $stateParams,
    $window, sopLogFormTypes, checklistLibraryService, choiceModal) {
    'ngInject';

    this.$timeout = $timeout;
    this.$state = $state;
    this.$log = $log;
    this.products = products;
    this.preventDirtyNav = preventDirtyNav;
    this.growl = growl;
    this.users = users;
    this.sopService = sopService;
    this.utils = utils;
    this.$q = $q;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.sopLogFormTypes = sopLogFormTypes;
    this.checklistLibraryService = checklistLibraryService;
    this.choiceModal = choiceModal;
  }

  $onInit() {
    this.isNew = _.isEmpty(_.find(this.sops, {$id: this.$sop.$id}));
    this.$sop.typeId = this.$sop.typeId || 'passFail';
    this.$timeout(() => this.preventDirtyNav(this.sopForm, 'You have unsaved SOP changes.', () => this.save()));
    //this.sopTypeArray = _.map(this.sopTypes, (sopType, key) => ({name: sopType.title, value: key}));
    //this.sopTypeSet = !!this.$sop.typeId;

    this.$sop.metadata = this.$sop.metadata || {};
    this.$sop.metadata.logType = this.$sop.metadata.logType || 'logForm';
    this.$sop.metadata.formIds = this.$sop.metadata.formIds || [];
    this.logFormArray = _.sortBy(this.sopLogFormTypes, 'name');

    this.$sop.organizationId = this.$sop.organizationId || this.user.orgContext.id;

    if (this.$sop.updatedBy) {
      this.users.getName(this.$sop.updatedBy)
        .then((name) => this.updatedBy = name);
    } else if (this.$sop.createdBy) {
      this.users.getName(this.$sop.createdBy)
        .then((name) => this.createdBy = name);
    }

    let hasControlInfo = this.hasControlInfo();

    this.facilityOrPlan = hasControlInfo ? 'plan' : 'facility';

    if (hasControlInfo) {
      this.getControlInfo();
    }

    this.remoteDirectory = 'sops/' + this.$sop.$id + '/files';
    this.categoryId = 30;
    this.isCfAdmin = this.user.isCfAdmin();
  }

  hasControlInfo() {
    let metadata = this.$sop.metadata || {};

    return metadata.productId && metadata.stepId && metadata.hazardId && metadata.controlId;
  }

  getControlInfo() {
    // lookup hazard and control, vm.hazardName and vm.controlName
    return this.products.get(this.$sop.metadata.productId).then(product => {
      let hazard = _.get(product, `hazards[${this.$sop.metadata.hazardId}]`);
      let control = hazard ? _.get(hazard, `controls[${this.$sop.metadata.controlId}]`) : '';

      this.planName = product.brandName;
      this.hazardName = _.truncate(hazard.name, {length: 20});
      this.controlName = _.truncate(control.measures, {length: 20});
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to lookup plan information.'));
  }

  saveAndClose() {
    if (this.sopForm && this.sopForm.$pristine) {
      this.close();
    }

    this.save().then(() => this.close());
  }

  save() {
    if (this.isNew) {
      this.$sop.createdOn = firebase.database.ServerValue.TIMESTAMP;
      this.$sop.createdBy = this.user.uid;
    } else {
      this.$sop.updatedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$sop.updatedBy = this.user.uid;
    }

    return this.$sop.$save()
      .then(() => {
        let promises = [];

        if (this.sopForm) {
          this.sopForm.$setPristine();
        }

        // add a reference to the procedure from the plan control if applicable.
        if (_.get(this.$sop, 'metadata.productId') && _.get(this.$sop, 'metadata.stepId') &&
          _.get(this.$sop, 'metadata.hazardId') && _.get(this.$sop, 'metadata.controlId')) {
          promises.push(this.products.addProcedure(this.$sop.metadata.productId, this.$sop.metadata.stepId,
            this.$sop.metadata.hazardId, this.$sop.metadata.controlId, this.$sop.$id)
            .catch((err) => this.$log.error('Could not save hazard after CCP edit -> save. Therefore the hazard ' +
              'will not have a reference to the CCP w/ ID = ' + this.$sop.$id, this.$log.toString(err))));
        }

        if (this.isNew) {
          promises.push(this.users.getName(this.$sop.createdBy)
            .then((name) => this.createdBy = name));
          if (this.sops) {
            if (_.isArray(this.sops)) {
              this.sops.push(this.$sop);
            } else {
              this.sops[this.$sop.$id] = this.$sop;
            }
          }
        } else {
          let listSOP = _.find(this.sops, {$id: this.$sop.$id});

          promises.push(this.users.getName(this.$sop.updatedBy)
            .then((name) => this.updatedBy = name));

          _.assign(listSOP, _.pick(this.$sop, ['title', 'description', 'typeId', 'externalId', 'metadata',
            'createdBy', 'createdOn', 'updatedBy', 'updatedOn']));
        }

        if (_.isFunction(this.onSave)) {
          promises.push(this.onSave({$sop: this.$sop}));
        }

        this.isNew = false;
        this.growl.success('Procedure saved successfully.');
        return this.$q.all(promises);
      })
      .catch((err) => {
        this.$log.error('Unable to save procedure.', this.$log.toString(err));
        this.growl.error('Unable to save procedure. Please try again or contact customer support for assistance.');
      });
  }

  cancel() {
    if (this.sopForm) {
      this.sopForm.$setPristine();
    }

    if (_.isFunction(this.onCancel)) {
      return this.onCancel();
    }

    this.close();
  }

  close() {
    if (this.$stateParams.goBack === 'true') {
      return this.$window.history.back();
    }

    if (this.$stateParams.close === 'true') {
      return this.$window.close();
    }

    this.$state.go('operations.sops.list');
  }

  addForm() {
    // this.choiceModal({
    //   title: 'Choose the type of form',
    //   buttonWidth: 228,
    //   body: '<div>Select Checklist from library or Log our log forms</div>',
    //   choice1Text: 'Log Form',
    //   choice2Text: 'Checklist'
    // }).then((choice) => {
    //   if (choice === 1) {
    //     this.logSheetsPromise.then(logSheets => {
    //       const itemsArray = _.map(logSheets, (val, $id) => _.assign(val, {$id}));
    //
    //       this.$uibModal.open({
    //         component: 'logSheetPreviewModal',
    //         backdrop: 'static',
    //         resolve: {
    //           itemName: () => 'Logs',
    //           header: () => '<i class="far fa-clipboard-check fa-fw"></i> Choose Logs',
    //           instructionsHtml: () => '<div class="g-mb-10">Choose the logs that will be used for record keeping.</div>',
    //           itemsArray: () => itemsArray,
    //           columns: () => [
    //           {title: 'Name', property: 'name'}
    //           ]
    //         }
    //       }).result.then(logForm => {
    //         let formItem = {
    //           type: 'checklist',
    //           id: logForm.value,
    //           name: logForm.name};
    //
    //         return formItem;
    //       });
    //     });
    //   } else {
    this.checklistLibraryService.promptAddChecklist(this.user)
      .then((checklistTemplate) => {
        let formItem = {
          type: 'checklist',
          id: checklistTemplate.copiedTemplateId,
          name: checklistTemplate.title
        };

        return formItem;
      })
      .then((formItem) => {
        this.$sop.metadata.formIds = [formItem];
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to select checklist.'));
  }

  removeForm(i) {
    this.$sop.metadata.formIds = this.$sop.metadata.formIds.filter((forms, index) => index !== i);
  }
}

module.exports = Controller;
