'use strict';

module.exports = function (
  $log,
  $timeout,
  $firebaseObject,
  preventDirtyNav,
  growl,
  $q,
  $uibModal,
  suppliers,
  $state,
  supplierIngredientService,
  supplierService,
  confirmModal,
  $stateParams,
  $window
) {
  'ngInject';

  let vm = this;

  function getSupplier() {
    return $uibModal.open({
      component: 'cfChooseSupplier',
      backdrop: 'static',
      resolve: {
        user: () => vm.user,
        ingredient: () => vm.ingredient,
        company: () => vm.company,
      },
    }).result;
  }

  function checkForLinked() {
    if (!vm.supplier.organizationId) {
      return;
    }
  }

  vm.$onInit = function () {
    $timeout(() => {
      preventDirtyNav(vm.ingredientOverviewForm, 'You have unsaved overview changes.', vm.save);
    });

    // If it's a brand new supplierIngredient, initialize it.
    if (vm.supplierIngredient.$value === null) {
      vm.supplierIngredient.ingredientName = vm.ingredient.name;
      vm.supplierIngredient.createdOn = firebase.database.ServerValue.TIMESTAMP;
      vm.supplierIngredient.ingredientId = vm.ingredient.$id;
      vm.supplierIngredient.organizationId = vm.company.$id;
      vm.supplierIngredient.brandName = vm.ingredient.name;
      vm.supplierIngredient.brandNames = [vm.ingredient.name];
      $timeout(() => vm.ingredientOverviewForm.$setDirty());
    }

    vm.linkedIngredient = vm.supplierIngredient.productId && vm.supplierIngredient.$value !== null;
    if (vm.supplierIngredient.supplierId) {
      checkForLinked();
      if (!vm.supplierIngredient.productId && vm.supplierIngredient.brandName) {
        vm.productResults = [
          {
            brandName: vm.supplierIngredient.brandName,
          },
        ];
        vm.chosenProduct = vm.productResults[0];
        vm.customName = true;
      }
    } else {
      getSupplier()
        .then((supplier) => {
          vm.supplier = supplier;
          checkForLinked();
          vm.supplierIngredient.supplierId = supplier.$id;
        })
        .catch((reason) => {
          vm.ingredientOverviewForm.$setPristine();
          if (reason && reason.redirectTo) {
            $state.go(reason.redirectTo);
            return;
          }
          if (reason !== 'cancel') {
            growl.error('Error choosing a supplier.');
            $log.error(reason);
          }
          $state.go('ingredients.list');
        });
    }
  };

  vm.filterResults = function (results) {
    return _.filter(results, function (entry) {
      return !vm.supplier.ingredients || !vm.supplier.ingredients[entry.$id];
    });
  };

  vm.selectProduct = function (product) {
    if (product.custom) {
      delete vm.supplierIngredient.productId;
      vm.supplierIngredient.brandName = product.brandName;
      return;
    }
    vm.supplierIngredient.productId = product.$id;
    vm.supplierIngredient.brandName = product.brandName;
    vm.supplierIngredient.description = product.description;
    vm.supplierIngredient.productOrganizationId = vm.supplier.organizationId;
  };

  vm.save = function () {
    let newSupplierIngredient = vm.supplierIngredient.$value === null;

    vm.saving = true;
    vm.supplierIngredient.brandName = vm.supplierIngredient.brandNames ?
      vm.supplierIngredient.brandNames.join(',') :
      null;

    vm.supplierIngredient.createdOn = vm.supplierIngredient.createdOn || firebase.database.ServerValue.TIMESTAMP;
    vm.supplierIngredient.description = vm.supplierIngredient.description || null;
    vm.supplierIngredient
      .$save()
      .then(() => {
        if (newSupplierIngredient) {
          return supplierIngredientService.add(vm.supplierIngredient, vm.supplier.$id, vm.ingredient.$id);
        }
      })
      .then(() => {
        if (vm.supplierIngredient.productId && (newSupplierIngredient || !vm.linkedIngredient)) {
          return supplierIngredientService.linkToProduct(vm.user, vm.supplierIngredient).then(() => {
            return confirmModal({
              title: 'Access Request Sent',
              body: `An access request was sent to <b>${vm.supplier.name}</b> for permission to view 
<b>${vm.supplierIngredient.brandName}</b> documents. Monitor the status of the request in your supplier list. 
If approved, return here to view any documentation uploaded by <b>${vm.supplier.name}</b>.`,
              okText: 'Ok',
              hideCancelButton: true,
            });
          });
        }
      })
      .then(() => {
        vm.linkedIngredient = vm.supplierIngredient.productId;
        if (vm.ingredientOverviewForm) {
          vm.ingredientOverviewForm.$setPristine();
        }
        $state.go(
          $state.current.name,
          {supplierIngredientId: vm.supplierIngredient.$id, ingredientId: vm.ingredient.$id},
          {reload: false}
        );

        vm.saving = false;
        growl.success('Ingredient saved successfully!');
        if ($stateParams.close) {
          $window.close();
        }
      })
      .catch((err) => {
        vm.saving = false;
        $log.error(err);
        growl.error('Unable to save ingredient. Please try ' + 'again or contact FoodReady customer support.');
      });
  };

  vm.cancel = function () {
    if (vm.ingredientOverviewForm.$dirty) {
      confirmModal({
        title: 'Unsaved Changes!',
        okText: 'Yes, I\'m sure.',
        cancelText: 'No, stay on this page.',
        body:
          'It looks like you have unsaved changes. Are you sure you want to undo ' +
          'your updates and return to the ingredients list?',
      }).then(() => {
        if (vm.ingredientOverviewForm) {
          vm.ingredientOverviewForm.$setPristine();
        }
        $state.go('ingredients.list');
      });
    } else {
      $state.go('ingredients.list');
    }
  };
};
