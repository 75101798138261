module.exports = function(ngModule) {
  ngModule.component('cfCustomerOrder', {
    bindings: {
      user: '<',
      productCustomer: '<',
      orders: '<',
    },
    template: require('./product-customers.order.template.html'),
    controller: require('./product-customers.order.controller.js'),
    controllerAs: 'vm'
  });
};
