module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceProviders', {
    bindings: {
      user: '<',
      organization: '<',
      providerProfiles: '<',
      defaultServices: '<'
    },
    template: require('./marketplace.providers.template.html'),
    controller: require('./marketplace.providers.controller'),
    controllerAs: 'vm'
  });
};
