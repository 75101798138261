module.exports = function(ngModule) {
  ngModule.component('cfEditEquipment', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./equipment.edit.template.html'),
    controller: require('./equipment.edit.controller'),
    controllerAs: 'vm'
  });
};
