class Controller {
  constructor(billing, $q, users, growl, $log) {
    'ngInject';

    this.billing = billing;
    this.$q = $q;
    this.users = users;
    this.growl = growl;
    this.$log = $log;
  }

  $onInit() {
    this.user = this.resolve.user;

    this.step = 1;
    this.achData = {
      country: 'US',
      currency: 'USD'
    };
    this.metadata = {};
  }

  forward() {
    this.step++;
  }

  back() {
    this.step--;
  }

  addAch() {
    this.adding = true;
    let orgId = this.user.currentOrgContext().id;
    let customerIdPromise = this.$q.when(this.user.customerId || this.createCustomer(orgId, this.user));

    customerIdPromise.then(customerId => {
      return this.billing.addAch(this.user.currentOrgContext().id, customerId, this.achData, this.metadata);
    }).then(newSource => {
      this.newSource = newSource;
      this.forward();
    }).catch(err => {
      this.growl.error('Unable to add ACH payment method.');
      this.$log.error('Unable to add ACH ', err);
    }).finally(() => {
      this.adding = false;
    });
  }

  createCustomer(orgId, user) {
    return this.billing.createCustomer(orgId, {email: user.email, metadata: {userId: user.uid}})
      .then((customer) => this.users.setCustomerId(user, customer.id).then(() => customer.id));
  }

}

module.exports = Controller;