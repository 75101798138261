module.exports = function (ngModule) {
  ngModule.component('frTaskSchedulerModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<',
    },
    template: require('./task-scheduler-modal.template.html'),
    controller: require('./task-scheduler-modal.controller'),
    controllerAs: 'vm',
  });
};
