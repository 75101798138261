module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseObject, $firebaseArray) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseObject = $firebaseObject;
      this.$firebaseArray = $firebaseArray;
    }

    $get(orgId) {
      return this.$firebaseObject(this.fbutil.ref(`recallPlans/${orgId}`)).$loaded();
    }

    $problemsReport(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/problemsReport`)).$loaded();
    }

    $notifications(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/notifications`)).$loaded();
    }

    $customers(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/customers`)).$loaded();
    }

    $customer(orgId, customerId) {
      return this.$firebaseObject(this.fbutil.ref(`recallPlans/${orgId}/customers/${customerId}`)).$loaded();
    }

    pushCustomer(orgId) {
      return this.fbutil.ref(`recallPlans/${orgId}/customers`).push();
    }

    $problemReports(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/problemReports`)).$loaded();
    }

    $getProblemReport(orgId, reportId) {
      return this.$firebaseObject(this.fbutil.ref(`recallPlans/${orgId}/problemReports/${reportId}`)).$loaded();
    }

    $pushProblemReport(orgId) {
      return this.$firebaseObject(this.fbutil.ref(`recallPlans/${orgId}/problemReports`).push()).$loaded();
    }

    $getLetterTemplate(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/letterTemplate`)).$loaded();
    }

    $getPhoneScript(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/phoneScript`)).$loaded();
    }

    $getCustomerInstructions(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/customerInstructions`)).$loaded();
    }

    $productComplaints(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`recallPlans/${orgId}/productComplaints`)).$loaded();
    }

    $getProductComplaint(orgId, complaintId) {
      return this.$firebaseObject(this.fbutil.ref(`recallPlans/${orgId}/productComplaints/${complaintId}`)).$loaded();
    }

    $pushProductComplaint(orgId) {
      return this.$firebaseObject(this.fbutil.ref(`recallPlans/${orgId}/productComplaints`).push()).$loaded();
    }
  }

  ngModule.service('recallService', Service);
};
