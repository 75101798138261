class Constructor {
  constructor(utils, $q, $scope, moment) {
    'ngInject';

    this.utils = utils;
    this.$q = $q;
    this.$scope = $scope;
    this.moment = moment;
  }

  $onInit() {
    this.header = this.resolve.header;
    this.title = this.resolve.title;
    this.option = this.resolve.option;
    this.endDate = new Date();
    this.endDate.setHours(23, 59, 59, 999);
    this.startDate = this.moment(new Date()).subtract(30, 'days').toDate();
    this.startDate.setHours(0, 0, 0, 0);
    this.checked = this.resolve.checked;
    this.subTitle = this.resolve.subTitle;
    this.noLogOption = this.resolve.noLogOption;
    this.allowSkip = this.resolve.allowSkip || false;
    this.skipButtonClass = this.resolve.skipButtonClass;
    this.skipButtonHtml = this.resolve.skipButtonHtml;
    this.chooseBtnHtml = this.resolve.chooseBtnHtml;
  }
  submit() {
    let returnObj = {
      params: {
        startDate: this.checked ? this.startDate.getTime() : null,
        endDate: this.checked ? this.endDate.getTime() : null,
      },
    };

    this.modalInstance.close(returnObj);
  }
}

module.exports = Constructor;
