// require('textangular/dist/textAngular-sanitize.min');

module.exports = function(parentModuleName) {
  const moduleName = parentModuleName + '.vendor';

  angular.module(moduleName, [
    'ui.router',
    'ngAnimate',
    'ngCookies',
    'ui.bootstrap',
    'firebase',
    'payment',
    'angularMoment',
    'angular-growl',
    'ngFileUpload',
    'ui.sortable',
    'infinite-scroll',
    // 'textAngular',
    'ui.select',
    'ui-select-infinity',
    'cfp.loadingBar',
    'mgo-angular-wizard',
    'angular-storage',
    'luegg.directives',
    'ng-currency',
    'ui.validate',
    'angular-cache',
    'ng.deviceDetector',
    'vcRecaptcha',
    'uiCropper',
    'chart.js',
  ]).run(function($templateCache, uiSelectConfig) {
    'ngInject';

    // The following templates are necessary to make the ui-select component work with bootstrap 4
    $templateCache.put('bootstrap4/choices.tpl.html', require('../vendor/uiSelect_bootstrap4/choices.tpl.html'));
    $templateCache.put('bootstrap4/match.tpl.html', require('../vendor/uiSelect_bootstrap4/match.tpl.html'));
    $templateCache.put('bootstrap4/match-multiple.tpl.html',
      require('../vendor/uiSelect_bootstrap4/match-multiple.tpl.html'));
    $templateCache.put('bootstrap4/no-choice.tpl.html', require('../vendor/uiSelect_bootstrap4/no-choice.tpl.html'));
    $templateCache.put('bootstrap4/select.tpl.html', require('../vendor/uiSelect_bootstrap4/select.tpl.html'));
    $templateCache.put('bootstrap4/select-multiple.tpl.html',
      require('../vendor/uiSelect_bootstrap4/select-multiple.tpl.html'));

    // The following templates are necessary to make angular ui bootstrap components work with bootstrap 4/unify 2
    $templateCache.put('uib/template/tabs/tab.html', require('./ui_bootstrap_custom_templates/tab.tpl.html'));
    $templateCache.put('uib/template/tabs/tabset.html', require('./ui_bootstrap_custom_templates/tabset.tpl.html'));

    uiSelectConfig.dropdownPosition = 'down';
    uiSelectConfig.theme = 'bootstrap4';
  });

  return moduleName;
};
