module.exports = function (ngModule) {
  ngModule.component('frPricingPage', {
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
    template: require('./pricing-page.template.html'),
    controller: require('./pricing-page.controller'),
    controllerAs: 'vm',
  });
};
