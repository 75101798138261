require('./marketplace.orders.scss');

module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceOrders', {
    bindings: {
      user: '<',
      organization: '<'
    },
    template: require('./marketplace.orders.template.html'),
    controller: require('./marketplace.orders.controller.js'),
    controllerAs: 'vm'
  });
};
