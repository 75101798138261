class Controller {
  constructor($state, $uibModal, $log, sopLogsService, utils, $q, products, growl, confirmDeleteModal,
              sopService, batchService, users, confirmModal) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.sopLogsService = sopLogsService;
    this.utils = utils;
    this.$q = $q;
    this.products = products;
    this.growl = growl;
    this.confirmDeleteModal = confirmDeleteModal;
    this.sopService = sopService;
    this.batchService = batchService;
    this.users = users;
    this.confirmModal = confirmModal;

    this.page = 1;
    this.maxReached = false;
    this.searching = false;

    this.PAGE_SIZE = 100;
    this.SPECIAL_FILTERS = {
      BATCH: 'batch',
      SOP: 'sop'
    };
  }

  $onInit() {
    this.noLogs = _.get(this, 'sopLogs.length') === 0;
    this.maxReached = _.get(this, 'sopLogs.length', 0) < this.PAGE_SIZE;
    this.search = _.debounce(this._search, 300);
    this.loadMore = _.debounce(() => this.fetchLogs()
      .then((result) => { this.sopLogs = _.concat(this.sopLogs, result); }), 300);
    this.sopTitles = {};
    this.batchNumbers = {};
    this.userNames = {};

    this.$q.all([
      this.getSopTitles(this.sopLogs),
      this.getUserNames(this.sopLogs),
      this.getBatchNumbers(this.sopLogs)]).then(() => {
        this.searchText = '';
        if (this.$state.params.batch) {
          this.searchText = `${this.SPECIAL_FILTERS.BATCH}: ${this.$state.params.batch}`;
        }
        if (this.$state.params.sop) {
          this.searchText = `${this.SPECIAL_FILTERS.SOP}: ${this.$state.params.sop}`;
        }

        if (this.searchText) {
          this._search();
        }
      });
  }

  getSopTitles(logs) {
    return this.$q.all(_.map(logs, log => {
      if (!log.sopId || this.sopTitles[log.sopId]) { return; }
      this.sopTitles[log.sopId] = '';
      return this.sopService.getTitle(log.sopId).then(title => {
        this.sopTitles[log.sopId] = title;
      });
    }));
  }

  getUserNames(logs) {
    return this.$q.all(_.map(logs, log => {
      if (this.userNames[log.createdBy]) { return; }
      this.userNames[log.createdBy] = '';
      return this.users.getName(log.createdBy).then(name => {
        this.userNames[log.createdBy] = name;
      });
    }));
  }

  getBatchNumbers(logs) {
    return this.$q.all(_.map(logs, log => {
      if (!log.batchId || this.batchNumbers[log.batchId]) { return; }
      this.batchNumbers[log.batchId] = '';
      return this.batchService.getBatchNumber(log.batchId).then(batchNumber => {
        this.batchNumbers[log.batchId] = batchNumber;
      });
    }));
  }

  _search() {
    this.page = 0;
    this.sopLogs = [];
    this.searching = true;
    return this.fetchLogs()
      .then((result) => {
        this.sopLogs = result;
        this.getSopTitles(result);
        this.getBatchNumbers(result);
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch Logs.'))
      .finally(() => { this.searching = false; });
  }

  fetchLogs() {
    this.searching = true;
    let parsedSearch = this.parseSearchBox(this.searchText);

    return this.sopLogsService.query(this.user.orgContext.id, parsedSearch.searchText, parsedSearch.additionalFilters,
      this.PAGE_SIZE * this.page++, this.PAGE_SIZE)
      .then((logs) => {
        this.maxReached = logs.length < this.PAGE_SIZE;

        return logs;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred loading Logs.'))
      .finally(() => this.loading = false);
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  // Pull out additional filters from search box (e.g. "batch: <batchNumber>")
  parseSearchBox(originalSearchText) {
    let searchText = originalSearchText;
    let additionalFilters = {};

    if (_.isEmpty(originalSearchText)) {
      return {searchText, additionalFilters};
    }
    let parts = searchText.split(':');
    let prop;

    if (parts.length) {
      searchText = '';
      for (var i = 0; i < parts.length; i++) {
        var words = _.compact(parts[i].split(' '));

        if (i % 2 === 0) {
          let last = _.last(words);

          if (_.find(this.SPECIAL_FILTERS, filter => filter === last)) {
            prop = last;
            words.pop();
          }
        } else if (prop) {
          additionalFilters[prop] = _.first(words);
          words.shift();
          prop = null;
        }
        searchText += words.join(' ');
      }
    }
    if (additionalFilters.batch) {
      additionalFilters.batchId = _.findKey(this.batchNumbers, b => b === additionalFilters.batch);
      delete additionalFilters.batch;
    }
    return {searchText, additionalFilters};
  }

  editLog(log) {
    if (!log) {
      return this.sopService.chooseSop(this.user.orgContext.id, false, null, null, true).then(sop => {
        if (!sop) {
          return this.confirmModal({
            title: 'No SOPs Found',
            body: 'Please <a href="" ng-click="vm.go(\'operations.sops.list\')">' +
              'create a standard operating procedure</a> before entering logs. ',
            okText: 'Ok',
            hideCancelButton: true
          });
        }
        return this.sopLogsService.$push()
          .then($log => this.$state.go('operations.sopLogs.sopLog.edit', {sopLogId: $log.$id, sopId: sop.$id}))
          .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to add new log.'));
      });
    }

    this.$q.when(log || this.sopLogsService.$push())
      .then($log => this.$state.go('operations.sopLogs.sopLog.edit', {sopLogId: $log.$id}));
  }

  deleteLog(log) {
    this.confirmDeleteModal('Log', {
      body: `Are you sure you want to delete <strong>${this.sopTitles[log.sopId]}</strong> log ?`
    }).then(() => {
      this.sopLogsService.delete(log.$id).then(() => {
        _.remove(this.sopLogs, {$id: log.$id});
        this.growl.success('Log deleted');
      }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete log.'));
    });
  }

  filterByBatch(batchId) {
    this.searchText = `${this.SPECIAL_FILTERS.BATCH}: ${this.batchNumbers[batchId]}`;
    this._search();
  }

  filterBySop(sopId) {
    this.searchText = `${this.SPECIAL_FILTERS.SOP}: ${sopId}`;
    this._search();
  }
}

module.exports = Controller;
