module.exports = function(ngModule) {
  ngModule.component('cfMediaList', {
    bindings: {
      remoteDirectory: '@',
      media: '=',
      organization: '<',
      user: '<',
      tip: '@?',
      tipId: '@?',
      noFilesText: '@?',
      closeText: '@?',
      warning: '@?',
      readOnly: '=?',
      categories: '<?',
      onNewFile: '&?',
      beforeNewFile: '&?',
      onRemoveFile: '&?',
      onClose: '&?',
      tableEmptyState: '<?',  // Show the table instead of the special empty state panel when empty.
      loading: '<?',
      missingFiles: '<?',  // Note: caller is responsible for keeping this current with event handlers
                          // (onRemoveFile, onNewFile)
      isFileBucket: '<?',
      projectId: '@?',
      searchInput: '<',
      expiryDate: '<'
    },
    template: require('./media-list.template.html'),
    controller: require('./media-list.controller.js'),
    controllerAs: 'vm'
  });
};
