'use strict';

module.exports = function(ngModule) {
  ngModule.constant('countries', function() {
    return [
      {name: 'Andorra', abbreviation: 'AD'},
      {name: 'United Arab Emirates', abbreviation: 'AE'},
      {name: 'Afghanistan', abbreviation: 'AF'},
      {name: 'Antigua and Barbuda', abbreviation: 'AG'},
      {name: 'Anguilla', abbreviation: 'AI'},
      {name: 'Albania', abbreviation: 'AL'},
      {name: 'Armenia', abbreviation: 'AM'},
      {name: 'Angola', abbreviation: 'AO'},
      {name: 'Antarctica', abbreviation: 'AQ'},
      {name: 'Argentina', abbreviation: 'AR'},
      {name: 'American Samoa', abbreviation: 'AS'},
      {name: 'Austria', abbreviation: 'AT'},
      {name: 'Australia', abbreviation: 'AU'},
      {name: 'Aruba', abbreviation: 'AW'},
      {name: 'Azerbaijan', abbreviation: 'AZ'},
      {name: 'Bosnia and Herzegovina', abbreviation: 'BA'},
      {name: 'Barbados', abbreviation: 'BB'},
      {name: 'Bangladesh', abbreviation: 'BD'},
      {name: 'Belgium', abbreviation: 'BE'},
      {name: 'Burkina Faso', abbreviation: 'BF'},
      {name: 'Bulgaria', abbreviation: 'BG'},
      {name: 'Bahrain', abbreviation: 'BH'},
      {name: 'Burundi', abbreviation: 'BI'},
      {name: 'Benin', abbreviation: 'BJ'},
      {name: 'Saint Barthelemy', abbreviation: 'BL'},
      {name: 'Bermuda', abbreviation: 'BM'},
      {name: 'Brunei', abbreviation: 'BN'},
      {name: 'Bolivia', abbreviation: 'BO'},
      {name: 'Brazil', abbreviation: 'BR'},
      {name: 'Bahamas, The', abbreviation: 'BS'},
      {name: 'Bhutan', abbreviation: 'BT'},
      {name: 'Bouvet Island', abbreviation: 'BV'},
      {name: 'Botswana', abbreviation: 'BW'},
      {name: 'Belarus', abbreviation: 'BY'},
      {name: 'Belize', abbreviation: 'BZ'},
      {name: 'Canada', abbreviation: 'CA'},
      {name: 'Cocos (Keeling) Islands', abbreviation: 'CC'},
      {name: 'Congo, Democratic Republic of the', abbreviation: 'CD'},
      {name: 'Central African Republic', abbreviation: 'CF'},
      {name: 'Congo, Republic of the', abbreviation: 'CG'},
      {name: 'Switzerland', abbreviation: 'CH'},
      {name: 'Cote d\'Ivoire', abbreviation: 'CI'},
      {name: 'Cook Islands', abbreviation: 'CK'},
      {name: 'Chile', abbreviation: 'CL'},
      {name: 'Cameroon', abbreviation: 'CM'},
      {name: 'China', abbreviation: 'CN'},
      {name: 'Colombia', abbreviation: 'CO'},
      {name: 'Costa Rica', abbreviation: 'CR'},
      {name: 'Cuba', abbreviation: 'CU'},
      {name: 'Cape Verde', abbreviation: 'CV'},
      {name: 'Curacao', abbreviation: 'CW'},
      {name: 'Christmas Island', abbreviation: 'CX'},
      {name: 'Cyprus', abbreviation: 'CY'},
      {name: 'Czech Republic', abbreviation: 'CZ'},
      {name: 'Germany', abbreviation: 'DE'},
      {name: 'Djibouti', abbreviation: 'DJ'},
      {name: 'Denmark', abbreviation: 'DK'},
      {name: 'Dominica', abbreviation: 'DM'},
      {name: 'Dominican Republic', abbreviation: 'DO'},
      {name: 'Algeria', abbreviation: 'DZ'},
      {name: 'Ecuador', abbreviation: 'EC'},
      {name: 'Estonia', abbreviation: 'EE'},
      {name: 'Egypt', abbreviation: 'EG'},
      {name: 'Western Sahara', abbreviation: 'EH'},
      {name: 'Eritrea', abbreviation: 'ER'},
      {name: 'Spain', abbreviation: 'ES'},
      {name: 'Ethiopia', abbreviation: 'ET'},
      {name: 'Finland', abbreviation: 'FI'},
      {name: 'Fiji', abbreviation: 'FJ'},
      {name: 'Falkland Islands (Islas Malvinas)', abbreviation: 'FK'},
      {name: 'Micronesia, Federated States of', abbreviation: 'FM'},
      {name: 'Faroe Islands', abbreviation: 'FO'},
      {name: 'France', abbreviation: 'FR'},
      {name: 'France, Metropolitan', abbreviation: 'FX'},
      {name: 'Gabon', abbreviation: 'GA'},
      {name: 'United Kingdom', abbreviation: 'GB'},
      {name: 'Grenada', abbreviation: 'GD'},
      {name: 'Georgia', abbreviation: 'GE'},
      {name: 'French Guiana', abbreviation: 'GF'},
      {name: 'Guernsey', abbreviation: 'GG'},
      {name: 'Ghana', abbreviation: 'GH'},
      {name: 'Gibraltar', abbreviation: 'GI'},
      {name: 'Greenland', abbreviation: 'GL'},
      {name: 'Gambia, The', abbreviation: 'GM'},
      {name: 'Guinea', abbreviation: 'GN'},
      {name: 'Guadeloupe', abbreviation: 'GP'},
      {name: 'Equatorial Guinea', abbreviation: 'GQ'},
      {name: 'Greece', abbreviation: 'GR'},
      {name: 'South Georgia and the Islands', abbreviation: 'GS'},
      {name: 'Guatemala', abbreviation: 'GT'},
      {name: 'Guam', abbreviation: 'GU'},
      {name: 'Guinea-Bissau', abbreviation: 'GW'},
      {name: 'Guyana', abbreviation: 'GY'},
      {name: 'Hong Kong', abbreviation: 'HK'},
      {name: 'Heard Island and McDonald Islands', abbreviation: 'HM'},
      {name: 'Honduras', abbreviation: 'HN'},
      {name: 'Croatia', abbreviation: 'HR'},
      {name: 'Haiti', abbreviation: 'HT'},
      {name: 'Hungary', abbreviation: 'HU'},
      {name: 'Indonesia', abbreviation: 'ID'},
      {name: 'Ireland', abbreviation: 'IE'},
      {name: 'Israel', abbreviation: 'IL'},
      {name: 'Isle of Man', abbreviation: 'IM'},
      {name: 'India', abbreviation: 'IN'},
      {name: 'British Indian Ocean Territory', abbreviation: 'IO'},
      {name: 'Iraq', abbreviation: 'IQ'},
      {name: 'Iran', abbreviation: 'IR'},
      {name: 'Iceland', abbreviation: 'IS'},
      {name: 'Italy', abbreviation: 'IT'},
      {name: 'Jersey', abbreviation: 'JE'},
      {name: 'Jamaica', abbreviation: 'JM'},
      {name: 'Jordan', abbreviation: 'JO'},
      {name: 'Japan', abbreviation: 'JP'},
      {name: 'Kenya', abbreviation: 'KE'},
      {name: 'Kyrgyzstan', abbreviation: 'KG'},
      {name: 'Cambodia', abbreviation: 'KH'},
      {name: 'Kiribati', abbreviation: 'KI'},
      {name: 'Comoros', abbreviation: 'KM'},
      {name: 'Saint Kitts and Nevis', abbreviation: 'KN'},
      {name: 'Korea, North', abbreviation: 'KP'},
      {name: 'Korea, South', abbreviation: 'KR'},
      {name: 'Kuwait', abbreviation: 'KW'},
      {name: 'Cayman Islands', abbreviation: 'KY'},
      {name: 'Kazakhstan', abbreviation: 'KZ'},
      {name: 'Laos', abbreviation: 'LA'},
      {name: 'Lebanon', abbreviation: 'LB'},
      {name: 'Saint Lucia', abbreviation: 'LC'},
      {name: 'Liechtenstein', abbreviation: 'LI'},
      {name: 'Sri Lanka', abbreviation: 'LK'},
      {name: 'Liberia', abbreviation: 'LR'},
      {name: 'Lesotho', abbreviation: 'LS'},
      {name: 'Lithuania', abbreviation: 'LT'},
      {name: 'Luxembourg', abbreviation: 'LU'},
      {name: 'Latvia', abbreviation: 'LV'},
      {name: 'Libya', abbreviation: 'LY'},
      {name: 'Morocco', abbreviation: 'MA'},
      {name: 'Monaco', abbreviation: 'MC'},
      {name: 'Moldova', abbreviation: 'MD'},
      {name: 'Montenegro', abbreviation: 'ME'},
      {name: 'Saint Martin', abbreviation: 'MF'},
      {name: 'Madagascar', abbreviation: 'MG'},
      {name: 'Marshall Islands', abbreviation: 'MH'},
      {name: 'Macedonia', abbreviation: 'MK'},
      {name: 'Mali', abbreviation: 'ML'},
      {name: 'Burma', abbreviation: 'MM'},
      {name: 'Mongolia', abbreviation: 'MN'},
      {name: 'Macau', abbreviation: 'MO'},
      {name: 'Northern Mariana Islands', abbreviation: 'MP'},
      {name: 'Martinique', abbreviation: 'MQ'},
      {name: 'Mauritania', abbreviation: 'MR'},
      {name: 'Montserrat', abbreviation: 'MS'},
      {name: 'Malta', abbreviation: 'MT'},
      {name: 'Mauritius', abbreviation: 'MU'},
      {name: 'Maldives', abbreviation: 'MV'},
      {name: 'Malawi', abbreviation: 'MW'},
      {name: 'Mexico', abbreviation: 'MX'},
      {name: 'Malaysia', abbreviation: 'MY'},
      {name: 'Mozambique', abbreviation: 'MZ'},
      {name: 'Namibia', abbreviation: 'NA'},
      {name: 'New Caledonia', abbreviation: 'NC'},
      {name: 'Niger', abbreviation: 'NE'},
      {name: 'Norfolk Island', abbreviation: 'NF'},
      {name: 'Nigeria', abbreviation: 'NG'},
      {name: 'Nicaragua', abbreviation: 'NI'},
      {name: 'Netherlands', abbreviation: 'NL'},
      {name: 'Norway', abbreviation: 'NO'},
      {name: 'Nepal', abbreviation: 'NP'},
      {name: 'Nauru', abbreviation: 'NR'},
      {name: 'Niue', abbreviation: 'NU'},
      {name: 'New Zealand', abbreviation: 'NZ'},
      {name: 'Oman', abbreviation: 'OM'},
      {name: 'Panama', abbreviation: 'PA'},
      {name: 'Peru', abbreviation: 'PE'},
      {name: 'French Polynesia', abbreviation: 'PF'},
      {name: 'Papua New Guinea', abbreviation: 'PG'},
      {name: 'Philippines', abbreviation: 'PH'},
      {name: 'Pakistan', abbreviation: 'PK'},
      {name: 'Poland', abbreviation: 'PL'},
      {name: 'Saint Pierre and Miquelon', abbreviation: 'PM'},
      {name: 'Pitcairn Islands', abbreviation: 'PN'},
      {name: 'Puerto Rico', abbreviation: 'PR'},
      {name: 'Gaza Strip', abbreviation: 'PS'},
      {name: 'West Bank', abbreviation: 'P2'},
      {name: 'Portugal', abbreviation: 'PT'},
      {name: 'Palau', abbreviation: 'PW'},
      {name: 'Paraguay', abbreviation: 'PY'},
      {name: 'Qatar', abbreviation: 'QA'},
      {name: 'Reunion', abbreviation: 'RE'},
      {name: 'Romania', abbreviation: 'RO'},
      {name: 'Serbia', abbreviation: 'RS'},
      {name: 'Russia', abbreviation: 'RU'},
      {name: 'Rwanda', abbreviation: 'RW'},
      {name: 'Saudi Arabia', abbreviation: 'SA'},
      {name: 'Solomon Islands', abbreviation: 'SB'},
      {name: 'Seychelles', abbreviation: 'SC'},
      {name: 'Sudan', abbreviation: 'SD'},
      {name: 'Sweden', abbreviation: 'SE'},
      {name: 'Singapore', abbreviation: 'SG'},
      {name: 'Saint Helena, Ascension, and Tristan da Cunha', abbreviation: 'SH'},
      {name: 'Slovenia', abbreviation: 'SI'},
      {name: 'Svalbard', abbreviation: 'SJ'},
      {name: 'Slovakia', abbreviation: 'SK'},
      {name: 'Sierra Leone', abbreviation: 'SL'},
      {name: 'San Marino', abbreviation: 'SM'},
      {name: 'Senegal', abbreviation: 'SN'},
      {name: 'Somalia', abbreviation: 'SO'},
      {name: 'Suriname', abbreviation: 'SR'},
      {name: 'South Sudan', abbreviation: 'SS'},
      {name: 'Sao Tome and Principe', abbreviation: 'ST'},
      {name: 'El Salvador', abbreviation: 'SV'},
      {name: 'Sint Maarten', abbreviation: 'SX'},
      {name: 'Syria', abbreviation: 'SY'},
      {name: 'Swaziland', abbreviation: 'SZ'},
      {name: 'Turks and Caicos Islands', abbreviation: 'TC'},
      {name: 'Chad', abbreviation: 'TD'},
      {name: 'French Southern and Antarctic Lands', abbreviation: 'TF'},
      {name: 'Togo', abbreviation: 'TG'},
      {name: 'Thailand', abbreviation: 'TH'},
      {name: 'Tajikistan', abbreviation: 'TJ'},
      {name: 'Tokelau', abbreviation: 'TK'},
      {name: 'Timor-Leste', abbreviation: 'TL'},
      {name: 'Turkmenistan', abbreviation: 'TM'},
      {name: 'Tunisia', abbreviation: 'TN'},
      {name: 'Tonga', abbreviation: 'TO'},
      {name: 'Turkey', abbreviation: 'TR'},
      {name: 'Trinidad and Tobago', abbreviation: 'TT'},
      {name: 'Tuvalu', abbreviation: 'TV'},
      {name: 'Taiwan', abbreviation: 'TW'},
      {name: 'Tanzania', abbreviation: 'TZ'},
      {name: 'Ukraine', abbreviation: 'UA'},
      {name: 'Uganda', abbreviation: 'UG'},
      {name: 'United States Minor Outlying Islands', abbreviation: 'UM'},
      {name: 'United States', abbreviation: 'US'},
      {name: 'Uruguay', abbreviation: 'UY'},
      {name: 'Uzbekistan', abbreviation: 'UZ'},
      {name: 'Holy See (Vatican City)', abbreviation: 'VA'},
      {name: 'Saint Vincent and the Grenadines', abbreviation: 'VC'},
      {name: 'Venezuela', abbreviation: 'VE'},
      {name: 'British Virgin Islands', abbreviation: 'VG'},
      {name: 'Virgin Islands', abbreviation: 'VI'},
      {name: 'Vietnam', abbreviation: 'VN'},
      {name: 'Vanuatu', abbreviation: 'VU'},
      {name: 'Wallis and Futuna', abbreviation: 'WF'},
      {name: 'Samoa', abbreviation: 'WS'},
      {name: 'Kosovo', abbreviation: 'XK'},
      {name: 'Yemen', abbreviation: 'YE'},
      {name: 'Mayotte', abbreviation: 'YT'},
      {name: 'South Africa', abbreviation: 'ZA'},
      {name: 'Zambia', abbreviation: 'ZM'},
      {name: 'Zimbabwe', abbreviation: 'ZW'}
    ];
  });
};