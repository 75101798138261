module.exports = function(ngModule) {
  /**
   * This is a wrapper class for https://github.com/sandywalker/webui-popover.
   */
  class MiniTourService {
    constructor($q, $timeout, $uibModal, deviceDetector, $window) {
      'ngInject';

      this.$q = $q;
      this.$timeout = $timeout;
      this.$uibModal = $uibModal;
      this.deviceDetector = deviceDetector;
      this.$window = $window;
      this.coreTourDef = {
        backdrop: true,
        dismissible: true,
        animation: 'fade',
        width: 400,
        trigger: 'manual'
      };

      this.type = {
        CENTERED: 'centered',
        POPOVER: 'popover' // Default
      };

      this.lastTourPromise = null;
      this.planTipIds = ['uploadFilesTour','addEquipmentTour','addPropertiesTour',
        'addFoodProductTour','addNewProcessStepTour'];
    }

    /**
     * Add a tour to the tour queue and kick off.
     * @param {object} [user] The logged in user. If there is no user, try to use localStorage.
     * @param {object} tour The tour to kick off
     * @param {string} tour.id A unique identifier used to hide tours already seen by a user.
     * @param {string} tour.selector The element selector to assign the tour to.
     * @param {string} tour.title The tour title.
     * @param {boolean=} tour.arrow Show the arrow?
     * @param {string} tour.type Is the tour 'centered' or a 'popover'?
     * @param {function=} tour.beforeOpen Function to run before opening the tour.
     * @param {string} tour.contentHtml The tour content (HTML).
     * @param {number=} [tour.width] The tour width (default set above).
     * @param {string} [tour.placement] The tour placement (default to auto).
     * @param {string=} [tour.useLocalStorage] Use localStorage to persist state vs. FB.
     * @return {Promise} A promise that's resolved when the tour closes.
     */
    enqueueTour(user, tour) {
      if (tour.useLocalStorage && !this.$window.localStorage) { return; }
      if (!tour.id) { throw new Error('Invalid mini-tour call. No id.'); }
      if (tour.useLocalStorage ? this.wasShownLocalStorage(tour.id) : this.wasShown(user, tour.id)) {
        return this.$q.resolve(true);
      }
      let elem, tourDeferred, standardModal = false;

      if (tour.type === this.type.CENTERED || this.deviceDetector.isMobile()) {
        standardModal = true;
      } else {
        elem = $(tour.selector);
        tourDeferred = this.$q.defer();

        tour.contentHtml = this.addCloseButton(tour.contentHtml);
        let fullTourDef = _.assign({
          title: tour.title,
          placement: tour.placement,
          style: 'mini-tour',
          content: tour.contentHtml,
          onShow: ($element) => this.onShow($element, user, elem, tourDeferred)
        }, this.coreTourDef);

        if (tour.width) {
          fullTourDef.width = tour.width;
        }
        elem.webuiPopover(fullTourDef);
      }

      this.lastTourPromise = this.$q.when(this.lastTourPromise || true).then(() => {
        if (_.isFunction(tour.beforeOpen)) {
          tour.beforeOpen();
        }
        return this.$timeout(() => {
          if (standardModal) {
            this.setShown(user, tour.id);
            let modalInstance = this.showStandardModal(tour);

            if (tour.bindings) {
              modalInstance.rendered.then(() => this.bindCustomHandlers($('.modal-open'), tour.bindings));
            }
            return modalInstance.result;
          }

          if(!this.wasShown(user, tour.id)) {
            elem.webuiPopover('show');
            // if (tour.useLocalStorage) {
            //   this.setShownLocalStorage(tour.id);
            // } else {
            this.setShown(user, tour.id);
            // }
          }
          return tourDeferred.promise;
        }, 100).then(() => {
          if (_.isFunction(tour.afterClose)) {
            tour.afterClose();
          }
        });
      });
      return this.lastTourPromise;
    }

    showStandardModal(tour) {
      return this.$uibModal.open({
        windowClass: 'webui-popover-mini-tour',
        backdropClass: 'mini-tour-backdrop',
        component: 'cfMiniTourModal',
        resolve: {
          tour: () => tour
        }
      });
    }

    /**
     * Mark a mini-tour as shown so it doesn't show up.
     * @param {object} user The logged in user.
     * @param {string} tourId The tour ID.
     * @return {void}
     */
    setShown(user, tourId) {
      user.setTipHidden(this.getTipId(tourId));
    }

    /**
     * Was the mini-tour already shown?
     * @param {object} user The logged in user.
     * @param {string} tourId The tour ID.
     * @return {boolean} True if the tour was already shown. False if not.
     */
    wasShown(user, tourId) {
      return user.isTipHidden(this.getTipId(tourId));
    }

    /**
     * Mark a mini-tour as shown so it doesn't show up (using local storage).
     * @param {string} tourId The tour ID.
     * @return {void}
     */
    setShownLocalStorage(tourId) {
      this.$window.localStorage.setItem(this.getTipId(tourId), true);
    }

    /**
     * Was the mini-tour already shown (using local storage)?
     * @param {string} tourId The tour ID.
     * @return {boolean} True if the tour was already shown. False if not.
     */
    wasShownLocalStorage(tourId) {
      return this.$window.localStorage.getItem(this.getTipId(tourId)) === 'true' &&
      this.$window.localStorage.getItem('showAllTips') !== 'true';
    }

    getTipId(tourId) {
      return 'minitour_' + tourId;
    }

    addCloseButton(tourHtml) {
      return `<div class="w-100">
                <div class="modal-body">${tourHtml}</div>
                <div class="modal-footer justify-content-center">
<!--                  <button class="btn btn-md u-btn-primary previous-btn">Previous</button>-->
                  <button class="btn btn-md btn-secondary skip-btn">Skip Tour</button>
                  <button class="btn btn-md btn-primary next-btn">Next</button>
                </div>
              </div>`;
    }

    bindCustomHandlers($element, handlerObjs) {
      _.each(handlerObjs, handlerObj => {
        $element.find(handlerObj.selector).on(handlerObj.eventType || 'click', (event) => {
          event.preventDefault();
          handlerObj.handler();
        });
      });
    }

    onShow($element, user, tourElem, tourDeferred) {
      $element.find('.next-btn').on('click', () => {
        tourElem.webuiPopover('destroy');
        tourDeferred.resolve();
      });
      $element.find('.skip-btn').on('click', () => {
        tourElem.webuiPopover('destroy');
        tourDeferred.resolve();
        user.setMultipleTipHidden(this.planTipIds);
        this.lastTourPromise = null;
      });
      $('.webui-popover-backdrop').on('click', () => {
        tourElem.webuiPopover('destroy');
        tourDeferred.resolve();
      });
      this.scrollTo();
    }

    scrollTo() {
      $('html, body').animate({
        scrollTop: $('.webui-popover').offset().top - 200
      }, 1000);
    }

    unsetPlanTipHidden(user) {
      return user.unsetMultipleTipHidden(this.planTipIds);
    }
  }

  ngModule.service('miniTourService', MiniTourService);
};
