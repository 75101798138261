const _ = require('lodash');

module.exports = function(ngModule) {
  ngModule.factory('profitwell', function($window, users, PROFITWELL_PUBLIC_KEY) {
    return {
      userLoggedIn: (authUser) => {
        if(PROFITWELL_PUBLIC_KEY) {
          users.getProfile(authUser.uid).then(user => {
            if (!_.get(user, 'email')) { return; }
            $window.profitwell('start',{'user_email': user.email});
          });
        }
      }
    };
  });
};
