'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsLetter', {
    bindings: {
      $letter: '=',
      recall: '<',
      user: '<'
    },
    template: require('./recall.events.letter.template.html'),
    controller: require('./recall.events.letter.controller'),
    controllerAs: 'vm'
  });
};
