module.exports = function(ngModule) {
  ngModule.component('cfBilling', {
    bindings: {
      user: '<',
      $company: '<'
    },
    template: require('./users.settings.billing.template.html'),
    controller: require('./users.settings.billing.controller'),
    controllerAs: 'vm'
  });
};
