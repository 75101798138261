class Controller {
  constructor(billing, growl, $log) {
    'ngInject';

    this.billing = billing;
    this.growl = growl;
    this.$log = $log;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.source = this.resolve.source;
  }

  verifyAch() {
    let verifyArray = [this.amount1, this.amount2];

    this.verifying = true;
    this.billing.verifyAch(this.user.currentOrgContext().id, this.user.customerId, this.source.id, verifyArray)
      .then(() => {
        this.modalInstance.close();
      })
      .catch(err => {
        this.verifying = false;
        this.$log.error('Unable to verify account ', err);
        if (err.status === 400) {
          this.growl.error('The amounts provided do not match the amounts that were sent to the bank account.');
        } else {
          this.growl.error('Unable to verify account. Please try again or call customer support.');
        }
      });
  }
}

module.exports = Controller;
