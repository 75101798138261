'use strict';

module.exports = function(ngModule) {
  ngModule.factory('VerificationSearch', (Search, authorization) => {
    'ngInject';

    const VerificationSearch = function(user, size) {
      let searchParams = {
        uri: 'search/verification',
        type: 'verification',
        max: size || 300,
        body: {}
      };

      this.queries = [];
      this.sorts = [];
      this.user = user;
      this.search = new Search(user, searchParams, 'verifications');
    };

    function buildSearchQuery() {
      let query = {},
        queries = _.clone(this.queries),
        sorts = _.clone(this.sorts);

      if (queries.length === 1) {
        query.query = queries[0];
      } else if (queries.length > 1) {
        query.query = {
          bool: {
            must: queries
          }
        };
      }

      query.sort = sorts;

      this.search.setSearchQuery(query);
    }

    VerificationSearch.prototype.executeSearch = function() {
      if (!this.user.hasPermission(authorization.claims.CF_ADMIN)) {
        if (this.organizationId) {
          this.search.options.context = {organizationId: this.organizationId};
        } else {
          let allMyOrgs = _.keys(this.user.organizations());

          if (!this.restrictToProduct) {
            this.queries.push({
              terms: {organizationId: allMyOrgs}
            });
          }
          this.search.options.context = {organizationId: allMyOrgs};
        }
      }

      buildSearchQuery.call(this);

      return this.search.search();
    };

    VerificationSearch.prototype.clear = function() {
      this.queries = [];
      this.sorts = [];
    };

    VerificationSearch.prototype.propertiesRecs = function(product) {
      this.filterByProductId(product.$id);

      this.queries.push({term: {target: 'properties'}});

      return this;
    };

    VerificationSearch.prototype.labTestRequests = function(labId) {
      this.queries.push({
        term: {bidsRequested: true}
      });
      this.queries.push({
        bool: {
          should: [
            {term: {laboratory: labId}},
            {bool: {'must_not': {exists: {field: 'laboratory'}}}}
          ]
        }
      });
      this.queries.push({
        bool: {
          'must_not': {
            nested: {
              path: 'rejects',
              query: {
                term: {'rejects.id': labId}
              }
            }
          }
        }
      });

      return this;
    };

    VerificationSearch.prototype.filterByProductName = function(productSearchText) {
      if (productSearchText) {
        this.queries.push({
          'match_phrase_prefix': {
            productName: {
              query: productSearchText.toLowerCase(),
              slop: 3,            // Allow terms to appear out of order by this many positions
              'max_expansions': 20  // To improve performance, limit results of the final search term to this many
            }
          }
        });
      }

      return this;
    };

    VerificationSearch.prototype.filterByProductId = function(product) {
      this.restrictToProduct = true;
      this.organizationId = product.organizationId;
      this.queries.push({
        term: {productId: product.$id}
      });

      return this;
    };

    VerificationSearch.prototype.sortByDateAndTestStatus = function() {
      this.sorts.push({'bidRequestDate': {order: 'desc'}});

      return this;
    };

    return VerificationSearch;
  });
};
