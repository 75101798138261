'use strict';

module.exports = function(users, $firebaseArray, fbutil, notifications, moment, supplier,
                          user, $uibModalInstance) {
  'ngInject';

  var vm = this, now = moment(new Date());

  vm.supplier = supplier;
  vm.comments = $firebaseArray(fbutil.ref('suppliers', vm.supplier.$id, 'comments'));

  vm.addComment = function() {
    vm.comments.$add({
      userId: user.uid,
      content: vm.comment,
      timestamp: new Date()
    }).then(function() {
      notifications.postToOrg({
        from: vm.user.uid,
        to: vm.supplier.$id,
        message: vm.comment
      });
    });
    vm.comment = '';
  };

  vm.getUserProfile = function(userId) {
    let profile = {};

    users.getPublicProfile(userId).then(function(result) {
      _.assign(profile, result);
    });
    return profile;
  };
  vm.humanizeAge = function(submittedOn) {
    return moment.duration(moment(submittedOn).diff(now)).humanize(true);
  };

  vm.ok = function() {
    $uibModalInstance.close();
  };
};


