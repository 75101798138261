'use strict';

module.exports = function(ngModule) {
  ngModule.factory('fbutil', function($q, $firebaseObject, $firebaseArray, fbGenerateKey, ENV) {
    'ngInject';

    var rootRef = firebase.database().ref();
    var firebaseAuth = firebase.auth();

    var ExtendedFirebaseObject = $firebaseObject.$extend({
      softDelete: function() {
        var newRef = rootRef.child('/deleted/' + this.refUri());

        return this.moveTo(newRef);
      },
      moveTo: function(newRef) {
        var self = this;

        return this.$ref().once('value').then(function(snap) {
          return newRef.set(snap.val()).then(function() {
            return self.$ref().remove();
          });
        });
      },
      refUri: function() {
        var refParts = this.$ref().toString().split('/');

        refParts.splice(0, 3);
        return refParts.join('/');
      }
    });

    var ExtendedFirebaseArray = $firebaseArray.$extend({
      $upsert: function(record) {
        return record.$id ? this.$save(record) : this.$add(record);
      }
    });

    function pathRef(args) {
      return _.map(args, arg => {
        if (_.isArray(arg)) {
          return pathRef(arg);
        }

        if (!_.isString(arg)) {
          if (ENV === 'development') {
            // Console output stack is wrong.
            console.trace('%cActual stack for bad firebaseRef:', 'color: red');
          }
          throw new Error('Argument to firebaseRef is not a string: ' + arg + '. Args: ' + angular.toJson(args));
        }

        return arg;
      }).join('/');
    }

    function firebaseRef() {
      var args = Array.prototype.slice.call(arguments);

      return args.length ? rootRef.child(pathRef(args)) : rootRef;
    }

    var utils = {
      // convert a node or Firebase style callback to a future
      handler: function(fn, context) {
        return utils.defer(function(def) {
          fn.call(context, function(err, result) {
            if (err !== null) {
              def.reject(err);
            } else {
              def.resolve(result);
            }
          });
        });
      },

      // abstract the process of creating a future/promise
      defer: function(fn, context) {
        var def = $q.defer();

        fn.call(context, def);
        return def.promise;
      },

      moveRec: function(oldPath, newPath) {
        var oldRef = this.ref(oldPath),
          newRef = this.ref(newPath);

        return oldRef.once('value').then(function(snap) {
          var val = snap.val();

          val.originalCreatedOn = val.createdOn;
          val.createdOn = firebase.database.ServerValue.TIMESTAMP;
          return newRef.push(val).then(function() {
            oldRef.remove();
          });
        });
      },

      generateArrayKey: fbGenerateKey,

      $firebaseObjectExt: function(ref) {
        return new ExtendedFirebaseObject(ref);
      },

      $firebaseArrayExt: function(ref) {
        return new ExtendedFirebaseArray(ref);
      },

      ref: firebaseRef,

      auth: firebaseAuth,

      getValueOrDefault: function(snap, addKey) {
        const val = snap && snap.exists() ? snap.val() : null;

        return addKey ? _.assign(val, {$id: snap.key}) : val;
      }
    };

    return utils;
  });
};