module.exports = function(ngModule) {
  ngModule.factory('batchRunsService', function($firebaseObject, fbutil) {
    'ngInject';

    return {
      get: (batchRunId) => {
        return fbutil.ref('batchRuns', batchRunId).once('value')
          .then(snap => snap.exists() ? _.assign(snap.val(), {$id: snap.key}) : null);
      },

      $get: function(batchRunId) {
        return $firebaseObject(fbutil.ref('batchRuns', batchRunId)).$loaded();
      }
    };
  });
};
