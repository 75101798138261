module.exports = function(ngModule) {
  ngModule.factory('suppliers', function($firebaseObject, fbutil, $firebaseArray) {
    'ngInject';

    return {
      $get: function(supplierId) {
        return $firebaseObject(fbutil.ref('suppliers', supplierId)).$loaded();
      },

      getName: function(supplierId) {
        return fbutil.ref('suppliers', supplierId, 'name').once('value').then(fbutil.getValueOrDefault);
      },

      $files: function(supplierId) {
        return $firebaseArray(fbutil.ref('suppliers', supplierId, 'files')).$loaded();
      }
    };
  });
};
