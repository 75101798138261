const fileCategories = require('../../config/constants/fileCategories');

module.exports = function(ngModule) {

  ngModule
    .config(function($stateProvider, growlProvider, STRIPE_PUBLIC_KEY, $qProvider, uibDropdownConfig) {
      'ngInject';

      growlProvider.globalPosition('top-right');
      growlProvider.globalTimeToLive({success: 5000, error: 5000, warning: 5000, info: 5000});
      growlProvider.globalDisableCountDown(true);

      Stripe.setPublishableKey(STRIPE_PUBLIC_KEY);

      // This is a temporary setting to hide the 'promise reject not caught' warning that now occurs in angular 1.6.
      // Eventually we should go around and find those instances and fix them.
      $qProvider.errorOnUnhandledRejections(false);

      uibDropdownConfig.openClass = 'show';
    })

    .controller('AppController', require('./core.controller'))

    .constant('CF_CONSTANTS', require('../../config/constants/miscConstants'))
    .constant('CF_CLAIMS', require('../../config/constants/claims'))
    .constant('CF_ROLES', require('../../config/constants/roles'))
    .constant('CF_ORDER_ITEM_STATUS', require('../../config/constants/order-item-status'));
};
