'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfIngredientSupIngLots', {
    bindings: {
      supplierIngredient: '=',
      lots: '=',
      transactions: '=',
      organization: '<',
      user: '<',
      readOnly: '<',
      ingredientLogs: '<'
    },
    template: require('./ingredients.supplier-ingredient.lots.template.html'),
    controller: require('./ingredients.supplier-ingredient.lots.controller.js'),
    controllerAs: 'vm'
  });
};
