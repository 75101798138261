const requireAuth = require('../../_services/firebase-resolve.service').requireAuth;

module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider.state('ingredients', {
      abstract: true,
      url: '/ingredients',
      template: '<div ui-view></div>',
      resolve: {
        currentAuth: requireAuth,
        user: (currentAuth, authentication) => authentication.user.$loaded(),
        company: (user, fbutil, $firebaseObject) =>
          $firebaseObject(fbutil.ref('organizations', user.currentOrgContext().id)).$loaded()
      },
      data: {menuId: 'dashboard'}
    }).state('ingredients.customers', {
      abstract: true,
      url: '/customers',
      template: '<div ui-view></div>'
    }).state('ingredients.customers.list', {
      url: '/list',
      template: '<cf-customers-list user="$resolve.user" product-customers="$resolve.productCustomers" ' +
        '></cf-customers-list>',
      resolve: {
        productCustomers: (productCustomersService, user) =>
          productCustomersService.getAllProductCustomers(user.orgContext.id)
      }
    }).state('ingredients.customers.customer', {
      abstract: true,
      url: '/:productCustomersId',
      template: '<cf-customers user="$resolve.user"  product-customer="$resolve.productCustomer"> ' +
        '</cf-customers>',
      resolve: {
        productCustomer: (user, productCustomersService, $stateParams) =>{
          if($stateParams.productCustomersId) {
            return productCustomersService.getProductCustomer(user.orgContext.id, $stateParams.productCustomersId);
          }
        }
      }
    }).state('ingredients.customers.customer.overview', {
      url: '/overview',
      template: '<cf-customer-overview user="$resolve.user" product-customer="$resolve.productCustomer" ' +
        'customer-types="$resolve.customerTypes"> </cf-customer-overview>',
      resolve: {
        customerTypes: (constantsService) => constantsService.get('recallCustomerTypes')
          .then(o => _.map(o, (c) => ({name: c.text, value: c.id})))
      }
    }).state('ingredients.customers.customer.orders', {
      url: '/orders',
      template: '<cf-customer-order user="$resolve.user" product-customer="$resolve.productCustomer" ' +
        'orders="$resolve.orders" ></cf-customer-order>',
      resolve: {
        orders: (user, productCustomersService, $stateParams) =>
          productCustomersService.getAllOrders(user.orgContext.id, $stateParams.productCustomersId)
      }
    });
  }).run(function(menus, authorization, orgPerspectives) {
    'ngInject';

    menus.addMenuItem('dashboard', {
      title: 'Supply Chain',
      redirectPath: '/supplyChain',
      position: 5,
      isPublic: false,
      claims: [authorization.claims.SUPPLIER_READ],
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP]
    });
  });
};
