module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider.state('authentication', {
      abstract: true,
      url: '/authentication',
      template: '<div ui-view></div>',
      data: {menuId: 'dashboard'}
    }).state('signup', {
      abstract: true,
      url: '/signup?aid?profileType?prod?freq?key?orgType?trial?serviceId?template?productCategory?src?plan?spid?coupon',
      template: '<ui-view></ui-view>',
      data: {menuId: 'dashboard'}
    }).state('signup.begin', {
      url: '',
      template: '<cf-signup token-id="$resolve.tokenId" token-data="$resolve.tokenData" ' +
        'activate-capcha="$resolve.activateCapcha" company-name="$resolve.companyName" ' +
        'profile-type="$resolve.profileType" org-type="$resolve.orgType">' +
        '</cf-signup>',
      resolve: {
        tokenId: ($stateParams) => $stateParams.aid,
        key: ($stateParams) => $stateParams.key,
        profileType: ($stateParams) => $stateParams.profileType,
        orgType: ($stateParams) => $stateParams.orgType,
        tokenData: ($q, tokenId, key, urlTokens) => {
          if (tokenId) {
            return urlTokens.legacyGet(tokenId)
              .then(tokenData => {
                if (_.get(tokenData, 'key') !== key) { return $q.reject('Invalid URL token key'); }
                return tokenData;
              });
          }
        },
        companyName: function(tokenData, fbutil) {
          return _.get(tokenData, 'organizationId') ?
            fbutil.ref('organizations', tokenData.organizationId, 'companyName').once('value')
              .then(fbutil.getValueOrDefault) : null;
        },
        activateCapcha: (fbutil) => {
          return fbutil.ref('constants/activateCapcha').once('value')
            .then(fbutil.getValueOrDefault);
        }
      }
    }).state('signup.plan', {
      url: '/plan',
      onEnter: function(user, $state, $timeout) {
        // Don't let subscribed users visit the plan chooser.
        if (user.subscription && !$state.params.transfer) {
          // Removing the $timeout would eliminate the flicker, but unfortunately it hits a ui-router bug w/o it.
          $timeout(() => $state.go('onboarding.firstPlan'));
        }
      },
      template: '<cf-plan-chooser user="$resolve.user" stripe-constants="$resolve.stripeConstants"' +
          'company="$resolve.company"></cf-plan-chooser>',
      resolve: {
        user: (authentication) => authentication.requireAuth(),
        stripeConstants: (constantsService) => constantsService.get('stripe'),
        company: (user, organizations) => organizations.$get(user.organizationId)
      }
    }).state('authentication.signin', {
      url: '/signin?aid?key?return_to?profileType?reason?zendeskSso?serviceId?email',
      template: '<cf-signin reason="$resolve.reason" token-data="$resolve.tokenData" ' +
        'profile-type="$resolve.profileType">' +
      '</cf-signin><cf-auth-footer></cf-auth-footer>',
      resolve: {
        reason: ($stateParams) => $stateParams.reason,
        tokenId: ($stateParams) => $stateParams.aid,
        key: ($stateParams) => $stateParams.key,
        profileType: ($stateParams) => $stateParams.profileType,
        tokenData: ($q, tokenId, key, urlTokens) => {
          if (tokenId) {
            return urlTokens.legacyGet(tokenId)
              .then(tokenData => {
                if (_.get(tokenData, 'key') !== key) { return $q.reject('Invalid URL token key'); }
                return tokenData;
              });
          }
        },
      }
    }).state('authentication.signout', {
      url: '/signout?reason',
      template: '<div></div>',
      controller: function($stateParams, authentication) {
        authentication.logout($stateParams.reason);
      },
      controllerAs: 'vm'
    }).state('authentication.resetPassword', {
      url: '/settings/password/reset',
      template: '<div cf-password-reset></div>'
    }).state('authentication.fbActionUrl', {
      url: '/fbAction?mode&oobCode',
      templateProvider: ($stateParams, growl, cfpLoadingBar) => {
        'ngInject';

        switch ($stateParams.mode) {
        case 'resetPassword':
          return '<cf-new-password action-code="' + $stateParams.oobCode + '"></cf-new-password>';
        default:
          growl.error('Invalid link. Please request a new link or call customer support.');
          cfpLoadingBar.complete();

          throw new Error('LOGGED_OFF_ERROR');
        }
      }
    });
  }).run(function(menus) {
    'ngInject';

    menus.addMenu('dashboard', {isPublic: true});
  });
};
