module.exports = function(ngModule) {
  class FilePortalService {
    constructor(fbutil, $firebaseArray, $http, urlTokens, $q, products) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseArray = $firebaseArray;
      this.$http = $http;
      this.urlTokens = urlTokens;
      this.$q = $q;
      this.products = products;
    }

    /**
     * Get the staging area used to save files temporarily until the user hits 'submit' and files are copied to the
     * ultimate destination. If none exists, create one and save in the token state.
     * @param {object} token The file portal token
     * @param {string} accessKey The access key which will allow the token to be updated.
     * @param {string} key The key that segregates the portal sections from one another.
     * @returns {Promise<any>} A promise that resolves to an object containing a FB array and the remote dir.
     */
    getTempFileStagingArea(token, accessKey, key) {
      let staging = _.get(token, 'state.fileStaging');

      if (staging) {
        return this.$firebaseArray(this.fbutil.ref(`filePortal/${staging.fbKey}/${key}`)).$loaded().then($files => {
          return {
            $files,
            remoteDirectory: `${staging.remoteDirectory}/${key}`
          };
        });
      }

      // Establish a file staging area for the user of this token to save files.
      // Security is established via file paths using randomly generated keys
      return this.$http.get('/randomKey').then(result => {
        let randomKey = `k${result.data.key.substring(0, 15)}`;
        let remoteDirectory = `filePortalStaged/${randomKey}/files`;

        _.set(token, 'state.fileStaging', {
          fbKey: randomKey,
          remoteDirectory
        });

        return this.urlTokens.saveState(token.$id, accessKey, token).then(() => {
          return this.$firebaseArray(this.fbutil.ref(`filePortal/${randomKey}/${key}`)).$loaded()
            .then($files => ({
              $files,
              remoteDirectory: `${remoteDirectory}/${key}`
            }));
        });
      });
    }

    /**
     * Return all files that are staged for a given token.
     * @param {object} token The token object
     * @returns {Promise} A promise that resolves to a map of filePortal groups to an array of staged file categories.
     */
    getStagedFileCategories(token) {
      let staging = _.get(token, 'state.fileStaging');

      if (!staging) { return []; }
      return this.fbutil.ref(`filePortal/${staging.fbKey}`).once('value').then(stageSnap => {
        if (!stageSnap.exists()) { return []; }
        let stagedFolders = stageSnap.val();

        return _.mapValues(stagedFolders, files => {
          return _.map(files, f => f.category);
        });
      });
    }

    /**
     * Fetch the missing supplier level files.
     * @param {string} tokenId The token used to encrypt and store the supplierId
     * @param {string} supplierId The encrypted supplier Id
     * @returns {array} An array of file category Ids that are missing
     */
    getMissingSupplierFiles(tokenId, supplierId) {
      return this.$http.get(`suppliers/${supplierId}/missingFiles?encryptedWithToken=${tokenId}`);
    }

    /**
     * Create file upload portals.
     * @param {string} organizationId The organization to create the portal for.
     * @param {array} recipients An array of {name, email, uid} to send the request to.
     * @param {object} fileInfo - The requested files
     * @param {string} fileInfo.requiredOrgFiles - CSV of file category IDs (ex. "4,9,13")
     * @param {string} [fileInfo.requiredProductFiles] - CSV of file category IDs (ex. "4,9,13")
     * @param {string} [fileInfo.productId] - If present, the product/plan that the product files will apply to.
     * @return {Q.Promise<any> | IRoute | T | TRequest} A promise that resolves to an array of tokens created.
     */
    createPortals(organizationId, recipients, fileInfo) {
      return this.$http.post('urlTokens', {
        organizationId,
        recipients,
        fileInfo
      });
    }

    $getPortals(orgId) {
      return this.$firebaseArray(this.fbutil.ref(`portalStatuses/${orgId}`)).$loaded();
    }
  }

  ngModule.service('filePortalService', FilePortalService);
};
