module.exports = function(ngModule) {
  class ProductAccess {
    constructor($q, authorization, CF_CLAIMS) {
      'ngInject';

      this.$q = $q;
      this.authorization = authorization;
      this.CF_CLAIMS = CF_CLAIMS;
    }

    hasWriteAccess(user, product) {
      if (user.orgContext.id !== product.organizationId) {
        return this.authorization.hasOrgLevelProductException(user,
          product.organizationId, product.$id, this.CF_CLAIMS.PRODUCT_WRITE);
      }

      return this.$q.resolve(user.hasPermission(this.CF_CLAIMS.PRODUCT_WRITE));
    }
  }

  ngModule.service('productAccess', ProductAccess);
};
