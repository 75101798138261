class Controller {
  constructor($q, $uibModal, foodProductService, products, confirmDeleteModal, utils, ingredientService,ingredients) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.productsSvc = products;
    this.confirmDeleteModal = confirmDeleteModal;
    this.utils = utils;
    this.ingredientService = ingredientService;
    this.ingredients = ingredients;
  }

  removeFoodProduct(foodProduct) {
    this.confirmDeleteModal('Food Product', {
      body: `Are you sure you want to remove foodProduct <strong>${foodProduct.itemName}</strong>?`
    }).then(() => {
      this.ingredients.deleteIngredient(foodProduct.id)
        .then(()=>{
          this.productsSvc
            .removeFoodProduct(this.product.$id, foodProduct.id)
            .then(() => _.remove(this.foodProducts, {id: foodProduct.id}))
            .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove foodProduct.'));
        });
    });
  }

  editFoodProduct(foodProduct,index) {
    this.$uibModal.open({
      component: 'cfEditIngredient',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        item: () => this.ingredientService.get(foodProduct.id),
        isFoodProduct: () => true,
        plan: () => this.product.$id
      }
    }).result
      .then((foodProduct)=> {
        this.foodProducts[index].itemName = foodProduct.itemName;
      }).catch(err => {
        return this.utils.defaultErrorHandler(err, err?.data?.message);
      });
  }

  onAddFoodProduct() {
    this.$uibModal.open({
      component: 'cfEditIngredient',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        isFoodProduct: () => true,
        company: () => ({$id: this.user.orgContext.id}),
        plan: () => this.product.$id
      }
    }).result
      .then((foodProduct)=> {
        this.productsSvc.addFoodProduct(this.product.$id, foodProduct.id);
        this.foodProducts.push(foodProduct);
      })
      .catch(err => {
        return this.utils.defaultErrorHandler(err, err?.data?.message);
      });
  }
}

module.exports = Controller;
