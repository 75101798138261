module.exports = function(ngModule) {
  ngModule.component('cfAccessRequestNotification', {
    bindings: {
      notification: '<',
      user: '<',
      onDelete: '&'
    },
    template: require('./notifications.access-request.template.html'),
    controller: require('./notifications.access-request.controller.js'),
    controllerAs: 'vm'
  });
};
