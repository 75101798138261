module.exports = function(ngModule) {
  ngModule.component('cfProblemReportsEdit', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./recall.problem-reports-edit.template.html'),
    controller: require('./recall.problem-reports-edit.controller'),
    controllerAs: 'vm'
  });
};
