class Constructor {
  constructor(
    $q,
    organizations,
    users,
    $uibModal,
    growl,
    $state,
    confirmDeleteModal,
    utils,
    selectModal,
    cfpLoadingBar,
    $timeout,
    confirmModal,
    orgInteractionService,
    authorization,
    datePickerModal,
    CF_ROLES,
    createLinkModal,
    $log,
    questionnaireService
  ) {
    'ngInject';

    this.$q = $q;
    this.organizations = organizations;
    this.users = users;
    this.$uibModal = $uibModal;
    this.growl = growl;
    this.$state = $state;
    this.confirmDeleteModal = confirmDeleteModal;
    this.utils = utils;
    this.CF_ROLES = CF_ROLES;
    this.selectModal = selectModal;
    this.cfpLoadingBar = cfpLoadingBar;
    this.confirmModal = confirmModal;
    this.orgInteractionService = orgInteractionService;
    this.authorization = authorization;
    this.$timeout = $timeout;
    this.datePickerModal = datePickerModal;
    this.$log = $log;
    this.createLinkModal = createLinkModal;
    this.questionnaireService = questionnaireService;
    this.TRANSFER_REASON = 'ownershipTransfer';
  }

  $onInit() {
    this.searching = true;
    this.organizations
      .getClients(this.user.isCfAdmin() ? this.user.orgContext.id : this.user.organizationId)
      .then((orgs) => {
        this.clients = _.isEmpty(orgs) ? [] : orgs;

        _.each(this.clients, (client) => {
          _.each(client.userInvitations, (invitation) => {
            if (
              invitation.reason === this.TRANSFER_REASON &&
              (!client.lastInvite || invitation.createdOn > client.lastInvite.createdOn)
            ) {
              client.lastInvite = invitation;
              client.lastInvite.expired = moment().subtract(5, 'days') > moment(client.lastInvite.createdOn);
            }
          });
        });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to search clients.'))
      .finally(() => {
        this.searching = false;
      });
  }

  addClient() {
    this.$uibModal
      .open({
        component: 'cfCreateOrg',
        backdrop: 'static',
        resolve: {
          newOrgProps: () => ({provider: this.user.organizationId}),
        },
      })
      .result.then((org) => {
        this.clients.push(org);
        this.growl.success('Client created successfully.', {});
        return this.user
          .loadAccess()
          .then(() => this.$state.go('administration.clients.client.edit', {organizationId: org.$id}));
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Error creating the new organization.'));
  }

  createTransferLink(orgId) {
    const inviteOptions = {
      assumeOwnership: true,
      updatePrevOwner: {
        uid: this.user.uid,
        role: this.CF_ROLES.PROVIDER_WRITE,
      },
      urlParams: {prod: 'default'},
      reason: this.TRANSFER_REASON,
      skipEmail: true,
    };

    this.cfpLoadingBar.start();
    return this.orgInteractionService
      .inviteNewUser(this.user, orgId, this.CF_ROLES.ADMIN, null, inviteOptions)
      .then((generateInvitationResult) => {
        this.generateInvitationResult = generateInvitationResult;
        return generateInvitationResult.invitationUrl;
      })
      .catch((err) => {
        this.utils.defaultErrorHandler(err, 'Unable to generate signup link.');
        return null;
      })
      .finally(() => this.cfpLoadingBar.complete());
  }

  emailInvitation(organization, contact, link) {
    let {companyName, $id} = organization;
    const EMAIL_TEMPLATE = 'user_invite';

    return this.orgInteractionService
      .emailInvitation(this.user, $id, contact, EMAIL_TEMPLATE, this.generateInvitationResult.invitationKey, link, {
        companyName,
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to send transfer signup email.'));
  }

  transferOwnership(organization) {
    const inviteOptions = {
      assumeOwnership: true,
      updatePrevOwner: {
        uid: this.user.uid,
        role: this.CF_ROLES.PROVIDER_WRITE,
      },
      urlParams: {prod: 'default'},
      reason: this.TRANSFER_REASON,
      skipEmail: true,
    };

    if (!organization.pointOfContactName || !organization.pointOfContactEmail) {
      this.growl.error('Please provide a point of contact name and email.');
      return;
    }
    let contact = {
      contactName: organization.pointOfContactName,
      contactEmail: organization.pointOfContactEmail,
    };

    this.orgInteractionService
      .inviteUser(organization.$id, contact, this.CF_ROLES.ADMIN, inviteOptions)
      .then(() => {
        this.$log.info(`User ${contact.contactEmail} invited to : ${organization.companyName}`);
        this.growl.success(`User ${contact.contactEmail} invited to : ${organization.companyName}`);
      })
      .catch((err) => {
        this.utils.defaultErrorHandler(err, 'Unable to transfer ownership.');
      });
    // this.createLinkModal(this.user, {
    //   title: 'Transfer Link Created',
    //   instructionsHtml: `<div class="g-mb-10">When your client signs up with the link below, they will assume
    //       ownership of <b>${organization.companyName}</b>. You will retain access as an authorized provider.</div>
    //       <div>Copy the link and send it to your client <i>or</i> click the <b>Send in Email</b> button.</div>`,
    //   generateLinkFn: () => this.createTransferLink(organization.$id),
    //   contact: contact,
    //   emailLinkFn: (contact, link) => this.emailInvitation(organization, contact, link),
    // })
    //   .then((inviteUrl) => {
    //     organization.lastInvite = {createdOn: new Date().getTime()};
    //     this.$log.info('Invite URL sent: ' + inviteUrl);
    //   })
    //   .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to transfer ownership.'));
  }

  inviteUser(organization) {
    let contact = {
      contactName: organization.pointOfContactName,
      contactEmail: organization.pointOfContactEmail,
    };

    this.orgInteractionService.inviteUser(organization.$id, contact, this.CF_ROLES.ADMIN, {})
      .then(() => {
        this.$log.info(`User ${contact.contactEmail} invited to : ${organization.companyName}`);
        this.growl.success(`User ${contact.contactEmail} invited to : ${organization.companyName}`);
      })
      .catch((err) => {
        this.utils.defaultErrorHandler(err, 'Unable to invited user.');
      });
  }
  workClient(organization) {
    this.user
      .setOrgContext(organization.$id)
      .then(() => {
        this.$state.go('user.dashboard');
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to work client.'));
  }

  remove(organization) {
    this.confirmDeleteModal(organization.companyName).then(() => {
      return this.$q
        .all([
          this.authorization.removeFromOrganization(this.user.uid, organization.$id),
          this.organizations.removeProvider(organization.$id),
        ])
        .then(() => {
          _.remove(this.clients, (org) => org.$id === organization.$id);
          this.growl.success('Organization removed');
        })
        .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to remove organization.'));
    });
  }

  assignQuestionnaire(organization) {
    this.questionnaireService
      .chooseType(
        '<div class="g-mt-10 g-mb-30">Choose which questionnaire you would like to ' + 'assign to your client.</div>'
      )
      .then((type) => {
        return this.createLinkModal(this.user, {
          title: 'Send Questionnaire Link',
          instructionsHtml:
            '<div class="g-mb-10">Copy the link below and send it to a recipient at ' +
            `<b>${organization.companyName}</b>.`,
          generateLinkFn: () =>
            this.$state.href('questionnaire', {type, key: organization.$id}, {absolute: true}).replace('#', 'app/#'),
        });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to assign questionnaire.'));
  }

  viewQuestionnaire(organization) {
    const msg = '<div class="g-mt-10 g-mb-30">Choose which questionnaire you would like to view.</div>';

    this.questionnaireService
      .chooseType(msg, organization.$id)
      .then((type) =>
        this.$state.go('administration.clients.client.questionnaires', {organizationId: organization.$id, type})
      )
      .catch((err) => {
        if (err === 'None found') {
          this.growl.error(
            'No questionnaires found. Please assign a questionnaire to the client ' + 'and verify it was filled out.'
          );
          return;
        }
        return this.utils.defaultErrorHandler(err, 'Unable to view questionnaire.');
      });
  }
}

module.exports = Constructor;
