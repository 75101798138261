class Constructor {
  constructor() {
    'ngInject';

    this.caChoices = {
      REPORT: 1,
      ASSIGN: 2,
      SIMPLE: 3
    };
  }

  $onInit() {
    this.$sopLog.correctiveActionOpen = true;
    this.$sopLog.caReport = this.$sopLog.caReport || {};
  }

  getPlanCA($event) {
    $event.stopPropagation();
    if (_.isFunction(this.onPreFillClicked)) {
      this.onPreFillClicked();
    } else {
      this.$sopLog.caReport.actionsTaken = this.$sop.metadata.correctiveAction;
    }
  }

  caChoose(caChoice) {
    this.caChoice = caChoice;
  }
}

module.exports = Constructor;
