module.exports = function(ngModule) {
  class ProductCategories {
    constructor(CacheFactory, $q, fbutil, $firebaseArray, $firebaseObject) {
      'ngInject';

      this.$q = $q;
      this.fbutil = fbutil;
      this.$firebaseArray = $firebaseArray;
      this.$firebaseObject = $firebaseObject;

      if (!CacheFactory.get('productCategoriesCache')) {
        CacheFactory.createCache('productCategoriesCache', {
          deleteOnExpire: 'aggressive',
          recycleFreq: 60 * 30 * 1000, // 30 min (how ofter the cache mgr will check for expired items)
          maxAge: 60 * 60 * 1000, // 60 min (note: ordering a letter will result in manual deleting of the cache rec)
          storageMode: 'localStorage' // Will persist across browser refreshes
        });
      }
      this.CACHE_KEY = 'categories';
      this.cache = CacheFactory.get('productCategoriesCache');
    }

    /**
     * Get all product categories.
     * @return {Array} - An array of category objects (with 'name' and 'group' properties).
     */
    getAll() {
      let cachedVal = this.cache.get(this.CACHE_KEY);

      if (cachedVal) { return this.$q.when(cachedVal); }
      return this.fbutil.ref('foodCategories').once('value').then(snap => {
        if (!snap.exists()) { throw new Error('Product categories missing!'); }
        let val = _.map(snap.val(), (val, id) => {
          val.id = id;
          return val;
        });

        try {
          this.cache.put(this.CACHE_KEY, val);
        } catch (err) {
          console.error(err);
        }
        return val;
      });
    }

    $getAll() {
      return this.$firebaseArray(this.fbutil.ref('foodCategories')).$loaded();
    }

    /**
     * Get product category name from an Id.
     * @param {string} categoryId - The category Id.
     * @return {string} - The category name.
     */
    getName(categoryId) {
      return this.getAll().then(categories => {
        if (!categories) { return null; }
        let categoryRec = _.find(categories, c => c.id === categoryId);

        if (!categoryRec) { return null; }
        return categoryRec.name;
      });
    }

    $getCategory(categoryId) {
      return this.$firebaseObject(this.fbutil.ref(`foodCategories/${categoryId}`)).$loaded();
    }

    $getSubCategory(categoryId, subCategoryId) {
      return this.$firebaseObject(this.fbutil.ref(`foodCategories/${categoryId}/subCategories/${subCategoryId}`))
          .$loaded();
    }

    $pushCategory() {
      return this.$firebaseObject(this.fbutil.ref('foodCategories').push()).$loaded();
    }

    $pushSubCategory(categoryId) {
      return this.$firebaseObject(this.fbutil.ref(`foodCategories/${categoryId}/subCategories`).push()).$loaded();
    }
  }

  ngModule.service('productCategories', ProductCategories);
};

