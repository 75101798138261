module.exports = function(ngModule) {
  ngModule.factory('UsersSearchCflib', function($http, $q, utils) {
    'ngInject';

    const DEFAULT_PAGE_SIZE = 30;

    class UsersSearchCflib {
      constructor() {
        this.baseUri = 'users';
        this.maxReached = false;
        this.pageSize = DEFAULT_PAGE_SIZE;
      }

      /**
       * Sort by a property on the procedure entry
       * @param {string} sortBy Sort field
       * @param {string} sortDirection Sort direction
       * @returns {SopSearch} The SopSearch object
       */
      sortBy(sortBy, sortDirection = 'asc') {
        this.sortByValue = sortBy;
        this.sortDirection = sortDirection;

        return this;
      }

      /**
       * Set the max page size of the result set
       * @param {number} size Max page size
       * @returns {SopSearch} The SopSearch object
       */
      size(size) {
        this.pageSize = size;

        return this;
      }

      /**
       * Start from index
       * @param {number} index Max page size
       * @returns {SopSearch} The SopSearch object
       */
      startFrom(index) {
        this.from = index;

        return this;
      }

      /**
       * Set the search text (currently will try to match key name)
       * @param {string=} text The search text
       * @returns {Promise} The SopSearch object
       */
      search(text = '') {
        this.from = 0;
        this.maxReached = false;
        this.searching = true;
        this.badSearch = false;
        this.searchResults = [];

        return this._search(text)
          .finally(() => { this.searching = false; });
      }

      getMore(text) {
        if (this.badSearch) { throw new Error('Failed search...cannot get more'); }
        if (this.maxReached || this.searching) { return; }
        this.from += this.pageSize;

        return this._search(text);
      }

      $loaded() {
        return this.httpPromise;
      }

      _search(text) {
        let queryParmArray = [];

        queryParmArray.push(`from=${this.from}`);
        queryParmArray.push(`size=${this.pageSize}`);
        queryParmArray.push(`sortBy=${this.sortByValue || 'lastName.keyword'}`);
        queryParmArray.push(`sortDirection=${this.sortDirection || 'asc'}`);

        if (text) { queryParmArray.push(`searchText=${text}`); }

        this.searching = true;
        this.httpPromise = $http.get(`${this.baseUri}${utils.toQueryParm(queryParmArray)}`)
          .then(result => {
            if (result.status === 204) {
              this.maxReached = true;
              return [];
            }

            let recs = result.data.users;

            if (recs.length < this.pageSize) {
              this.maxReached = true;
            }
            this.searchResults = _.concat(this.searchResults, recs);

            return recs;
          })
          .catch(err => {
            this.badSearch = true;

            return $q.reject(err);
          })
          .finally(() => this.searching = false);

        return this.httpPromise;
      }
    }

    return UsersSearchCflib;
  });
};
