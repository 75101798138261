module.exports = function($uibModal, growl, $log, utils) {
  'ngInject';

  const vm = this;

  vm.submit = function(payment, order) {
    payment.removeCard = !payment.saveCard;
    delete payment.saveCard;
    vm.ordering = true;

    let submitPromise = vm.onSubmit({payment: payment, order: order});

    if (_.isFunction(submitPromise.finally)) {
      submitPromise.finally(() => vm.ordering = false);
    } else {
      $log.warn('OrderPaymentControls Warning. The onSubmit handler does not return a promise that ' +
        'supports \'finally\'. Cannot stop spinner.');
    }
  };

  // vm.setupAch = function() {
  //   vm.onCancel();
  //   $uibModal.open({
  //     component: 'cfAddAch',
  //     resolve: {
  //       user: () => vm.orderCtrl.user
  //     }
  //   }).result.then(() => {
  //     growl.success('ACH payment method created. Once the account deposits are made, return to the billing ' +
  //       'page to complete the setup.');
  //   }).catch(reason => {
  //     if (utils.isModalDismissalByUser(reason)) { return; }
  //     growl.error('Could not add ACH payment method.');
  //   });
  // };
};
