module.exports = function(ngModule) {
  ngModule.constant('countryStates', function() {
    return [
      {
        countryName: 'United States',
        states: [
        {name: 'Alabama', abbreviation: 'AL'},
        {name: 'Alaska', abbreviation: 'AK'},
        {name: 'American Samoa', abbreviation: 'AS'},
        {name: 'Arizona', abbreviation: 'AZ'},
        {name: 'Arkansas', abbreviation: 'AR'},
        {name: 'California', abbreviation: 'CA'},
        {name: 'Colorado', abbreviation: 'CO'},
        {name: 'Connecticut', abbreviation: 'CT'},
        {name: 'Delaware', abbreviation: 'DE'},
        {name: 'District Of Columbia', abbreviation: 'DC'},
        {name: 'Federated States Of Micronesia', abbreviation: 'FM'},
        {name: 'Florida', abbreviation: 'FL'},
        {name: 'Georgia', abbreviation: 'GA'},
        {name: 'Guam', abbreviation: 'GU'},
        {name: 'Hawaii', abbreviation: 'HI'},
        {name: 'Idaho', abbreviation: 'ID'},
        {name: 'Illinois', abbreviation: 'IL'},
        {name: 'Indiana', abbreviation: 'IN'},
        {name: 'Iowa', abbreviation: 'IA'},
        {name: 'Kansas', abbreviation: 'KS'},
        {name: 'Kentucky', abbreviation: 'KY'},
        {name: 'Louisiana', abbreviation: 'LA'},
        {name: 'Maine', abbreviation: 'ME'},
        {name: 'Marshall Islands', abbreviation: 'MH'},
        {name: 'Maryland', abbreviation: 'MD'},
        {name: 'Massachusetts', abbreviation: 'MA'},
        {name: 'Michigan', abbreviation: 'MI'},
        {name: 'Minnesota', abbreviation: 'MN'},
        {name: 'Mississippi', abbreviation: 'MS'},
        {name: 'Missouri', abbreviation: 'MO'},
        {name: 'Montana', abbreviation: 'MT'},
        {name: 'Nebraska', abbreviation: 'NE'},
        {name: 'Nevada', abbreviation: 'NV'},
        {name: 'New Hampshire', abbreviation: 'NH'},
        {name: 'New Jersey', abbreviation: 'NJ'},
        {name: 'New Mexico', abbreviation: 'NM'},
        {name: 'New York', abbreviation: 'NY'},
        {name: 'North Carolina', abbreviation: 'NC'},
        {name: 'North Dakota', abbreviation: 'ND'},
        {name: 'Northern Mariana Islands', abbreviation: 'MP'},
        {name: 'Ohio', abbreviation: 'OH'},
        {name: 'Oklahoma', abbreviation: 'OK'},
        {name: 'Oregon', abbreviation: 'OR'},
        {name: 'Palau', abbreviation: 'PW'},
        {name: 'Pennsylvania', abbreviation: 'PA'},
        {name: 'Puerto Rico', abbreviation: 'PR'},
        {name: 'Rhode Island', abbreviation: 'RI'},
        {name: 'South Carolina', abbreviation: 'SC'},
        {name: 'South Dakota', abbreviation: 'SD'},
        {name: 'Tennessee', abbreviation: 'TN'},
        {name: 'Texas', abbreviation: 'TX'},
        {name: 'Utah', abbreviation: 'UT'},
        {name: 'Vermont', abbreviation: 'VT'},
        {name: 'Virgin Islands', abbreviation: 'VI'},
        {name: 'Virginia', abbreviation: 'VA'},
        {name: 'Washington', abbreviation: 'WA'},
        {name: 'West Virginia', abbreviation: 'WV'},
        {name: 'Wisconsin', abbreviation: 'WI'},
        {name: 'Wyoming', abbreviation: 'WY'}
        ]
      },
      {
        countryName: 'India',
        states: [
          {name: 'Maharashtra', abbreviation: 'MH'},
          {name: 'Gujjarat', abbreviation: 'GJ'}
        ]
      },
      {
        countryName: 'Canada',
        states: [
          {name: 'Alberta', abbreviation: 'AB'},
          {name: 'British Columbia', abbreviation: 'BC'},
          {name: 'Manitoba', abbreviation: 'MB'},
          {name: 'New Brunswick', abbreviation: 'NB'},
          {name: 'Newfoundland and Labrador', abbreviation: 'NL'},
          {name: 'Northwest Territories', abbreviation: 'NT'},
          {name: 'Nova Scotia', abbreviation: 'NS'},
          {name: 'Nunavut', abbreviation: 'NU'},
          {name: 'Ontario', abbreviation: 'ON'},
          {name: 'Prince Edward Island', abbreviation: 'PE'},
          {name: 'Québec', abbreviation: 'QC'},
          {name: 'Saskatchewan', abbreviation: 'SK'},
          {name: 'Yukon', abbreviation: 'YT'}]
      },
      {
        countryName: 'Afghanistan'
      },
      {
        countryName: 'Albania'
      },
      {
        countryName: 'Algeria'
      },
      {
        countryName: 'Andorra'
      },
      {
        countryName: 'Angola'
      },
      {
        countryName: 'France'
      },
      {
        countryName: 'Gabon'
      },
      {
        countryName: 'Gambia'
      },
      {
        countryName: 'Georgia'
      },
      {
        countryName: 'Germany'
      },
      {
        countryName: 'Ghana'
      },
      {
        countryName: 'Greece'
      },
      {
        countryName: 'Grenada'
      },
      {
        countryName: 'Guatemala'
      },
      {
        countryName: 'Guinea'
      },
      {
        countryName: 'Guinea-Bissau'
      },
      {
        countryName: 'Guyana'
      },
      {
        countryName: 'Haiti'
      },
      {
        countryName: 'Holy See'
      },
      {
        countryName: 'Honduras'
      },
      {
        countryName: 'Hungary'
      },
      {
        countryName: 'Iceland'
      },
      {
        countryName: 'Indonesia'
      },
      {
        countryName: 'Iran'
      },
      {
        countryName: 'Iraq'
      },
      {
        countryName: 'Ireland'
      },
      {
        countryName: 'Israel'
      },
      {
        countryName: 'Italy'
      },
      {
        countryName: 'Jamaica'
      },
      {
        countryName: 'Japan'
      },
      {
        countryName: 'Jordan'
      },
      {
        countryName: 'Kazakhstan'
      },
      {
        countryName: 'Kenya'
      },
      {
        countryName: 'Kiribati'
      },
      {
        countryName: 'Kuwait'
      },
      {
        countryName: 'Kyrgyzstan'
      },
      {
        countryName: 'Laos'
      },
      {
        countryName: 'Latvia'
      },
      {
        countryName: 'Lebanon'
      },
      {
        countryName: 'Lesotho'
      },
      {
        countryName: 'Liberia'
      },
      {
        countryName: 'Libya'
      },
      {
        countryName: 'Liechtenstein'
      },
      {
        countryName: 'Lithuania'
      },
      {
        countryName: 'Luxembourg'
      },
      {
        countryName: 'Madagascar'
      },
      {
        countryName: 'Malawi'
      },
      {
        countryName: 'Malaysia'
      },
      {
        countryName: 'Maldives'
      },
      {
        countryName: 'Mali'
      },
      {
        countryName: 'Malta'
      },
      {
        countryName: 'Marshall Islands'
      },
      {
        countryName: 'Mauritania'
      },
      {
        countryName: 'Mauritius'
      },
      {
        countryName: 'Mexico'
      },
      {
        countryName: 'Micronesia'
      },
      {
        countryName: 'Moldova'
      },
      {
        countryName: 'Monaco'
      },
      {
        countryName: 'Mongolia'
      },
      {
        countryName: 'Montenegro'
      },
      {
        countryName: 'Morocco'
      },
      {
        countryName: 'Mozambique'
      },
      {
        countryName: 'Myanmar (formerly Burma)'
      },
      {
        countryName: 'Namibia'
      },
      {
        countryName: 'Nauru'
      },
      {
        countryName: 'Nepal'
      },
      {
        countryName: 'Netherlands'
      },
      {
        countryName: 'New Zealand'
      },
      {
        countryName: 'Nicaragua'
      },
      {
        countryName: 'Niger'
      },
      {
        countryName: 'Nigeria'
      },
      {
        countryName: 'North Korea'
      },
      {
        countryName: 'North Macedonia'
      },
      {
        countryName: 'Norway'
      },
      {
        countryName: 'Oman'
      },
      {
        countryName: 'Pakistan'
      },
      {
        countryName: 'Palau'
      },
      {
        countryName: 'Palestine State'
      },
      {
        countryName: 'Panama'
      },
      {
        countryName: 'Papua New Guinea'
      },
      {
        countryName: 'Paraguay'
      },
      {
        countryName: 'Peru'
      },
      {
        countryName: 'Philippines'
      },
      {
        countryName: 'Poland'
      },
      {
        countryName: 'Portugal'
      },
      {
        countryName: 'Qatar'
      },
      {
        countryName: 'Romania'
      },
      {
        countryName: 'Russia'
      },
      {
        countryName: 'Rwanda'
      },
      {
        countryName: 'Saint Kitts and Nevis'
      },
      {
        countryName: 'Saint Lucia'
      },
      {
        countryName: 'Saint Vincent and the Grenadines'
      },
      {
        countryName: 'Samoa'
      },
      {
        countryName: 'San Marino'
      },
      {
        countryName: 'Sao Tome and Principe'
      },
      {
        countryName: 'Saudi Arabia'
      },
      {
        countryName: 'Senegal'
      },
      {
        countryName: 'Serbia'
      },
      {
        countryName: 'Seychelles'
      },
      {
        countryName: 'Sierra Leone'
      },
      {
        countryName: 'Singapore'
      },
      {
        countryName: 'Slovakia'
      },
      {
        countryName: 'Slovenia'
      },
      {
        countryName: 'Solomon Islands'
      },
      {
        countryName: 'Somalia'
      },
      {
        countryName: 'South Africa'
      },
      {
        countryName: 'South Korea'
      },
      {
        countryName: 'South Sudan'
      },
      {
        countryName: 'Spain'
      },
      {
        countryName: 'Sri Lanka'
      },
      {
        countryName: 'Sudan'
      },
      {
        countryName: 'Suriname'
      },
      {
        countryName: 'Sweden'
      },
      {
        countryName: 'Switzerland'
      },
      {
        countryName: 'Syria'
      },
      {
        countryName: 'Tajikistan'
      },
      {
        countryName: 'Tanzania'
      },
      {
        countryName: 'Thailand'
      },
      {
        countryName: 'Timor-Leste'
      },
      {
        countryName: 'Togo'
      },
      {
        countryName: 'Tonga'
      },
      {
        countryName: 'Trinidad and Tobago'
      },
      {
        countryName: 'Tunisia'
      },
      {
        countryName: 'Turkey'
      },
      {
        countryName: 'Turkmenistan'
      },
      {
        countryName: 'Tuvalu'
      },
      {
        countryName: 'Uganda'
      },
      {
        countryName: 'Ukraine'
      },
      {
        countryName: 'United Arab Emirates'
      },
      {
        countryName: 'United Kingdom'
      },
      {
        countryName: 'Uruguay'
      },
      {
        countryName: 'Uzbekistan'
      },
      {
        countryName: 'Vanuatu'
      },
      {
        countryName: 'Venezuela'
      },
      {
        countryName: 'Vietnam'
      },
      {
        countryName: 'Yemen'
      },
      {
        countryName: 'Zambia'
      },
      {
        countryName: 'Zimbabwe'
      }
    ];
  });
};
