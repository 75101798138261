'use strict';

require('./signature-pad.css');

module.exports = function (ngModule) {
  ngModule.directive('signaturePad', function () {
    return {
      scope: {
        height: '@',
        width: '@',
        saveText: '@',
        clearText: '@',
        onSave: '='
      },
      restrict: 'EA',
      replace: true,
      template: require('./signature-pad.template.html'),
      link: function (scope, element) {
        var canvas = element.find('canvas')[0],
          context = canvas.getContext("2d"),
          clickX = new Array(),
          clickY = new Array(),
          clickDrag = new Array(),
          paint;

        scope.saveText = scope.saveText || 'Submit';
        scope.clearText = scope.clearText || 'Clear';
        scope.clear = function(){
          clickX = new Array();
          clickY = new Array();
          clickDrag = new Array();
          context.clearRect(0, 0, canvas.width, canvas.height);
        };

        scope.save = function(){
          scope.onSave(canvas.toDataURL("image/png"));
        };

        function draw() {
          context.clearRect(0, 0, canvas.width, canvas.height); // Clears the canvas
          context.strokeStyle = "#000000";  //set the "ink" color
          context.lineJoin = "miter";       //line join
          context.lineWidth = 2;            //"ink" width

          for (var i = 0; i < clickX.length; i++) {
            context.beginPath();                               //create a path
            if (clickDrag[i] && i) {
              context.moveTo(clickX[i - 1], clickY[i - 1]);  //move to
            } else {
              context.moveTo(clickX[i] - 1, clickY[i]);      //move to
            }
            context.lineTo(clickX[i], clickY[i]);              //draw a line
            context.stroke();                                  //filled with "ink"
            context.closePath();                               //close path
          }
        }

        function getMousePos(evt) {
          var rect = canvas.getBoundingClientRect();
          return {
            x: evt.clientX - rect.left,
            y: evt.clientY - rect.top
          };
        }

        function addClick(mousePos, dragging) {
          clickX.push(mousePos.x);
          clickY.push(mousePos.y);
          clickDrag.push(dragging);
        }

        function mouseXY(e) {
          if (paint) {
            addClick(getMousePos(e), true);
            draw();
          }
        }

        function mouseUp() {
          paint = false;
        }

        function mouseDown(e)
        {
          paint = true;
          addClick(getMousePos(e));
          draw();
        }

        canvas.addEventListener("mousedown", mouseDown, false);
        canvas.addEventListener("mousemove", mouseXY, false);
        document.body.addEventListener("mouseup", mouseUp, false);

        //For mobile
        canvas.addEventListener("touchstart", mouseDown, false);
        canvas.addEventListener("touchmove", mouseXY, true);
        canvas.addEventListener("touchend", mouseUp, false);
        document.body.addEventListener("touchcancel", mouseUp, false);
      }
    };
  });
};
