module.exports = function(ngModule) {
  ngModule.constant('timerUnits', function() {
    return [
      {name: 'seconds', value: 'sec'},
      {name: 'minutes', value: 'min'},
      {name: 'hours', value: 'hr'},
      {name: 'days', value: 'day'},
      {name: 'months', value: 'mon'},
    ];
  });
};
