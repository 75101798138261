class Controller {
  constructor(growl, utils) {
    'ngInject';

    this.growl = growl;
    this.utils = utils;
  }

  $onInit() {
  }

  save() {
    this.$event.$save()
      .then(() => this.growl.success('Recall Class Type Saved'))
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save recall plan'));
  }
}

module.exports = Controller;
