class Constructor {
  constructor(growl, utils, userGroups) {
    'ngInject';

    this.growl = growl;
    this.utils = utils;
    this.userGroups = userGroups;
  }

  async $onInit() {
    this.header = this.resolve.header;
    this.tasks = this.resolve.tasks;
    this.foodProducts = this.resolve.foodProducts;
    this.members = this.resolve.members || [];
    this.user = this.resolve.user;
    this.groups = await this.userGroups.getAllUserGroups(this.user.orgContext.id);
    this.groups = _.map(this.groups, (group) => {
      return {id: group.id, name: group.name, type: 'group'};
    });
    this.members = this.members.concat(this.groups);
    if (this.foodProducts) {
      this.foodProducts = this.foodProducts.map((data) => {
        return {id: data.$id, name: data.name};
      });
      this.foodProducts.unshift({name: '--All Product--', value: 'allFoodProduct'});
    }

    this.timeUnit = [
      {name: 'Minutes', value: 'minutes'},
      {name: 'Hour', value: 'hours'},
      {name: 'Day', value: 'days'},
      {name: 'Week', value: 'weeks'},
      {name: 'Month', value: 'months'},
    ];

    if (this.tasks) {
      this.tasks.forEach((task) => {
        task.foodProductId = task.foodProductId ?? [];
        task.assignees = task.assignees ?? [];
      });
    }
  }
  addTasks() {
    if (!this.tasks) {
      this.tasks = [];
    }
    this.tasks.unshift({
      products: [],
      assignees: [],
      relativeId: Math.random().toString(36).slice(2),
      title: this.header,
    });
  }
  changeItem(items){
   if(items.length > 1 && items[0].value === "allFoodProduct"){
     items.shift();
   } else if(items.length > 0 && items[items.length - 1].value === "allFoodProduct") {
     items.splice(0,items.length-1);
   }
  }
  dataReset(data) {
    if(!data.frequency || data.frequency < 2) {
      data.time = null;
      data.timeUnit = null;
    }
  }
  submit() {
    this.modalInstance.close(this.tasks);
  }
  deleteTask(index) {
    this.tasks = this.tasks.filter((_, i) => i !== index);
  }
}

module.exports = Constructor;
