class Constructor {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.title = this.resolve.title;
    this.text = this.resolve.text;
    this.videoUrl = this.resolve.videoUrl;
    this.okText = this.resolve.okText;
    this.noShowAgain = true;
  }
}

module.exports = Constructor;
