module.exports = function(ngModule) {
  ngModule.component('cfEditMaterial', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./ingredients.edit-material.template.html'),
    controller: require('./ingredients.edit-material.controller.js'),
    controllerAs: 'vm'
  });
};
