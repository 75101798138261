module.exports = function (ngModule) {
  require('./product-status.service.js')(ngModule);
  require('./product-plan-type.service.js')(ngModule);
  require('./products.process-control.critical-limits.service')(ngModule);
  require('./products.verification-search.service.js')(ngModule);
  require('./products.step-ingredients.service')(ngModule);
  require('./product-name-search.service')(ngModule);
  require('./product-search.service')(ngModule);
  require('./product-group-search.service')(ngModule);
  require('./product-categories.service')(ngModule);
  require('./product-access.service')(ngModule);
  require('./hazards.service')(ngModule);
  require('./controls.service')(ngModule);
};
