module.exports = function(ngModule) {
  ngModule.component('cfOrganizationOverview', {
    bindings: {
      user: '<',
      organization: '<',
      analystOrgs: '<',
      subscriptionPlans: '<'
    },
    template: require('./administration.organization-overview.template.html'),
    controller: require('./administration.organization-overview.controller.js'),
    controllerAs: 'vm'
  });
};
