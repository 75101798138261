class Controller {
  constructor($q, $log, growl, orgPerspectives, SAMPLE_ORGANIZATION_ID) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.growl = growl;
    this.orgPerspectives = orgPerspectives;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
    this.selectedTemplate = null;
    this.saving = false;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.orgTemplates = this.resolve.orgTemplates;
    this.userInitiated = this.resolve.userInitiated;
    this.recommendedTemplates = this.resolve.recommendedTemplates;
    this.cfTemplates = _.mapValues(this.resolve.cfTemplates,
      (template) => _.assign(template, {cf: true, organizationId: this.SAMPLE_ORGANIZATION_ID}));
    this.showWarning = this.resolve.showWarning;
    this.isProvider = this.user.getPerspective() === this.orgPerspectives.PROVIDER_ANALYST;

    if (!this.user.isTipHidden('firstTemplateIntro')) {
      this.user.setTipHidden('firstTemplateIntro');
      this.showIntro = true;
    }

    this.step = this.showIntro ? 1 : 2;

    this.templates = _.concat(this.templateCollectionToArray(this.orgTemplates),
      this.templateCollectionToArray(this.cfTemplates));

    if (this.recommendedTemplates) {
      const recommendedList = _.filter(this.templates, t => !!this.recommendedTemplates[t.$id]);

      if (recommendedList.length === 1) {
        this.selectedTemplate = _.first(recommendedList);
      }
      _.last(recommendedList).lastRecommended = true;
      _.each(recommendedList, recommended => { recommended.recommended = true; });
    }

    this.templates = _.orderBy(this.templates, ['recommended', 'cf'], ['asc', 'desc']);
    this.templateOptions = {hazards: true, ingredients: true,  equipment: true, planAnalysis: true};
  }

  templateCollectionToArray(templateCollection) {
    return _.map(templateCollection, (template, templateId) => _.assign(template, {$id: templateId}));
  }

  closeIntro() {
    this.step++;
  }

  submit() {
    this.modalInstance.close(_.assign(this.selectedTemplate, {options: this.templateOptions}));
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
