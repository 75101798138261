class Constructor {
  constructor(constantsService, filePortalService, $stateParams) {
    'ngInject';

    this.constantsService = constantsService;
    this.filePortalService = filePortalService;
    this.$stateParams = $stateParams;
  }

  $onInit() {
    this.noFilesText = 'Add Organization Files';
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.categories = _.pickBy(fileTypes, fc => fc.companyLevel);
    });
  }
}

module.exports = Constructor;
