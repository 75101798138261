class Controller {
  constructor(preventDirtyNav, $timeout, growl, $log, batchService, moment) {
    'ngInject';

    this.preventDirtyNav = preventDirtyNav;
    this.$timeout = $timeout;
    this.growl = growl;
    this.$log = $log;
    this.batchService = batchService;
    this.moment = moment;
  }

  $onInit() {
    this.$timeout(() => this.preventDirtyNav(this.eventVolumeForm, 'Your recall volume changes are not saved',
      () => this.save()));
    if(!this.$event.volume) {
      _.assign(this.$event,{volume: {datesProduced: null, datesDistributed: null}});
    }
    if(this.$event.volume) {
      _.assign(this.$event.volume,{
        totalQuantity: 0,
        quantityDistributed: 0,
        hold: 0
      });
    }
    if(!this.$event.productCustomers) {
      _.assign(this.$event,{productCustomers: null});
    }
    this.datesProduced = this.$event.volume.datesProduced ? new Date(this.$event.volume.datesProduced) : null;
    this.datesDistributed = this.$event.volume.datesDistributed ? new Date(this.$event.volume.datesDistributed) : null;
    this.batchService.getSalesOrderByBatchRunId(this.$event.batchRunId, this.user.orgContext.id)
      .then(data => {
        _.each(data,r => {
          this.$event.volume.totalQuantity += r.batchRun.noOfUnits * r.batchRun.sizeOfUnit;
          this.$event.volume.quantityDistributed += r.salesOrder.quantity;
          this.$event.productCustomers.push(r.salesOrder.productCustomerId);
        });
      }).then(()=> this.$event.volume.hold = this.$event.volume.totalQuantity - this.$event.volume.quantityDistributed);
  }

  save() {
    if(this.datesProduced) { this.$event.volume.datesProduced = this.datesProduced.getTime();}
    if(this.datesDistributed) {this.$event.volume.datesDistributed = this.datesDistributed.getTime();}
    this.$event.$save().then(() => {
      if (this.eventVolumeForm) { this.eventVolumeForm.$setPristine(); }

      this.growl.success('Recall volume saved.');
      this.$log.info('Recall volume saved.');
    });
  };

}

module.exports = Controller;
