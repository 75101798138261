/* eslint-disable max-len */
class Controller {
  constructor($state, $uibModal, growl, utils, $log, users, $q, products, confirmModal, projectWritingServices, messageServices,
              notifications, organizations, confirmDeleteModal, sopService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.utils = utils;
    this.$q = $q;
    this.products = products;
    this.confirmModal = confirmModal;
    this.projectWritingServices = projectWritingServices;
    this.messageServices = messageServices;
    this.growl = growl;
    this.notifications = notifications;
    this.organizations = organizations;
    this.confirmDeleteModal = confirmDeleteModal;
    this.sopService = sopService;
    this.$state = $state;
    this.users = users;
    this.$log = $log;
    this.page = 1;
    this.maxReached = false;
    this.searching = false;

    this.PAGE_SIZE = 100;
  }

  $onInit() {
    this.anyPlans = !!this.projects;
    if(this.user.isCfAdmin()) {
      this.hydrateProjects();
    }

    this.isRequester = true;
    this.noPlan = false;
  }

  hydrateProjects() {
    _.each(this.projects, project => {
      let providerOrgNames = [];
      let clientOrgNames = [];

      // Get the name of the organization that created the project request
      this.organizations.getName(project.organizationId).then(name => {
        project = _.assign(project, {orgName: name});
      });

      // Get the name of the client & provider organizations
      this.$q.all([
        _.each(Object.keys(project.clientOrgs), clientOrgId => {
          this.organizations.getName(clientOrgId).then(name => {
            clientOrgNames.push(name);
          });
        }),
        _.each(Object.keys(project.providerOrgs), providerOrgId => {
          this.organizations.getName(providerOrgId).then(name => {
            providerOrgNames.push(name);
          });
        })
      ])
        .then(() => {
          project.clientOrgNames = clientOrgNames;
          project.providerOrgNames = providerOrgNames;
        });
    });
  }

  editProject(project) {
    this.$uibModal.open({
      component: 'cfProjectWritingEditModal',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        user: () => this.user,
        organization: () => this.company,
        project: ()=> project,
        existingProject: ()=> true,
      }
    }).result
        .then(resultProject => {
          return this.update(resultProject);
        })
        .catch(reason => {
          if (this.utils.isModalDismissalByUser(reason)) { return; }

          this.$log.error('Unable to edit a Project.', this.$log.toString(reason));
          this.growl.error('Unable to edit a Project. Please try again or contact FoodReady ' +
            'customer support for assistance.');
        });
  }

  openProject(project) {
    this.$state.go('projectwriting.messages',{projectId: project.$id});
  }

  deleteProject(project) {
    this.confirmDeleteModal('Project', {
      body: 'Are you sure you want to delete Project Title <strong>' + project.title + '</strong>?'
    }).then(() => {
      this.projectWritingServices.delete(project.$id).then(() => {
        _.remove(this.projects, {$id: project.$id});
        this.growl.success('Project deleted');
      }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete project.'));
    });
  }

  chooseService() {
    this.$uibModal.open({
      component: 'cfProjectWritingEditModal',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        user: () => this.user,
        organization: () => this.company
      }
    }).result
      .then(response => {
        this.project = response;
        this._save();
      })
      .catch(reason => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.$log.error('Unable to create a Project.', this.$log.toString(reason));
        this.growl.error('Unable to create a Project. Please try again or contact FoodReady ' +
          'customer support for assistance.');
      });
  }

  getParticipatingOrgs() {
    let clientOrgs = Object.keys(this.project.clientOrgs);
    let providerOrgs = Object.keys(this.project.providerOrgs);
    let participatingOrgs = [...clientOrgs, ...providerOrgs];

    return participatingOrgs;
  }

  getParticipatingUsers() {
    let participatingUsers = [];

    _.map(this.project.clientUsers, (users)=>{
      participatingUsers = [...participatingUsers, ...Object.keys(users)];
    });
    _.map(this.project.providerUsers, (users)=>{
      participatingUsers = [...participatingUsers, ...Object.keys(users)];
    });
    return participatingUsers;
  }

  update(resultProject) {
    let promises = [];
    let removedProviders = [];
    let addedProviders = [];

    if (!_.isEmpty(resultProject.removedProviders)) {
      removedProviders = resultProject.removedProviders;
      delete resultProject.removedProviders;
    }
    if (!_.isEmpty(resultProject.addedProviders)) {
      addedProviders = resultProject.addedProviders;
      delete resultProject.addedProviders;
    }

    return this.projectWritingServices.$get(resultProject.projectId)
      .then((project) => {
        if (resultProject.sopId && project.sopId !== resultProject.sopId) {
          promises.push(this.sopService.$get(resultProject.sopId)
            .then((sop) => {
              resultProject.sopInstructions = sop.metadata.instructions;
            }));
        }

        return this.$q.all(promises)
          .then(() => {
            delete resultProject.projectId;
            _.assign(project, resultProject);
            project.updatedBy = this.user.uid;
            project.updatedOn = firebase.database.ServerValue.TIMESTAMP;
            return project.$save()
              .then(() => {
                if (!_.isEmpty(removedProviders) || !_.isEmpty(addedProviders)) {
                  return this.messageServices.$getTopic(project.messageTopicId)
                    .then((messageTopic) => {
                      let prom = [];

                      if(!_.isEmpty(addedProviders)) {
                        prom.push(this.addProviderEntry(addedProviders, project, messageTopic.$id, messageTopic.messageId));
                      }
                      if(!_.isEmpty(removedProviders)) {
                        prom.push(this.removeProviderEntry(removedProviders, project, messageTopic.$id, messageTopic.messageId));
                      }
                      return this.$q.all(prom);
                    });
                }
              });
          });
      });
  }

  addProviderEntry(addedProviders, project, messageTopicId, messageId) {
    // adding all providers into org-project mapping.
    return this.projectWritingServices.$pushOrg(_.map(addedProviders, 'orgId'), project.$id)
      .then(() => {
        // adding each provider entry one by one into Message topic and Messages
        return _.map(addedProviders, provObj => {
          let addPromises = [];

          let checkedUsers = _.reduce(provObj.userIds, (result, user) => {
            if (user.checked) {
              result.push(user.value);
            }
            return result;
          }, []);

          addPromises.push(this.messageServices.pushParticipatingOrg(messageTopicId, provObj.orgId));
          addPromises.push(this.messageServices.pushParticipatingUsers(messageTopicId, checkedUsers));     // add only whose checked value is true.
          addPromises.push(this.messageServices.pushMessageParticipatingOrg(messageId, provObj.orgId));
          addPromises.push(this.messageServices.pushMessageParticipatingUsers(messageId, checkedUsers));

          return this.$q.all(addPromises)
            .then(() => console.log('all org entries added for ' + provObj.orgId))
            .catch((err) => {
              this.utils.defaultErrorHandler(err, 'Unable to add entry for ' + provObj.orgId  + ' into Message topic and Messages.');
            });
        });
      });
  }

  removeProviderEntry(removedProviders, project, messageTopicId, messageId) {
    // Removing all providers from org-project mapping.
    return this.projectWritingServices.removeOrgProjects(_.map(removedProviders, 'orgId'), project.$id)
      .then(() => {
        // Removing each provider entry one by one from Message topic and Messages
        return _.map(removedProviders, provObj => {
          let removePromises = [];
          let checkedUsers = _.reduce(provObj.userIds, (result, user) => {
            if (user.checked) {
              result.push(user.value);
            }
            return result;
          }, []);

          removePromises.push(this.messageServices.removeParticipatingOrg(messageTopicId, provObj.orgId));
          removePromises.push(this.messageServices.removeParticipatingUsers(messageTopicId, checkedUsers));
          removePromises.push(this.messageServices.removeMessageParticipatingOrg(messageId, provObj.orgId));
          removePromises.push(this.messageServices.removeMessageParticipatingUsers(messageId, checkedUsers));

          return this.$q.all(removePromises)
            .then(() => console.log('all org entries deleted for ' + provObj.orgId))
            .catch((err) => {
              this.utils.defaultErrorHandler(err, 'Unable to remove entry for ' + provObj.orgId  + ' from Message topic and Messages.');
            });
        });
      });
  }

  sendNotifications(orgsInfo, usersInfo) {
    _.each(Object.keys(orgsInfo), orgId => {
      _.each(Object.keys(usersInfo[orgId]), uid => {
        this.notifications.postToUser({
          from: this.user.uid,
          to: uid,
          message: 'A Request for Project <b>"' + this.project.title + '"</b> was submitted by <i>' + this.user.orgContext.companyName + '</i>',
          link: {
            state: 'projectwriting.list'
          }
        });
      });
    });
  }

  _save() {
    let newProject = {};

    if (this.project && !this.project.projectId) {
      newProject = this.project;
      newProject.createdBy = this.user.uid;
      newProject.createdOn = firebase.database.ServerValue.TIMESTAMP;
      newProject.organizationId = this.company.$id;

      let orgs = this.getParticipatingOrgs();
      let users = this.getParticipatingUsers();

      let promises = [];

      if(newProject.sopId) {
        promises.push(this.sopService.$get(newProject.sopId)
          .then((sop) => {
            newProject.sopInstructions = sop.metadata.instructions ? sop.metadata.instructions : "No Specific Instructions.";
          }));
      }

      promises.push(this.messageServices.$pushTopic(this.user, newProject.title , users, orgs)
        .then(($topic)=> {
          newProject.messageTopicId = $topic.$id;
        }));

      this.$q.all(promises)
        .then(() => {
          this.projectWritingServices.$push(newProject)
            .then(($project) => {
              this.projectWritingServices.$pushOrg(orgs, $project.$id)
                .then(()=> {
                  this.growl.success('Project saved successfully.');
                });
              // Sending notifications to Clients
              this.sendNotifications(this.project.clientOrgs, this.project.clientUsers);
              // Sending notifications to Provider
              this.sendNotifications(this.project.providerOrgs, this.project.providerUsers);

              this.openProject($project);
            })
            .catch((err) => {
              this.utils.defaultErrorHandler(err, 'Unable to save Project.');
              this.growl.error('Unable to save a Project. Please try again');
            });
        });
    }
  }
}

module.exports = Controller;
