module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceSubmitQuote', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./marketplace.submit-quote.template.html'),
    controller: require('./marketplace.submit-quote.controller.js'),
    controllerAs: 'vm'
  });
};
