class Constructor {
  constructor($scope, growl, userGroups, confirmDeleteModal) {
    'ngInject';

    this.growl = growl;
    this.userGroups = userGroups;
    this.confirmDeleteModal = confirmDeleteModal;
  }

  $onInit() {
  }

  addGroup() {
    if (!this.groups) {
      this.groups = [];
    }
    this.groups.push({name: 'Group ' + (this.groups.length + 1) , users: [], edit: true});
  }

  save(group) {
    delete group.edit;
    if (group.id) {
      this.userGroups
        .update(this.user.orgContext.id, group.id, group)
        .then(()=>this.growl.success('Group successfully updated.'))
        .catch(() =>this.growl.error('Error occurred during updating.'));
    } else {
      this.userGroups
        .create(this.user.orgContext.id, group)
        .then((res) => {
          group.id = res.id;
          this.growl.success('Group successfully created.');
        })
        .catch(() =>this.growl.error('Error occurred during creating.'));
    }
  }

  deleteGroup(group) {
    this.confirmDeleteModal(group.name).then(() => {
      this.userGroups
        .deleteGroup(this.user.orgContext.id, group.id)
        .then(() => {
          this.groups = this.groups.filter(item => item.id !== group.id);
          this.growl.success('Group deleted Successfully');
        })
        .catch(() =>this.growl.error('Error occurred during deleting.'));
    });
  }
}

module.exports = Constructor;
