module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('checklists', {
        url: '/checklists',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          company: (user, organizations) => organizations.$get(user.orgContext.id)
        },
        data: {menuId: 'dashboard'}
      });
  }).run(function(menus, orgPerspectives) {
    'ngInject';

    menus.addMenuItem('dashboard', {
      title: 'Checklists',
      redirectPath: '/checklists/templates',
      position: 2,
      isPublic: false,
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP]
    });
  });
};
