class Constructor {
  constructor(growl, $log, accessRequests, $q, sopService, sopLibraryService) {
    'ngInject';

    this.growl = growl;
    this.$log = $log;
    this.accessRequests = accessRequests;
    this.$q = $q;
    this.sopService = sopService;
    this.sopLibraryService = sopLibraryService;

    this.DEFAULT_SOP_TYPE = 'passFail';
  }

  $onInit() {
    this.records = this.resolve.records;
    this.user = this.resolve.user;
    this.toSopLibrary = this.resolve.toSopLibrary;

    this.existingSopsPromise = this.toSopLibrary ?
        this.sopLibraryService.query(this.user, null, {fields: ['Id']}, 0, 9999) :
        this.sopService.query(this.user.orgContext.id, null, {templates: false, fields: ['Id']}, 0, 9999);
  }

  import() {
    let errorCount = 0;

    this.importing = true;

    this.$log.info(`Starting an SOP import with ${this.records.length} records.`, {
      orgId: this.user.orgContext.id
    });

    return this.existingSopsPromise.then(existingSops => {
      const existingMap = _.reduce(existingSops, (map, val, key) => {
        map[val.id] = key;
        return map;
      }, {});

      return this.$q.all(_.map(this.records, rec => {
        delete rec.index;

        if (existingMap[rec.id]) {
          return this.$q.reject(`Duplicate SOP ID found: ${rec.id}`);
        }
        let newSop = this.toSop(rec);
        let pushPromise = this.toSopLibrary ? this.sopLibraryService.push(newSop) : this.sopService.push(newSop);

        return pushPromise.then(newSopRef => {
          if (newSop.id) {
            existingMap[newSop.id] = newSopRef.key;
          }

          return _.assign(newSop, {$id: newSopRef.key});
        }).catch(err => {
          errorCount++;

          this.$log.error('Error occurred during SOP import.', {
            errStr: this.$log.toString(err),
            recStr: angular.toJson(rec)
          });
        });
      }));
    }).then(newSops => {
      this.$log.info(`Completed an SOP import with ${this.records.length} records.`, {
        orgId: this.user.orgContext.id,
        errorCount
      });

      this.modalInstance.close({
        newSops,
        total: this.records.length,
        errors: errorCount
      });
    }).finally(() => {
      this.importing = false;
    });
  }

  toSop(importedRec) {
    importedRec.metadata = importedRec.metadata || {};
    let sop = this.noUndefined({
      id: importedRec.id,
      title: importedRec.title,
      description: this.toSopLibrary ? importedRec.description : null,
      metadata: {
        assignedToRole: importedRec.metadata.assignedToRole || importedRec.assignedToRole,
        correctiveAction:
          this.ensureHtml(importedRec.metadata.correctiveAction || importedRec.correctiveAction),
        scope: importedRec.metadata.scope || importedRec.scope,
        frequency: importedRec.metadata.frequency || importedRec.frequency,
        instructions: this.ensureHtml(importedRec.metadata.instructions || importedRec.instructions),
      },
      createdBy: this.user.uid,
      createdByName: this.user.fullName(),
      createdOn: new Date().getTime(),
      organizationId: this.toSopLibrary ? null : this.user.orgContext.id,
      type: importedRec.type || this.DEFAULT_SOP_TYPE
    });

    if (this.user.isPartner()) {
      sop.organizationId = this.user.organizationId;
    }

    return sop;
  }

  ensureHtml(inString) {
    return inString && inString.length ? inString[0] === '<' ? inString : `<p>${inString}</p>` : null;
  }

  noUndefined(obj) {
    _.each(obj, (val, key) => {
      if (_.isUndefined(val)) {
        obj[key] = null;
      }
    });

    return obj;
  }
}

module.exports = Constructor;
