module.exports = function(ngModule) {
  ngModule.component('cfOnboardingCompany', {
    bindings: {
      user: '<',
      company: '<',
      $user: '<'
    },
    template: require('./onboarding.company.template.html'),
    controller: require('./onboarding.company.controller'),
    controllerAs: 'vm'
  });
};
