class Controller {
  constructor($timeout, planTypes, utils, growl, products, SAMPLE_ORGANIZATION_ID, $q, $state) {
    'ngInject';

    this.$timeout = $timeout;
    this.products = products;
    this.growl = growl;
    this.planTypes = planTypes;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
    this.utils = utils;
    this.$q = $q;
    this.$state = $state;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.foodCategories = this.resolve.foodCategories;

    this.isPartner = this.user.isPartner();
    if (this.isPartner) {
      this.onPrimaryOrg = this.isPartner && this.user.onPrimaryOrg();
      this.clientName = this.user.orgContext.companyName;
    }

    this.newProduct = this.products.newProduct(this.user);
  }

  save() {
    this.creating = true;

    // If a partner is creating this plan, create it unlocked.
    if (this.user.isPartner()) {
      this.newProduct.unlockedBy = this.user.uid;
    }

    let subCategoryFound = false;

    _.each(this.foodCategories, (category) => {
      _.each(category.subCategories, (subCategory, subCategoryId) => {
        if (subCategory.template === this.template.value) {
          this.newProduct.categoryId = category.id;
          this.newProduct.subCategoryId = subCategoryId;
          subCategoryFound = true;

          return false;
        }
      });

      if (subCategoryFound) {
        return false;
      } else if (category.template === this.template.value) {
        this.newProduct.categoryId = category.id;
        return false;
      }
    });

    return this.products.create(this.newProduct)
      .then(productId => this.products.loadTemplate(
        {
          organizationId: this.user.orgContext.id,
          $id: productId
        }, this.template.orgId, this.template.value,
        {
          hazards: true,
          ingredients: true,
          equipment: true,
          planAnalysis: true
        }).then(() => productId))
      .then(productId => this.modalInstance.close(productId))
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new food safety plan.'))
      .finally(() => this.creating = false);
  }

  gotoClientList() {
    this.modalInstance.dismiss('cancel');
    this.$state.go('administration.clients.list');
  }
}

module.exports = Controller;
