'use strict';

module.exports = function(ngModule) {
  ngModule.constant('countryPhoneCodes', function() {
    return [
      {
        'name': '+93 Afghanistan',
        'code': '+93'
      },
      {
        'name': '+355 Albania',
        'code': '+355'
      },
      {
        'name': '+213 Algeria',
        'code': '+213'
      },
      {
        'name': '+1 684 AmericanSamoa',
        'code': '+1 684'
      },
      {
        'name': '+376 Andorra',
        'code': '+376'
      },
      {
        'name': '+244 Angola',
        'code': '+244'
      },
      {
        'name': '+1 264 Anguilla',
        'code': '+1 264'
      },
      {
        'name': '+1268 Antigua and Barbuda',
        'code': '+1268'
      },
      {
        'name': '+54 Argentina',
        'code': '+54'
      },
      {
        'name': '+374 Armenia',
        'code': '+374'
      },
      {
        'name': '+297 Aruba',
        'code': '+297'
      },
      {
        'name': '+61 Australia',
        'code': '+61'
      },
      {
        'name': '+43 Austria',
        'code': '+43'
      },
      {
        'name': '+994 Azerbaijan',
        'code': '+994'
      },
      {
        'name': '+1 242 Bahamas',
        'code': '+1 242'
      },
      {
        'name': '+973 Bahrain',
        'code': '+973'
      },
      {
        'name': '+880 Bangladesh',
        'code': '+880'
      },
      {
        'name': '+1 246 Barbados',
        'code': '+1 246'
      },
      {
        'name': '+375 Belarus',
        'code': '+375'
      },
      {
        'name': '+32 Belgium',
        'code': '+32'
      },
      {
        'name': '+501 Belize',
        'code': '+501'
      },
      {
        'name': '+229 Benin',
        'code': '+229'
      },
      {
        'name': '+1 441 Bermuda',
        'code': '+1 441'
      },
      {
        'name': '+975 Bhutan',
        'code': '+975'
      },
      {
        'name': '+387 Bosnia and Herzegovina',
        'code': '+387'
      },
      {
        'name': '+267 Botswana',
        'code': '+267'
      },
      {
        'name': '+55 Brazil',
        'code': '+55'
      },
      {
        'name': '+246 British Indian Ocean Territory',
        'code': '+246'
      },
      {
        'name': '+359 Bulgaria',
        'code': '+359'
      },
      {
        'name': '+226 Burkina Faso',
        'code': '+226'
      },
      {
        'name': '+257 Burundi',
        'code': '+257'
      },
      {
        'name': '+855 Cambodia',
        'code': '+855'
      },
      {
        'name': '+237 Cameroon',
        'code': '+237'
      },
      {
        'name': '+1 Canada',
        'code': '+1'
      },
      {
        'name': '+238 Cape Verde',
        'code': '+238'
      },
      {
        'name': '+ 345 Cayman Islands',
        'code': '+ 345'
      },
      {
        'name': '+236 Central African Republic',
        'code': '+236'
      },
      {
        'name': '+235 Chad',
        'code': '+235'
      },
      {
        'name': '+56 Chile',
        'code': '+56'
      },
      {
        'name': '+86 China',
        'code': '+86'
      },
      {
        'name': '+61 Christmas Island',
        'code': '+61'
      },
      {
        'name': '+57 Colombia',
        'code': '+57'
      },
      {
        'name': '+269 Comoros',
        'code': '+269'
      },
      {
        'name': '+242 Congo',
        'code': '+242'
      },
      {
        'name': '+682 Cook Islands',
        'code': '+682'
      },
      {
        'name': '+506 Costa Rica',
        'code': '+506'
      },
      {
        'name': '+385 Croatia',
        'code': '+385'
      },
      {
        'name': '+53 Cuba',
        'code': '+53'
      },
      {
        'name': '+537 Cyprus',
        'code': '+537'
      },
      {
        'name': '+420 Czech Republic',
        'code': '+420'
      },
      {
        'name': '+45 Denmark',
        'code': '+45'
      },
      {
        'name': '+253 Djibouti',
        'code': '+253'
      },
      {
        'name': '+1 767 Dominica',
        'code': '+1 767'
      },
      {
        'name': '+1 849 Dominican Republic',
        'code': '+1 849'
      },
      {
        'name': '+593 Ecuador',
        'code': '+593'
      },
      {
        'name': '+20 Egypt',
        'code': '+20'
      },
      {
        'name': '+503 El Salvador',
        'code': '+503'
      },
      {
        'name': '+240 Equatorial Guinea',
        'code': '+240'
      },
      {
        'name': '+291 Eritrea',
        'code': '+291'
      },
      {
        'name': '+372 Estonia',
        'code': '+372'
      },
      {
        'name': '+251 Ethiopia',
        'code': '+251'
      },
      {
        'name': '+298 Faroe Islands',
        'code': '+298'
      },
      {
        'name': '+679 Fiji',
        'code': '+679'
      },
      {
        'name': '+358 Finland',
        'code': '+358'
      },
      {
        'name': '+33 France',
        'code': '+33'
      },
      {
        'name': '+594 French Guiana',
        'code': '+594'
      },
      {
        'name': '+689 French Polynesia',
        'code': '+689'
      },
      {
        'name': '+241 Gabon',
        'code': '+241'
      },
      {
        'name': '+220 Gambia',
        'code': '+220'
      },
      {
        'name': '+995 Georgia',
        'code': '+995'
      },
      {
        'name': '+49 Germany',
        'code': '+49'
      },
      {
        'name': '+233 Ghana',
        'code': '+233'
      },
      {
        'name': '+350 Gibraltar',
        'code': '+350'
      },
      {
        'name': '+30 Greece',
        'code': '+30'
      },
      {
        'name': '+299 Greenland',
        'code': '+299'
      },
      {
        'name': '+1 473 Grenada',
        'code': '+1 473'
      },
      {
        'name': '+590 Guadeloupe',
        'code': '+590'
      },
      {
        'name': '+1 671 Guam',
        'code': '+1 671'
      },
      {
        'name': '+502 Guatemala',
        'code': '+502'
      },
      {
        'name': '+224 Guinea',
        'code': '+224'
      },
      {
        'name': '+245 Guinea-Bissau',
        'code': '+245'
      },
      {
        'name': '+595 Guyana',
        'code': '+595'
      },
      {
        'name': '+509 Haiti',
        'code': '+509'
      },
      {
        'name': '+504 Honduras',
        'code': '+504'
      },
      {
        'name': '+36 Hungary',
        'code': '+36'
      },
      {
        'name': '+354 Iceland',
        'code': '+354'
      },
      {
        'name': '+91 India',
        'code': '+91'
      },
      {
        'name': '+62 Indonesia',
        'code': '+62'
      },
      {
        'name': '+964 Iraq',
        'code': '+964'
      },
      {
        'name': '+353 Ireland',
        'code': '+353'
      },
      {
        'name': '+972 Israel',
        'code': '+972'
      },
      {
        'name': '+39 Italy',
        'code': '+39'
      },
      {
        'name': '+1 876 Jamaica',
        'code': '+1 876'
      },
      {
        'name': '+81 Japan',
        'code': '+81'
      },
      {
        'name': '+962 Jordan',
        'code': '+962'
      },
      {
        'name': '+7 7 Kazakhstan',
        'code': '+7 7'
      },
      {
        'name': '+254 Kenya',
        'code': '+254'
      },
      {
        'name': '+686 Kiribati',
        'code': '+686'
      },
      {
        'name': '+965 Kuwait',
        'code': '+965'
      },
      {
        'name': '+996 Kyrgyzstan',
        'code': '+996'
      },
      {
        'name': '+371 Latvia',
        'code': '+371'
      },
      {
        'name': '+961 Lebanon',
        'code': '+961'
      },
      {
        'name': '+266 Lesotho',
        'code': '+266'
      },
      {
        'name': '+231 Liberia',
        'code': '+231'
      },
      {
        'name': '+423 Liechtenstein',
        'code': '+423'
      },
      {
        'name': '+370 Lithuania',
        'code': '+370'
      },
      {
        'name': '+352 Luxembourg',
        'code': '+352'
      },
      {
        'name': '+261 Madagascar',
        'code': '+261'
      },
      {
        'name': '+265 Malawi',
        'code': '+265'
      },
      {
        'name': '+60 Malaysia',
        'code': '+60'
      },
      {
        'name': '+960 Maldives',
        'code': '+960'
      },
      {
        'name': '+223 Mali',
        'code': '+223'
      },
      {
        'name': '+356 Malta',
        'code': '+356'
      },
      {
        'name': '+692 Marshall Islands',
        'code': '+692'
      },
      {
        'name': '+596 Martinique',
        'code': '+596'
      },
      {
        'name': '+222 Mauritania',
        'code': '+222'
      },
      {
        'name': '+230 Mauritius',
        'code': '+230'
      },
      {
        'name': '+262 Mayotte',
        'code': '+262'
      },
      {
        'name': '+52 Mexico',
        'code': '+52'
      },
      {
        'name': '+377 Monaco',
        'code': '+377'
      },
      {
        'name': '+976 Mongolia',
        'code': '+976'
      },
      {
        'name': '+382 Montenegro',
        'code': '+382'
      },
      {
        'name': '+1664 Montserrat',
        'code': '+1664'
      },
      {
        'name': '+212 Morocco',
        'code': '+212'
      },
      {
        'name': '+95 Myanmar',
        'code': '+95'
      },
      {
        'name': '+264 Namibia',
        'code': '+264'
      },
      {
        'name': '+674 Nauru',
        'code': '+674'
      },
      {
        'name': '+977 Nepal',
        'code': '+977'
      },
      {
        'name': '+31 Netherlands',
        'code': '+31'
      },
      {
        'name': '+599 Netherlands Antilles',
        'code': '+599'
      },
      {
        'name': '+687 New Caledonia',
        'code': '+687'
      },
      {
        'name': '+64 New Zealand',
        'code': '+64'
      },
      {
        'name': '+505 Nicaragua',
        'code': '+505'
      },
      {
        'name': '+227 Niger',
        'code': '+227'
      },
      {
        'name': '+234 Nigeria',
        'code': '+234'
      },
      {
        'name': '+683 Niue',
        'code': '+683'
      },
      {
        'name': '+672 Norfolk Island',
        'code': '+672'
      },
      {
        'name': '+1 670 Northern Mariana Islands',
        'code': '+1 670'
      },
      {
        'name': '+47 Norway',
        'code': '+47'
      },
      {
        'name': '+968 Oman',
        'code': '+968'
      },
      {
        'name': '+92 Pakistan',
        'code': '+92'
      },
      {
        'name': '+680 Palau',
        'code': '+680'
      },
      {
        'name': '+507 Panama',
        'code': '+507'
      },
      {
        'name': '+675 Papua New Guinea',
        'code': '+675'
      },
      {
        'name': '+595 Paraguay',
        'code': '+595'
      },
      {
        'name': '+51 Peru',
        'code': '+51'
      },
      {
        'name': '+63 Philippines',
        'code': '+63'
      },
      {
        'name': '+48 Poland',
        'code': '+48'
      },
      {
        'name': '+351 Portugal',
        'code': '+351'
      },
      {
        'name': '+1 939 Puerto Rico',
        'code': '+1 939'
      },
      {
        'name': '+974 Qatar',
        'code': '+974'
      },
      {
        'name': '+40 Romania',
        'code': '+40'
      },
      {
        'name': '+250 Rwanda',
        'code': '+250'
      },
      {
        'name': '+685 Samoa',
        'code': '+685'
      },
      {
        'name': '+378 San Marino',
        'code': '+378'
      },
      {
        'name': '+966 Saudi Arabia',
        'code': '+966'
      },
      {
        'name': '+221 Senegal',
        'code': '+221'
      },
      {
        'name': '+381 Serbia',
        'code': '+381'
      },
      {
        'name': '+248 Seychelles',
        'code': '+248'
      },
      {
        'name': '+232 Sierra Leone',
        'code': '+232'
      },
      {
        'name': '+65 Singapore',
        'code': '+65'
      },
      {
        'name': '+421 Slovakia',
        'code': '+421'
      },
      {
        'name': '+386 Slovenia',
        'code': '+386'
      },
      {
        'name': '+677 Solomon Islands',
        'code': '+677'
      },
      {
        'name': '+27 South Africa',
        'code': '+27'
      },
      {
        'name': '+500 South Georgia and the South Sandwich Islands',
        'code': '+500'
      },
      {
        'name': '+34 Spain',
        'code': '+34'
      },
      {
        'name': '+94 Sri Lanka',
        'code': '+94'
      },
      {
        'name': '+249 Sudan',
        'code': '+249'
      },
      {
        'name': '+597 Suriname',
        'code': '+597'
      },
      {
        'name': '+268 Swaziland',
        'code': '+268'
      },
      {
        'name': '+46 Sweden',
        'code': '+46'
      },
      {
        'name': '+41 Switzerland',
        'code': '+41'
      },
      {
        'name': '+992 Tajikistan',
        'code': '+992'
      },
      {
        'name': '+66 Thailand',
        'code': '+66'
      },
      {
        'name': '+228 Togo',
        'code': '+228'
      },
      {
        'name': '+690 Tokelau',
        'code': '+690'
      },
      {
        'name': '+676 Tonga',
        'code': '+676'
      },
      {
        'name': '+1 868 Trinidad and Tobago',
        'code': '+1 868'
      },
      {
        'name': '+216 Tunisia',
        'code': '+216'
      },
      {
        'name': '+90 Turkey',
        'code': '+90'
      },
      {
        'name': '+993 Turkmenistan',
        'code': '+993'
      },
      {
        'name': '+1 649 Turks and Caicos Islands',
        'code': '+1 649'
      },
      {
        'name': '+688 Tuvalu',
        'code': '+688'
      },
      {
        'name': '+256 Uganda',
        'code': '+256'
      },
      {
        'name': '+380 Ukraine',
        'code': '+380'
      },
      {
        'name': '+971 United Arab Emirates',
        'code': '+971'
      },
      {
        'name': '+44 United Kingdom',
        'code': '+44'
      },
      {
        'name': '+1 United States',
        'code': '+1'
      },
      {
        'name': '+598 Uruguay',
        'code': '+598'
      },
      {
        'name': '+998 Uzbekistan',
        'code': '+998'
      },
      {
        'name': '+678 Vanuatu',
        'code': '+678'
      },
      {
        'name': '+681 Wallis and Futuna',
        'code': '+681'
      },
      {
        'name': '+967 Yemen',
        'code': '+967'
      },
      {
        'name': '+260 Zambia',
        'code': '+260'
      },
      {
        'name': '+263 Zimbabwe',
        'code': '+263'
      },
      {
        'name': ' land Islands',
        'code': ''
      },
      {
        'name': 'null Antarctica',
        'code': null
      },
      {
        'name': '+591 Bolivia, Plurinational State of',
        'code': '+591'
      },
      {
        'name': '+673 Brunei Darussalam',
        'code': '+673'
      },
      {
        'name': '+61 Cocos (Keeling) Islands',
        'code': '+61'
      },
      {
        'name': '+243 Congo, The Democratic Republic of the',
        'code': '+243'
      },
      {
        'name': '+225 Cote d\\\'Ivoire',
        'code': '+225'
      },
      {
        'name': '+500 Falkland Islands (Malvinas)',
        'code': '+500'
      },
      {
        'name': '+44 Guernsey',
        'code': '+44'
      },
      {
        'name': '+379 Holy See (Vatican City State)',
        'code': '+379'
      },
      {
        'name': '+852 Hong Kong',
        'code': '+852'
      },
      {
        'name': '+98 Iran, Islamic Republic of',
        'code': '+98'
      },
      {
        'name': '+44 Isle of Man',
        'code': '+44'
      },
      {
        'name': '+44 Jersey',
        'code': '+44'
      },
      {
        'name': '+850 Korea, Democratic People\\\'s Republic of',
        'code': '+850'
      },
      {
        'name': '+82 Korea, Republic of',
        'code': '+82'
      },
      {
        'name': '+856 Lao People\\\'s Democratic Republic',
        'code': '+856'
      },
      {
        'name': '+218 Libyan Arab Jamahiriya',
        'code': '+218'
      },
      {
        'name': '+853 Macao',
        'code': '+853'
      },
      {
        'name': '+389 Macedonia, The Former Yugoslav Republic of',
        'code': '+389'
      },
      {
        'name': '+691 Micronesia, Federated States of',
        'code': '+691'
      },
      {
        'name': '+373 Moldova, Republic of',
        'code': '+373'
      },
      {
        'name': '+258 Mozambique',
        'code': '+258'
      },
      {
        'name': '+970 Palestinian Territory, Occupied',
        'code': '+970'
      },
      {
        'name': '+872 Pitcairn',
        'code': '+872'
      },
      {
        'name': '+262 Réunion',
        'code': '+262'
      },
      {
        'name': '+7 Russia',
        'code': '+7'
      },
      {
        'name': '+590 Saint Barthélemy',
        'code': '+590'
      },
      {
        'name': '+290 Saint Helena, Ascension and Tristan Da Cunha',
        'code': '+290'
      },
      {
        'name': '+1 869 Saint Kitts and Nevis',
        'code': '+1 869'
      },
      {
        'name': '+1 758 Saint Lucia',
        'code': '+1 758'
      },
      {
        'name': '+590 Saint Martin',
        'code': '+590'
      },
      {
        'name': '+508 Saint Pierre and Miquelon',
        'code': '+508'
      },
      {
        'name': '+1 784 Saint Vincent and the Grenadines',
        'code': '+1 784'
      },
      {
        'name': '+239 Sao Tome and Principe',
        'code': '+239'
      },
      {
        'name': '+252 Somalia',
        'code': '+252'
      },
      {
        'name': '+47 Svalbard and Jan Mayen',
        'code': '+47'
      },
      {
        'name': '+963 Syrian Arab Republic',
        'code': '+963'
      },
      {
        'name': '+886 Taiwan, Province of China',
        'code': '+886'
      },
      {
        'name': '+255 Tanzania, United Republic of',
        'code': '+255'
      },
      {
        'name': '+670 Timor-Leste',
        'code': '+670'
      },
      {
        'name': '+58 Venezuela, Bolivarian Republic of',
        'code': '+58'
      },
      {
        'name': '+84 Viet Nam',
        'code': '+84'
      },
      {
        'name': '+1 284 Virgin Islands, British',
        'code': '+1 284'
      },
      {
        'name': '+1 340 Virgin Islands, U.S.',
        'code': '+1 340'
      }
    ];
  });
};
