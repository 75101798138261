class Controller {
  constructor($timeout, $log, products, growl, utils, $q,
    $uibModal, S3, confirmDeleteModal, fileBucketService) {
    'ngInject';

    this.$timeout = $timeout;
    this.$log = $log;
    this.growl = growl;
    this.utils = utils;
    this.$q = $q;
    this.$uibModal = $uibModal;
    this.S3 = S3;
    this.confirmDeleteModal = confirmDeleteModal;
    this.fileBucketService = fileBucketService;
  }

  $onInit() {
    this.fileBucketService.externalFiles(this.$branch.files).then(files => {
      this.files = _.isEmpty(files) ? null : files;
    });
    this.filesPath = `${this.branchName}/${this.$branch.$id}/files`;
  }

  getIcon(fileType) {
    let typeObj = _.find(this.fileTypeIcons, f => _.isString(f.type) ? f.type === fileType : f.type.test(fileType));

    return typeObj ? typeObj.icon : 'far fa-file';
  }

  open(file) {
    this.S3.openSignedUrlInNewTab(this.organization.$id, file.key);
  }

  categoryName(fileType) {
    let category = _.find(this.categories, c => c.id === fileType);

    return category ? category.text : '';
  }

  remove(file) {
    this.confirmDeleteModal(_.truncate(file.name, {length: 30}))
      .then(() => {
        this.fileBucketService.removeFileMap(this.filesPath, file.$id).then(()=>{
          return this.fileBucketService.remove(file.$id)
            .then(() => {
              return this.S3.deleteFile(this.organization.$id, file.key)
                .catch((err) => {
                  let fileId = file.$id;

                  this.fileBucketService.updateFile(file, fileId);
                  this.fileBucketService.saveFileMap(this.filesPath, file.$id);
                  file.$id = fileId;
                  return this.$q.reject(err);
                });
            });
        }).then(() => {
          delete this.files[file.$id];
          this.files = this.files.length > 0 ? this.files : null;
          this.growl.success('File successfully removed.');
        })
          .catch((err) =>
            this.utils.defaultErrorHandler(err, 'Unable to delete file.')
          );
      });
  }

  addFiles(item) {
    return this.$uibModal.open({
      component: 'cfMedia',
      controllerAs: 'vm',
      backdrop: 'static',
      resolve: {
        item: () => item ? this.fileBucketService.$get(item.$id) : this.fileBucketService.$pushFile()
          .then(fileItem => {
            fileItem.organizationId = this.organization.$id;
            return fileItem;
          }),
        remoteDirectory: () => this.remoteDirectory,
        media: () => this.files,
        organization: () => this.organization,
        askInPlan: () => this.askInPlan,
        chosenCategory: () => this.categoryId
      }
    }).result.then((file) => {
      this.files = this.files || {};
      this.fileBucketService.saveFileMap(this.filesPath, file.$id);
      this.fileBucketService.get(file.$id).then((newFile)=>{
        this.files[file.$id] = newFile;
      });
      this.growl.success('Files ' + (item ? 'updated' : 'added') + ' successfully.', {});
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new file'));
  }

  updateInPlan(file) {
    let fileId = file.$id;

    this.$timeout(() => this.fileBucketService.updateFile(file, fileId).then(() => {
      file.$id = fileId;
      this.growl.success('File Saved');
    })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to save file.')));
  }
}

module.exports = Controller;
