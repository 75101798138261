module.exports = function(organizations, $log, growl, CompanyNameSearch, $timeout) {
  'ngInject';

  const vm = this, MAX_RECS = 300;

  vm.$onInit = function() {
    vm.user = vm.resolve.user;
    vm.myOrgs = vm.user.organizations();

    vm.companyNameSearch = new CompanyNameSearch(vm.user, false, {size: MAX_RECS});
  };

  vm.chooseCompany = function(company) {
    vm.chosenCompany = company.$id;
    delete vm.roles;
    organizations.getRoles(vm.chosenCompany)
      .then(roles => $timeout(() => vm.roles = _.pickBy(roles, {visible: true})))
      .catch((err) => $log.error(err));
  };

  vm.filterResults = function(results) {
    return _.filter(results, entry => {
      return !vm.myOrgs[entry.$id];
    });
  };

  vm.ok = function() {
    vm.modalInstance.close({
      id: vm.chosenCompany,
      role: vm.orgRole
    });
  };
};
