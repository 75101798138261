'use strict';

module.exports = function (ngModule) {
  ngModule.service('criticalLimits', function ($stateParams, $firebaseArray, $q) {
    //noinspection JSLint
    'ngInject';

    var criticalLimits = [];
    return {
      list: function () {
        var deferred = $q.defer(), count = 0;

        $firebaseArray('products/' + $stateParams.productId + '/processSteps').$loaded().then(function (steps) {
          count = count + 1;
          angular.forEach(steps, function (step) {
            $firebaseArray('products/' + $stateParams.productId + '/processSteps/' + step.$id + '/criticalLimits').$loaded().then(function (limits) {
              angular.forEach(limits, function (limit) {
                criticalLimits.push(limit);
              });
            });
          });

          if (count === steps.length) {
            deferred.resolve(criticalLimits);
          }
        });

        return deferred.promise;
      },
      delete: function (criticalLimit) {
        delete criticalLimits[criticalLimit.$id];
        return criticalLimits;
      }
    };
  });
};
