'use strict';

module.exports = function(S3, growl, confirmDeleteModal, $log, $firebaseArray, $q) {
  'ngInject';

  const vm = this;
  let uploadProgress = {};

  function refreshUploadProgress() {
    if (!vm.newFiles.length) { return 0; }

    let progress = _.reduce(uploadProgress, (sum, n) => {
      return sum + n;
    }, 0);

    vm.uploadProgress = _.round(progress / vm.newFiles.length);
  }

  vm.$onInit = function() {
    vm.vvName = vm.substituteName || 'verification';
    vm.vvNameTitle = vm.vvName.charAt(0).toUpperCase() + vm.vvName.slice(1);

    vm.newFiles = [];
    vm.removeFiles = [];
    vm.existingFiles = $firebaseArray(vm.verification.$ref().child('internalDocs'));
    vm.uploadProgress = 0;
  };

  vm.save = function() {
    vm.saving = true;
    vm.verification.$save()
      .then(() => {
        const filePromises = [];

        vm.uploading = vm.newFiles.length > 0;
        uploadProgress = {};
        _.each(vm.newFiles, (file, idx) => {
          filePromises.push(
            S3.uploadOrgFile(vm.product.organizationId, 'products/' + vm.product.$id + '/verifications/' +
              vm.verification.$id + '/' + file.name, file,
              (event) => {
                uploadProgress[idx] = parseInt(100.0 * event.loaded / event.total, 10);
                refreshUploadProgress();
              })
              .then((result) => vm.existingFiles.$add(result))
          );
        });

        _.each(vm.removeFiles, (file) => {
          filePromises.push(
            S3.deleteFile(vm.product.organizationId, file.key)
              .then(() => vm.existingFiles.$remove(file))
          );
        });

        return $q.all(filePromises);
      })
      .then(() => vm.$uibModalInstance.close(vm.verification))
      .catch(err => $log.error('An error occurred saving internal verification: ', err));
  };

  vm.cancel = function() {
    vm.$uibModalInstance.dismiss('cancel');
  };
};
