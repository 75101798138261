module.exports = function(ngModule) {
  class MarketplaceServices {
    constructor($q, fbutil) {
      'ngInject';

      this.$q = $q;
      this.fbutil = fbutil;
    }

    /**
     * Get default FoodReady marketplace services
     * @returns {Object} An key/value pair of default marketplace services
     */
    getDefaultServices() {
      if (this.default) { return this.$q.when(this.default); }
      return this.fbutil.ref('marketplaceServices', 'default').once('value')
        .then(defaultSnap => {
          this.default = defaultSnap.exists() ? defaultSnap.val() : null;
          return this.default;
        });
    }

    /**
     * Get a default FoodReady marketplace service
     * @param {string} serviceId The default service ID
     * @returns {Promise<object>} The default marketplace service
     */
    getDefaultService(serviceId) {
      return this.fbutil.ref('marketplaceServices', 'default', serviceId).once('value')
        .then(this.fbutil.getValueOrDefault);
    }

    /**
     * Get an organization's services offered.
     * @param {String} orgId The organization ID
     * @returns {Object} A key/value pair of organization's marketplace services
     */
    getOrgServices(orgId) {
      return this.fbutil.ref('marketplaceServices', orgId).once('value')
        .then(this.fbutil.getValueOrDefault);
    }

    /**
     * Get an organization's service offered.
     * @param {String} orgId The organization ID
     * @param {String} serviceId The service ID
     * @returns {Promise<object>} The organization's marketplace service
     */
    getOrgService(orgId, serviceId) {
      return this.fbutil.ref('marketplaceServices', orgId, serviceId).once('value')
        .then(this.fbutil.getValueOrDefault);
    }

    /**
     * Update an organization's marketplace services
     * @param {string} orgId The organization ID
     * @param {string} serviceId The service ID
     * @param {Object} service The service data
     * @returns {Object} The result of the update operation
     */
    update(orgId, serviceId, service) {
      return this.fbutil.ref('marketplaceServices', orgId, serviceId).set(service);
    }

    /**
     * Add a custom organization service to the service marketplace
     * @param {string} orgId The organization ID
     * @param {Object} service The marketplace services to add
     * @returns {Object} The result of the push operation
     */
    add(orgId, service) {
      return this.fbutil.ref('marketplaceServices', orgId).push(service);
    }

    remove(orgId, serviceId) {
      return this.fbutil.ref('marketplaceServices', orgId, serviceId).remove();
    }

    setEnabled(orgId, serviceId, enabled) {
      return this.fbutil.ref('marketplaceServices', orgId, serviceId, 'enabled').set(enabled);
    }
  }

  ngModule.service('marketplaceServices', MarketplaceServices);
};
