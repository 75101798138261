require('./operations.sop-logs.types.curing-chamber.scss');

module.exports = function(ngModule) {
  ngModule.component('cfLogTypeCuringChamber', {
    bindings: {
      user: '<',
      $sop: '<',
      $sopLog: '='
    },
    template: require('./operations.sop-logs.types.curing-chamber.template.html'),
    controller: require('./operations.sop-logs.types.curing-chamber.controller'),
    controllerAs: 'vm'
  });
};
