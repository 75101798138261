class Constructor {
  constructor(sopService, $q, $state, growl, moment, procedureDurationUnits, users,
              confirmDeleteModal, utils, $log) {
    'ngInject';

    this.sopService = sopService;
    this.$q = $q;
    this.$state = $state;
    this.growl = growl;
    this.moment = moment;
    this.procedureDurationUnits = procedureDurationUnits;
    this.users = users;
    this.confirmDeleteModal = confirmDeleteModal;
    this.utils = utils;
    this.$log = $log;
  }

  $onInit() {
    // addToHomescreen.removeSession(); // Uncomment to force addToHomescreen
    // Prompt mobile users to add CF to their home screen.
    /* eslint no-undef: 0 */
    addToHomescreen({
      maxDisplayCount: 1
    });
    this.assignedLengthsObj = {};
    this.loadAdditional();
  }

  loadAdditional() {
    this.anyProducts = _.some(this.sops,
        recMgmt => recMgmt.parent.scope === this.sopService.scopes.PRODUCT);

    if (!this.anyProducts) { return; }
    _.each(this.sops, sop => {
      sop.parentText = sop.parent.scope === this.sopService.scopes.ORGANIZATION ? '' :
        sop.parent.name;
    });
  }

  frequencyString(record) {
    if (!record.enforcedFrequency) { return record.frequency; }
    return record.enforcedFrequency.amount + ' ' +
      _.find(this.procedureDurationUnits, x => x.val === record.enforcedFrequency.units).text;
  }

  isInPast(date) {
    return date < new Date().getTime();
  }

  assignedLengths(procedure) {
    if (this.assignedLengthsObj[procedure.$id]) { return this.assignedLengthsObj[procedure.$id];}
    this.assignedLengthsObj[procedure.$id] = _.keys(procedure.assignedToUsers).length;
    return this.assignedLengthsObj[procedure.$id];
  }

  newEntry(procedure) {
    this.$state.go(`procedure.entry.main.${procedure.template}`, {
      entryId: this.sopService.pushRecord().key,
      procedureId: procedure.$id,
      returnState: 'user.myRecords.list'
    });
  }
}

module.exports = Constructor;
