module.exports = function (ngModule) {
  class Billing {
    constructor($q, $window, $http, moment, fbutil) {
      'ngInject';

      this.$q = $q;
      this.fbutil = fbutil;
      this.$http = $http;
      this.Stripe = $window.Stripe;
      this.moment = moment;

      // Statuses are defined here: https://stripe.com/docs/api#subscription_object
      this.subscriptionStatuses = {
        TRIALING: 'trialing',
        ACTIVE: 'active',
        PAST_DUE: 'past_due',
        CANCELED: 'canceled',
        MISSING: 'missing', // This is a status defined by CF, not a stripe status
        UNPAID: 'unpaid',
      };
    }

    static getBaseUri(orgId) {
      return orgId + '/customers';
    }

    createToken(cardData) {
      return this.$q((resolve, reject) => {
        this.Stripe.card.createToken(cardData, (status, response) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response.id);
          }
        });
      });
    }
    /* eslint-disable camelcase */
    createAchToken(achData) {
      return this.$q((resolve, reject) => {
        this.Stripe.bankAccount.createToken(
          {
            country: achData.country,
            currency: achData.currency,
            routing_number: achData.routingNumber,
            account_number: achData.accountNumber,
            account_holder_name: achData.accountHolderName,
            account_holder_type: achData.accountHolderType,
          },
          (status, response) => {
            if (response.error) {
              reject(response.error);
            } else {
              resolve(response.id);
            }
          }
        );
      });
    }

    /**
     * Convert the CF amount representation to the representation expected by Stripe. Stripe uses cents ("100" = $1.00).
     * @param {number} amount  Internal amount representation
     * @return {number} Stripe representation
     */
    toStripeAmountFormat(amount) {
      return Math.round(amount * 100);
    }

    getCustomer(orgId, customerId) {
      return this.$http.get(Billing.getBaseUri(orgId) + '/' + customerId).then((result) => result.data);
    }

    customerHasDefaultSource(orgId, customerId) {
      return this.$http
        .head(Billing.getBaseUri(orgId) + '/' + customerId + '/sources?default=true')
        .then((result) => result.status === 200);
    }

    /**
     * Create a payment system customer.
     * @param {string} orgId The user's primary organization ID
     * @param {object} args The customer information
     * @param {string} args.email The customer's email address
     * @param {object} args.metadata Metadata associated with the customer / user (e.g. {userId: user.uid})
     * @return {Promise<object>} A promise with the result of the create operation
     */
    createCustomer(orgId, args) {
      return this.$http.post(Billing.getBaseUri(orgId), args).then((result) => result.data);
    }

    /**
     * Add a CC to an existing customer.
     * @param {string} orgId The user's primary organization ID
     * @param {string} customerId The payment system customer ID
     * @param {object} card The credit card information
     * @param {string} card.number The credit card number
     * @param {number} card.cvc The credit card cvc
     * @param {string} card.name The name on the credit card
     * @param {string} card.address_line1 The address (line 1) associated with credit card
     * @param {string} card.address_line2 The address (line 2) associated with credit card
     * @param {string} card.city The city associated with credit card
     * @param {string} card.countryName The country associated with credit card
     * @param {string} card.address_state The state associated with credit card
     * @param {string} card.zip The zip associated with credit card
     * @param {number} card.exp_month The expiration month of the credit card
     * @param {number} card.exp_year The expiration year of the credit card
     * @param {object} metadata Metadata associated with the card (e.g. {nickname: 'My Card'})
     * @return {number} A promise with the result of the add card operation
     */
    addCard(orgId, customerId, card, metadata) {
      return this.createToken(card)
        .then((token) =>
          this.$http.post(Billing.getBaseUri(orgId) + '/' + customerId + '/sources', {
            source: token,
            metadata: metadata,
          })
        )
        .then((result) => result.data);
    }

    addAch(orgId, customerId, ach, metadata) {
      return this.createAchToken(ach)
        .then((token) =>
          this.$http.post(Billing.getBaseUri(orgId) + '/' + customerId + '/sources', {
            source: token,
            metadata: metadata,
          })
        )
        .then((result) => result.data);
    }

    deleteCard(orgId, customerId, cardId) {
      return this.$http
        .delete(Billing.getBaseUri(orgId) + '/' + customerId + '/cards/' + cardId)
        .then((result) => result.data);
    }

    deleteAch(orgId, customerId, achId) {
      return this.$http
        .delete(Billing.getBaseUri(orgId) + '/' + customerId + '/sources/' + achId)
        .then((result) => result.data);
    }

    verifyAch(orgId, customerId, achId, amounts) {
      return this.$http
        .post(Billing.getBaseUri(orgId) + '/' + customerId + '/sources/' + achId + '/verify', {
          amounts: _.map(amounts, (a) => this.toStripeAmountFormat(a)),
        })
        .then((result) => result.data);
    }

    /**
     * Charge a customer.
     * @param {string} orgId The provider organization ID
     * @param {string} customerId The payment system customer ID
     * @param {object} args The payment arguments
     * @param {number} args.amount The charge amount
     * @param {string} args.source The charge source ID (e.g. CC source ID)
     * @param {string} args.currency The currency (e.g. 'usd')
     * @param {string} args.receipt_email Where to send the receipt
     * @param {object} args.metadata Payment metadata (e.g. {orderId: 123, itemId: 456)})
     * @return {Promise<object>} A promise with the result of the charge operation
     */
    chargeCustomer(orgId, customerId, args) {
      return this.$http
        .post(Billing.getBaseUri(orgId) + '/' + customerId + '/charges', args)
        .then((result) => result.data);
    }

    /**
     * Refund a charge.
     * @param {string} orgId Organization Id
     * @param {string} customerId Customer Id
     * @param {string} chargeId The charge Id to refund
     * @return {Promise<object>} A promise with the result of the refund operation
     */
    refundCharge(orgId, customerId, chargeId) {
      return this.$http
        .delete(Billing.getBaseUri(orgId) + '/' + customerId + '/charges/' + chargeId)
        .then((result) => result.data);
    }

    getCoupon(couponId) {
      return this.$http.get('payment/coupons/' + couponId).then((result) => result.data);
    }

    getSku(skuId) {
      return this.$http.get('payment/skus/' + skuId).then((result) => result.data);
    }

    getProductName(productId) {
      return this.$http.get(`payment/products/${productId}/name`).then((result) => result.data);
    }

    getPlans(orgId) {
      return this.$http.get(orgId + '/plans').then((result) => result.data);
    }

    getPlan(orgId, planId) {
      return this.$http.get(`${orgId}/plans/${planId}`).then((result) => result.data);
    }

    createSubscription(orgId, customerId, planId, opts) {
      let quantity = opts.quantity;

      delete opts.quantity;
      let payload = _.assign({items: [{plan: planId, quantity: quantity}]}, opts);

      return this.$http
        .post(Billing.getBaseUri(orgId) + '/' + customerId + '/subscriptions', payload)
        .then((result) => result.data);
    }

    cancelSubscription(orgId, subscriptionId) {
      return this.$http.delete(orgId + '/subscriptions/' + subscriptionId).then((result) => result.data);
    }

    changeSubscription(orgId, subscriptionId, planId) {
      return this.$http.post(`${orgId}/subscriptions/${subscriptionId}/plan`, {planId}).then(() => planId);
    }

    fetchSubscription(orgId, subscriptionId) {
      return this.$http.get(orgId + '/subscriptions/' + subscriptionId).then((result) => result.data);
    }

    /**
     * Update an organization's subscription.
     * @param {string} orgId Organization's orgId.
     * @param {string} subscriptionId Payment processor's subscriptionId.
     * @param {object} parms Properties to change in the subscription.
     * @return {Promise} A promise that resolves to the updated subscription.
     */
    updateSubscription(orgId, subscriptionId, parms) {
      return this.$http.put(orgId + '/subscriptions/' + subscriptionId, parms).then((result) => result.data);
    }

    updateCustomer(orgId, customerId, parms) {
      return this.$http.put(orgId + '/customers/' + customerId, parms);
    }

    cardExpired(month, year) {
      return this.moment(month + '-1-' + year, 'MM-DD-YYYY').isBefore(this.moment());
    }

    // if the `angular-payment` directive(s) are used for collecting payment information, we need to transform
    // a few of the card properties into fields compatible with Stripe.
    dehydrateCard(card) {
      card.address_country = card.addressCountry.countryName;
      const result = _.pick(card, [
        'number',
        'cvc',
        'name',
        'address_line1',
        'address_line2',
        'address_city',
        'address_country',
        'address_zip',
      ]);

      result['address_state'] = card.address_state || card.state.abbreviation;
      result['exp_month'] = card.exp_month || card.expiry.month;
      result['exp_year'] = card.exp_year || card.expiry.year;

      return result;
    }

    isAch(source) {
      return source.object === 'bank_account';
    }

    getSourceDescription(source) {
      const nickname = _.get(source, 'metadata.nickname') || '';

      return (
        nickname +
        (nickname && ' (') +
        (this.isAch(source) ? 'bank account' : source.brand) +
        ' ending in ' +
        source.last4 +
        (nickname && ')')
      );
    }

    /**
     * Return human-readable text describing the cost of a subscription.
     * @param {object} plan The plan for which to generate subscription price text.
     * @return {string} Text for the subscription cost.
     */
    getSubscriptionPriceText(plan) {
      if (!plan || !plan.amount) {
        return '';
      }
      let interval;

      switch (plan.interval) {
      case 'month':
        interval = plan.interval_count === 1 ? ' /mo' : ' semi-annual';
        break;
      case 'year':
        interval = ' /yr';
        break;
      default:
        return '';
      }

      return `$${plan.amount / 100} ${interval}`;
    }

    getPriceForTier(plan, noOfUsers) {
      if (!plan || !noOfUsers || !plan.tiers) {
        return 0;
      }
      let tierAmount = plan.tiers[0].amount;

      if (noOfUsers > 1) {
        let tierDetails = plan.tiers.find((tier) => tier.up_to === noOfUsers);

        if (!tierDetails) {
          tierDetails = plan.tiers.find((tier) => tier.up_to === null);
        }
        tierAmount += tierDetails.amount * (noOfUsers - 1);
      }

      return tierAmount;
    }

    getDiscountCoupon(orgId, type) {
      return this.$http.get(orgId + '/coupon/type=' + type).then((result) => result.data);
    }

    listInvoices(orgId, subscriptionId) {
      return this.$http.get(`${orgId}/invoices/${subscriptionId}`).then((result) => result.data);
    }
  }

  ngModule.service('billing', Billing);
};
