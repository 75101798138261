'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfSupplierFiles', {
    bindings: {
      supplier: '=',
      files: '=',
      organization: '<',
      user: '<'
    },
    template: require('./ingredients.supplier.files.template.html'),
    controller: require('./ingredients.supplier.files.controller.js'),
    controllerAs: 'vm'
  });
};
