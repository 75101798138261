'use strict';

module.exports = function (ngModule) {
  ngModule.component('cfOrganization', {
    bindings: {
      organization: '<',
      analystOrgs: '<',
    },
    template: require('./administration.organization.template.html'),
    controller: require('./administration.organization.controller'),
    controllerAs: 'vm',
  });

  require('./overview')(ngModule);
  require('./contact')(ngModule);
  require('./create_new')(ngModule);
  require('./users')(ngModule);
};
