require('./reports.company-reports.template.scss');

module.exports = function(ngModule) {
  ngModule.component('cfCompanyReports', {
    bindings: {
      user: '<',
      $company: '<',
      $product: '<'
    },
    template: require('./reports.company-reports.template.html'),
    controller: require('./reports.company-reports.controller'),
    controllerAs: 'vm'
  });
};
