class Controller {
  constructor(preventDirtyNav, $timeout, growl, $log, S3, recallEventsService, $q, supplierIngredientService,
              batchService, $scope) {
    'ngInject';

    this.preventDirtyNav = preventDirtyNav;
    this.$timeout = $timeout;
    this.growl = growl;
    this.$log = $log;
    this.S3 = S3;
    this.recallEventsService = recallEventsService;
    this.$q = $q;
    this.supplierIngredientService = supplierIngredientService;
    this.batchService = batchService;
    this.$scope = $scope;
  }

  $onInit() {
    this.$timeout(() => this.preventDirtyNav(this.eventLotNumberForm, 'Your recall lot number changes are not saved.',
      () => this.save()));

    this.uploadProgress = 0;
    this.newLotFiles = [];
    this.removeLotFiles = [];
    this.existingLotFiles = this.recallEventsService.$recallLotFiles(this.$event);
    this.$event.lotNumberSystem = this.$event.lotNumberSystem || 'Food Product Name - Date - Item Number';
    this.checked = false;
    if(this.$event && this.$event.supplierIngredientId) {
      this.searchSupplierIngredient();
      this.lotNum(this.$event.supplierIngredientId);
    }else {
      this.checked = true;
    }
  }

  searchSupplierIngredient() {
    this.supplierIngredientService.getAllSupplierIngredients(this.user.orgContext.id).then(supplierIngredientslist => {
      if (supplierIngredientslist || supplierIngredientslist.length) {
        this.supplierIngredientslist = supplierIngredientslist;
      }
    });
  }

  lotNum(supplierIngredientId) {
    this.supplierIngredientService.get(supplierIngredientId).then(supplierIngredient => {
      if (supplierIngredient.transactions) {
        this.lotNumbers = _.reduce(supplierIngredient.transactions,(r,v)=>{
          (r[v.lotNumber] || (r[v.lotNumber] = [])).push(v.batchRunId);
          return r;
        },{});
        this.lotNumbers = _.map(this.lotNumbers,(val,key)=> {
          return{'batchRunId': _.uniq(val),'lotNumber': key};
        });
      }
    });
  }

  batchData(batchRunIds) {
    if(batchRunIds) {
      this.$event.batchRunId = batchRunIds.filter(id =>_.some(this.batchRuns, {'batchRunId': id}));
    }
  }

  clearValue() {
    this.$event.batchRunId = null;
    this.$event.supplierIngredientId = null;
    this.$event.ingredientLotNumber = null;
  }

  save() {
    this.$event.$save().then(() => {
      let filePromises = [];

      this.uploadProgress = 0;
      this.uploadProgressObj = {};
      _.each(this.newLotFiles, (file, idx) => {
        filePromises.push(
          this.S3.uploadOrgFile(this.company.$id, `/recallLotFiles/${file.name}`, file,
            (event) => {
              this.uploadProgressObj[idx] = parseInt(100.0 * event.loaded / event.total, 10);
              this._refreshUploadProgress();
            }).then((result) => this.existingLotFiles.$add(result))
        );
      });

      _.each(this.removeLotFiles, (file) => {
        filePromises.push(
          this.S3.deleteFile(this.company.$id, file.key).then(() => {
            return this.existingLotFiles.$remove(file);
          })
        );
      });

      return this.$q.all(filePromises);
    }).then(() => {
      this.removeLotFiles = [];
      this.newLotFiles = [];
      this.uploadProgress = 0;
      if (this.eventLotNumberForm) { this.eventLotNumberForm.$setPristine(); }

      this.growl.success('Recall lot numbers saved.');
      this.$log.info('Recall lot numbers saved.');
    });
  }

  _refreshUploadProgress() {
    if (!this.newLotFiles.length) {
      return 0;
    }

    let progress = _.reduce(this.uploadProgressObj, (sum, n) => {
      return sum + n;
    }, 0);

    this.uploadProgress = _.round(progress / this.newLotFiles.length);
  }
}

module.exports = Controller;
