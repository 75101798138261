module.exports = function(ngModule) {
  class MessageServices {
    constructor(fbutil, $firebaseObject, $firebaseArray, $uibModal, $log, $http, users, organizations, $q) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseObject = $firebaseObject;
      this.$firebaseArray = $firebaseArray;
      this.$uibModal = $uibModal;
      this.$log = $log;
      this.$http = $http;
      this.users = users;
      this.organizations = organizations;
      this.$q = $q;
      this.USER_TYPE = 1;
      this.ORG_TYPE = 2;
    }

    $getTopic(messageId) {
      return this.$firebaseObject(this.fbutil.ref('messageTopics', messageId)).$loaded();
    }

    getTopic(messageId) {
      return this.fbutil.ref('messageTopics', messageId).once('value').then(this.fbutil.getValueOrDefault);
    }

    $getMessages(messageId, limit) {
      return this.$firebaseArray(this.fbutil.ref('messages', messageId).child('chats').orderByChild('createdOn')
        .limitToLast(limit)).$loaded();
    }

    /**
     * Starts a watcher on the 'lastmessageSent' property. When a message is sent (and the property updated), indicate that
     * this user has seen the new message so that no notifications are sent.
     * @param {object} $topic  Topic to monitor ($firebaseObject)
     * @param {object} user  User who is monitoring the topic
     * @returns {function}  A function that will unregister the watcher.
     */
    startTopicMonitoring($topic, user) {
      let $lastMessage = this.$firebaseObject($topic.$ref().child('lastMessageSent')), myOrg = user.orgContext.id;

      return $lastMessage.$watch(() => {
        $topic.participatingOrgs = $topic.participatingOrgs || {};
        $topic.participatingUsers = $topic.participatingUsers || {};

        // First check if the user is a member of a participating org. If so, monitor at the org level only.
        if ($topic.participatingOrgs[myOrg]) {
          if ($lastMessage.$value) {
            $topic.$ref().child('participatingOrgs').child(myOrg).set($lastMessage.$value);
          }
        } else if ($lastMessage.$value) {
          $topic.$ref().child('participatingUsers').child(user.uid).set($lastMessage.$value);
        }
      });
    }

    /**
     * Does this topic have a message unread by the user?
     * @param {$firebaseObject} $topic Topic FB Object.
     * @param {object} user User object
     * @return {boolean}  Returns true if there are messages unread by the user.
     */
    hasUnreadMessage($topic, user) {
      $topic.participatingOrgs = $topic.participatingOrgs || {};
      $topic.participatingUsers = $topic.participatingUsers || {};

      if ($topic.participatingOrgs[user.orgContext.id]) {
        return $topic.lastMessageSent > $topic.participatingOrgs[user.orgContext.id];
      } else {
        return $topic.lastMessageSent > $topic.participatingUsers[user.uid];
      }
    }

    /**
     * Add a new topic to the messageTopics collection.
     * @param {object} user  User who initiated the message topic.
     * @param {string} title Topic title
     * @param {[string]} [users]  Participating users.
     * @param {[string]} [orgs]  Participating organizations.
     * @returns {object}  A new message topic
     */
    $pushTopic(user, title, users, orgs) {
      users = users || [];
      orgs = orgs || [];
      let newTopic = {
        createdOn: new Date().getTime(),
        createdBy: user.uid,
        title: title,
        participatingUsers: {},
        participatingOrgs: {}
      };

      if (_.indexOf(orgs, user.orgContext.id) === -1 && _.indexOf(users, user.uid) === -1) {
        users.push(user.uid);
      }
      _.each(users, u => {
        newTopic.participatingUsers[u] = true;
      });
      _.each(orgs, org => {
        newTopic.participatingOrgs[org] = true;
      });
      return this.$pushNewMessagesId(newTopic.participatingOrgs, newTopic.participatingUsers).then(($message)=>{
        newTopic.messageId = $message.$id;
        return this.fbutil.ref('messageTopics').push(newTopic)
          .then(newRef => {
            return this.$firebaseObject(newRef).$loaded();
          });
      });
    }

    $pushNewMessagesId(participatingOrgs, participatingUsers) {
      return this.fbutil.ref('messages').push({participatingOrgs, participatingUsers})
        .then(newRef => {
          return this.$firebaseObject(newRef).$loaded();
        });
    }

    /**
     * Soft delete the message topic.
     * @param {string} topicId - The topic Id
     * @return {Promise} - A promise that is resolved when the topic is removed.
     */
    deleteTopic(topicId) {
      return this.$q.when([
        this.fbutil.ref('messageTopics', topicId, 'deleted').set(new Date().getTime()),
        this.fbutil.ref('messageTopics', topicId, 'deletedOn').set(new Date().getTime()),
      ]);
    }

    /**
     * Remove Participating org from a Message topic.
     * @param {string} messageTopicId - The topic Id
     * @param {string} orgId - The Participating org id
     * @return {Promise} - A promise that is resolved when a Participating org is removed.
     */
    removeParticipatingOrg(messageTopicId,orgId) {
      return this.fbutil.ref(`messageTopics/${messageTopicId}/participatingOrgs/${orgId}`).remove();
    }

    /**
     * Remove Participating User/s from a Message topic.
     * @param {string} messageTopicId - The topic Id
     * @param {string[]} userIds - list of user id/s
     * @return {Promise} - A promise that is resolved when Participating User/s are removed.
     */
    removeParticipatingUsers(messageTopicId, userIds) {
      return this.$q.all(_.map(userIds, (userId)=> {
        return this.fbutil.ref(`messageTopics/${messageTopicId}/participatingUsers/${userId}`).remove();
      }));
    }

    /**
     * Remove Participating org from a chat Message
     * @param {string} messageId - The topic Id
     * @param {string} orgId - The Participating org id
     * @return {Promise} - A promise that is resolved when a Participating org is removed.
     */
    removeMessageParticipatingOrg(messageId, orgId) {
      return this.fbutil.ref(`messages/${messageId}/participatingOrgs/${orgId}`).remove();
    }

    /**
     * Remove Participating User/s from a chat Message.
     * @param {string} messageId - The topic Id
     * @param {string[]} userIds - list of user id/s
     * @return {Promise} - A promise that is resolved when Participating User/s are removed.
     */
    removeMessageParticipatingUsers(messageId, userIds) {
      return this.$q.all(_.map(userIds, (userId)=> {
        return this.fbutil.ref(`messages/${messageId}/participatingUsers/${userId}`).remove();
      }));
    }

    /**
     * Remove Participating org from a Message topic.
     * @param {string} messageTopicId - The topic Id
     * @param {string} orgId - The Participating org id
     * @return {Promise} - A promise that is resolved when a Participating org is removed.
     */
    pushParticipatingOrg(messageTopicId,orgId) {
      return this.fbutil.ref(`messageTopics/${messageTopicId}/participatingOrgs/${orgId}`).set(true);
    }

    /**
     * Remove Participating User/s from a Message topic.
     * @param {string} messageTopicId - The topic Id
     * @param {string[]} userIds - list of user id/s
     * @return {Promise} - A promise that is resolved when Participating User/s are removed.
     */
    pushParticipatingUsers(messageTopicId, userIds) {
      return this.$q.all(_.map(userIds, (userId)=> {
        return this.fbutil.ref(`messageTopics/${messageTopicId}/participatingUsers/${userId}`).set(true);
      }));
    }

    /**
     * Remove Participating org from a chat Message
     * @param {string} messageId - The topic Id
     * @param {string} orgId - The Participating org id
     * @return {Promise} - A promise that is resolved when a Participating org is removed.
     */
    pushMessageParticipatingOrg(messageId, orgId) {
      return this.fbutil.ref(`messages/${messageId}/participatingOrgs/${orgId}`).set(true);
    }

    /**
     * Remove Participating User/s from a chat Message.
     * @param {string} messageId - The topic Id
     * @param {string[]} userIds - list of user id/s
     * @return {Promise} - A promise that is resolved when Participating User/s are removed.
     */
    pushMessageParticipatingUsers(messageId, userIds) {
      return this.$q.all(_.map(userIds, (userId)=> {
        return this.fbutil.ref(`messages/${messageId}/participatingUsers/${userId}`).set(true);
      }));
    }

    getUnreadMessageTopics() {
      return this.$http.get('/messages?filter=unread').then(result => result.data);
    }

    /**
     * Get all message topics that involve a given organization.
     * @param {string} orgId Organization ID.
     * @return {Array<string>} An array of topic Ids.
     */
    getTopicsWithOrg(orgId) {
      return this.$http.get('/messages?includesOrg=' + orgId).then(result => result.data);
    }

    /**
     * Gets the topic between two organizations. If one does not exist, it is created.
     * @param {object} user - Logged in user.
     * @param {string} orgId - The organization to which the user wants to establish a message topic.
     * @return {Promise} - A promise that is resolved when the topic is created.
     */
    getOrgToOrgTopic(user, orgId) {
      return this.organizations.getOrgMessageTopic(user, orgId)
        .then(topicId => {
          if (topicId) { return topicId; }

          // Create a new message topic and save a pointer for both organizations.
          return this.$pushTopic(user, 'One-on-one message', null, [user.orgContext.id, orgId])
            .then($messageTopic => {
              const topicId = $messageTopic.$id;

              $messageTopic.$destroy();
              return this.organizations.saveOrgMessageTopic(user, orgId, topicId).then(() => topicId);
            });
        });
    }

    /**
     * Delete an org-to-org message topic.
     * @param {object} user - Logged in user
     * @param {string} orgId - The org Id of the other org in the topic.
     * @return {Promise.<void>} - A promise that is resolved when the topic is deleted from both orgs.
     */
    deleteOrgToOrgTopic(user, orgId) {
      return this.organizations.getOrgMessageTopic(user, orgId).then(topicId => {
        return this.organizations.deleteOrgMessageTopic(user, orgId).then(() => {
          if (!topicId) { return; }
          return this.deleteTopic(topicId);
        });
      });
    }

    /**
     * For the last message received in the topic, return an html string to summarize the message.
     * @param {Array<string>} messages - An array of messages.
     * @return {Promise} - A promise that resolves to an html string.
     */
    lastMessageSummaryHtml(messages) {
      let lastMessage = _.maxBy(messages, message => message.createdOn);

      if (!lastMessage) { return this.$q.when(null); }
      switch(lastMessage.createdByType) {
      case this.USER_TYPE:
        return this.users.getProfile(lastMessage.createdBy).then(profile => {
          return '<div><b>' + profile.name + '</b></div>' +
            '<div>' + lastMessage.content + '</div>';
        });
      case this.ORG_TYPE:
        return this.organizations.getMiniProfile(lastMessage.createdBy).then(profile => {
          return '<div><b>' + profile.name + '</b></div>' +
            '<div>' + lastMessage.content + '</div>';
        });
      default:
        throw new Error('Invalid type');
      }
    }
  }

  ngModule.service('messageServices', MessageServices);
};
