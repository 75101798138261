'use strict';

module.exports = function($log, $timeout, $state, preventDirtyNav, growl, $firebaseArray, S3, $q, fbutil) {
  'ngInject';

  let vm = this, uploadProgress = {};

  function refreshUploadProgress() {
    if (!vm.newFiles.length) {
      return 0;
    }

    var progress = _.reduce(uploadProgress, (sum, n) => {
      return sum + n;
    }, 0);

    vm.uploadProgress = _.round(progress / vm.newFiles.length);
  }

  // Disable weekend selection
  function disabled(data) {
    var date = data.date,
      mode = data.mode;

    return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
  }

  function updateAuditFiles() {
    var filePromises = [];

    vm.uploading = vm.newFiles.length > 0;
    _.each(vm.newFiles, (file, idx) => {
      filePromises.push(
        S3.uploadOrgFile(vm.company.$id, 'suppliers/' + vm.supplier.$id + '/auditMedia/' + file.name,
          file, (event) => {
            uploadProgress[idx] = parseInt(100.0 * event.loaded / event.total, 10);
            refreshUploadProgress();
          })
          .then((result) => {
            return vm.existingFiles.$add(result);
          })
      );
    });

    _.each(vm.removeFiles, (file) => {
      filePromises.push(
        S3.deleteFile(vm.company.$id, file.key).then(() => {
          return vm.existingFiles.$remove(file);
        })
      );
    });

    return $q.all(filePromises)
      .finally(() => {
        vm.uploading = false;
      });
  }

  function getDayClass(data) {
    var date = data.date,
      mode = data.mode;

    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

      for (var i = 0; i < vm.events.length; i++) {
        var currentDay = new Date(vm.events[i].date).setHours(0, 0, 0, 0);

        if (dayToCheck === currentDay) {
          return vm.events[i].status;
        }
      }
    }

    return '';
  }


  vm.$onInit = function() {
    vm.uploadProgress = 0;
    vm.newFiles = [];
    vm.removeFiles = [];
    vm.uploadsInProgress = [];
    vm.supplier.auditDate = vm.supplier.auditDate || new Date().getTime();
    $timeout(() => {
      preventDirtyNav(vm.supplierAuditForm, 'You have unsaved audit changes.', vm.save);
    });

    $firebaseArray(fbutil.ref('suppliers', vm.supplier.$id, 'auditMedia')).$loaded()
      .then((files) => {
        vm.existingFiles = files;
      });

    vm.auditTypes = [
      {id: 'certification', name: 'Certification'},
      {id: 'inPerson', name: 'In Person'},
      {id: 'selfAssessment', name: 'Self Assessment'}
    ];

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: true
    };

    vm.dateOptions = {
      dateDisabled: disabled,
      formatYear: 'yy',
      maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1
    };

    vm.toggleMin();

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {opened: false};

    var tomorrow = new Date(),
      afterTomorrow = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);
    afterTomorrow.setDate(tomorrow.getDate() + 1);

    vm.events = [
      {date: tomorrow, status: 'full'},
      {date: afterTomorrow, status: 'partially'}
    ];
  };

  vm.toggleMin = function() {
    vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
    vm.dateOptions.minDate = vm.inlineOptions.minDate;
  };

  vm.open1 = function() {
    vm.popup1.opened = true;
  };

  vm.save = function() {
    vm.supplier.auditDate = new Date(vm.supplier.auditDate).getTime();
    vm.supplier.$save()
      .then(() => {
        return updateAuditFiles();
      })
      .then(() => {
        if (vm.supplierAuditForm) { vm.supplierAuditForm.$setPristine(); }
        growl.success('Supplier audit saved successfully!');
      })
      .catch((err) => {
        $log.error(err);
        growl.error('Unable to save supplier audit. Please try again or audit FoodReady customer support.');
      });
  };

  vm.cancel = function() {
    if (vm.supplierAuditForm) {
      vm.supplierAuditForm.$setPristine();
    }

    vm.close();
  };

  vm.close = function() {
    $state.go('ingredients.suppliers.list');
  };
};
