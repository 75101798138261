module.exports = function (
  $state,
  $log,
  signup,
  growl,
  CompanyNameSearch,
  organizations,
  $timeout,
  utils,
  authorization,
  orgInteractionService
) {
  'ngInject';

  const vm = this,
    MAX_RECS = 300;

  function setCompany(id, name) {
    vm.chosenCompany = id;
    vm.chosenCompanyName = name;

    organizations.getRoles(vm.chosenCompany).then((roles) => {
      $timeout(() => {
        vm.roles = _.pickBy(roles, (r) => r.visible);
      });
    });
  }

  vm.$onInit = function () {
    vm.user = vm.resolve.user;
    vm.passcode = '1234';
    vm.$state = $state;
    vm.roles = {};

    vm.isCfAdmin = vm.user.hasPermission(authorization.claims.CF_ADMIN);
    if (vm.isCfAdmin) {
      vm.companyNameSearch = new CompanyNameSearch(vm.user, false, {size: MAX_RECS});
      vm.search();
    } else {
      setCompany(vm.resolve.user.currentOrgContext().id, vm.resolve.user.currentOrgContext().companyName);
    }
  };

  vm.createUser = function () {
    vm.adding = true;
    const contact = {
      firstName: vm.firstName,
      lastName: vm.lastName
    };

    let passcode = Number(vm.passcode);

    return orgInteractionService.addNonEmailUser(vm.chosenCompany, contact, vm.orgRole, passcode)
      .then(() => this.modalInstance.close({contact: contact, companyName: vm.chosenCompanyName, passcode: vm.passcode}))
      .catch((err) => utils.defaultErrorHandler(err, 'Unable to Add user.'))
      .finally(() => {
        this.adding = false;
      });
  };

  vm.chooseCompany = function (company) {
    setCompany(company.$id, company.companyName);
  };

  vm.search = function (searchText) {
    vm.loading = vm.searching = true;
    return vm.companyNameSearch
      .search(searchText)
      .then((results) => {
        if (!vm.resolve.currentOrgIds) {
          vm.companyResults = results;
          return;
        }

        vm.companyResults = _.filter(results, (org) => {
          return !vm.resolve.currentOrgIds[org.payload.id];
        });
      })
      .catch(function (err) {
        growl.error('An error occurred searching company names.');
        $log.error(err);
      })
      .finally(() => {
        vm.loading = vm.searching = false;
        vm.maxReached = vm.companyNameSearch.searchResults.length >= MAX_RECS;
      });
  };

  vm.getMore = function () {
    vm.loading = true;
    vm.companyNameSearch
      .getMore()
      .then(() => {
        vm.companyResults = vm.companyNameSearch.searchResults;
      })
      .finally(() => {
        vm.maxReached = vm.companyNameSearch.searchResults.length >= MAX_RECS;
        vm.loading = false;
      });
  };

  vm.reset = function () {
    vm.chosenCompany = null;
    vm.orgRole = null;
  };
};
