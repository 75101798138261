module.exports = function (
  $state,
  fbutil,
  $firebaseArray,
  confirmDeleteModal,
  ENV,
  $uibModal,
  $log,
  $q,
  utils,
  authorization,
  growl,
  confirmModal,
  urlTokens,
  INVITE_EXPIRE_DAYS,
  subscriptionService,
  $timeout,
  constantsService,
  orgInteractionService,
) {
  'ngInject';

  const vm = this;

  vm.$state = $state;

  vm.$onInit = function () {
    vm.totalUsers = _.get(vm.users, 'searchResults.length', 0);
    vm.utils = utils;
    vm.isCfAdmin = vm.user.isCfAdmin();

    const stripeProductId = vm.user.subscription.plan.product;

    vm.userConstraint = _.get(vm.productConstraints, stripeProductId + '.rateConstants.USERS');
    if (_.isUndefined(vm.userConstraint)) {
      $log.warn(`User constraints not defined for stripe product: ${stripeProductId}`);
      vm.userConstraint = -1; // Force check off
    }

    if (!vm.isCfAdmin && !vm.user.hasPermission('admin')) {
      vm.unauthorized = true;
      $timeout(() => vm.showUnauthorized = true, 1000);
    }
  };

  vm.$onDestroy = function () {
    if (_.isFunction(_.get(vm, '$invitations.$destroy'))) {
      vm.$invitations.$destroy();
    }
  };

  vm.checkConstraint = function () {
    if (vm.userConstraint === -1 || vm.isCfAdmin) {
      return $q.resolve();
    }
    if (vm.userConstraint > vm.totalUsers + vm.$invitations.length) {
      return $q.resolve();
    }
    return constantsService.get('stripe').then((c) => {
      const myProd = _.get(vm.user, 'subscription.plan.product');
      let newProductPromise = $q.when(c.stripeProducts.OLD_PREMIUM);

      if (myProd === c.stripeProducts.PAY_AS_GO) {
        newProductPromise = $q.when(c.stripeProducts.STANDARD);
      } else if (myProd === c.stripeProducts.STANDARD) {
        newProductPromise = $q.when(c.stripeProducts.PREMIUM);
      }
      return subscriptionService
        .promptForUpgrade(vm.user, `Greater than ${vm.userConstraint} users`, newProductPromise)
        .then(() => {
          vm.userConstraint = _.get(vm.productConstraints, vm.user.subscription.plan.product + '.rateConstants.USERS');
        });
    });
  };

  vm.getRole = function (user) {
    if (!user.reviews) {
      return null;
    }

    let orgId = vm.isCfAdmin ? user.reviews.organizationId : vm.user.orgContext.id,
      orgRec = _.find(user.reviews.organizations, (orgRec) => orgRec.id === orgId);

    return orgRec && orgRec.role;
  };

  vm.isAdminOverUser = function (user) {
    return (
      vm.isCfAdmin ||
      vm.user.hasPermission(authorization.claims.ADMIN) &&
        user.reviews.organizationId === vm.user.currentOrgContext().id
    );
  };

  vm.remove = function (user, index) {
    // TODO: This function needs some work after the authorization / rules refactor (4/24/2017)

    let name = user.lastName ? user.firstName + ' ' + user.lastName : user.email;

    confirmDeleteModal(name).then(function () {
      let orgId = vm.isCfAdmin ? user.reviews.organizationId : vm.user.orgContext.id;

      orgInteractionService.removeNonEmailUser(orgId, user.$id).then(() => {
        vm.users.searchResults.splice(index, 1);
        growl.success('User removed');
        $log.info('Profile deleted for ' + user.$id);
      })
        .catch((err) => $log.error(err));
    });
  };

  vm.searchKeyPress = function ($event) {
    if (utils.isBenignKeyUp($event.keyCode)) {
      return;
    }

    if ($event.keyCode === 13) {
      if (!vm.searchText || vm.searchText.length >= 3) {
        vm.search();
      }

      vm.search.flush();

      return;
    }

    if (vm.searchText && vm.searchText.length <= 3) {
      return;
    }

    vm.search();
  };

  vm.getMore = function () {
    vm.loading = true;
    vm.users.getMore().then(() => vm.loading = false);
  };

  vm.search = _.debounce(function () {
    vm.loading = true;
    vm.users.searchNameEmail(vm.searchText);
    vm.users.search().then(() => vm.loading = false);
  }, 300);

  vm.inviteUser = function () {
    vm.checkConstraint().then(() => {
      $uibModal
        .open({
          component: 'cfInviteUser',
          backdrop: 'static',
          resolve: {
            user: () => vm.user,
          },
        })
        .result.then(({contact, companyName}) => {
          $log.info('Invite sent to: ' + contact.contactEmail);

          confirmModal({
            title: 'Invitation Sent!',
            body:
              'An invitation to join <b>' +
              companyName +
              '</b> on FoodReady was sent to <b>' +
              contact.contactEmail +
              '</b>.',
            okText: 'Ok',
            hideCancelButton: true,
          });
        })
        .catch(function (reason) {
          if (utils.isModalDismissalByUser(reason)) {
            return;
          }

          growl.error('Unable to assign the user to a new organization.', {});
          $log.error(reason);
        });
    });
  };

  vm.addNonEmailUser = function () {
    $uibModal
      .open({
        component: 'frAddUser',
        backdrop: 'static',
        resolve: {
          user: () => vm.user,
        },
      })
      .result.then(({contact, companyName, passcode}) => {
        $log.info(`User added: ${contact.firstName} ${contact.lastName} to ${companyName}`);

        confirmModal({
          title: 'New User added',
          body: `<b>${contact.firstName} ${contact.lastName} </b> added to <b> ${companyName} </b> with passcode: <b>${passcode}</b>`,
          okText: 'Ok',
          hideCancelButton: true,
        })
          .then(() => {
            vm.search();
          });
      })
      .catch(function (reason) {
        if (utils.isModalDismissalByUser(reason)) {
          return;
        }

        growl.error('Unable to add the user to the organization.', {});
        $log.error(reason);
      });
  };

  vm.cancelInvitation = function (invitation) {
    confirmDeleteModal(`Invitation for ${invitation.name}`).then(() => {
      urlTokens
        .remove(invitation.token)
        .then(() => {
          growl.success('Invitation cancelled.');
          return vm.$invitations.$remove(invitation);
        })
        .catch((err) => utils.defaultErrorHandler(err, 'Unable to cancel invitation.'));
    });
  };
};
