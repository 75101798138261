class CfInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-checkbox.template.html');
    this.require = 'ngModel';
    this.transclude = true;
    this.replace = true;
    this.bindToController = {
      ngModel: '=',         // The form control model reference
      label: '@',           // The label text
      name: '@',            // The name of the form control
      isRequired: '=',      // Is the field required (true / false)
      helpText: '@',        // Optional help text
      isDisabled: '=',      // Is the field disabled (true / false)
      containerClass: '=',  // Override class (default to g-mb-20)
      onChange: '&'         // ngChange callback
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';

    angular.noop();
  }
}

module.exports = CfInput;
