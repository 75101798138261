'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfCsvImport', {
    bindings: {
      modalInstance: '<',
      resolve: '='
    },
    template: require('./csv-import.template.html'),
    controller: require('./csv-import.controller.js'),
    controllerAs: 'vm'
  });
};
