module.exports = function(ngModule) {
  ngModule.component('logSheetPreviewModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./log-sheet-preview-modal.template.html'),
    controller: require('./log-sheet-preview-modal.controller'),
    controllerAs: 'vm'
  });
};
