module.exports = function(ngModule) {
  ngModule.constant('planTypes', {
    PC_PLAN: {
      id: 'pcPlan',
      serverId: 'preventiveControls',
      nameShort: 'Preventive Controls Plan',
      name: 'PC - Preventive Controls Plan (Food Safety Modernization Act)'
    },
    HACCP_PLAN: {
      id: 'haccpPlan',
      serverId: 'haccp',
      nameShort: 'HACCP Plan',
      name: 'HACCP - Hazard Analysis and Critical Control Points Plan'
    }
  });
};
