module.exports = function(ngModule) {
  ngModule.component('frEditMaterial', {
    bindings: {
      user: '<',
      $batch: '<',
      resolve: '<?',
      modalInstance: '<?'
    },
    template: require('./edit_material.template.html'),
    controller: require('./edit_material.controller'),
    controllerAs: 'vm'
  });
};
