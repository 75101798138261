class Constructor {
  constructor(reportsService, $uibModal, $log, authorization, $q, growl, subscriptionService,
              utils, companySubscription, orders, S3, confirmModal, $state, constantsService) {
    'ngInject';

    this.reportsService = reportsService;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.authorization = authorization;
    this.$q = $q;
    this.growl = growl;
    this.subscriptionService = subscriptionService;
    this.utils = utils;
    this.companySubscription = companySubscription;
    this.orders = orders;
    this.S3 = S3;
    this.confirmModal = confirmModal;
    this.$state = $state;
    this.constantsService = constantsService;
  }

  $onInit() {
    this.planId = this.user.subscription.plan.id;
    this.setPermissions();
  }

  setPermissions() {
    this.onPayAsGo = this.user.onPayAsGoPlan() && !this.user.hasPermission(this.authorization.claims.CF_ADMIN);
    this.onPremiumPlan = !this.user.onPayAsGoPlan();
  }


  fetchReport(reportType, skipChargeCheck) {
    this.reportsService.fetchPlanReport(this.user, this.$product, reportType, skipChargeCheck)
        .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred opening the report'));
  }

}

module.exports = Constructor;
