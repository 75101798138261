class Constructor {
  constructor($timeout, preventDirtyNav, growl, $log, sopService, $state, confirmModal, $scope,
              $q, $uibModal, utils, $stateParams, organizations, batchService,
              users, moment, CF_CLAIMS, products) {
    'ngInject';

    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.growl = growl;
    this.$log = $log;
    this.sopService = sopService;
    this.$state = $state;
    this.confirmModal = confirmModal;
    this.$scope = $scope;
    this.$q = $q;
    this.$uibModal = $uibModal;
    this.utils = utils;
    this.$stateParams = $stateParams;
    this.organizations = organizations;
    this.batchService = batchService;
    this.users = users;
    this.moment = moment;
    this.CF_CLAIMS = CF_CLAIMS;
    this.products =  products;
  }

  $onInit() {
    this.$timeout(() => {
      this.unsubscribe = this.preventDirtyNav(this.logForm, 'You have unsaved changes.',
        () => this.save());
    });

    this.instructionsOpen = true;

    this.canApprove = this.user.hasPermission(this.CF_CLAIMS.LOG_APPROVE);
    this.setUpdatedApprovedInfo();
    this.heading = `${this.$sopLog.$value === null ? 'New' : 'Edit'} SOP Log`;
    this.subHeading = this.$sop.title;

    if (this.$sopLog.batchId) {
      this.batchService.getBatchNumber(this.$sopLog.batchId).then(batchNumber => {
        this.batchNumber = batchNumber;
      });
    }
    if (_.get(this.$sop, 'metadata.productId')) {
      this.productSop = true;
      this.products.getBrandName(this.$sop.metadata.productId).then(name => {
        this.subHeading = `${this.subHeading} (${name})`;
      });
    }

    console.log("THIS IS LOG FORM TYPE" + this.$sop.metadata.logFormType);

    // Watch for failed SOPs, which will activate the Corrective Action section and set the deviation date watcher.
    this.stopLogWatch = this.$scope.$watch('vm.$sopLog.results', (val) => {
      this.anyFailed = val &&  _.some(val, (result) => !result.passed);
      if (!this.productSop) { return; }

      // Product specific SOPs require more detailed info in the CA report.
      if (this.anyFailed) {
        this.$sopLog.caReport = {
          deviationTime: this.moment(new Date()).format('HH:mm:ss'),
          personAction: this.user.fullName()
        };
      } else {
        this.$sopLog.caReport = null;
      }
    }, true);
  }

  $onDestroy() {
    if (_.isFunction(this.stopLogWatch)) { this.stopLogWatch(); }
  }

  save() {
    const isNew = this.$sopLog.$value === null;

    this.saving = true;
    if (isNew) {
      _.assign(this.$sopLog, {
        organizationId: this.user.orgContext.id,
        createdOn: new Date().getTime(),
        createdBy: this.user.uid,
        createdByName: this.user.fullName()
      });
    } else {
      this.$sopLog.updatedOn = new Date().getTime();
      this.$sopLog.updatedBy = this.user.uid;
      this.$sopLog.updatedByName = this.user.fullName();
    }

    this.$sopLog.anyFailed = _.some(this.$sopLog.results, r => !r.passed);
    this.$sopLog.approvedOn = null;
    this.$sopLog.approvedBy = null;

    return this.$sopLog.$save()
      .then(() => {
        if (this.logForm) { this.logForm.$setPristine(); }

        if (isNew) {
          this.$sop.lastLog = this.$sopLog.createdOn;

          return this.$sop.$save();
        }
      })
      .then(() => {
        this.growl.success('Log saved.');
        this.onSave({$sopLog: this.$sopLog});
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save SOP log.'))
      .finally(() => this.saving = false);
  }

  approve() {
    this.sopLogsService.approve(this.user, this.$sopLog.$id).then(() => {
      this.growl.success('Entry Saved');
      return this.setUpdatedApprovedInfo();
    }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred approving the corrective action report.'));
  }

  setUpdatedApprovedInfo() {
    return this.$q.all({
      updatedBy: this.$sopLog.updatedBy && this.users.getName(this.$sopLog.updatedBy),
      approvedBy: this.$sopLog.approvedBy && this.users.getName(this.$sopLog.approvedBy)
    }).then(({updatedBy, approvedBy}) => {
      this.updated = updatedBy && {
        on: this.$sopLog.updatedOn &&
          this.moment(this.$sopLog.updatedOn).format('MMMM Do YYYY, h:mm:ss a'),
        by: updatedBy
      };

      this.approved = approvedBy && {
        on: this.$sopLog.approvedOn &&
          this.moment(this.$sopLog.approvedOn).format('MMMM Do YYYY, h:mm:ss a'),
        by: approvedBy
      };
    });
  }
}

module.exports = Constructor;
