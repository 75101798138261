class Controller {
  constructor(constantsService) {
    'ngInject';

    this.constantsService = constantsService;
  }

  $onInit() {
    this.remoteDirectory = 'files';
    this.constantsService.get('projectFileType').then(fileTypes => {
      this.categories = _.omitBy(fileTypes, fc => fc.companyLevel);
    });
  }

  onNewFile($file) {
    let newFile = _.assign({},$file);
    this.files.push(newFile);
  }

  onRemoveFile(removedFile) {
    this.files = this.files.filter((file)=>file.$id !== removedFile.$id);
  }
}

module.exports = Controller;
