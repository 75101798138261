const md5 = require('js-md5');

module.exports = function(ngModule) {
  ngModule.factory('authentication',
    function($firebaseAuth, fbutil, $q,
      $log, User, users, $http, $window, $location) {
      'ngInject';

      let authObject = $firebaseAuth();

      return {
        user: null,
        presenceMonitorStarted: false,
        startPresenceMonitor: function() {
          if (this.presenceMonitorStarted) { return; }

          let amOnline = fbutil.ref('.info/connected'),
            userRef = fbutil.ref('presence', this.user.uid);

          this.presenceMonitorStarted = true;
          amOnline.on('value', (snapshot) => {
            if (snapshot.val()) {
              userRef.child('connected').onDisconnect().remove();
              userRef.child('lastDisconnect').onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
              userRef.child('lastConnect').set(firebase.database.ServerValue.TIMESTAMP);
              userRef.child('connected').set(true);
            }
          });
        },
        loginLocal: function(email, password, skipHydrate) {
          let loginP = authObject.$signInWithEmailAndPassword(email, password);

          return skipHydrate ? loginP : loginP.then(() => {
            $log.info('User logged in (local auth).');

            return this.waitForAuth();
          });
        },
        loginOAuth: function(referrer, skipHydrate) {
          return authObject.$signInWithPopup(referrer)
            .then((result) => {
              return users.getProfile(result.user.uid)
                .then((profile) => {
                  if (profile) {
                    return result.user;
                  }

                  this.logout();
                  throw {code: 'auth/user-not-found'};
                });
            })
            .then((authUser) => {
              $log.info('User logged in (OAuth referrer: ' + referrer + ').');
              return skipHydrate ? $q.resolve(authUser) : new User(authUser);
            });
        },
        signUpLocal: function(email, password) {
          return authObject.$createUserWithEmailAndPassword(email, password);
        },
        signUpOAuth: function(referrer) {
          let signinPromise;

          if (referrer === 'google') {
            // We can't use angularFire with google because we need to add to the scope.
            let provider = new firebase.auth.GoogleAuthProvider();

            provider.addScope('email');
            provider.addScope('profile');
            signinPromise = firebase.auth().signInWithPopup(provider);
          } else {
            signinPromise = authObject.$signInWithPopup(referrer);
          }

          return signinPromise
            .then((result) => {
              return users.getProfile(result.user.uid)
                .then((profile) => {
                  if (profile) { throw {code: 'auth/user-already-exists', profile: profile}; }
                  $log.info('User signing up (OAuth referrer: ' + referrer + ').');
                  return result.user;
                });
            });
        },
        logout: async function() {
          // Temporarily include a stack trace to track down if/how users are getting signed out randomly.
          $log.info('User logging off.', new Error('Dummy error to get stacktrace'));

          delete this.user;
          User.logout();
          await $firebaseAuth().$signOut();
          // $window.location.reload();
        },
        changeEmail: function(newEmail) {
          let that = this;

          return authObject.$updateEmail(newEmail)
            .then(() => {
              return users.$getProfile(that.user.uid)
                .then((userObj) => {
                  that.user.email = newEmail;
                  that.user.profileImage = that.user.profileImage ||
                    {
                      key: 'avatar/' + that.user.emailHash + '?d=identicon&s=175',
                      url: 'https://www.gravatar.com'
                    };

                  userObj.email = newEmail;
                  userObj.emailHash = md5(newEmail);

                  return userObj.$save();
                });
            });
        },
        changePassword: function(user, oldPassword, newPassword) {
          return this.loginLocal(user.email, oldPassword, true)
            .then(() => $firebaseAuth().$updatePassword(newPassword));
        },
        resetPassword: function(email) {
          return $firebaseAuth().$sendPasswordResetEmail(email);
        },
        waitForAuth: function() {
          return authObject.$waitForSignIn()
            .then((authUser) => {
              if (authUser) {
                this.user = this.user || new User(authUser);
                return this.user.$loaded()
                  .then(() => this.user);
              }
              return null;
            });
        },
        requireAuth: function() {
          return this.waitForAuth()
            .then((user) => {
              if (!user) {
                throw 'AUTH_REQUIRED';
              }
              return user;
            });
        },
        getWpParams: function() {
          let url = '/wp-jwt';

          return $http.get(url).then(result => result.data.redirectUrl);
        },
        zendeskSsoLogin: function(returnTo, onSignedIn) {
          if(returnTo) {
            return this.getWpParams()
              .then(params =>          {
                returnTo = returnTo ? '&return_to=' + returnTo : '';
                $window.location = params + returnTo;
              })
              .catch(err => {
                $log.error('Error attempting to connect to ZenDesk.', {actualError: angular.toJson(err)});
                if(onSignedIn) {
                  onSignedIn({$preferredState: 'user.dashboard'});
                }
              });
          }else{
            return $location.url('https://support.foodready.ai/access/normal');
          }
        },
        setPasscode(uid, passcode) {
          return $http.post(`users/${uid}/passcode`, {passcode});
        }
      };
    });
};
