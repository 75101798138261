module.exports = function(ngModule) {
  ngModule.component('cfSignupFormMain', {
    bindings: {
      user: '=',
      password: '=',
      onLoginOauth: '&'
    },
    template: require('./auth.signup-form-main.template.html'),
    controller: require('./auth.signup-form-main.controller.js'),
    controllerAs: 'vm'
  });
};
