require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductDiagram', {
    bindings: {
      user: '<',
      product: '<',
      processSteps: '<',
      $hazards: '<',
      processLinks: '<',
      diagramState: '=',
      sops: '=',
      isReadOnly: '=',
      onEditControl: '&',
      onEditHazard: '&',
      onRemoveControl: '&',
      onRemoveHazard: '&',
      onAddIngredient: '&',
      onAddEquipment: '&'
    },
    template: require('./products.edit.diagram.template.html'),
    controller: require('./products.edit.diagram.controller.js'),
    controllerAs: 'vm'
  });
};
