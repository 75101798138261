module.exports = function(ngModule) {
  ngModule.component('cfInviteUser', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./users.settings.users.invite.template.html'),
    controller: require('./users.settings.users.invite.controller.js'),
    controllerAs: 'vm'
  });
};
