module.exports = function(ngModule) {
  ngModule.component('cfChangeDefaultBillingSource', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./billing.change-default-source.template.html'),
    controller: require('./billing.change-default-source.controller'),
    controllerAs: 'vm'
  });
};
