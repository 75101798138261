class Controller {
  constructor($stateParams, $state, deviceDetector) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$state = $state;
    this.deviceDetector = deviceDetector;
  }

  $onInit() {
    this.isMobile = this.deviceDetector.isMobile();
  }

  selectTopic(topicId) {
    this.selecting = false;
    this.$state.go('user.messages.message', {topicId: topicId});
  }
}

module.exports = Controller;
