/* eslint-disable angular/on-watch */
module.exports = function(ngModule) {
  ngModule.run(function($rootScope, $state, S3, growl, $log, $window, $timeout, $templateCache, cfpLoadingBar, V2_URL, LOCAL_DEVELOPMENT) {
    'ngInject';

    let redirects = 0, MAX_REDIRECTS_PER_SEC = 5;

    $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => {
      // Detect infinite loop redirects
      if (++redirects > MAX_REDIRECTS_PER_SEC) {
        $log.error('$stateChangeError infinite loop detected. Sending user to error page.');
        $window.location.href = 'https://app.foodready.ai';
        // todo: redirect to an error page
        //$window.location.href = 'errorPage';
        return;
      }

      $timeout(() => { redirects--; }, 1000);

      if (error === 'AUTH_REQUIRED') {
        $log.info('User attempted to visit protected route while not logged in: ' + toState.name);
        $timeout(() => growl.error('Cannot access page while not logged in. Please log in.'));
        if ($window.localStorage) {
          $window.localStorage.setItem('pendingStateName', toState.name);
          $window.localStorage.setItem('pendingStateParams', angular.toJson(toParams));
        }

        if (LOCAL_DEVELOPMENT) {
          $state.go('authentication.signin');
        } else {
          $window.open(`${V2_URL}/signin`, '_self');
        }
      } else if (_.get(error, 'message') === 'LOGGED_OFF_ERROR') {
        if (LOCAL_DEVELOPMENT) {
          $state.go('authentication.signin');
        } else {
          $window.open(`${V2_URL}/signin`, '_self');
        }
      } else {
        if (!error.suppressError) {
          $log.error(error);
          growl.error('Internal error during page navigation.');
        }
        $state.go(error.toState || 'user.dashboard');
      }
      cfpLoadingBar.complete(); // for some reason this state transition never calls stateChangeSuccess
    });

    // TODO: this is supported by default in ui-router 1.x. Remove this if/when ui-router is upgraded.
    $rootScope.$on('$stateChangeStart', function(event, to, params) {
      if (to.redirectTo) {
        event.preventDefault();
        $state.go(to.redirectTo, params, {location: 'replace'});
      }
    });
  });
};
