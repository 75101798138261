'use strict';

module.exports = function($log, $timeout, $firebaseObject, preventDirtyNav, growl, fbutil, email) {
  'ngInject';

  let vm = this, prevPocEmail;

  vm.$onInit = function() {
    vm.reset();
    $timeout(() => {
      preventDirtyNav(vm.organizationContactForm, 'You have unsaved contact changes.', vm.save);
    });

    prevPocEmail = vm.organization.pointOfContactEmail;
  };

  vm.save = function() {
    vm.organization.$save()
      .then(() => {
        if (vm.organizationContactForm) { vm.organizationContactForm.$setPristine(); }
        growl.success('Organization contact saved successfully!');

        if (vm.organization.pointOfContactEmail && prevPocEmail !== vm.organization.pointOfContactEmail) {
          prevPocEmail === vm.organization.pointOfContactEmail;
          email.sendTemplate({
            email: vm.organization.pointOfContactEmail,
            name: vm.organization.pointOfContactName,
            subject: 'You have been set as your Organizations Point of Contact'
          }, 'generic_email', [
            {
              name: 'bodyText',
              content: 'A Point of Contact alert has successfully been set to notify you of updates regarding ' +
              'your Organization in the FoodReady Platform.'
            },
            {name: 'titleSection', content: 'Hello ' + vm.organization.pointOfContactName},
            {name: 'userFullName', content: vm.organization.pointOfContactName}
          ]).catch(function(reason) {
            $log.error(reason);
          });
        }
      })
      .catch((err) => {
        $log.error(err);
        growl.error('Unable to save organization contact. Please try again or contact FoodReady customer support.');
      });
  };

  vm.reset = function() {
    $firebaseObject(fbutil.ref('organizations/' + vm.organization.$id)).$loaded()
      .then(organization => {
        vm.organization = organization;
        vm.organizationContactForm.$setPristine();
      })
      .catch((err) => {
        $log.error(err);
        growl.error('Unable to reset the organization contact form. Please try again or ' +
          'contact FoodReady customer support.');
      });
  };
};
