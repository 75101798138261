module.exports = function(ngModule) {
  ngModule.factory('InventoryItemsSearch', function($http, $q, utils) {
    'ngInject';

    const DEFAULT_PAGE_SIZE = 30;

    class InventoryItemsSearch {
      constructor(orgId) {
        this.baseUri = 'inventoryItems/search';
        this.maxReach = false;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.inventoryTypeCodes = [];
        this.ids = [];
        this.orgId = orgId;
      }
      maxReached() {
        return this.maxReach;
      }


      /**
       * Sort by a property on the procedure entry
       * @param {string} sortBy Sort field
       * @param {string} sortDirection Sort direction
       * @returns {InventoryItemsSearch} The InventoryItemsSearch object
       */
      sortBy(sortBy, sortDirection = 'asc') {
        this.sortByValue = sortBy;
        this.sortDirection = sortDirection;

        return this;
      }

      /**
       * Set the max page size of the result set
       * @param {number} size Max page size
       * @returns {InventoryItemsSearch} The InventoryItemsSearch object
       */
      size(size) {
        this.pageSize = size;

        return this;
      }

      /**
       * Start from index
       * @param {number} index Max page size
       * @returns {InventoryItemsSearch} The InventoryItemsSearch object
       */
      startFrom(index) {
        this.from = index;

        return this;
      }

      inventoryTypes(types) {
        this.inventoryTypeCodes = types.split(',');

        return this;
      }

      itemIds(types) {
        this.ids = types.split(',');

        return this;
      }
      /**
       * Set the search text (currently will try to match key name)
       * @param {string=} text The search text
       * @returns {Promise} The InventoryItemsSearch object
       */
      search(text = '') {
        this.from = 0;
        this.maxReach = false;
        this.searching = true;
        this.badSearch = false;

        return this._search(text)
          .finally(() => { this.searching = false; });
      }

      getMore(text) {
        if (this.badSearch) { throw new Error('Failed search...cannot get more'); }
        if (this.maxReach || this.searching) { return; }
        this.from += this.pageSize;

        return this._search(text);
      }

      $loaded() {
        return this.httpPromise;
      }

      _search(text) {
        let queryParmArray = [];

        queryParmArray.push(`from=${this.from}`);
        queryParmArray.push(`size=${this.pageSize}`);
        queryParmArray.push(`organizationId=${this.orgId}`);
        queryParmArray.push(`sortBy=${this.sortByValue || 'createdOn'}`);
        queryParmArray.push(`sortDirection=${this.sortDirection || 'desc'}`);
        if (!_.isUndefined(this.inventoryTypeCodes)) {
          this.inventoryTypeCodes.forEach((types,index) =>{
            queryParmArray.push(`inventoryTypeCodes[${index}]=` + types);
          });
        }
        if (!_.isUndefined(this.ids)) {
          this.ids.forEach((key,index) =>{
            queryParmArray.push(`ids[${index}]=` + key);
          });
        }
        if (text) { queryParmArray.push(`searchText=${text}`); }
        const url = `${this.baseUri}${utils.toQueryParm(queryParmArray)}`;

        this.searching = true;
        this.httpPromise = $http.get(url)
          .then(result => {
            if (result.status === 204) {
              this.maxReach = true;
              return [];
            }
            let recs = result.data.data;

            if (recs.length < this.pageSize) {
              this.maxReach = true;
            }

            return result.data.data;
          })
          .catch(err => {
            console.error('Error during search:', err);

            this.badSearch = true;

            return $q.reject(err);
          })
          .finally(() => this.searching = false);

        return this.httpPromise;
      }
    }

    return InventoryItemsSearch;
  });
};
