class Controller {
  constructor($state, $uibModal, $filter, growl, moment, $q, confirmDeleteModal, orgInteractionService, $log,
              orders, messageServices) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$filter = $filter;
    this.growl = growl;
    this.moment = moment;
    this.$q = $q;
    this.confirmDeleteModal = confirmDeleteModal;
    this.orgInteractionService = orgInteractionService;
    this.$log = $log;
    this.messageServices = messageServices;
    this.ordersService = orders;

    this.filterTypes = {
      ACTIVE: 'Active Clients',
      PENDING: 'Pending Invitation'
    };
  }

  $onInit() {
    this.currentFilterType = this.filterTypes.ACTIVE;
    _.forEach(this.orgServices, (svc, id) => {
      let orgPrice = svc.price;

      _.assign(svc, this.defaultServices[id]);
      svc.price = orgPrice || svc.price;
    });

    this.services = this.orgServices;

    _.forEach(this.clients, (client) => {
      client.servicesProvided = _.map(client.serviceIds, (id) => this.services[id]);
    });

    this.pendingInvitations = _.map(this.organization.pendingInvitations, (invitation, tokenId) => {
      return _.assign(invitation, {tokenId: tokenId});
    });
  }

  inviteNewClient() {
    this.$uibModal.open({
      component: 'cfMarketplaceInviteClient',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        user: () => this.user,
        services: () => this.services,
        organizationId: () => this.organization.$id
      }
    }).result
      .then(tokenId => {
        this.growl.success('New client invitation sent successfully. ' +
          'You will be notified when your client accepts the invitation.');
        this.pendingInvitations.push(_.assign(this.organization.pendingInvitations[tokenId], {tokenId: tokenId}));
      })
      .catch((err) => {
        if (err === 'cancel') { return; }
        this.$log.error(err);
      });
  }

  getServicesMarkup(client) {
    return _.reduce(client.servicesProvided, (result, service) =>
      result + '<li>' + (service ? service.title : '(No Name)') + '</li>', '<ol>') + '</ol>';
  }

  cancelInvitation(pendingInvitation, skipVerify) {
    let verifyPromise = skipVerify ? this.$q.when(true) :
      this.confirmDeleteModal('Invitation to ' + pendingInvitation.sentToName);

    verifyPromise.then(() => {
      this.orgInteractionService.cancelInvitation(this.organization.$id, pendingInvitation.tokenId).then(() => {
        _.remove(this.pendingInvitations, i => i.tokenId === pendingInvitation.tokenId);
      }).catch(err => {
        this.$log.error('Unable to cancel invitation.', err);
        this.growl.error('An error occurred cancelling the invitation. Please try again or contact customer support.');
      });
    }).catch(() => angular.noop);
  }

  message(client) {
    this.messageServices.getOrgToOrgTopic(this.user, client.$id).then(topicId => {
      this.$state.go('user.messages.message', {topicId: topicId});
    });
  }

  terminate(client) {
    this.confirmDeleteModal('Client Relationship with ' + client.companyName)
      .then(() => {
        return this.ordersService.get(this.organization.$id, true, null, 0, 1, {isActive: true, client: client.$id})
          .then(orders => {
            if (!_.isEmpty(orders)) {
              this.growl.warning('Unable to terminate client relationship. First cancel all orders with client.');
              return this.$q.when(false);
            }

            return this.orgInteractionService.terminateClientProviderRelationship(this.organization.$id, client.$id)
              .then(() => true);
          })
          .then(terminated => {
            if (terminated) {
              _.remove(this.clients, c => c.$id === client.$id);
            }
          })
          .catch(err => {
            this.$log.error('An error occurred terminating a client/provider relationship.', err);
            this.growl.error('Unable to terminate relationship. Please contact customer support.');
          });
      })
      .catch(() => angular.noop);
  }

  humanizeDate(date) {
    return this.moment.duration(moment(date).diff(new Date())).humanize(true);
  }
}

module.exports = Controller;
