class CfInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-date.template.html');
    this.require = 'ngModel';
    this.replace = false;
    this.bindToController = {
      ngModel: '=',         // The form control model reference
      label: '@',           // The label text
      name: '@',            // The name of the form control
      helpText: '@',        // Optional subscript helper text
      isRequired: '=',      // Is the field required (true / false)
      isReadonly: '=',      // Is the field readonly (true / false)
      isInvalid: '=',       // Is the field invalid (true / false)
      invalidText: '@',     // The text to display if the field is invalid.
      placeholder: '@',      // Input placeholder
      size: '@',             // Input size (e.g. md, sm, lg)
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller($scope) {
    'ngInject';

    this.$scope = $scope;

    this.$onInit = function() {
      this.dateVal = this.ngModel ? new Date(this.ngModel) : new Date();

      this.dateWatcherUnregister = this.$scope.$watch('vm.dateVal', date => {
        if (date) {
          this.ngModel = date.getTime();
        } else {
          this.ngModel = null;
        }
      });
    };

    this.$onDestroy = function() {
      if (_.isFunction(this.dateWatcherUnregister)) {
        this.dateWatcherUnregister();
      }
    };
  }
}

module.exports = CfInput;
