class Controller {
  constructor(products, $q) {
    'ngInject';

    this.products = products;
    this.$q = $q;

    this.questionnaireProperties = ['planType', 'generic_name', 'facilityId', 'description', 'intendeduse',
      'intendedendusers', 'packagingtype', 'wheresold', 'organic', 'kosher', 'specialdistribution',
      'handlinginstructions', 'labelinginstructions', 'storageconditions', 'packagefilldata'];
    this.propertiesProperties = ['preservatives', 'wateractivity', 'phacidity', 'viscosity', 'shelflife', 'density'];
  }

  $onInit() {
    this.copyBitmap = {};
  }

  showWarning() {
    let criticalProps = _.pick(this.copyBitmap, ['questionnaire', 'processSteps', 'properties']);

    return _.some(criticalProps, c => c);
  }

  noneSelected() {
    return !_.some(this.copyBitmap, c => c);
  }

  copyProduct() {
    let newProduct = this.copy(this.user, this.sourceProduct, this.copyBitmap);

    this.onCopy({newProduct: newProduct});
  }

  copy(user, sourceProduct, copyBitmap) {
    let copyProduct = {
      processSteps: copyBitmap.processSteps && _.clone(sourceProduct.processSteps) || null,
      processLinks: copyBitmap.processSteps && _.clone(sourceProduct.processLinks) || null,
      recall: copyBitmap.recall && _.clone(sourceProduct.recall) || null
    };

    if (!copyBitmap.hazards) {
      _.each(copyProduct.processSteps, step => delete step.hazards);
    }
    if (copyBitmap.questionnaire) {
      _.assign(copyProduct, _.pick(sourceProduct, this.questionnaireProperties));
    }
    if (copyBitmap.properties) {
      _.assign(copyProduct, _.pick(sourceProduct, this.propertiesProperties));
    }

    copyProduct.organizationId = sourceProduct.organizationId;
    copyProduct.groupId = sourceProduct.groupId || null;
    copyProduct.originalProduct = sourceProduct.$id;
    copyProduct.originalBrandName = sourceProduct.brandName || null;
    copyProduct.originalContactId = sourceProduct.contactId;
    copyProduct.contactId = user.uid;
    copyProduct.contactEmail = user.email;
    copyProduct.createdOn = firebase.database.ServerValue.TIMESTAMP;
    copyProduct.isCopy = true;

    return copyProduct;
  }

  getToolTip(title, propertyDescriptions) {
    return _.reduce(propertyDescriptions, (result, prop) => {
      return result + '<li>' + prop + '</li>';
    }, '<b><u>' + title + '</u></b><ul>') + '</ul>';
  }
  //this.propertiesProperties = ['preservatives', 'wateractivity', 'phacidity', 'viscosity', 'shelflife', 'density'];
  getQuestionnaireTooltips() {
    return this.getToolTip('Questionnaire Fields', ['Plan Type', 'Generic Name', 'Facility', 'Description',
      'Intended User', 'Intended End Users', 'Packaging Type', 'Where Sold', 'Organic', 'Kosher',
      'Special Distribution', 'Handling Instructions', 'Labeling Instructions', 'Storage Conditions',
      'Package Fill Data']);
  }

  getPropertiesTooltips() {
    return this.getToolTip('Properties Fields', ['Preservatives', 'Water Activity', 'pH Level', 'Viscosity',
      'Shelf Life', 'Density']);
  }
}

module.exports = Controller;
