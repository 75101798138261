class Constructor {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  $onInit() {
    this.page = 'intro';
    this.user = {};
  }

  continue() {
    this.$state.go('filePortal.portal');
  }
}

module.exports = Constructor;
