'use strict';

module.exports = function(ngModule) {
  class ProjectWritingServices {
    constructor($q, fbutil, $firebaseObject, messageServices, fileBucketService, $firebaseArray) {
      'ngInject';

      this.$q = $q;
      this.fbutil = fbutil;
      this.$firebaseObject = $firebaseObject;
      this.messageServices = messageServices;
      this.$firebaseArray = $firebaseArray;
      this.fileBucketService = fileBucketService;
    }
    /**
     * Get a SOP.
     * @param {string} id The SOP ID
     * @return {Promise} A promise that resolves to a Project
     */
    get(id) {
      return this.fbutil.ref('projects', id).once('value')
        .then(snap => snap.exists() ? _.assign(snap.val(), {$id: snap.key}) : null);
    }

    /**
     * Get a project.
     * @param {string} id The Project ID
     * @return {Promise} A promise that resolves to a $firebaseObject
     */
    $get(id) {
      return this.$firebaseObject(this.fbutil.ref('projects', id)).$loaded();
    }

    /**
     * Soft delete a Project.
     * @param {string} id The Project ID
     * @return {Promise} A promise that resolves when a Project is removed
     */
    delete(id) {
      return this.fbutil.ref('projects', id, 'deleted').set(true);
    }

    /**
     * Get external files of a project
     * @param {string} projectId The Project ID
     * @return {Promise} A promise that resolves to a external file details
     */
    externalFiles(projectId) {
      return this.fbutil.ref('projects', projectId, 'files').once('value').then((snap)=>{
        let files = snap.val() || {};

        return this.$q.all(_.mapValues(files, (value, fileId) => this.fileBucketService.get(fileId))).then((files)=>{
          return Object.values(files);
        });
      });
    }

    /**
     * Get list of all Projects as a firebase array object.
     * @return {*}  A promise that resolves to an array of Projects associated to an org.
     */
    $getList() {
      return this.$firebaseArray(this.fbutil.ref('projects')).$loaded();
    }

    /**
     * Get list of Projects associated with an org as a firebase array object. Can be used by Consultancy firm which creates a Project Req
     * @param {string} organizationId Organization Ref id
     * @return {*}  A promise that resolves to an array of Projects associated to an org.
     */
    $getListByOrg(organizationId) {
      return this.$firebaseArray(this.fbutil.ref('projects').orderByChild('organizationId').equalTo(organizationId)).$loaded();
    }

    getProjectLists(user) {
      if (user.isCfAdmin()) {
        return this.$firebaseArray(this.fbutil.ref('projects').orderByChild('createdOn')).$loaded()
          .then((projects) => {
            return _.filter(projects, function(p) { return !p.deleted; });
          });
      } else {
        return this.$firebaseArray(this.fbutil.ref('orgProjects')
          .orderByChild('organizationId').equalTo(user.orgContext.id)).$loaded()
          .then((orgProjects)=>{
            return this.$q.all(_.map(orgProjects, (orgProject)=> this.get(orgProject.projectId)));
          })
          .then((projects) => {
            return _.filter(projects, function(p) { return !p.deleted; });
          });
      }
    }

    /**
     * Push a new project.
     * @param {object} newProject The new project object.
     * @return {*}  A promise that resolves to an angularFire project object.
     */
    $push(newProject) {
      return this.$firebaseObject(this.fbutil.ref('projects').push(newProject)).$loaded();
    }

    /**
     * Remove Org-Project Entry.
     * @param {string[]} removedOrgs - list of provider org id/s
     * @param {string} projectId - The project Id
     * @return {Promise} - A promise that is resolved when Provider Org/s are removed.
     */
    removeOrgProjects(removedOrgs, projectId) {
      return this.$firebaseArray(this.fbutil.ref('orgProjects')
        .orderByChild('projectId').equalTo(projectId)).$loaded()
        .then((projectsMap) => {
          return this.$q.all(_.map(projectsMap, (project)=> {
            if (removedOrgs.includes(project.organizationId)) {
              return this.fbutil.ref(`orgProjects/${project.$id}`).remove();
            }
          }));
        });
    }

    /**
     * Push a new project.
     * @param {[string]} [orgs]  Participating organizations.
     * @param {string} projectId The project id.
     * @return {*}  A promise that resolves to an angularFire project object.
     */
    $pushOrg(orgs, projectId) {
      let projectDetails = {projectId: projectId};

      return this.$q.all(orgs.forEach((org)=>{
        projectDetails.organizationId = org;
        return this.fbutil.ref('orgProjects').push(projectDetails);
      }));
    }
  }

  ngModule.service('projectWritingServices', ProjectWritingServices);
};

