class Controller {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.symptoms = [
      {id: 1, name: 'Fever'},
      {id: 2, name: 'Vomiting'},
      {id: 3, name: 'Diarrhea'},
      {id: 4, name: 'Respiratory'},
      {id: 5, name: 'Jaundice (e.g. yellowing of skin)'},
      {id: 6, name: 'Nausea'},
      {id: 7, name: 'Sore Throat w/Fever'},
      {id: 8, name: 'Lesions (on exposed skin)'},
      {id: 9, name: 'other (explain below)'}
    ];
  }

  $onInit() {
    this.$sopLog.metadata = this.$sopLog.metadata || {};
    this.chosenSymptoms = {};
    if (this.$sopLog.metadata.symptoms) {
      _.each(this.$sopLog.metadata.symptoms.split(','), (symptomName) => {
        this.chosenSymptoms[_.find(this.symptoms, s => s.name === symptomName.trim()).id] = true;
      });
    } else {
      this.$sopLog.metadata.symptoms = {};
    }

    this.unregister = this.$scope.$watch('vm.chosenSymptoms', (chosenSymptomsArray) => {
      this.$sopLog.metadata.symptoms = _.map(_.pickBy(chosenSymptomsArray, (val) => val),
        (val, id) => _.find(this.symptoms, s => s.id === _.toNumber(id)).name).join(', ');
    }, true);
  }

  $onDestroy() {
    if (_.isFunction(this.unregister)) {
      this.unregister();
    }
  }
}

module.exports = Controller;
