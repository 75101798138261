'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUiSelect', {
    bindings: {
      user: '<',
      searchObject: '<',    // Pre-searching with this object will allow the ngModel to be initialized.
      ngModel: '=',         // Holds the selected item.
      ngDisabled: '=',      // Is the control disabled?
      allowRemove: '=?',    // Adds a dropdown options to choose nothing.
      ngRequired: '=?',     // Sets a form's required attribute
      placeholder: '@?',    // Placeholder for the field.
      createItem: '&?',     // Include if a new item is chosen and that new items needs a function to generate it.
      onItemSelected: '&',  // Called when an item is selected.
      nameProperty: '@?',   // default is 'name'
      customNameText: '&?', // Custom text to display when the item name is shown.
      resultFilter: '&?',   // Function that filters search results
      noCreate: '<?',       // If true, don't allow them to create a new item when there are no results.
      basicDropdown: '<?'   // Only display the name in the dropdown
    },
    template: require('./cf-ui-select.template.html'),
    controller: require('./cf-ui-select.controller.js'),
    controllerAs: 'vm'
  });
};