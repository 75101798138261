class Controller {
  constructor($log, $http, growl, confirmDeleteModal) {
    'ngInject';

    this.$log = $log;
    this.$http = $http;
    this.growl = growl;
    this.confirmDeleteModal = confirmDeleteModal;
    this.documentTemplate = null;
    this.loading = false;
  }

  $onInit() {
    this.modalTitle = this.resolve.modalTitle;
    this.serviceId = this.resolve.serviceId;
    this.defaultServices = this.resolve.defaultServices;
    this.servicesOffered = this.resolve.servicesOffered;
    this.$documentTemplates = this.resolve.$documentTemplates;
    this.filterDocumentTemplates(this.serviceId);
  }

  filterDocumentTemplates(serviceId) {
    this.documentTemplate = null;
    this.documentTemplates = _.filter(this.resolve.$documentTemplates, {serviceId: serviceId});

    // don't include FoodReady template if none available (custom services)
    if (this.defaultServices[serviceId]) {
      this.documentTemplates.push({name: 'FoodReady Sample Template', serviceId: serviceId, isSample: true});
    }
  }

  ok() {
    this.loading = true;
    if (this.documentTemplate.isSample) {
      this.$http.get(`/letters/${this.documentTemplate.serviceId}/templates/foodready`)
        .then(result => this.modalInstance.close(result.data))
        .catch((err) => {
          this.$log.error(err);
          this.growl.error('Unable to load the sample template. Please try again or ' +
            'contact customer support for assistance.');
          this.loading = false;
        });
    } else {
      this.modalInstance.close(this.documentTemplate.markup);
    }
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  deleteTemplate(documentTemplate) {
    this.confirmDeleteModal(documentTemplate.name)
      .then(() => this.$documentTemplates.$remove(documentTemplate))
      .then(() => _.remove(this.documentTemplates, {$id: documentTemplate.$id}))
      .catch((err) => {
        if (err === 'cancel') { return; }

        this.$log.error(err);
        this.growl.error('Unable to remove the selected template. Please try again or ' +
          'contact customer support for assistance.');
      });
  }
}

module.exports = Controller;
