'use strict';

module.exports = function(ngModule) {
  require('./choose_supplier')(ngModule);
  require('./edit_ingredient')(ngModule);
  require('./supplier_ingredient')(ngModule);
  require('./ingredients')(ngModule);
  require('./main')(ngModule);
  require('./quick_add_supplier_ingredient')(ngModule);
  require('./file_tracking')(ngModule);
  require('./import_ingredients_modal')(ngModule);
  require('./product_customers_list')(ngModule);
  require('./product_customers')(ngModule);
  require('./sales_orders_modal')(ngModule);
};

