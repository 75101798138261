class Constructor {
  constructor(growl, batchService, utils, moment, foodProductService) {
    'ngInject';

    this.growl = growl;
    this.batchService = batchService;
    this.utils = utils;
    this.moment = moment;
    this.foodProductService = foodProductService;

    this.steps = {
      PICK_DATE: 1,
      PICK_ITEM: 2
    };
  }

  $onInit() {
    this.user = this.resolve.user;
    this.step = this.steps.PICK_DATE;
    this.endDate = new Date();
    this.endDate.setHours(23,59,59,999);
    this.startDate = moment(new Date()).subtract(30, 'days').toDate();
    this.startDate.setHours(0, 0, 0, 0);
  }

  chooseFoodProduct() {
    this.step = this.steps.PICK_ITEM;
    this.searching = true;
    this.foodProductService.getAllFoodProducts(this.user.orgContext.id)
      .then(results => {
        this.foodProducts = results.foodProducts;
      })
      .finally(()=> this.searching = false);
  }

  submit() {
    let returnObj = {
      reportType: 'productLogs',
      params: {
        foodProductId: this.selectedFoodProduct.$id,
        startDate: this.startDate.getTime(),
        endDate: this.endDate.getTime()
      }
    };

    this.modalInstance.close(returnObj);
  }

  previousStep() {
    this.foodProducts = [];
    this.step -= 1;
  }

  isValid() {
    return _.findIndex(this.batchRuns, (item) => item.checked) > -1;
  }
}

module.exports = Constructor;
