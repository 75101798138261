const Quagga = require('quagga/dist/quagga');

class CfInputGroup {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-barcode-input.template.html');
   // this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      label: '@?',           // The label text
      name: '@',             // The name of the form control
      helpText: '@?',        // Optional subscript helper text
      isRequired: '=',       // Is the field required (true / false)
      isDisabled: '=',       // Is the field disabled (true / false)
      size: '@?',            // Size of component
      isReadonly: '=',       // Is the field read-only (true / false)
      placeholder: '@',      // Input placeholder
    };

    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller($uibModal, $timeout, utils) {
    'ngInject';

    this.$timeout = $timeout;

    this.scan = function() {
      $uibModal.open({
        component: 'cfBarcodeScanner'
      }).result.then(result => {
        this.ngModel = result;
      }).catch(err => utils.defaultErrorHandler(err, 'Unable to open barcode scanner'));
    };
  }

  link(scope) {
    let vm = scope.vm;

    if (!navigator.mediaDevices || !_.isFunction(navigator.mediaDevices.getUserMedia)) {
      vm.showButton = false;

      return;
    }

    vm.showButton = true;

    Quagga.CameraAccess.enumerateVideoDevices().then(devices => {
      vm.$timeout(() => vm.showButton = devices && devices.length);
    }).catch(() => {
      vm.showButton = false;
    });

  }
}

module.exports = CfInputGroup;
