class Constructor {
  constructor(accessRequests, $log, growl) {
    'ngInject';

    this.accessRequests = accessRequests;
    this.$log = $log;
    this.growl = growl;
  }

  approve(event) {
    event.stopPropagation();
    const metadata = this.notification.metaData;

    this.accessRequests.approveAccessRequestNotification(this.user, metadata.accessRequestId,
      this.notification.$id, metadata.emailToken)
      .then(() => this.onDelete())
      .then(() => {
        this.growl.success('Access request approved.');
        this.$log.info('Access request approved by ' + this.user.uid, {notificationId: this.notification.$id});
      })
      .catch(err => {
        this.$log.error('Error occurred approving access request: ' + err);
      });
  }

  reject(event) {
    event.stopPropagation();
    const metadata = this.notification.metaData;

    this.accessRequests.rejectAccessRequestNotification(this.user, metadata.accessRequestId,
      this.notification.$id, metadata.emailToken)
      .then(() => this.onDelete())
      .then(() => {
        this.growl.success('Access request rejected.');
        this.$log.info('Access request rejected by ' + this.user.uid, {notificationId: this.notification.$id});
      })
      .catch(err => {
        this.$log.error('Error occurred rejecting access request: ' + err);
      });
  }
}

module.exports = Constructor;
