module.exports = function(ngModule) {
  ngModule.factory('services', function($q, $firebaseObject, $firebaseArray, fbutil, organizations,
                                        email, notifications, authorization, $log) {
    'ngInject';
    const statuses = {
      INITIAL: {
        id: 1,
        text: 'Not sent'
      },
      INVOICED: {
        id: 2,
        text: 'Invoice Sent'
      },
      PENDING_PAYMENT: {
        id: 3,
        text: 'Pending Payment'
      },
      PAID: {
        id: 4,
        text: 'Paid'
      }
    };

    function lookupService(serviceId) {
      return fbutil.ref('services', serviceId).once('value').then(serviceSnap => {
        if (!serviceSnap.exists()) { return; }
        let retVal = serviceSnap.val();

        retVal.$id = serviceId;
        return retVal;
      });
    }

    function getOrgServices(orgId) {
      return fbutil.ref('organizations', orgId, 'services').once('value').then(services => {
        if (!services.exists()) { return []; }
        return $q.all(_.map(services.val(), (val, serviceId) => lookupService(serviceId)));
      });
    }

    function setStatusDate(serviceId) {
      return fbutil.ref('services', serviceId, 'statusDate').set(new Date().getTime());
    }

    function initialize(serviceFbObj) {
      if (!serviceFbObj.organizationId || !serviceFbObj.providerOrgId) {
        return $q.reject('Invalid service');
      }
      serviceFbObj.status = statuses.INITIAL.id;
      serviceFbObj.statusDate = new Date().getTime();
      return serviceFbObj.$save()
        .then(() => {
          return $q.all([
            fbutil.ref('organizations', serviceFbObj.organizationId, 'services', serviceFbObj.$id).set(true),
            fbutil.ref('organizations', serviceFbObj.providerOrgId, 'providedServices', serviceFbObj.$id).set(true)
          ]);
        });
    }

    function invoiceNotifications(user, service) {
      return organizations.getPrimaryContact(service.organizationId)
        .then(primaryContact => {
          let subject = 'Invoice for ' + service.name,
            content = 'An invoice has been generated for: ' + service.name + '. Description: ' +
              service.description + '. Please login to FoodReady and navigate to "My Account" to view ' +
              'your invoices. ';

          if (primaryContact.pointOfContactEmail) {
            email.sendTemplate({
              email: primaryContact.pointOfContactEmail, name: primaryContact.pointOfContactName,
              subject: subject
            }, 'generic_email', [
              {name: 'bodyText', content: content},
              {name: 'titleSection', content: 'Dear ' + primaryContact.pointOfContactName + ','},
              {name: 'userFullName', content: primaryContact.pointOfContactName}
            ]).catch($log.error);
          }

          email.sendTemplate({
            email: 'william@foodready.ai',
            name: 'William',
            subject: subject
          }, 'generic_email', [
            {name: 'bodyText', content: content},
            {name: 'titleSection', content: 'Dear ' + primaryContact.pointOfContactName + ','},
            {name: 'userFullName', content: 'William'}
          ]).catch($log.error);

          notifications.postToOrg({
            from: user.uid,
            to: service.organizationId,
            message: 'Invoice created for service: <b>' + service.name + '</b>.',
            type: notifications.types.SERVICE,
            link: {
              state: 'user.invoices',
              requiredClaim: authorization.claims.BILLING_READ
            }
          });

          notifications.postToOrg({
            from: user.uid,
            to: service.providerOrgId,
            message: 'Invoice Created: ' + service.name,
            type: notifications.types.SERVICE,
            metaData: {
              organizationId: service.organizationId
            },
            link: {
              state: 'analysis.team.services',
              params: {
                providerOrgId: service.providerOrgId
              }
            }
          });
        });
    }

    return {
      get: (serviceId) => {
        return $firebaseObject(fbutil.ref('services', serviceId)).$loaded();
      },
      add: (serviceData) => {
        return $firebaseObject(fbutil.ref('services').push(serviceData)).$loaded().then(serviceFbObj => {
          return initialize(serviceFbObj).then(() => serviceFbObj);
        });
      },
      initialize: (serviceFbObj) => {
        return initialize(serviceFbObj);
      },
      sendInvoice: (user, service) => {
        service.status = statuses.INVOICED.id;
        return fbutil.ref('services', service.$id, 'status').set(statuses.INVOICED.id)
          .then(() => setStatusDate(service.$id))
          .then(() => invoiceNotifications(user, service));
      },
      resendInvoiceNotifications: (user, service) => {
        invoiceNotifications(user, service);
      },
      setPaid: (service) => {
        service.status = statuses.PAID.id;
        return fbutil.ref('services', service.$id, 'status').set(statuses.PAID.id)
          .then(() => setStatusDate(service.$id));
      },
      setOrder: (service, orderId) => {
        service.status = statuses.PENDING_PAYMENT.id;
        return fbutil.ref('services', service.$id, 'orderId').set(orderId)
          .then(() => {
            return fbutil.ref('services', service.$id, 'status').set(statuses.PENDING_PAYMENT.id);
          })
          .then(() => setStatusDate(service.$id));
      },
      getOrgsServices: (orgId) => {
        return getOrgServices(orgId);
      },
      remove: (service) => {
        $q.all([
          fbutil.ref('organizations', service.organizationId, 'services', service.$id).remove(),
          fbutil.ref('organizations', service.providerOrgId, 'providedServices', service.$id).remove()
        ]).then(() => fbutil.ref('services', service.$id).remove());
      },
      statusText: (service) => {
        return _.find(statuses, s => s.id === service.status).text;
      },
      isPaid: service => service.status === statuses.PAID.id,
      isSent: service => service.status >= statuses.INVOICED.id
    };
  });
};
