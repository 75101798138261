class CfCurrencyInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-currency-input.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',         // The form control model reference
      label: '@',           // The label text
      name: '@',            // The name of the form control
      helpText: '@',        // Optional subscript helper text
      isRequired: '=',      // Is the field required (true / false)
      isReadonly: '=',      // Is the field readonly (true / false)
      isInvalid: '=',       // Is the field invalid (true / false)
      invalidText: '@',     // The text to display if the field is invalid.
      placeholder: '@'      // Input placeholder
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';

    this.invalidText = this.invalidText || 'Please enter a valid value.';
  }
}

module.exports = CfCurrencyInput;
