'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRejectOrgReadRequest', {
    bindings: {
      user: '='
    },
    template: require('./company.reject-read-request.template.html'),
    controller: require('./company.reject-read-request.controller'),
    controllerAs: 'vm'
  });
};
