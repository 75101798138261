require('./popover.scss');

module.exports = function(ngModule) {
  ngModule.directive('cfPopover', function() {
    return {
      restrict: 'A',
      scope: {
        cfPopover: '='
      },
      link: function(scope, el) {
        el.popover({
          content: scope.cfPopover,
          container: 'body'
        });

        let popover = el.data('bs.popover');

        //popover.show();
        scope.$watch('cfPopover', newVal => {
          popover.config.content = newVal;
          popover.setContent();
        });
      }
    };
  });

  ngModule.directive('cfPopoverHtml', function() {
    return {
      restrict: 'A',
      scope: {
        cfPopoverHtml: '=',
        popoverPlacement: '='
      },
      link: function(scope, el) {
        const options = {
          content: scope.cfPopoverHtml,
          html: true,
          container: 'body'
        };

        if (scope.popoverPlacement) {
          options.placement = scope.popoverPlacement;
        }

        el.popover(options);

        let popover = el.data('bs.popover');

        scope.$watch('cfPopoverHtml', newVal => {
          popover.config.content = newVal;
          popover.setContent();
        });
      }
    };
  });
};
