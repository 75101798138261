'use strict';

module.exports = function(ngModule) {
  ngModule.factory('cfHttpInterceptor', function($q, $firebaseAuth, $injector, $timeout, $window) {
    var retrieveTokenPromise, localStorage = $window.localStorage;

    function getFbToken() {
      return $firebaseAuth().$waitForSignIn().then(authData => {
        if (!authData) { return; }
        return authData.getToken();
      });
    }

    function setRetrievePromise() {
      retrieveTokenPromise = getFbToken();

      retrieveTokenPromise.then(token => {
        retrieveTokenPromise = undefined;
        if (!token || !localStorage) { return; }
        localStorage.setItem('session.fbToken', token);
        var expire = new Date();

        // Persist the newly acquired token for 60 seconds.
        expire.setTime(expire.getTime() + 60000);
        localStorage.setItem('session.fbToken.expire', expire.getTime());
      });
    }

    return {
      request: function(config) {
        // Omit non-local urls
        if (config.url.indexOf('://') !== -1) {
          return config;
        }
        if (config._isTemplate) {
          return config;
        }

        config.url = config.url.trim();
        
        config.url = `api/${config.url?.startsWith('/') ? config.url.replace(/^\/{0,}/g, '') : config.url}`;
        // config.url = 'api/' + config.url;

        if (config.url.endsWith('/')) {
          config.url = config.url.replace(/\/{0,}$/g, '');
        }

        // Check local storage FB token
        var storedToken = localStorage && localStorage.getItem('session.fbToken');

        if (storedToken) {
          var expires = localStorage.getItem('session.fbToken.expire');

          if (expires && expires > new Date().getTime()) {
            config.headers.authorization = storedToken;
            return config;
          }
        }

        // Generate a new token
        if (!retrieveTokenPromise) {
          setRetrievePromise();
        }
        return retrieveTokenPromise.then(token => {
          config.headers.authorization = token;
          return config;
        });
      },
      responseError: function(response) {
        if (response.status !== 419) {
          return $q.reject(response);
        }

        // An error occurred parsing the FB token. Get a new one and retry.
        var $http = $injector.get('$http');

        if (!retrieveTokenPromise) {
          setRetrievePromise();
        }
        return retrieveTokenPromise.then(token => {
          response.config.headers.authorization = token;
          response.config.headers['CfApiKey-Retry'] = true;
          return $http(response.config);
        });
      }
    };
  });
};
