class Controller {
  constructor($log, $timeout, $document, $window, growl, marketplaceService, utils,
              moment, S3, CF_ORDER_ITEM_STATUS) {
    'ngInject';

    this.$log = $log;
    this.$timeout = $timeout;
    this.$document = $document;
    this.$window = $window;
    this.growl = growl;
    this.marketplaceService = marketplaceService;
    this.utils = utils;
    this.moment = moment;
    this.saving = false;
    this.savingTimeout = null;
    this.letterHtml = '<div class="text-center text-muted g-mt-30 g-mb-30">' +
      '<i class="fas fa-sync fa-spin fa-fw"></i> Loading...</div>';
    this.S3 = S3;
    this.CF_ORDER_ITEM_STATUS = CF_ORDER_ITEM_STATUS;
    this.unWatchOrderItem = null;
  }

  $onInit() {
    this.$letter = this.$letter || {};
    this.orderId = this.$orderItem.$ref().ref.parent.parent.key;

    // keep track of itemId for the custom text editor toolbar buttons
    this.marketplaceService.orderItem = this.utils.fbToPojo(this.$orderItem);
    this.marketplaceService.order = this.orderId;

    this.save = _.debounce(this._save, 1000);

    this.toolbar = [
      ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'quote'],
      ['ul', 'ol', 'outlineTypeLowerAlpha', 'outlineTypeUpperAlpha', 'outlineTypeLowerRoman', 'outlineTypeUpperRoman',
        'outlineTypeNumeric'],
      ['bold', 'italics', 'underline', 'strikeThrough', 'redo', 'undo', 'clear'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
      ['insertImage','insertLink'],
      ['loadTemplate', 'saveAsTemplate', 'preview'],
      ['html']
    ];

    this.showTextAngular = false;
    this.fetchLetterHtml(this.$orderItem);
    this.unWatchOrderItem = this.$orderItem.$watch(() => {
      this.fetchLetterHtml(this.$orderItem);
    });
  }

  fetchLetterHtml(orderItem) {
    if (orderItem.status === this.CF_ORDER_ITEM_STATUS.COMPLETED) {
      this.marketplaceService.getLetterHtml(orderItem.letterId, true)
        .then((result) => this.letterHtml = result.data);
    }
  }

  $onDestroy() {
    // remove references to the itemId
    this.unWatchOrderItem();
  }

  setSaving(saving) {
    if (saving) {
      if (this.savingTimeout) { this.$timeout.cancel(this.savingTimeout); }
      this.saving = true;
    } else {
      this.savingTimeout = this.$timeout(() => this.saving = false, 1000);
    }
  }

  _save() {
    if (!this.$letter || this.$orderItem.$isDestroyed === true) { return; }

    this.setSaving(true);
    this.$letter.savedOn = new Date().getTime();
    let savePromise = this.$letter.$save();

    if (_.isEmpty(this.$orderItem.letterId)) {
      savePromise = savePromise.then(() => {
        this.$orderItem.letterId = this.$letter.$id;
        return this.$orderItem.$save();
      });
    }

    savePromise
      .then(() => {
        if (this.$orderItem.status !== this.CF_ORDER_ITEM_STATUS.COMPLETED) {
          this.$orderItem.status = this.CF_ORDER_ITEM_STATUS.PROCESSING;
          return this.$orderItem.$save();
        }
      })
      .catch((reason) => {
        this.growl.error('Unable to save letter.');
        this.$log.error(reason);
      }).finally(() => this.setSaving(false));
  }

  openAsPdf(orderId, orderItem) {
    this.S3.downloadPdf(orderItem.clientOrgId, 'organizations/' + orderItem.clientOrgId + '/orders/' +
      orderId + '/items/' + orderItem.$id + '/letters/' + orderItem.service.key + '.pdf');
  }

  printLetter() {
    let newWin = this.$window.open('', '_blank');

    this.marketplaceService.getLetterHtml(this.$orderItem.letterId, false)
      .then((result) => {
        newWin.document.open();
        newWin.document.write(result.data);
        newWin.onload = function() { newWin.print(); newWin.close(); };
        newWin.document.close();
      });
  }

  humanize(lastSaved) {
    return this.moment.duration(this.moment(lastSaved).diff(this.moment())).humanize(true);
  }
}

module.exports = Controller;
