'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfEditSafetyTeam', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./safety-team.edit.template.html'),
    controller: require('./safety-team.edit.controller.js'),
    controllerAs: 'vm'
  });
};
