module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider.state('checklists.operational', {
      url: '/operational',
      template: '<div ui-view></div>',
      resolve: {
        user: (authentication) => authentication.requireAuth(),
        company: (user, organizations) => organizations.$get(user.orgContext.id)
      },
      data: {menuId: 'dashboard'}
    })
      .state('checklists.operational.list', {
        url: '/list',
        template: '<fr-operational-checklist-list company="$resolve.company" user="$resolve.user" ' +
          '></fr-operational-checklist-list>',
      }).state('checklists.operational.edit', {
        url: '/edit?checklistId',
        params: {checklistTemplate: null},
        template: '<fr-operational-checklist-edit company="$resolve.company" user="$resolve.user" ' +
        'checklist="$resolve.checklist"></fr-operational-checklist-edit>',
        resolve: {
          checklist: (user, operationalChecklistService, $stateParams) => {
            if($stateParams.checklistId) {
              return operationalChecklistService.getChecklist(user.orgContext.id, $stateParams.checklistId);
            } else if($stateParams.checklistTemplate) {
              return $stateParams.checklistTemplate;
            } else {
              return null;
            }
          }
        }
      });
  });
};
