require('./barcode-scanner.scss');

module.exports = function(ngModule) {
  ngModule.component('cfBarcodeScanner', {
    bindings: {
      modalInstance: '<'
    },
    template: require('./barcode-scanner.template.html'),
    controller: require('./barcode-scanner.controller.js'),
    controllerAs: 'vm'
  });
};
