class Controller {
  constructor($stateParams, hazardsService, controlsService, planTypes, $q, confirmModal, $scope) {
    'ngInject';

    this._ = _;
    this.$stateParams = $stateParams;
    this.hazardsSvc = hazardsService;
    this.controlsSvc = controlsService;
    this.planTypes = planTypes;
    this.$q = $q;
    this.confirmModal = confirmModal;
    this.numHazards = -1;
    this.$scope = $scope;
  }

  $onInit() {
    if (this.$stateParams.hazardId) {
      this.selectedHazard = _.find(this.$hazards, {$id: this.$stateParams.hazardId});
    }
    this.isHaccp = this.product.planType === this.planTypes.HACCP_PLAN.id;

    this.$scope.$watch('this.product', () => {
      this.syncProduct();
    });
    this.syncProduct();
  }

  syncProduct() {
    // If number of hazards changed, update the grouping
    if (this.numHazards !== _.keys(this.product.hazards).length) {
      this.hazardGroups = _.values(_.reduce(this.$hazards, (groups, hazard) => {
        const name = (hazard.groupId ? this.product.hazardGroups[hazard.groupId].name : hazard.name) || '';
        const key = hazard.key || hazard.type + name.replace(/\s/g,'');

        if (!groups[key]) {
          groups[key] = {
            instances: [],
            containsGroup: !!hazard.groupId,
            groupHazardNames: hazard.groupId && this.product.hazardGroups[hazard.groupId].hazards.replace('|', ', '),
            name: name,
            type: hazard.groupId ? this.product.hazardGroups[hazard.groupId].type : hazard.type
          };
        }
        hazard.introStepNumber = _.get(this.product, `processSteps.${hazard.introductionStep}.number`, 0);
        hazard.stepName = _.get(this.product, `processSteps.${hazard.introductionStep}.name`, '');
        if (hazard.introStepNumber) {
          hazard.stepName = `${hazard.introStepNumber}. ${hazard.stepName}`;
        }
        groups[key].instances.push(hazard);
        return groups;
      }, {}));
    }

    this.hazardControls = _.reduce(this.product.controls, (map, control) => {
      _.each(control.hazards, (val, hazardId) => {
        map[hazardId] = map[hazardId] || [];

        map[hazardId].push(control);
      });
      return map;
    }, {});

    this.hazardCcps = _.reduce(this.hazardControls, (map, controls, hazardId) => {
      _.each(controls, control => {
        if (this.controlsSvc.isCritical(control)) {
          let ccpName = this.isHaccp ? _.get(this.product, `processSteps[${control.stepId}].name`, '') :
            `${_.capitalize(control.type)} Controls - <i>${_.get(this.product, `processSteps[${control.stepId}].name`, '')}</i>`;

          if (map[hazardId]) {
            map[hazardId] = map[hazardId] + '<br>' + ccpName;
          } else {
            map[hazardId] = ccpName;
          }
        }
      });
      return map;
    }, {});
  }

  addGroup() {
    this.hazardsSvc.editGroup(this.product).then(hazard => {
      this.onEditHazard({hazard});
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to add hazard group.'));
  }

  numControls(hazard) {
    return this.hazardControls ? _.keys(this.hazardControls[hazard.$id]).length : 0;
  }

  editHazard(hazard) {
    this.originalHazard = this.selectedHazard;
    this.selectedHazard = this.selectedHazard !== hazard ? hazard : null;

    this.onEditHazard({hazard})
      .catch(() => this.selectedHazard = this.originalHazard);
  }

  removeHazard(hazard) {
    this.onRemoveHazard({hazard})
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove hazard.'))
      .finally(() => {
        this.selectedHazard = null;
        this.$stateParams.hazardId = undefined;
      });
  }

  needsControl(hazard) {
    return hazard.isSignificant && !this.hazardControls[hazard.$id];
  }

  highlightHazard(hazard) {
    if (this.$stateParams.hazardId === hazard.$id) {
      this.clearHighlight();
    } else {
      this.$stateParams.hazardId = hazard.$id;
    }
  }

  highlightGroup(group) {
    this.clearHighlight();
    if (group.isOpen) {
      this.$stateParams.hazardId = _.map(group.instances, (h) => h.$id).join(',');
    }
  }

  clearHighlight() {
    this.$stateParams.hazardId = null;
  }

  editGroup($event, group) {
    $event.stopPropagation();
    this.hazardsSvc.editGroup(this.product, _.first(group.instances).groupId);
  }

}

module.exports = Controller;
