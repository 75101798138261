module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceCheckout', {
    bindings: {
      user: '<',
      organization: '<',
      shoppingCartItems: '<'
    },
    template: require('./marketplace.checkout.template.html'),
    controller: require('./marketplace.checkout.controller.js'),
    controllerAs: 'vm'
  });
};
