class Controller {
  constructor($log, $uibModal, $q, growl, controlsService, confirmDeleteModal, sopService, $state, $scope, $window) {
    'ngInject';

    this._ = _;

    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.growl = growl;
    this.controlsSvc = controlsService;
    this.confirmDeleteModal = confirmDeleteModal;
    this.$state = $state;
    this.$scope = $scope;
    this.$window = $window;
  }

  $onInit() {
    Object.keys(this.facilitySops).forEach((sopId) => {
      if (this.facilitySops[sopId].firebase_id) {
        this.facilitySops[this.facilitySops[sopId].firebase_id] = this.facilitySops[sopId];
      }
    });
  }

  addPrerequisite() {
    const beforeAddPromise = _.isFunction(this.beforeAddPrerequisite) ?
      this.$q.when(this.beforeAddPrerequisite({$control: this.$control})) : this.$q.resolve();

    beforeAddPromise
      .then(() => {
        const sopsToPick = _.pickBy(
          this.facilitySops,
          (sop, id) => _.indexOf(_.keys(this.$control.prerequisites), id) === -1
        );

        return this.$uibModal
          .open({
            component: 'cfChooseFromListModal',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              itemName: () => 'procedure',
              allowSkip: true,
              skipButtonHtml: () => '<i class="far fa-plus mr-2"></i> Create New SOP',
              instructionsHtml: () =>
                '<div class="g-mb-15">Choose a procedure from your list of prerequisite ' +
                'programs or create a new one.</div>',
              header: () => '<i class="far fa-list-alt fa-fw"></i> Choose SOP',
              itemsArray: () => _.map(sopsToPick, (sop, id) => ({id, title: sop.title})),
              columns: () => [
                {
                  title: 'Procedure',
                  property: 'title'
                }
              ]
            }
          })
          .result
          .then((chosen) => {
            if (!chosen) {
              this.$window.open('app/operations/sops/new', '_blank');
            }
            return this.controlsSvc.pushPrerequisite(this.product.$id, this.$control.$id, chosen.id);
          })
          .then(() => this.growl.success('Prerequisite procedure added successfully.'));
      });
  }

  removePrerequisite(control, sopId) {
    const sop = this.facilitySops[sopId];

    this.confirmDeleteModal('Procedure', {
      headerHtml: '<span class="text-danger">Remove <strong>Prerequisite Procedure</strong>?</span>',
      confirmText: 'Remove',
      body: `Are you sure you want to remove prerequisite procedure <strong>${sop ? sop.title : 'Not Found'}</strong>?`
    }).then(() => {
      this.controlsSvc.removePrerequisite(this.product.$id, control.$id, sopId)
        .then(() => this.growl.success('Prerequisite procedure removed successfully.'))
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove procedure from the control.'));
    });
  }
  viewPrerequisite(sopId) {
    this.$uibModal.open({
      component: 'viewSop',
      controllerAs: 'vm',
      size: 'xl',
      resolve: {
        sop: () => this.facilitySops[sopId]
      }
    }).result
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to open sop.'));
  }
}

module.exports = Controller;
