module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider.state('reports', {
      url: '/reports',
      template: '<cf-reports $company="$resolve.$company" user="$resolve.user"></cf-reports>',
      resolve: {
        user: (authentication) => authentication.requireAuth(),
        $company: (user, organizations) => organizations.$get(user.orgContext.id)
      },
      data: {menuId: 'dashboard'}
    });
  }).run((menus, authorization, orgPerspectives) => {
    'ngInject';

    menus.addMenuItem('dashboard', {
      title: 'Reports',
      redirectPath: '/reports',
      position: 10,
      isPublic: false,
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP],
      claims: [authorization.claims.REPORTS_READ]
    });
  });
};
