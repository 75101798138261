module.exports = function(ngModule) {
  ngModule.component('frRecallEventsComplaint', {
    bindings: {
      $productComplaints: '<',
      $event: '=',
      user: '<'
    },
    template: require('./recall.events.complaint.controller.html'),
    controller: require('./recall.events.complaint.controller'),
    controllerAs: 'vm'
  });
};
