module.exports = function(moment, users, $state, fbutil, growl, $log, $window, $http, $timeout, notifications,
                          $uibModal, utils, notificationTypes) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    vm.notificationTypes = notificationTypes();

    // If the notification isn't linked, we gotta mark as read somehow...wait 5 seconds and then mark it.
    if (!vm.linked()) {
      $timeout(() => vm.onRead(), 5000);
    }

    vm.isSystemMessage = vm.getIsSystemMessage();
    if (vm.isSystemMessage) {
      vm.createdByName = 'FoodReady';
      return;
    }

    if (vm.notification.createdBy) {
      users.getName(vm.notification.createdBy)
        .then((name) => vm.createdByName = name);
    }
  };

  vm.linked = function() {
    return vm.notification.modal || vm.notification.link &&
      (!vm.notification.link.requiredClaim || vm.user.hasPermission(vm.notification.link.requiredClaim));
  };

  vm.getIsSystemMessage = function() {
    return vm.notification.createdBy === 'system' ||
      vm.notification.createdBy === 'googleFunctions-planHandler' ||
      vm.notification.createdBy === 'lambda:1' ||
      !vm.notification.createdBy;
  };

  vm.humanizeAge = function(createdOn) {
    let now = new Date().getTime();

    return moment.duration(moment(createdOn).diff(now)).humanize(true);
  };

  vm.isUnread = function() {
    return _.indexOf(vm.notification.read, vm.user.uid) === -1;
  };

  vm.go = function() {
    if (vm.deleting) { return; }

    if (vm.notification.modal) {
      $uibModal.open({
        size: vm.notification.modal.size,
        component: vm.notification.modal.component,
        resolve: _.assign(_.reduce(vm.notification.modal.resolve, (result, entry, entryKey) => {
          result[entryKey] = () => entry;
          return result;
        }, {}), {
          user: () => vm.user
        })
      }).result
        .then(() => {
          if (vm.notification.modal.deleteOnComplete) {
            vm.delete(true);
          } else {
            vm.onRead();
          }
        })
        .catch(err => {
          if (utils.isModalDismissalByUser(err)) { return; }

          $log.error(`Unable to open a notification modal. Component ${vm.notification.modal.component}`,
            $log.toString(err));
          growl.error('Unable to open the notification. Contact customer support if this continues.');
        });
    }

    if (!vm.notification.link) { return; }

    if (vm.notification.link.state) {
      $state.go(vm.notification.link.state, vm.notification.link.params);
    } else if (vm.notification.link.uri && vm.notification.link.newTab) {
      let reportWindow = $window.open('/api/reports/loading');

      $http.get(vm.notification.link.uri)
        .success((url) => reportWindow.location.replace(url));
    } else if (vm.notification.link.url) {
      $window.open(vm.notification.link.url, '_blank');
    } else {
      return;
    }

    vm.onRead();
  };

  vm.onRead = function() {
    if (vm.isUnread()) {
      vm.notification.read = vm.notification.read || [];
      vm.notification.read.push(vm.user.uid);
      notifications.setRead(vm.user.uid, vm.notification.$id);
    }
  };

  vm.onUnRead = function() {
    if (!vm.isUnread()) {
      notifications.markUnread(vm.notification.$id)
        .then(() => _.remove(vm.notification.read, (n) => n === vm.user.uid));
    }
  };

  vm.delete = function(deleteAll) {
    vm.deleting = true;
    vm.onDelete();

    let deletePromsie = deleteAll ? notifications.delete(vm.notification) :
      notifications.smartDelete(vm.user.uid, vm.notification);

    deletePromsie.catch(err => {
      $log.error(err);
      growl.error('Unable to delete notification');
    });
  };
};
