module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceChooseServiceModal', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./marketplace.choose-service-modal.template.html'),
    controller: require('./marketplace.choose-service-modal.controller.js'),
    controllerAs: 'vm'
  });
};
