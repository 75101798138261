module.exports = function(fbutil, $log, $stateParams, growl, $state, $q, notifications, urlTokens,
                          accessRequests, products) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    if (!vm.user) {
      return;
    }
    return urlTokens.legacyGet($stateParams.requestId).then(tokenData => {
      if (!tokenData) {
        $state.go('user.dashboard');
        growl.error('Request not found!');
      }

      return accessRequests.$get(tokenData.accessRequestId).then(accessRequest => {
        if (accessRequest.status === 'approved' || accessRequest.status === 'rejected') {
          throw new Error('Token no longer valid');
        }
        let promises = [];

        if (accessRequest.type === accessRequests.requestTypes.ORG_ROLE_REQUEST) {
          vm.message = 'You have rejected <b>' + accessRequest.createdByOrgName +
            '</b> access to your product names.';
        } else if (accessRequest.type === accessRequests.requestTypes.ORG_PRODUCT_EXCEPTION_REQUEST) {
          promises.push(products.$get(accessRequest.productId).then(product => {
            vm.message = 'You have rejected the <b>' + accessRequest.createdByOrgName + '</b> ' +
              'request to access your <b>' + product.brandName + '</b> reports.';
          }));
        }

        promises.push(this.accessRequests.rejectAccessRequestNotification(vm.user, tokenData.accessRequestId,
          tokenData.notificationId, $stateParams.requestId));

        vm.permissionRejected = true;
        return $q.all(promises);
      });
    }).catch(err => {
      vm.permissionRejected = false;
      $log.log(err);
      throw err;
    });
  };
};
