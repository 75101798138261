'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfCustomers', {
    bindings: {
      user: '<',
      orders: '<',
      productCustomer: '<'
    },
    template: require('./product-customers.template.html'),
    controller: require('./product-customers.controller'),
    controllerAs: 'vm'
  });

  require('./overview')(ngModule);
  require('./orders')(ngModule);
};
