'use strict';

module.exports = function(ngModule) {
  ngModule.constant('eventInfo', {
    symposiumSingleDayPrice: {
      id: 1,
      amount: 80,
      title: 'Single Day Pass - 2017 Illinois Food Safety Symposium'
    },
    symposiumTwoDayPrice: {
      id: 2,
      amount: 140,
      title: 'Two Day Pass - 2017 Illinois Food Safety Symposium'
    }
  });
};
