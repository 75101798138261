'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfVerifications', {
    bindings: {
      verificationSearch: '=',
      productOrganization: '=',
      title: '@',
      substituteName: '@',
      product: '=',
      target: '@',
      externalOnly: '=?',
      user: '<'
    },
    template: require('./products.verifications.template.html'),
    controller: require('./products.verifications.controller.js'),
    controllerAs: 'vm'
  });
};
