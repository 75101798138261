module.exports = function(ngModule) {
  ngModule.component('cfNoticeLetter', {
    bindings: {
      recall: '=',
      letterVersions: '=',
      company: '=',
      product: '=',
      user: '<'
    },
    template: require('./products.recall-plan.notice-letter.template.html'),
    controller: require('./products.recall-plan.notice-letter.controller'),
    controllerAs: 'vm'
  });
};
