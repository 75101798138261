module.exports = function(ngModule) {
  require('./breadcrumbs')(ngModule);
  require('./ng_enter')(ngModule);
  require('./upload_progress')(ngModule);
  require('./signature_pad')(ngModule);
  require('./currency_mask')(ngModule);
  require('./size_watcher')(ngModule);
  require('./ng_password_strength')(ngModule);
  require('./mobile_tab')(ngModule);
  require('./popover')(ngModule);
  require('./responsive_header')(ngModule);
  require('./bind_html_compile')(ngModule);
  require('./cf_form_controls')(ngModule);
};
