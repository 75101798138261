module.exports = function(ngModule) {
  ngModule.component('cfEditRequiredHazardFields', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./products.edit-required-hazard-fields.template.html'),
    controller: require('./products.edit-required-hazard-fields.controller'),
    controllerAs: 'vm'
  });
};
