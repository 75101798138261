class Controller {
  constructor($log, growl) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
  }

  $onInit() {
    this.saving = false;
    this.modalTitle = this.resolve.modalTitle;
    this.$documentTemplates = this.resolve.$documentTemplates;
    this.documentTemplate = _.assign({}, {serviceId: this.resolve.serviceId, markup: this.resolve.markup});
    this.nameMap = _.keyBy(_.filter(this.$documentTemplates, {documentType: this.resolve.documentType}),  'name');
  }

  ok() {
    this.saving = true;
    this.$documentTemplates.$add(this.documentTemplate)
      .then((ref) => {
        this.documentTemplate.$id = ref.key;
        this.modalInstance.close(this.documentTemplate);
      })
      .catch((err) => {
        this.growl.error('Unable to add safety letter template. Please try again or contact ' +
          'customer support for assistance');
        this.$log.error(err);
        this.saving = false;
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
