'use strict';

module.exports = function(ngModule) {
  ngModule.factory('csvImportModal', function($uibModal, growl, $log, $window) {
    'ngInject';

    return function(options, user) {
      if (!$window.File || !$window.FileReader || !$window.FileList || !$window.Blob) {
        growl.error('Browser not capable of file import.');
        $log.error('Browser not capable of file import.');
        return;
      }

      return $uibModal.open({
        component: 'cfCsvImport',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          fields: () => options.fields,
          user: () => user
        }
      }).result;
    };
  });
};
