module.exports = function(ngModule) {
  class RequestForQuote {
    constructor($firebaseObject, fbutil, $q, $http, marketplaceRfqQuoteStatus, marketplaceRfqStatus, users,
                CF_ORGANIZATION_ID) {
      'ngInject';

      this.$firebaseObject = $firebaseObject;
      this.fbutil = fbutil;
      this.$q = $q;
      this.$http = $http;
      this.marketplaceRfqQuoteStatus = marketplaceRfqQuoteStatus;
      this.marketplaceRfqStatus = marketplaceRfqStatus;
      this.users = users;
      this.CF_ORGANIZATION_ID = CF_ORGANIZATION_ID;

      this.baseUri = 'rfqs';
    }

    /**
     * Gets an array of RFQs for either the requesting or subject organization.
     * @param {string} orgId The organization ID
     * @param {boolean=} isRequester Is the organization a provider (e.g. get orders where orgId is a preferred
     *   provider)
     * @param {string=} searchText Optional search text for product brand name or service title
     * @param {number=} from The starting index of the result set
     * @param {number=} size The size of the result set
     * @param {object=} filters Additional filters.
     * @param {boolean=} filters.isOpen Include active orders
     * @param {boolean=} filters.isClosed Include closed orders
     * @returns {Promise<Array>} A promise resolving to the list of RFQs
     */
    get(orgId, isRequester = false, searchText, from, size, filters) {
      let url = `${this.baseUri}?${orgId ? 'organizationId=' + orgId + '&' : ''}isRequester=${isRequester}`;

      if (!_.isEmpty(searchText)) { url += '&searchText=' + searchText; }
      if (_.isInteger(from)) { url += '&from=' + from; }
      if (_.isInteger(size)) { url += '&size=' + size; }
      if (filters) {
        if (filters.isOpen) { url += '&isOpen=true'; }
        if (filters.isClosed) { url += '&isClosed=true'; }
      }

      return this.$http.get(url)
        .then((result) => result.data);
    }

    $get(rfqId) {
      return this.$firebaseObject(this.fbutil.ref('requestForQuotes', rfqId)).$loaded();
    }

    /**
     * Push a new rfq record. The pushed object can be empty (undefined).
     * @param {object} rfqRec  A new rfq record.
     * @return {*}  A promise that resolves to an empty $firebaseObject.
     */
    $push(rfqRec) {
      return this.$firebaseObject(this.fbutil.ref('requestForQuotes').push(rfqRec)).$loaded();
    }

    /**
     * Push a new quote onto the requestForQuote and initialize it.
     * @param {object} user Logged in user.
     * @param {object} rfq The requestForQuote object.
     * @return {$firebaseObject} A promise that resolves to a new FirebaseObject quote.
     */
    $pushQuote(user, rfq) {
      return this.$firebaseObject(this.fbutil.ref('requestForQuotes', rfq.$id, 'quotes').push()).$loaded()
        .then($quote => {
          $quote.createdBy = user.uid;
          $quote.createdOn = firebase.database.ServerValue.TIMESTAMP;
          return $quote;
        });
    }

    /**
     * Sets the status for a RFQ.
     * @param {object} user Logged in user
     * @param {string} rfqId Request for quote id.
     * @param {string} status Status to set
     * @returns {Promise} A promise that is resolved when the status is updated.
     */
    setStatus(user, rfqId, status) {
      return this.fbutil.ref('requestForQuotes', rfqId).once('value').then(rfqSnap => {
        if (!rfqSnap.exists()) {
          throw new Error('RFQ not found');
        }

        let val = rfqSnap.val();

        val.status = status;
        val.updatedBy = user.uid;
        val.updatedDate = firebase.database.ServerValue.TIMESTAMP;

        return rfqSnap.ref.set(val);
      });
    }

    /**
     * Assigns an RFQ.
     * @param {string} rfqId Request for quote id.
     * @param {string} providerId The provider org id.
     * @param {object} user Logged in user
     * @returns {Promise} A promise that is resolved when the assignment has been completed.
     */
    assign(rfqId, providerId, user) {
      return this.fbutil.ref('requestForQuotes', rfqId).once('value')
        .then((rfqSnap) => {
          if (!rfqSnap.exists()) { throw new Error('RFQ not found'); }

          let val = rfqSnap.val();

          val.subjectOrgId = providerId;
          val.assignment = {
            assignedOn: firebase.database.ServerValue.TIMESTAMP,
            assignedBy: user.uid
          };

          return rfqSnap.ref.set(val)
            .then(() => val);
        });
    }

    /**
     * Un-assigns an RFQ.
     * @param {string} rfqId Request for quote id.
     * @returns {Promise} A promise that is resolved when the un-assignment has been completed.
     */
    unAssign(rfqId) {
      return this.$q.all([
        this.fbutil.ref('requestForQuotes', rfqId, 'assignment').remove(),
        this.fbutil.ref('requestForQuotes', rfqId, 'subjectOrgId').set(this.CF_ORGANIZATION_ID)
      ]);
    }

    /**
     * Reject RFQ Assignment.
     * @param {string} rfqId Request for quote id.
     * @param {string} reason Reason for the rejection.
     * @param {object} user Logged in user
     * @returns {Promise} A promise that is resolved when the assignment has been rejected.
     */
    rejectAssignment(rfqId, reason, user) {
      return this.fbutil.ref('requestForQuotes', rfqId).once('value')
        .then((rfqSnap) => {
          if (!rfqSnap.exists()) { throw new Error('RFQ not found'); }

          let val = rfqSnap.val();

          _.assign(val.assignment, {
            rejectedOn: firebase.database.ServerValue.TIMESTAMP,
            rejectedByOrgId: val.subjectOrgId,
            rejectedByUserId: user.uid,
            rejectedReason: reason
          });

          val.subjectOrgId = this.CF_ORGANIZATION_ID;

          return rfqSnap.ref.set(val)
            .then(() => val);
        });
    }

    /**
     * Sets the hidden flag for a RFQ.
     * @param {string} rfqId Request for quote id.
     * @param {boolean} hidden true or false
     * @returns {Promise} A promise that is resolved when the hidden flag is updated.
     */
    setHidden(rfqId, hidden) {
      return this.fbutil.ref('requestForQuotes', rfqId).once('value')
        .then((rfqSnap) => {
          if (!rfqSnap.exists()) { throw new Error('RFQ not found'); }

          let val = rfqSnap.val();

          val.hidden = hidden;

          return rfqSnap.ref.set(val);
        });
    }

    /**
     * Accept a quote. Send an order to the user's shopping card and mark the quote/RFQ accordingly.
     * @param {object} user Logged in user
     * @param {object} rfq The RFQ object
     * @param {object} quote The quote that was accepted
     * @param {string} quote.id The quote Id
     * @param {number} quote.regularPrice The quote's regular price
     * @param {number} quote.rushPrice The quote's rush price
     * @return {Promise} A promise that is resolved when the operation completes.
     */
    acceptQuote(user, rfq, quote) {
      const itemProperties = ['createdOn', 'isRush', 'product', 'service', 'specialInstructions', 'providerOrgId'];
      let orderItemObj = _.assign(_.pick(rfq, itemProperties), {providerOrgId: quote.providerOrgId});

      _.set(orderItemObj, 'service.price', {regular: quote.regularPrice, rush: quote.rushPrice || null});

      return this.users.addShoppingCartItems(user, orderItemObj)
        .then(() => this.setQuoteStatus(user, rfq.$id, quote.id, this.marketplaceRfqQuoteStatus.ACCEPTED))
        .then(() => this.setStatus(user, rfq.$id, this.marketplaceRfqStatus.CLOSED));
    }

    /**
     * Sets the status for a quote.
     * @param {object} user Logged in user
     * @param {string} rfqId Request for quote id.
     * @param {string} quoteId Quote Id
     * @param {string} status Status to set
     * @returns {Promise} A promise that is resolved when the quote status is updated.
     */
    setQuoteStatus(user, rfqId, quoteId, status) {
      return this.fbutil.ref('requestForQuotes', rfqId, 'quotes', quoteId).once('value').then(quoteSnap => {
        if (!quoteSnap.exists()) {
          throw new Error('Quote not found');
        }

        let val = quoteSnap.val();

        val.status = status;
        val.updatedBy = user.uid;
        val.updatedDate = firebase.database.ServerValue.TIMESTAMP;

        return quoteSnap.ref.set(val);
      });
    }

  }
  ngModule.service('requestForQuotes', RequestForQuote);
};
