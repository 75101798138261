class Controller {
  constructor($state, $uibModal, $log, $q, sopLibraryService, utils, confirmDeleteModal, products, growl,
              SAMPLE_ORGANIZATION_ID, CF_ORGANIZATION_ID, sopLogsService, users, orgTypes, orgPerspectives,
              csvImportModal, $window) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.$q = $q;
    this.sopLibraryService = sopLibraryService;
    this.utils = utils;
    this.confirmDeleteModal = confirmDeleteModal;
    this.products = products;
    this.growl = growl;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
    this.CF_ORGANIZATION_ID = CF_ORGANIZATION_ID;
    this.sopLogsService = sopLogsService;
    this.orgPerspectives = orgPerspectives;
    this.users = users;
    this._ = _;
    this.orgTypes = orgTypes;
    this.csvImportModal = csvImportModal;
    this.$window = $window;
    this.searching = false;
    this.PAGE_SIZE = 50;
  }

  $onInit() {
    this.noSops = _.get(this, 'sops.length') === 0;
    this.isCfAdmin = this.user.isCfAdmin();
    this.search = _.debounce(this._search, 300);
    this.usersMap = {};
    this.fetchSops().then((sops)=>{
      this.sops = sops;
      this.onSearchComplete(sops);
    });
    this.loadMore = _.debounce(() => {
      let loadMorePromise = this.sopLibraryService.loadMore(this.searchText);

      if(loadMorePromise) {
        return loadMorePromise.then((result) => { this.sops = _.concat(this.sops, result); });
      }
    }, 300);
  }

  _search() {
    this.sops = [];
    this.searching = true;
    return this.fetchSops()
      .then((results) => {
        this.sops = results;
        this.onSearchComplete(this.sops);
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch SOPs.'))
      .finally(() => { this.searching = false; });
  }

  onSearchComplete(sops) {
    _.each(sops, (sop) => {
      if (_.isUndefined(this.usersMap[sop.createdBy])) {
        this.usersMap[sop.createdBy] = '';
        this.users.getName(sop.createdBy)
            .then((name) => this.usersMap[sop.createdBy] = name || 'Unknown');
      }
    });
  }

  fetchSops() {
    this.searching = true;
    return this.sopLibraryService.query(this.user, this.searchText, null,
      0, this.PAGE_SIZE)
      .then((sops) => {
        return sops;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred loading SOPs.'))
      .finally(() => this.loading = false);
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  addSop() {
    let sopRef = this.sopLibraryService.push();

    return this.$state.go('operations.sopLibrary.edit', {sopId: sopRef.key});
  }

  editSop(sop) {
    return this.$state.go('operations.sopLibrary.edit', {sopId: sop.$id});
  }

  deleteSop(sop) {
    this.confirmDeleteModal('SOP Library Item', {
      body: `Are you sure you want to delete the library item <strong>${sop.title}</strong>?`
    }).then(() => {
      this.sopLibraryService.remove(sop.$id)
        .then(() => {
          _.remove(this.sops, {$id: sop.$id});
          this.noSops = !this.sops.length;
          this.growl.success('SOP deleted');
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete sop.'));
    });
  }

  export() {
    this.sopLibraryService.query(this.user, null, {asFile: true}, 0, 9999)
      .catch((err) => {
        this.utils.defaultErrorHandler(err, 'Unable to export SOPs');
      });
  }

  import() {
    return this.sopLibraryService.import(this.user).then(result => {
      this.sops = _.concat(this.sops, result.newSops);
      this.noSops = !this.sops.length;

      if (result.errors === result.total) {
        throw new Error('All import records failed.');
      } else if (result.errors) {
        this.growl.warning(result.total - result.errors + ' records imported. ' + result.errors + ' records failed.');
      } else {
        this.growl.success(result.total + ' records imported.');
      }
    }).catch(err => this.utils.defaultErrorHandler(err, 'Error importing SOPs'));
  }

}

module.exports = Controller;
