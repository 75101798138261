'use strict';

module.exports = function($scope, $firebaseObject, fbutil, utils, $http) {
  'ngInject';

  var vm = this;

  vm.$onInit = function() {
    getTeams();
  };

  function getTeams() {
    _.each(vm.allAnalysts.searchResults, analyst => {
      if (analyst.reviews.teams) {
        return;
      }
      fbutil.ref('userAccess', analyst.$id, 'reviews/teams').once('value').then(snap => {
        if (!snap.exists()) {
          return;
        }
        analyst.reviews.teams = {};
        snap.forEach(childSnap => {
          if (childSnap.val()) {
            analyst.reviews.teams[childSnap.key] = true;
          }
        });
      });
    });
  }

  function removeAnalyst(uid, analyst) {
    delete vm.team.analysts[uid];
    if (vm.team.teamLeadId === uid) {
      vm.team.teamLeadId = null;
    }
    vm.team.$save();

    delete analyst.teams[vm.team.$id];
    return analyst.$save();
  }

  function addAnalyst(uid, analyst) {
    vm.team.analysts = vm.team.analysts || {};
    vm.team.analysts[uid] = true;
    vm.team.$save();

    analyst.teams = analyst.teams || {};
    analyst.teams[vm.team.$id] = true;
    return analyst.$save();
  }

  vm.displayChecked = function(analyst) {
    var checked = analyst.reviews.teams[vm.team.$id];

    $firebaseObject(fbutil.ref('userAccess', analyst.$id, 'reviews')).$loaded().then(function(accessObj) {
      var promise = checked ? addAnalyst(analyst.$id, accessObj) : removeAnalyst(analyst.$id, accessObj);

      promise.then(function() {
        return $http.post('teamAnalysts/' + analyst.$id + '/refreshPermissions');
      });
    });
  };

  vm.getMore = function() {
    vm.loading = true;
    vm.allAnalysts.getMore().then(() => {
      vm.loading = false;
      getTeams();
    });
  };

  vm.searchKeyPress = function($event) {
    if (utils.isBenignKeyUp($event.keyCode)) {
      return;
    }

    if ($event.keyCode === 13) {
      if (!vm.searchText || vm.searchText.length >= 3) {
        vm.search();
      }

      vm.search.flush();
      return;
    }

    if (vm.searchText && vm.searchText.length <= 3) {
      return;
    }

    vm.search();
  };


  vm.search = _.debounce(function() {
    vm.loading = true;
    vm.allAnalysts.searchNameEmail(vm.searchText);
    vm.allAnalysts.search().then(() => {
      vm.loading = false;
      getTeams();
    });
  }, 300);
};