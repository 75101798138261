class Controller {
  constructor($state, $uibModal, $log, $q, growl, users, marketplaceService, requestForQuotes, notifications,
              marketplaceRfqStatus, miniTourService, utils, $stateParams) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.$q = $q;
    this.growl = growl;
    this.users = users;
    this.marketplaceService = marketplaceService;
    this.rfqService = requestForQuotes;
    this.marketplaceRfqStatus = marketplaceRfqStatus;
    this.notifications = notifications;
    this.miniTourService = miniTourService;
    this.utils = utils;
    this.$stateParams = $stateParams;
  }

  $onInit() {
    this.marketplaceService.getAvailableServices(this.organization)
      .then(services => {
        let servicesArray = _.map(services, (value, key) => _.assign({}, value, {$id: key}));

        this.chunkedServices = _.chunk(_.orderBy(servicesArray, 'order'), 3);

        if (this.$stateParams.serviceId) {
          let service = _.find(services, (s, id) => id === this.$stateParams.serviceId);

          if (service) {
            service.$id = this.$stateParams.serviceId;
            this.chooseService(service);
          }
        }
      });

    this.users.$getShoppingCartItems(this.user.uid)
      .then($items => this.$shoppingCartItems = $items);
  }

  $onDestroy() {
    if (this.$shoppingCartItems) {
      this.$shoppingCartItems.$destroy();
    }
  }

  chooseService(service) {
    this.$uibModal.open({
      component: 'cfMarketplaceChooseServiceModal',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        user: () => this.user,
        service: () => service
      }
    }).result
      .then(response => {
        if (service.orgLevel) {
          return this.orgServiceChosen(service, response);
        } else {
          return this.productServiceChosen(service, response);
        }
      })
      .catch(reason => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.$log.error('Unable to add shopping cart item.', this.$log.toString(reason));
        this.growl.error('Unable to add shopping cart item. Please try again or contact FoodReady ' +
          'customer support for assistance.');
      });
  }

  /**
   * Product services require the user to select one or more products. Each product gets its own service request and
   * communicate about the request is consolidated (hence the need for a separate routine for org vs. product services)
   * @param {object} service The service requested.
   * @param {object} modalResponse The user-specified options for this service request (products, special notes, etc).
   * @return {*} A promise that is resolved when the service is requested (RFQ or shopping cart item).
   */
  productServiceChosen(service, modalResponse) {
    let conflicts = [];
    let newRecs = _.map(modalResponse.products, (product) => {
      return _.assign({
        product: product
      }, _.omit(modalResponse, ['products', 'user']), {
        service: {
          key: service.$id,
          title: service.title,
          price: service.price || null
        }
      });
    });

    if (service.price) {
      let conflicts = [];

      // TODO: check for shopping cart conflicts for CF Admin submission use case
      if (!modalResponse.user) {
        conflicts = _.intersectionWith(newRecs, _.toArray(_.get(this.user, 'shoppingCart.items')),
          (newRec, item) => newRec.product.key === item.product.key &&
            newRec.service.Key === item.service.Key && item.service.key === newRec.service.key);

        _.remove(newRecs, (rec) => _.some(conflicts, (conflict) => conflict === rec));
      }

      return this.users.addShoppingCartItems(modalResponse.user || this.user, newRecs)
        .then(() => {
          if (newRecs.length) {
            return this.growl.success('Successfully added <strong>' + service.title + (newRecs.length > 1 ? 's' : '') +
              '</strong> to your shopping cart');
          }

          if (conflicts.length) {
            return this.growl.warning('You\'re shopping cart already contains the following: ' +
              _.reduce(conflicts, (result, conflict) => {
                result += '<li><strong>' + conflict.service.title + ' &#8594 ' + conflict.product.brandName +
                  '</strong></li>';
                return result;
              }, '<ul>') + '</ul>Please remove the duplicates before adding these item(s).', {ttl: 10000});
          }
        });
    } else {
      return this.$q.all([this.marketplaceService.getServiceProviders(this.user.orgContext.id, [service.$id]),
        this.rfqService.get(this.user.orgContext.id, true)])
        .then(([providerProfiles, existingRequests]) => this.$q.all(_.map(newRecs, (newRec) => {
          newRec.status = this.marketplaceRfqStatus.OPEN;
          newRec.createdOn = firebase.database.ServerValue.TIMESTAMP;
          newRec.createdBy = _.get(modalResponse, 'user.uid') || this.user.uid;
          newRec.requesterOrgId = this.user.orgContext.id;
          newRec.subjectOrgId = providerProfiles[service.$id].$id;

          if (_.find(existingRequests.data, (existingReq) => existingReq.product &&
            existingReq.product.key === newRec.product.key &&
            existingReq.status === this.marketplaceRfqStatus.OPEN &&
              existingReq.service.key === newRec.service.key)) {
            conflicts.push(newRec);

            return this.$q.resolve();
          }

          return this.rfqService.$push(newRec);
        }))
          .then(($newRfqs) => {
            if (conflicts.length < newRecs.length) {
              this.miniTourService.enqueueTour(this.user, {
                id: 'marketplaceSubmitRfq',
                selector: '.marketplace #rfqMenu',
                title: 'Check your RFQ Status',
                contentHtml: 'Your request for quote has been submitted. Check the ' +
                  '<b>Request for Quote</b> tab for updates to your request.'
              });

              this.growl.success('Successfully submitted RFQ: ' + service.title + (newRecs.length > 1 ? 's' : ''));
              this.notifications.postToOrg({
                from: _.get(modalResponse, 'user.uid') || this.user.uid,
                to: providerProfiles[service.$id].$id,
                message: 'A Request For Quote was submitted by: <i>"' + this.user.orgContext.companyName + '"</i>',
                link: {
                  state: 'marketplace.requestForQuote.list'
                }
              });

              if (modalResponse.isNewProduct) {
                this.$state.go('products.edit.planAnalysis.questionnaire',
                  {productId: _.first($newRfqs).product.key, newProdFromService: true});
              }
            }

            if (conflicts.length) {
              this.growl.warning('RFQ already found for the following products: ' +
                _.reduce(conflicts, (result, conflict) => {
                  result += '<li><strong>' + conflict.product.brandName + '</strong></li>';
                  return result;
                }, '<ul>') + '</ul>Please cancel the existing RFQ before submitting a new one.', {ttl: 10000});
            }
          }));
    }
  }

  /**
   * Request an organization level services (e.g. GMP review). Unlike product requests, this will yield one and only
   * one RFQ or shopping cart item.
   * @param {object} service The service requested.
   * @param {object} modalResponse The user-specified options for this service request (products, special notes, etc).
   * @return {*} A promise that is resolved when the service is requested (RFQ or shopping cart item).
   */
  orgServiceChosen(service, modalResponse) {
    let serviceRequest = _.assign({}, _.omit(modalResponse, ['user']), {
      service: {
        key: service.$id,
        title: service.title,
        price: service.price || null
      }
    });

    if (service.price) {
      // TODO: check for shopping cart conflicts for CF Admin submission use case
      if (!modalResponse.user) {
        let conflict = _.find(_.get(this.user, 'shoppingCart.items'),
          item => serviceRequest.service.Key === item.service.Key && item.service.key === serviceRequest.service.key);

        if (conflict) {
          this.growl.warning(`You\'re shopping cart already contains ${conflict.service.title}. ` +
            'Please remove the duplicate before adding this item.', {ttl: 10000});

          return;
        }
      }

      return this.users.addShoppingCartItems(modalResponse.user || this.user, serviceRequest)
        .then(() => this.growl.success('Successfully added <strong>' + service.title +
          '</strong> to your shopping cart'));
    } else {
      return this.$q.all([this.marketplaceService.getServiceProviders(this.user.orgContext.id, [service.$id]),
        this.rfqService.get(this.user.orgContext.id, true)])
        .then(([providerProfiles, existingRequests]) => {
          serviceRequest.status = this.marketplaceRfqStatus.OPEN;
          serviceRequest.createdOn = firebase.database.ServerValue.TIMESTAMP;
          serviceRequest.createdBy = _.get(modalResponse, 'user.uid') || this.user.uid;
          serviceRequest.requesterOrgId = this.user.orgContext.id;
          serviceRequest.subjectOrgId = providerProfiles[service.$id].$id;

          if (_.find(existingRequests.data, (existingReq) => existingReq.status === this.marketplaceRfqStatus.OPEN &&
            existingReq.service.key === serviceRequest.service.key)) {
            this.growl.warning('RFQ already found for this service. Please cancel the existing RFQ before ' +
              'submitting a new one.', {ttl: 10000});
            return;
          }

          return this.rfqService.$push(serviceRequest).then(() => {
            this.miniTourService.enqueueTour(this.user, {
              id: 'marketplaceSubmitRfq',
              selector: '.marketplace #rfqMenu',
              title: 'Check your RFQ Status',
              contentHtml: 'Your request for quote has been submitted. Check the ' +
              '<b>Request for Quote</b> tab for updates to your request.'
            });

            this.growl.success('Successfully submitted RFQ: ' + service.title);
            this.notifications.postToOrg({
              from: this.user.uid,
              to: providerProfiles[service.$id].$id,
              message: 'A Request For Quote was submitted by: <i>"' + this.user.orgContext.companyName + '"</i>',
              link: {
                state: 'marketplace.requestForQuote.list'
              }
            });
          });
        });
    }
  }
}


module.exports = Controller;
