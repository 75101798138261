class Controller {
  constructor($stateParams, $uibModal, utils, featureIntroModal) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.utils = utils;
    this.featureIntroModal = featureIntroModal;
  }

  $onInit() {
    this.subHeading = 'Audit Date: ' + moment(this.auditDate).format('MMMM Do YYYY');
    this.introModal();
  }

  introModal() {
    const gmpText = 'Good manufacturing practices (GMPs) are the foundation of any food safety plan. They describe ' +
      'the conditions and practices that  are necessary for the manufacturing, processing, packing or ' +
      'storage of food to ensure its safety. Watch the video below to learn more.';

    this.featureIntroModal(this.user, {
      title: 'Good Manufacturing Practices (GMPs) & Prerequisite Programs',
      helpTitle: 'GMPs & Prerequisite Programs',
      helpText: gmpText,
      tipName: 'featureIntro_GMP',
      helpVideoUrl: ''
    });
  }
}

module.exports = Controller;
