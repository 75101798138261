'use strict';

module.exports = function ($scope, $state, authentication, growl) {
  //noinspection JSLint
  'ngInject';

  $scope.sendPasswordEmail = function () {
    authentication.resetPassword($scope.email).then(function () {
      growl.success('Password reset email sent.');
      $state.go('authentication.signin');
    }).catch(function (err) {
      growl.error(err.message);
    });
  };
};