class Controller {
  constructor($state, $uibModal, $log, $q, growl, marketplaceServices) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.growl = growl;
    this.marketplaceServices = marketplaceServices;
    this.$q = $q;
  }

  $onInit() {}

  editService(service) {
    this.$uibModal.open({
      component: 'cfMarketplaceEditServiceOffered',
      backdrop: 'static',
      resolve: {
        service: () => _.cloneDeep(service),
        titleMap: () => _.mapValues(_.keyBy(_.omitBy(
          this.services, {title: _.get(service, 'title')}), 'title'), () => true)
      }
    }).result
      .then(result => this.save(result)
        .then(() => service && _.assign(service, result)))
      .catch((err) => {
        if (err === 'cancel') { return; }

        this.growl.error('Unable to ' + (service ? 'update' : 'add') +
          ' service. Please try again or contact customer support for assistance');
        this.$log.error(err);
      });
  }

  setEnabled(service, enabled) {
    return this.marketplaceServices.setEnabled(this.organization.$id, service.key, enabled)
      .then(() => this.growl.success(service.title + ' <strong>' +
        (enabled ? 'enabled' : 'disabled') + '</strong> successfully!'));
  }

  save(service) {
    if(service.key) {
      let servicePayload = service.default ? {enabled: !!service.enabled, price: service.price} : service,
        serviceId = service.key;

      delete service.key;
      return this.marketplaceServices.update(this.organization.$id, serviceId, servicePayload)
        .then(() => {
          service.key = serviceId;
          this.growl.success('Service updated successfully!');
        })
        .catch((err) => {
          service.key = serviceId;
          throw err;
        });
    } else {
      return this.marketplaceServices.add(this.organization.$id, _.assign(service, {enabled: true}))
        .then((result) => {
          this.growl.success('Service added successfully!');
          service.key = result.key;
          this.services.push(service);
        });
    }
  }

  remove(service) {
    return this.marketplaceServices.remove(this.organization.$id, service.key)
      .then(() => {
        _.remove(this.services, service);
        this.growl.success('Service removed successfully!');
      });
  }

  reset() {
    this.$onInit();
    if (this.servicesForm) { this.servicesForm.$setPristine(); }
  }
}

module.exports = Controller;
