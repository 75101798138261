class Controller {
  constructor($log, growl, CompanyNameSearch, marketplaceService, requestForQuotes) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.CompanyNameSearch = CompanyNameSearch;
    this.marketplaceService = marketplaceService;
    this.requestForQuotesService = requestForQuotes;
  }

  $onInit() {
    this.rfq = this.resolve.rfq;
    this.user = this.resolve.user;

    this.saving = false;
    this.providerSearch = new this.CompanyNameSearch(this.user, false)
      .filterByTypes(this.marketplaceService.getProviderOrgTypes()).filterByAuthorizedProvider();
  }

  ok(provider) {
    this.saving = true;
    this.requestForQuotesService.assign(this.rfq.$id, provider.$id, this.user)
      .then((rfq) => this.modalInstance.close(rfq))
      .catch((err) => {
        this.growl.error('Unable to assign RFQ. Please try again or contact ' +
          'customer support for assistance');
        this.$log.error('Unable to assign RFQ.', this.$log.toString(err));
        this.saving = false;
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
