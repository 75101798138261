require('./styles.scss');

module.exports = function (ngModule) {
  ngModule.component('frHistoryList', {
    bindings: {
      user: '<',
      referenceId: '=',
      type: '=',
    },
    template: require('./history-list.template.html'),
    controller: require('./history-list.controller.js'),
    controllerAs: 'vm',
  });
};
