class Controller {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  $onInit() {
    this.hazards = this.resolve.hazards;
    this.productName = this.resolve.productName;
    this.introducedStep = this.resolve.introducedStep;
    this.product = this.resolve.product;

    this.isHaccp = this.product.planType === 'haccpPlan';
    this.hazardIndex = 0;
  }

  next() {
    this.hazardIndex++;
    this.editHazardForm.$setUntouched();
  }

  prev() {
    this.hazardIndex--;
    this.editHazardForm.$setUntouched();
  }

}

module.exports = Controller;
