module.exports = function(ngModule) {
  class LetterServices {
    constructor($firebaseObject, $firebaseArray, fbutil) {
      'ngInject';

      this.$firebaseObject = $firebaseObject;
      this.$firebaseArray = $firebaseArray;
      this.fbutil = fbutil;
    }

    /**
     * Get a letter
     * @param {string} id The letter ID
     * @returns {Promise<$firebaseObject>} A promise resolving to the letter
     */
    $get(id) {
      return this.$firebaseObject(this.fbutil.ref('letters', id)).$loaded();
    }

    /**
     * Add a letter
     * @param {string} clientOrgId The product owner's org ID
     * @param {string} providerOrgId The service provider's org ID
     * @param {string} orderId The order ID
     * @param {string} itemId The order item ID
     * @param {string} productId The product ID
     * @param {string} markup The letter HTML
     * @returns {Promise<$firebaseObject>} A promise resolving to the new letter
     */
    $push(clientOrgId, providerOrgId, orderId, itemId, productId, markup) {
      return this.$firebaseObject(this.fbutil.ref('letters').push()).$loaded().then($letter => {
        _.assign($letter, {
          createdOn: firebase.database.ServerValue.TIMESTAMP,
          clientOrgId: clientOrgId,
          providerOrgId: providerOrgId,
          orderId: orderId,
          itemId: itemId,
          productId: productId,
          markup: markup
        });
        return $letter;
      });
    }

    /**
     * Remove a letter
     * @param {string} id The letter ID
     * @returns {Promise<void>} The result of the remove operation
     */
    remove(id) {
      return this.fbutil.ref('letters', id).remove();
    }
  }

  ngModule.service('letters', LetterServices);
};
