module.exports = function(ngModule) {
  ngModule.component('cfProjectWritingMessage', {
    bindings: {
      user: '<',
      topic: '<',
      project: '<',
      company: '<',
      files: '=',
    },
    template: require('./project_writing.messages.template.html'),
    controller: require('./project_writing.messages.controller.js'),
    controllerAs: 'vm'
  });
};
