class Controller {
  constructor($log, growl, $state, orders, organizations, marketplaceService, utils, confirmModal,
              messageServices, confirmDeleteModal, moment, CF_ORDER_ITEM_STATUS, $uibModal, $q,
              miniTourService, $timeout, users) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.$state = $state;
    this.ordersService = orders;
    this.marketplaceService = marketplaceService;
    this.organizations = organizations;
    this.utils = utils;
    this.messageServices = messageServices;
    this.confirmDeleteModal = confirmDeleteModal;
    this.confirmModal = confirmModal;
    this.moment = moment;
    this.CF_ORDER_ITEM_STATUS = CF_ORDER_ITEM_STATUS;
    this.orders = [];
    this.markup = {};
    this.clientProfiles = {};
    this.providerProfiles = {};
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.miniTourService = miniTourService;
    this.$timeout = $timeout;
    this.users = users;
    this.searchText = '';
    this.searching = false;

    this.orderFilterTypes = {
      ACTIVE: 'Active Orders',
      COMPLETED: 'Completed Orders',
      ALL: 'All Orders'
    };
    this.EXPAND_TOUR_ID = 'ordersExpand';
  }

  $onInit() {
    this.isProvider = this.marketplaceService.isProvider(this.user, this.organization);
    this.currentFilterType = this.orderFilterTypes.ALL;
    this._search(this.organization, this.isProvider);
    this.search = _.debounce(this._search, 300);
    this.expandMiniTourShown = this.miniTourService.wasShown(this.user ,this.EXPAND_TOUR_ID);
  }

  _search(organization, isProvider, searchText, from, size) {
    let orders = [];
    let additionalFilters = {};

    if (this.currentFilterType === this.orderFilterTypes.ACTIVE) { additionalFilters.isActive = true; }
    if (this.currentFilterType === this.orderFilterTypes.COMPLETED) { additionalFilters.isComplete = true; }

    this.searching = true;
    return this.ordersService.get(organization.$id, isProvider, searchText, from, size,
      _.isEmpty(additionalFilters) ? null : additionalFilters)
      .then((result) => {
        orders = _.get(result, 'data', []);
        _.each(orders, (order) => _.assign(order,
          {
            isCollapsed: true,
            trStyle: {display: 'none'},
            createdOn: new Date(order.createdOn),
            completeItems: _.get(_.countBy(order.items, (item) => item.status),
              this.CF_ORDER_ITEM_STATUS.COMPLETED, 0),
            cancelledItems: _.get(_.countBy(order.items, (item) => item.status),
              this.CF_ORDER_ITEM_STATUS.CANCELLED, 0)
          }));

        if (orders.length && !this.expandMiniTourShown) {
          this.expandMiniTourShown = true;
          this.$timeout(() => {
            this.miniTourService.enqueueTour(this.user, {
              id: this.EXPAND_TOUR_ID,
              selector: '.orders .expand-col-tour',
              title: 'View Order Details',
              contentHtml: 'Want to view individual order items? Click the <i class="far fa-plus-square"></i> ' +
              'to view details.'
            });
          }, 600);
        }

        this.orders = orders;
        this.fetchClientProfiles(orders);
      })
      .finally(() => this.searching = false);
  }

  fetchClientProfiles(orders) {
    let clientOrgIds = _.uniq(_.map(orders, (o) => o.organizationId));

    _.each(clientOrgIds, (id) => {
      if (!this.clientProfiles[id]) {
        this.organizations.getMiniProfile(id)
          .then((profile) => this.clientProfiles[id] = profile);
      }
    });
  }

  expandingOrder(order) {
    let providerOrgIds = _.uniq(_.map(order.items, (i) => i.providerOrgId));

    order.trStyle = undefined;
    _.each(providerOrgIds, (id) => {
      if (!id) { return; }
      if (!this.providerProfiles[id]) {
        this.organizations.getMiniProfile(id)
          .then((profile) => this.providerProfiles[id] = profile);
      }
    });
  }

  searchKeyPress($event) {
    if (this.utils.isBenignKeyUp($event.keyCode)) { return; }

    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) {
        this.search(this.organization, this.isProvider, this.searchText.toLowerCase());
      }

      return;
    }

    if (this.searchText && this.searchText.length <= 3) { return; }

    this.search(this.organization, this.isProvider, this.searchText.toLowerCase());
  }

  getItemsMarkup(order) {
    return _.reduce(order.items, (result, item) =>
      result + `<p>${item.isRush ? '<span class="text-danger">RUSH</span> ' : ''} ${_.get(item, 'service.title')}` +
      `${_.get(item, 'product.brandName') ? '(' + _.get(item, 'product.brandName') + ')' : ''}</p>`, '');
  }

  hasUnfinishedRush(order) {
    return order.completeItems + order.cancelledItems < _.get(order, 'items.length') &&
      !!_.find(order.items, i => i.isRush);
  }

  message(otherOrgId) {
    this.messageServices.getOrgToOrgTopic(this.user, otherOrgId).then(topicId => {
      this.$state.go('user.messages.message', {topicId: topicId});
    });
  }

  changeFilter(filterType) {
    this.currentFilterType = filterType;
    this._search(this.organization, this.isProvider, this.searchText.toLowerCase());
  }

  cancelOrder(order) {
    this.confirmDeleteModal('Order from ' + this.moment(order.createdOn).format('MMM DD, YYYY hh:mm:ss a'))
      .then(() => {
        this.ordersService.cancel(order)
          .then(() => {
            if (this.currentFilterType = this.orderFilterTypes.ACTIVE) {
              _.remove(this.orders, o => o.$id === order.$id);
            } else {
              _.each(order.items, i => i.status = this.CF_ORDER_ITEM_STATUS.CANCELLED);
            }
          })
          .catch(err => {
            this.$log.error('An error cancelling the order.', err);
            this.growl.error('Unable to cancel the order. Please contact customer support.');
          });
      })
      .catch(() => angular.noop);
  }

  cancelItem(order, item) {
    this.confirmDeleteModal(item.service.title)
      .then(() => {
        this.ordersService.cancelItem(order.$id, item.id)
          .then(() => {
            item.status = this.CF_ORDER_ITEM_STATUS.CANCELLED;
          })
          .catch(err => {
            this.$log.error('An error cancelling the order item.', err);
            this.growl.error('Unable to cancel the order item. Please contact customer support.');
          });
      })
      .catch(() => angular.noop);
  }

  reopenItem(order, item) {
    this.confirmModal({
      title: 'Reopen Order Item?',
      body: 'An order item should only be reopened for adjustments that will not be charged to the client. ' +
      'If the change should be charged, the client must create a new order.<br><br>Are you sure you want to ' +
      'reopen this order item?',
      okText: 'Yes, Reopen Item',
      cancelText: 'Cancel'
    }).then(() => {
      return this.ordersService.$getItem(order.$id, item.id);
    }).then($item => {
      $item.status = this.CF_ORDER_ITEM_STATUS.PROCESSING;
      return $item.$save().then(() => $item.$destroy());
    }).then(() => {
      this.growl.success('Order item reopened!');
      this.$state.go('marketplace.orders.item', {orderId: order.$id, itemId: item.id});
    }).catch(err => {
      if (err === 'cancel') { return; }
      this.$log.error('An error occurred reopening the order item.', err);
      this.growl.error('Unable to reopen the order item. Please contact customer support.');
    });
  }

  editItem(order, item) {
    this.$uibModal.open({
      component: 'cfMarketplaceEditOrderItem',
      resolve: {
        user: () => this.user,
        order: () => order,
        item: () => _.cloneDeep(item),
        facilityName: () => this.clientProfiles[order.organizationId].name
      }
    }).result
      .then((newItem) => {
        _.assign(item, newItem);
        this.growl.success('Order item updated successfully!');
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.$log.error('An error occurred updating an order item.', this.$log.toString(reason));
        this.growl.error('Unable to update the order item. Please try again or contact customer support.');
      });
  }

  reassignProvider(orderId, orderItem) {
    this.$q.all({
      profile: this.$q.when(this.providerProfiles[orderItem.providerOrgId] ||
        this.organizations.getMiniProfile(orderItem.providerOrgId)),
      $orderItem: this.ordersService.$getItem(orderId, orderItem.id)
    }).then(result => {
      this.providerProfiles[orderItem.providerOrgId] = this.providerProfiles[orderItem.providerOrgId] || result.profile;

      this.$uibModal.open({
        component: 'cfReassignProvider',
        backdrop: 'static',
        resolve: {
          user: () => this.user,
          $orderItem: () => result.$orderItem,
          currentProfile: result.profile
        }
      }).result.then(() => {
        _.remove(this.orders, o => o.$id === orderId);
        this.growl.success('Provider reassigned.');
      });
    }).catch(reason => {
      if (this.utils.isModalDismissalByUser(reason)) {
        return;
      }
      this.growl.success('Unable to reassign provider.');
    });
  }

  clientInfoModal(order) {
    let clientOrgId = _.first(order.items).clientOrgId;

    if (!order.userId || !clientOrgId) {
      this.growl.error('Client information missing from order.');
      return;
    }

    this.$uibModal.open({
      component: 'cfMarketplaceClientInfoModal',
      size: 'lg',
      resolve: {
        user: () => this.user,
        requesterOrgId: () => clientOrgId,
        clientUserProfile: () => this.users.getProfile(order.userId),
        onboardingQuestionnaire: () => this.organizations.getOnboardingQuestionnaire(clientOrgId)
      }
    });
  }

  viewFiles(order, orderItem) {
    this.$state.go('marketplace.orders.files', {
      orgId: orderItem.clientOrgId,
      productId: orderItem.product.key,
      returnState: this.$state.current.name,
      marketplacePocUid: order.userId
    });
  }
}

module.exports = Controller;
