class Controller {
  constructor(products, SAMPLE_ORGANIZATION_ID, $q, utils, $timeout) {
    'ngInject';

    this.products = products;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
    this.$q = $q;
    this.utils = utils;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.loadTemplates();
  }

  loadTemplates() {
    this.loadingTemplates = true;
    let templatePromises = {
      cfTemplates: this.products.getPlanTemplates(this.SAMPLE_ORGANIZATION_ID),
      primaryOrgTemplates: this.products.getPlanTemplates(this.user.organizationId),
      orgTemplates: this.user.onPrimaryOrg() ? {} : this.products.getPlanTemplates(this.user.orgContext.id)
    };

    this.$q.all(templatePromises)
      .then(({cfTemplates, primaryOrgTemplates, orgTemplates}) => {
        let templates = _.assign(cfTemplates, primaryOrgTemplates, orgTemplates);

        this.$timeout(() => {
          this.templates = _.sortBy(_.map(templates, (template, key) => ({
            name: template.name,
            orgId: template.organizationId,
            value: key
          })), ['name']);
        });
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to load templates.'))
      .finally(() => { this.loadingTemplates = false; });
  }
}

module.exports = Controller;
