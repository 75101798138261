'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfListSuppliers', {
    bindings: {
      user: '<',
      company: '<',
      supplierSearch: '<',
      productConstraints: '<'
    },
    template: require('./ingredients.suppliers.list.template.html'),
    controller: require('./ingredients.suppliers.list.controller.js'),
    controllerAs: 'vm'
  });
};