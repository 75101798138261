module.exports = function(ngModule) {
  ngModule.factory('CompanyNameSearch', function(Search) {
    'ngInject';

    class CompanyNameSearch extends Search {

      constructor(user, publicOrgs, options) {
        let index = publicOrgs ? 'public' : 'organizations';

        options = options || {};
        super(user, {
          uri: 'search/organization',
          type: 'organization',
          context: publicOrgs || user.isCfAdmin() ? undefined : {
            organizationId: user.orgContext.id
          },
          max: options.size || 30, // Maximum number of recs to pull back
          pageLimit: 30,    // Number of recs to show per page
          body: {}
        }, index, publicOrgs);
      }

      search(text) {
        let body = this.getSearchQuery();

        body.query = {
          bool: {
            must: []
          }
        };

        if (text) {
          body.query.bool.must.push({
            'match_phrase_prefix': {
              companyName: {
                query: text,
                slop: 3,              // Allow terms to appear out of order by this many positions
                'max_expansions': 20  // To improve performance, limit results of the final search term to this many
              }
            }
          });
        }

        if (this.authorizedProviders) {
          body.query.bool.must.push({
            term: {
              authorizedProvider: true
            }
          });
        }

        if (this.typesFilter) {
          let typeQuery = {
            nested: {
              path: 'types',
              query: {
                bool: {
                  should: [],
                  'minimum_should_match': 1
                }
              }
            }
          };

          _.each(this.typesFilter, type => {
            let newItem = {
              term: {}
            };

            newItem.term['types.' + type.id] = true;
            typeQuery.nested.query.bool.should.push(newItem);
          });
          body.query.bool.must.push(typeQuery);
        }

        if (this.orgsFilter) {
          body.query.bool.must.push({
            ids: {
              values: this.orgsFilter
            }
          });
        }

        if (!body.query.bool.must.length) {
          body.query = {'match_all': {}};
          body.sort = 'companyName.keyword';
        }

        this.setSearchQuery(body);

        return super.search();
      }

      /**
       * Filter the company name results by company types (see org.types.constant.js).
       * @param {Array} types Types to restrict results to.
       * @returns {CompanyNameSearch} This search object to allow chaining.
       */
      filterByTypes(types) {
        this.typesFilter = types;
        return this;
      }

      /**
       * Filter the company name results by companies authorized to participate as a provider in the Marketplace.
       * @returns {CompanyNameSearch} This search object to allow chaining.
       */
      filterByAuthorizedProvider() {
        this.authorizedProviders = true;
        return this;
      }

      /**
       * Restrict the results to the passed organizationIds.
       * @param {Array} orgIds OrgIds to restrict search to
       * @returns {CompanyNameSearch} This search object to allow chaining.
       */
      restrictToOrgs(orgIds) {
        this.orgsFilter = orgIds;
        return this;
      }
    }

    return CompanyNameSearch;
  });
};
