class Constructor {
  constructor(filePortalService, $stateParams, constantsService) {
    'ngInject';

    this.filePortalService = filePortalService;
    this.$stateParams = $stateParams;
    this.constantsService = constantsService;
  }

  $onInit() {
    this.noFilesText = `Add files for product <b>${this.ingredient.brandName}</b>`;
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.categories = _.omitBy(fileTypes, fc => fc.companyLevel);
    });
  }
}

module.exports = Constructor;
