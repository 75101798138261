require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductHazard', {
    bindings: {
      user: '<',
      product: '<',
      $hazard: '=',
      facilitySops: '<',
      planSops: '<',
      isReadOnly: '<',
      onSaveAndClose: '&?',
      onSave: '&?',
      onCancel: '&?',
      isDirty: '=',
      onEditControl: '&?',
      onAddExistingControl: '&?'
    },
    template: require('./products.edit.hazard.template.html'),
    controller: require('./products.edit.hazard.controller.js'),
    controllerAs: 'vm'
  });
};
