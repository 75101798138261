module.exports = function(ngModule) {
  ngModule.component('cfIngredients', {
    bindings: {
      user: '<',
      company: '<',
      ingredientSearch: '<'
    },
    template: require('./ingredients.ingredients.template.html'),
    controller: require('./ingredients.ingredients.controller.js'),
    controllerAs: 'vm'
  });
};
