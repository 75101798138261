module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider

      .state('fileHelper', {
        url: '/files?key',
        template: '<div></div>',
        controllerAs: 'vm',
        controller: function($http, $stateParams, $log, $window, S3) {
          'ngInject';
          if (!$stateParams.key) {
            $log.error('Missing parameters in fileHelper state');
            throw new Error('Invalid parameters');
          }
          $window.location.replace('/api/s3/loading');
          let keyParts = S3.parseKey($stateParams.key);
          let urlPromise = keyParts.productId ?
            S3.getSignedUrlForProductFile(keyParts.organizationId, keyParts.productId, $stateParams.key) :
            S3.getSignedUrl(keyParts.organizationId, $stateParams.key);

          return urlPromise.then(url => $window.location.replace(url)).catch(err => {
            $log.error('FileHelper state error. Error getting file.', $log.toString(err));
          });
        },
        resolve: {
          user: (authentication) => authentication.requireAuth()
        }
      })

      .state('filePortal', {
        url: '/portal?token?accessKey',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          token: (urlTokens, $stateParams, growl, $state) => {
            return urlTokens.get($stateParams.token, $stateParams.accessKey).catch(err => {
              if (err === 204) {
                growl.error('Invalid or expired link. Please contact the requester for a new one.');
                $state.go('authentication.signin');
              }
            });
          }
        }
      })

      .state('filePortal.intro', {
        url: '/intro',
        onEnter: function(authentication) {
          'ngInject';
          authentication.logout();
        },
        template: '<cf-upload-portal-intro token="$resolve.token"></cf-upload-portal-intro>'
      })

      .state('filePortal.portal', {
        url: '/portal',
        template: '<cf-upload-portal token="$resolve.token" user="$resolve.user" staged="$resolve.staged">' +
        '</cf-upload-portal>',
        resolve: {
          user: (authentication) => authentication.waitForAuth(),
          staged: (filePortalService, token) => filePortalService.getStagedFileCategories(token)
        }
      })

      .state('filePortal.optOut', {
        url: '/optOut',
        template: '<div>You have successfully opted out of file requests from {{vm.coName}}.</div>',
        controllerAs: 'vm',
        controller: function(token, supplierService) {
          var vm = this;

          vm.coName = token.requesterName;
          supplierService.fileRequestOptOut(token.supplierId);
        }
      })

      .state('filePortal.supplierFiles', {
        url: '/supplierFiles',
        template: '<cf-upload-portal-supplier-files token="$resolve.token" user="$resolve.user">' +
        '</cf-upload-portal-supplier-files>'
      })

      .state('filePortal.orgFiles', {
        url: '/orgFiles',
        template: '<cf-upload-portal-org-files token="$resolve.token" user="$resolve.user">' +
        '</cf-upload-portal-org-files>'
      })

      .state('filePortal.productFiles', {
        url: '/productFiles/:productId',
        template: '<cf-upload-portal-product-files token="$resolve.token" user="$resolve.user" ' +
          'product="$resolve.product"></cf-upload-portal-product-files>',
        resolve: {
          product: (token, $stateParams) => token.products[$stateParams.productId]
        }
      })

      .state('filePortal.ingredientFiles', {
        url: '/ingredientFiles/:ingredientId',
        template: '<cf-upload-portal-ingredient-files token="$resolve.token" ' +
        'ingredient="$resolve.supplierIngredient"></cf-upload-portal-ingredient-files>',
        resolve: {
          supplierIngredient: (token, $stateParams) => token.ingredients[$stateParams.ingredientId]
        }
      });
  });
};
