'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfSupplier', {
    bindings: {
      supplier: '<'
    },
    template: require('./ingredients.supplier.template.html'),
    controller: require('./ingredients.supplier.controller.js'),
    controllerAs: 'vm'
  });

  require('./overview')(ngModule);
  require('./address')(ngModule);
  require('./audit')(ngModule);
  require('./contact')(ngModule);
  require('./files')(ngModule);
  require('./supplier_ingredients')(ngModule);
};
