module.exports = function(ngModule) {
  ngModule.factory('ai', function($http) {
    return {
      answer: (prompt) => {
        if (!prompt) { return; }
        return $http.post('/ai/answer', { prompt });
      }
    };
  });
};
