class Controller {
  constructor(
    preventiveControlTypes,
    haccpControlTypes,
    growl,
    utils,
    $q,
    confirmModal,
    $uibModal,
    sopService,
    $state,
    preventDirtyNav,
    $timeout,
    products,
    cfpLoadingBar,
    sopLibraryService,
    confirmDeleteModal,
    $firebaseObject,
    controlsService,
    $stateParams,
    hazardsService
  ) {
    'ngInject';

    this._ = _;
    this.preventiveControlTypes = preventiveControlTypes;
    this.haccpControlTypes = haccpControlTypes;
    this.growl = growl;
    this.utils = utils;
    this.$q = $q;
    this.confirmModal = confirmModal;
    this.$uibModal = $uibModal;
    this.sopService = sopService;
    this.$state = $state;
    this.preventDirtyNav = preventDirtyNav;
    this.$timeout = $timeout;
    this.productsSvc = products;
    this.cfpLoadingBar = cfpLoadingBar;
    this.sopLibraryService = sopLibraryService;
    this.confirmDeleteModal = confirmDeleteModal;
    this.$firebaseObject = $firebaseObject;
    this.controlsService = controlsService;
    this.$stateParams = $stateParams;
    this.hazardsSvc = hazardsService;
  }

  $onInit() {
    let hazardsIds = this.$control.hazards ? _.keys(this.$control.hazards) : [this.$stateParams.hazardId];
    let hazards = _.map(hazardsIds, (id) => this.$hazards.$getRecord(id));

    this.controlsSignificantHazard = _.some(hazards, (h) => h.isSignificant);
    this.criticalLimitObj = {};
    if (
      this.controlsService.requiresSop(this.product.planType, this.$control) ||
      this.$control.type === 'other'
    ) {
      this.fetchSop().then((sop) => this.loadCriticalLimitObj(sop));
    }
    this.loadHazards(hazards);

    this.newControl = this.$control.$value === null;
    this.$timeout(() => {
      this.preventDirtyNav(this.controlForm, this.unsavedMsg, () => this.save());

      // If they are editing an incomplete control (i.e. no type),
      // force to dirty to prevent leaving the page without fixing.
      if (!this.newControl && !this.$control.type) {
        this.controlForm.$setDirty();
      }
    }, 0);

    this.unsavedMsg = `You have unsaved changes to the ${this.product.brandName} control.`;
    this.isHaccp = this.product.planType === 'haccpPlan';
    this.controlTypes = _.map(this.isHaccp ? this.haccpControlTypes : this.preventiveControlTypes, (entry) => ({
      name: entry.name,
      value: entry.id,
    }));

    this.mySopsPromise = this.sopService.query(
      [this.product.organizationId],
      '',
      {
        isFacilitySop: false,
        type: 'passFail',
      },
      0,
      10000
    );
  }

  $doCheck() {
    this.isDirty = _.get(this, 'controlForm.$dirty', false);
  }

  loadCriticalLimitObj(sop) {
    // eslint-disable-next-line angular/typecheck-object
    if (typeof sop.metadata.criticalLimitsDetails === 'object') {
      this.criticalLimitObj = _.assign({}, sop.metadata.criticalLimitsDetails);

      if (this.criticalLimitObj.source === 'timerTemp') {
        this.criticalLimitObj['timerTempFields'] = this.criticalLimitObj.values;
        this.criticalLimitObj['phFields'] = [{ph: '', foodProduct: ''}];
      } else if (this.criticalLimitObj.source === 'pH') {
        this.criticalLimitObj['phFields'] = this.criticalLimitObj.values;
        this.criticalLimitObj['timerTempFields'] = [
          {time: '', unit: '', temp: '', tempUnit: '', inequalities: '', foodProduct: ''},
        ];
      } else {
        this.criticalLimitObj['source'] = 'none';
        this.criticalLimitObj['timerTempFields'] = [
          {time: '', unit: '', temp: '', tempUnit: '', inequalities: '', foodProduct: ''},
        ];
        this.criticalLimitObj['phFields'] = [{ph: '', foodProduct: ''}];
      }

      this.criticalLimitObj['notes'] =
        // eslint-disable-next-line angular/typecheck-string
        typeof sop.metadata.criticalLimits === 'string' ? sop.metadata.criticalLimits : '';
    } else {
      this.criticalLimitObj = {
        source: 'none',
        // eslint-disable-next-line angular/typecheck-string
        notes: typeof sop.metadata.criticalLimits === 'string' ? sop.metadata.criticalLimits : '', // For Historical data
        timerTempFields: [{time: '', unit: '', temp: '', tempUnit: '', inequalities: '', foodProduct: ''}],
        phFields: [{ph: '', foodProduct: ''}],
      };
    }
  }

  loadHazards(hazards) {
    this.hazardNames = _.map(hazards, (h) => h.groupId ? this.product.hazardGroups[h.groupId].name : h.name).join(
      ', '
    );
    this.hazardSteps = _.map(hazards, (h) =>
      h.introductionStep ? this.product.processSteps[h.introductionStep].name : null
    );
    this.hazardSteps = _.compact(this.hazardSteps).join(', ');
  }

  fetchSop() {
    if (this.$control.procedure) {
      this.newSop = false;
      return this.sopService.$get(this.$control.procedure).then((result) => {
        this.$sop = result;
        this.$sop.metadata = this.$sop.metadata || {productId: this.product.$id, controlId: this.$control.$id};

        return this.$sop;
      });
    } else {
      this.newSop = true;
      return this.sopService.$push().then((result) => {
        return this.$sop = _.assign(result, {
          organizationId: this.product.organizationId,
          metadata: {productId: this.product.$id, controlId: this.$control.$id},
        });
      });
    }
  }

  cancel() {
    if (this.controlForm.$dirty) {
      this.confirmModal({
        title: 'Unsaved Changes!',
        okText: 'Yes, I\'m sure.',
        cancelText: 'No, stay here.',
        body:
          'It looks like you have unsaved changes. Are you sure you want to undo ' +
          'your updates and return to the diagram?',
      }).then(() => this.onCancel());
    } else {
      this.onCancel();
    }
  }

  save(close) {
    if (this.controlForm.$invalid) {
      return this.confirmModal({
        title: 'Control Incomplete',
        hideCancelButton: true,
        body: 'Please enter all required information before saving.',
      }).then(() => this.utils.setFormFieldsToTouched(this.controlForm));
    }

    if (this.controlForm.$pristine && _.get(this.sopForm, '$pristine', true)) {
      return this.$q.when(
        close ? this.onSaveAndClose({$control: this.$control}) : this.onSave({$control: this.$control})
      );
    }

    let newStepPromise = this.$q.resolve();

    if (this.newControl) {
      this.$control.createdOn = firebase.database.ServerValue.TIMESTAMP;
      this.$control.createdBy = this.user.uid;
      if (!this.$stateParams.hazardId) {
        throw new Error('Hazard must be selected for brand new controls.');
      }
      _.set(this.$control, `hazards.${this.$stateParams.hazardId}`, true);
    }

    this.$control.updatedOn = firebase.database.ServerValue.TIMESTAMP;
    this.$control.updatedBy = this.user.uid;

    if (this.newStepName) {
      newStepPromise = this.productsSvc.pushStep(this.product, {name: this.newStepName}).then((newStepRef) => {
        this.$control.stepId = newStepRef.key;
      });
    }

    if (this.$sop) {
      this.$sop.metadata['criticalLimitsDetails'] = {
        source: this.criticalLimitObj.source,
      };
      this.$sop.metadata['criticalLimits'] = this.criticalLimitObj.notes || '';

      if (this.criticalLimitObj.source !== 'none') {
        this.$sop.metadata.criticalLimitsDetails['values'] =
          this.criticalLimitObj.source === 'timerTemp' ?
            this.criticalLimitObj.timerTempFields :
            this.criticalLimitObj.phFields;
      }

      if (this.newSop) {
        this.$sop.createdOn = firebase.database.ServerValue.TIMESTAMP;
        this.$sop.createdBy = this.user.uid;
        this.$sop.createdByName = this.user.fullName();
      }

      this.$sop.updatedOn = firebase.database.ServerValue.TIMESTAMP;
      this.$sop.updatedBy = this.user.uid;
      this.$sop.createdByName = this.$sop.createdByName || this.user.fullName();
    }

    return newStepPromise
      .then(() => this.$q.all([this.$control.$save(), this.$sop ? this.$sop.$save() : this.$q.resolve()]))
      .then(() => {
        if (this.$sop) {
          if (this.newSop) {
            this.productsSvc
              .addProcedure(this.$sop.metadata.productId, this.$sop.metadata.controlId, this.$sop.$id)
              .catch((err) =>
                this.$log.error(
                  'Could not add SOP to product record. Therefore the product ' +
                    'will not have a reference to SOP w/ ID = ' +
                    this.$sop.$id,
                  this.$log.toString(err)
                )
              );

            this.planSops[this.$sop.$id] = this.$sop;
            this.newSop = false;
          } else {
            _.assign(this.planSops[this.$sop.$id], this.$sop);
          }
          if(this.$stateParams.activeTab === 'forms') {
            this.controlsService.syncControls(this.planSops, this.product);
          }
        }

        if (!close && _.isFunction(this.onSave)) {
          this.onSave({$control: this.$control});
          this.newControl = false;
        }

        if (close && _.isFunction(this.onSaveAndClose)) {
          this.onSaveAndClose({$control: this.$control});
        }
      })
      .then(() => {
        if (this.controlForm) {
          this.controlForm.$setPristine();
        }

        if (this.sopForm) {
          this.sopForm.$setPristine();
        }
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save control.'));
  }

  getWipPromise() {
    let wipPromise = this.$q.resolve();

    if (this.controlForm.$dirty && !this.controlForm.$invalid) {
      wipPromise = this.confirmModal({
        title: '<span class="far fa-thermometer fa-fw"></span> Unsaved Changes',
        okText: 'Save Control & Continue',
        cancelText: 'Cancel',
        body: 'Please save your work before adding a prerequisite procedure.',
      }).then(() => this.save());
    } else if (this.controlForm.$invalid) {
      return this.confirmModal({
        title: '<span class="far fa-thermometer fa-fw"></span>Control Incomplete',
        okText: 'Ok',
        hideCancelButton: true,
        body: 'Please enter control information and save your work before adding a prerequisite procedure.',
      })
        .then(() => this.utils.setFormFieldsToTouched(this.controlForm))
        .then(() => this.$q.reject());
    }

    return wipPromise;
  }

  copySop(cfLib = false) {
    this.getWipPromise().then(() => {
      this.mySopsPromise
        .then((mySops) => {
          let types = this.company.types && _.pickBy(this.company.types, (t) => !!t);
          let searchPromise = !cfLib ?
            this.$q.when(mySops) :
            this.sopLibraryService.query(
              this.user,
              '',
              {
                suggestedType: this.user.isPartner() ? null : 'plan',
                orgTypes: this.user.isPartner() || !types ? null : _.keys(types),
              },
              0,
              10000
            );

          this.cfpLoadingBar.start();

          return searchPromise.finally(() => this.cfpLoadingBar.complete());
        })
        .then((results) => {
          if (_.isEmpty(results)) {
            return this.$q.reject('Cannot copy procedure: No procedures found.');
          }

          let msg =
            '<p>Copy an existing procedure to get a head start on instructions, ' +
            'critical limits, corrective actions and more.</p>';

          return this.$uibModal
            .open({
              component: 'cfChooseFromListModal',
              backdrop: 'static',
              size: 'lg',
              resolve: {
                itemName: () => 'sop',
                instructionsHtml: () => msg,
                header: () => '<i class="far fa-drafting-compass fa-fw"></i> Copy Procedure',
                itemsArray: () => results,
                columns: () => [
                  {
                    title: 'Name',
                    property: 'title',
                  },
                ],
              },
            })
            .result.then((item) => {
              let foundItem = item && _.find(results, {$id: item.$id});

              if (!foundItem) {
                throw new Error('SOP missing from selection.');
              }

              return this.save().then(() => this.sopService.copySop(this.user, foundItem));
            });
        })
        .then(($newSop) => {
          $newSop.metadata = _.assign($newSop.metadata || {}, {
            productId: this.product.$id,
            controlId: this.$control.$id,
          });

          this.sops[$newSop.$id] = $newSop;
          this.planSops[$newSop.$id] = $newSop;

          return $newSop
            .$save()
            .then(() =>
              this.productsSvc
                .addProcedure($newSop.metadata.productId, $newSop.metadata.controlId, $newSop.$id)
                .catch((err) =>
                  this.$log.error(
                    'Could not add procedure to product record. Therefore the product ' +
                      'will not have a reference to procedure w/ ID = ' +
                      this.$sop.$id,
                    this.$log.toString(err)
                  )
                )
            )
            .then(() => this.editProcedure($newSop))
            .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to create new procedure'));
        });
    });
  }

  controlTypeChanged(newType) {
    const originalType = this.$control.type;
    let removePrerequisitesPromise = this.$q.resolve();

    if (originalType === 'sop' && _.keys(_.get(this.$control, 'prerequisites', {})).length) {
      removePrerequisitesPromise = this.confirmModal({
        title: 'Removing Procedures',
        okText: 'Yes, I\'m sure.',
        cancelText: 'No, don\'t change.',
        body:
          'This update will remove the <strong>Prerequisite Procedures (SOPs)</strong> from the control. ' +
          'Are you sure you want to change the control type?',
      })
        .then(() => this.controlsService.removeAllPrerequisites(this.product.$id, this.$control.$id))
        .then(() => this.$control.type = newType)
        .catch(() => this.$control.type = originalType);
    }

    removePrerequisitesPromise.then(() => {
      this.$timeout(() => {
        if (
          this.controlsService.requiresSop(this.product.planType, this.$control) ||
          newType === 'other'
        ) {
          this.fetchSop().then((sop) => {
            this.loadCriticalLimitObj(sop);
            this.$control.type = newType;
          });
        } else if (this.$sop) {
          this.confirmModal({
            title: 'Removing Control Measure Details',
            okText: 'Yes, I\'m sure.',
            cancelText: 'No, don\'t change.',
            body:
              'This update will remove the <strong>Critical Limits</strong>, <strong>Monitoring</strong>, ' +
              '<strong>Corrective Action</strong>, <strong>Verification</strong> and <strong>Records</strong> ' +
              'entries from the control. Are you sure you want to change the control type?',
          })
            .then(() => this.removeProcedure())
            .then(() => this.controlsService.removeProcedure(this.product.$id, this.$control.$id))
            .then(() => this.$control.type = newType)
            .then(() => this.save())
            .catch((err) => {
              this.utils.defaultErrorHandler(err, 'Unable to remove the control procedure');
              this.$control.type = originalType;
            });
        }
      });
    });
  }

  removeProcedure() {
    const sopId = this.$sop.$id;

    if (!this.newSop) {
      this.sopService
        .remove(sopId)
        .then(() => {
          this.$control.procedure = null;
          delete this.planSops[sopId];
        })
        .then(() => delete this.$sop);
    } else {
      delete this.$sop;
    }
  }

  changeHazard() {
    const step = this.product.processSteps[this.$control.stepId];
    let introStepName = step.name;

    if (step.number) {
      introStepName = `${step.number}. ${introStepName}`;
    }
    let missingNumber = false;
    let hazardsList = _.map(_.sortBy(this.$hazards, ['name']), (h) => {
      const type = h.groupId ? this.product.hazardGroups[h.groupId].type : h.type;
      let introStep = this.product.processSteps[h.introductionStep] || {};
      let name = introStep.name || '';
      const stepNumber = parseInt(introStep.number);

      if (introStep.number) {
        name = `${introStep.number}. ${name}`;
      }

      missingNumber = missingNumber || _.isNaN(stepNumber);
      return _.assign(h, {
        name: h.groupId ? this.product.hazardGroups[h.groupId].name : h.name,
        type: type,
        typeName: _.get(this.hazardsSvc.hazardTypes[type], 'name', type),
        introducedAt: name,
        introducedAtStepNumber: !missingNumber && parseInt(introStep.number),
      });
    });

    hazardsList = _.orderBy(hazardsList, missingNumber ? 'introducedAt' : 'introducedAtStepNumber');

    return this.$uibModal
      .open({
        component: 'cfChooseFromListModal',
        size: 'lg',
        resolve: {
          chooseBtnHtml: () => '<i class="far fa-check fa-fw g-mr-5"></i>Control Hazards',
          itemName: () => 'hazard',
          multiple: true,
          instructionsHtml: () =>
            '<div class="alert alert-info">' +
            `Choose the hazards that will be controlled at step <b>"${introStepName}"</b>.</div>`,
          header: () => '<i class="far fa-exclamation-triangle fa-fw"></i> Choose Hazards to Control',
          itemsArray: () => hazardsList,
          columns: () => [
            {
              title: 'Type',
              property: 'typeName',
            },
            {
              title: 'Name',
              property: 'name',
            },
            {
              title: 'Introduced At Step',
              property: 'introducedAt',
            },
          ],
        },
      })
      .result.then((hazards) => {
        this.$control.hazards = _.reduce(
          hazards,
          (map, h) => {
            map[h.$id] = true;
            return map;
          },
          {}
        );
        this.loadHazards(hazards);
        this.controlForm.$setDirty();
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to create hazard control.'));
  }
}

module.exports = Controller;
