module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseArray, $firebaseObject, $http, $q, sopService, controlsService, $uibModal,
      confirmModal, productCategories, cfInputModal) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseArray = $firebaseArray;
      this.$firebaseObject = $firebaseObject;
      this.$http = $http;
      this.$q = $q;
      this.sopService = sopService;
      this.controlsSvc = controlsService;
      this.$uibModal = $uibModal;
      this.confirmModal = confirmModal;
      this.productCategories = productCategories;
      this.cfInputModal = cfInputModal;

      this.hazardTypes = {
        bio: {
          icon: 'fas fa-biohazard',
          name: 'Biological'
        },
        chem: {
          icon: 'fas fa-atom-alt',
          name: 'Chemical'
        },
        alg: {
          icon: 'fas fa-allergies',
          name: 'Allergen'
        },
        phys: {
          icon: 'fas fa-gem',
          name: 'Physical'
        }
      };

      this.hazardIcons = {
        bio: {
          icon: 'fas fa-biohazard',
          popover: 'Biological Hazard'
        },
        chem: {
          icon: 'fas fa-atom-alt',
          popover: 'Chemical Hazard'
        },
        alg: {
          icon: 'fas fa-allergies',
          popover: 'Allergen Hazard'
        },
        phys: {
          icon: 'fas fa-gem',
          popover: 'Physical Hazard'
        }
      };
    }

    getHazardLibrary() {
      return this.fbutil.ref('hazardsLibrary').once('value').then(snap => snap.val());
    }

    /**
     * Given a product $firebaseObject, return a $firebaseArray of its hazards.
     * @param {object} $product The product as a $firebaseObject
     * @return {*} A promise that resolves to a $firebaseArray of hazards
     */
    $getAll($product) {
      return this.$firebaseArray($product.$ref().ref.child('hazards')).$loaded();
    }

    /**
     * Given a product ID, return an array of the product's hazards.
     * @param {object} productId The product ID
     * @return {*} A promise that resolves to an array of hazards
     */
    $getProductHazards(productId) {
      return this.$firebaseArray(this.fbutil.ref(`products/${productId}/hazards`)).$loaded();
    }


    $getHazard(productId, hazardId) {
      return this.$firebaseObject(this.fbutil.ref(`products/${productId}/hazards/${hazardId}`)).$loaded();
    }

    getHazardsRef(productId) {
      return this.fbutil.ref(`products/${productId}/hazards`);
    }

    $push(productId, hazard) {
      return this.$firebaseObject(this.fbutil.ref(`products/${productId}/hazards`).push(hazard)).$loaded();
    }

    pushHazard($product, hazard) {
      return $product.$ref().ref.child('processSteps').push(hazard);
    }

    /**
     * Copy a hazard and all it's contents to another product
     * @param {object} from Copy from hazard
     * @param {string} from.organizationId The 'from' organizationId
     * @param {string} from.productId The 'from' productId
     * @param {string} from.stepId The 'from' stepId
     * @param {string} from.hazardId The 'from' hazardId
     * @param {object} to Copy to hazard
     * @param {string} to.organizationId The 'to' organizationId
     * @param {string} to.hazardId The 'to' hazardId
     * @param {string} to.productId The 'to' productId
     * @param {string} to.stepId The 'to' stepId
     * @return {Promise} A promise that is resolved to the new hazard Id
     */
    copyHazard(from, to) {
      let url = `/organizations/${to.organizationId}/products/${to.productId}/hazards/copy`;

      return this.$http.post(url, {from, to});
    }

    $getControl($hazard, controlId) {
      return this.$firebaseObject($hazard.$ref().ref.child(`controls/${controlId}`));
    }

    /**
     * Return the full hazard type text.
     * @param {string} type The type id
     * @return {string} The full hazard type text.
     */
    getHazardTypeName(type) {
      return _.get(this.hazardTypes, `${type}.name`, 'unknown');
    }

    /**
     * Remove any control pointers to this hazard and then remove the hazard.
     * @param {object} product The product that owns the hazard
     * @param {string} hazardId The hazard Id to remove.
     * @return {*} A promise that resolves when the hazard is removed.
     */
    remove(product, hazardId) {
      return this.$q.all(_.map(product.controls, (control, controlId) => {
        if (!control.hazards[hazardId]) { return; }

        return this.controlsSvc.removeHazardFromControl(product.$id, hazardId, controlId);
      })).then(() => this.fbutil.ref('products', product.$id, 'hazards', hazardId).remove());
    }

    /**
     * Edits a hazard group for a given product and (if new) assign it as a hazard.
     * This is a convenience so the user doesn't have to hand pick
     * a bunch of hazards for each occurrence of this hazard group.
     * @param {object} product The product that will own the group
     * @param {string} groupId The group identifier
     * @return {*} A promise that resolves to the newly created hazard id.
     */
    editGroup(product, groupId) {
      const isNew = !groupId;
      const group = groupId && product.hazardGroups[groupId];

      return this.getHazardLibrary().then(availableHazards => {
        const itemsArray = _.orderBy(_.map(availableHazards, (h, id) =>
          _.assign(h, {typeName: _.get(this.hazardTypes[h.type], 'name', h.type), $id: id})), 'name');
        let modalOptions = {
          component: 'cfChooseFromListModal',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            filterProperty: () => 'type',
            filterOptions: () => _.map(this.hazardTypes, (val, id) => ({name: val.name, value: id})),
            multiple: true,
            chooseBtnHtml: () => '<i class="far fa-check fa-fw g-mr-5"></i>Choose Hazard(s)',
            itemName: () => 'hazard',
            instructionsHtml: () => '<div class="alert alert-info">' +
              '<strong>Note:</strong> Select individual hazards to be added to a hazard group' +
              ` for plan: <b>${product.brandName}</b>.</div>`,
            header: () => `<i class="far fa-exclamation-triangle fa-fw"></i> ${isNew ? 'Add' : 'Update'} Hazards`,
            itemsArray: () => itemsArray,
            columns: () => [
              {
                title: 'Type',
                property: 'typeName'
              },
              {
                title: 'Name',
                property: 'name'
              }
            ]
          }
        };

        // If we are editing an existing group, preset the selected hazards.
        if (group) {
          modalOptions.filterInitialOption = () => group.type;
          modalOptions.initialCheckedIds = () => group.ids.split(',');
        }
        return this.$uibModal.open(modalOptions).result;
      }).then(hazards => {
        const introduction = _.reduce(hazards, (intro, h) => {
          return intro + `<li>${h.name}</li>`;
        }, '<div>Hazards Selected:</div><ul>') +
          '</ul><label class="mt-3">Enter the name for the hazard group (e.g. Pathogens):</label>';

        return this.cfInputModal({
          title: 'Hazard Group Name',
          intro: introduction,
          initial: isNew ? null : group.name
        }).then(name => this._upsertGroup(product.$id, hazards, name, groupId)).then((ref) => {
          if (groupId) { return; }
          const newHazard = {
            groupId: ref.key,
            key: ref.key
          };

          return this.fbutil.ref(`products/${product.$id}/hazards`).push(newHazard)
            .then(ref => _.assign(newHazard, {$id: ref.key}));
        });
      });
    }

    /**
     * Create or update the hazard group in Firebase.
     * @param {string} productId The product that will own the group.
     * @param {Array} hazards The hazards that belong to the group.
     * @param {string} name The group name.
     * @param {string} groupId Optional. If set, updates the group. If not set creates a new group..
     * @return {*} A promise that resolves to the new group as a hazard.
     * @private
     */
    _upsertGroup(productId, hazards, name, groupId) {
      const newGroup = {
        type: _.first(hazards).type,
        name,
        hazards: _.join(_.map(hazards, (h) => h.name), '|'),
        ids: _.join(_.map(hazards, (h) => h.$id), ',')
      };

      if (groupId) {
        let ref = this.fbutil.ref(`products/${productId}/hazardGroups/${groupId}`);

        return ref.set(newGroup).then(() => ref);
      } else {
        return this.fbutil.ref(`products/${productId}/hazardGroups`).push(newGroup);
      }
    }
    /**
     * Create a new hazard or import hazards from the hazards library.
     * @param {object} user The logged in user
     * @param {object} product The product.
     * @param {$firebaseArray} $hazards A $firebaseArray of existing hazards.
     * @param {string} stepId Optional step Id which is presets at which step the hazard was controlled.
     * @param {object} overrideOptions Additional options to override the modal.
     * @return {*} Returns a promise that resolves to an object with properties "$createdHazard" if a new hazard
     * was created or "hazardsImported" set to true if the user opted to import from the hazards library.
     */
    createHazard(user, product, $hazards, stepId, overrideOptions) {
      overrideOptions = overrideOptions || {};
      return this.getHazardLibrary().then(availableHazards => {
        availableHazards = _.map(availableHazards, (val, $id) => _.assign(val, {$id}));
        let groupsAsHazards = _.map(product.hazardGroups, (val, $id) => {
          let groupText = _.reduce(val.hazards.split('|'), (html, name) => {
            return `${html}<li>${name}</li>`;
          }, '<ul>') + '</ul>';

          return _.assign(val, {$id, isGroup: true, groupText, name: val.name, groupId: $id});
        });
        let itemsArray = _.map(_.sortBy(_.concat(groupsAsHazards, availableHazards), ['isGroup', 'name']), h =>
          _.assign(h, {typeName: _.get(this.hazardTypes[h.type], 'name', h.type)})
        );

        return this.$uibModal
          .open({
            component: 'cfChooseFromListModal',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              multiple: true,
              allowSkip: true,
              filterProperty: () => 'type',
              htmlColumns: true,
              filterOptions: () => _.map(this.hazardTypes, (val, id) => ({name: val.name, value: id})),
              chooseBtnHtml: () => '<i class="far fa-check fa-fw g-mr-5"></i>Choose Hazard(s)',
              skipButtonClass: () => 'u-btn-primary',
              skipButtonHtml: () => '<i class="far fa-plus fa-fw g-mr-5"></i>Create a New Hazard',
              cancelBtnHtml: () => overrideOptions.cancelBtnHtml || null,
              itemName: () => 'hazard',
              instructionsHtml: () => overrideOptions.instructionsHtml ||
                '<div class="alert alert-info">' +
                '<strong>Note:</strong> Select from a list of pre-defined hazards or create a new hazard.</div>',
              header: () => '<i class="far fa-exclamation-triangle fa-fw"></i> Add Hazards',
              itemsArray: () => itemsArray,
              columns: () => [
                {
                  title: 'Group',
                  property: 'groupText'
                },
                {
                  title: 'Type',
                  property: 'typeName'
                },
                {
                  title: 'Name',
                  property: 'name'
                }
              ]
            }
          }).result;
      }).then(items => {
        let recommendGroupPromise = this.$q.resolve();

        if (_.isEmpty(items)) {
          return this.$push(product.$id).then($hazard => {
            $hazard.introductionStep = stepId || null;
            return [$hazard];
          });
        }
        if (!_.some(items, i => i.isGroup) && items.length > 1) {
          const intro = _.reduce(items, (intro, h) => {
            return intro + `<li>${h.name}</li>`;
          }, '<div class="mb-4">Would you like to create a hazard group for the below hazards? ' +
            'This is recommended if the hazards typically appear together in the plan.</div>' +
            '<div>Hazards Selected:</div><ul>') +
            '</ul><label class="mt-3">Enter the name for the hazard group (e.g. Pathogens):</label>';

          recommendGroupPromise = this.cfInputModal({
            title: 'Create Hazard Group?',
            okText: 'Yes, Create Group',
            cancelText: 'No, Keep Separate',
            intro
          }).then(name => this._upsertGroup(product.$id, items, name)
            .then((ref) => _.assign({
              groupId: ref.key,
              $id: ref.key,
              name
            }, {name}))).catch(() => null);
        }
        _.each(items, (i) => {
          i.isGroup = null;
        });

        return recommendGroupPromise.then(groupHazard => {
          if (groupHazard) {
            items = [groupHazard];
          }
          return this.$uibModal.open({
            component: 'cfEditRequiredHazardFields',
            backdrop: 'static',
            resolve: {
              hazards: () => items,
              productName: () => product.brandName,
              introducedStep: () => stepId ? product.processSteps[stepId].name : null,
              product: () => product
            }
          }).result.then((hazardItems) => this.$q.all(
            _.map(hazardItems, (item) => $hazards.$add(
              _.assign(
                {},
                {
                  name: item.groupId ? null : item.name,
                  key: item.$id,
                  type: item.groupId ? null : item.type,
                  groupId: item.groupId || null,
                  isSignificant: _.isUndefined(item.isSignificant) ? null : item.isSignificant,
                  justification: item.justification || null,
                  introductionStep: stepId || null
                },
                {
                  updatedBy: user.uid,
                  updatedOn: firebase.database.ServerValue.TIMESTAMP
                }
              )
            ).then(ref => this.$firebaseObject(ref).$loaded()))
          ));
        });
      });
    }

    _getHazardsList() {
      return this.$q.all({
        foodCategories: this.productCategories.getAll(),
        hazardsLibrary: this.getHazardLibrary()
      }).then(({foodCategories, hazardsLibrary}) => _.reduce(
        foodCategories,
        (allHazards, category) => {
          let categoryHazards = _.reduce(
            category.subCategories,
            (hazardArray, subCat) => {
              return _.concat(hazardArray,
                _.compact(_.map(subCat.hazards, (val, hazardId) =>
                  _.assign(hazardsLibrary[hazardId], {$id: hazardId}))));
            },
            []
          );

          let temp = _.concat(allHazards, categoryHazards);

          return _.uniqBy(temp, 'name');
        }, [])
      );
    }
  }

  ngModule.service('hazardsService', Service);
};
