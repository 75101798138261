require('./styles.scss');

module.exports = function (ngModule) {
  ngModule.component('cfEditProductProcedure', {
    bindings: {
      user: '<',
      product: '<',
      $control: '<',
      $sop: '<',
      sopForm: '=',
      criticalLimitObj: '=',
      otherType: '<',
      foodProducts: '=',
      members: '=',
      groups: '<',
      onSave: '&?'
    },
    template: require('./products.procedure.template.html'),
    controller: require('./products.procedure.controller'),
    controllerAs: 'vm',
  });
};
