module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.batches', {
        url: '/batches',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          batches: ($log, batchService, user) => batchService.query(user.orgContext.id)
            .catch((err) => {
              $log.error('Unable to query batches.', $log.toString(err));
              return [];
            }),
          foodProducts: (user, foodProductService) => foodProductService.$getAllFoodProducts(user.orgContext.id)
        }
      })
      .state('operations.batches.list', {
        url: '',
        template: '<cf-batch-list user="$resolve.user" batches="$resolve.batches" ' +
          'company="$resolve.company" food-products="$resolve.foodProducts"></cf-batch-list>'
      })
      .state('operations.batches.batch', {
        url: '/:batchId',
        template: '<div ui-view></div>',
        resolve: {
          $batch: (batchService, $stateParams) => batchService.$get($stateParams.batchId),
          $billOfMaterials: (batchService, $stateParams) => batchService.$getBillOfMaterials($stateParams.batchId),
          members: (organizations, $q, users, user) => {
            return organizations.$getMembers(user.orgContext.id)
              .then(function(members) {
                return $q.all(_.map(members, function(member) {
                  return users.getName(member.$id).then(function(name) {
                    return {
                      value: member.$id,
                      name: name
                    };
                  });
                }));
              });
          }
        }
      })
      .state('operations.batches.batch.edit', {
        url: '/edit',
        template: '<cf-batch-edit user="$resolve.user" $batch="$resolve.$batch"  ' +
          'food-products="$resolve.foodProducts" batches="$resolve.batches" ' +
          '$bill-of-materials="$resolve.$billOfMaterials" members="$resolve.members"></cf-batch-edit>'
      });
  });
};
