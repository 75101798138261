module.exports = function(ngModule) {
  ngModule.component('cfSopLibraryEdit', {
    bindings: {
      user: '<',
      sops: '<',
      $sop: '<',
      company: '<',
      onSave: '&?',
      onCancel: '&?', // By default cancelling will return to SOP list view. Implement to override this behavior.
      isTemplate: '<',
      useHeader: '<',
      headerTitle: '<',
      headerSubTitle: '<',
      hideHeader: '<',
      origin: '@?' // Origin can be 'hazard', 'sops' or any ID to indicate the origin of the SOP edit view
    },
    template: require('./sop-library.edit.template.html'),
    controller: require('./sop-library.edit.controller.js'),
    controllerAs: 'vm'
  });
};
