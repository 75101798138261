class Constructor {
  constructor($scope, $state, growl, $log, authentication) {
    'ngInject';

    this.growl = growl;
    this.$log = $log;
    this.authentication = authentication;
  }

  $onInit() {
    this.passcode = this.user.passcode.toString() || '1234';
    if(this.passcode.length < 4) {
      this.passcode = '0'.repeat(4 - this.passcode.length) + this.passcode;
    }
  }

  updatePasscode() {
    let passcode = Number(this.passcode);

    this.authentication.setPasscode(this.user.uid, passcode).then(() => {
      this.growl.success('Passcode updated');
    }).catch(err => {
      this.$log.error(err);
    });
  }
}

module.exports = Constructor;
