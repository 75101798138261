module.exports = function($scope, $log, growl, utils, $q, fbutil, $uibModal, $http, Search) {
  'ngInject';

  const vm = this;

  function createSearch() {
    let searchParams = {
      uri: 'search/organization',
      type: 'organization',
      max: 300,
      pageLimit: 30,    // Number of recs to show per page
      body: {
        query: {}
      }
    };

    if (!vm.user.isCfAdmin()) {
      searchParams.context = {
        'organizationId': vm.limitToOrganizations
      };
    }

    return new Search(vm.user, searchParams, 'organizations');
  }

  vm.$onInit = function() {
    vm.user = vm.resolve.user;
    vm.titleHtml = vm.resolve.titleHtml;
    vm.chooseMineButton = vm.resolve.chooseMineButton;
    vm.okButtonText = vm.resolve.okButtonText;
    vm.limitToOrganizations = vm.resolve.organizations;

    vm.orgSearch = createSearch();
    vm.search();
    vm.search.flush();
  };

  vm.chooseOrganization = function(orgId) {
    vm.modalInstance.close(orgId);
  };

  vm.searchKeyPress = function($event) {
    if (utils.isBenignKeyUp($event.keyCode)) {
      return;
    }

    if ($event.keyCode === 13) {
      if (!vm.searchText || vm.searchText.length > 2) {
        vm.search();
      }

      vm.search.flush();
      return;
    }

    if (vm.searchText && vm.searchText.length < 2) {
      return;
    }

    vm.search();
  };

  vm.search = _.debounce(function() {
    delete vm.selectedOrg;
    let body = vm.orgSearch.getSearchQuery();
    let queries = [];

    if (vm.limitToOrganizations) {
      queries.push({
        ids: {
          values: vm.limitToOrganizations
        }
      });
    }

    if (vm.searchText) {
      queries.push({
        'match_phrase_prefix': {
          companyName: {
            query: vm.searchText.toLowerCase(),
            slop: 3,
            'max_expansions': 20
          }
        }
      });
    }

    if (!queries.length) {
      body.query = {
        'match_all': {}
      };
    } else if (queries.length === 1) {
      body.query = _.first(queries);
    } else {
      body.query = {
        bool: {
          must: queries
        }
      };
    }

    vm.orgSearch.setSearchQuery(body);

    vm.searching = vm.loading = true;

    return vm.orgSearch.search().catch((err) => {
      $log.error(err);
      growl.error('Error occurred during search');
    }).then((results) => {
      return !results ? [] : results;
    }).finally(function() {
      vm.maxReached = vm.orgSearch.maxReached();
      vm.searching = vm.loading = false;
    });
  }, 200);

  vm.getMore = function() {
    vm.loading = true;
    $q.when(vm.orgSearch.getMore()).finally(function() {
      vm.maxReached = vm.orgSearch.maxReached();
      vm.loading = false;
    });
  };
};
