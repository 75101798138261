module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.recall',
    ngModule = angular.module(moduleName, [
      require('./plan')(moduleName),
      require('./events')(moduleName),
      require('./customers')(moduleName),
      require('./problem-reports')(moduleName),
      require('./product-complaints')(moduleName)
    ]);

  require('./_config')(ngModule);
  require('./_services')(ngModule);

  return moduleName;
};
