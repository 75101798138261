require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfProcessStep', {
    bindings: {
      resolve: '=',
      modalInstance: '='
    },
    template: require('./products.edit.process-step.template.html'),
    controller: require('./products.edit.process-step.controller.js'),
    controllerAs: 'vm'
  });
};
