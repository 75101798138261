class Constructor {
  constructor(growl, $log, accessRequests) {
    'ngInject';

    this.growl = growl;
    this.$log = $log;
    this.accessRequests = accessRequests;
  }

  $onInit() {
    this.accessRequestNotification = this.resolve.accessRequestNotification;
    this.user = this.resolve.user;

    this.step = this.accessRequestNotification ? 1 : 2;
  }

  advance() {
    this.step++;
  }

  approve() {
    this.approving = true;
    const metadata = this.accessRequestNotification.metaData;

    this.accessRequests.approveAccessRequestNotification(this.user, metadata.accessRequestId,
      this.accessRequestNotification.$id, metadata.emailToken)
      .then(() => {
        this.advance();
        this.growl.success('Access request approved.');
        this.$log.info('Access request approved by ' +
          this.user.uid, {notificationId: this.accessRequestNotification.$id});
      })
      .catch(err => {
        this.$log.error('Error occurred approving access request: ' + err);
      })
      .finally(() => {
        this.approving = false;
      });
  }

  reject() {
    this.rejecting = true;
    const metadata = this.accessRequestNotification.metaData;

    this.accessRequests.rejectAccessRequestNotification(this.user, metadata.accessRequestId,
      this.accessRequestNotification.$id, metadata.emailToken)
      .then(() => {
        this.advance();
        this.growl.success('Access request rejected.');
        this.$log.info('Access request rejected by ' + this.user.uid,
          {notificationId: this.accessRequestNotification.$id});
      })
      .catch(err => {
        this.$log.error('Error occurred rejecting access request: ' + err);
      })
      .finally(() => {
        this.rejecting = false;
      });
  }
}

module.exports = Constructor;
