module.exports = function(ngModule) {
  class EquipmentService {
    constructor($firebaseObject, fbutil, $q, EquipmentSearch, $firebaseArray, $http) {
      'ngInject';

      this.EquipmentSearch = EquipmentSearch;
      this.$firebaseObject = $firebaseObject;
      this.fbutil = fbutil;
      this.$q = $q;
      this.$firebaseArray = $firebaseArray;
      this.$http = $http;
      this.baseUri = 'equipment';
    }

    /**
     * Get a list of equipment associated with an org as a firebase object.
     * @param {string} organizationId an organization ID
     * @returns {*} The equipment firebase array
     */
    $getList(organizationId) {
      return this.$firebaseArray(this.fbutil.ref(this.baseUri).orderByChild('organizationId').equalTo(organizationId)).$loaded();
    }

    /**
     * Get an equipment firebase object.
     * @param {string} equipmentId The equipment ID
     * @returns {*} The equipment firebase object
     */
    $get(equipmentId) {
      return this.$firebaseObject(this.fbutil.ref(this.baseUri, equipmentId)).$loaded();
    }
    get(equipmentId) {
      return this.$http.get(`${this.baseUri}/${equipmentId}`)
        .then((result) => result?.data?.data);
    }

    /**
     * Push a new equipment record. The pushed object can be empty (undefined).
     * @param {object} equipment  A new equipment record.
     * @return {*}  A promise that resolves to an empty $firebaseObject.
     */
    $push(equipment) {
      return this.$firebaseObject(this.fbutil.ref(this.baseUri).push(equipment)).$loaded();
    }

    push(data) {
    return  this.$http.post(this.baseUri, data)
      .then(results => results.data?.data);
    }

    update(id, data) {
      return this.$http.patch(`${this.baseUri}/${id}`, data)
        .then((results) => results.data?.data);
    }

    deleteEquipment(id) {
      return  this.$http.delete(`inventoryItems/${id}`)
        .then((results)=>  results.data?.data);
    }

    /**
     * Soft delete a piece of equipment.
     * @param {string} id The equipment ID
     * @return {Promise} A promise that resolves when the equipment is removed
     */
    delete(id) {
      return this.fbutil.ref('equipment', id, 'deleted').set(true);
    }

    /**
     * Get all equipment for a given organization.
     * @param {string} orgId The owning org's id
     * @param {string} searchText The query text
     * @param {number} from From index
     * @param {number} size Number of results to return
     * @returns {Promise} A promise that resolves to a batch array
     */
    query(orgId, searchText, from = 0, size = 100) {
      let equipmentSearch = new this.EquipmentSearch(orgId);

      equipmentSearch = equipmentSearch.startFrom(from).size(size);

      return equipmentSearch.search(searchText);
    }

    fetchSpecs($equipment) {
      return this.$firebaseArray($equipment.$ref().child('specs')).$loaded();
    }
  }

  ngModule.service('equipmentService', EquipmentService);
};
