module.exports = function(ngModule) {
  require('./orders')(ngModule);
  require('./organizations')(ngModule);
  require('./verifications')(ngModule);
  require('./suppliers')(ngModule);
  require('./ingredients')(ngModule);
  require('./url-tokens')(ngModule);
  require('./marketing')(ngModule);
  require('./services')(ngModule);
  require('./products')(ngModule);
  require('./product-groups')(ngModule);
  require('./users')(ngModule);
  require('./access-request')(ngModule);
  require('./gmps')(ngModule);
  require('./billing')(ngModule);
  require('./marketplace-services')(ngModule);
  require('./letters')(ngModule);
  require('./request-for-quote')(ngModule);
  require('./constants')(ngModule);
  require('./audit')(ngModule);
  require('./fileBucket')(ngModule);
  require('./batchRuns')(ngModule);
  require('./user-groups')(ngModule);
};
