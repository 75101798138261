class Constructor {
  constructor($window, $http, reportsService, utils) {
    'ngInject';
    this.$window = $window;
    this.$http = $http;
    this.reportsService = reportsService;
    this.utils = utils;
  }

  $onInit() {
    this.reportUrl = this.resolve.reportUrl;
    this.product = this.resolve.product;
    this.user = this.resolve.user;
    this.scale = 0.4;
    this.PRINT_UNITS = 250 / 72.0;
    this.planPurchased = !this.user.onPayAsGoPlan() || this.product.chargeId || this.product.unlockedBy || this.user.isCfAdmin();
    this.generateReport();
    this.isError = false;
  }

  generateReport() {
    this.generatingReport = true;
    this.$http.get(this.reportUrl)
      .then(async (response) => {
        this.generatingReport = false;
        this.url = await this.utils.pdfGenerator(response);
        this.initializePdf(this.url);
      })
      .catch((err)=>{
        this.isError = true;
        this.$log.error(err);
      })
    ;
  }

  initializePdf(url) {
    this.pdfjsLib.getDocument(url).promise.then((pdf_)=>{
      this.thePdf = pdf_;
      let viewer = document.getElementById('pdf-viewer');

      for(let page = 1; page <= this.thePdf.numPages; page++) {
        let canvas = document.createElement('canvas');

        canvas.oncontextmenu = function(e) { e.preventDefault(); e.stopPropagation(); };
        canvas.className = 'pdf-page-canvas';
        viewer.appendChild(canvas);
        this.renderPage(page, canvas);
      }
    });
  }

  downloadPlan() {
    this.reportsService.downloadPlan(this.user, this.product, this.url);
  }

  renderPage(pageNumber, canvas) {
    this.thePdf.getPage(pageNumber).then((page)=>{
      let viewport = page.getViewport({scale: this.scale});
      let transform = [this.PRINT_UNITS, 0, 0, this.PRINT_UNITS, 0, 0];

      canvas.width = Math.floor(viewport.width * this.PRINT_UNITS);
      canvas.height = Math.floor(viewport.height * this.PRINT_UNITS);
      page.render({canvasContext: canvas.getContext('2d'), viewport, transform});
    });
  }
}

module.exports = Constructor;
