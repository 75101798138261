'use strict';

require('./_css');

module.exports = function(parentModuleName) {
  const moduleName = parentModuleName + '.billing',
    ngModule = angular.module(moduleName, [
      require('./ach')(moduleName)
    ]);

  require('./_components')(ngModule);
  require('./_services')(ngModule);
  require('./_constants')(ngModule);

  return moduleName;
};
