require('./cc-form.scss');

module.exports = function(ngModule) {
  ngModule.component('ccForm', {
    bindings: {
      source: '=',
      nickname: '<'
    },
    template: require('./cc-form.template.html'),
    controller: require('./cc-form.controller.js'),
    controllerAs: 'vm'
  });
};
