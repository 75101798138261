module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.files', {
        url: '/files',
        template: '<fr-files files="$resolve.files" company="$resolve.company" user="$resolve.user"></fr-files>',
        resolve: {
          company: (user, organizations) => organizations.$get(user.orgContext.id),
          files: (user, orgFileManager) => orgFileManager.getAll(user.orgContext.id),
        }
      });
  });
};
