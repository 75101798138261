module.exports = function(ngModule) {
  ngModule.component('cfSopLogEditFormTypeFridgeFreezerTemp', {
    bindings: {
      user: '<',
      $sopLog: '<',
      $sopLogs: '<',
      $sop: '<',
      onSave: '&?',
      onCancel: '&?'
    },
    template: require('./operations.sop-logs.edit-form.type.fridge-freezer-temp.template.html'),
    controller: require('./operations.sop-logs.edit-form.type.fridge-freezer-temp.controller'),
    controllerAs: 'vm'
  });
};
