require('./batch.edit.scss');

module.exports = function(ngModule) {
  ngModule.component('cfBatchEdit', {
    bindings: {
      user: '<',
      batches: '<',
      $batch: '<',
      $billOfMaterials: '<',
      members: '<',
      company: '<',
      foodProducts: '<',
      onSave: '&?'
    },
    template: require('./batch.edit.template.html'),
    controller: require('./batch.edit.controller.js'),
    controllerAs: 'vm'
  });
};
