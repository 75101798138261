class Constructor {
  constructor(CompanyNameSearch, marketplaceService, orgInteractionService, CF_ROLES, authorization, growl,
              $log, notifications, $q) {
    'ngInject';

    this.CompanyNameSearch = CompanyNameSearch;
    this.marketplaceService = marketplaceService;
    this.orgInteractionService = orgInteractionService;
    this.$log = $log;
    this.growl = growl;
    this.$q = $q;
    this.notifications = notifications;
    this.defaultOrgRole = CF_ROLES.PROVIDER;
    this.defaultProductClaims = [authorization.claims.PRODUCT_READ,
      authorization.claims.LETTER_WRITE, authorization.claims.LETTER_READ];
  }

  $onInit() {
    this.order = this.resolve.order;
    this.$orderItem = this.resolve.$orderItem;
    this.currentProfile = this.resolve.currentProfile;
    this.user = this.resolve.user;

    this.providerSearch = new this.CompanyNameSearch(this.user, false)
      .filterByTypes(this.marketplaceService.getProviderOrgTypes()).filterByAuthorizedProvider();
  }

  ok() {
    this.saving = true;
    this.$orderItem.providerOrgId = this.chosenProvider.$id;
    this.$orderItem.providerName = this.chosenProvider.companyName;
    this.$orderItem.$save()
      .then(() => this.marketplaceService.getService(this.$orderItem.service.key, this.$orderItem.providerOrgId))
      .then((service) => this.$q.all([
        this.orgInteractionService.allowAccessToProducts(this.user, this.$orderItem.clientOrgId,
          this.$orderItem.providerOrgId, this.$orderItem.product, _.keys(service.claims) || this.defaultProductClaims),
        this.orgInteractionService.allowAccessToOrganization(this.user, this.$orderItem.clientOrgId,
          this.$orderItem.providerOrgId, service.role || this.defaultOrgRole),
        this.$log.info(`Order provider reassigned - Service: ${service.title}, Organization: ` +
          `${_.get(this.user, 'orgContext.companyName')}, Product: ${this.$orderItem.product.brandName}`)
      ]))
      .then(() => this.notifications.postToOrg({
        from: this.user.uid,
        to: this.$orderItem.providerOrgId,
        message: (this.$orderItem.isRush ? 'A RUSH' : 'An') + ' order was assigned to you for : <i>"' +
        this.$orderItem.service.title + '"</i>',
        link: {state: 'marketplace.orders.list'}
      }))
      .then(() => this.modalInstance.close(this.$orderItem))
      .catch(err => {
        this.saving = false;
        this.growl.error('An error occurred reassigned the provider.');
        this.$log.error('An error occurred reassigned the provider.', this.$log.toString(err));
      });
  }
}

module.exports = Constructor;
