module.exports = function(ngModule) {
  ngModule.component('frChooseFromItemModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./choose-from-item-modal.template.html'),
    controller: require('./choose-from-item-modal.controller.js'),
    controllerAs: 'vm'
  });
};
