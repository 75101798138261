module.exports = function(ngModule) {
  ngModule.component('cfNotifications', {
    bindings: {
      user: '<',
      $company: '<'
    },
    template: require('./users.notifications.template.html'),
    controller: require('./users.notifications.controller.js'),
    controllerAs: 'vm'
  });
};
