class Constructor {
  constructor(utils, $q, $scope) {
    'ngInject';

    this.utils = utils;
    this.$q = $q;
    this.$scope = $scope;
  }

  $onInit() {
    this.header = this.resolve.header;
    this.itemsArray = this.resolve.itemsArray;
    this.searchObject = this.resolve.searchObject;
    this.itemName = this.resolve.itemName;
    this.chooseBtnHtml = this.resolve.chooseBtnHtml;
    this.allowSkip = this.resolve.allowSkip || false;
    this.skipButtonClass = this.resolve.skipButtonClass;
    this.skipButtonHtml = this.resolve.skipButtonHtml;
    this.cancelBtnClass = this.resolve.cancelBtnClass;
    this.cancelBtnHtml = this.resolve.cancelBtnHtml;
    this.instructionsHtml = this.resolve.instructionsHtml;
    this.htmlColumns = this.resolve.htmlColumns;
    this.filterProperty = this.resolve.filterProperty;
    this.filterOptions = this.resolve.filterOptions;
    this.filterInitialOption = this.resolve.filterInitialOption;
    this.columns = this.resolve.columns;
    this.multiple = this.resolve.multiple;
    this.noneFoundHtml = this.resolve.noneFoundHtml || `No ${this.itemName}s found.`;
    this.initialChecked = this.resolve.initialChecked;
    this.clonedItemsArray = _.compact(_.cloneDeep(this.itemsArray));
    this.gridView = true;
    this.isReport = this.resolve.isReport;

    // If searchObject was sent, this will be an async search.
    if (this.searchObject) {
      if (this.itemsArray || this.initialChecked || this.filterProperty || this.filterOptions) {
        throw new Error('Invalid arguments to chooseFromList component.');
      }

      this.search = _.debounce(this._search, 300);
      this._search();
    }

    if (this.initialChecked) {
      _.each(this.clonedItemsArray, (item, index) => {
        item.checked = this.resolve.initialChecked[index];
      });
    }

    if (this.filterProperty) {
      this.$scope.$watch('vm.filterSelected', (selected) => {
        this.clonedItemsArray = _.filter(this.itemsArray, x => x.type === selected);
      });

      this.filterSelected = this.filterInitialOption || _.first(this.filterOptions).value;
    }
  }

  submit() {
    if (this.multiple) {
      if(this.isReport) {
        this.modalInstance.close({checklists:_.filter(this.clonedItemsArray, (val, index) => this.clonedItemsArray[index].checked), gridView: this.gridView});
      } else {
        this.modalInstance.close(_.filter(this.clonedItemsArray, (val, index) => this.clonedItemsArray[index].checked));
      }
    } else {
      this.modalInstance.close(this.selectedItem);
    }
  }
  skip() {
    if(this.isReport) {
      this.modalInstance.close({checklists: null, gridView: this.gridView});
    }
    this.modalInstance.close();
  }

  rowClicked(item) {
    if (this.multiple) {
      item.checked = !item.checked;
    } else {
      this.selectedItem = item;
    }
  }

  getMore() {
    this.loading = true;
    this.$q.when(this.searchObject.getMore())
        .then(results => {
          this.clonedItemsArray = _.concat(this.clonedItemsArray, results);
        })
        .finally(() => {
          this.loading = false;
        });
  }

  searchKeyPress($event) {
    if (!this.searchObject) { return; }
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      this.search.flush();

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
        this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  _search() {
    this.searching = true;
    return this.searchObject.search(this.searchText)
        .then(results => {
          this.clonedItemsArray = results;
        })
        .catch(err => this.utils.defaultErrorHandler('Unable to perform search', err))
        .finally(() => {
          this.searching = false;
        });
  }

  isValid() {
    if (this.multiple) {
      return _.findIndex(this.clonedItemsArray, (item) => item.checked) > -1;
    } else {
      return this.selectedItem;
    }
  }
}

module.exports = Constructor;
