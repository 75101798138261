class Controller {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  $onInit() {
    this.customAuth = this.user.provider === 'password';
  }
}

module.exports = Controller;
