require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductEquipment', {
    bindings: {
      product: '=',
      equipment: '=',
      user: '<',
      isReadOnly: '<',
      onAddEquipment: '&'
    },
    template: require('./products.edit.equipment.template.html'),
    controller: require('./products.edit.equipment.controller'),
    controllerAs: 'vm'
  });
};
