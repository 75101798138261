class Controller {
  constructor($state, $uibModal, $log, $q, sopService, utils, confirmDeleteModal, products, growl,
              CF_ORGANIZATION_ID, sopLogsService, users, orgTypes, orgPerspectives,
              csvImportModal, $window, cfpLoadingBar, sopLibraryService, confirmModal, sopLogFormTypes) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.$q = $q;
    this.sopService = sopService;
    this.utils = utils;
    this.confirmDeleteModal = confirmDeleteModal;
    this.products = products;
    this.growl = growl;
    this.CF_ORGANIZATION_ID = CF_ORGANIZATION_ID;
    this.sopLogsService = sopLogsService;
    this.orgPerspectives = orgPerspectives;
    this.users = users;
    this._ = _;
    this.orgTypes = orgTypes;
    this.csvImportModal = csvImportModal;
    this.$window = $window;
    this.cfpLoadingBar = cfpLoadingBar;
    this.sopLibraryService = sopLibraryService;
    this.confirmModal = confirmModal;
    this.sopLogFormTypes = sopLogFormTypes;

    this.page = 1;
    this.maxReached = false;
    this.searching = false;

    this.PAGE_SIZE = 100;
  }

  $onInit() {
    this.noSops = _.get(this, 'sops.length') === 0;
    this.perspective = this.user.getPerspective();
    this.maxReached = _.get(this, 'sops.length', 0) < this.PAGE_SIZE;
    this.search = _.debounce(this._search, 300);
    this.planStepMap = {};
    this.usersMap = {};
    this.sopService.buildPlanStepMap(this.sops).then((planStepMap) => {
      this.planStepMap = planStepMap;
    });
    this.onSearchComplete(this.sops);
    this.loadMore = _.debounce(() => this.fetchSops()
      .then((result) => { this.sops = _.concat(this.sops, result); }), 300);
    this.utils.usersName(this.sops);
  }

  _search() {
    this.page = 0;
    this.sops = [];
    this.searching = true;
    return this.fetchSops()
      .then((results) => {
        this.sops = results;
        this.onSearchComplete(this.sops);
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch SOPs.'))
      .finally(() => { this.searching = false; });
  }

  onSearchComplete(sops) {
    _.each(sops, (sop) => {
      sop.isPublic = sop.organizationId === this.SAMPLE_ORGANIZATION_ID;
      if (_.isUndefined(this.usersMap[sop.createdBy])) {
        if (sop.organizationId === this.SAMPLE_ORGANIZATION_ID) {
          this.usersMap[sop.createdBy] = 'FoodReady';
        } else {
          this.usersMap[sop.createdBy] = '';
          this.users.getName(sop.createdBy)
            .then((name) => this.usersMap[sop.createdBy] = name || 'Unknown');
        }
      }
      if (sop.metadata && sop.metadata.logFormType) {
        let sopLogType = _.find(this.sopLogFormTypes, {value: sop.metadata.logFormType});
        sop.logTypeName = sopLogType ? sopLogType.name : '';
      } else {
        sop.logTypeName = '<i class="text-danger">No Log selected</i>';
      }
    });
  }

  fetchSops() {
    this.searching = true;

    return this.sopService.query([this.user.orgContext.id], this.searchText, {isFacilitySop: true},
      this.PAGE_SIZE * this.page++, this.PAGE_SIZE)
      .then((sops) => {
        this.maxReached = sops.length < this.PAGE_SIZE;
        this.utils.usersName(this.sops);
        return sops;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred loading SOPs.'))
      .finally(() => this.loading = false);
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  editSop(sop) {
    if (!sop) {
      return this.addSop();
/*
      if (this.areTemplates) {
        return this.sopService.$push()
          .then(($newTemplate) => this.$state.go('user.settings.procedureTemplates.edit', {sopId: $newTemplate.$id}));
      } else {
        return this.sopService.createSop(this.user).then(({$sop, typeId, template}) =>
          this.$state.go('operations.sops.edit', {sopId: $sop.$id, typeId, templateId: _.get(template, '$id')}))
          .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to add new sop.'));
      }
*/
    }

    return this.$state.go('operations.sops.edit', {sopId: sop.$id});
  }

  addSop() {
    this.sopService.promptAddSop(this.user, this.company.types, {copyMultipleLib: true})
      .then((newSopId) => {
        if (newSopId) {
          this.$state.go('operations.sops.edit', {sopId: newSopId});
        } else{
          // Means Multiple SOPs from SOP library are selected.
          this.$window.location.reload();
        }
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new procedure.'));
  }

  deleteSop(sop) {
    // First make sure no plans reference this SOP. This routine isn't very efficient, but it's an edge case
    // so we're going with it
    this.products.getAll(this.company.$id).then(products => {
      return Promise.all(_.map(products, partialProduct => {
        return this.products.get(partialProduct.$id).then(fullProduct => {
          if (_.some(fullProduct.controls,
              c => _.some(c.sops, (s, sopId) => sopId === sop.$id))) {
            return fullProduct.brandName;
          }
        });
      }));
    }).then(prodReferencesArray => {
      let referencingProduct = _.find(prodReferencesArray, x => !!x);

      if (referencingProduct) {
        return this.confirmModal({
          title: 'Unable to Delete SOP',
          body: `The plan <b>${referencingProduct}</b> uses this procedure to control one or more hazards. Remove ` +
              'the reference to this SOP before deleting it.',
          okText: 'Ok',
          hideCancelButton: true
        });
      } else {
        return this.confirmDeleteModal('Procedure', {
          body: 'Are you sure you want to delete the standard operating procedure' +
              `<strong>${sop.title}</strong>?`
        }).then(() => {
          this.sopService.remove(sop.$id)
              .then(() => {
                _.remove(this.sops, {$id: sop.$id});
                this.noSops = !this.sops.length;
                this.growl.success('SOP deleted');
              })
              .catch(err => this.utils.defaultErrorHandlremoveer(err, 'Unable to delete sop.'));
        });
      }
    });
  }

  addLog(sop) {
    return this.sopLogsService.$push()
      .then($log => this.$state.go('operations.sops.addLog', {sopLogId: $log.$id, sopId: sop.$id}))
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to add new log.'));
  }

  updateRecommended(sop) {
    return this.sopService.$get(sop.$id).then($sop => {
      let orgTypesArray = _.values(this.orgTypes);
      let initialChecked = [];

      $sop.recommended = $sop.recommended || {};
      _.each(orgTypesArray, (type, index) => {
        if ($sop.recommended[type.id]) {
          initialChecked[index] = true;
        }
      });
      this.$uibModal.open({
        component: 'cfChooseFromListModal',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          itemName: () => 'Type',
          multiple: () => true,
          header: () => '<i class="far fa-star fa-fw"></i> Choose Organization Types',
          instructionsHtml: () => `<div class="g-mb-10">Choose which organization types have the following recommended SOP: <b>${$sop.title}</b></div>`,
          itemsArray: () => orgTypesArray,
          initialChecked: () => initialChecked,
          columns: () => [
            {title: 'Name', property: 'name'}
          ]
        }
      }).result.then(recommended => {
        $sop.recommended = {};
        _.each(recommended, r => {
          $sop.recommended[r.id] = true;
        });
        return $sop.$save();
      });
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to save SOP.'));
  }

  export() {
    this.sopService.query(this.user.orgContext.id, null,
      {asFile: true}, 0, 9999)
      .then((url) => {
        this.$window.location.replace(url);
      })
      .catch((err) => {
        this.utils.defaultErrorHandler(err, 'Unable to export SOPs');
      });
  }

  import() {
    return this.sopLibraryService.import(this.user).then(result => {
      this.sops = _.concat(this.sops, result.newSops);
      this.noSops = !this.sops.length;

      if (result.errors === result.total) {
        throw new Error('All import records failed.');
      } else if (result.errors) {
        this.growl.warning(result.total - result.errors + ' records imported. ' + result.errors + ' records failed.');
      } else {
        this.growl.success(result.total + ' records imported.');
      }
    }).catch(err => this.utils.defaultErrorHandler(err, 'Error importing SOPs'));
  }

}

module.exports = Controller;
