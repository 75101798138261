require('./onboarding.assign-template.scss');

module.exports = function(ngModule) {
  ngModule.component('cfOnboardingAssignTemplate', {
    bindings: {
      user: '<',
      company: '<',
      template: '<',
      productCategory: '<?',
      productSubCategory: '<?',
      $product: '<'
    },
    template: require('./onboarding.assign-template.template.html'),
    controller: require('./onboarding.assign-template.controller'),
    controllerAs: 'vm'
  });
};
