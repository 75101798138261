module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.sopLibrary', {
        url: '/sopLibrary',
        abstract: true,
        template: '<div ui-view></div>'
      })
      .state('operations.sopLibrary.list', {
        url: '',
        template: '<cf-sop-library-list user="$resolve.user"></cf-sop-library-list>'
      })
      .state('operations.sopLibrary.edit', {
        url: '/edit/:sopId',
        template: '<cf-sop-library-edit user="$resolve.user" $sop="$resolve.$sop" ' +
          'sops="$resolve.sops"></cf-sop-library-edit>',
        resolve: {
          $sop: (sopLibraryService, user, $stateParams) => sopLibraryService.$get($stateParams.sopId)
        }
      });
  });
};
