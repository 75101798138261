module.exports = function (ngModule) {
  require('./editable_field')(ngModule);
  require('./editable_textarea')(ngModule);
  require('./image_cropper')(ngModule);
  require('./csv_import')(ngModule);
  require('./help_banner')(ngModule);
  require('./help_banner_notification')(ngModule);
  require('./media_manager')(ngModule);
  require('./media_manager_progress_bar')(ngModule);
  require('./media_upload_modal')(ngModule);
  require('./required_field_indicator')(ngModule);
  require('./headline')(ngModule);
  require('./header')(ngModule);
  require('./heading')(ngModule);
  require('./choose_organization')(ngModule);
  require('./media')(ngModule);
  require('./media_list')(ngModule);
  require('./radial_menu')(ngModule);
  require('./mini_tour_modal')(ngModule);
  require('./choose_from_list_modal')(ngModule);
  require('./analyst-banner')(ngModule);
  require('./cc_form')(ngModule);
  require('./cf_form_controls')(ngModule);
  require('./view_error')(ngModule);
  require('./barcode-scanner.component.js')(ngModule);
  require('./radio_list_modal')(ngModule);
  require('./create_link_modal')(ngModule);
  require('./pdf_modal')(ngModule);
  require('./logs_modal')(ngModule);
  require('./plan_media_list')(ngModule);
  require('./view_sops')(ngModule);
  require('./choose_date_modal')(ngModule);
  require('./choose_from_item_modal')(ngModule);
  require('./file_view_modal')(ngModule);
  require('./edit_material')(ngModule);
  require('./history_modal')(ngModule);
  require('./history_list')(ngModule);
  require('./send_app_url')(ngModule);
  require('./task_scheduler_modal')(ngModule);
};
