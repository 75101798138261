module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.library',
    ngModule = angular.module(moduleName, []);

  require('./_config')(ngModule);
  require('./_components')(ngModule);
  require('./_services')(ngModule);

  return moduleName;
};
