module.exports = function(ngModule) {
  ngModule.decorator('$uibModal', function($delegate, $rootScope, $log) {
    'ngInject';

    let originalOpen = $delegate.open;

    $delegate.open = function(options) {
      let instance = originalOpen(options);

      let unregisterIdleWatch = $rootScope.$on('IdleTimeout', () => {
        $log.warn('Idle timeout with modal open');
        instance.dismiss('cancel');
        unregisterIdleWatch();
      });

      return instance;
    };


    return $delegate;
  });
};
