require('./_css');

module.exports = function(parentModuleName) {
  var moduleName = parentModuleName + '.notifications',
    ngModule = angular.module(moduleName, [
      require('./customTypes')(moduleName)
    ]);

  require('./_services')(ngModule);
  require('./_components')(ngModule);
  require('./_constants')(ngModule);

  return moduleName;
};
