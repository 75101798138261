module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.sopLogs',
    ngModule = angular.module(moduleName, [
      require('./types')(moduleName)
    ]);

  require('./_components')(ngModule);
  require('./_services')(ngModule);
  require('./_config')(ngModule);

  return moduleName;
};