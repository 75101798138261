'use strict';

module.exports = function($log, $timeout, states, countries, preventDirtyNav, growl, $state) {
  'ngInject';

  let vm = this;

  vm.$onInit = function() {
    vm.states = states();
    vm.countries = countries();

    vm.USA = 'US';
    this.supplier.country = this.supplier.country || this.USA;
    this.supplier.mailingCountry = this.supplier.mailingCountry || this.USA;

    $timeout(() => {
      preventDirtyNav(vm.supplierAddressForm, 'You have unsaved address changes.', vm.save);
    });
  };

  vm.save = function() {
    vm.supplier.$save()
      .then(() => {
        if (vm.supplierAddressForm) { vm.supplierAddressForm.$setPristine(); }
        growl.success('Supplier addresses saved successfully!');
      })
      .catch((err) => {
        $log.error(err);
        growl.error('Unable to save supplier addresses. Please try again or contact FoodReady customer support.');
      });
  };

  vm.cancel = function() {
    if (vm.supplierAddressForm) {
      vm.supplierAddressForm.$setPristine();
    }

    vm.close();
  };

  vm.close = function() {
    $state.go('ingredients.suppliers.list');
  };

  vm.resetState = function(rec) {
    if (rec.country === this.USA) { rec.state = null; }
  };

  vm.resetMailingState = function(rec) {
    if (rec.mailingCountry === this.USA) { rec.mailingState = null; }
  };

  vm.countryName = function(countryId) {
    if (!countryId) { return ''; }
    let country = _.find(vm.countries, c => c.abbreviation === countryId);

    return country ? country.name : '';
  };
};
