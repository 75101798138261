module.exports = function (ngModule) {
  ngModule.component('frHistoryModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<',
    },
    template: require('./history-modal.template.html'),
    controller: require('./history-modal.controller'),
    controllerAs: 'vm',
  });
};
