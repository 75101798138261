'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfProductOrders', {
    bindings: {
      product: '<',
      productOrders: '<',
      user: '='
    },
    template: require('./product-orders.main.template.html'),
    controller: require('./product-orders.main.controller.js'),
    controllerAs: 'vm'
  });
};
