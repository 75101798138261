module.exports = function (ngModule) {
  ngModule.component('frForms', {
    bindings: {
      product: '<',
      planSops: '<',
      onEditControl: '&?'
    },
    template: require('./forms.template.html'),
    controller: require('./forms.controller.js'),
    controllerAs: 'vm',
  });
};
