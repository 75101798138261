module.exports = function(ngModule) {
  class Audit {
    constructor(fbutil) {
      'ngInject';

      this.fbutil = fbutil;

      this.baseUri = 'audit';
    }

    /**
     * Push a new audit onto the audit collection.
     * @param {object} user Logged in user.
     * @param {string} auditType The audit type.
     * @param {string} objectId The object ID for which the audit is for (e.g. order ID).
     * @param {object} metadata Details about the activity being performed on the object.
     * @return {Promise<object>} A promise that resolves to result of the push.
     */
    push(user, auditType, objectId, metadata) {
      if (!_.get(auditType, 'id') || !objectId) {
        throw new Error('Audit type ID or object ID not specified.');
      }

      let auditPayload = {
        type: _.get(auditType, 'id'),
        objectId: objectId,
        organizationId: user.orgContext.id,
        createdOn: firebase.database.ServerValue.TIMESTAMP,
        createdBy: user.uid,
        metadata: metadata
      };

      return this.fbutil.ref(this.baseUri).push(auditPayload);
    }
  }

  ngModule.service('audit', Audit);
};
