module.exports = function(ngModule) {
  ngModule.component('frAddUser', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./users.settings.users.add.template.html'),
    controller: require('./users.settings.users.add.controller.js'),
    controllerAs: 'vm'
  });
};
