class Constructor {
  constructor($scope, countries, countryStates) {
    'ngInject';

    this.$scope = $scope;
    this.countryStatesFunction = countryStates;
    this.states = [];
  }

  $onInit() {
    this.statesToSelect = this.countryStatesFunction();
    this.countries = _.map(this.statesToSelect, (countryMap) => ({
      value: countryMap,
      name: countryMap.countryName
    }));
    this.sortedCountries = _.sortBy(this.countries, 'name');

    this.$scope.$watch("vm.source.addressCountry" , (selected) => {
      this.source.state = '';
      if(selected) {
        let selectedStates = selected.states;

        if (selectedStates) {
          this.states = _.map(selectedStates, (state) => ({
            value: state,
            name: state.abbreviation
          }));
        } else{
          this.states = [];
        }
      }
    });
  }
}

module.exports = Constructor;
