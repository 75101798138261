class CfInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-input.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      type: '@',             // The type of input (default = 'text')
      min: '@',              // Min value for input (if type is number)
      max: '@',              // Max value for input (if type is number)
      label: '@',            // The label text
      name: '@',             // The name of the form control
      helpText: '@',         // Optional subscript helper text
      popoverPlacement: '@', // The placement of the popover help text
      popoverHelpText: '@',  // Optional question mark icon to right of label with popover helper text
      isRequired: '=',       // Is the field required (true / false)
      isReadonly: '=',       // Is the field readonly (true / false)
      isDisabled: '=',       // Is the field disabled (true / false)
      isInvalid: '=',        // Is the field invalid (true / false)
      invalidText: '@',      // The text to display if the field is invalid.
      placeholder: '@',      // Input placeholder
      size: '@',             // Input size (e.g. md, sm, lg)
      onChange: '&',          // Callback onChange
      containerClass: '=',  // Override class (default to g-mb-20)
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';
  }

  link(scope) {
    let vm = scope.vm;

    vm.type = vm.type || 'text';
    vm.invalidText = vm.invalidText || 'Please enter a valid value.';
  }
}

module.exports = CfInput;
