const md5 = require('js-md5');

module.exports = function(ngModule) {
  ngModule.factory('signup', function($log, $q, fbutil, authentication, authorization, suppliers, CF_ORGANIZATION_ID,
                                      organizations, $firebaseAuth, User, users, orgPerspectives,
                                      emailTemplateIds, orgInteractionService, deviceDetector, CF_ROLES,
                                      accessRequests, urlTokens) {
    'ngInject';

    function hydrateProfile(userProperties) {
      return _.assign(userProperties, {
        name: userProperties.name || (userProperties.lastName ?
          userProperties.firstName + ' ' + userProperties.lastName : ''),
        emailHash: userProperties.email ? md5(userProperties.email) : null,
        organizationId: null,
        source: userProperties.source || null,
        createdOn: firebase.database.ServerValue.TIMESTAMP
      });
    }

    function configureOrganization(userProperties, authUser, tokenData, options) {
      if (tokenData && !tokenData.templateId && tokenData.type !== 'supplierFileRequest') {
        // assign the user to the organization specified in the token data
        // TODO: all tokenData payloads need to have a templateId assigned - so we know how to handle various types.
        return assignSelfToOrganization(authUser.uid, userProperties, tokenData).then(orgId => {
          $log.info('Added self to organization', {
            uid: authUser.uid,
            orgId
          }, {type: 'registration'});

          return organizations.addMember(orgId, authUser.uid).then(() => orgId);
        });
      }

      // create the user's org and set applicable authorization
      let orgMembers = {};
      orgMembers[authUser.uid] = true;
      return organizations.create({
        companyName: userProperties.companyName,
        members: orgMembers,
        pointOfContactName: userProperties.name,
        pointOfContactEmail: userProperties.email,
        salespersonId: options.salespersonId || null,
        createdByUid: authUser.uid,
        supplierMgmtOrg: _.get(tokenData, 'type') === 'supplierFileRequest' ? _.get(tokenData, 'organizationId') : null
      })

        // set the orgId on the user record
        .then(org => users.setOrganizationId(authUser.uid, org.$id).then(() => org.$id))

        // give the user 'admin' rights to the organization
        .then((orgId) => authorization.setOrganizationRole(authUser.uid, orgId, CF_ROLES.ADMIN)

        // FoodReady should be given an analyst role since they are the default provider todo: this go away??
          .then(() => authorization.assignOrgToOrganization(authUser.uid, CF_ORGANIZATION_ID, orgId, CF_ROLES.ANALYST))

          // check the tokenData for required processing after org creation.
          .then(() => orgInteractionService.processUrlToken(authUser, tokenData, orgId, true))
          .then(() => orgId));
    }

    /**
     * Assign oneself to an organization using an ownership token. This process is used when a user is invited to
     * join an existing organization.
     * @param {string} uid   The uid of the user making the call.
     * @param {Object} userProperties The new user properties (e.g. name, email, co., etc.)
     * @param {Object} tokenData Token properties from FB token record.
     * @returns {Promise} A promise to be resolved with ownership is established.
     */
    function assignSelfToOrganization(uid, userProperties, tokenData) {
      let accessRequest;
      const accessRequestKey = tokenData.key;
      const clientAccessRequest = tokenData.accessRequestId;

      return accessRequests.execute(clientAccessRequest, accessRequestKey)
        .then(response => {
          accessRequest = response.data;
          let promises = [users.setOrganizationId(uid, accessRequest.targetOrgId)];

          if (tokenData.assumeOwnership) {
            promises.push(fbutil.ref(`organizations/${accessRequest.targetOrgId}/transferDate`)
                .set(firebase.database.ServerValue.TIMESTAMP));
          }

          return $q.all(promises);
        })
        .then(() => fbutil.ref(`organizations/${accessRequest.targetOrgId}/userInvitations`).once('value'))
        .then(openInvitationsSnap => {
          let invitationRecKey = _.findKey(openInvitationsSnap.exists() ? openInvitationsSnap.val() : {},
              rec => rec.token === tokenData.token);

          if (!invitationRecKey) {
            $log.warn('Unable to delete pending invitation from organization.', {
              orgId: accessRequest.targetOrgId,
              tokenId: tokenData.token
            });
            return;
          }
          return fbutil.ref(`organizations/${accessRequest.targetOrgId}/userInvitations/${invitationRecKey}`).remove()
            .catch(err => {
              $log.warn('Unable to delete pending invitation from organization.', {
                orgId: accessRequest.targetOrgId,
                tokenId: tokenData.token,
                errStr: $log.toString(err)
              });
            });
        })
        .then(() => authorization.setOrgContext(uid, accessRequest.targetOrgId))
        .then(() => urlTokens.remove(tokenData.token))
        .then(() => accessRequest.targetOrgId);
    }

    return {
      /**
       * Create the reviews user object, organization & required user access properties
       * @param {Object} userProperties The new user properties (e.g. name, email, co., etc.)
       * @param {Object} authUser The authenticated firebase user
       * @param {Object} tokenData Optional token data
       * @param {Object} options Additional options when configuring a new user/org.
       * @param {string} options.salespersonId The sales person who sold this user/org.
       * @returns {Promise} A promise to be resolved with the result of the update.
       */
      reviews: function(userProperties, authUser, tokenData, options) {
        options = options || {};
        return users.createProfile(authUser.uid, hydrateProfile(userProperties))
            .then(() => authorization.initialize(authUser.uid))
            .then(() => configureOrganization(userProperties, authUser, tokenData,
                {salespersonId: options.salespersonId}))

            // If the token data includes a task to demote the previous org owner, execute that now.
            .then((orgId) => {
              if (tokenData && tokenData.updatePrevOwner) {
                return authorization.setOrganizationRole(tokenData.updatePrevOwner.uid, orgId,
                    tokenData.updatePrevOwner.role);
              }
            })

            // create a new User object...
            .then(() => {
              let newUser = new User(authUser);

              return newUser.$loaded();
            })

            // override the perspective as the user is still signing up (has to pick plan, etc.)
            .then((user) => user.overridePerspective(orgPerspectives.PENDING_SIGNUP).then(() => user))
            .catch((err) => {
              // something failed during the sign-up process - let's remove any related DB entries so the user can try again.
              users.getProfile(authUser.uid)
                  .then((u) => {
                    if (u) {
                      return $q.all([
                        authorization.remove(u.uid),
                        organizations.remove(u, u.organizationId, true),
                        users.removeProfile(u.uid),
                        authUser.delete()
                      ]);
                    }
                  })
                  .catch(() => angular.noop());

              throw err;
            });
      }
    };
  });
};
