module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceClients', {
    bindings: {
      user: '<',
      clients: '=',
      organization: '<',
      defaultServices: '<',
      orgServices: '<'
    },
    template: require('./marketplace.clients.template.html'),
    controller: require('./marketplace.clients.controller'),
    controllerAs: 'vm'
  });
};
