module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceInviteClient', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./marketplace.invite-client.template.html'),
    controller: require('./marketplace.invite-client.controller.js'),
    controllerAs: 'vm'
  });
};
