'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfEditTeamAnalysts', {
    bindings: {
      allAnalysts: '=',
      team: '='
    },
    template: require('./administration.edit-team-analysts.template.html'),
    controller: require('./administration.edit-team-analysts.controller'),
    controllerAs: 'vm'
  });
};