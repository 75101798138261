module.exports = function(ngModule) {
  ngModule.component('cfSopList', {
    bindings: {
      user: '<',
      sops: '<',
      sopTypes: '<',
      company: '<',
      hideHeading: '<'
    },
    template: require('./sops.list.template.html'),
    controller: require('./sops.list.controller'),
    controllerAs: 'vm'
  });
};
