class CfCcExpiryInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-cc-expiry-input.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      label: '@?',           // The label text
      name: '@',             // The name of the form control
      helpText: '@?',        // Optional subscript helper text
      isRequired: '='        // Is the field required (true / false)
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';
  }

  link(scope, elem, attrs, ngModelCtrl) {
    let $input = elem.find('input');

    let listener = scope.$watch(() => ngModelCtrl.$viewValue, (val) => {
      if (val) { $input.val(`${val.month} / ${val.year}`).change(); }

      listener();
    });
  }
}

module.exports = CfCcExpiryInput;
