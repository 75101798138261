module.exports = function(ngModule) {
  ngModule.component('cfSopLogEditFormHeader', {
    bindings: {
      user: '<',
      $sopLog: '<',
      $sopLogs: '<',
      $sop: '<'
    },
    template: require('./operations.sop-logs.edit.form.header.template.html'),
    controller: require('./operations.sop-logs.edit.form.header.controller'),
    controllerAs: 'vm'
  });
};
