class Controller {
  constructor(growl, utils, recallService, $state, batchRunsService) {
    'ngInject';

    this.growl = growl;
    this.utils = utils;
    this.recallService = recallService;
    this.$state = $state;
    this.batchRunsService = batchRunsService;

  }

  $onInit() {
    if(this.$event && this.$event.productComplaintId) {
      this.preview(_.filter(this.$productComplaints,{$id: this.$event.productComplaintId})[0]);
    }
  }

  preview(previewData, save) {
    this.previewData = previewData;
    if(previewData.batchRun) {
      this.batchRunsService.get(previewData.batchRun).then(batchRun => this.batchRun = batchRun);
    }
    if(save) {
      this.save();
    }
  }

  edit(complaintId) {
    if(complaintId) {
      this.$state.go('recall.productComplaints.edit',{productComplaintId: complaintId});
    }
  }

  save() {
    this.$event.$save()
      .then(() => this.growl.success('Product Complaint File Saved'))
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save Product Complaint File'));
  }
}

module.exports = Controller;
