'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfChooseLogs', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./batches.choose-logs.template.html'),
    controller: require('./batches.choose-logs.controller'),
    controllerAs: 'vm'
  });
};
