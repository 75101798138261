class Constructor {
  constructor(
    reportsService,
    $uibModal,
    $log,
    authorization,
    $q,
    growl,
    subscriptionService,
    utils,
    companySubscription,
    orders,
    S3,
    confirmModal,
    $state,
    constantsService,
    sopService,
    recallEventsService,
    operationalChecklistService,
    productSearch,
    products,
    supplierIngredientService,
    users,
    ChecklistsOperationalSearch
  ) {
    'ngInject';

    this.reportsService = reportsService;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.authorization = authorization;
    this.$q = $q;
    this.growl = growl;
    this.subscriptionService = subscriptionService;
    this.utils = utils;
    this.companySubscription = companySubscription;
    this.orders = orders;
    this.S3 = S3;
    this.confirmModal = confirmModal;
    this.$state = $state;
    this.constantsService = constantsService;
    this.sopService = sopService;
    this.recallEventsService = recallEventsService;
    this.operationalChecklistService = operationalChecklistService;
    this.productSearchService = productSearch;
    this.products = products;
    this.supplierIngredientService = supplierIngredientService;
    this.users = users;
    this.ChecklistsOperationalSearch = ChecklistsOperationalSearch;
  }

  $onInit() {
    this.onPremiumPlan = !this.user.onPayAsGoPlan();
    this.canViewServiceReports =
      this.user.doesSubscriptionPermit('facilityReports') ||
      this.user.doesSubscriptionPermit('foodServiceReports') && !this.user.isTrialing();
    this.canViewReports = this.user.doesSubscriptionPermit('facilityReports') && !this.user.isTrialing();
    this.sopPromise = this.sopService.query(this.$company.$id, null, {isFacilitySop: true});
    this.planId = this.user.subscription.plan.id;
    this.setPermissions();

    this.productSearch = new this.productSearchService.ProductSearch(this.user, [this.user.orgContext.id]);
    this.productSearch.searchFields(['brandName']);
    this.productSearch.search().then((results) => {
      this.selectedProduct = _.first(results);
      if (results && results.length) {
        this.selectProduct(this.selectedProduct);
      }
      return results;
    });
  }

  setPermissions() {
    this.onPayAsGo = this.user.onPayAsGoPlan() && !this.user.hasPermission(this.authorization.claims.CF_ADMIN);
    this.onPremiumPlan = !this.user.onPayAsGoPlan();
  }

  fetchReport(reportType, params) {
    // eslint-disable-next-line new-cap
    params = {...params,timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone};
    this.reportsService.fetchCompanyReport(this.user, this.$company, reportType, params);
  }

  selectProduct(searchProduct) {
    this.products.$get(searchProduct.$id).then(($product) => {
      this.$product = $product;
    });
  }

  fetchPcHaccpReport(reportType, skipChargeCheck) {
    let reportCategory;

    if (reportType === 'pcPlan') {
      reportCategory = 'preventiveControlsFull';
    } else if (reportType === 'haccpPlan') {
      reportCategory = 'haccpFull';
    }
    this.reportsService
      .fetchPlanReport(this.user, this.$product, reportCategory, skipChargeCheck)
      .catch((err) => this.utils.defaultErrorHandler(err, 'An error occurred opening the report'));
  }

  canRunGmpComplianceReport() {
    if (this.user.access.cfAdmin) {
      return true;
    }

    return _.some(_.get(this.user, 'access.reviews.organizations'), (org) => org.role === 'admin');
  }

  fetchLogsReport(logsSource) {
    let reportModal = '';

    if (logsSource === 'batch') {
      reportModal = 'frBatchLogsReportModal';
    } else {
      reportModal = 'frProductLogsReportModal';
    }
    let upgradePromise;

    if (!this.user.doesSubscriptionPermit('logs')) {
      upgradePromise = this.subscriptionService.promptForUpgrade(
        this.user,
        'Record Keeping/Logs',
        this.constantsService.get('stripe').then((c) => c.stripeProducts.FACILITY_PACKAGE)
      );
    }

    this.$q
      .when(upgradePromise)
      .then(() => {
        this.$uibModal
          .open({
            component: reportModal,
            resolve: {
              user: () => this.user,
            },
          })
          .result.then(({reportType, params}) => {
            this.fetchReport(reportType, _.assign(params, {noReportCache: true}));
          })
          .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to open logs report.'));
      })
      .catch((err) => {
        this.utils.defaultErrorHandler(err, 'Unable to upgrade user.');
      });
  }

  promptForUpgrade() {
    return this.$uibModal
      .open({
        component: 'frPricingPage',
        size: 'xl',
        backdrop: 'static',
      })
      .result.then((result) => {
        if (result === 'modalDismissed') {
          return this.$q.reject('cancel');
        }
      });
  }

  fetchRecallReport() {
    return this.recallEventsService
      .queryEvents(this.user.orgContext.id)
      .then((recallEvents) => {
        return this.$uibModal.open({
          component: 'cfChooseFromListModal',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            itemName: () => 'Event',
            skipButtonHtml: () => 'Plan Only',
            allowSkip: true,
            header: () => '<i class="far fa-clipboard-list"></i> Choose a Recall Event',
            itemsArray: () =>
              _.map(recallEvents, (e) =>
                _.assign(e, {
                  isMockText: e.isMock ? 'Yes' : 'No',
                  createdOnText: moment(e.createdOn).format('MMMM Do YYYY'),
                })
              ),
            columns: () => [
              {title: 'title', property: 'title'},
              {title: 'Mock?', property: 'isMockText'},
              {title: 'Date', property: 'createdOnText'},
            ],
          },
        }).result;
      })
      .then((event) => this.fetchReport('recallPlan', {eventId: event ? event.$id : null}))
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch recall report.'));
  }

  sopReport() {
    return this.sopPromise
      .then((sops) => {
        if (!sops || !sops.length) {
          this.growl.error('No SOPs found');
          return;
        }

        return this.$uibModal
          .open({
            component: 'frChooseDateModal',
            size: 'lg',
            resolve: {
              header: () => '<i class="far fa-clipboard-list"></i> Choose SOPs',
              option: () => 'SOPs with logs',
              checked: () => true,
              title: () => 'Would you like the report with logs or without logs?',
              subTitle: () => 'View SOP logs by:',
            },
          })
          .result.then((r) => {
            this.param = r.params;
            return this.$uibModal.open({
              component: 'cfChooseFromListModal',
              backdrop: 'static',
              size: 'lg',
              resolve: {
                itemName: () => 'SOPs',
                allowSkip: () => true,
                multiple: () => true,
                skipButtonHtml: () => 'Print All',
                user: () => this.user,
                header: () => '<i class="far fa-clipboard-list"></i> Choose SOPs',
                itemsArray: () => sops,
                columns: () => [{title: 'Name', property: 'title'}],
              },
            }).result;
          });
      })
      .then((r) => {
        this.fetchReport('sop', {
          sops: r ? _.map(r, (s) => s.$id) : undefined,
          startDate: this.param.startDate,
          endDate: this.param.endDate,
        });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to print report'));
  }
  inventoryReport() {
    return this.supplierIngredientService
      .getAllSupplierIngredients(this.$company.$id)
      .then((supplierIngredientslist) => {
        if (!supplierIngredientslist || !supplierIngredientslist.length) {
          this.supplierIngredientslist = false;
          this.growl.error('No Ingredients found');
          return;
        }
        this.supplierIngredientslist = true;
        return this.$uibModal
          .open({
            component: 'frChooseDateModal',
            size: 'lg',
            resolve: {
              header: () => 'Choose Supplier Ingredient',
              option: () => 'Inventory with logs',
              allowSkip: () => true,
              skipButtonHtml: () => 'Print All Inventory',
              checked: () => true,
              title: () => 'Would you like the report with logs or without logs?',
              subTitle: () => 'View Inventory logs by:',
            },
          })
          .result.then((r) => {
            if(r) {
              this.param = r.params;
              return this.$uibModal.open({
                component: 'cfChooseFromListModal',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  itemName: () => 'Supplier Ingredient',
                  header: () => '<i class="far fa-clipboard-list"></i> Choose Supplier Ingredient',
                  itemsArray: () => supplierIngredientslist,
                  columns: () => [
                    {title: 'Ingredient', property: 'ingredientName'},
                    {title: 'Brand Name', property: 'supplierIngredientName'},
                    {title: 'Supplier Name', property: 'supplierName'},
                  ],
                },
              }).result;
            }
          });
      })
      .then((event) => {
        if(event) {
          this.fetchReport('inventory', {
            supplierIngredientId: event ? event.supplierIngredientId : null,
            startDate: this.param.startDate,
            endDate: this.param.endDate,
          });
        }else if(this.supplierIngredientslist) {
          this.fetchReport('ingredientInventory');
        }
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to print report'));
  }

  fetchChecklistReport() {
    let checklistsSearchObject = new this.ChecklistsOperationalSearch(this.user.orgContext.id);

    return this.$uibModal
      .open({
        component: 'cfChooseFromListModal',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          isReport: () => true,
          itemName: () => 'Checklists',
          allowSkip: () => true,
          multiple: () => true,
          skipButtonHtml: () => 'Print All',
          header: () => '<i class="far fa-clipboard-list"></i> Choose Checklists',
          searchObject: () => checklistsSearchObject,
          columns: () => [
            {title: 'Name', property: 'title'},
            {title: 'Updated By', property: 'updatedByName'},
            {title: 'Updated On', property: 'date'},
            {title: 'Status', property: 'status'},
          ],
        },
      })
      .result.then((checklistsData) =>{
        const {checklists, gridView} = checklistsData;

        this.fetchReport('opChecklists', {
          checklistId: checklists ? _.map(checklists, (c) => c.id) : undefined,
          gridView: gridView,
          format: 'pdf',
        });
      }).catch((err) => this.utils.defaultErrorHandler(err, 'Unable to print report'));
  }

  correctiveActionReport() {
    return this.$uibModal
      .open({
        component: 'frChooseDateModal',
        size: 'lg',
        resolve: {
          header: () => '<i class="far fa-cogs"></i> Choose Corrective Action',
          checked: () => true,
          subTitle: () => 'Corrective Action By :',
          noLogOption: () => true,
        },
      })
      .result.then((correctiveAction) =>
        this.fetchReport('capa', {
          startDate: correctiveAction.params.startDate,
          endDate: correctiveAction.params.endDate,
          format: 'pdf',
        })
      )
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to print report'));
  }
  fetchSalesOrdersReport() {
    return this.$uibModal
      .open({
        component: 'frChooseDateModal',
        size: 'lg',
        resolve: {
          header: () => '<i class="far fa-cogs"></i> Sales Orders',
          checked: () => true,
          subTitle: () => 'Sales Orders By :',
          noLogOption: () => true,
          chooseBtnHtml: () =>  '<i class="fas fa-clipboard-list fa-fw" aria-hidden="true"></i> View Report'
        },
      })
      .result.then((salesOrders) =>
        this.fetchReport('salesOrders', {
          startDate: salesOrders.params.startDate,
          endDate: salesOrders.params.endDate,
          format: 'pdf',
        })
      )
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to print report'));
  }
}

module.exports = Constructor;
