'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfHeader', {
    bindings: {
      user: '<',
      showOrgBanner: '<'
    },
    template: require('./header.template.html'),
    controller: require('./header.controller'),
    controllerAs: 'vm'
  });
};
