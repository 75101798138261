'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfGrantOrgReadRequest', {
    bindings: {
      user: '='
    },
    template: require('./company.grant-read-request.template.html'),
    controller: require('./company.grant-read-request.controller'),
    controllerAs: 'vm'
  });
};
