class Constructor {
  constructor(productCategories) {
    'ngInject';

    this.productCategories = productCategories;
  }

  $onInit() {
    this.productCategories.getAll().then(categories => {
      this.categories = _.orderBy(categories, ['group', 'name'], ['asc', 'asc']);
    });
  }
}

module.exports = Constructor;

