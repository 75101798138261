module.exports = function(ngModule) {
  class CompanyService {
    constructor($q, billing, organizations, $log, $uibModal, growl, utils, subscriptionService, gtm) {
      'ngInject';

      this.$q = $q;
      this.billing = billing;
      this.organizations = organizations;
      this.$log = $log;
      this.$uibModal = $uibModal;
      this.growl = growl;
      this.utils = utils;
      this.subscriptionService = subscriptionService;
      this.gtm = gtm;
      // this.gtag = gtag;
      // this.uettag = uettag;
      // this.linkedInTag = linkedInTag;
    }

    /**
     * Set the organization's subscription plan.
     * @param {object} user Logged in user.
     * @param {string} newPlanId  Plan Id to set the user's org to.
     * @param {object} [opts]  Additional optional options for the payment processor.
     * @return {Promise<any>} A promise that resolves to the new subscription.
     */
    subscribe(user, newPlanId, opts) {
      const orgId = user.organizationId;
      let newSubscription;

      return this.billing.createSubscription(orgId, user.customerId, newPlanId, opts)
        .then(result => {
          newSubscription = result;

          this.trackConversion(newSubscription, opts.trial_from_plan);
          return this.organizations.setSubscription(orgId, result, _.get(opts, 'coupon'));
        }).then(() => user.fetchSubscription())
          .then(() => newSubscription);
    }

    /**
     * Change the organization's subscription plan.
     * @param {object} user Logged in user.
     * @param {string} newPlanId  Plan Id to set the user's org to.
     * @param {object} [opts]  Additional optional options for the payment processor.
     * @return {Promise<any>} A promise that resolves to the new subscription.
     */
    changePlan(user, newPlanId, opts) {
      if (!user.customerId) {
        throw new Error('Attempting to start a subscription but payment customer is missing.');
      }

      const orgId = user.organizationId;
      const subscription = user.subscription;

      // If no subscription exists or it is in a cancelled state, create a new one.
      if (!subscription || subscription.status === this.billing.subscriptionStatuses.CANCELED ||
        subscription.status === this.billing.subscriptionStatuses.MISSING) {
        return this.subscribe(user, newPlanId, opts);
      }
      let newSubscription;
      let quantity = opts.quantity;

      delete opts.quantity;
      return this.billing.updateSubscription(orgId, subscription.id, _.assign(opts || {}, {
        items: [{
          id: subscription.items.data[0].id,
          plan: newPlanId,
          quantity: quantity
        }],
      }))
        .then(result => {
          newSubscription = result;

          this.trackConversion(newSubscription, opts.trial_from_plan);
          return this.organizations.setSubscription(orgId, result, _.get(opts, 'coupon'));
        }).then(() => user.fetchSubscription())
          .then(() => newSubscription);
    }

    activate(user, opts) {
      opts = opts || {};
      return this.$uibModal.open({
        component: 'cfChangeDefaultBillingSource',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          user: () => user,
          activating: () => true,
          intro: opts.intro ? () => {
            return opts.intro;
          } : undefined
        }
      }).result
        .then(() => user.fetchSubscription())
        .then(() => this.growl.success('Subscription activated.'))
        .catch(err => this.utils.defaultErrorHandler(err, 'Sorry, we were unable to process your request'));
    }

    showPlanOptions(user, $product) {
      return this.subscriptionService.promptForPlanOptions(user, $product)
        .then(() => window.location.reload())
        .catch(reason => {
          if (this.utils.isModalDismissalByUser(reason)) { return 'modalDismissed'; }

          this.growl.error('Sorry, we were unable to process your upgrade request. Please contact customer support.');
          this.$log.error('Unable to upgrade user.', this.$log.toString(reason));
        });
    }

    trackConversion(newSubscription, isTrial) {
      let eventType;

      if(!isTrial) {
        eventType = 'subscribe';
      } else {
        eventType = 'trial';
        // this.linkedInTag.event({'conversion_id': 8333801});
      }
      if(newSubscription.plan.id) {
        this.gtm.event(eventType, {
          'event_category': 'subscriber',
          'event_label': newSubscription.plan.id
        });
        // this.gtag.event(eventType, {
        //   'event_category': 'subscriber',
        //   'event_label': newSubscription.plan.id
        // });
        // this.uettag.event(eventType, {
        //   'event_category': 'subscriber',
        //   'event_label': newSubscription.plan.id
        // });
      }
    }
  }

  ngModule.service('companySubscription', CompanyService);
};
