class Controller {
  constructor(billing, growl, $log) {
    'ngInject';

    this.billing = billing;
    this.growl = growl;
    this.$log = $log;

    this.newCard = {};
  }

  $onInit() {
    this.user = this.resolve.user;
  }

  save() {
    this.saving = true;
    this.billing.addCard(this.user.organizationId, this.user.customerId,
      this.billing.dehydrateCard(this.newCard), {nickname: this.newCard.nickname})
      .then((source) => this.modalInstance.close(source))
      .catch((err) => {
        this.$log.error('Error occurred adding a credit / debit card', this.$log.toString(err));
        this.growl.error('An error occurred while trying to add your card. ' +
          'Please try again, or contact customer support for assistance.');
      })
      .finally(() => this.saving = false);
  }
}

module.exports = Controller;
