module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceShoppingCart', {
    bindings: {
      user: '<',
      shoppingCartItems: '<'
    },
    template: require('./marketplace.shopping-cart.template.html'),
    controller: require('./marketplace.shopping-cart.controller.js'),
    controllerAs: 'vm'
  });
};
