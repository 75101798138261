module.exports = function(ngModule) {
  ngModule.component('cfProductProperties', {
    bindings: {
      product: '<',
      user: '<',
      isReadOnly: '<'
    },
    template: require('./product.plan-analysis.properties.template.html'),
    controller: require('./product.plan-analysis.properties.controller.js'),
    controllerAs: 'vm'
  });
};
