class Constructor {
  constructor($state, subscriptionService, utils, growl, $log, $q) {
    'ngInject';

    this.$state = $state;
    this.subscriptionService = subscriptionService;
    this.$log = $log;
    this.utils = utils;
    this.growl = growl;
    this.$q = $q;
  }

  $onInit() {
  }

  gotoSupTracking() {
    const canView = !this.user.onPayAsGoPlan();
    let upgradePromise = canView ? this.$q.when() :
      this.subscriptionService.promptForUpgrade(this.user, 'Supplier Management');

    upgradePromise.then(() => {
      this.$state.go('ingredients.fileTracking');
    }).catch(reason => {
      if (this.utils.isModalDismissalByUser(reason)) { return; }

      this.growl.error('An error occurred opening Supplier File Tracking. ' +
        'Please try again or contact FoodReady customer support.');
      this.$log.error('Error opening Supplier File Tracking', reason);
    });
  }
}

module.exports = Constructor;
