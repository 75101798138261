class Controller {
  constructor(recallService, $uibModal, utils, authorization, confirmDeleteModal, growl, $state, reportsService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.recallService = recallService;
    this.utils = utils;
    this.authorization = authorization;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.$state = $state;
    this.reportsService = reportsService;
  }

  $onInit() {
  }

  edit(complaint) {
    if(!complaint) {
      this.recallService.$pushProductComplaint(this.user.orgContext.id).then((newComplaint)=>{
        this.$state.go('recall.productComplaints.edit',{productComplaintId: newComplaint.$id});
      });
    } else {
      this.$state.go('recall.productComplaints.edit',{productComplaintId: complaint.$id});
    }
  }

  remove(complaint) {
    if (this.user.hasPermission(this.authorization.claims.PRODUCT_WRITE)) {
      this.confirmDeleteModal(complaint.initialAction).then(() => {
        if (complaint) {
          this.$productComplaints.$remove(complaint)
            .then(() => this.growl.success('Product Complaint removed successfully.'))
            .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to remove Product complaint.'));
        }
      });
    } else {
      this.growl.error('Insufficient access. Please contact customer support.');
    }
  }

  fetchReport(reportType, productComplaintId) {
    this.reportsService.fetchCompanyReport(this.user, {$id: this.user.orgContext.id},
      reportType, productComplaintId);
  }
}

module.exports = Controller;
