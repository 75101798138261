class Controller {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.recallNotification = this.resolve.recallNotification;
  }

  save() {
    this.recallNotification.$save().then(() => {
      this.modalInstance.close(this.recallNotification);
    }).catch((err) => this.modalInstance.dismiss(err));
  }
}

module.exports = Controller;
