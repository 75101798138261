module.exports = function($uibModal, $state, growl, User, $window, $http, confirmDeleteModal, $log, $firebaseObject,
                          fbutil, utils, $q, confirmModal, supplierIngredientService, $timeout, ingredientService,
                          constantsService, authorization, supplierService, csvImportModal, featureIntroModal,
                          products, batchService, cfInputModal, cfpLoadingBar, moment) {
  'ngInject';

  const vm = this;

  function missingFiles(supIngredients, externalFileTypes) {
    const currentDate = moment().valueOf();
    const nextMonth = moment(currentDate).add(1, 'M').valueOf();

    return $q.all(_.map(supIngredients, (rec, supIngId) => {
      if (!rec.organizationId || vm.missingFilesMap[supIngId]) { return; }  // Some old recs don't have an orgId
      return $q.all({
        files: supplierIngredientService.getFiles(rec.organizationId, rec.supplierId, supIngId,
          !rec.productId || !vm.hasProductAccess[rec.productId]),
        outstandingRequests: supplierService.getRequestFilesTokens(rec.supplierId),
      }).then(result => {
        let numFound = 0;
        let markup = _.reduce(vm.filesNeeded, (curMarkup, fileCat) => {
          let found = _.find(result.files, f => f.category === fileCat);
          let entry = _.find(externalFileTypes, f => f.id === fileCat);
          let name = entry ? entry.text : 'File Name Not Found';
          let message = '<span class="text-danger">Missing</span>';

          if(found) {
            if (currentDate < Number(found.expirationDate) &&  Number(found.expirationDate) < nextMonth) {
              message = '<span class="text-warning">Expired Soon</span>';
            }else if(Number(found.expirationDate) < currentDate) {
              message = '<span class="text-danger">Expired</span>';
            }else {
              message = '<span class="text-success">Valid</span>';
            }
          }
          if (found) { numFound++; }
          return `${curMarkup}<li>${name} ${found ? `- ${found.name}` : '' } - ${message}</li>`;
        }, '<ol>') + '</ol>';

        vm.missingFilesMap[supIngId] = {
          text: `${numFound}/${vm.filesNeeded.length}`,
          markup: markup,
          allFound: numFound === vm.filesNeeded.length,
          requestOutstanding: _.map(result.outstandingRequests, (token, id) => _.assign(token, {id}))
        };
      });
    }));
  }

  function hasProductAccess(supIngredients) {
    return $q.all(_.map(supIngredients, (supIng) => {
      if (!supIng.productId || vm.hasProductAccess[supIng.productId]) { return; }
      return authorization.hasOrgLevelProductException(vm.user, supIng.productOrganizationId,
        supIng.productId, 'filesRead').then(hasAccess => {
          vm.hasProductAccess[supIng.productId] = hasAccess;
        });
    }));
  }

  function fetchSupplierIngredients(ingredientId) {
    if (vm.supplierIngredients[ingredientId]) {
      return $q.when(vm.supplierIngredients[ingredientId]);
    }

    return supplierIngredientService.getAll(ingredientId)
      .then(result => {
        vm.supplierIngredients[ingredientId] = result;
        if (vm.filesNeeded) {
          return vm.externalFileTypesPromise.then(externalFileTypes => {
            return hasProductAccess(result).then(() => missingFiles(result, externalFileTypes)).then(() => result);
          });
        } else {
          return result;
        }
      });
  }

  function search() {
    _.set(vm.ingredientSearch, 'searching', true);
    return vm.ingredientSearch.search(vm.ingredientSearch.searchText)
      .then(results => {
        _.each(results, ingredient => {
          vm.markup[ingredient.$id] = vm.markup[ingredient.$id] || '';
        });
      })
      .catch(err => {
        $log.error(err);
        growl.error('Error occurred during search.');
      })
      .finally(() => _.set(vm.ingredientSearch, 'searching', false));
  }

  vm.$onInit = function() {
    vm.utils = utils;
    vm.isPremium = !vm.user.onPayAsGoPlan();
    vm.importAccess = vm.user.hasPermission(authorization.claims.BULK_IMPORT);
    vm.supplierIngredients = {};
    vm.markup = {};
    vm.hasProductAccess = {};
    vm.missingFilesMap = {};
    vm.canDoLogs = vm.user.doesSubscriptionPermit('logs');
    vm.filesNeeded = _.get(vm.company, 'supplierTracking.fileCategories');
    vm.isTracking = !!vm.filesNeeded;
    vm.filesNeeded = vm.filesNeeded && _.map(vm.filesNeeded.split(','), _.toNumber);
    vm.externalFileTypesPromise = constantsService.get('fileTypes').then(fileTypes => {
      return _.omitBy(fileTypes, fc => fc.companyLevel);
    });

    vm.collapseAll();

    vm.noIngredients = !_.get(vm.ingredientSearch, 'searchResults.length') && !vm.ingredientSearch.searchText;
    search();
    featureIntroModal(vm.user, {
      helpTitle: 'Add Your Suppliers and Ingredients',
      //helpTitle: 'GMPs & Prerequisite Programs',
      helpText: ' Supplier verification is required when a supplier controls a hazard that can materially affect ' +
        'the food’s safety (as determined through a Hazard Analysis).' +
        '<br><br>Enter your suppliers and their products in the <b>Suppliers</b> and <b>Ingredients</b> menus. Afterward, visit the <b>Supplier Management</b> menu to start a <i>Supplier File Tracking Program</i>. We will periodically send your suppliers a link to the <b>File Upload Portal</b>, an easy-to-use site where they can upload all of the documents belonging to your ingredients.',
      tipName: 'featureIntro_supplier',
      // helpVideoUrl: ''
    });
  };

  vm.uploadCsv = function() {
    csvImportModal({
      title: 'Import Ingredient CSV file',
      fields: {
        ingredientName: {required: true, description: 'Ingredient Name'},
        ingredientNumber: {description: 'A unique number identifying your ingredient'},
        description: {description: 'Ingredient Description'},

        supplierName: {
          description: 'Supplier Name<br><small><i>The supplier name is required if any of the following fields are ' +
              'included <span class="text-danger">(Recommended)</span></i></small>'
        },
        supplierNumber: {
          description: 'Unique Supplier Number <small><i><span class="text-danger">(Recommended)</span></i></small>'
        },
        productNumber: {description: 'A unique number identifying the specific supplier product'},
        ingredientRisk: {description: 'Ingredient Risk'},
        contactEmail: {description: 'Supplier Contact Email'},
        contactName: {description: 'Supplier Contact Name'},
        contactTitle: {description: 'Supplier Contact Title'},
        phone: {description: 'Supplier Contact Phone'},
        address: {description: 'Supplier Physical Address Line 1'},
        address2: {description: 'Supplier Physical Address Line 2'},
        city: {description: 'Supplier Physical Address City'},
        state: {description: 'Supplier Physical Address State'},
        postalCode: {description: 'Supplier Physical Address Postal Code'},
        country: {description: 'Supplier Physical Address Country'},
        mailingAddressSameAsPhysical: {description: 'Supplier Mailing Same as Physical? (yes/no)', type: 'yesNo'},
        mailingAddress: {description: 'Supplier Mailing Address Line 1'},
        mailingAddress2: {description: 'Supplier Mailing Address Line 2'},
        mailingCity: {description: 'Supplier City'},
        mailingState: {description: 'Supplier State Code (e.g. IL)'},
        mailingPostalCode: {description: 'Supplier Mailing Postal Code'},
        mailingCountry: {description: 'Supplier Mailing Country'}
      }
    }).then(importedRecords => {
      return $uibModal.open({
        component: 'cfImportIngredientsModal',
        backdrop: 'static',
        resolve: {
          records: () => importedRecords,
          company: () => vm.company,
          user: () => vm.user
        }
      }).result;
    }).then(result => {
      vm.noIngredients = false;
      $timeout(() => search(), 1000);

      if (result.errors === result.total) {
        growl.error('All import records failed.');
      } else if (result.errors) {
        growl.warning(result.total - result.errors + ' records imported. ' + result.errors + ' records failed.');
      } else {
        vm.ingredientSearch.searchResults = vm.ingredientSearch.searchResults || [];
        vm.ingredientSearch.searchResults = _.concat([], result.newSuppliers, vm.ingredientSearch.searchResults);
        growl.success(result.total + ' records imported.');
      }
    }).catch(function(reason) {
      if (utils.isModalDismissalByUser(reason)) { return; }

      growl.error('Error importing records.', {});
      $log.error(reason);
    });
  };

  vm.editIngredient = function(existingIngredient) {
    $uibModal.open({
      component: 'cfEditIngredient',
      backdrop: 'static',
      resolve: {
        user: () => vm.user,
        ingredient: () => existingIngredient ? ingredientService.$get(existingIngredient.$id) :
          ingredientService.$push(vm.company.$id),
        company: () => vm.company
      }
    }).result
      .then(updatedIngredient => {
        vm.noIngredients = false;
        if (existingIngredient) {
          let existing = _.find(vm.ingredientSearch.searchResults, i => i.$id === updatedIngredient.$id);

          _.assign(existing, updatedIngredient);
          return;
        }

        vm.ingredientSearch.searchResults = vm.ingredientSearch.searchResults || [];
        vm.ingredientSearch.searchResults.push(updatedIngredient);
        confirmModal({
          title: 'Add Supplier for ' + _.capitalize(updatedIngredient.name) + '?',
          body: '<b>' + _.capitalize(updatedIngredient.name) + '</b> has been added to you list of ingredients. ' +
          'Would you like to add a ' +
          '<b>' + updatedIngredient.name + '</b> supplier? You can add more any time from the ingredients tab.',
          okText: 'Add Supplier',
          cancelText: 'No, Save and Return'
        }).then(() => {
          $state.go('ingredients.ingredient.supplierIngredient.overview',
            {supplierIngredientId: 'new', ingredientId: updatedIngredient.$id});
        });
      })
      .catch(reason => {
        if (utils.isModalDismissalByUser(reason)) { return; }

        growl.error('Error selecting ingredient.', {});
        $log.error(reason);
      });
  };

  vm.editSupIng = function(ingredientId, supIngId) {
    $state.go('ingredients.ingredient.supplierIngredient.overview',
      {supplierIngredientId: supIngId, ingredientId: ingredientId});
    $timeout(() => delete vm.supplierIngredients[ingredientId], 1000);
  };

  vm.collapseAll = function() {
    _.each(_.get(vm, 'ingredientSearch.searchResults', []), i => {
      i.expanded = false;
    });
  };

  vm.expandIngredient = function(ingredient) {
    let expanded = !ingredient.expanded;

    if (expanded) {
      vm.collapseAll();

      if (!vm.supplierIngredients[ingredient.$id]) {
        ingredient.loading = true;
        fetchSupplierIngredients(ingredient.$id).then(() => {
          $timeout(() => {
            ingredient.loading = false;
            ingredient.expanded = true;
          });
        }).catch(err => utils.defaultErrorHandler(err, 'An error occurred looking up the ingredient.'));

        return;
      }
    }

    ingredient.expanded = expanded;
  };

  vm.removeIngredient = function(ingredient, index) {
    // First make sure no plans reference this equipment. This routine isn't very efficient, but it's an edge case
    // so we're going with it.
    products.getAll(vm.company.$id).then(allProducts => {
      return Promise.all(_.map(allProducts, partialProduct => {
        return products.get(partialProduct.$id).then(fullProduct => {
          if (_.get(fullProduct, `ingredients[${ingredient.$id}]`)) {
            return fullProduct.brandName;
          }
        });
      }));
    }).then(prodReferencesArray => {
      let referencingProduct = _.find(prodReferencesArray, x => !!x);

      if (referencingProduct) {
        return confirmModal({
          title: 'Unable to Delete Ingredient',
          body: `The plan <b>${referencingProduct}</b> references this ingredient. Remove ` +
              'it from the plan before deleting it.',
          okText: 'Ok',
          hideCancelButton: true
        });
      } else {
        return confirmDeleteModal('Ingredient', {
          body: 'Are you sure you want to delete <strong>' + ingredient.name + '</strong>?'
        }).then(() => {
          ingredientService.remove(vm.user, ingredient.$id)
              .then(() => {
                vm.ingredientSearch.searchResults.splice(index, 1);
                growl.success('Ingredient deleted.');
              })
              .catch(err => utils.defaultErrorHandler('Unable to delete ingredient', err));
        });
      }
    });
  };

  vm.removeSupplierIngredient = function(index, supplierIngredientId, supplierIngredient) {
    let ingredientId = supplierIngredient.ingredientId;

    confirmDeleteModal(supplierIngredient.brandName).then(() => {
      supplierIngredientService.remove(vm.user, supplierIngredientId)
        .then(() => {
          delete vm.supplierIngredients[ingredientId][supplierIngredientId];
          _.find(vm.ingredientSearch.searchResults, {$id: ingredientId}).numSuppliers--;
          growl.success('Supplier ingredient deleted.');
        })
        .catch(err => {
          $log.error('Unable to delete supplier ingredient: ' + err);
          growl.error('Could not delete supplier record.');
        });
    });
  };

  vm.searchKeyPress = function($event) {
    if ($event.keyCode === 13) {
      if (!vm.ingredientSearch.searchText || vm.ingredientSearch.searchText.length <= 3) { vm.search(); }

      vm.search.flush();

      return;
    }

    if (utils.isBenignKeyUp($event.keyCode) ||
      vm.ingredientSearch.searchText && vm.ingredientSearch.searchText.length <= 3) { return; }

    vm.search();
  };

  vm.search = _.debounce(search, 300);

  vm.getMore = function() {
    vm.loading = true;
    $q.when(vm.ingredientSearch.getMore())
      .then(results => {
        _.each(results, ingredient => {
          vm.markup[ingredient.$id] = vm.markup[ingredient.$id] || '';
        });
      })
      .finally(() => {
        vm.loading = false;
      });
  };

  vm.fetchReport = function(ingredientId, ingredient, reportType) {
    let reportWindow = $window.open('/api/reports/loading');

    if (!ingredient.productOrganizationId) {
      vm.growl.error('Ingredient organization Id missing.');
      vm.$log.error('Ingredient organization Id missing.', {supIngId: ingredientId});
      return;
    }

    return $http.get('/reports/' + ingredient.productOrganizationId + '/' + ingredient.productId + '/' + reportType + '?format=pdf')
      .then(url => {
        reportWindow.location.replace(url.data);
      })
      .catch(err => {
        $log.error('Error retrieve supplier report: ' + err);
        growl.error('Unable to retrieve report');
      });
  };

  vm.getSuppliersMarkup = function(ingredient) {
    if (_.isEmpty(vm.markup[ingredient.$id])) {
      vm.markup[ingredient.$id] = '<p class="text-muted text-center">Loading...</p>';
      fetchSupplierIngredients(ingredient.$id).then(supplierIngredients => {
        $timeout(() => {
          vm.markup[ingredient.$id] = _.reduce(supplierIngredients, (result, supplierIngredient) => {
            return result + '<li>' + supplierIngredient.supplierName + '</li>';
          }, '<ol>');
          vm.markup[ingredient.$id] += '</ol>';
        }, 0);
      });
    }
    return vm.markup[ingredient.$id];
  };

  vm.findBatchFromLot = function(ingredient, supplierIngredient, supplierIngredientId) {
    return cfInputModal({
      title: 'Enter Lot Number',
      intro: `Enter the lot number to look up for <b>${supplierIngredient ? supplierIngredient.supplierName + ': ' + supplierIngredient.brandName : ingredient.name}</b>.`
    })
        .then(lot => batchService.query(vm.user.orgContext.id, '', {lot: {lot, ingredientId: ingredient.$id, supplierIngredientId}})
            .then(results => {
              if (!results.length) {
                growl.error('Lot not found.');
                return $q.reject('cancel');
              }
              return $q.all(_.reduce(results, (promises, rec) => {
                if (promises[rec.productId]) { return promises; }

                promises[rec.productId] = products.getBrandName(rec.productId);
                return promises;
              }, {})).then((nameMap) => {
                _.each(results, rec => {
                  rec.brandName = nameMap[rec.productId];
                });

                return $uibModal
                    .open({
                      component: 'cfChooseFromListModal',
                      backdrop: 'static',
                      size: 'lg',
                      resolve: {
                        itemName: () => 'Batch',
                        instructionsHtml: () =>
                            `<div class="mb-3">The below batches use lot ${lot}. Choose one below to view the batch details.</div>`,
                        header: () => '<i class="far fa-conveyor-belt mr-2"></i> Choose a Batch to View',
                        itemsArray: () => results,
                        columns: () => [
                          {title: 'Name', property: 'brandName'},
                          {title: 'Batch', property: 'batchNumber'}
                        ]
                      }
                    }).result;
              });
            }))
        .then((batch) => $state.go('operations.batches.batch.edit', {batchId: batch.$id}))
        .catch(err => utils.defaultErrorHandler(err, 'Unable to lookup lot.'));
  };

  vm.bulkDelete = function() {
    return confirmDeleteModal('Bulk Ingredients', {
      body: 'Are you sure you want to bulk delete up to 30 ingredients' +
        ` for <strong>${vm.company.companyName}</strong>?`,
      confirmText: 'Yes, Bulk Delete'
    }).then(() => {
      cfpLoadingBar.start();
      vm.ingredientSearch.search().then(ingredients => {
        return _.reduce(ingredients, (removePromises, ingredient) => {
          return removePromises.then(() => ingredientService.remove(vm.user, ingredient.$id))
            .then(() => console.log(`Deleted ${ingredient.name}`));
        }, $q.resolve()).then(() => growl.success(`Deleted ${ingredients.length} ingredients.`));
      }).then(() => $timeout(() => vm.search(), 2000))
        .catch(err => utils.defaultErrorHandler(err, 'Unable to bulk delete ingredients.'))
        .finally(() => cfpLoadingBar.complete());
    });
  };
};
