module.exports = function(ngModule) {
  ngModule.config(
    function($stateProvider) {
      'ngInject';

      $stateProvider.state('recall.productComplaints', {
        url: '/productComplaints',
        template: '<div ui-view></div>',
        abstract: true,
        resolve: {
          customers: (company, recallService) => recallService.$customers(company.$id),
          complaintTypes: (constantsService) => constantsService.get('complaintTypes')
        },
        controllerAs: 'vm'
      }).state('recall.productComplaints.list', {
        url: '/list',
        template: '<cf-product-complaints customers="$resolve.customers" user="$resolve.user" ' +
          '$product-complaints="$resolve.productComplaints" complaint-types="$resolve.complaintTypes"> </cf-product-complaints>',
        controllerAs: 'vm',
        resolve: {
          productComplaints: (recallService, user) => recallService.$productComplaints(user.orgContext.id)
        }
      })
        .state('recall.productComplaints.edit', {
          url: '/edit/:productComplaintId',
          template: '<cf-product-complaints-edit customers="$resolve.customers" user="$resolve.user" company="$resolve.company" ' +
            '$product-complaint="$resolve.productComplaint" batch-runs="$resolve.batchRuns"' +
              'complaint-types="$resolve.complaintTypes" ></cf-product-complaints-edit>',
          controllerAs: 'vm',
          resolve: {
            productComplaint: (recallService, user, $stateParams) =>
              recallService.$getProductComplaint(user.orgContext.id, $stateParams.productComplaintId),
            batchRuns: ($log, batchService, user) => batchService.getAllBatchRuns(user.orgContext.id)
              .catch((err) => {
                $log.error('Unable to query batches.', $log.toString(err));
                return [];
              })
          }
        });
    }
  );
};
