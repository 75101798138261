class Controller {
  constructor(organizations, utils) {
    'ngInject';

    this.organizations = organizations;
    this.utils = utils;
  }

  $onInit() {
    this.newOrgProps = this.resolve.newOrgProps;
  }

  save() {
    this.organizations.create(_.assign({companyName: this.companyName,
      restrictTemplates: this.restrictTemplates ? this.restrictTemplates : null
    }, this.newOrgProps), {assumeOwnership: true})
      .then(newOrg => this.modalInstance.close(newOrg))
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to create organization.'));
  }
}

module.exports = Controller;
