module.exports = function(ngModule) {
  ngModule.component('cfFacility', {
    bindings: {
      company: '=',
      user: '<',
      closeBtn: '<'
    },
    template: require('./facility.template.html'),
    controller: require('./facility.controller.js'),
    controllerAs: 'vm'
  });
};
