class Controller {
  constructor($state, $uibModal, $log, batchService, utils, $q, products, growl, confirmDeleteModal,
              recallEventsService, foodProductService, confirmModal, units, unitContainers, $timeout) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.batchService = batchService;
    this.utils = utils;
    this.$q = $q;
    this.products = products;
    this.foodProductService = foodProductService;
    this.growl = growl;
    this.confirmDeleteModal = confirmDeleteModal;
    this.recallEventsService = recallEventsService;
    this.confirmModal = confirmModal;
    this.$timeout = $timeout;
    this.page = 1;
    this.maxReached = false;
    this.searching = false;
    this.PAGE_SIZE = 100;
    this.unitList = units();
    this.unitContainers = unitContainers();
  }

  $onInit() {
    this.noBatches = _.get(this, 'batches.length') === 0;
    this.maxReached = _.get(this, 'batches.length', 0) < this.PAGE_SIZE;
    this.search = _.debounce(this._search, 300);
    this.markup = {};
    _.each(this.batches, batch => {
      this.markup[batch.$id] = this.markup[batch.$id] || '';
    });
    this.loadMore = _.debounce(() => this.fetchBatches()
      .then((result) => { this.batches = _.concat(this.batches, result); }), 300);
    this.getPlanNames();
  }

  getPlanNames() {
    let planNames = {};

    this.$q.all(_.map(this.batches, batch => {
      if (planNames[batch.productId]) { return; }

      planNames[batch.productId] = true;
      return this.products.getBrandName(batch.productId).then(brandName => {
        planNames[batch.productId] = brandName;
      });
    })).then(() => {
      _.each(this.batches, batch => {
        batch.productName = planNames[batch.productId];
        if (batch.noOfUnits && batch.unitType) {
          let singularPlural = batch.noOfUnits > 1 ? 's' : '';
          batch.totalSizeString = batch.noOfUnits + ' ' + batch.unitType + singularPlural;
        } else {
          batch.totalSizeString = '<i>Update the quantity</i>';
        }
      });
    });
  }

  _search() {
    this.page = 0;
    this.batches = [];
    this.searching = true;
    return this.fetchBatches()
      .then((result) => {
        this.batches = result;
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch Batches.'))
      .finally(() => { this.searching = false; });
  }

  fetchBatches() {
    this.searching = true;
    return this.batchService.query(this.user.orgContext.id, this.searchText,
      this.PAGE_SIZE * this.page++, this.PAGE_SIZE)
      .then((batches) => {
        this.maxReached = batches.length < this.PAGE_SIZE;
        _.each(batches, batch => {
          this.markup[batch.$id] = this.markup[batch.$id] || '';
        });

        return batches;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred loading Batches.'))
      .finally(() => this.loading = false);
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  editBatch(batch) {
    this.$q.when(batch || this.batchService.$push())
      .then($batch => this.$state.go('operations.batches.batch.edit', {batchId: $batch.$id})
    );
  }

  cloneBatch(batch) {
    this.batchService.$get(batch.$id).then((batchObj) => {
      this.batchService.$push()
        .then(async ($cloneBatch) => {
          _.assign($cloneBatch, _.pick(batchObj, ['billOfMaterials', 'brandName', 'foodProductId', 'foodProductName',
            'measurementUnit', 'member', 'noOfUnits', 'procedureLogMap','processInfos', 'productId', 'size',
            'sizeOfUnit', 'sopId', 'unitContainer','unitType']));

          await this.foodProductService.getFoodProductNumber(batchObj.foodProductId).then((foodProductNumber) => {
            const today = new Date();
            const todayTime = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();

            $cloneBatch.batchNumber = `${foodProductNumber}-${Math.floor(parseInt(todayTime))}`;
          })
            .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to clone batch.'));

          $cloneBatch.batchTitle = batchObj.batchTitle + ' - CLONED';
          $cloneBatch.createdOn = firebase.database.ServerValue.TIMESTAMP;
          $cloneBatch.createdBy = this.user.uid;
          $cloneBatch.organizationId = this.user.orgContext.id;
          $cloneBatch.cloneFromBatch = batchObj.$id;
          $cloneBatch.$save()
            .then(() => {
              this.batches.push($cloneBatch);
              this.growl.success('Batch cloned successfully.');
              this.$state.go('operations.batches.batch.edit', {batchId: $cloneBatch.$id});
            })
            .catch((err)=> this.utils.defaultErrorHandler(err, 'Unable to clone batch.'));
        });
    });
  }

  deleteBatch(batch) {
    this.confirmDeleteModal('Batch', {
      body: 'Are you sure you want to delete batch number <strong>' + batch.batchNumber + '</strong>?'
    }).then(() => {
      this.batchService.delete(batch.$id).then(() => {
        _.remove(this.batches, {$id: batch.$id});
        this.growl.success('Batch deleted');
      }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete batch.'));
    });
  }

  getBOMMarkup(batchId) {
    if (_.isEmpty(this.markup[batchId])) {
      this.markup[batchId] = '<p class="text-muted text-center">Loading...</p>';
      this.batchService.getBillOfMaterials(batchId).then(billOfMaterials => {
        this.$timeout(() => {
          this.markup[batchId] = _.reduce(billOfMaterials, (result, billOfMaterial) => {
            return result + '<li>' + billOfMaterial.ingredientName + '</li>';
          }, '<ol>');
          this.markup[batchId] += '</ol>';
        }, 0);
      });
    }
      return this.markup[batchId];
  }

  viewBOM(batch) {
    this.batchService.$get(batch.$id).then(($batch) => {
      this.$uibModal.open({
        component: 'frEditMaterial',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          user: () => this.user,
          $batch: () => $batch
        }
      }).result.then(() => {
        $batch.$save().then(()=> {
          this.growl.success('Ingredients successfully saved.');
          this.search();
        }).catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save ingredients.'));
      }).catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }
        this.utils.defaultErrorHandler('Unable to save ingredient.');
      });
    })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save ingredients.'));
  }
  // collapseAll() {
  //   _.each(_.get(this, 'batches', []), i => {
  //     i.expanded = false;
  //   });
  // }

  // expandBatch(batch) {
  //   let expanded = !batch.expanded;
  //
  //   if (expanded) {
  //     this.collapseAll();
  //
  //     if (batch.$id) {
  //       batch.loading = true;
  //       this.batchService.$get(batch.$id).then(batchData => {
  //         this.batch = batchData;
  //         let unitType = batchData.unitType ? _.find(this.unitContainers,{value: batchData.unitType}).name : '';
  //         let measurementUnit = batchData.measurementUnit ? _.find(this.unitList,{value: batchData.measurementUnit}).name : '';
  //         this.batch.totalSizeString = (batchData.noOfUnits && batchData.sizeOfUnit) ? batchData.noOfUnits + ' ' + unitType +
  //           ' (' + batchData.noOfUnits * batchData.sizeOfUnit + measurementUnit + ')' : 'Update the quantity';
  //         batch.loading = false;
  //         batch.expanded = true;
  //       }).catch(() => console.log( 'An error occurred looking up the material.'));
  //       return;
  //     }
  //   }
  //   batch.expanded = expanded;
  // }

  // saveMaterial() {
  //   this.batch.$save().then(() => this.growl.success('Save Material data Successfully.'))
  //     .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to Save Material data.'));
  // }
}

module.exports = Controller;
