require('./project_writing.edit.scss');

module.exports = function(ngModule) {
  ngModule.component('cfProjectWritingEditModal', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./project_writing.edit.template.html'),
    controller: require('./project_writing.edit.controller'),
    controllerAs: 'vm'
  });
};
