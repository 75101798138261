module.exports = function(ngModule) {
  ngModule.component('cfImportSopsModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./ingredients.import-sops-modal.template.html'),
    controller: require('./ingredients.import-sops-modal.controller'),
    controllerAs: 'vm'
  });
};