'use strict';

module.exports = function(ngModule) {
  ngModule.factory('ingredientService', function(fbutil, $q, supplierIngredientService, $firebaseObject, $http) {
    'ngInject';

    return {
      /**
       * Fetch an ingredient object.
       * @param {string} ingredientId The ingredients unique identifier
       * @return {*}  A promise that resolves to an ingredient object.
       */
      get: (ingredientId) => {
        return $http.get(`inventoryItems/${ingredientId}`)
          .then((result)=> result.data?.data);
      },

      /**
       * Fetch an ingredient FB object.
       * @param {string} ingredientId The ingredients unique identifier
       * @return {*}  A promise that resolves to an angularFire ingredient object.
       */
      $get: (ingredientId) => {
        return $firebaseObject(fbutil.ref('ingredients', ingredientId)).$loaded();
      },

      /**
       * Push a new empty ingredient.
       * @param {string} orgId The organization who owns the ingredient.
       * @return {*}  A promise that resolves to an angularFire ingredient object.
       */
      $push: (orgId) => {
        return $firebaseObject(fbutil.ref('ingredients').push()).$loaded()
          .then($newIngredient => {
            $newIngredient.organizationId = orgId;
            $newIngredient.numSuppliers = 0;
            $newIngredient.createdOn = firebase.database.ServerValue.TIMESTAMP;
            return $newIngredient;
          });
      },

      remove: (user, ingredientId) => {
        var ingredient;

        return fbutil.ref('ingredients', ingredientId).once('value')
          .then(ingredientSnap => {
            if (!ingredientSnap.exists()) {
              return $q.reject('Ingredient not found');
            }
            ingredient = ingredientSnap.val();
            if (!ingredient.supplierIngredients) { return; }

            return $q.all(_.map(ingredient.supplierIngredients, (val, supplierIngredientId) => {
              return supplierIngredientService.remove(user, supplierIngredientId);
            }));
          })
          .then(function() {
            if (!ingredient.processSteps) {
              return;
            }

            return $q.all(_.map(ingredient.processSteps, processStep => {
              return fbutil.ref('products', processStep.productId, 'processSteps', processStep.processStep,
                'ingredients', ingredientId).remove();
            }));
          })
          .then(function() {
            return $q.when([
              fbutil.ref('ingredients', ingredientId, 'deleted').set(new Date().getTime()),
              fbutil.ref('ingredients', ingredientId, 'deletedOn').set(new Date().getTime()),
            ]);
          });
      },

      /**
       * Remove pointers from an ingredient to a process step.
       * @param {string} ingredientId The ingredient ID.
       * @param {string} stepId The step ID.
       * @return {Promise} A promise that is resolved when the pointers are removed.
       */
      removeStepPointers: function(ingredientId, stepId) {
        fbutil.ref('ingredients', ingredientId, 'processSteps').orderByChild('processStep')
          .equalTo(stepId).once('value')
          .then((snap) => {
            snap.forEach((childSnap) => {
              childSnap.ref.remove();
            });
          });
      },

      /**
       * Add pointer to a process step.
       * @param {string} ingredientId The ingredient ID.
       * @param {string} productId The product ID.
       * @param {string} processStepId The process step ID.
       * @return {Promise} A promise that is resolved when the pointer is added.
       */
      addStepPointer: function(ingredientId, productId, processStepId) {
        return fbutil.ref('ingredients', ingredientId, 'processSteps').push({
          productId: productId,
          processStep: processStepId
        });
      },

      ingredientRef: function(ingredientId) {
        return fbutil.ref('ingredients', ingredientId);
      },

      getIngredientLotsFile: (supplierIngredientId,organizationId) => {
        return $http.get(`ingredient-logs/supplier-ingredient/${supplierIngredientId}/organization/${organizationId}`)
          .then((result)=> result.data?.response);
      },
    };
  });
};
