class Controller {
  constructor($state, orgTypes, confirmModal, $uibModal, growl, $log, subscriptionService, $q, organizations,
              companyService, utils) {
    'ngInject';

    this.$state = $state;
    this.orgTypes = orgTypes;
    this.confirmModal = confirmModal;
    this.$uibModal = $uibModal;
    this.growl = growl;
    this.$log = $log;
    this.subscriptionService = subscriptionService;
    this.$q = $q;
    this.organizations = organizations;
    this.companyService = companyService;
    this.utils = utils;
  }

  gotoGmps() {
    const canView = !this.user.onPayAsGoPlan();
    let upgradePromise = canView ? this.$q.when() :
      this.subscriptionService.promptForUpgrade(this.user, 'Good Manufacturing Practices Survey');

    upgradePromise.then(() => {
      if (!_.isUndefined(this.company.facilities)) {
        const firstFacilityId = _.first(_.keys(this.company.facilities));

        if (!firstFacilityId) {
          throw Error('Error routing to GMPs');
        }
        return this.$q.when(firstFacilityId);
      }

      return this.confirmModal({
        title: 'No Facilities Defined',
        body: 'Before adding GMPs, you must first add a facility.',
        okText: 'Add Facility',
        cancelText: 'Cancel'
      }).then(() => {
        return this.$uibModal.open({
          component: 'cfAddFacility',
          backdrop: 'static',
          resolve: {
            orgId: () => this.company.$id,
            facility: () => this.organizations.$pushFacility(this.company.$id)
          }
        }).result
          .then((newFacility) => {
            this.growl.success('Facility added successfully.');
            return newFacility.$id;
          });
      });
    }).then(facilityId => {
      this.$state.go('company.edit.facility.gmps.edit.category', {
        companyId: this.company.$id,
        facilityId: facilityId
      });
    }).catch(reason => {
      if (this.utils.isModalDismissalByUser(reason)) { return; }

      this.growl.error('An error occurred opening GMPs. Please try again or contact FoodReady customer support.');
      this.$log.error('Error opening GMPs', reason);
    });
  }
}

module.exports = Controller;
