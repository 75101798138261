const csv = require('csvtojson');

class Constructor {
  constructor(growl, $log, Upload, utils, $timeout, $q, drift) {
    'ngInject';

    this.growl = growl;
    this.$log = $log;
    this.Upload = Upload;
    this.utils = utils;
    this.$timeout = $timeout;
    this.$q = $q;
    this.drift = drift;
  }

  $onInit() {
    this.fields = this.resolve.fields;
    this.user = this.resolve.user;
    this.fileType = 'csv';
    this.step = this.user && this.user.isCfAdmin() ? 'chooseType' : 'import';
  }

  parseFile(contents) {
    let lines = contents.split(/\r?\n/),
      header = lines.shift().split(','),
      requiredFields;

    if (this.fields) {
      // Nullify column headers that don't belong.
      header = _.map(header, h => this.fields[h] ? h : null);
      requiredFields = _.keys(_.pickBy(this.fields, f => f.required));
    }
    let parsed = [], failed = [], missingFields = {};

    _.each(lines, (line, index) => {
      if (!line.trim()) {
        return;
      }
      let newObj = {
        index: index
      };

      let processWord = (index, val) => {
        if (header[index]) {
          let field = this.fields[header[index]];

          if (field.type === 'yesNo') {
            val = val.toLowerCase() === 'yes';
          }
          newObj[header[index]] = _.isString(val) ? val.replace(/"/g, '') : val;
        }
      };

      // The inter-webs didn't have a reliable way to split a csv that allows quote escaping.
      // So I'm doing it the old fashion way.
      for (let i = 0, curWord = '', escaped = false, index = 0; i <= line.length; i++) {
        if (!escaped && (i === line.length || line[i] === ',')) {
          processWord(index, curWord);
          curWord = '';
          index++;
        } else if (line[i] === '"') {
          escaped = !escaped;
        } else {
          curWord += line[i];
        }

        if (i === line.length && escaped) {
          throw new Error('Invalid data. Unterminated quotes');
        }
      }

      let missing = requiredFields && _.find(requiredFields, requiredCol => !newObj[requiredCol]);

      if (missing) {
        missingFields[missing] = true;
        failed.push(newObj);
      } else {
        parsed.push(newObj);
      }
    });

    return {parsed, failed, missingFields: _.keys(missingFields)};
  }

  onFileSelect(file) {
    if (!file) {
      return;
    }

    this.loading = true;
    this.Upload.dataUrl(file)
      .then(this.Upload.urlToBlob)
      .then((url) => {
        return new Promise((resolve, reject) => {
          let r = new FileReader();

          r.onload = (e) => {
            {
              let parsedPromise;

              try {
                if (this.fileType === 'csv') {
                  let requiredFields = _.keys(_.pickBy(this.fields, f => f.required));

                  parsedPromise = csv({output: 'json'})
                      .fromString(e.target.result)
                      .then((jsonArray) => {
                        let missingFields = [];
                        let failed = [];
                        let parsed = [];
                        let mapped = _.map(jsonArray, (obj, i) =>
                            _.assign({index: i}, _.pick(obj, _.keys(this.fields))));

                        _.each(mapped, obj => {
                          if (_.some(requiredFields, f => !obj[f])) {
                            missingFields.push(obj);
                            failed.push(obj);
                          } else {
                            parsed.push(obj);
                          }
                        });

                        return {parsed, failed, missingFields};
                      });
                } else {
                  this.parsed = this.$q.when({parsed: angular.fromJson(e.target.result)});
                }

                return parsedPromise.then(({parsed, missingFields, failed}) => this.$timeout(() => {
                  this.parsed = parsed;
                  this.missingFields = missingFields;
                  this.failed = failed;
                  this.propertyNames = this.parsed.length ? _.keys(this.parsed[0]) :
                      this.failed ? _.keys(this.failed[0]) : [];
                })).then(() => resolve());
              } catch (err) {
                this.parseError = err;
                this.step = 'failedParse';
                reject(err);
              }
            }
          };

          r.readAsText(url);
        });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to parse file.'))
      .finally(() => {
        this.loading = false;
      });
  }

  ok() {
    this.continuing = true;
    this.$timeout(() => this.modalInstance.close(this.parsed));
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  chat() {
    this.drift.openSidebar();
  }
}

module.exports = Constructor;


