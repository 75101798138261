'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfOldRecords', {
    bindings: {
      records: '=',
      product: '<',
      company: '<'
    },
    template: require('./products.records.template.html'),
    controller: require('./products.records.controller.js'),
    controllerAs: 'vm'
  });
};