module.exports = function(ngModule) {
  ngModule.config(
    function($stateProvider) {
      'ngInject';

      $stateProvider.state('products.edit.reports', {
        url: '/reports',
        template: '<cf-product-reports user="$resolve.user" ' +
        '$product="$resolve.product"></cf-product-reports>',
        controllerAs: 'vm',
        data: {
          titleBar: {
            helpModal: require('../_views/modals/reports-help.view.html')
          }
        }
      });
    }
  );
};
