module.exports = function(ngModule) {
  ngModule.factory('ElasticSearchAdmin', function(ES_INDICES, $log, $q, $http, growl, fbutil, $firebaseObject, utils) {
    'ngInject';

    const allTypes = {
      ingredients: ['ingredient'],
      supplierIngredients: ['supplierIngredient'],
      suppliers: ['supplier'],
      organizations: ['organization'],
      products: ['product'],
      productGroups: ['productGroup'],
      verifications: ['verification'],
      notifications: ['notification'],
      //messages: ['messageTopic'],
      orders: ['order'],
      users: ['user'],
      rfqs: ['rfq'],
      sops: ['sop'],
      batches: ['batch'],
      sopLogs: ['sopLog'],
      hazards: ['hazard'],
      leads: ['lead'],
      equipment: ['equipment'],
      sopLibraryItems: ['sopLibraryItem'],
      recallEvents: ['recallEvent'],
      foodProducts: ['foodProduct'],
      checklists: ['checklist'],
      checklistTemplates: ['checklistTemplate'],
      batchLogs: ['batchLog']
    };

    function resetIndex(index, version) {
      return $http.post(`elasticsearch/indices/${index}/recreate?version=${version}`)
        .then(() => $log.log('Reset index complete: ' + index));
    }

    function activateIndex(index, version) {
      return $http.post(`elasticsearch/indices/${index}/activate?version=${version}`)
        .then(() => {
          $log.log('Activate index complete: ' + index);
        });
    }

    function remap(index, type, version) {
      return $http.post(`elasticsearch/indices/${index}/types/${type}/remap?version=${version}`)
        .then(() => {
          $log.log('Remap type complete: ' + index + ', ' + type);
        });
    }

    function reindex(index, type) {
      let url = 'elasticsearch/reindex';
      let queryParms = {
        index: index,
        type: type
      };

      return $http.post(url + utils.jsonToQueryString(queryParms));
    }

    function getIndexFromType(type) {
      let foundIndex = null;

      _.each(allTypes, (arrayOfTypes, indexName) => {
        if (_.indexOf(arrayOfTypes, type) !== -1) {
          foundIndex = indexName;
          return false;
        }
      });

      return foundIndex ? ES_INDICES[foundIndex] : null;
    }

    return {
      allTypes: function() {
        return allTypes;
      },
      noReindexTypes: ['hazard'], // Types to skip bulk reindexing (due to indexing via cloud functions)
      switchIndex: function(index) {
        return $firebaseObject(fbutil.ref('server/currentEsVersions')).$loaded($esVersions => {
          let indexesToReset = index ? [index] : _.keys(ES_INDICES);

          _.remove(indexesToReset, i => i === 'logs');
          // Switch between v1 and v2
          _.each(indexesToReset, i => {
            $esVersions[i] = $esVersions[i] === 1 ? 2 : 1;
          });

          $log.info('Switching Elastic Search index');
          return $q.all(_.map(indexesToReset, i => activateIndex(ES_INDICES[i], $esVersions[i])))
            .then(() => {
              return $esVersions.$save().finally(() => $esVersions.$destroy());
            })
            .then(() => {
              $log.info('Elastic Search index switch complete.');
              growl.success('Indexes switched');
            })
            .catch(err => utils.defaultErrorHandler(err, 'Unable to switch Elastic Search indexes'));
        });
      },
      reset: function(index) {
        return $firebaseObject(fbutil.ref('server/currentEsVersions')).$loaded($esVersions => {
          let indexesToReset = index ? [index] : _.keys(ES_INDICES);

          _.remove(indexesToReset, i => i === 'logs');
          // Switch between v1 and v2
          _.each(indexesToReset, i => {
            $esVersions[i] = $esVersions[i] === 1 ? 2 : 1;
          });

          const types = this.allTypes();
          let typesToReset = index ? _.pick(types, index) : types;

          $log.info('Beginning Elastic Search reindex');
          return $q.all(_.map(indexesToReset, i => resetIndex(ES_INDICES[i], $esVersions[i])))
            .then(() => $q.all(_.map(typesToReset, (types, i) =>
              $q.all(_.map(types, t => remap(ES_INDICES[i], t, $esVersions[i]))))))
            .then(() => $q.all(_.map(indexesToReset, i =>
              activateIndex(ES_INDICES[i], $esVersions[i]))))
            .then(() => $q.all(_.map(indexesToReset, i =>
              $q.all(_.map(types[i], t =>
                _.indexOf(this.noReindexTypes, t) === -1 && reindex(ES_INDICES[i], t)))
            )))
            .then(() =>
              $esVersions.$save().finally(() => $esVersions.$destroy()))
            .then(() => {
              $log.info('Elastic Search recreate/reindex complete.');
              growl.success('Indexes recreated and re-indexed');
            })
            .catch(err => utils.defaultErrorHandler(err, 'Unable to recreate Elastic Search indexes'));
        });
      },
      reindex: (type) => reindex(getIndexFromType(type), type)
        .then(() => {
          $log.info('Elastic Search reindex In Progress.');
          growl.success('Re Indexing In Progress');
        })
        .catch(err => utils.defaultErrorHandler(err, 'Unable to reindex Elastic Search indexes'))
    };
  });
};
