class Controller {
  constructor(constantsService,$stateParams,controlsService) {
    'ngInject';
    this.constantsService = constantsService;
    this.$stateParams = $stateParams;
    this.controlsService = controlsService;
  }

  $onInit() {
    this.constantsService.get('logSheets').then(sheets => {
      this.logSheets = sheets;
    });
    this.controlsService.syncControls(this.planSops, this.product);
  }

  openControl(id) {
    if (this.$stateParams.controlId !== id) {
      this.$stateParams.activeView = 'controls';
      this.onEditControl({controlId: id});
    }
  }
}

module.exports = Controller;
