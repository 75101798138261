class Constructor {
  constructor(CF_ORDER_ITEM_STATUS, organizations, messageServices, orders, confirmModal, $state) {
    'ngInject';

    this.CF_ORDER_ITEM_STATUS = CF_ORDER_ITEM_STATUS;
    this.organizations = organizations;
    this.messageServices = messageServices;
    this.ordersService = orders;
    this.confirmModal = confirmModal;
    this.$state = $state;
  }

  $onInit() {
    this.providerNames = {};
    this.productItems = [];
    _.each(this.productOrders, order => {
      _.each(order.items, item => {
        if (_.get(item, 'product.key') === this.product.$id) {
          this.productItems.push(_.assign({order: order}, item));
          if (_.isUndefined(this.providerNames[item.service.key])) {
            this.providerNames[item.service.key] = '';
            this.organizations.getName(item.providerOrgId).then(name => {
              this.providerNames[item.service.key] = name;
            });
          }
        }
      });
    });
  }

  cancelItem(item) {
    this.confirmModal({
      title: 'Cancel Order?',
      body: 'Are you sure you want to cancel this order?',
      okText: 'Cancel Order',
      cancelText: 'Do NOT Cancel'
    })
      .then(() => {
        this.ordersService.cancelItem(item.order.$id, item.id)
          .then(() => {
            item.status = this.CF_ORDER_ITEM_STATUS.CANCELLED;
          })
          .catch(err => {
            this.$log.error('An error cancelling the order item.', err);
            this.growl.error('Unable to cancel the order item. Please contact customer support.');
          });
      })
      .catch(() => angular.noop);
  }

  message(otherOrgId) {
    this.messageServices.getOrgToOrgTopic(this.user, otherOrgId).then(topicId => {
      this.$state.go('user.messages.message', {topicId: topicId});
    });
  }
}

module.exports = Constructor;
