module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider.state('verification', {
      url: '/verification',
      template: '<div class="col-md-12 text-center">' +
                  '<h3><i class="fas fa-sync fa-spin fa-fw"></i> Redirecting...</h3>' +
                '</div>',
    });
  }).run((menus, authorization, orgPerspectives) => {
    'ngInject';

    menus.addMenuItem('dashboard', {
      title: 'Verification',
      redirectPath: '/verification',
      position: 9,
      isPublic: false,
      claims: [authorization.claims.VERIFICATION_WRITE],
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP]
    });
  });
};
