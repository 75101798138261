module.exports = function(ngModule) {
  ngModule.component('cfRequestForQuotes', {
    bindings: {
      user: '<',
      organization: '<'
    },
    template: require('./marketplace.request-for-quotes.template.html'),
    controller: require('./marketplace.request-for-quotes.controller.js'),
    controllerAs: 'vm'
  });
};
