module.exports = function(productSearch, growl) {
  'ngInject';

  const vm = this;
  vm.productSearchService = productSearch;

  vm.$onInit = function() {
    vm.foodProduct = vm.resolve.foodProduct;
    vm.company = vm.resolve.company;
    vm.user = vm.resolve.user;
    vm.showProductList = vm.resolve.showProductList;
    vm.productSearch = new vm.productSearchService.ProductSearch(vm.user, [vm.user.orgContext.id]);
    vm.productSearch.searchFields(['brandName']);
    vm.productSearch.search().then(results => {
      vm.selectedProduct = _.first(results);
    });
    // if (!vm.foodProduct.externalNumber) {
    //   vm.foodProduct.externalNumber = vm.genFoodProductNumber();
    //   if (vm.foodProduct.$value !== null) { vm.foodProduct.$save().then(() => growl.success('Food Product Number updated')) }
    // }
  };

  vm.cancel = function() {
    vm.modalInstance.dismiss('cancel');
  };

  vm.genFoodProductNumber = function() {
    const today = new Date();
    const todayTime = today.getHours() + '' + today.getMinutes() +''+ today.getSeconds();

    return `${vm.user.uid.substring(0, 4).toUpperCase()}${Math.floor(parseInt(todayTime))}`;
  }

  vm.save = function() {
    vm.saving = true;
    vm.foodProduct.description = vm.foodProduct.description || null;
    return vm.foodProduct.$save()
      .then(() => vm.modalInstance.close({foodProduct: vm.foodProduct, product: vm.selectedProduct}))
      .catch((err) => vm.modalInstance.dismiss(err));
  };
};
