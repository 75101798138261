module.exports = function(ngModule) {
  ngModule.factory('SopLogsSearch', function($http, $q, utils) {
    'ngInject';

    const DEFAULT_PAGE_SIZE = 30;

    class SopLogsSearch {
      constructor(orgId) {
        this.baseUri = 'sopLogs';
        this.orgId = orgId;
        this.maxReached = false;
        this.pageSize = DEFAULT_PAGE_SIZE;
      }

      /**
       * Sort by a property on the procedure entry
       * @param {string} sortBy Sort field
       * @param {string} sortDirection Sort direction
       * @returns {SopSearch} The SopSearch object
       */
      sortBy(sortBy, sortDirection = 'asc') {
        this.sortByValue = sortBy;
        this.sortDirection = sortDirection;

        return this;
      }

      /**
       * Set the max page size of the result set
       * @param {number} size Max page size
       * @returns {SopSearch} The SopSearch object
       */
      size(size) {
        this.pageSize = size;

        return this;
      }

      /**
       * Start from index
       * @param {number} index Max page size
       * @returns {SopSearch} The SopSearch object
       */
      startFrom(index) {
        this.from = index;

        return this;
      }

      /**
       * Only logs from this batch
       * @param {number} batchId The batch identifier
       * @returns {SopSearch} The SopSearch object
       */
      batchId(batchId) {
        this.batchIdFilter = batchId;

        return this;
      }

      /**
       * No logs with a batch number
       * @returns {SopSearch} The SopSearch object
       */
      noBatches() {
        this.noBatchFilter = true;

        return this;
      }

      /**
       * Only logs from this SOP
       * @param {number} sopId The SOP identifier
       * @returns {SopSearch} The SopSearch object
       */
      sopId(sopId) {
        this.sopIdFilter = sopId;

        return this;
      }

      /**
       * Set the search text (currently will try to match key name)
       * @param {string=} text The search text
       * @returns {Promise} The SopSearch object
       */
      search(text = '') {
        this.from = 0;
        this.maxReached = false;
        this.searching = true;
        this.badSearch = false;

        return this._search(text)
          .finally(() => { this.searching = false; });
      }

      getMore(text) {
        if (this.badSearch) { throw new Error('Failed search...cannot get more'); }
        if (this.maxReached || this.searching) { return; }
        this.from += this.pageSize;

        return this._search(text);
      }

      $loaded() {
        return this.httpPromise;
      }

      _search(text) {
        let queryParmArray = [];

        queryParmArray.push(`orgId=${this.orgId}`);
        if (this.batchIdFilter) { queryParmArray.push(`batchId=${this.batchIdFilter}`); }
        if (this.noBatchFilter) { queryParmArray.push('noBatches=true'); }
        if (this.sopIdFilter) { queryParmArray.push(`sopId=${this.sopIdFilter}`); }
        if (!_.isUndefined(this.from)) { queryParmArray.push(`from=${this.from}`); }
        if (this.pageSize) { queryParmArray.push(`size=${this.pageSize}`); }
        if (this.sortByValue) { queryParmArray.push(`sortBy=${this.sortByValue}`); }
        if (this.sortDirection) { queryParmArray.push(`sortDirection=${this.sortDirection}`); }

        if (text) { queryParmArray.push(`searchText=${text}`); }

        this.searching = true;
        this.httpPromise = $http.get(`${this.baseUri}${utils.toQueryParm(queryParmArray)}`)
          .then(result => {
            if (result.status === 204) {
              this.maxReached = true;
              return [];
            }

            let recs = result.data.sopLogs;

            if (recs.length < this.pageSize) {
              this.maxReached = true;
            }

            return recs;
          })
          .catch(err => {
            this.badSearch = true;

            return $q.reject(err);
          })
          .finally(() => this.searching = false);

        return this.httpPromise;
      }
    }

    return SopLogsSearch;
  });
};
