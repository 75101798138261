module.exports = function (
  $state,
  $log,
  signup,
  growl,
  CompanyNameSearch,
  organizations,
  $timeout,
  utils,
  authorization,
  orgInteractionService,
  $q,
  constantsService,
  $uibModal
) {
  'ngInject';

  const vm = this,
    MAX_RECS = 300;

  function setCompany(id, name) {
    vm.chosenCompany = id;
    vm.chosenCompanyName = name;

    organizations.getRoles(vm.chosenCompany).then((roles) => {
      $timeout(() => {
        vm.roles = _.pickBy(roles, (r) => r.visible);
      });
    });
  }

  vm.$onInit = function () {
    vm.user = vm.resolve.user;

    vm.$state = $state;
    vm.roles = {};

    vm.isCfAdmin = vm.user.hasPermission(authorization.claims.CF_ADMIN);
    if (vm.isCfAdmin) {
      vm.companyNameSearch = new CompanyNameSearch(vm.user, false, {size: MAX_RECS});
      vm.search();
    } else {
      setCompany(vm.resolve.user.currentOrgContext().id, vm.resolve.user.currentOrgContext().companyName);
    }
    constantsService.get('userRoles').then((userRoles) => vm.userRoles = userRoles);
  };

  vm.createUser = function () {
    vm.sending = true;
    const contact = {
      contactEmail: vm.email,
      contactName: vm.name,
      inviteNotes: null,
    };
    let newOrgPromise = vm.transfer ?
      this.organizations.$push().then(($newOrg) => {
        $newOrg.companyName = vm.newOrgName;
        $newOrg.subscriptionId = this.user.subscription.id;
        $newOrg.members = {};
        $newOrg.members[this.user.uid] = true;
        $newOrg.createdOn = new Date().getTime();
        $newOrg.createdBy = this.user.uid;

        return $newOrg.$save();
      }) :
      null;

    return $q
      .when(newOrgPromise)
      .then(() => orgInteractionService.inviteUser(vm.chosenCompany, contact, vm.orgRole, {}))
      .then(() => this.modalInstance.close({contact: contact, companyName: vm.chosenCompanyName}))
      .catch((err) => utils.defaultErrorHandler(err, 'Unable to send invitation.'))
      .finally(() => {
        this.sending = false;
      });
  };

  vm.chooseCompany = function (company) {
    setCompany(company.$id, company.companyName);
  };

  vm.search = function (searchText) {
    vm.loading = vm.searching = true;
    return vm.companyNameSearch
      .search(searchText)
      .then((results) => {
        if (!vm.resolve.currentOrgIds) {
          vm.companyResults = results;
          return;
        }

        vm.companyResults = _.filter(results, (org) => {
          return !vm.resolve.currentOrgIds[org.payload.id];
        });
      })
      .catch(function (err) {
        growl.error('An error occurred searching company names.');
        $log.error(err);
      })
      .finally(() => {
        vm.loading = vm.searching = false;
        vm.maxReached = vm.companyNameSearch.searchResults.length >= MAX_RECS;
      });
  };

  vm.getMore = function () {
    vm.loading = true;
    vm.companyNameSearch
      .getMore()
      .then(() => {
        vm.companyResults = vm.companyNameSearch.searchResults;
      })
      .finally(() => {
        vm.maxReached = vm.companyNameSearch.searchResults.length >= MAX_RECS;
        vm.loading = false;
      });
  };

  vm.reset = function () {
    vm.chosenCompany = null;
    vm.orgRole = null;
  };

  vm.showRoles = function () {
    let roleRows = '';

    vm.userRoles.forEach(userRole => {
      roleRows += `<tr><td class="text-left">${userRole.features}</td>
      <td>${userRole.facilityAdmin}</td>
      <td>${userRole.supervisor }</td>
      <td>${userRole.viewer }</td>
      <td>${userRole.qa }</td>
      <td>${userRole.operations}</td>
      <td>${userRole.billing}</td></tr>`;
    });
    return $uibModal.open({
      animation: true,
      template: `<style>.modal-content{background-color: #ffff; border: none;}
                   .modal-backdrop.in{opacity: 0.8;}
                   </style><div class="row"><div class="col-11">
                   <h4 class="m-2 text-center">User Access Roles Table</h4></div>
                   <div class="col-1"><h2 type="button" class="text-dark mb-0 text-center" ng-click="$close(true)">&times;</h2></div></div>
                   <div class="mx-3"><table class="table text-center table-striped">
                   <thead><tr><th class="text-left">Roles/Features</th><th>Facility Admin</th><th>Manager/Supervisor</th><th>Viewer</th><th>QA</th><th>Operations</th><th>Billing</th></tr></thead>
                   <tbody>${roleRows}</tbody></table></div>`,
      size: 'xl',
    });
  };
};
