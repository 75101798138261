'use strict';

module.exports = function($log, organizations, labTestStatus, notifications, order) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    vm.teams = {};
    vm.verification = vm.resolve.verification;
    vm.companyName = vm.resolve.companyName;
    vm.brandName = vm.resolve.brandName;
    vm.title = vm.resolve.title;
    vm.user = vm.resolve.user;
    vm.step = 'selectBid';

    _.each(vm.verification.bids, (bid) => {
      organizations.getMiniProfile(bid.teamId)
        .then((teamProfile) => {
          vm.teams[bid.teamId] = teamProfile;
        });
    });
  };

  vm.submitOrder = function(newOrder, payment) {
    const bid = vm.verification.bids[vm.selectedBidId];

    order.submit(newOrder, payment, vm.user, vm.verification.organizationId)
      .then((processedOrder) => {
        vm.verification.selectedBidId = vm.selectedBidId;
        vm.verification.bidSelectedDate = firebase.database.ServerValue.TIMESTAMP;
        vm.verification.laboratoryId = bid.teamId;
        vm.verification.labTestStatusId = labTestStatus.TEST_IN_PROGRESS.id;
        vm.verification.order = {id: processedOrder.$id, itemId: processedOrder.items[0].$id};
      })
      .then(() => vm.verification.$save())
      .then(() => vm.step = 'orderSubmitted')
      .catch((error) => vm.paymentError = error);

    notifications.postToOrg({
      from: vm.user.uid,
      to: vm.verification.bids[vm.selectedBidId].teamId,
      message: 'Bid accepted by <i>' + vm.companyName + '</i> for <b>' + vm.brandName + '</b>.',
      link: {state: 'analysis.lab.tests'}
    });
  };

  vm.onPaymentBack = function() {
    vm.step = 'selectBid';
  };

  vm.onPaymentCancel = function() {
    vm.modalInstance.dismiss('cancel');
  };

  vm.acceptBid = function(bidId) {
    vm.order = {
      teamId: vm.verification.bids[bidId].teamId,
      items: [{
        title: 'Verification Lab Test',
        amount: {
          standard: vm.verification.bids[bidId].labRate,
          rush: vm.verification.bids[bidId].labRushRate
        },
        verificationId: vm.verification.$id,
        bidId: bidId,
        comments: 'Lab test from some partner.'
      }]
    };

    vm.step = 'submitOrder';
  };

  vm.cancel = function() {
    vm.modalInstance.dismiss('cancel');
  };
};
