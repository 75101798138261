module.exports = function(ngModule) {
  ngModule.component('frListLogs', {
    bindings: {
      user: '<'
    },
    template: require('./administration.list-logs.template.html'),
    controller: require('./administration.list-logs.controller'),
    controllerAs: 'vm'
  });
};
