module.exports = function(ngModule) {
  ngModule.component('cfSignin', {
    bindings: {
      reason: '<',
      tokenData: '<',
      profileType: '<'
    },
    template: require('./auth.signin.template.html'),
    controller: require('./auth.signin.controller.js'),
    controllerAs: 'vm'
  });

  require('./signin_form')(ngModule);
};
