class Constructor {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.options = this.resolve.options;
    this.title = this.resolve.title;
    this.message = this.resolve.message;
    let defaultObj = _.find(this.options, o => o.default);

    this.result = defaultObj ? defaultObj.value : null;
  }
}

module.exports = Constructor;
