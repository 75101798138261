class Constructor {
  constructor(supplierIngredientService, $q, supplierService, authorization, constantsService, $state,
    confirmDeleteModal, utils, growl, moment) {
    'ngInject';

    this.supplierIngredientService = supplierIngredientService;
    this.$q = $q;
    this.supplierService = supplierService;
    this.authorization = authorization;
    this.constantsService = constantsService;
    this.$state = $state;
    this.confirmDeleteModal = confirmDeleteModal;
    this.utils = utils;
    this.growl = growl;
    this.moment = moment;
  }

  $onInit() {
    this.isPremium = !this.user.onPayAsGoPlan();
    this.filesNeeded = _.get(this.company, 'supplierTracking.fileCategories');
    this.isTracking = !!this.filesNeeded;
    this.filesNeeded = this.filesNeeded && _.map(this.filesNeeded.split(','), _.toNumber);
    this.hasProductAccess = {};
    this.missingFilesMap = {};

    this.$q.when(this.filesNeeded ? this.getProductAccess() : null)
      .then(() => this.constantsService.get('fileTypes'))
      .then((fileTypes) => this.missingFiles(_.omitBy(fileTypes, fc => fc.companyLevel)));
  }

  missingFiles(externalFileTypes) {
    const currentDate = this.moment().valueOf();
    const nextMonth = this.moment(currentDate).add(1, 'M').valueOf();

    return this.$q.all(_.map(this.ingredients, rec => {
      if (!rec.organizationId || this.missingFilesMap[rec.$id]) { return; }  // Some old recs don't have an orgId
      return this.$q.all({
        files: this.supplierIngredientService.getFiles(rec.organizationId, rec.supplierId, rec.$id,
          !rec.productId || !this.hasProductAccess[rec.productId]),
        outstandingRequests: this.supplierService.getRequestFilesTokens(rec.supplierId),
      }).then(result => {
        let numFound = 0;
        let markup = _.reduce(this.filesNeeded, (curMarkup, fileCat) => {
          let found = _.find(result.files, f => f.category === fileCat);
          let entry = _.find(externalFileTypes, f => f.id === fileCat);
          let name = entry ? entry.text : 'File Name Not Found';
          let message = '<span class="text-danger">Missing</span>';

          if(found) {
            if (currentDate < Number(found.expirationDate) &&  Number(found.expirationDate) < nextMonth) {
              message = '<span class="text-warning">Expired Soon</span>';
            }else if(Number(found.expirationDate) < currentDate) {
              message = '<span class="text-danger">Expired</span>';
            }else {
              message = '<span class="text-success">Valid</span>';
            }
          }
          if (found) { numFound++; }
          return `${curMarkup}<li>${name} ${found ? `- ${found.name}` : '' } - ${message}</li>`;
        }, '<ol>') + '</ol>';

        this.missingFilesMap[rec.$id] = {
          text: `${numFound}/${this.filesNeeded.length}`,
          markup: markup,
          allFound: numFound === this.filesNeeded.length,
          requestOutstanding: _.map(result.outstandingRequests, (token, id) => _.assign(token, {id})),
        };
      });
    }));
  }

  getProductAccess() {
    return this.$q.all(_.map(this.ingredients, (supIng) => {
      if (!supIng.productId || this.hasProductAccess[supIng.productId]) { return; }
      return this.authorization.hasOrgLevelProductException(this.user, supIng.productOrganizationId,
        supIng.productId, 'filesRead')
        .then(hasAccess => {
          this.hasProductAccess[supIng.productId] = hasAccess;
        });
    }));
  }

  editSupIng(ingredientId, supIngId) {
    this.$state.go('ingredients.ingredient.supplierIngredient.overview',
      {supplierIngredientId: supIngId, ingredientId: ingredientId});
  }

  removeSupplierIngredient(supplierIngredientId, supplierIngredient) {
    this.confirmDeleteModal(supplierIngredient.brandName).then(() => {
      this.supplierIngredientService.remove(this.user, supplierIngredientId)
        .then(() => {
          _.remove(this.ingredients, ing => ing.$id === supplierIngredientId);
          this.growl.success('Ingredient deleted.');
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'Could not delete ingredient.'));
    });
  }

}

module.exports = Constructor;
