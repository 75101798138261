class Controller {
  constructor(utils, constantsService, $stateParams, products, $uibModal, users, growl, $q, urlTokens, $log) {
    'ngInject';

    this.utils = utils;
    this.constantsService = constantsService;
    this.$stateParams = $stateParams;
    this.products = products;
    this.$uibModal = $uibModal;
    this.users = users;
    this.growl = growl;
    this.$q = $q;
    this.urlTokens = urlTokens;
    this.$log = $log;

    this.portalHelp = 'Create File Portals if you need files from a client. This will create a unique ' +
      'portal for a customer to upload files. Below is a list of all outstanding File Upload Portals ' +
      'created by your organization that are awaiting client submission.';
  }

  $onInit() {
    this.remoteOrgDirectory = 'organizations/' + this.clientProfile.$id + '/externalFiles';

    this.clientProfile.$id = this.clientProfile.id;
    this.loadingFiles = true;
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.organizationCategories = _.pickBy(fileTypes, fc => fc.companyLevel);
      this.fileTypes = fileTypes;
      this.productCategories = _.omitBy(fileTypes, fc => fc.companyLevel);
      this.getFiles(this.$portals, fileTypes);
    });

    this.backText = this.$stateParams.returnState === 'marketplace.requestForQuote.list' ? 'back to quotes' :
      'back to orders';
    this.backState = {name: this.$stateParams.returnState || 'marketplace.orders.list'};
    if (this.$stateParams.productId) {
      this.remoteProdDirectory = 'products/' + this.$stateParams.productId + '/externalFiles';
      this.products.getBrandName(this.$stateParams.productId).then(name => {
        this.productName = name;
      });
    }

    this.activePortals = _.filter(this.$portals, p => p.status === 'active');
    this.$portals.$watch(() => {
      this.activePortals = _.filter(this.$portals, p => p.status === 'active');
      this.getFiles(this.$portals, this.fileTypes);
    });
  }

  requestFiles() {
    return this.$uibModal.open({
      component: 'cfRequestFiles',
      size: 'lg',
      resolve: {
        user: () => this.user,
        contacts: () => this.users.getClientProfile(this.$stateParams.marketplacePocUid)
          .then(profile => profile ? [{
            uid: this.$stateParams.marketplacePocUid,
            name: profile.lastName ? profile.firstName + ' ' + profile.lastName : 'No name',
            email: profile.email
          }] : [])
          .catch(() => []),
        requesteeOrg: () => this.clientProfile.id,
        productId: () => this.$stateParams.productId
      }
    }).result.then(() => this.growl.success('Request for files successfully sent!'))
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred requesting files.'));
  }

  getFiles(portals, categories) {
    this.markup = {};
    this.$q.all(_.map(portals, (portal) => {
      if (portal.status === 'closed') { return; }
      return this.urlTokens.getFiles(portal.$id).then(files => {

        this.markup[portal.$id] = {
          count: _.get(files, 'product.files.length', 0) + _.get(files, 'facility.files.length', 0)
        };

        this.markup[portal.$id].markup = _.reduce([files.facility, files.product], (curMarkup, type) => {
          curMarkup += '<div class="g-mb-10">';
          curMarkup += `<h5>${type.productName || 'Facility'}</h5>`;
          curMarkup += _.reduce(type.files, (curSubMarkup, fileCatId) => {
            let fileCat = _.find(categories, cat => cat.id === fileCatId);

            curSubMarkup += `<li>${fileCat ? fileCat.text : 'Unknown file type'}</li>`;
            return curSubMarkup;
          }, '<ol>') + '</ol>';
          curMarkup += '</div>';
          return curMarkup;
        }, '<div>') + '</div>';
      });
    })).catch(err => {
      this.loadingFilesFailed = true;
      this.$log.error('Unable to load files.', this.$log.toString(err));
    }).finally(() => { this.loadingFiles = false; });
  }

  cancelPortal(portalId) {
    this.urlTokens.cancelPortal(portalId).then(() => {
      this.growl.success('Portal cancelled');
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to cancel portal.'));
  }

  resendEmail(portalId) {
    this.urlTokens.resendPortalEmail(portalId).then(() => {
      this.growl.success('Portal email resent.');
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to resend portal email.'));

  }
}

module.exports = Controller;
