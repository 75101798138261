require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsReason', {
    bindings: {
      $event: '=',
      company: '=',
      user: '<'
    },
    template: require('./recall.events.reason.template.html'),
    controller: require('./recall.events.reason.controller'),
    controllerAs: 'vm'
  });
};
