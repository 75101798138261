class Controller {
  constructor($stateParams, $uibModal, $state, productStatus, growl, products, $window, $http,
    confirmDeleteModal, $log, $firebaseObject, fbutil, utils, $q, SAMPLE_PRODUCT_ID, subscriptionService,
    productGroups, authorization, orgPerspectives, $timeout, miniTourService, confirmModal,
    SAMPLE_ORGANIZATION_ID, reportsService, constantsService, productCategories, hazardsService, cfpLoadingBar) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.productStatus = productStatus;
    this.growl = growl;
    this.products = products;
    this.$window = $window;
    this.$http = $http;
    this.confirmDeleteModal = confirmDeleteModal;
    this.$log = $log;
    this.$firebaseObject = $firebaseObject;
    this.fbutil = fbutil;
    this.utils = utils;
    this.$q = $q;
    this.sampleProductId = SAMPLE_PRODUCT_ID;
    this.subscriptionService = subscriptionService;
    this.productGroups = productGroups;
    this.authorization = authorization;
    this.orgPerspectives = orgPerspectives;
    this.$timeout = $timeout;
    this.miniTourService = miniTourService;
    this.confirmModal = confirmModal;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
    this.reportsService = reportsService;
    this.constantsService = constantsService;
    this.productCategories = productCategories;
    this.hazardsService = hazardsService;
    this.searching = true;
    this.cfpLoadingBar = cfpLoadingBar;
  }

  $onInit() {
    this.groups = {};
    this.templateNames = {};
    this.productId = this.$stateParams.productId;
    this.facilityId = this.$stateParams.facilityId;
    this.isCfAdmin = this.user.isCfAdmin();
    this.isPartner = this.user.isPartner();
    this.canUnlock = this.isCfAdmin || this.user.isPartner();
    this.onPrimaryOrg = this.user.onPrimaryOrg();
    this.fullPlanAccess = this.isCfAdmin || !this.user.onPayAsGoPlan();
    this.restrictTemplates = this.user.doesSubscriptionPermit('haccpTemplates') !== false;
    this.miniTourService.setShown(this.user, 'companyProduct');
    this.search = _.debounce(this._search, 300);
    const stripeProductId = this.user.subscription.plan.product;

    this.planConstraint = _.get(this.productConstraints, stripeProductId + '.PLANS');
    if (_.isUndefined(this.planConstraint)) {
      this.planConstraint = -1; // Force check off
    }

    let getSamplePromise = this.user.showSample && this.fbutil.ref('products', this.sampleProductId).once('value')
      .then(sampleSnap => {
        if (sampleSnap.exists()) {
          this.sample = sampleSnap.val();
          this.sample.$id = sampleSnap.key;
        }
      });

    this.$q.when(getSamplePromise).then(() => this.search())
      .then(results => {
        this.noProducts = !results;
      });
  }

  $onDestroy() {
    delete this.productSearch.searchResults;
  };

  _search() {
    let body = this.productSearch.getSearchQuery();

    delete body.query.bool.should;
    delete body.query.bool['minimum_should_match'];

    if (this.productSearch.searchText) {
      body.query.bool.should = [
        {
          'multi_match': {
            query: this.productSearch.searchText.toLowerCase(),
            type: 'phrase_prefix',
            fields: ['brandName', 'description', 'generic_name', 'groupName'],
            slop: 3,            // Allow terms to appear out of order by this many positions
            'max_expansions': 20  // To improve performance, limit results of the final search term to this many
          }
        },
        {
          term: {
            externalId: this.productSearch.searchText
          }
        }
      ];
      body.query.bool['minimum_should_match'] = 1;
    }

    body.sort = [{lastView: {order: 'desc'}}];

    this.productSearch.setSearchQuery(body);
    this.searching = this.loading = true;
    return this.productSearch.search()
      .then(results => {
        if (!this.productSearch.searchText) {
          if (results && results.length === 1 && results[0].sampleFromTemplate) {
            this.$timeout(() => this.miniTourService.enqueueTour(this.user, {
              id: 'templateSample',
              selector: '.list-product-view .template-sample',
              placement: 'bottom-right',
              title: 'Sample Food Safety Plan',
              contentHtml: 'We created a sample food safety plan from ' +
                `a <b>${results[0].sampleFromTemplate}</b> template. Use it as a starting point for your ` +
                `own <b>${results[0].sampleFromTemplate}</b> food safety plan, or start your own ` +
                'by clicking "Add Plan".'
            }), 400);
          } else if (this.sample) {
            this.productSearch.searchResults = this.productSearch.searchResults || [];
            this.productSearch.searchResults.unshift(this.sample);

            this.$timeout(() => this.miniTourService.enqueueTour(this.user, {
              id: 'productSample',
              selector: '.list-product-view .sample-product',
              placement: 'bottom-right',
              title: 'Visit the Sample Food Safety Plan',
              contentHtml: 'We added a sample <b>Peanut Butter</b> food safety plan to give you an idea how to use ' +
                'our Food Safety Plan Generator. You can hide it anytime by choosing <i>Hide Sample Product</i> ' +
                'in the menu to the right.'
            }), 400);
          }
        }

        if (this.isCfAdmin) {
          _.each(results, (product) => {
            if (product.fromTemplate && !this.templateNames[product]) {
              this.templateNames[product.fromTemplate] = '';
              this.products.getTemplateName(product.fromTemplateOrgId, product.fromTemplate).then(name => {
                this.templateNames[product.fromTemplate] = name;
              });
            }
          });
        }

        return results;
      })
      .catch(err => {
        this.$log.error(err);
        this.growl.error('Error occurred during search.');
      })
      .finally(() => {
        this.maxReached = this.productSearch.maxReached();
        this.searching = this.loading = false;
      });
  }

  checkConstraint() {
    if (this.planConstraint === -1) { return this.$q.resolve(); }
    return this.products.getCount(this.user.orgContext.id).then(count => {
      if (this.planConstraint > count) { return this.$q.resolve(); }

      return this.subscriptionService.promptForUpgrade(this.user,
        `Greater than ${this.planConstraint} Food Safety Plans`,
        this.constantsService.get('stripe').then(c => c.stripeProducts.OLD_PREMIUM));
    });
  }

  addProduct() {
    this.$uibModal.open({
      component: 'cfCreateProduct',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        foodCategories: this.productCategories.getAll(),
        hazardsLibrary: this.hazardsService.getHazardLibrary(),
        noProducts: () => this.noProducts
      }
    }).result
      .then((productId) => {
        this.noProducts = false;
        this.$state.go('products.edit', {productId});
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Could not save product.'));
  };

  addProductFromTemplate() {
    this.$uibModal.open({
      component: 'cfCreateProductFromTemplate',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        foodCategories: this.productCategories.getAll()
      }
    }).result
      .then((productId) => {
        this.noProducts = false;
        this.$state.go('products.edit', {productId});
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Could not save product.'));
  };

  removeProduct(index, product) {
    this.selectedItem = product;

    if (product.isSampleProduct) {
      this.user.hidePeanutButterSample();
      this.productSearch.searchResults.splice(index, 1);

      return;
    }

    this.confirmDeleteModal(product.brandName)
      .then(() => this.products.delete({product, user: this.user}))
      .then(() => {
        this.productSearch.searchResults.splice(index, 1);
        this.growl.success('Plan deleted.');
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.growl.error('Could not delete plan.');
      });
  };

  loadTemplate(product) {
    return this.$uibModal.open({
      component: 'cfSelectPlanTemplateModal',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        showWarning: () => false,
        recommendedTemplate: () => product.category ?
          this.products.getTemplatesFromCategory(product.category) : null,
        orgTemplates: () => this.products.getPlanTemplates(this.user.organizationId),
        cfTemplates: () => this.products.getPlanTemplates(this.SAMPLE_ORGANIZATION_ID)
      }
    }).result
      .then((template) => this.products.loadTemplate(product, template.organizationId, template.$id,
        {hazards: template.options.hazards,
          ingredients: template.options.ingredients,
          equipment: template.options.equipment,
          planAnalysis: template.options.planAnalysis}))
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.$log.error('Error while saving process flow as template.', this.$log.toString(reason));
        this.growl.error('Could not save process flow as a template.');
      });
  }

  getClass(status) {
    let label = this.productStatus(status);

    this.status = label.status;
    this.label_status = label.label_status;

    return this.label_status;
  };

  searchKeyPress($event) {
    if (this.utils.isBenignKeyUp($event.keyCode)) { return; }

    if ($event.keyCode === 13) {
      if (!this.productSearch.searchText || this.productSearch.searchText.length <= 3) {
        this.search();
      }

      this.search.flush();

      return;
    }

    if (this.productSearch.searchText && this.productSearch.searchText.length <= 3) { return; }

    this.search();
  };

  getMore() {
    this.loading = true;
    this.$q.when(this.productSearch.getMore())
      .finally(() => {
        this.maxReached = this.productSearch.maxReached();
        this.loading = false;
      });
  };

  fetchFullReport(product) {
    const reportType = product.planType === 'haccpPlan' ? 'haccpFull' : 'preventiveControlsFull';

    this.products.$get(product.$id)
      .then($product => this.reportsService.fetchPlanReport(this.user, $product, reportType)
        .then(() => $product.$destroy()))
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred opening the report'));
  }

  editGroup(groupId) {
    return this.$uibModal.open({
      component: 'cfEditProductGroup',
      backdrop: 'static',
      resolve: {
        group: () => this.productGroups.$get(groupId)
      }
    }).result
      .then((result) => {
        _.each(result.productChanges, (changedRec) => {
          let changedProduct = _.find(this.productSearch.searchResults, {$id: changedRec.productId});

          if (changedProduct) {
            changedProduct.groupName = changedRec.groupName;
          }
        });
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) { return; }

        this.growl.error(reason);
      });
  };

  saveAsTemplate(product) {
    this.cfpLoadingBar.start();
    return this.products.$get(product.$id)
      .then($product => {
        this.cfpLoadingBar.complete();
        return this.$uibModal.open({
          component: 'cfSavePlanAsTemplateModal',
          backdrop: 'static',
          resolve: {
            user: () => this.user,
            product: () => $product,
            planTemplates: () => this.products.getPlanTemplates($product.organizationId),
            defaultTemplate: () => $product.savedToTemplate
          }
        }).result
          .then((template) => {
            $product.savedToTemplate = template.$id;
            return $product.$save();
          });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Error while saving process flow as template.'))
      .finally(() => this.cfpLoadingBar.complete());
  }

  unlock(product) {
    return this.confirmModal({
      title: 'Unlock Plan',
      okText: 'Unlock Plan',
      cancelText: 'Cancel',
      body: `Are you sure you want to unlock ${product.brandName}?`
    }).then(() => this.products.unlock(product.$id, this.user.uid)).then(() => {
      product.unlockedBy = this.user.uid;
      this.growl.success('Plan unlocked');
      this.$log.info(`Plan ${product.brandName} unlocked by ${this.user.uid}`);
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to unlock plan'));
  }

  lock(product) {
    return this.confirmModal({
      title: 'Lock Plan',
      okText: 'Lock Plan',
      cancelText: 'Cancel',
      body: `Are you sure you want to lock ${product.brandName}?`
    }).then(() => this.products.lock(product.$id, this.user.uid)).then(() => {
      product.unlockedBy = null;
      this.growl.success('Plan locked');
      this.$log.info(`Plan ${product.brandName} locked by ${this.user.uid}`);
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to lock plan'));
  }
}

module.exports = Controller;
