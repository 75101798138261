class Constructor {
  constructor($log, growl, $window, order, organizations, billing, $uibModal,
    cfpLoadingBar, confirmModal, subscriptionService, utils,
    products, supplierService, $q) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.$window = $window;
    this.orderService = order;
    this.organizations = organizations;
    this.billing = billing;
    this.cfpLoadingBar = cfpLoadingBar;
    this.confirmModal = confirmModal;
    this.subscriptionService = subscriptionService;
    this.utils = utils;
    this.products = products;
    this.supplierService = supplierService;
    this.$uibModal = $uibModal;
    this.$q = $q;

  }

  $onInit() {
    this.user = this.resolve.user;
    this.product = this.resolve.product;
    this.stripeConstants = this.resolve.stripeConstants;
    this.stripeProducts = this.stripeConstants.stripeProducts;
    this.productConstraints = this.stripeConstants.constraints;
    this.subscriptionPlans = this.stripeConstants.subscriptionPlans;

    this.step = 'choosePlan';
    this.payPeriod = 'monthly';
    this.numPlansPromise = this.products.getCount(this.user.orgContext.id);
    this.numSuppliersPromise = this.supplierService.getCount(this.user.orgContext.id);
    this.getSkuPrice();
    let plan = _.get(this.user, 'subscription.plan');

    if (this.user.subscription.status === 'canceled') {
      this.user.subscription.plan.id = null;
    }

    if (plan) {
      if (plan.interval === 'year') {
        this.payPeriod = 'annual';
      } else if (plan.interval === 'month' && plan.interval_count === 6) {
        this.payPeriod = 'semi-annual';
      }
    }

    this.plansPromise = this.billing.getPlans(this.user.orgContext.id)
      .then((result) => {
        this.plans = _.keyBy(result.data, 'id');
        return result.data;
      });

    this.userList = [{name: '5 users', value: 10}, {name: '10 users', value: 20}, {name: '15 users', value: 30}];
    this.noOfUsers = 10;
  }

  getSkuPrice() {
    if (!this.stripeConstants.planSku) {
      throw new Error('Defaut SKU missing');
    }

    return this.billing.getSku(this.stripeConstants.planSku).then(sku => {
      this.skuPrice = sku.price / 100;
    }).catch(err => {
      this.$log.error('Unable to retrieve payAsGo sku price', this.$log.toString(err));
      this.skuPrice = 'X';
    });
  }

  getPrice(planIds) {
    if (_.isUndefined(planIds) || !this.plans) {
      return '...';
    }
    let tierAmount;

    switch (this.payPeriod) {
    case 'semi-annual':
      tierAmount = this.billing.getPriceForTier(this.plans[planIds.SEMIANNUAL], 1);
      return Math.round(tierAmount / 600);
    case 'annual':
      tierAmount = this.billing.getPriceForTier(this.plans[planIds.ANNUAL], 1);

      return Math.round(tierAmount / 1200);
    default:
      tierAmount = this.billing.getPriceForTier(this.plans[planIds.MONTHLY], 1);
      return Math.round(tierAmount / 100);
    }
  }

  onCancel() {
    this.modalInstance.dismiss('cancel');
  }

  getPlanId(product, payFreq) {
    switch (payFreq) {
    case 'monthly':
      return this.subscriptionPlans[product].MONTHLY;
    case 'annual':
      return this.subscriptionPlans[product].ANNUAL;
    case 'semi-annual':
      return this.subscriptionPlans[product].SEMIANNUAL;
    default:
      throw new Error('Invalid pay frequency: ' + payFreq);
    }

  }

  activatePlan() {
    if (this.user.isTrialing()) {
      this.subscriptionService.promptForActivation(this.user)
        .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred activating your subscription.'));
    } else {
      this.subscriptionService.promptForUpgrade(this.user, null, this.stripeProducts.FACILITY_PACKAGE);
    }
  }

  promptForPurchase() {
    return this.$uibModal.open({
      component: 'cfPurchasePlan',
      size: 'lg',
      resolve: {
        product: () => this.product,
        user: () => this.user
      }
    }).result.then(chargeId => {
      this.growl.success('Your payment was received. Reports can now be viewed.');
      this.$product.chargeId = chargeId;
      return this.$product.$save();
    });
  }

  getDiscountAmount() {
    if (this.plans) {
      let annualPlan = this.plans[this.subscriptionPlans[this.stripeProducts.STANDARD].ANNUAL];

      let annualDiscountedPrice = this.billing.getPriceForTier(annualPlan, this.noOfUsers) / 100;

      let monthlyPlan = this.plans[this.subscriptionPlans[this.stripeProducts.STANDARD].MONTHLY];

      let annualPrice = this.billing.getPriceForTier(monthlyPlan, this.noOfUsers) / 100 * 12;

      return annualPrice - annualDiscountedPrice;
    }
  }

}

module.exports = Constructor;
