module.exports = function(ngModule) {
  ngModule.component('cfCategoryChooser', {
    bindings: {
      ngModel: '=',
      onItemSelected: '&'  // Called when an item is selected.
    },
    template: require('./products.plan-analysis.category-chooser.template.html'),
    controller: require('./products.plan-analysis.category-chooser.controller'),
    controllerAs: 'vm'
  });
};