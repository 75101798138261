module.exports = function(ngModule) {
  ngModule.component('cfSopLogEditFormTypeFishReceiving', {
    bindings: {
      user: '<',
      $sopLog: '<',
      $sopLogs: '<',
      $sop: '<',
      onSave: '&?',
      onCancel: '&?'
    },
    template: require('./operations.sop-logs.edit-form.type.fish-receiving.template.html'),
    controller: require('./operations.sop-logs.edit-form.type.fish-receiving.controller'),
    controllerAs: 'vm'
  });
};
