module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.sopLogs', {
        url: '/sopLogs',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          sopLogs: ($log, sopLogsService, user) => sopLogsService.query(user.orgContext.id, null, {noBatches: true})
            .catch((err) => {
              $log.error('Unable to query sop logs.', $log.toString(err));

              return [];
            })
        }
      })
      .state('operations.sopLogs.list', {
        url: '?batch?sop',
        template: '<cf-sop-logs-list user="$resolve.user" sop-logs="$resolve.sopLogs"></cf-sop-logs-list>'
      })
      .state('operations.sopLogs.sopLog', {
        url: '/:sopLogId?batchId?sopId',
        template: '<div ui-view></div>',
        resolve: {
          $sopLog: (sopLogsService, user, $stateParams) => sopLogsService.$get($stateParams.sopLogId).then($sopLog => {
            if (!$sopLog.sopId && !$stateParams.sopId) {
              throw new Error('Invalid SOP log. SOP ID missing.');
            }
            _.assign($sopLog, {
              batchId: $sopLog.batchId || $stateParams.batchId || null,
              sopId: $sopLog.sopId || $stateParams.sopId || null
            });
            return $sopLog;
          }),
          $sop: (sopService, $stateParams, $sopLog) => sopService.$get($sopLog.sopId || $stateParams.sopId)
        }
      })
      .state('operations.sopLogs.sopLog.edit', {
        url: '/edit',
        template: '<cf-sop-log-edit user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
          'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop"></cf-sop-log-edit>'
      });
  });
};
