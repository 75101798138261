'use strict';

module.exports = function(ngModule) {
  ngModule.constant('companyTypes', function() {
    return [
      {name: 'USDA / FDA / Retail / SQF / Manufacturing / Distribution / Farm', value: 'manufacturer'},
      {name: 'Restaurant / Catering / Kitchen', value: 'restaurant'},
      {name: 'Other', value: 'other'}
    ];
  });
};
