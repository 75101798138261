class Constructor {
  constructor($q, organizations, users, $uibModal, adminServices, utils, confirmModal) {
    'ngInject';

    this.$q = $q;
    this.organizations = organizations;
    this.users = users;
    this.$uibModal = $uibModal;
    this.adminServices = adminServices;
    this.utils = utils;
    this.confirmModal = confirmModal;
  }

  $onInit() {
    this.search = _.debounce(this._search, 300);
    this._search();
  }

  _search() {
    this.userProfiles = {};
    this.loading = this.searching = true;
    this.standardSearch = true;
    this.searchResults = [];
    let searchPromise;

    this.logSearch = new this.adminServices.LogSearch();
    if (this.searchText) {
      searchPromise = this.logSearch.search(this.searchText);
    } else {
      searchPromise = this.logSearch.search();
    }

    searchPromise
      .then(results => {
        this.searchResults = results;
        this.getUserProfiles(results);
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Error occurred during search.'))
      .finally(() => {
        this.searching = this.loading = false;
      });
  }

  getUserProfiles(logs){
    _.map(logs, (log)=>{
      if(log._source.uid && !this.userProfiles[log._source.uid]) {
        this.userProfiles[log._source.uid] = {uid: log._source.uid};
        this.users.getProfile(log._source.uid).then((profile)=>{
          this.userProfiles[log._source.uid].profile = profile;
        });
      }
    });
  }
  searchKeyPress($event) {
    if (this.utils.isBenignKeyUp($event.keyCode)) {
      return;
    }

    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) {
        this.search();
      }

      this.search.flush();

      return;
    }

    if (this.searchText && this.searchText.length <= 3) {
      return;
    }

    this.search();
  }

  getMore() {
    this.loading = true;

    this.$q.when(this.logSearch.getMore())
      .then((searchResults) => {
        this.searchResults = searchResults;
        this.getUserProfiles(searchResults);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  openMessage(log) {
    let displayText = {};

    if (log._source.level === 'error') {
      displayText = JSON.stringify(JSON.parse(log._source.stack));
    } else if(log._source.level === 'info') {
      displayText = log._source.metadata && log._source.metadata.customPropsStr ?
        JSON.stringify(JSON.parse(log._source.customPropsStr)) : log._source.message;
    } else{
      displayText = log._source.message;
    }
    this.confirmModal({
      title: 'Log Details',
      body: `<code>${displayText}</code>`,
      hideCancelButton: true,
      size: 'lg'
    });
  }
}

module.exports = Constructor;
