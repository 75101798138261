'use strict';

module.exports = function(ngModule) {
  ngModule.constant('labTestStatus', {
    INIT: {id: 0, text: 'Test not Requested', class: 'label rounded label-default'},
    TEST_REQUESTED: {id: 1, text: 'Test Requested', class: 'label rounded label-orange'},
    BID_SUBMITTED: {id: 2, text: 'Bid Submitted', class: 'label rounded label-yellow'},
    TEST_IN_PROGRESS: {id: 3, text: 'In Progress', class: 'label rounded label-blue'},
    PUBLISHED_BUT_UNPAID: {id: 4, text: 'Published / Unpaid', class: 'label rounded label-sea'},
    PUBLISHED: {id: 5, text: 'Results Published', class: 'label rounded label-green'},
    CANCELLED: {id: 6, text: 'Cancelled', class: 'label rounded label-red'}
  });
};
