'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfProductQuestionnaire', {
    bindings: {
      user: '<',
      product: '=',
      company: '<',
      facilities: '=',
      isReadOnly: '<'
    },
    template: require('./products.plan-analysis.questionnaire.template.html'),
    controller: require('./products.plan-analysis.questionnaire.controller'),
    controllerAs: 'vm'
  });
};

