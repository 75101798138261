class Constructor {
  constructor($log, $state, $q, states, countries, $timeout, singleExitForm) {
    'ngInject';

    this.$log = $log;
    this.$state = $state;
    this.$q = $q;
    this.$timeout = $timeout;
    this.singleExitForm = singleExitForm;

    this.states = states();
    this.countries = countries();
    this.USA = 'US';

    this.companySizes = [
      {name: 'Self-employed', value: 'A'},
      {name: '1-10 employees', value: 'B'},
      {name: '11-50 employees', value: 'C'},
      {name: '51-200 employees', value: 'D'},
      {name: '201-500 employees', value: 'E'},
      {name: '501-1000 employees', value: 'F'},
      {name: '1001-5000 employees', value: 'G'},
      {name: '5001-10,000 employees', value: 'H'},
      {name: '10,001+ employees', value: 'I'}
    ];
  }

  $onInit() {
    this.companyOnboarding = {companyName: this.company.companyName};
    _.assign(this.user, _.pick(this.$user, ['email', 'firstName', 'lastName']));

    this.noEmail = !this.user.email;
    this.company.country = this.company.country || this.USA;

    this.$timeout(() => {
      if (this.companyInfoForm) {
        this.singleExitForm(this.companyInfoForm, 'Next');
      }
    }, 0);
  }

  finish() {
    this.company.companyName = this.companyOnboarding.companyName;
    this.$user.email = this.user.email;
    this.$user.firstName = this.user.firstName;
    this.$user.lastName = this.user.lastName;
    this.$user.phone = this.user.phone;
    this.company.pointOfContactName = this.company.pointOfContactName || this.user.firstName + ' ' + this.user.lastName;
    this.company.pointOfContactEmail = this.company.pointOfContactEmail || this.user.email;
    this.company.phone = this.company.phone || this.user.phone || null;
    this.$q.all([
      this.$user.$save(),
      this.company.$save(),
    ])
      .then(() => {
        this.user.setOrgContext(this.user.orgContext.id);
        if (this.companyInfoForm) {
          this.companyInfoForm.$setPristine();
        }

        if (this.company.onboarded) {
          this.$state.go('user.dashboard');
        } else {
          this.$state.go('onboarding.firstPlan');
        }
      })
      .catch(err =>
        this.$log.error('An error occurred in onboarding company info', this.$log.toString(err)));
  }
}

module.exports = Constructor;
