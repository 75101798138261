class Controller {
  constructor(fbutil, $q) {
    'ngInject';

    this.fbutil = fbutil;
    this.$q = $q;
  }

  $onInit() {
    this.group = this.resolve.group;
    this.prevGroupName = this.group.groupName;

    this.newGroup = this.group.$value === null;
  }

  save() {
    this.group.createdOn = this.newGroup ? firebase.database.ServerValue.TIMESTAMP : this.group.createdOn;
    this.group.$save().then(() => {
      // If the group name changed, we have to change on each member product.
      if (!this.newGroup && this.prevGroupName !== this.group.groupName) {
        return this.$q.all(_.map(this.group.members, (val, productId) => {
          return this.fbutil.ref('products', productId, 'groupName').set(this.group.groupName).then(() => {
            return {
              productId: productId,
              groupName: this.group.groupName
            };
          });
        }));
      }
    }).then(productChanges => {
      this.modalInstance.close({
        group: this.group,
        productChanges: productChanges,
      });
    });
  }
}

module.exports = Controller;
