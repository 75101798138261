module.exports = function(ngModule) {
  ngModule.component('cfSopLogEditForm', {
    bindings: {
      user: '<',
      $sopLog: '<',
      $sopLogs: '<',
      $sop: '<',
      onSave: '&?',
      onCancel: '&?'
    },
    template: require('./operations.sop-logs.edit-form.template.html'),
    controller: require('./operations.sop-logs.edit-form.controller'),
    controllerAs: 'vm'
  });
  require('./footer')(ngModule);
  require('./header')(ngModule);
  require('./form-content')(ngModule);
  require('./type')(ngModule);
};
