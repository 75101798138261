module.exports = function($state, $uibModal, $q, $log, $firebaseObject, $window, fbutil, moment, organizations,
  authentication, growl, confirmDeleteModal, S3, authorization, utils, FILE_PORTAL_ORG, fileBucketService, users) {
  'ngInject';

  const vm = this;
  vm.$onInit = function() {
    vm.$state = $state;
    vm.isUploadPortal = vm.organization.$id === FILE_PORTAL_ORG;
    vm.names = {};
    if(vm.media) {
      vm.media.forEach((file)=>{
        if(file.uid && !vm.names[file.uid]) {
          vm.names[file.uid] = 'User';
          users.getName(file.uid).then((name) => vm.names[file.uid] = name);
        }
      });
    }
  };

  vm.edit = function(item, categoryId) {
    let beforePromise = $q.when(!item && _.isFunction(vm.beforeNewFile) ? vm.beforeNewFile() : true);
    let mediaChild = {};

    if(!vm.isFileBucket) {
      mediaChild = $firebaseObject(item ? vm.media.$ref().child(item.$id) : vm.media.$ref().push()).$loaded();
    } else {
      mediaChild =
        $firebaseObject(item ? fileBucketService.ref().child(item.$id) :  fileBucketService.ref().push()).$loaded()
          .then((item)=> {
            item.organizationId = vm.organization.$id;
            return item;
          });
    }
    beforePromise.then(result => {
      if (!result) { return $q.reject('cancel'); }
      return $uibModal.open({
        component: 'cfMedia',
        controllerAs: 'vm',
        backdrop: 'static',
        resolve: {
          item: () => mediaChild,
          remoteDirectory: () => vm.remoteDirectory,
          media: () => vm.media,
          organization: () => vm.organization,
          categories: () => vm.categories,
          warning: () => vm.warning,
          chosenCategory: () => categoryId,
          user: () => vm.user,
          expiryDate: () => vm.expiryDate
        }
      }).result;
    }).then(file => {
      vm.media.forEach(mediaObject => {
        if(mediaObject.$id === file.$id) {
          Object.assign(mediaObject, file);
        }
        if(file.uid && !vm.names[file.uid]) {
          vm.names[file.uid] = 'User';
          users.getName(file.uid).then((name) => vm.names[file.uid] = name);
        }
      });

      if (!item) {
        if (vm.onNewFile) { vm.onNewFile({$file: file}); }
      }
      growl.success('Files ' + (item ? 'updated' : 'added') + ' successfully.', {});
    }).catch(function(reason) {
      if (utils.isModalDismissalByUser(reason)) { return; }

      growl.error('Error creating the new file.', {});
      $log.error(reason);
    });
  };

  vm.remove = function(item) {
    confirmDeleteModal(_.truncate(item.name, {length: 30}))
      .then(function() {
        vm.removeFile(item)
          .then(() => {
            return S3.deleteFile(vm.organization.$id, item.key)
              .catch((err) => {
                vm.media.$add(_.pick(item, ['url', 'key', 'name', 'size', 'type']));
                return $q.reject(err);
              });
          })
          .then(() => {
            if (vm.onRemoveFile) { vm.onRemoveFile({removedFile: item}); }
            growl.success('File successfully removed.');
          })
          .catch((err) => {
            growl.error('Unable to delete file. Please try again or contact customer support for assistance.');
            $log.error(err);
          });
      });
  };

  vm.removeFile = function(item) {
    if(!vm.isFileBucket) {
      return vm.media.$remove(item);
    } else {
      return fileBucketService.remove(item.$id);
    }
  };

  vm.toDateString = function(date) {
    return date ? moment(date).format('MM-DD-YYYY') : 'Unknown';
  };

  vm.open = function(file) {
    S3.openSignedUrlInNewTab(vm.organization.$id, file.key, null, S3.parseKey(file.key).productId, vm.projectId);
  };

  vm.categoryName = function(fileType) {
    let category = _.find(vm.categories, c => c.id === fileType);

    return category ? category.text : '';
  };
};
