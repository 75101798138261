class Constructor {
  constructor($window) {
    'ngInject';

    this.$window = $window;
  }

  goBack() {
    this.$window.history.back();
  }
}

module.exports = Constructor;
