require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProduct', {
    bindings: {
      product: '=',
      processSteps: '=',
      processLinks: '=',
      user: '<',
      productOrganization: '<',
      isReadOnly: '<',
      $hazards: '<',
      sops: '<',
      foodCategories: '<',
      members: '=',
      facilitySops: '<'
    },
    template: require('./products.edit.template.html'),
    controller: require('./products.edit.controller.js'),
    controllerAs: 'vm'
  });

  require('./diagram')(ngModule);
  require('./info')(ngModule);
  require('./properties')(ngModule);
  require('./ingredients')(ngModule);
  require('./equipment')(ngModule);
  require('./hazards')(ngModule);
  require('./files')(ngModule);
  require('./control')(ngModule);
  require('./process_step')(ngModule);
  require('./procedure')(ngModule);
  require('./prerequisites')(ngModule);
  require('./food_products')(ngModule);
  require('./forms')(ngModule);
};
