module.exports = function(ngModule) {
  ngModule.factory('supplierService', function(fbutil, $q, $log, orgInteractionService, INVITE_EXPIRE_DAYS,
                                               $firebaseObject, supplierIngredientService, authorization,
                                               accessRequests, messageServices, confirmModal, CF_ROLES, utils,
                                               organizations, $http, $firebaseArray) {
    'ngInject';

    function removeSupplier(user, supplierId) {
      let supplier;

      return fbutil.ref('suppliers', supplierId).once('value')
        .then(supplierSnap => {
          if (!supplierSnap.exists()) {
            return $q.reject('Supplier does not exist ' + supplierId);
          }

          supplier = supplierSnap.val();

          if (!supplier.ingredients) {
            return;
          }

          return $q.all(_.map(supplier.ingredients, (val, ingredientId) => {
            return supplierIngredientService.remove(user, ingredientId).catch($log.error);
          }));
        })
        .then(() => $q.when([
          fbutil.ref('suppliers', supplierId, 'deleted').set(new Date().getTime()),
          fbutil.ref('suppliers', supplierId, 'deletedOn').set(new Date().getTime()),
        ]))
        .then(() => fbutil.ref('organizations', supplier.owningOrg, 'suppliers', supplierId).remove())
        .then(() => {
          if (!supplier.organizationId) { return; }
          return messageServices.deleteOrgToOrgTopic(user, supplier.organizationId);
        });
    }

    function saveSupplier(user, $supplier, org) {
      let newSupplier = $supplier.$value === null;
      let newSupplierPromise;

      if (newSupplier) {
        newSupplierPromise = $q.all([fbutil.ref('organizations', org.$id, 'suppliers', $supplier.$id).set(true),
          organizations.setMilestoneAchieved(org.$id, organizations.milestones.HAS_SUPPLIERS)]);
      }

      $supplier.owningOrg = $supplier.owningOrg || org.$id;
      $supplier.createdOn = $supplier.createdOn || firebase.database.ServerValue.TIMESTAMP;
      return $q.when(newSupplierPromise).then(() => $supplier.$save());
    }

    return {
      $get: function(supplierId) {
        return $firebaseObject(fbutil.ref(`suppliers/${supplierId}`)).$loaded();
      },

      $push: function(data) {
        return $firebaseObject(fbutil.ref('suppliers').push(data)).$loaded();
      },

      $getFiles: function(supplierId) {
        return $firebaseArray(fbutil.ref(`suppliers/${supplierId}/files`)).$loaded();
      },

      getCount: function(organizationId) {
        return $http.get(`/organizations/${organizationId}/suppliers?count=true`)
          .then(resp => resp.data.count);
      },

      getName: function(supplierId) {
        return fbutil.ref(`/suppliers/${supplierId}/name`).once('value').then(fbutil.getValueOrDefault);
      },

      remove: function(user, supplierId) {
        return removeSupplier(user, supplierId);
      },

      getIngredients: function(organizationId, supplierId) {
        return $http.get(`/organizations/${organizationId}/suppliers/${supplierId}/ingredients`)
          .then(resp => resp.data);
      },

      saveSupplier: function(user, supplier, org) {
        return saveSupplier(user, supplier, org);
      },

      /**
       * Get an organization's supplier tracking settings.
       * @param {string} organizationId The organization ID
       * @returns {Promise} A promise that resolves to a FB object representing the tracking config.
       */
      $getTracking: function(organizationId) {
        return $firebaseObject(fbutil.ref(`organizations/${organizationId}/supplierTracking`)).$loaded();
      },

      stopReminders: function(supplierId) {
        $log.info('A supplier opted out of supplier tracking reminders.', null, {notify: true});
        return fbutil.ref(`suppliers/${supplierId}/supplierTrackingOptOut`).set(true);
      },

      /**
       * Send a files request to one or more contacts that belong to a supplier.
       * @param {object} supplier The supplier receiving the request
       * @param {array} contacts An array of contacts of the specific supplier contacts receiving the request
       * @return {Q.Promise<any>} A promise that resolves to the tokens created
       */
      sendFilesRequest: function(supplier, contacts) {
        return $http.post(`/organizations/${supplier.owningOrg}/suppliers/${supplier.$id}/filesRequest`, {
          contacts
        }).then(result => result.data);
      },

      cancelFilesRequest: function(supplier) {
        return $http.delete(`/organizations/${supplier.owningOrg}/suppliers/${supplier.$id}/filesRequest`);
      },

      getRequestFilesTokens: function(supplierId) {
        return fbutil.ref(`suppliers/${supplierId}/requestFilesTokens`).once('value')
          .then(fbutil.getValueOrDefault);
      },

      fileRequestOptOut: function(supplierId) {
        return fbutil.ref(`suppliers/${supplierId}/requestFilesOptOut`).set(true);
      },

      fileRequestOptIn: function(supplierId) {
        return fbutil.ref(`suppliers/${supplierId}/requestFilesOptOut`).remove();
      },

      getSupplierMap: function(organizationId) {
        return $http.get(`/organizations/${organizationId}/suppliers?asExternalNumberMap=true`)
          .then(resp => resp.data);
      },

      incrementBadEmailResult: function(supplier, tokenId) {
        let tokenArray = supplier.requestFilesTokens;
        let tokenStatus = _.find(tokenArray, t => t.id === tokenId);
        let curCount = tokenStatus ? _.get(tokenStatus, 'failedEmailCheckCount', 0) : 0;

        return fbutil.ref(`suppliers/${supplier.$id}/requestFilesTokens/${tokenId}/failedEmailCheckCount`)
          .set(curCount + 1);
      }
    };
  });
};
