require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductControl', {
    bindings: {
      user: '<',
      product: '<',
      company: '<',
      facilitySops: '<',
      planSops: '=',
      $control: '=',
      $hazards: '<',
      $processSteps: '<',
      onSaveAndClose: '&?',
      onSave: '&?',
      onCancel: '&?',
      isDirty: '=',
      foodProducts: '=',
      members: '=',
    },
    template: require('./products.control.template.html'),
    controller: require('./products.control.controller.js'),
    controllerAs: 'vm'
  });
};
