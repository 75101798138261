class CfCcNumberInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-cc-number-input.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      label: '@?',           // The label text
      cardType: '=',         // The CC type
      name: '@',             // The name of the form control
      appendDirection: '@?', // Append the icon to the right or the left (options: ['left', 'right'], default = 'left')
      helpText: '@?',        // Optional subscript helper text
      isRequired: '='        // Is the field required (true / false)
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller($timeout) {
    'ngInject';

    this.$timeout = $timeout;
    this.appendDirection = this.appendDirection || 'left';
  }

  link(scope, elem) {
    let vm = scope.vm;

    vm.$timeout(() => {
      let $formElement = elem.find('input');

      $formElement.focus(() => $formElement.parent().addClass('g-state-focus'));
      $formElement.blur(() => $formElement.parent().removeClass('g-state-focus'));
    });
  }
}

module.exports = CfCcNumberInput;
