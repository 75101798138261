class Controller {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.save = _.debounce(this._save, 2000);
  }

  infoChanged() {
    this.save();
  }

  _save() {
    this.saving = true;
    this.product.updatedOn = firebase.database.ServerValue.TIMESTAMP;
    this.product.updatedBy = this.user.uid;
    this.product.$save()
      .then(() => this.lastSaved = this.product.updatedOn)
      .catch(err => {
        this.growl.error('Unable to save product information.');
        this.$log.error(err);
      })
      .finally(() => this.saving = false);
  }
}

module.exports = Controller;
