module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceEditServiceOffered', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./marketplace.edit-service-offered.template.html'),
    controller: require('./marketplace.edit-service-offered.controller.js'),
    controllerAs: 'vm'
  });
};
