class Constructor {
  constructor($log, growl, $window, order, drift, organizations, billing, CF_CONSTANTS,
              cfpLoadingBar, confirmModal, companySubscription, utils,
              products, supplierService, $q, reportsService, subscriptionService, constantsService) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.$window = $window;
    this.orderService = order;
    this.organizations = organizations;
    this.billing = billing;
    this.CF_CONSTANTS = CF_CONSTANTS;
    this.cfpLoadingBar = cfpLoadingBar;
    this.confirmModal = confirmModal;
    this.companySubscription = companySubscription;
    this.drift = drift;
    this.utils = utils;
    this.products = products;
    this.supplierService = supplierService;
    this.reportsService = reportsService;
    this.subscriptionService = subscriptionService;
    this.$q = $q;
    this.constantsService = constantsService;
  }

  $onInit() {
    this.stripeProducts = this.stripeConstants.stripeProducts;
    this.productConstraints = this.stripeConstants.constraints;
    this.subscriptionPlans = this.stripeConstants.subscriptionPlans;
    this.activeProducts = this.stripeConstants.activeProducts;
    this.step = 'choosePlan';
    this.payPeriodToggle = true;
    this.numPlansPromise = this.products.getCount(this.user.orgContext.id);
    this.numSuppliersPromise = this.supplierService.getCount(this.user.orgContext.id);
    this.getSkuPrice();
    if (this.user.subscription.status === 'canceled') {
      this.user.subscription.plan.id = null;
    }

    this.activePlans = {};
    this.billing.getPlans(this.user.orgContext.id)
    .then((result) => {
      this.plans = _.keyBy(result.data, 'id');
      _.each(this.activeProducts, data => {
        let monthlyId = this.subscriptionPlans[this.stripeProducts[data.id]].MONTHLY;
        let annualId = this.subscriptionPlans[this.stripeProducts[data.id]].ANNUAL;
        this.activePlans[data.id] = {
          id: data.id,
          name: data.name,
          text: data.text,
          monthlyId,
          monthly: this.plans[monthlyId].tiers[0].amount / 100,
          annualId,
          annual: this.plans[annualId].tiers[0].amount / 100,
          annualMonthlyPrice: Math.round(this.plans[annualId].tiers[0].amount / 1200),
          discount: this.getDiscountAmount(data.id),
        };
      });
      this.setClickedRow(this.activePlans.SMALL_FACILITY);
    });
    this.noOfUsers = 1;
  }

  getSkuPrice() {
    if (!this.stripeConstants.planSku) {
      throw new Error('Default SKU missing');
    }

    return this.billing.getSku(this.stripeConstants.planSku).then(sku => {
      this.skuPrice = sku.price / 100;
    }).catch(err => {
      this.$log.error('Unable to retrieve payAsGo sku price', this.$log.toString(err));
      this.skuPrice = 'X';
    });
  }

  setClickedRow(index) {
    this.selectedRow = index;
    this.priceId = this.payPeriodToggle ? this.selectedRow.monthlyId : this.selectedRow.annualId;
  }

  togglePayperiod() {
    this.payPeriodToggle = !this.payPeriodToggle;
    this.setClickedRow(this.activePlans.SMALL_FACILITY);
  }

  submitOrder(planOrder, payment) {
    this.paymentError = null;
    return this.orderService.submit(planOrder, payment, this.user, planOrder.organizationId)
      .then((processedOrder) => {
        let subscription = _.first(processedOrder.items).subscription;

        this.newSubscription = subscription;
        this.user.subscription = subscription;
        this.step = 'orderSubmitted';
      })
      .catch(error => {
        this.$log.error('Error processing order', this.$log.toString(error));
        this.paymentError = 'An error occurred processing your order. Please try again or contact customer service.';
      });
  }

  onPaymentBack() {
    this.step = 'choosePlan';
  }


  getDiscountAmount(productName) {
    if(this.plans) {
      let annualPlan = this.plans[this.subscriptionPlans[this.stripeProducts[productName]].ANNUAL];

      let annualDiscountedPrice = this.billing.getPriceForTier(annualPlan, this.noOfUsers) / 100;

      let monthlyPlan = this.plans[this.subscriptionPlans[this.stripeProducts[productName]].MONTHLY];

      let annualPrice = this.billing.getPriceForTier(monthlyPlan, this.noOfUsers) / 100 * 12;

      return annualPrice - annualDiscountedPrice;
    }
  }

  chat() {
    this.drift.openSidebar();
  }

  buyProduct() {
    this.order = {
      organizationId: this.user.orgContext.id,
      items: [{
        title: this.selectedRow.name,
        comments: 'FoodReady subscription (billed every ' + (this.payPeriodToggle ? 'monthly' : 'yearly') + ')',
        planId: this.priceId,
        amount: {standard: this.payPeriodToggle ? this.selectedRow.monthly : this.selectedRow.annual},
        couponType: 'subscription',
        quantity: this.noOfUsers
      }]
    };
    this.step = 'submitOrder';
    this.choosing = false;
  }
}

module.exports = Constructor;
