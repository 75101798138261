class CfInfoIcon {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-info-icon.template.html');
    this.bindToController = {
      popoverHtml: '<'    // Popover text
    };

    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';
  }

}

module.exports = CfInfoIcon;
