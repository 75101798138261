require('./change-subscription.scss');

module.exports = function(ngModule) {
  ngModule.component('cfChangeSubscription', {
    bindings: {
      user: '<',
      isModal: '<?',
      onComplete: '&',
      stripeConstants: '<',
      onCancel: '&?',
      isPlanPrompt: '<',
      product: '<'
    },
    template: require('./change-subscription.template.html'),
    controller: require('./change-subscription.controller.js'),
    controllerAs: 'vm'
  });
};
