module.exports = function(ngModule) {
  ngModule.component('cfQuestionnaire', {
    bindings: {
      questionnaire: '<',
      type: '<',
      readOnly: '<'
    },
    template: require('./questionnaires.questionnaire.template.html'),
    controller: require('./questionnaires.questionnaire.controller'),
    controllerAs: 'vm'
  });
};
