require('./users.settings.profile.scss');

module.exports = function(ngModule) {
  ngModule.component('cfProfile', {
    bindings: {
      $user: '=',
      user: '=',
      orgName: '=',
      readOnly: '<?'
    },
    template: require('./users.settings.profile.template.html'),
    controller: require('./users.settings.profile.controller'),
    controllerAs: 'vm'
  });
};
