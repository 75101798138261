module.exports = function(ngModule) {
  ngModule.component('cfMyProgress', {
    bindings: {
      user: '<',
      $company: '<'
    },
    template: require('./users.my-progress.template.html'),
    controller: require('./users.my-progress.controller.js'),
    controllerAs: 'vm'
  });

  /**
   * Whitelist the YouTube videos that are used to educate users on each checklist item.
   */
  ngModule.config(function($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      '', // GMP Video
      '', // HACCP/PC Video
      '', // Flow Diagram
      '', // Recall Plan
      ''  // Supplier Verification
    ]);
  });

  require('./checklist_item')(ngModule);
  require('./intro_modal')(ngModule);
};
