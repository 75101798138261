module.exports = function($firebaseObject, $log, $q, $uibModal, S3, confirmDeleteModal, authorization, growl, utils) {
  'ngInject';

  const vm = this;

  vm.edit = function(laboratoryId) {
    $uibModal.open({
      component: 'cfLaboratory',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        company: () => vm.company,
        laboratory: () => {
          const laboratoryRef = laboratoryId ? vm.laboratories.$ref().child(laboratoryId) :
            vm.laboratories.$ref().push();

          return $firebaseObject(laboratoryRef).$loaded();
        }
      }
    }).result.then(function() {
      growl.success('Laboratory ' + (laboratoryId ? 'updated' : 'added') + ' successfully.', {});
    }).catch(function(reason) {
      if (utils.isModalDismissalByUser(reason)) { return; }

      growl.error('Error ' + (laboratoryId ? 'updating' : 'added') + ' the new laboratory. Please ' +
        'try again or contact FoodReady customer support.', {});
      $log.error(reason);
    });
  };

  vm.remove = function(laboratory) {
    if (vm.user.hasPermission(authorization.claims.ORGANIZATION_WRITE)) {
      confirmDeleteModal(laboratory.name).then(function() {
        let filePromises = [];

        _.each(laboratory.media, (file) => {
          filePromises.push(S3.deleteFile(vm.company.$id, file.key));
        });

        vm.facilities.$remove(laboratory);
        $q.all(filePromises).catch($log.error);
      });
    } else {
      growl.error('Insufficient access. Please contact customer support.');
    }
  };
};
