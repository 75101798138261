module.exports = function(ngModule) {
  ngModule.component('cfProjectWritingView', {
    bindings: {
      user: '<',
      organization: '<',
      project: '<',
      files: '<',
    },
    template: require('./project_writing.view.template.html'),
    controller: require('./project_writing.view.controller'),
    controllerAs: 'vm'
  });
};
