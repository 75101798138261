'use strict';

module.exports = function (ngModule) {
  ngModule.directive('cfPasswordReset', function () {
    return {
      scope: true,
      restrict: 'EA',
      template: require('./users.password-reset.template.html'),
      controller: require('./users.password-reset.controller.js')
    };
  });
};
