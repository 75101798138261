module.exports = function(ngModule) {
  ngModule.component('cfCompanyOverview', {
    bindings: {
      company: '=',
      user: '<'
    },
    template: require('./company.overview.template.html'),
    controller: require('./company.overview.controller.js'),
    controllerAs: 'vm'
  });
};
