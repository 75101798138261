module.exports = function(ngModule) {
  ngModule.component('cfImportIngredientsModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./ingredients.import-ingredients-modal.template.html'),
    controller: require('./ingredients.import-ingredients-modal.controller'),
    controllerAs: 'vm'
  });
};