module.exports = function(ngModule) {
  ngModule.component('cfAssignedRecords', {
    bindings: {
      user: '<',
      sops: '='
    },
    template: require('./users.assigned-records.template.html'),
    controller: require('./users.assigned-records.controller.js'),
    controllerAs: 'vm'
  });
};
