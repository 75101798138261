module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsEdit', {
    bindings: {
      user: '<',
      $event: '<',
      company: '<'
    },
    template: require('./recall.events.edit.template.html'),
    controller: require('./recall.events.edit.controller.js'),
    controllerAs: 'vm'
  });

  require('./reason')(ngModule);
  require('./volume')(ngModule);
  require('./lots')(ngModule);
  require('./notifications')(ngModule);
  require('./notification')(ngModule);
  require('./class_type')(ngModule);
  require('./consignee_impact')(ngModule);
  require('./consignee_impact_edit')(ngModule);
  require('./destruction')(ngModule);
  require('./letter')(ngModule);
  require('./complaint')(ngModule);
};
