class Constructor {
  constructor($log, growl, $window, order, organizations, billing, CF_CONSTANTS, $q, $uibModal,
              companySubscription, drift, constantsService) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.$window = $window;
    this.orderService = order;
    this.organizations = organizations;
    this.billing = billing;
    this.CF_CONSTANTS = CF_CONSTANTS;
    this.$q = $q;
    this.$uibModal = $uibModal;
    this.companySubscription = companySubscription;
    this.constantsService = constantsService;
    this.drift = drift;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.product = this.resolve.product;
    this.planType = this.product.planType === 'pcPlan' ? 'Preventive Controls Plan' : 'HACCP Plan';

    this.step = 'confirmPurchase';

    let stripeConstantsPromise = this.constantsService.get('stripe');

    stripeConstantsPromise.then(stripeConstants => {
      this.skuPromise = this.billing.getSku(stripeConstants.planSku);
    });

    this.onTrial = this.user.isTrialing() && _.get(this.user, 'subscription.plan.metadata.planCoupon');

    // this.gtag.event('unlock_plan_modal', {
    //   'event_category': 'sales',
    //   'event_label': this.user.uid
    // });
  }

  close(order) {
    this.modalInstance.close(order);
  }

  viewSample() {
    this.$window.open(' https://app.foodready.ai/examples/', '_blank');  // todo - update url

    // this.gtag.event('unlock_plan_view_sample', {
    //   'event_category': 'sales',
    //   value: this.user.uid
    // });
  }

  viewFaqs() {
    this.$window.open(' https://app.foodready.ai/faqs/', '_blank');  // todo - update url

    // this.gtag.event('unlock_plan_faqs', {
    //   'event_category': 'sales',
    //   value: this.user.uid
    // });
  }

  chat() {
    this.drift.openSidebar();

    // this.gtag.event('unlock_plan_chat', {
    //   'event_category': 'sales',
    //   value: this.user.uid
    // });
  }

  submitOrder(order, payment) {
    this.paymentError = null;

    return this.$q.all({
      processedOrder: this.orderService.submit(order, payment, this.user, order.organizationId),
      totalAmount: this.calcTotalAmount(order)
    }).then(({processedOrder, totalAmount}) =>
      this.orderService.charge(processedOrder.$id, _.first(processedOrder.items).$id, totalAmount))
      .then(chargeId => this.modalInstance.close(chargeId))
      .catch(error => {
        this.$log.error('Error processing order', this.$log.toString(error));
        this.paymentError = 'An error occurred processing your order. Please try again or contact customer service.';
      });
  }

  calcTotalAmount(order) {
    return this.$q.all(_.map(order.items, (item) => {
      return item.coupon && this.billing.getCoupon(item.coupon);
    })).then(coupons => {
      return _.reduce(order.items, (total, item) => {
        if (!item.amount) { return total; }
        let percent = 1;
        let coupon = item.coupon && _.find(coupons, {id: item.coupon});

        if (coupon && coupon['percent_off_precise']) {
          percent = 1 - coupon['percent_off_precise'] / 100;
        }
        return total + (item.isRushOrder ? item.amount.rush : item.amount.standard * percent);
      }, 0);
    });
  }

  onPaymentBack() {
    this.step = 'confirmPurchase';
  }

  confirmPurchase() {
    this.skuPromise.then(sku => {
      this.order = {
        organizationId: this.user.orgContext.id,
        items: [{
          title: this.product.brandName + ' ' + this.planType,
          amount: {standard: sku.price / 100},
          couponType: 'plan',
          coupon: this.user.isTrialing() ? null : _.get(this.user, 'subscription.plan.metadata.planCoupon', null)
        }],
      };

      this.step = 'submitOrder';
    });
  }

  activateTrial() {
    this.modalInstance.dismiss('cancel');
    return this.companySubscription.activate(this.user);
  }
}

module.exports = Constructor;
