class Constructor {
  constructor($log, growl, $window, order, organizations, billing,
              cfpLoadingBar, confirmModal, companySubscription, utils,
              products, supplierService, $q) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.$window = $window;
    this.orderService = order;
    this.organizations = organizations;
    this.billing = billing;
    this.cfpLoadingBar = cfpLoadingBar;
    this.confirmModal = confirmModal;
    this.companySubscription = companySubscription;
    this.utils = utils;
    this.products = products;
    this.supplierService = supplierService;
    this.$q = $q;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.title = this.resolve.title;
    this.onComplete = this.resolve.onComplete;
    this.stripeConstants = this.resolve.stripeConstants;
    this.requiredProduct = this.resolve.requiredProduct;
    this.onCancel = this.resolve.onCancel;
    this.trialAvailable = this.user.subscription.plan.amount === 0;

    this.stripeProducts = this.stripeConstants.stripeProducts;
    this.subscriptionPlans = this.stripeConstants.subscriptionPlans[this.requiredProduct];

    this.step = 'choosePlan';

    this.billing.getPlans(this.user.orgContext.id)
      .then((result) => {
        this.plans = _.keyBy(result.data, 'id');
        this.chosenPlan = this.plans[this.subscriptionPlans.MONTHLY];
        return result.data;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to load plans.'));
    this.getProductInfo();
  }

  getProductInfo() {
    switch (this.requiredProduct) {
    case this.stripeProducts.PREMIUM:
      this.featureList = [
        'Discounted HACCP/PC Plans',
        'Supplier File Upload Portals',
        'Automated Supplier File Requests',
        'Food Recall Plan',
        'Free Consultation with Annual Subscription'
      ];
      this.productName = 'PREMIUM';
      break;
    case this.stripeProducts.OLD_PREMIUM:
      this.featureList = [
        'Unlimited HACCP/PC Plans',
        'Supplier File Upload Portals',
        'Automated Supplier File Requests',
        'Food Recall Plan',
        'Free Consultation with Annual Subscription'
      ];
      this.productName = 'PREMIUM';
      break;
    case this.stripeProducts.STANDARD:
      this.featureList = [
        'Unlimited Food Safety / HACCP Plans',
        'Unlimited Food Safety & GMP Checklist Self-Audits',
        'Quality Batch Management',
        'Digital Logs / Records Management',
        'Standard Operation Procedure Library',
        'Automated Supplier Document Management',
        'Recall Plan Generator',
        'Up to 5 app mobile users'
      ];
      this.productName = 'STANDARD';
      break;
    case this.stripeProducts.FACILITY_PACKAGE:
      this.featureList = [
        'Unlimited Food Safety / HACCP Plans',
        'Unlimited Food Safety & GMP Checklist Self-Audits',
        'Quality Batch Management',
        'Digital Logs / Records Management',
        'Standard Operation Procedure Library',
        'Automated Supplier Document Management',
        'Recall Plan Generator'
      ];
      this.productName = 'FACILITY PACKAGE';
      break;
    default:
      this.$log.error('Invalid product to upgrade to: ' + this.requiredProduct);
    }
  }

  submitOrder(planOrder, payment) {
    this.paymentError = null;

    return this.orderService.submit(planOrder, payment, this.user, planOrder.organizationId)
      .then((processedOrder) => {
        let subscription = _.first(processedOrder.items).subscription;

        this.newSubscription = subscription;
        this.user.subscription = subscription;
        this.step = 'orderSubmitted';
      })
      .catch(error => {
        this.$log.error('Error processing order', this.$log.toString(error));
        this.paymentError = 'An error occurred processing your order. Please try again or contact customer service.';
      });
  }

  onPaymentBack() {
    this.step = 'choosePlan';
  }

  startTrial() {
    if (this.choosing) { return; }
    this.choosing = true;

    if (!this.user.onPrimaryOrg()) {
      throw new Error('Cannot start a free trial when user is not on primary company.');
    }
    //const planId = this.getPlanId(product, payFreq);

    this.cfpLoadingBar.start();
    this.$log.info('users.plan-chooser.controller.js:startSubscriptionTrial: Starting Subscription.',
        {customerId: this.user.customerId, planId: this.chosenPlan.id});

    return this.companySubscription.changePlan(this.user, this.chosenPlan.id, {'trial_from_plan': true, quantity: 1})
        .then(() => {
          this.step = 'trialStarted';
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to change your subscription plan.'))
        .finally(() => {
          this.cfpLoadingBar.complete();
          this.choosing = false;
        });
  }

  selectPlan() {
    if (this.trialAvailable) {
      return this.startTrial();
    }
    this.choosing = true;
    return this.billing.getProductName(this.chosenPlan.product).then(subscriptionProductName => {
      this.planName = subscriptionProductName;

      this.order = {
        organizationId: this.user.orgContext.id,
        items: [{
          title: this.planName,
          comments: 'FoodReady subscription (billed every ' + (this.chosenPlan.interval_count > 1 ?
            this.chosenPlan.interval_count + ' ' : '') + this.chosenPlan.interval +
            (this.chosenPlan.interval_count > 1 ? 's' : '') + ')',
          planId: this.chosenPlan.id,
          amount: {standard: this.billing.getPriceForTier(this.chosenPlan, 1) / 100},
          couponType: 'subscription',
          quantity: 1
        }]
      };

      this.step = 'submitOrder';
      this.choosing = false;
    }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred changing your subscription plan'));
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Constructor;
