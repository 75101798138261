module.exports = function(ngModule) {
  ngModule.component('cfListOrganizations', {
    bindings: {
      user: '<',
      plans: '<'
    },
    template: require('./administration.list-organizations.template.html'),
    controller: require('./administration.list-organizations.controller'),
    controllerAs: 'vm'
  });
};
