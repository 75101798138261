module.exports = function(ngModule) {
  class Gtm {
    constructor(GTM_ID) {
      'ngInject';

      this.GTM_ID = GTM_ID;

      this.initialized = false;
      this.disabled = false;

      // eslint-disable-next-line angular/window-service
      window.dataLayer = window.dataLayer || [];

      if (this.GTM_ID && !this.initialized) {
        this.initialized = true;
      }
    }

    _gtm() {
      if (this.GTM_ID) {
        // eslint-disable-next-line no-undef,angular/window-service
        let eventDetails = {} ;

        if(arguments[0] === 'event') {
          eventDetails[arguments[0]] = arguments[1];
          eventDetails.label = arguments[2].event_label;
        }

        window.dataLayer.push(eventDetails);
      } else {
        // eslint-disable-next-line angular/log
        console.log('Mock GTM call: ' + angular.toJson(arguments));
      }
    }

    config(value) {
      if (this.disabled) { return; }
      this._gtm('config', this.GTM_ID, value);
    }

    event(action, value) {
      if (this.disabled) { return; }
      this._gtm('event', action, value);
    }

    disable() {
      this.disabled = true;
    }
  }

  ngModule.service('gtm', Gtm);
};

