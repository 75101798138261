class Constructor {
  constructor($q, suppliers, $log, growl, IngredientSearch, ingredients, supplierIngredientService) {
    'ngInject';

    this.$q = $q;
    this.suppliers = suppliers;
    this.$log = $log;
    this.growl = growl;
    this.IngredientSearch = IngredientSearch;
    this.ingredients = ingredients;
    this.supplierIngredientService = supplierIngredientService;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.supplierName = this.resolve.supplierName;
    this.supplierIngredient = {
      createdBy: this.user.uid,
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      supplierId: this.resolve.supplierId,
      productId: this.resolve.productId,
      brandName: this.resolve.productBrandName,
      ingredientName: this.resolve.productBrandName,
    };
    this.ingredientSearch = new this.IngredientSearch(this.user, this.user.orgContext.id);
    this.supplierPromise = this.suppliers.$get(this.resolve.supplierId);
  }

  selectIngredient(selectedIngredient) {
    if (selectedIngredient.custom) {
      // New ingredient
      this.existingIngredient = undefined;
      this.newIngredient = {
        organizationId: this.user.orgContext.id,
        name: selectedIngredient.name,
        createdBy: this.user.uid,
        createdOn: firebase.database.ServerValue.TIMESTAMP
      };
      this.ingredientOverrides = {};
    } else {
      // Existing ingredient
      this.newIngredient = undefined;
      this.existingIngredient = selectedIngredient;
      this.ingredientOverrides = {
        description: this.existingIngredient.description,
        externalNumber: this.existingIngredient.externalNumber
      };
    }
  }

  save() {
    let $supIng;

    this.saving = true;
    this.supplierPromise
      .then($supplier => {
        this.supplierIngredient.productOrganizationId = $supplier.organizationId;
        return this.ingredients.$pushSupplierIngredient(this.supplierIngredient);
      })
      .then(result => {
        $supIng = result;
        return this.existingIngredient ? this.ingredients.get(this.existingIngredient.$id) :
          this.ingredients.$pushIngredient(this.newIngredient);
      })
      .then($ingredient => {
        if (this.newIngredient) {
          this.ingredientOverrides.description = this.ingredientOverrides.description || null;
          _.assign($ingredient, this.ingredientOverrides);
        }
        $ingredient.numSuppliers = ($ingredient.numSuppliers || 0) + 1;
        $ingredient.supplierIngredients = $ingredient.supplierIngredients || {};
        $ingredient.supplierIngredients[$supIng.$id] = true;
        $supIng.ingredientId = $ingredient.$id;

        this.$q.all([
          $ingredient.$save(),
          $supIng.$save()
        ]).then(() => $ingredient.$destroy());
      })
      .then(() => {
        return this.supplierIngredientService.linkToProduct(this.user, $supIng);
      })
      .then(() => {
        this.accessRequestSent = true;
      })
      .catch((err) => {
        this.modalInstance.dismiss(err);
      })
      .finally(() => {
        this.saving = false;
        $supIng && $supIng.$destroy();
      });
  }
}

module.exports = Constructor;