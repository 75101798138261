const convert = require('convert-units');

class Controller {
  constructor(constantsService, batchRunsService, $q, S3) {
    'ngInject';

    this.constantsService = constantsService;
    this.batchRunsService = batchRunsService;
    this.$q = $q;
    this.S3 = S3;
  }

  async $onInit() {
    this.heading = '<strong><i>Lots for </i></strong> - ' + this.supplierIngredient.brandName;
    this.unitMap = {};
    this.unitTypeMap = {};
    this.slideNumber = 1;
    this.noLots = _.isEmpty(this.lots);

    await this.loadConstants();
    this.hydrateLots();
  }

  async loadConstants() {
    this.unitsPromise = this.constantsService.get('units').then(units => {
      this.units = units;
      this.units.forEach((unit)=>{
        this.unitMap[unit.value] = unit.name;
      });
    });

    await this.constantsService.get('fileTypes').then(fileTypes => this.fileTypes =  _.keyBy(fileTypes, 'id'));

    this.unitTypesPromise = this.constantsService.get('unitTypes').then(unitTypes => {
      this.unitTypes = unitTypes;
      this.unitTypes.forEach((container)=>{
        this.unitTypeMap[container.value] = container.name;
      });
    });

    await this.$q.all([this.unitsPromise, this.unitTypesPromise]);
  }

  hydrateLots() {
    _.each(this.lots, lot => {
      let singularPlural = lot.receivedQuantity > 1 ? 's' : '';
      lot.receivedQuantityStr = lot.receivedQuantity + ' ' + this.unitTypeMap[lot.unitType] + singularPlural;
      lot.sizeOfUnitStr = '1 ' + this.unitTypeMap[lot.unitType] + ' = ' + lot.sizeOfUnit + ' ' + this.unitMap[lot.measurementUnit];
      lot.totalLotSize = (lot.receivedQuantity * lot.sizeOfUnit).toFixed(4) + ' ' + this.unitMap[lot.measurementUnit];
      lot.qtyInHandStr = lot.qtyInHand + ' ' + this.unitMap[lot.measurementUnit];

      lot.qtyUsedStr = (lot.receivedQuantity * lot.sizeOfUnit - lot.qtyInHand).toFixed(4) + ' ' + this.unitMap[lot.measurementUnit];
      if(this.ingredientLogs) {
        this.ingredientLogs.forEach(ingredientLog => {
          if(lot.createdOn === ingredientLog.createdOn && ingredientLog.metadata && ingredientLog.metadata.lot === lot.lotNumber) {
            lot.files = ingredientLog.files;
            _.each(lot.files, file => {
              file.fileName = ingredientLog.metadata.lot;
              if(ingredientLog.metadata.po) {
                file.fileName += '/' + ingredientLog.metadata.po;
              }
              if(file.category &&  this.fileTypes[file.category].name) {
                file.fileName += '/' + this.fileTypes[file.category].name;
              }
            });
          }
        });
      }
    });
  }

  openFiles(files) {
    this.slideNumber = 3;
    this.files = files;
  }

  open(file) {
    this.S3.openSignedUrlInNewTab(this.organization.$id, file.key);
  }

  async openTransactions(lot){
    this.slideNumber = 2;
    this.transactionLotNumber = lot.lotNumber;
    this.noTransactions = _.isEmpty(this.transactions);
    if (this.noTransactions) {return;}

    this.lotTransactions = this.transactions[lot.$id];
    this.noLotTransactions = _.isEmpty(this.lotTransactions);
    if (this.noLotTransactions) {return;}

    await this.$q.all(_.map(this.lotTransactions, async transaction => {
      let txQuantity = transaction.measurementUnit !== lot.measurementUnit ?
        this.converter(transaction.qtyDeducted, transaction.measurementUnit, lot.measurementUnit) : transaction.qtyDeducted;

      txQuantity = parseFloat(txQuantity).toFixed(4);

      transaction.quantityStr = txQuantity + ' ' + this.unitMap[lot.measurementUnit];
      await this.batchRunsService.get(transaction.batchRunId)
        .then((batchRun) => {
          transaction.batchRunControlNumber = batchRun.batchControlNumber;
          transaction.foodProductName = batchRun.foodProductName;
        });
    }));
  }

  backToLots() {
    this.slideNumber = 1;
  }

  converter(quantity, givenUnit, targetUnit) {
    if (quantity === 0) {
      return 0;
    }
    if (givenUnit === targetUnit) {
      return quantity;
    }

    let primaryUnit = givenUnit === 'lt' ? 'l' : givenUnit;
    let secondaryUnit = targetUnit === 'lt' ? 'l' : targetUnit;

    primaryUnit = primaryUnit === 'floz' ? 'fl-oz' : primaryUnit;
    secondaryUnit = secondaryUnit === 'floz' ? 'fl-oz' : secondaryUnit;
    try {
      const qty = convert(quantity).from(primaryUnit).to(secondaryUnit).toFixed(4);

      return Number(qty);
    } catch (err) {
      return 0;
    }
  }
}
module.exports = Controller;
