module.exports = function(ngModule) {
  ngModule.factory('adminServices', function($firebaseObject, fbutil, ES_INDICES, $http) {
    'ngInject';

    class LogSearch {
      constructor(size) {
        this.options = {
          uri: 'search/log',
          type: 'log',
          max: size || 3000, // Maximum number of recs to pull back
          pageLimit: 30,     // Number of recs to show per page
          body: {},
        };

        this.queryData = {
          index: ES_INDICES.logs,
          type: 'log',
          body: {
            query: {
              bool: {}
            }
          }
        };
      }

      search(text) {
        this.pageNumber = 1;
        this.searchResults = [];
        if (text) {
          this._addShould({
            'multi_match': {
              query: text.toLowerCase(),
              type: 'phrase_prefix',
              slop: 3,            // Allow terms to appear out of order by this many positions
              'max_expansions': 20  // To improve performance, limit results of the final search term to this many
            }
          });
        }
        this.queryData.body.sort = {'date': {order: 'desc'}};
        this.setSearchQuery(this.queryData.body);
        this.searchPromise = this._doSearch().then(searchResults => {
          this.searchResults = this._processResults(searchResults);
          return this.searchResults;
        });
        return this.searchPromise;
      }


      setSearchQuery(query) {
        this.pageNumber = 1;
        this.searchResults = [];
        this.queryData.body = query;
      }

      _doSearch() {
        var queryCopy = angular.copy(this.queryData),
          payload = {
            method: 'search',
            params: queryCopy
          };

        if (this.options.pageLimit) {
          queryCopy.body.size = this.options.pageLimit;
          queryCopy.body.from = this.options.pageLimit * (this.pageNumber - 1);
        } else {
          queryCopy.body.size = this.options.max;
        }

        this.searching = true;
        return $http.post(this.options.uri, payload).then(results => results.data).finally(() => {
          this.searching = false;
        });
      }

      maxReached() {
        if (!this.options.max || !this.options.pageLimit) {
          return false;
        }
        let max = Math.min(this.options.max, this.total || 0);

        return this.options.pageLimit * this.pageNumber >= max;
      }

      _processResults(searchResults) {
        this.total = searchResults.hits.total?.value;
        if (!searchResults.hits.hits || !searchResults.hits.hits.length) {
          return null;
        } else {
          return searchResults.hits.hits;
        }
      }

      getMore() {
        var that = this;

        // We can't allow more results until the initial result is in (and we therefore know the limit)
        if (!this.searchResults || !this.searchResults.length) {
          return $q.when();
        }

        if (!this.options.pageLimit) {
          throw new Error('Page limit not set');
        }

        if (this.maxReached()) {
          return $q.when(this.searchResults);
        }

        this.pageNumber++;
        this.searchPromise = this._doSearch().then(searchResults => {
          that.searchResults = that.searchResults ?
            this.searchResults.concat(this._processResults(searchResults)) :
            this._processResults(searchResults);
          return that.searchResults;
        });
        return this.searchPromise;
      }

      _addShould(query) {
        this.queryData.body.query = this.queryData.body.query || {};
        this.queryData.body.query.bool.should = this.queryData.body.query.bool.should || [];
        this.queryData.body.query.bool['minimum_should_match'] = this.queryData.body.query.bool['minimum_should_match'] || 1;
        this.queryData.body.query.bool.should.push(query);
      }
    }

    return {
      LogSearch: LogSearch,
      getRoleNames: function(orgId) {
        return fbutil.ref(`roles/${orgId || 'default'}`).once('value').then(snap => {
          if (!snap.exists()) { return []; }
          return _.keys(snap.val());
        });
      },

      getClaims: function(orgId, role) {
        return fbutil.ref(`roles/${orgId || 'default'}/${role}/claims`).once('value').then(fbutil.getValueOrDefault);
      },

      saveClaims: function(orgId, role, claims) {
        return fbutil.ref(`roles/${orgId || 'default'}/${role}/claims`).set(claims);
      }
    };
  });
};
