require('./onboarding.first-plan.scss');

module.exports = function(ngModule) {
  ngModule.component('cfOnboardingFirstPlan', {
    bindings: {
      user: '<',
      company: '<',
      foodCategories: '<',
      $user: '<'
    },
    template: require('./onboarding.first-plan.template.html'),
    controller: require('./onboarding.first-plan.controller'),
    controllerAs: 'vm'
  });
};
