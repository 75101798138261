class Controller {
  constructor($document, $scope) {
    'ngInject';

    this.$document = $document;
    this.$scope = $scope;
  }

  $onInit() {
    this.isOpen = false;
    this.initialAngle = this.initialAngle || 90;
    this.fullCircle = false;
    this.circumference = this.circumference || 1;
  }

  $postLink() {
    let items = this.$document.querySelectorAll('.circular-menu .circle ' + (this.itemSelector || 'a'));

    this.setPositions(items);
    this.$scope.$watch('vm.radius', () => {
      this.setPositions(items);
    });
  }

  setPositions(items) {
    let increase = Math.PI / items.length,
      angle = this.initialAngle;

    increase *= this.circumference * 2;

    for (let i = 0; i < items.length; i++) {
      items[i].style.left = this.radius * Math.cos(angle) + 'px';
      items[i].style.top = this.radius * Math.sin(angle) + 'px';
      angle += increase;
    }
  }
}

module.exports = Controller;
