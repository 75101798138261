class Controller {
  constructor($timeout, planTypes, utils, growl, products, fbutil, SAMPLE_ORGANIZATION_ID, hazardsService, $state) {
    'ngInject';

    this.products = products;
    this.growl = growl;
    this.$timeout = $timeout;
    this.planTypes = planTypes;
    this.fbutil = fbutil;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
    this.utils = utils;
    this.hazardsSvc = hazardsService;
    this.$state = $state;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.foodCategories = this.resolve.foodCategories;
    this.hazardsLibrary = this.resolve.hazardsLibrary;

    this.isPartner = this.user.isPartner();
    if (this.isPartner) {
      this.onPrimaryOrg = this.isPartner && this.user.onPrimaryOrg();
      this.clientName = this.user.orgContext.companyName;
    }

    this.step = 1;
    this.hazardsSelected = {};
    this.newProduct = this.products.newProduct(this.user);
    this.foodCategories = _.orderBy(this.foodCategories, ['name'], ['asc']);

    _.each(this.foodCategories, category => {
      category.subCategories = _.map(
        category.subCategories,
        (subCategory, id) => {
          _.assign(subCategory, {id: id});
          subCategory.hazards = _.map(subCategory.hazards, (val, hazardId) => this.hazardsLibrary[hazardId]);

          return subCategory;
        }
      );
    });
  }

  nextStep(newStep) {
    switch (newStep) {
    case 2:
      let templateId = _.get(this.selectedSubCategory, 'template') ||
          _.get(this.selectedCategory, 'template');

      if (templateId) {
        this.products.getPlanTemplate(this.SAMPLE_ORGANIZATION_ID, templateId).then(template => this.$timeout(() => {
          this.template = template;
        }));
      } else {
        return this.nextStep(newStep + 1);  // No templates, skip that step.
      }
      break;
    case 3:
      this.typicalHazards = _.get(this.selectedSubCategory, 'hazards') ||
          _.get(this.selectedCategory, 'hazards');

      if (!this.typicalHazards) {
        this.hazardsSelected = [];
        return this.save();
      }
      break;
    }
    this.step = newStep;
  }

  prevStep() {
    this.step--;
    if (this.step === 2 && !this.template) {
      this.step--;
    }
  }

  applyTemplate() {
    this.creatingFromTemplate = true;
    return this.products.create(this.newProduct).then(productId => {
      return this.products.loadTemplate(
        {
          organizationId: this.user.orgContext.id,
          $id: productId
        }, this.SAMPLE_ORGANIZATION_ID, this.template.$id,
        {
          hazards: true,
          ingredients: true,
          equipment: true,
          planAnalysis: true
        }).then(() => productId);
    }).then(productId => {
      this.modalInstance.close(productId);
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new food safety plan.')).finally(() => {
      this.creatingFromTemplate = false;
    });
  }

  save() {
    this.newProduct.hazards = _.reduce(this.hazardsSelected, (hazardObj, val, index) => {
      hazardObj[this.fbutil.generateArrayKey()] = _.pick(this.typicalHazards[index], ['name', 'type']);

      return hazardObj;
    }, {});

    this.creating = true;

    // If a partner is creating this plan, create it unlocked.
    if (this.user.isPartner()) {
      this.newProduct.unlockedBy = this.user.uid;
    }
    return this.products.create(this.newProduct)
      .then(productId => {
        this.modalInstance.close(productId);
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new food safety plan.')).finally(() => {
        this.creating = false;
      });
  }

  categorySelected($item) {
    this.hazardsSelected = {};
    this.templateSelected = {};
    this.newProduct.categoryId = $item.id;
    this.selectedSubCategory = null;
  }

  subCategorySelected($item) {
    this.hazardsSelected = {};
    this.templateSelected = {};
    this.newProduct.subCategoryId = $item.id;
  }

  gotoClientList() {
    this.modalInstance.dismiss('cancel');
    this.$state.go('administration.clients.list');
  }
}

module.exports = Controller;
