class Controller {
  constructor($window, $state, $uibModal, $log, growl, $http, order, eventInfo, billing, deviceDetector) {
    'ngInject';

    this.$window = $window;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.growl = growl;
    this.$http = $http;
    this.order = order;
    this.eventInfo = eventInfo;
    this.billing = billing;
    this.deviceDetector = deviceDetector;

    this.professionOptions = ['Federal Government Agency', 'State Health Department', 'Local Health Department',
      'FSSMC Instructor', 'University/Academia', 'Industry', 'Other'];
  }

  $onInit() {
    if (this.user) {
      this.attendee = _.pick(this.user, ['firstName', 'lastName', 'companyName', 'phone', 'email']);
      this.orderUser = this.user;
    }
    this.step = 1;
    this.isMobile = this.deviceDetector.isMobile();
  }

  loginFirst() {
    if (this.$window.localStorage) {
      this.$window.localStorage.setItem('pendingStateName', 'events.register');
    }
    this.$state.go('authentication.signin');
  }

  continue() {
    this.step++;
  }

  chooseMethod() {
    if (this.paymentMethod === 'check') {
      this.$http.post('symposium', {
        attendee: this.attendee,
        paymentMethod: 'check'
      }).then(() => {
        this.step = 6;
      });
    } else {
      this.continue();
    }
  }

  setAttendee() {
    this.orderUser = this.orderUser || this.attendee;
    this.continue();
  }

  choosePackage() {
    let item = {
      title: this.item.title,
      eventId: this.item.id,
      attendDate: this.attendDate || null,
      comments: 'Registration for "' + this.attendee.firstName + ' ' + this.attendee.lastName + '"',
      amount: {standard: this.item.amount}
    };

    this.eventOrder = {
      items: [item]
    };
    this.attendee.purchasedItem = item;
    this.continue();
  }

  submitOrder(order, payment) {
    let orderPromise = payment.customer ? this.existingUserOrder(order, payment) : this.guestOrder(order, payment);

    return orderPromise
      .then(() => {
        this.continue();
      })
      .catch((error) => {
        this.$log.error('An error occurred processing an event order', error);
        this.paymentError = 'Unable to submit order.';
      });
  }

  cancelPayment() {
    this.$state.reload();
  }

  existingUserOrder(order, payment) {
    let processedOrder;

    return this.order.submit(order, payment, this.user, this.user.orgContext.id)
      .then(o => {
        processedOrder = o;
        return this.order.charge(processedOrder.$id, _.first(processedOrder.items).$id);
      })
      .then(chargeId => {
        return this.$http.post('/symposium', {
          attendee: this.attendee,
          orderId: processedOrder.$id,
          chargeId: chargeId
        });
      });
  }

  guestOrder(order, payment) {
    let orderItem = _.first(order.items);

    if (!orderItem) {
      return this.$q.reject('Invalid order item');
    }
    return this.order.tokenize(payment.source)
      .then(token => {
        this.paymentError = null;
        return this.$http.post('symposium', {
          attendee: this.attendee,
          paymentArgs: {
            source: token,
            'receipt_email': order.receiptEmail || undefined,
            amount: this.billing.toStripeAmountFormat(orderItem.amount.standard),
            description: orderItem.title,
            currency: 'usd'
          }
        });
      });
  }

  finish() {
    switch (this.attendee.profession) {
    case 'Federal Government Agency':
    case 'State Health Department':
    case 'Local Health Department':
    case 'FSSMC Instructor':
      this.$window.location.href = 'https://foodready.ai/welcome/symposium-thank-you-answers/';
      break;
    case 'University/Academia':
      this.$window.location.href = 'https://foodready.ai/welcome/symposium-thank-you-academia/';
      break;
    default:
      this.$window.location.href = 'https://foodready.ai/welcome/symposium-thank-you-industry/';
      break;
    }

  }

}

module.exports = Controller;
