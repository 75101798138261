'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsNotification', {
    bindings: {
      resolve: '=',
      modalInstance: '<'
    },
    template: require('./recall.events.notification.template.html'),
    controller: require('./recall.events.notification.controller.js'),
    controllerAs: 'vm'
  });
};
