'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsLots', {
    bindings: {
      $event: '=',
      user: '<',
      company: '<',
      batchRuns: '<'
    },
    template: require('./recall.events.lots.template.html'),
    controller: require('./recall.events.lots.controller'),
    controllerAs: 'vm'
  });
};
