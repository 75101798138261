module.exports = function(growl, $log, preventDirtyNav, fbutil, $timeout, products, $q, productGroups,
                          $uibModal, organizations, $document, SAMPLE_PRODUCT_ID, $stateParams, planTypes,
                          SAMPLE_ORGANIZATION_ID, $window, $http, utils, confirmModal) {
  'ngInject';

  let vm = this;

  vm.$onInit = function() {
    $timeout(function() {
      preventDirtyNav(vm.questionnaireForm, 'You have unsaved changes to ' + vm.product.brandName + '.', vm.save,
        () => products.$reset(vm.product));
    }, 0);

    vm.planTypes = planTypes;

    vm.invalidNotification = false;
    vm.notifications = {};
    vm.originalGroup = vm.product.groupId;
    vm.selectedGroup = {$id: vm.product.groupId};
    if ($stateParams.newProdFromService) {
      confirmModal({
        title: 'Fill Out Plan Details',
        body: 'Thank you for submitting a request to the FoodReady marketplace! <br>Please fill out the ' +
        `plan questionnaire for <b>${vm.product.brandName}</b> so we can provide the most accurate quote.`,
        okText: 'Ok',
        hideCancelButton: true
      });
    }
  };

  vm.$onDestroy = function() {
    // Clean up any erroneously created product groups.
    _.each(vm.newGroups, newGroup => {
      if (newGroup.$id !== vm.product.groupId) {
        productGroups.delete(newGroup.$id);
      }
    });
  };

  vm.fetchSamplePlan = function() {
    let reportWindow = $window.open('/api/reports/loading');
    let chosenPlan = _.find(vm.planTypes, type => type.id === vm.product.planType);

    $http.get('/reports/' + SAMPLE_ORGANIZATION_ID + '/' + SAMPLE_PRODUCT_ID + '/' +
      (chosenPlan || vm.planTypes.PC_PLAN).serverId + '?format=pdf')
      .then((url) => {
        reportWindow.location.replace(url.data);
      })
      .catch(reason => {
        if (utils.isModalDismissalByUser(reason)) { return; }

        $log.error('An error occurred opening the report', $log.toString(reason));
        growl.error('An error occurred opening the report. If this continues, please contact customer support.');
      });
  };

  vm.save = function() {
    let groupUpdatePromise = vm.originalGroup !== vm.product.groupId ?
      productGroups.setGroup(vm.product.$id, vm.product.groupId) : $q.when();

    groupUpdatePromise
      .then(() => {
        _.remove(vm.newGroups, g => g.$id === vm.product.groupId);
        vm.originalGroup = vm.product.groupId;
        return vm.product.$save();
      })
      .then(() =>
        organizations.setMilestoneAchieved(vm.product.organizationId, organizations.milestones.PLAN_ANALYSIS_STARTED))
      .then(() => {
        if (vm.questionnaireForm) {
          vm.questionnaireForm.$setPristine();
        }
        growl.success('Product questionnaire saved.');
      })
      .catch(err => {
        $log.error('Error saving questionnaire: ' + err);
        growl.error('Unable to save changes.');
      });
  };

  vm.groupSelected = function(group) {
    vm.product.groupId = _.get(group, '$id') || null;
  };

  vm.groupCreated = function(group) {
    vm.newGroups = vm.newGroups || [];
    vm.newGroups.push(group);
    growl.success('New Group Created: ' + group.groupName);
  };

  vm.groupCreateFailed = function(error) {
    growl.error('Unable to create new product line');
    $log.error('Unable to create new product line: ' + error);
  };

  vm.addFacility = function() {
    $uibModal.open({
      component: 'cfAddFacility',
      backdrop: 'static',
      resolve: {
        orgId: () => this.company.$id,
        facility: () => organizations.$pushFacility(this.company.$id)
      }
    }).result
      .then((newFacility) => {
        vm.product.facilityId = newFacility.$id;
        vm.questionnaireForm.$setDirty();
        growl.success('Facility added successfully. When convenient, ' +
          'go to the company tab to enter facility details.');
      })
      .catch(function(reason) {
        if (utils.isModalDismissalByUser(reason)) { return; }

        growl.error('Error adding the new facility. Please ' +
          'try again or contact FoodReady customer support.');
        $log.error(reason);
      });
  };
};
