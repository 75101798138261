module.exports = function(ngModule) {
  ngModule.factory('stepIngredients', function(fbutil, $q, $log, supplierIngredientService) {
    'ngInject';

    function lookupIngredients(processStep) {
      return $q.all(_.mapValues(processStep.ingredients, function(val, ingredientId) {
        return fbutil.ref('ingredients', ingredientId).once('value')
          .then(function(snap) {
            if (!snap.exists()) {
              $log.error('Corrupt ingredient pointer found in step: ' + processStep.$ref().toString());
              return;
            }

            let ingredient = snap.val();

            return supplierIngredientService.getAll(ingredientId)
              .then(supplierIngredients => {
                ingredient.supplierIngredients = supplierIngredients;

                return ingredient;
              });
          })
          .catch(err => {
            $log.error('Error occurred reading ingredient ' + ingredientId + '. ' + err);
          });
      }));
    }

    return {
      all: function(processSteps) {
        let stepIngredients = {};

        return $q.all(_.map(processSteps, function(step, stepId) {
          return lookupIngredients(step).then(function(ingredients) {
            stepIngredients[step.$id || stepId] = ingredients;
          });
        }))
          .then(() => stepIngredients);
      },
      step: function(processStep) {
        return lookupIngredients(processStep)
          .then((ingredients) => _.pickBy(ingredients, _.identity));
      }
    };
  });
};
