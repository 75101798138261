module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseArray, $firebaseObject, $http, sopService, products, $q, planTypes) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseArray = $firebaseArray;
      this.$firebaseObject = $firebaseObject;
      this.$http = $http;
      this.sopService = sopService;
      this.products = products;
      this.planTypes = planTypes;
      this.$q = $q;
      this.controls = {};
    }

    $getControl(productId, controlId) {
      return this.$firebaseObject(this.fbutil.ref(`products/${productId}/controls/${controlId}`)).$loaded();
    }

    $pushControl(productId, control) {
      return this.$firebaseObject(this.fbutil.ref(`products/${productId}/controls`).push(control)).$loaded();
    }

    /**
     * Remove the entire control and its supporting SOP.
     * @param {string} productId The product owning the control
     * @param {string} controlId The control to remove
     * @return {*} A promise that resolves when the control is removed.
     */
    $removeControl(productId, controlId) {
      const ref = this.fbutil.ref(`products/${productId}/controls/${controlId}`);

      return ref.once('value').then(controlSnap => {
        if (!controlSnap.exists()) { throw new Error('Cannot find control.'); }
        let control = controlSnap.val();
        const sopRemovalPromise = control.procedure ? this.sopService.remove(control.procedure)
          .then(() => this.products.removeProcedure(productId, controlId, control.procedure)) : this.$q.resolve();

        return this.$q.all([
          sopRemovalPromise,
          ref.remove()
        ]);
      });
    }

    isCritical(control) {
      return control.type !== 'sop' && control.type !== 'other';
    }

    /**
     * Add a single hazard to the control.
     * @param {string} productId The product identifier
     * @param {string} hazardId The hazard to add to the control
     * @param {string} controlId The control identifier
     * @return {*} A promise that is resolved when the hazard is added.
     */
    addHazardToControl(productId, hazardId, controlId) {
      return this.fbutil.ref(`products/${productId}/controls/${controlId}/hazards/${hazardId}`).set(true);
    }

    /**
     * Remove a single hazard from the control. The control controls 1 or more hazards. If after removing the hazard
     * the control is no longer controlling anything, remove the control.
     * @param {string} productId The product identifier
     * @param {string} hazardId The hazard to remove from the control
     * @param {string} controlId The control identifier
     * @return {*} A promise that is resolved to true if the control was deleted.
     */
    removeHazardFromControl(productId, hazardId, controlId) {
      return this.fbutil
        .ref(`products/${productId}/controls/${controlId}/hazards/${hazardId}`).remove()
        .then(() => this.fbutil.ref(`products/${productId}/controls/${controlId}`).once('value'))
        .then(controlSnap => {
          const control = controlSnap.val();

          // If no hazards are left, delete the control and its SOPs
          if (!_.keys(control.hazards).length) {
            return this.$removeControl(productId, controlId).then(() => true);
          }
        });
    }

    /**
     * Does the control require a procedure?
     * @param {string} planTypeId The safety plan type ID
     * @param {object} control The hazard control
     * @returns {boolean} Whether or not the control requires a procedure
     */
    requiresSop(planTypeId, control) {
      return (
        planTypeId === this.planTypes.PC_PLAN.id && (control.type !== 'sop' && control.type !== 'other') ||
        planTypeId === this.planTypes.HACCP_PLAN.id && control.type === 'process'
      );
    }

    removeAllPrerequisites(productId, controlId) {
      return this.fbutil.ref(`products/${productId}/controls/${controlId}/prerequisites`).remove();
    }

    removeProcedure(productId, controlId) {
      return this.fbutil.ref(`products/${productId}/controls/${controlId}/procedure`).remove();
    }

    pushPrerequisite(productId, controlId, sopId) {
      return this.fbutil.ref(`products/${productId}/controls/${controlId}/prerequisites/${sopId}`).set(true);
    }

    removePrerequisite(productId, controlId, sopId) {
      return this.fbutil.ref(`products/${productId}/controls/${controlId}/prerequisites/${sopId}`).remove();
    }

    syncControls(planSopsV1, product) {
      this.controls = {};
      const isHaccp = product.planType === 'haccpPlan';
      const planSops = {};

      for (const key in planSopsV1) {
        if (planSopsV1[key]?.firebase_id) {
          planSops[planSopsV1[key].firebase_id] = planSopsV1[key];
        } else {
          planSops[key] = planSopsV1[key];
        }
      }

      _.map(product.controls, (control, id) => {
        let checklists = null, logSheets = null, tasks = null;

        if (control.procedure && planSops[control.procedure]) {
          if (planSops[control.procedure].metadata && planSops[control.procedure].metadata.checklists) {
            checklists = planSops[control.procedure].metadata.checklists;
          }
          if (planSops[control.procedure].metadata && planSops[control.procedure].metadata.logSheets) {
            logSheets = planSops[control.procedure].metadata.logSheets;
          }
          if (planSops[control.procedure].metadata && planSops[control.procedure].metadata.tasks) {
            _.each(planSops[control.procedure].metadata.tasks, task => task.numTasks = task.length);
            tasks = planSops[control.procedure].metadata.tasks;
          }
          if(!this.controls[control.stepId]) {
            this.controls[control.stepId] = { controls: {},
              stepId: control.stepId,
              stepName: product.processSteps[control.stepId].name,
              stepNumber: product.processSteps[control.stepId].number || '~'};
          }
          this.controls[control.stepId].controls[id] = {
            checklists,
            logSheets,
            tasks,
            controlType: isHaccp && control.type === 'process' ? 'CCP' : control.type
          };
        }
      });
      this.controls = Object.values(this.controls);
      this.controls.sort((a, b) =>{
        if(a.stepNumber > b.stepNumber) {
          return 1;
        }
        return -1;
      });
    }
  }

  ngModule.service('controlsService', Service);
};
