module.exports = function(ngModule) {
  ngModule.factory('fileBucketService', function($firebaseObject, fbutil, $q) {
    'ngInject';

    return {
      get: (fileBucketId) => {
        return fbutil.ref('fileBucket', fileBucketId).once('value')
          .then(snap => snap.exists() ? _.assign(snap.val(), {$id: snap.key}) : null);
      },

      getAllFiles: (organizationId) => {
        return fbutil.ref('fileBucket').orderByChild('organizationId').equalTo(organizationId).once('value')
          .then(snap => {
            let fileBuckets = [];
            snap.forEach(child => {
              fileBuckets.push(_.assign(child.val(), {$id: child.key}));
            });
            return fileBuckets;
          });
      },

      $get: function(fileBucketId) {
        return $firebaseObject(fbutil.ref('fileBucket', fileBucketId)).$loaded();
      },

      $pushFile: function() {
        return $firebaseObject(fbutil.ref('fileBucket').push()).$loaded();
      },

      pushFile: function(file) {
        return fbutil.ref('fileBucket').push(file);
      },

      remove: function(fileId) {
        return fbutil.ref('fileBucket', fileId).remove();
      },

      updateFile: function(file, fileId) {
        delete file.$id;
        return fbutil.ref('fileBucket', fileId).update(file);
      },

      ref: function() {
        return fbutil.ref('fileBucket');
      },

      externalFiles: function(fileBucketMap) {
        return $q.all(_.mapValues(fileBucketMap, (value, fileId) =>
          this.get(fileId))).then((files)=>{
            return files;
          });
      },

      saveFileMap(path, id) {
        let fileMap = {};

        fileMap[id] = true;
        return fbutil.ref(path).update(fileMap);
      },

      removeFileMap(path, id) {
        return fbutil.ref(path, id).remove();
      }
    };
  });
};
