class Controller {
  constructor($log, $timeout, $q, ENV, Upload, imageCropModal, growl, organizations,
              preventDirtyNav, orgTypes, orgPerspectives, states, countries) {
    'ngInject';

    this.$log = $log;
    this.$timeout = $timeout;
    this.$q = $q;
    this.ENV = ENV;
    this.Upload = Upload;
    this.imageCropModal = imageCropModal;
    this.growl = growl;
    this.organizations = organizations;
    this.preventDirtyNav = preventDirtyNav;
    this.orgTypes = orgTypes;
    this.organizations = organizations;
    this.orgPerspectives = orgPerspectives;
    this.states = states();
    this.countries = countries();

    this.USA = 'US';
  }

  $onInit() {
    this.$timeout(() => {
      this.preventDirtyNav(this.companyForm, 'You have unsaved changes to <strong>' +
        (this.company.companyName || 'your company') + '</strong>.', () => this.save());
    }, 0);


    this.company.country = this.company.country || this.USA;
    this.company.mailingCountry = this.company.mailingCountry || this.USA;
    this.isDevMode = this.ENV === 'development';
    this.companyLogo = this.company.logo ?
      `${this.company.logo.url}/${this.company.logo.key}?${new Date().getTime()}` : null;
    this.logoUpdated = false;
    this.originalTypes = _.clone(this.company.types);
  }

  onFileSelect(file) {
    if (!file) { return; }

    this.imageCropModal({
      file: file,
      title: 'Update Company Logo',
      areaType: 'rectangle'
    })
      .then((croppedImage) => {
        this.companyLogo = croppedImage;
        this.logoUpdated = true;
        if (this.companyForm) { this.companyForm.$setDirty(); }
      });
  };

  save() {
    this.saving = true;
    this.$q.all([this.company.$save(), this.uploadLogo(this.companyLogo)])
      .then(() => {
        if (!_.isEqual(this.originalTypes, this.company.types)) {
          // Reset org context to trigger new perspective
          this.user.setOrgContext(this.user.orgContext.id).then(() => this.perspectiveTours());
          this.originalTypes = _.clone(this.company.types);
        }
        this.growl.success('Company updated successfully!');
        if (this.companyForm) { this.companyForm.$setPristine(); }
      })
      .catch((err) => {
        this.$log.error(err);
        this.growl.error('Hmm something went wrong while updating company.');
      })
      .finally(() => this.saving = false);
  }

  uploadLogo(logo) {
    if(this.logoUpdated && logo) {
      return this.organizations.uploadLogo(this.company.$id, this.Upload.dataUrltoBlob(logo, 'logo.png'));
    } else {
      return this.$q.resolve();
    }
  }

  resetState(rec) {
    if (rec.country === this.USA) { rec.state = null; }
  }

  resetMailingState(rec) {
    if (rec.mailingCountry === this.USA) { rec.mailingState = null; }
  }
}

module.exports = Controller;
