module.exports = function(ngModule) {
  ngModule.factory('BatchSearch', function($http, $q, utils) {
    'ngInject';

    const DEFAULT_PAGE_SIZE = 30;

    class BatchSearch {
      constructor(orgId) {
        this.baseUri = 'batches';
        this.orgId = orgId;
        this.maxReached = false;
        this.pageSize = DEFAULT_PAGE_SIZE;
      }

      /**
       * Sort by a property on the procedure entry
       * @param {string} sortBy Sort field
       * @param {string} sortDirection Sort direction
       * @returns {SopSearch} The SopSearch object
       */
      sortBy(sortBy, sortDirection = 'asc') {
        this.sortByValue = sortBy;
        this.sortDirection = sortDirection;

        return this;
      }

      /**
       * Set the max page size of the result set
       * @param {number} size Max page size
       * @returns {SopSearch} The SopSearch object
       */
      size(size) {
        this.pageSize = size;

        return this;
      }

      /**
       * Start from index
       * @param {number} index Max page size
       * @returns {SopSearch} The SopSearch object
       */
      startFrom(index) {
        this.from = index;

        return this;
      }

      /**
       * Limit to this batch number
       * @param {string} batchNumber Batch number
       * @returns {SopSearch} The SopSearch object
       */
      batchNumber(batchNumber) {
        this.batchNumberFilter = batchNumber;

        return this;
      }

      /**
       * Limit to batches with this lot
       * @param {object} lot Lot combo
       * @param {string} lot.lot Lot Number
       * @param {string} lot.ingredientId Ingredient with lot
       * @returns {SopSearch} The SopSearch object
       */
      lot(lot) {
        this.lotFilter = lot;

        return this;
      }

      /**
       * Set the search text (currently will try to match key name)
       * @param {string=} text The search text
       * @returns {Promise} The SopSearch object
       */
      search(text = '') {
        this.from = 0;
        this.maxReached = false;
        this.searching = true;
        this.badSearch = false;

        return this._search(text)
          .finally(() => { this.searching = false; });
      }

      getMore(text) {
        if (this.badSearch) { throw new Error('Failed search...cannot get more'); }
        if (this.maxReached || this.searching) { return; }
        this.from += this.pageSize;

        return this._search(text);
      }

      $loaded() {
        return this.httpPromise;
      }

      _search(text) {
        let queryParmArray = [];

        queryParmArray.push(`orgId=${this.orgId}`);
        if (this.from) { queryParmArray.push(`from=${this.from}`); }
        if (this.pageSize) { queryParmArray.push(`size=${this.pageSize}`); }
        if (this.sortByValue) { queryParmArray.push(`sortBy=${this.sortByValue}`); }
        if (this.sortDirection) { queryParmArray.push(`sortDirection=${this.sortDirection}`); }
        if (this.batchNumberFilter) { queryParmArray.push(`batchNumber=${this.batchNumberFilter}`); }
        if (this.lotFilter) {
          let filterArray = [
            this.lotFilter.ingredientId,
            this.lotFilter.lot
          ];

          if (this.lotFilter.supplierIngredientId) {
            filterArray.push(this.lotFilter.supplierIngredientId);
          }
          queryParmArray.push(
            `lot=${filterArray.join(',')}`);
        }

        if (text) { queryParmArray.push(`searchText=${text}`); }

        this.searching = true;
        this.httpPromise = $http.get(`${this.baseUri}${utils.toQueryParm(queryParmArray)}`)
          .then(result => {
            if (result.status === 204) {
              this.maxReached = true;
              return [];
            }

            let recs = result.data.batches;

            if (recs.length < this.pageSize) {
              this.maxReached = true;
            }

            return recs;
          })
          .catch(err => {
            this.badSearch = true;

            return $q.reject(err);
          })
          .finally(() => this.searching = false);

        return this.httpPromise;
      }
    }

    return BatchSearch;
  });
};
