require('./tests.main.template.scss');

module.exports = function(ngModule) {
  ngModule.component('frTests', {
    bindings: {
      isModal: '<?',
      onClose: '&'
    },
    template: require('./tests.main.template.html'),
    controller: require('./tests.main.controller'),
    controllerAs: 'vm'
  });
};
