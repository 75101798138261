'use strict';

module.exports = function ($scope, $uibModal, confirmDeleteModal, fbutil, growl) {
  //noinspection JSLint
  'ngInject';
  
  $scope.remove = function (team) {
    confirmDeleteModal(team.name).then(function () {
      fbutil.$firebaseObjectExt(fbutil.ref('teams', team.$id)).$loaded().then(function (teamObj) {
        teamObj.softDelete().then(function () {
          growl.success('Team deleted');
        }).catch(function (err) {
          console.log(err);
          growl.error('Unable to delete team');
        });
      });
    });
  };

  $scope.createTeamModal = function () {
    var modalInstance = $uibModal.open({
      template: require('../../_modals/administration.create-team.template.html'),
      controller: function ($scope, $uibModalInstance) {
        $scope.ok = function () {
          $uibModalInstance.close($scope.team);
        };
        $scope.cancel = function () {
          $uibModalInstance.dismiss();
        };
      }
    });

    modalInstance.result.then(function (team) {
      if (team) {
        $scope.teams.$upsert(team);
      }
    });
  };
};