module.exports = function(ngModule) {
  ngModule.component('cfEditTeamOrgs', {
    bindings: {
      user: '<',
      organizations: '=',
      team: '=',
      teams: '='
    },
    template: require('./administration.edit-team-orgs.template.html'),
    controller: require('./administration.edit-team-orgs.controller'),
    controllerAs: 'vm'
  });
};