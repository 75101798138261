class Controller {
  constructor($timeout, utils, projectChecklist, preventDirtyNav, growl, $stateParams, $window,
              $state, users, confirmModal, constantsService, notifications) {
    'ngInject';

    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.growl = growl;
    this.users = users;
    this.utils = utils;
    this.confirmModal = confirmModal;
    this.projectChecklist = projectChecklist;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.constantsService = constantsService;
    this.notifications = notifications;
  }

  $onInit() {
    // this.$timeout(() => this.preventDirtyNav(this.pwViewForm, 'You have unsaved Project changes.', () => this.save()));
    if (this.project) {
      if (this.project.checklist) {
        this.items = this.project.checklist;
      }      else{
        this.items = this.projectChecklist;
      }

      if (this.project.projectEndedBy) {
        this.isEnded = true;
        this.users.getName(this.project.projectEndedBy)
          .then((name) => this.projectEndedBy = name);
      }
      if (this.project.updatedBy) {
        this.users.getName(this.project.updatedBy)
          .then((name) => this.updatedBy = name);
      }
      if (this.project.createdBy) {
        this.users.getName(this.project.createdBy)
          .then((name) => this.createdBy = name);
      }
      this.fileUploadNotify = false;

      this.remoteDirectory = 'projects/' + this.project.$id + '/files';
      this.constantsService.get('projectFileType').then(fileTypes => {
        this.categories = _.omitBy(fileTypes, fc => fc.companyLevel);
      });
    }
  }

  sendNotifications(orgsInfo, usersInfo) {
    _.each(Object.keys(orgsInfo), orgId => {
      _.each(Object.keys(usersInfo[orgId]), uid => {
        this.notifications.postToUser({
          from: this.user.uid,
          to: uid,
          message: 'New File has been uploaded in <b>"' + this.project.title + '"</b> project by ' + `${this.user.firstName || ''} ${this.user.lastName || ''}`.trim() + ' (' + this.user.orgContext.companyName + ')',
          link: {
            state: 'projectwriting.messages',
            params: {
              projectId: this.project.$id
            }
          }
        });
      });
    });
  }

  endProject() {
    this.ending = true;
    this.confirmModal({
      title: '<span class="far fa-thermometer fa-fw"></span> End Project',
      okText: 'End Project',
      cancelText: 'Cancel',
      body: 'Are you sure you want to End Project <strong>' + this.project.title + '</strong>?'
    }).then(() => {
      this.project.projectEndedOn = firebase.database.ServerValue.TIMESTAMP;
      this.project.projectEndedBy = this.user.uid;
      this.growl.success('Project Ended');
      this.save();
    })
      .finally(()=>{
        this.ending = false;
      });
  }

  _save() {
    this.saving = true;
    this.project.updatedOn = firebase.database.ServerValue.TIMESTAMP;
    this.project.updatedBy = this.user.uid;
    this.project.checklist = this.items;
    this.save();
  }

  save() {
    this.project.$save()
      .then(() => {
        this.growl.success('Project details saved successfully.');

        if (this.fileUploadNotify) {    // sending notifications to all Providers after file upload.
          this.sendNotifications(this.project.providerOrgs, this.project.providerUsers);
          this.fileUploadNotify = false;
        }
      })
      .catch((err) => {
        this.$log.error('Unable to save procedure.', this.$log.toString(err));
        this.growl.error('Unable to save procedure. Please try again or contact customer support for assistance.');
      })
      .finally(() => this.saving = false);
  }

  onNewFile($file) {
    this.project.files = this.project.files || {};
    this.project.files[$file.$id] = true;
    let newFile = _.assign({},$file);

    this.files.push(newFile);
    this.fileUploadNotify = true;
    this.save();
  }

  onRemoveFile(removedFile) {
    delete this.project.files[removedFile.$id];
    this.files = this.files.filter((file)=>file.$id !== removedFile.$id);
    this.save();
  }

  cancel() {
    if (this.pwViewForm) {
      this.pwViewForm.$setPristine();
    }
    this.close();
  }

  close() {
    this.$state.go('projectwriting.list');
  }
}

module.exports = Controller;
