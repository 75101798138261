module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider.state('onboarding', {
      abstract: true,
      url: '/onboarding?orgType?serviceId?template?productCategory?productId',
      template: '<div class="onboarding-container content-xs h-100"><ui-view/></div>',
      resolve: {
        company: (user, organizations) => organizations.$get(user.organizationId),
        user: (authentication) => authentication.requireAuth(),
        foodCategories: (productCategories) => productCategories.getAll(),
        foodCategoriesMap: (foodCategories) => _.keyBy(foodCategories, 'id')
      }
    })
    //   .state('onboarding.company', {
    //   url: '',
    //   template: '<cf-onboarding-company user="$resolve.user" company="$resolve.company" ' +
    //     '$user="$resolve.$user"></cf-onboarding-company>',
    //   resolve: {
    //     $user: (users, user) => users.$get(user.uid)
    //   }
    // })
      .state('onboarding.firstPlan', {
        url: '',
        template: '<cf-onboarding-first-plan user="$resolve.user" company="$resolve.company" ' +
          '$user="$resolve.$user" food-categories="$resolve.foodCategories">' +
        '</cf-onboarding-first-plan>',
        resolve: {
          $user: (users, user) => users.$get(user.uid)
        }
      }).state('onboarding.editPlan', {
        url: '/editPlan',
        template: '<div ui-view class="h-100"></div>',
        resolve: {
          $product: ($stateParams, products) => products.$get($stateParams.productId),
          productCategory: (foodCategoriesMap, $product) => $product.categoryId ?
            foodCategoriesMap[$product.categoryId] : null,
          productSubCategory: (foodCategoriesMap, $product) =>
            $product.subCategoryId ?
                foodCategoriesMap[$product.categoryId].subCategories[$product.subCategoryId] : null,
        }
      }).state('onboarding.editPlan.assignTemplate', {
        url: '/assignTemplate',
        template: '<cf-onboarding-assign-template user="$resolve.user" company="$resolve.company" ' +
        '$product="$resolve.$product" template="$resolve.template" product-category="$resolve.productCategory" ' +
          'product-sub-category="$resolve.productSubCategory"></cf-onboarding-assign-template>',
        resolve: {
          template: ($state, products, SAMPLE_ORGANIZATION_ID, productCategory, productSubCategory, $stateParams) => {
            const templateId = $state.params.template || _.get(productSubCategory, 'template') ||
              _.get(productCategory, 'template');

            if (!templateId) {
              return $state.go('onboarding.editPlan.pickHazards', $stateParams);
            }
            return products.getPlanTemplate(SAMPLE_ORGANIZATION_ID, templateId);
          }
        }
      }).state('onboarding.editPlan.pickHazards', {
        url: '/pickHazards',
        template: '<cf-onboarding-pick-hazards user="$resolve.user" company="$resolve.company" ' +
        '$product="$resolve.$product" product-category="$resolve.productCategory" ' +
          'product-sub-category="$resolve.productSubCategory" typical-hazards="$resolve.typicalHazards">' +
          '</cf-onboarding-pick-hazards>',
        resolve: {
          typicalHazards: (hazardsService, productCategory, productSubCategory, $state, $stateParams) => {
            const typicalHazards = _.get(productSubCategory, 'hazards') ||
              _.get(productCategory, 'hazards');

          // If no typical hazards for their product category, don't visit this onboarding step.
            if (!typicalHazards) {
              $state.go('onboarding.needs', $stateParams);
            }

            return hazardsService.getHazardLibrary().then(lib => {
              return _.map(typicalHazards, (val, id) => lib[id]);
            });
          }
        }
      }).state('onboarding.needs', {
        url: '/needs',
        template: '<cf-onboarding-needs user="$resolve.user" company="$resolve.company"></cf-onboarding-needs>'
      }).state('onboarding.questionnaire', {
        url: '/questionnaire',
        template: '<cf-onboarding-questionnaire user="$resolve.user" company="$resolve.company" ' +
          'onboarding="$resolve.onboarding"></cf-onboarding-questionnaire>',
        resolve: {
          onboarding: (constantsService) => constantsService.get('onboarding')
        }
      });
  });
};
