module.exports = function(ngModule) {
  ngModule.component('cfSupplierFileTracking', {
    bindings: {
      user: '<',
      $tracking: '<'
    },
    template: require('./ingredients.file-tracking.template.html'),
    controller: require('./ingredients.file-tracking.controller'),
    controllerAs: 'vm'
  });
};