module.exports = function(ngModule) {
  require('./ccp')(ngModule);
  require('./pass_fail')(ngModule);
  require('./thermometer_calibration')(ngModule);
  require('./ph_calibration')(ngModule);
  require('./allergen_label')(ngModule);
  require('./curing_chamber')(ngModule);
  require('./water_activity')(ngModule);
  require('./employee_training')(ngModule);
  require('./employee_illness')(ngModule);
  require('./temperature')(ngModule);
  require('./equipment_cleaning')(ngModule);
  require('./thermometer_validation')(ngModule);
  require('./grinding_blade')(ngModule);
  require('./transporting_product')(ngModule);
  require('./cold_chain')(ngModule);
  require('./fish_receiving')(ngModule);
  require('./anti_microbial')(ngModule);
};
