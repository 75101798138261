module.exports = function (ngModule) {
  class ReportsService {
    constructor(
      $window,
      $http,
      $log,
      $uibModal,
      organizations,
      growl,
      utils,
      authorization,
      companySubscription,
      sopService,
      constantsService,
      $q
    ) {
      'ngInject';

      this.$window = $window;
      this.$http = $http;
      this.$log = $log;
      this.$uibModal = $uibModal;
      this.growl = growl;
      this.utils = utils;
      this.organizations = organizations;
      this.authorization = authorization;
      this.companySubscription = companySubscription;
      this.sopService = sopService;
      this.$q = $q;

      this.logSheetsPromise = constantsService.get('logSheets');
    }

    isNoReportCache() {
      const NO_REPORT_CACHE_KEY = 'noReportCache';

      return (
        this.$window.localStorage && _.lowerCase(this.$window.localStorage.getItem(NO_REPORT_CACHE_KEY)) === 'true'
      );
    }

    fetchCompanyReport(user, $company, reportType, params) {
      let queryParams = _.assign({format: 'pdf'}, params);

      let reportWindow = this.$window.open('/api/reports/loading?format=' + queryParams.format);

      // TODO: we need to update this to use new claim scheme. Also need to create an authorization method to get user claims on an org.
      if (reportType === 'gmpCompliance' || reportType === 'procedure') {
        if (user.onPrimaryOrg()) {
          // If on primary org, the gmpCompliance report is a report off ALL orgs the user belongs to.
          let orgs = [];

          _.each(_.get(user, 'access.reviews.organizations'), (access, orgId) => {
            if (user.access.cfAdmin || access.role === 'admin') {
              orgs.push(orgId);
            }
          });

          queryParams.organizationId = orgs.join(',');
        } else {
          // If NOT on primary org, then return just the org they are logged into.
          queryParams.organizationId = user.orgContext.id;
        }
      }

      if (this.isNoReportCache()) {
        queryParams.noReportCache = true;
      }

      const reportUrl = `/reports/${$company.$id}/${reportType}${this.utils.jsonToQueryString(queryParams)}`;

      reportWindow.onload = () => {
        this.$http
          .get(reportUrl)
          .then(async (report) => {
            if (queryParams.format === 'html') {
              reportWindow.document.getElementById('loading').innerHTML = report.data;
            } else {
              const fileUrl = await this.utils.pdfGenerator(report);

              reportWindow.location.replace(fileUrl);
            }
          })
          .catch((err) => {
            this.$log.error(err);
            reportWindow.close();
            this.growl.error(
              'Unable to retrieve the report due to a system error. ' +
                'Please try again or contact support for assistance.'
            );
          });
      };
    }

    /**
     * Fetch a plan report. This method ensures the plan is purchased.
     * @param {object} user The logged in user
     * @param {object} $product The product as a $firebaseObject
     * @param {string} reportType The report type
     * @return {*} A promise that resolves when the plan is created
     */
    fetchPlanReport(user, $product, reportType) {
      const onPayAsGo = user.onPayAsGoPlan() && !user.hasPermission(this.authorization.claims.CF_ADMIN);
      let options =
        onPayAsGo && (reportType === 'preventiveControlsFull' || reportType === 'haccpFull') ? {noLogs: true} : {};

      return this._fetchPlanReport(user, $product, reportType, options);
    }

    checkSubsciption(user, $product) {
      const onPayAsGo = user.onPayAsGoPlan() && !user.isCfAdmin();

      if (onPayAsGo && !$product.chargeId && !$product.unlockedBy) {
        return this._promptForPlanOptions(user, $product).catch((reason) => {
          if (this.utils.isModalDismissalByUser(reason)) {
            return;
          }

          this.$log.error('An error occurred purchasing a plan', this.$log.toString(reason));
          this.growl.error(
            'An error occurred purchasing your plan. If this continues, please contact customer support.'
          );
        });
      }

      if (!onPayAsGo && user.isTrialing() && !user.isCfAdmin() && !$product.chargeId && !$product.unlockedBy) {
        // Have the user activate their trial. We can't continue on with the report generation due to pop-up blockers.
        return this.$uibModal
          .open({
            component: 'frPricingPage',
            size: 'xl',
            backdrop: 'static',
          })
          .result.then((result) => {
            if (result === 'modalDismissed') {
              return this.$q.reject('cancel');
            }
          });
      }
      return false;
    }

    downloadPlan(user, $product, url) {
      const openSubscribeModal = this.checkSubsciption(user, $product);

      if (!openSubscribeModal) {
        return this.$window.open(url);
      }
    }

    htmlToPdf(html) {
      return this.$http.post('/reports/htmlToPdf', {htmlBase64: this._toBase64(html)});
    }

    _toBase64(html) {
      const buff = new Buffer(html);

      return buff.toString('base64');
    }

    _promptForPurchase(user, $product) {
      return this.$uibModal
        .open({
          component: 'cfPurchasePlan',
          size: 'lg',
          resolve: {
            product: () => $product,
            user: () => user,
          },
        })
        .result.then((chargeId) => {
          this.growl.success('Your payment was received. Reports can now be viewed.');
          $product.chargeId = chargeId;
          return $product.$save();
        });
    }

    _promptForPlanOptions(user, $product) {
      return this.companySubscription.showPlanOptions(user, $product);
    }

    _fetchPlanReport(user, product, reportType, options) {
      return this.$uibModal
        .open({
          component: 'cfPrintPlanModal',
          backdrop: 'static',
          resolve: {
            sops: this.sopService.query(product.organizationId, null, {isFacilitySop: true}),
            planSopIds: () =>
              _.reduce(
                product.controls,
                (sops, control) => {
                  if (control.type !== 'sop') {
                    return sops;
                  }
                  return _.uniq(_.concat(sops, _.keys(control.prerequisites)));
                },
                []
              ),
            user: () => user,
            planTypeText: () => product.planType === 'haccpPlan' ? 'HACCP' : 'Food Safety',
            defaultState: () =>
              this.organizations
                .getProfile(product.organizationId)
                .then((orgProfile) => orgProfile.state || orgProfile.mailingState),
          },
        })
        .result.then(async (result) => {
          const openSubscribeModal = this.checkSubsciption(user, product);

          if (!openSubscribeModal) {
            const reportWindow = this.$window.open('/api/reports/loading');

            options = _.assign(options || {}, result);
            options.format = 'pdf';
            options.planId = product.$id;

            let reportUrl = `/reports/${product.organizationId}/${reportType}`;

            reportUrl = reportUrl + this.utils.jsonToQueryString(options);
            this.generatingReport = true;
            await this.$http.get(reportUrl)
              .then(async (response) => {
                this.generatingReport = false;
                const url = await this.utils.pdfGenerator(response);

                reportWindow.location.replace(url);
              })
              .catch((err)=>{
                this.isError = true;
                this.$log.error(err);
              });
          }
        })
        .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to select regulation authority.'));
    }
  }

  ngModule.service('reportsService', ReportsService);
};
