module.exports = function(ngModule) {
  ngModule.constant('inequalities', function() {
    return [
      {name: 'Greater than', value: 'GT'},
      {name: 'Greater than or equal to', value: 'GTE'},
      {name: 'Equal to', value: 'E'},
      {name: 'Less than', value: 'LT'},
      {name: 'Less than or equal to', value: 'LTE'},
    ];
  });
};
