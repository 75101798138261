class CfSaveBtn {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-save-btn.template.html');
    this.replace = true;
    this.bindToController = {
      label: '@?',
      savingLabel: '@?',
      btnClass: '@?',   // ex. 'btn-u btn btn-md u-btn-primary'
      iconClass: '@?',  // ex. 'far fa-check'
      iconAlign: '@?',  // ex. 'right', 'left'
      saving: '=',
      isDisabled: '=?',
      click: '&onClick'
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';

    this.label = this.label || 'Save';
    this.savingLabel = this.savingLabel || 'Saving...';
    this.iconClass = this.iconClass || 'far fa-save';
    this.iconAlign = this.iconAlign ? this.iconAlign.toLowerCase() : 'left';
  }
}

module.exports = CfSaveBtn;
