module.exports = function(ngModule) {
  ngModule.component('cfReports', {
    bindings: {
      user: '<',
      $company: '<'
    },
    template: require('./reports.main.template.html'),
    controller: require('./reports.main.controller'),
    controllerAs: 'vm'
  });
};
