module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceLetter', {
    bindings: {
      isProvider: '<',
      user: '<',
      $letter: '=',
      $orderItem: '='
    },
    template: require('./marketplace.letter.template.html'),
    controller: require('./marketplace.letter.controller.js'),
    controllerAs: 'vm'
  });
};
