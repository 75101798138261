require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductHazards', {
    bindings: {
      product: '=',
      $hazards: '=',
      user: '<',
      lastSaved: '=',
      facilitySops: '<',
      isReadOnly: '<',
      selectedHazard: '=',
      onEditHazard: '&?',
      onRemoveHazard: '&?',
      onEditControl: '&?',
      onAddExistingControl: '&?'
    },
    template: require('./products.edit.hazards.template.html'),
    controller: require('./products.edit.hazards.controller'),
    controllerAs: 'vm'
  });

  require('./edit')(ngModule);
};
