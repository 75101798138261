'use strict';

module.exports = function (ngModule) {

  // Add new menu object by menu id
  ngModule.service('productStatus', function () {
    //noinspection JSLint
    'ngInject';

    return function (status) {
      // Return the menu object
      var label = {};

      switch (status) {
      case "Complete":
        label.status = "Complete";
        label.label_status = "label label-u";
        break;
      case "Completed but Unpaid":
        label.status = "Completed but Unpaid";
        label.label_status = "label rounded label-dark";
        break;
      case "Review Requested":
        label.status = "Review Requested";
        label.label_status = "label rounded label-yellow";
        break;
      case "No Review Requested":
        label.status = "No Review Requested";
        label.label_status = 'label rounded label-brown';
        break;
      case "Review In Progress":
        label.status = "Review In Progress";
        label.label_status = 'label rounded label-aqua';
        break;
      case "Changes Requested":
        label.status = "Changes Requested";
        label.label_status = 'label rounded label-orange';
        break;
      case "Published":
        label.status = "Published";
        label.label_status = 'label rounded label-dark-blue';
        break;
      case "Pre-Published":
        label.status = "Pre-Published";
        label.label_status = 'label rounded label-sea';
        break;
      case "Urgent Review Request":
        label.status = "Urgent Review Request";
        label.label_status = 'label rounded label-red';
        break;
      default:
        label.status = "No Review Requested";
        label.label_status = 'label rounded label-brown';
      }
      return label;
    };
  });


};