require('./_css');

module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.events',
    ngModule = angular.module(moduleName, []);

  require('./_components')(ngModule);
  require('./_config')(ngModule);
  require('./_services')(ngModule);

  return moduleName;
};
