module.exports = function(ngModule) {
  require('./employee_train')(ngModule);
  require('./pass_fail')(ngModule);
  require('./equipment_clean')(ngModule);
  require('./ph_calibration')(ngModule);
  require('./fridge_freezer_temp')(ngModule);
  require('./thermometer_calibration')(ngModule);
  require('./thermometer_validation')(ngModule);
  require('./grinding_blade')(ngModule);
  require('./transporting_product')(ngModule);
  require('./cold_chain')(ngModule);
  require('./fish_receiving')(ngModule);
  require('./anti_microbial')(ngModule);
};
