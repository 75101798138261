module.exports = function(ngModule) {
  class FacilityService {
    constructor($firebaseArray, $firebaseObject, fbutil) {
      'ngInject';

      this.$firebaseArray = $firebaseArray;
      this.$firebaseObject = $firebaseObject;
      this.fbutil = fbutil;
    }

    /**
     * Return a facility object.
     * @param {string} orgId Organization Id.
     * @param {string} facilityId Facility Id.
     * @return {Promise} A promise that resolves to a FirebaseObject of the facility.
     */
    $get(orgId, facilityId) {
      return this.$firebaseObject(this.fbutil.ref('organizations', orgId, 'facilities', facilityId)).$loaded();
    }

    /**
     * Return a FirebaseArray of facilities for an org.
     * @param {string} orgId Organization Id.
     * @return {Promise} A promise that resolves to a Firebasearray of all facilities.
     */
    $getAll(orgId) {
      return this.$firebaseArray(this.fbutil.ref('organizations', orgId, 'facilities')).$loaded();
    }

    /**
     * Return the first facility object for an org.
     * @param {string} orgId Organization Id.
     * @return {Promise} A promise that resolves to a FirebaseObject of the facility or null if none found.
     */
    $getFirst(orgId) {
      return this.fbutil.ref('organizations', orgId, 'facilities').once('value').then(facilitiesSnap => {
        if (!facilitiesSnap.exists()) { return null; }
        return _.first(_.keys(facilitiesSnap.val()));
      }).then(firstKey => this.$get(orgId, firstKey));
    }
  }

  ngModule.service('facilityService', FacilityService);
};
