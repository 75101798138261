module.exports = function(ngModule) {
  ngModule.config(
    function($stateProvider) {
      'ngInject';

      $stateProvider.state('recall.plan', {
        url: '/plan',
        abstract: true,
        template: '<cf-recall-plan user="$resolve.user"></cf-recall-plan>',
        resolve: {
          recall: (user, recallService) => recallService.$get(user.orgContext.id)
        }
      }).state('recall.plan.team', {
        url: '/team',
        template: '<cf-recall-plan-team $recall="$resolve.recall" members="$resolve.orgMembers" ' +
          'user="$resolve.user" company="$resolve.company" recall-roles="$resolve.recallRoles"' +
          'recall-responsibilities-template="$resolve.recallResponsibilitiesTemplate"></cf-recall-plan-team>',
        resolve: {
          orgMembers: function(company, $q, users, fbutil) {
            return $q.all(_.map(company.members, function(val, uid) {
              return $q.all([users.getName(uid), fbutil.ref('users', uid, 'phone').once('value')])
                .then((info) => ({$id: uid, name: info[0], phone: info[1].val() || ''}));
            }));
          },
          recallRoles: (constantsService) => constantsService.get('recallRoles').then(r => _.values(r)),
          recallResponsibilitiesTemplate: (constantsService) => constantsService.get('recallResponsibilitiesTemplate')
        },
        data: {
          titleBar: {
            helpModal: require('../_modals/help/recall-team-help.view.html')
          }
        }
      }).state('recall.plan.problemReports', {
        url: '/problemReports',
        template: '<cf-problem-reports recall="$resolve.recall" problem-reports="$resolve.problemReports" ' +
        'user="$resolve.user"></cf-problem-reports>',
        resolve: {
          problemReports: (company, recallService) => recallService.$problemsReport(company.$id)
        },
        data: {
          titleBar: {
            //helpModal: require('../_modals/help/problem-reports-help.view.html')
          }
        }
      }).state('recall.plan.consigneeInstructions', {
        url: '/consigneeInstructions',
        template: '<cf-recall-plan-consignee-instructions recall="$resolve.recall" ' +
        'user="$resolve.user" $letter-template="$resolve.$letterTemplate" ' +
          '$phone-script="$resolve.$phoneScript" ' +
          '$customer-instructions="$resolve.$customerInstructions"></cf-recall-plan-consignee-instructions>',
        resolve: {
          $letterTemplate: (recallService, company) => recallService.$getLetterTemplate(company.$id),
          $phoneScript: (recallService, company) => recallService.$getPhoneScript(company.$id),
          $customerInstructions: (recallService, company) => recallService.$getCustomerInstructions(company.$id)
        }
      }).state('recall.plan.distributionPattern', {
        url: '/distributionPattern',
        template: '<cf-recall-distribution-pattern recall="$resolve.recall" ' +
        'user="$resolve.user"></cf-recall-distribution-pattern>',
        data: {
          titleBar: {
            helpModal: require('../_modals/help/distribution-pattern-help.view.html')
          }
        }
      }).state('recall.plan.destruction', {
        url: '/destruction',
        template: '<cf-recall-plan-destruction recall="$resolve.recall"></cf-recall-plan-destruction>'
      }).state('recall.plan.classType', {
        url: '/classType',
        template: '<cf-recall-plan-class-type recall="$resolve.recall"></cf-recall-plan-class-type>'
      }).state('recall.plan.lotNumbers', {
        url: '/upcLotNumbers',
        template: '<cf-lot-numbers recall="$resolve.recall" ></cf-lot-numbers>',
        controllerAs: 'vm',
        data: {
          titleBar: {
            helpModal: require('../_modals/help/lot-numbers-help.view.html')
          }
        }
      }).state('recall.plan.volume', {
        url: '/volume',
        template: '<cf-recall-volume recall="$resolve.recall" ></cf-recall-volume>',
        controllerAs: 'vm',
        data: {
          titleBar: {
            //helpModal: require('../_modals/help/volume-help.view.html')
          }
        }
      });
    }
  );
};
