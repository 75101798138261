class Controller {
  constructor(preventDirtyNav, $state, growl, $log, operationalChecklistService, S3, $uibModal, confirmModal, users,) {
    'ngInject';

    this.preventDirtyNav = preventDirtyNav;
    this.$state = $state;
    this.growl = growl;
    this.$log = $log;
    this.operationalChecklistService = operationalChecklistService;
    this.temperatureUnits = [
      {name: 'F', value: 'F'},
      {name: 'C', value: 'C'},
    ];
    this.S3 = S3;
    this.$uibModal = $uibModal;
    this.confirmModal = confirmModal;
    this.users = users;
  }

  $onInit() {
    if (!this.checklist) {
      this.$state.go('checklists.operational.list');
    } else {
      let categories = this.checklist.categories;

      this.sections = [];
      this.checklist.answers = this.checklist.answers || [];

      if(categories) {
        categories.forEach((category)=>{
          this.sections.push({category, questions: {}, answers: {}});
        });

        this.checklist.questions.forEach((question)=>{
          if(question.category >= 0) {
            this.sections[question.category].questions[question.questionId] = question;
          } else {
            this.sections[categories.length - 1].questions[question.questionId] = question;
          }
        });
        this.checklist.answers.forEach((answerDetails)=> {
          if(answerDetails.category >= 0)  {
            this.sections[answerDetails.category].answers[answerDetails.questionId] = answerDetails;
          }
        });
      } else {
        this.sections.push({questions: this.checklist.questions, answers: {}});
        this.checklist.answers.forEach((answerDetails)=> {
          this.sections[0].answers[answerDetails.questionId] = answerDetails;
        });

      }

      if(!this.checklist.checklistId) {
        this.save();
      }
    }
    if (this.checklist.updatedBy) {
      this.users.getName(this.checklist.updatedBy)
        .then((name) => this.updatedBy = name);
    } else if (this.checklist.createdBy) {
      this.users.getName(this.checklist.createdBy)
        .then((name) => this.createdBy = name);
    }
  }

  cancel() {
    if (this.checklistForm) {
      this.checklistForm.$setPristine();
    }
    return this.$state.go('checklists.operational.list');
  }

  save() {
    let answers = [];
    let answerType;

    this.sections.forEach((section)=>{

      Object.keys(section.answers).forEach((questionId)=>{
        if(section.questions[questionId].forms.customType) {
          answerType = section.questions[questionId].forms.customType;
        } else {
          answerType = section.questions[questionId].forms.type;
        }
        let category = section.questions[questionId].category >= 0 ?
          section.questions[questionId].category : section.questions[questionId].category;

        answers.push({
          category,
          questionId: Number(questionId),
          details: section.answers[questionId].details,
          type: answerType
        });
      });
    });

    this.checklist.answers = answers;
    if(this.checklist.checklistId) {
      delete this.checklist.questions;
      this.operationalChecklistService
        .updateChecklist(this.user.orgContext.id, this.checklist.checklistId, this.checklist)
        .then(()=> {
          this.growl.success('Checklist Updated');
        });

    } else {
      this.operationalChecklistService.addChecklist(this.user.orgContext.id, this.checklist).then((response)=>{
        this.growl.success('Checklist Saved');
        this.checklist.checklistId = response.id;
      });
    }
  }

  addFiles(file, question, section) {
    if(this.checklist.checklistId  && file && this.user.orgContext.id) {
      let remoteDirectory = 'operationalChecklists/' + this.checklist.checklistId + '/' + question.questionId + '/files';
      let fileExtension = file.name.split('.').pop();
      let sameFileNameCount = 0;
      let fileName = file.name + '.' + fileExtension;
      if(section[question.category]) {
        if(section.answers[question.questionId].details.files) {
          _.each(section.answers[question.questionId].details.files, (f) => {
            let s3FileName = f.key.split('/').pop();

            if (s3FileName.startsWith(fileName)) {
              sameFileNameCount += 1;
            }
          });

          if (sameFileNameCount > 0) {
            fileName += sameFileNameCount.toString();
          }
        }
      }

      return this.S3
        .uploadOrgFile(this.user.orgContext.id, remoteDirectory + '/' + fileName, file)
        .then((result) => {
          if(!section.answers[question.questionId]) {
            section.answers[question.questionId] = {
              details: {files: []}
            };
          }
          if(!section.answers[question.questionId].details) {
            section.answers[question.questionId].details = {
              files: []
            };
          }
          if(!section.answers[question.questionId].details.files) {
            section.answers[question.questionId].details.files = [];
          }
          section.answers[question.questionId].details.files.push({
            createdOn: new Date().getTime(),
            key: result.key,
            name: result.name,
            type: result.type,
            url: result.url,
          });
          this.save();
          return result;
        });
    }
  }

  showFiles(files, question, section) {
    if(files) {
      this.$uibModal.open({
        component: 'fileViewModal',
        backdrop: 'static',
        resolve: {
          itemName: () => 'Image',
          multiple: () => true,
          header: () => '<i class="far fa-images fa-fw"></i> List of Images',
          itemsArray: () => files,
          organization: () => this.user.orgContext,
          columns: () => [
            {title: 'Name', property: 'name'}
          ]
        }
      }).result
        .then(result =>{
          section.answers[question.questionId].details.files = result;
          this.save();
        });
    }
  }

  showInstructions(description) {
    this.confirmModal({
      title: 'Instructions',
      body: description,
      okText: 'Ok',
      hideCancelButton: true
    });
  }
}

module.exports = Controller;
