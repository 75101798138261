class Controller {
  constructor($q, $timeout, growl, $log) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this.growl = growl;
    this.$log = $log;
  }

  $onInit() {
    this.title = this.resolve.title;
    this.instructionsHtml = this.resolve.instructionsHtml;
    this.link = this.resolve.link;
    this.emailLinkFn = this.resolve.emailLinkFn;

    this.name = _.get(this.resolve, 'contact.name', '');
    this.email = _.get(this.resolve, 'contact.email', '');

    if (this.link === null) {
      // If link is null, the link generating function handled the error and returned null.
      this.modalInstance.dismiss('cancel');
    } else if (!this.link) {
      this.modalInstance.dismiss('Link is not defined.');
    }

    this.step = 1;
  }

  copyLink() {
    // eslint-disable-next-line angular/document-service
    const copyText = document.querySelectorAll('.create-link-modal .cf-input-group input');
    const inputBox = _.first(copyText);

    inputBox.select();
    // eslint-disable-next-line angular/document-service
    document.execCommand('copy');
    inputBox.selectionEnd = 0;
    this.copied = true;
    this.$timeout(() => {
      this.copied = false;
    }, 3000);
  }

  send() {
    const contact = {
      contactEmail: this.email,
      contactName: this.name,
      inviteNotes: null,
    };

    this.emailLinkFn(contact, this.link).then(() => {
      this.growl.success(`Email sent to ${this.email}.`);
      this.modalInstance.close();
    });
  }

  createNewUserLink() {
    this.creating = true;
    const contact = {
      contactEmail: this.email,
      contactName: this.name,
      inviteNotes: null,
    };
    const inviteOptions = {
      assumeOwnership: true,
      newPlan: this.stripeConstants.defaultFreePlan,
      skipEmail: true,
    };

    this.orgInteractionService
      .inviteNewUser(
        this.user,
        this.organization.$id,
        this.CF_ROLES.ADMIN,
        this.EMAIL_TEMPLATE,
        null,
        inviteOptions,
        contact
      )
      .then(({invitationObj, inviteUrl}) => {
        this.inviteUrl = inviteUrl;
        this.invitationObj = invitationObj;
        this.step++;
        //this.modalInstance.close(invitationObj);
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to transfer ownership'))
      .finally(() => {
        this.creating = false;
      });
  }
}

module.exports = Controller;
