'use strict';

require('./upload-progress.css');

module.exports = function (ngModule) {
  ngModule.directive('cfUploadProgress', function () {
    return {
      scope: {
        abort: '&',
        progressVal: '=',
        fileName: '@'
      },
      restrict: 'EA',
      replace: true,
      template: require('./upload-progress.template.html'),
      link: function(scope, element) {
        scope.progressWidth = element.find('.progress').width();
      }
    };
  });
};
