module.exports = function(ngModule) {
  ngModule.component('cfMediaUploadModal', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./media-upload-modal.template.html'),
    controller: require('./media-upload-modal.controller'),
    controllerAs: 'vm'
  });
};
