require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductPrerequisites', {
    bindings: {
      user: '<',
      product: '<',
      $control: '<',
      facilitySops: '<',
      company: '<',
      beforeAddPrerequisite: '&?'
    },
    template: require('./products.prerequisites.template.html'),
    controller: require('./products.prerequisites.controller'),
    controllerAs: 'vm'
  });
};
