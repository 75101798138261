module.exports = function(ngModule) {
  ngModule.component('cfProductComplaintsEdit', {
    bindings: {
      $productComplaint: '<',
      batchRuns: '<',
      company: '<',
      complaintTypes: '<'
    },
    template: require('./recall.product-complaints-edit.template.html'),
    controller: require('./recall.product-complaints-edit.controller'),
    controllerAs: 'vm'
  });
};
