class CfYesNo {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-yes-no.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',         // The form control model reference
      label: '@',           // The label text
      name: '@',            // The name of the form control
      onChange: '&',        // Callback onChange
      ngDisabled: '=',      // Disabled?
      inline: '=',          // Label is inline with the checkboxes
      showUnknown: '=',     // Add the option "Not sure"
      infoIconHtml: '@',    // Html for an optional info icon
      helpText: '@',        // Optional subscript helper text
      isRequired: '=',       // Is the field required (true / false)
      defaultValue: '='
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';

    let vm = this;

    vm.$onInit = function() {
      vm.yesNoClass = vm.inline ? 'fixed-yes-no' : 'col-12';
      if (vm.showUnknown) {
        vm.yesNoClass += ' additional-option';
      }
      if(vm.defaultValue) {
        vm.ngModel = vm.defaultValue;
      }
    };
  }
}

module.exports = CfYesNo;
