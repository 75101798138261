'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfAnalystBanner', {
    bindings: {
      user: '<'
    },
    template: require('./analyst-banner.template.html'),
    controller: require('./analyst-banner.controller'),
    controllerAs: 'vm'
  });
};
