class Controller {
  constructor($q, users, $uibModal, $state, products, growl, utils, UsersSearch,
    sopService, organizations, CompanySearch, UsersSearchCflib, confirmDeleteModal,confirmModal) {
    'ngInject';

    this.$q = $q;
    this.users = users;
    this.products = products;
    this.growl = growl;
    this.utils = utils;
    this.UsersSearch = UsersSearch;
    this.confirmDeleteModal = confirmDeleteModal;
    this.confirmModal = confirmModal;
    this.organizations = organizations;
    this.sopService = sopService;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.companySearch = new CompanySearch();
    this.usersSearchCflib = new UsersSearchCflib();

    this.MAX_RECS = 300;
    this.orgUsers = [];
  }

  $onInit() {
    this.user = this.resolve.user;
    this.organization = this.resolve.organization;
    this.project = this.resolve.project;
    this.existingProject = this.resolve.existingProject;
    this.newProduct = {};
    this.providersArr = [];
    this.providerNames = {};
    this.clientNames = {};
    this.client = {};
    this.initialChecked = [];
    this.noProvider = _.isEmpty(this.providersArr);
    this.removedProviders = [];
    this.addedProviders = [];
    if(this.project) {
      this.populateProjectDetails();
    }
  }

  populateProjectDetails() {
    this.newProduct.title = this.project.title;
    this.newProduct.dueDate = this.project.dueDate;
    this.newProduct.description = this.project.description;
    this.newProduct.specialInstructions = this.project.specialInstructions;
    this.newProduct.sopId = this.project.sopId;
    this.sopService.getTitle(this.project.sopId).then((sopTitle) =>  this.sopName =  sopTitle);

    _.mapValues(this.project.providerUsers, (providerUserList, providerId) => {
      this.noProvider = false;
      this.organizations.getName(providerId).then(name => {
        this.providerNames[providerId] = {orgName: name};
      })
        .then(() => {
          return this.getProviderMembers(providerId);
        })
        .then(members => {
          let userNames = '';

          _.each(members, member => {
            if (providerUserList[member.value]) {
              member.checked = true;
              userNames = userNames === '' ? member.name : userNames + ', ' + member.name;
            }
          });
          this.providersArr.push({
            orgId: providerId,
            userIds: members
          });
          if (userNames !== '') {
            this.providerNames[providerId] = _.assign(this.providerNames[providerId], {
              userNames: userNames
            });
          }
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to set up the provider info'));
    });

    _.mapValues(this.project.clientUsers, (clientUserList, clientId)=>{
      this.organizations.getName(clientId).then(name => {
        this.clientNames[clientId] = {orgName: name};
      })
      .then(() => {
        this.getClientMembers(clientId)
          .then(members => {
            let userNames = '';

            _.each(members, member => {
              if (clientUserList[member.value]) {
                member.checked = true;
                userNames = userNames === '' ? member.name : userNames + ', ' + member.name;
              }
            });
            this.client = {
              orgId: clientId,
              userIds: members
            };
            if (userNames !== '') {
              this.clientNames[clientId] = _.assign(this.clientNames[clientId], {
                userNames: userNames
              });
            }
          });
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to set up the Client info'));
    });
  }

  findOrgObject(arrayObjs, propertyValue) {
    return arrayObjs.find(o => o.orgId === propertyValue);
  }

  convertArrayObjToMap(objArray) {
    let objMap = {};

    _.each(objArray, obj => {
      objMap[obj.value] = obj.checked;
    });
    return objMap;
  }

  addProvider() {
    this.setProvider();
  }

  removeProvider(provider) {
    if (this.providersArr.length === 1) {
      this.growl.error('Need at least one provider');
    }    else {
      let tempProviderArr = [];

      _.each(this.providersArr, obj => {
        if (obj.orgId === provider.orgId) {
          if (_.map(this.addedProviders, 'orgId').includes(provider.orgId)) {   // checking if recently added got removed.
            this.addedProviders = this.addedProviders.filter(function(el) { return el.orgId !== provider.orgId; });
          }          else {
            this.removedProviders.push(obj);
          }
        }        else {
          tempProviderArr.push(obj);
        }
      });
      this.providersArr = tempProviderArr;
      this.growl.success('Provider ' + this.providerNames[provider.orgId].orgName + ' removed');
    }
  }

  hydrateUserInfo(selectedMembers, userType, obj) {
    let selectedMembersMap = this.convertArrayObjToMap(selectedMembers);
    let userNames = '';

    if (selectedMembersMap && obj) {
      _.each(obj.userIds, (member) => {
        if (selectedMembersMap[member.value]) {
          member.checked = selectedMembersMap[member.value];
          userNames = userNames === '' ? member.name : userNames + ', ' + member.name;
        }        else{
          if (member.checked) {
            member.checked = false;
          }
        }
      });
    }
    if (userNames !== '') {
      if (userType === 'provider') {
        this.providerNames[obj.orgId] = _.assign(this.providerNames[obj.orgId], {
          userNames: userNames
        });
      }      else{
        this.clientNames[obj.orgId] = _.assign(this.clientNames[obj.orgId], {
          userNames: userNames
        });
      }
    }
  }

  getProviderMembers(providerOrgId) {
    return this.organizations.getMembersWithRoles(providerOrgId, ['admin','providerWrite'])
      .then((members) => {
        return _.map(members, (member)=>{
          return {
            name: member.name,
            value: member.$id
          };
        });
      });
  }

  getClientMembers(clientOrgId) {
    this.userSearch = new this.UsersSearch(this.user, {});
    this.userSearch.setOrg(clientOrgId);

    return this.userSearch.search()
      .then((results) => {
        this.userSearchResults = results;
        return _.map(this.userSearchResults, (result) => {
          return {
            name: `${result.firstName} ${result.lastName}`,
            value: result.$id
          };
        });
      });
  }

  lookupSOP() {
    this.sopService.chooseSop(this.organization.$id, false, null, null, true).then(sop => {
      if (!sop) {
        return this.confirmModal({
          title: 'No SOPs Found',
          body: 'Please <a href="" ng-click="vm.go(\'operations.sops.list\')">' +
            'create a standard operating procedure</a> before entering logs. ',
          okText: 'Ok',
          hideCancelButton: true
        });
      }
      this.sopName =  sop.title;
      this.newProduct.sopId = sop.$id;
      this.gettingSop = true;
      this.selectProductsForm.$setDirty();
    });
  }

  setProvider() {
    let providerId;

    this.$uibModal.open({
      component: 'cfChooseOrganization',
      resolve: {
        user: () => this.user,
        titleHtml: () => '<i class="far fa-handshake fa-fw" aria-hidden="true"></i> Choose Provider',
        okButtonText: () => 'Choose Provider'
      }
    }).result
      .then(id => {
        providerId = id;
        this.organizations.getName(providerId).then(name => {
          this.providerNames[providerId] = {orgName: name};
        });
        return providerId;
      })
      .then(() => {
        return this.getProviderMembers(providerId);
      })
      .then(members => {
        let providerObj = this.findOrgObject(this.providersArr, providerId);
        let providerMembers = [];

        if (!providerObj) {
          this.providersArr.push({
            orgId: providerId,
            userIds: members
          });
          if (this.noProvider) {
            this.noProvider = false;
          }
          providerMembers = members;
        }        else{
          providerMembers = providerObj.userIds;
        }
        let itemName = 'Provider Contact';
        let header = '<i class="far fa-clipboard-check fa-fw"></i> Choose Provider Contact';
        let instructionsHtml = '<div class="g-mb-10">Choose which Provider to assign with your project.</div>';

        return this.chooseContact(itemName, header, instructionsHtml, providerMembers);
      })
      .then((selectedProviders) => {
        let providerObj = this.findOrgObject(this.providersArr, providerId);

        this.hydrateUserInfo(selectedProviders, 'provider',providerObj);
        if (this.existingProject) {
          this.addedProviders.push(providerObj);
        }
      })
      .then(() => {
        console.log(this.providersArr);
        this.growl.success('Provider Org Selected');
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to set the provider'));
  }

  setProviderContact(index) {
    let obj = this.providersArr[index];
    let providerMembers = [];

    if (obj) {
      providerMembers = obj.userIds;
    }
    let itemName = 'Provider Contact';
    let header = '<i class="far fa-clipboard-check fa-fw"></i> Choose Provider Contact';
    let instructionsHtml = '<div class="g-mb-10">Choose which Provider to assign with your project.</div>';

    this.chooseContact(itemName, header, instructionsHtml, providerMembers)
      .then((selectedProviders) => {
        let providerObj = this.findOrgObject(this.providersArr, obj.orgId);

        this.hydrateUserInfo(selectedProviders, 'provider', providerObj);
      })
      .then(() => {
        console.log(this.providersArr);
        this.growl.success('Provider Org Selected');
      });
  }

  chooseContact(itemName, header, instructionsHtml, members) {
    return this.$uibModal.open({
      component: 'cfChooseFromListModal',
      backdrop: 'static',
      resolve: {
        itemName: () => itemName,
        multiple: () => true,
        htmlColumns: () => true,
        header: () => header,
        instructionsHtml: () => instructionsHtml,
        itemsArray: () => members,
        columns: () => [
          {title: 'Name', property: 'name'}
        ]
      }
    }).result;
  }

  setClient() {
    let clientId;

    this.$uibModal.open({
      component: 'cfChooseOrganization',
      resolve: {
        user: () => this.user,
        titleHtml: () => '<i class="far fa-handshake fa-fw" aria-hidden="true"></i> Choose Client',
        okButtonText: () => 'Choose Client'
      }
    }).result
      .then(id => {
        clientId = id;
        if (!_.isEmpty(this.client) && this.client.orgId !== clientId) {
          this.client = {};
          this.clientNames = {};
        }
        this.organizations.getName(clientId).then(name => {
          this.clientNames[clientId] = {orgName: name};
        });
        return clientId;
      })
      .then(() => {
        return this.getClientMembers(clientId);
      })
      .then(members => {
        let clientMembers = [];

        if (_.isEmpty(this.client)) {
          this.client = {
            orgId: clientId,
            userIds: members
          };
          clientMembers = members;
        }        else{
          clientMembers = this.client.userIds;
        }
        let itemName = 'Client Contact';
        let header = '<i class="far fa-clipboard-check fa-fw"></i> Choose Client Contact';
        let instructionsHtml = '<div class="g-mb-10">Choose which Client to assign with your project.</div>';

        return this.chooseContact(itemName, header, instructionsHtml, clientMembers);
      })
      .then((selectedClients) => {
        this.hydrateUserInfo(selectedClients, 'client', this.client);
      })
      .then(() => {
        console.log(this.client);
        this.growl.success('Client Org Selected');
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to set the Client'));
  }

  setClientContact() {
    let providerMembers = [];

    if (this.client) {
      providerMembers = this.client.userIds;
    }
    let itemName = 'Client Contact';
    let header = '<i class="far fa-clipboard-check fa-fw"></i> Choose Client Contact';
    let instructionsHtml = '<div class="g-mb-10">Choose which Client to assign with your project.</div>';

    this.chooseContact(itemName, header, instructionsHtml, providerMembers)
      .then((selectedClients) => {
        this.hydrateUserInfo(selectedClients, 'client', this.client);
      })
      .then(() => {
        console.log(this.client);
        this.growl.success('Client Org Selected');
      });
  }

  ok() {
    this.submitting = true;
    let providerOrgs = {};
    let clientOrgs = {};
    let providerUsers = {};
    let clientUsers = {};

    if (this.providersArr) {
      _.each(this.providersArr, provider => {
        let userDic = {};

        if (provider.userIds) {
          _.each(provider.userIds, user=> {
            if (user.checked) {
              userDic[user.value] = true;
            }
          });
          providerOrgs[provider.orgId] = true;
          providerUsers[provider.orgId] = userDic;
        }
      });
    }

    if (this.client) {
      let userDic = {};

      if (this.client.userIds) {
        _.each(this.client.userIds, user => {
          if (user.checked) {
            userDic[user.value] = true;
          }
        });
        clientOrgs[this.client.orgId] = true;
        clientUsers[this.client.orgId] = userDic;
      }
    }

    let retObj = {
      title: this.newProduct.title,
      dueDate: this.newProduct.dueDate,
      description: this.newProduct.description ? this.newProduct.description : '',
      specialInstructions: this.newProduct.specialInstructions ? this.newProduct.specialInstructions : '',
      providerOrgs: providerOrgs,
      providerUsers: providerUsers,
      clientOrgs: clientOrgs,
      clientUsers: clientUsers
    };

    if (this.newProduct.sopId) {
      retObj = _.assign(retObj, {
        sopId: this.newProduct.sopId
      });
    }

    // if an existing project is being edited.
    if (this.existingProject) {
      retObj = _.assign(retObj, {
        projectId: this.project.$id
      });

      if (!_.isEmpty(this.removedProviders)) {
        retObj = _.assign(retObj, {
          removedProviders: this.removedProviders
        });
      }
      if (!_.isEmpty(this.addedProviders)) {
        retObj = _.assign(retObj, {
          addedProviders: this.addedProviders
        });
      }
    }
    this.modalInstance.close(retObj);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
