'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfListOrgAccess', {
    bindings: {
      organizations: '=',
      user: '<'
    },
    template: require('./administration.list-org-access.template.html'),
    controller: require('./administration.list-org-access.controller'),
    controllerAs: 'vm'
  });
};
