'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfSupplierAudit', {
    bindings: {
      supplier: '<',
      company: '<'
    },
    template: require('./ingredients.supplier.audit.template.html'),
    controller: require('./ingredients.supplier.audit.controller.js'),
    controllerAs: 'vm'
  });
};
