class CfProductChooser {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-product-chooser.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',         // The form control model reference
      label: '@',           // The label text
      name: '@',            // The name of the form control
      helpText: '@',        // Optional subscript helper text
      isRequired: '=',      // Is the field required (true / false)
      allowEmpty: '=',      // Allow for an empty selection (true / false)
      isInvalid: '=',       // Is the field invalid (true / false)
      invalidText: '@',     // The text to display if the field is invalid.
      placeholder: '@',     // Input placeholder
      ngDisabled: '<',      // Field disabled
      user: '=',            // The logged in user
      onSelect: '&'         // Onselect callback
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller($q, utils, productSearch) {
    'ngInject';

    this.$q = $q;
    this.utils = utils;
    this.productSearch = productSearch;
    this.MAX_RECS = 300;
    this.productResults = [];
  }

  link(scope, elem) {
    let vm = scope.vm;

    vm.productSearch = vm.productSearch.getSearch(vm.user, this.MAX_RECS, [vm.user.orgContext.id]);
    vm.invalidText = vm.invalidText || 'Please enter a valid value.';

    vm.search = function(searchText) {
      let body = vm.productSearch.getSearchQuery();

      _.remove(body.query.bool.must, e => e.match_phrase_prefix);

      if (searchText) {
        body.query.bool.must.push({
          'match_phrase_prefix': {
            brandName: searchText.toLowerCase()
          }
        });
      }

      vm.productSearch.setSearchQuery(body);
      vm.searching = true;
      vm.$q.when(this.productSearch.search())
        .then(results => {
          vm.productResults = results || [];
          if (vm.allowEmpty) {
            vm.productResults.unshift({
              brandName: '-- None --'
            });
            if (!vm.ngModel) {
              vm.ngModel = vm.productResults[0];
            }
          }
          vm.maxReached = vm.productSearch.searchResults && vm.productSearch.searchResults.length >= vm.MAX_RECS;
        })
        .catch((err) => vm.utils.defaultErrorHandler(err, 'Error occurred during plan search.'))
        .finally(() => vm.searching = false);
    };

    vm.getMore = function() {
      vm.searching = true;
      vm.productSearch.getMore()
        .then(() => {
          vm.productResults = vm.productSearch.searchResults;
          vm.maxReached = vm.productSearch.searchResults.length >= vm.MAX_RECS;
        })
        .finally(() => vm.searching = false);
    };

    vm.selected = function($item, $model) {
      if (_.isFunction(vm.onSelect)) {
        vm.onSelect({$item: $item, $model: $model});
      }
    };
  }
}

module.exports = CfProductChooser;
