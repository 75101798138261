class Controller {
  constructor(questionnaireService, utils, $stateParams, $timeout, $window) {
    'ngInject';

    this.questionnaireService = questionnaireService;
    this.utils = utils;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$window = $window;
  }

  $onInit() {
    this.items = _.filter(_.map(this.questionnaire.items, (item, id) => _.assign({model: id}, item)),
            i => !i.disabled);

    _.each(this.items, item => {
      if (item.type !== 'select') { return; }
      item.opts = _.map(item.options.split(','), opt => ({name: opt, value: opt}));
    });
    this.additionalComments = this.questionnaire.additionalComments;
  }

  anyUndefined() {
    return _.some(this.items, (val) => _.isUndefined(val.answer));
  }

  getAnswers() {
    return _.reduce(this.items, (obj, rec) => {
      obj[rec.model] = rec.answer;
      return obj;
    }, {});
  }

  complete() {
    let answers = this.getAnswers();

    answers.additionalComments = this.additionalComments;
    this.questionnaireService.saveQuestionnaire(this.type, this.$stateParams.key,
        answers)
        .then(() => this.$timeout(() => { this.isComplete = true; }))
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to save questionnaire.'));
  }

  goBack() {
    this.$window.history.back();
  }
}

module.exports = Controller;
