'use strict';
module.exports = function(ngModule) {
  require('./intro')(ngModule);
  require('./media_list')(ngModule);
  require('./main')(ngModule);
  require('./ingredient_setup_modal')(ngModule);
  require('./supplier_files')(ngModule);
  require('./ingredient_files')(ngModule);
  require('./finish_modal')(ngModule);
  require('./org_files')(ngModule);
  require('./product_files')(ngModule);
};

