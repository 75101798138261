class Controller {
  constructor($state, $timeout, preventDirtyNav, growl, utils) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.growl = growl;
    this.utils = utils;
  }

  $onInit() {
    this.$timeout(() => this.preventDirtyNav(this.customerForm, 'You have unsaved customer changes.',
      () => this.save()));
    this.isNew = this.$customer.$value === null;
  }

  save() {
    return this.$customer.$save().then(() => {
      if (this.customerForm) {
        this.customerForm.$setPristine();
      }
      this.isNew = false;
      this.growl.success('Customer saved successfully.');
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to save customer.'));
  }

  saveAndClose() {
    this.save().then(() => this.close());
  }

  cancel() {
    if (this.customerForm) {
      this.customerForm.$setPristine();
    }
    this.close();
  }

  close() {
    this.$state.go('recall.consignees.list');
  }
}

module.exports = Controller;
