module.exports = function(ngModule) {
  ngModule.factory('productCustomersService', function(fbutil, $q, accessRequests, authorization, $firebaseArray,
                                                       organizations, $log, S3, orgInteractionService,
                                                         $http) {
    'ngInject';

    return {
      getAllProductCustomers: function(orgId) {
        return $http.get(`product-customers/organization/${orgId}`)
          .then(result =>result.data.productCustomers);
      },
      deleteProductCustomer(orgId,productCustomerId) {
        return $http.delete(`/product-customers/${productCustomerId}/organization/${orgId}`);
      },
      getProductCustomer: function(orgId, productCustomerId) {
        return $http.get(`/product-customers/${productCustomerId}/organization/${orgId}`)
          .then(result => result.data);
      },
      updateProductCustomer: function(organizationId, productCustomerId, customer) {
        return $http
          .patch(`/product-customers/${productCustomerId}/organization/${organizationId}`, {productCustomer: customer})
          .then((result)=> result.data);
      },
      addProductCustomer(organizationId, customer) {
        return $http.post('/product-customers/organization/' + organizationId, {productCustomer: customer})
          .then((result)=> result.data);
      },
      getAllOrders: function(organizationId, productCustomerId) {
        return $http.get(`/sales-orders/product-customers/${productCustomerId}/organization/${organizationId}`)
          .then(result =>result.data.salesOrders);
      },
      getOrder: function(organizationId,salesOrderId) {
        return $http.get(`/sales-orders/${salesOrderId}/organization/${organizationId}`)
          .then(result => result.data);
      },
      updateOrder: function(organizationId, salesOrderId, order) {
        return $http.patch(`/sales-orders/${salesOrderId}/organization/${organizationId}`,{salesOrder: order})
          .then((result)=> result.data);
      },
      addOrder: function(organizationId,order) {
        return $http.post(`/sales-orders/organization/${organizationId}`,{salesOrder: order})
          .then(result => result.data);
      },
      deleteOrder: function(organizationId, salesOrderId) {
        return $http.delete(`/sales-orders/${salesOrderId}/organization/${organizationId}`);
      }
    };
  });
};
