'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUploadPortalProductFiles', {
    bindings: {
      token: '<',
      product: '<'
    },
    template: require('./file-portal.product-files.template.html'),
    controller: require('./file-portal.product-files.controller.js'),
    controllerAs: 'vm'
  });
};