'use strict';

module.exports = function(ngModule) {
  ngModule.config(
    function($stateProvider) {
      'ngInject';

      $stateProvider.state('products.edit.orders', {
        url: '/orders',
        template: '<cf-product-orders product="$resolve.product" user="$resolve.user" ' +
        'product-orders="$resolve.productOrders"></cf-product-orders>',
        resolve: {
          //organization: (product, organizations) => organizations.$get(product.organizationId),
          productOrders: (orders, product) =>
            orders.get(product.organizationId, false, null, 0, 30, {productId: product.$id})
            .then(result => result.data)
        }
      });
    }
  );
};
