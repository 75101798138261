'use strict';

module.exports = function(ngModule) {
  ngModule.factory('recentLettersCache', function(CacheFactory) {
    'ngInject';
    if (!CacheFactory.get('recentLettersCache')) {
      CacheFactory.createCache('recentLettersCache', {
        deleteOnExpire: 'aggressive',
        recycleFreq: 60 * 1 * 1000, // 1 min (how ofter the cache mgr will check for expired items)
        maxAge: 60 * 10 * 1000, // 10 min (note: ordering a letter will result in manual deleting of the cache rec)
        storageMode: 'localStorage' // Will persist across browser refreshes
      });
    }

    return CacheFactory.get('recentLettersCache');
  });
};
