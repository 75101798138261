require('./_css');

module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.products',
    ngModule = angular.module(moduleName, [
      require('./plan_analysis')(moduleName),
      require('./verification')(moduleName),
      require('./reports')(moduleName),
      //require('./plan_review')(moduleName), Todo: phyically remove folder
      require('./product_orders')(moduleName)
    ]);

  require('./_services')(ngModule);
  require('./_config')(ngModule);
  require('./_constants')(ngModule);
  require('./_filters')(ngModule);
  require('./_components')(ngModule);

  return moduleName;
};
