class Constructor {
  constructor(growl, batchService, sopService, productSearch, utils, moment) {
    'ngInject';

    this.growl = growl;
    this.batchService = batchService;
    this.sopService = sopService;
    this.productSearch = productSearch;
    this.utils = utils;
    this.moment = moment;

    this.PAGE_SIZE = 30;
    this.steps = {
      PICK_TYPE: 1,
      PICK_ITEM: 2
    };
  }

  $onInit() {
    this.user = this.resolve.user;
    this.step = this.steps.PICK_TYPE;
    this.selectedType = 'sop';

    this.productSearch = new this.productSearch.ProductSearch(this.user);
    this.productSearch.searchFields(['brandName']);
    this.endDate = new Date();
    this.endDate.setHours(23,59,59,999);
    this.startDate = moment(new Date()).subtract(30, 'days').toDate();
    this.startDate.setHours(0, 0, 0, 0);
    this.search = _.debounce(this._search, 300);
    this.loadMore = _.debounce(() => {
      if (this.maxReached) { return; }
      this.searching = true;
      this.fetchRecords(this.page++)
        .then((result) => {
          if (result.length < this.PAGE_SIZE) { this.maxReached = true; }
          this.searchResults = _.concat(this.searchResults, result);
        })
        .finally(() => {this.searching = false;});
    }, 300);
    this.multiple = this.resolve.multiple;
    this.header = this.resolve.header;
    this.sopLogReport = 'noLog';
    this.itemName = this.resolve.itemName;
  }

  chooseType() {
    this.searching = true;
    switch(this.selectedType) {
    case 'product':
      this.fetchRecords = (page) => (page === 0 ?
        this.productSearch.search(this.searchText) :
        this.productSearch.getMore())
        .then(results => _.map(results, r => ({
          name: r.brandName,
          description: r.description,
          $id: r.$id
        })));
      break;
    case 'sop':
      this.fetchRecords = (page) => this.sopService.query(this.user.orgContext.id, this.searchText,
        {templates: false, isFacilitySop: true}, page * this.PAGE_SIZE, this.PAGE_SIZE)
        .then(results => _.map(results, r => ({
          name: r.title,
          description: r.description,
          $id: r.$id
        })));
      break;
    default:
      throw new Error('Invalid type: ' + this.selectedType);
    }
    this.step = this.steps.PICK_ITEM;
    this.search();
  }

  getColumnName() {
    switch(this.selectedType) {
    case 'product':
      return 'Plan Name';
    case 'sop':
      return 'SOP Name';
    default:
      throw new Error('Invalid type: ' + this.selectedType);
    }
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  _search() {
    this.page = 0;
    this.searchResults = [];
    this.maxReached = false;
    this.searching = true;
    return this.fetchRecords(this.page)
      .then((results) => {
        if (results.length < this.PAGE_SIZE) { this.maxReached = true; }
        this.searchResults = results;
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch search records.'))
      .finally(() => { this.searching = false; });
  }

  submit() {
    if (this.multiple) {
      this.selectedItem = _.filter(this.searchResults, (val, index) => this.searchResults[index].checked);
    } else {
      this.selectedItem = this.selectedItem.$id;
    }
    if (!this.selectedItem) {
      this.growl.error('Please select a record from the dropdown list.');
      return;
    }

    let returnObj = {
      reportType: 'sopLogs',
      params: {
        startDate: this.sopLogReport === 'log' || !this.itemName ? this.startDate.getTime() : null,
        endDate: this.sopLogReport === 'log' || !this.itemName ? this.endDate.getTime() : null
      }
    };

    switch(this.selectedType) {
    case 'product':
      returnObj.params.productId = this.selectedItem.$id;
      break;
    case 'batch':
      returnObj.reportType = 'batchReports';
      returnObj.params.batchId = this.selectedItem.$id;
      break;
    case 'sop':
      returnObj.params.sopId =  this.selectedItem;
      break;
    default:
      throw new Error('Invalid type: ' + this.selectedType);
    }

    this.modalInstance.close(returnObj);
  }

  previousStep() {
    this.searchResults = [];
    this.step = this.steps.PICK_TYPE;
  }
  rowClicked(item) {
    if (this.multiple) {
      item.checked = !item.checked;
    } else {
      this.selectedItem = item;
    }
  }
  isValid() {
    if (this.multiple) {
      return _.findIndex(this.searchResults, (item) => item.checked) > -1;
    } else {
      return this.selectedItem;
    }
  }
}

module.exports = Constructor;
