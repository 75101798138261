module.exports = function(ngModule) {
  class MarketplaceService {
    constructor($state, $log, $q, $uibModal, $window, $http, authentication, orders, urlTokens, orgPerspectives,
                marketplacePerspectiveTypes, organizations, billing, CF_ORGANIZATION_ID, marketplaceServices,
                CF_ORDER_ITEM_STATUS, orgTypes) {
      'ngInject';

      this.$state = $state;
      this.$log = $log;
      this.$q = $q;
      this.$uibModal = $uibModal;
      this.$window = $window;
      this.$http = $http;
      this.authentication = authentication;
      this.orders = orders;
      this.urlTokens = urlTokens;
      this.orgPerspectives = orgPerspectives;
      this.perspectiveTypes = marketplacePerspectiveTypes;
      this.organizations = organizations;
      this.orgTypes = orgTypes;
      this.billing = billing;
      this.CF_ORGANIZATION_ID = CF_ORGANIZATION_ID;
      this.marketplaceServices = marketplaceServices;
      this.orderItemStatus = CF_ORDER_ITEM_STATUS;

      // the order and orderItem currently being worked / viewed
      this._order = null;
      this._orderItem = null;
    }

    get order() { return this._order; }
    set order(value) { this._order = value; }
    get orderItem() { return this._orderItem; }
    set orderItem(value) { this._orderItem = value; }

    getPerspectiveType(perspective, organization) {
      switch(perspective) {
      case this.orgPerspectives.PROVIDER_ANALYST:
        return this.perspectiveTypes.PROVIDER;
      case this.orgPerspectives.CF_ADMIN:
      case this.orgPerspectives.ADMIN:
        return !organization.types || organization.types[this.orgTypes.MANUFACTURER.id] ||
          organization.types[this.orgTypes.RESTAURANT.id] ? this.perspectiveTypes.CONSUMER :
            this.perspectiveTypes.PROVIDER;
      default:
        return this.perspectiveTypes.CONSUMER;
      }
    }

    isConsumer(user, organization) {
      return ((this.perspectiveTypes.CONSUMER | this.perspectiveTypes.ALL) &
        this.getPerspectiveType(user.getPerspective(), organization)) > 0;
    }

    isProvider(user, organization) {
      return ((this.perspectiveTypes.PROVIDER | this.perspectiveTypes.ALL) &
        this.getPerspectiveType(user.getPerspective(), organization)) > 0;
    }

    /**
     * Get the preferred provider mini-profiles for a list of service IDs
     * @param {object} organization The organization from which to retrieve preferred providers
     * @param {Array<string>} serviceIds A list of service IDs
     * @returns {Promise} A promise that resolves to a hash/map of service providers (e.g. {[service ID]: [org's mini profile]}
     */
    getServiceProviders(organization, serviceIds) {
      if (_.isEmpty(serviceIds)) { return this.$q.resolve({}); }

      let _serviceIds = _.uniq(serviceIds),
        serviceProviders = {};

      return this.$q.all(_.map(_serviceIds, (id) => {
        let preferred = _.find(organization.preferredProviders, (p) => p.serviceId === id),
          providerOrgId = preferred ? preferred.providerOrgId : this.CF_ORGANIZATION_ID;

        return this.organizations.getMiniProfile(providerOrgId)
          .then((profile) => _.assign({}, profile, {$id: providerOrgId, serviceId: id}));
      }))
      .then((providerOrgs) => {
        _.each(_serviceIds, (id) => {
          serviceProviders[id] = _.find(providerOrgs, {serviceId: id});
        });

        return serviceProviders;
      });
    }

    getOrders(orgId, perspectiveType) {
      return this.orders.get(orgId, perspectiveType === this.perspectiveTypes.PROVIDER);
    }

    /**
     * Get the services available to an organization
     * @param {object} organization The organization from which to retrieve services
     * @returns {Promise} A promise that resolves to a hash/map of services (e.g. {[service ID]: [service info]}
     */
    getAvailableServices(organization) {
      return this.marketplaceServices.getDefaultServices().then(marketPlaceDefaults => {
        let services = _.cloneDeep(marketPlaceDefaults);

        return this.$q.all(_.map(organization.preferredProviders, (provider) =>
          this.marketplaceServices.getOrgService(provider.providerOrgId, provider.serviceId)
            .then((result) => _.assign(result, {$id: provider.serviceId}))
        )).then((result) => {
          _.each(result, (service) => {
            if (services[service.$id]) {
              _.assign(services[service.$id], service);
            } else {
              services[service.$id] = service;
            }
          });

          return services;
        });
      });
    }

    /**
     * Get a marketplace service (overriding default properties w/ provider's if necessary).
     * @param {string} serviceId The service ID
     * @param {string} providerOrgId The provider organization ID
     * @returns {object} A promise that resolves to the service
     */
    getService(serviceId, providerOrgId) {
      return this.$q.all([
        this.marketplaceServices.getDefaultService(serviceId),
        this.marketplaceServices.getOrgService(providerOrgId, serviceId)
      ])
      .then(([defaultService, providerService]) => {
        return _.assign(defaultService, providerService, {$id: serviceId});
      });
    }

    /**
     * Get the services offered by a service provider
     * @param {string} organizationId The organization ID of the org providing the services
     * @returns {Promise<object>} A promise that resolves to a key/value pair of service offered
     */
    getServicesOffered(organizationId) {
      let services;

      return this.$q.all([
        this.marketplaceServices.getDefaultServices(),
        this.marketplaceServices.getOrgServices(organizationId)
      ])
      .then(([defaultServices, orgServices]) => {
        // pull in the default services.
        services = _.mapValues(defaultServices, (svc) => _.assign({}, svc, {default: true}));

        // assign any org service overrides to the default service(s)
        services = _.mapValues(services, (svc, key) => {
          let orgService = _.get(orgServices, key, {}),
            result = _.assign(svc, orgService);

          if (!_.isEmpty(orgService)) { delete orgServices[key]; }
          return result;
        });

        // add any remaining org services
        return _.assign(services, orgServices);
      });
    }

    /**
     * Return an organization's default provider profiles.
     * @param {$firebaseObject} $organization The organization who's preferred providers will be grouped and profiled.
     * @return {Promise} A promise that resolves to a profile object, where each property is a provider orgId.
     */
    providerProfiles($organization) {
      let providerOrgLookups = {}, preferredProviders = _.values(_.cloneDeep($organization.preferredProviders));

      return this.marketplaceServices.getDefaultServices().then(defaultServices => {
        // For any service missing a preferred provider, add FoodReady
        _.each(defaultServices, (service, serviceId) => {
          if (!_.some(preferredProviders, p => p.serviceId === serviceId)) {
            preferredProviders.push({
              providerOrgId: this.CF_ORGANIZATION_ID,
              serviceId: serviceId
            });
          }
        });

        // Get org profiles for each preferred provider.
        _.each(preferredProviders, (providerInfo) => {
          if (providerOrgLookups[providerInfo.providerOrgId]) { return; }
          providerOrgLookups[providerInfo.providerOrgId] = this.$q.all({
            profile: this.organizations.getProfile(providerInfo.providerOrgId),
            members: this.organizations.getAnalystMembers(providerInfo.providerOrgId)
          });
        });

        return this.getAvailableServices($organization);
      }).then(services => {
        return this.$q.all(providerOrgLookups).then(orgsInfo => {
          return _.mapValues(orgsInfo, (orgInfo, orgId) => {
            return {
              profile: orgInfo.profile,
              members: orgInfo.members,
              services: _.map(_.filter(preferredProviders, p => p.providerOrgId === orgId),
                p => services[p.serviceId])
            };
          });
        });
      });
    }

    saveAsTemplate(modalTitle, markup) {
      if (_.isEmpty(this.orderItem)) {
        throw 'Missing order or orderItem properties';
      }

      return this.$uibModal.open({
        component: 'cfMarketplaceSaveAsDocumentTemplate',
        backdrop: 'static',
        resolve: {
          modalTitle: () => modalTitle,
          markup: () => markup,
          serviceId: () => this.orderItem.service.key,
          $documentTemplates: () => this.organizations.$getDocumentTemplates(this.authentication.user.orgContext.id)
        }
      }).result;
    }

    loadTemplate(modalTitle) {
      if (_.isEmpty(this.orderItem)) {
        throw 'Missing order or orderItem properties';
      }

      return this.$uibModal.open({
        component: 'cfMarketplaceLoadDocumentTemplate',
        backdrop: 'static',
        resolve: {
          modalTitle: () => modalTitle,
          serviceId: () => this.orderItem.service.key,
          defaultServices: () => this.marketplaceServices.getDefaultServices(),
          servicesOffered: () => this.getServicesOffered(this.orderItem.clientOrgId),
          $documentTemplates: () => this.organizations.$getDocumentTemplates(this.authentication.user.orgContext.id)
        }
      }).result;
    }

    /**
     * Get the compiled letter HTML.
     * @param {string} letterId The letter ID
     * @param {boolean=} snippet Return a full HTML page (with styles) or just an HTML snippet
     * @returns {* | Promise.<Object>} A promise resolving to letter markup
     */
    getLetterHtml(letterId, snippet) {
      return this.$http.get('/letters/' + letterId + '?format=html&snippet=' + !!snippet);
    }

    previewLetter() {
      if (_.isEmpty(this.orderItem)) {
        throw 'Missing order or orderItem properties';
      }

      if (_.isEmpty(this.orderItem.letterId)) {
        throw 'No letter ID set for order item';
      }

      let reportWindow = this.$window.open('/api/letters/loading');

      this.$http.get('/letters/' + this.orderItem.letterId)
        .then((url) => reportWindow.location.replace(url.data));
    }

    /**
     * Charge the customer on the order.
     * @param {string} orderId The order ID
     * @param {object} $orderItem $orderItem The order item
     * @returns {* | Promise.<Object>} A promise resolving to the result of the charge
     */
    chargeCustomer(orderId, $orderItem) {
      let wasCharged = false;

      return this.billing.chargeCustomer($orderItem.providerOrgId, $orderItem.customerId, {
        amount: this.billing.toStripeAmountFormat($orderItem.isRush ?
          $orderItem.service.price.rush : $orderItem.service.price.regular),
        source: $orderItem.sourceId,
        currency: 'usd',
        'receipt_email': _.get($orderItem, 'contact.email'),
        metadata: {orderId: orderId, itemId: $orderItem.$id}
      })
      .then((charge) => {
        wasCharged = true;
        return this.orders.setChargeId(orderId, $orderItem.$id, charge.id)
          .then(() => charge);
      })
      .catch(error => {
        return this.$q.reject({wasCharged, error});
      });
    }

    /**
     * Complete the order item.
     * @param {string} orderId The order ID
     * @param {object} $orderItem The order item
     * @return {Promise<object>} A promise resolving to the result of the update
     */
    completeOrderItem(orderId, $orderItem) {
      return this.$http.put('/orders/' + orderId + '/items/' + $orderItem.$id,
        _.assign({}, $orderItem, {status: this.orderItemStatus.COMPLETED}));
    }

    getProviderOrgTypes() {
      return [this.orgTypes.ANALYST, this.orgTypes.LABORATORY];
    }
  }

  ngModule.service('marketplaceService', MarketplaceService);
};
