module.exports = function($uibModal, $log, $q, S3, confirmDeleteModal, growl, utils, organizations,
                          featureIntroModal) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    featureIntroModal(this.user, {
      title: 'Safety Team',
      toState: 'company.safetyTeam',
      tipName: 'featureIntro_safetyTeam',
      helpTitle: 'Add Your Food Safety Team',
      helpText: 'Manufacturers should create a food safety team to put together the Food Safety Plan. ' +
    'This team should be managed by people in production, but can also include employees in ' +
    'maintenance, sanitation, quality assurance, purchasing, even marketing and logistics. A team ' +
    'approach will reduce the risk of missing key components of the plan, and also encourages ' +
    'ownership across the company. Top management commitment sends a strong message to all.<br><br>' +
    'However, there should be one person responsible for the creation, implementation, and oversight ' +
    'of the Food Safety Plan. And that person must be a <b><i>Preventive Controls Qualified Individual</i></b>. ' +
    'This individual will <ul>' +
    '<li>Oversee the food safety team.</li>' +
    '<li>Perform or oversee preparation of the Food Safety Plan.</li>' +
    '<li>Validate preventive controls.</li>' +
    '<li>Records Review.</li>' +
    '<li>Re-analysis of the Food Safety Plan.</li>' +
    '</ul>'
    });
  }

  vm.editMember = function(memberId) {
    $uibModal.open({
      component: 'cfEditSafetyTeam',
      backdrop: 'static',
      resolve: {
        company: () => vm.company,
        member: function($firebaseObject) {
          let memberRef = memberId ? vm.safetyTeam.$ref().child(memberId) : vm.safetyTeam.$ref().push();

          return $firebaseObject(memberRef).$loaded();
        },
        members: () => vm.members
      }
    }).result.then(function() {
      organizations.setMilestoneAchieved(vm.company.$id, organizations.milestones.SAFETY_TEAM);
      growl.success('Member ' + (memberId ? 'updated' : 'added') + ' successfully.', {});
    }).catch(function(reason) {
      if (utils.isModalDismissalByUser(reason)) { return; }

      growl.error('Error ' + (memberId ? 'updating' : 'adding') + ' the team member. Please ' +
        'try again or contact FoodReady customer support.', {});
      $log.error(reason);
    });
  };

  vm.remove = function(member) {
    confirmDeleteModal(member.name).then(function() {
      let filePromises = [];

      _.each(member.media, (file) => {
        filePromises.push(S3.deleteFile(vm.company.$id, file.key));
      });

      vm.safetyTeam.$remove(member)
        .catch((err) => {
          $log.error(err);
          growl.error('Unable to remove the team member. Please ' +
            'try again or contact FoodReady customer support.');
        });

      $q.all(filePromises).catch($log.error);
    });
  };

  vm.save = _.debounce(function(rec) {
    vm.safetyTeam.$save(rec).catch(function(err) {
      $log.error(err);
      growl.error('Unable to save changes.');
    });
  }, 200);
};
