class CfTimespanPicker {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-timespan-picker.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',         // The form control model reference
      label: '@',           // The label text
      helpText: '@',        // Optional subscript helper text
      isRequired: '=',      // Is the field required (true / false)
      isReadonly: '=',      // Is the field readonly (true / false)
      isInvalid: '=',       // Is the field invalid (true / false)
      invalidText: '@'      // The text to display if the field is invalid.
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  onKeydown(e) {
    if(e.keyCode === 189 || e.keyCode === 109) {
      return false;
    }
  }

  onBlur(vm) {
    return function() {
      vm.$timeout(() => {
        if (vm.isRequired) {
          if (!vm.ngModel.days && !vm.ngModel.hours && !vm.ngModel.minutes) {
            vm.cfTimespanPicker.days.$setValidity('required', false);
            vm.cfTimespanPicker.days.$setTouched();
            vm.cfTimespanPicker.hours.$setValidity('required', false);
            vm.cfTimespanPicker.hours.$setTouched();
            vm.cfTimespanPicker.minutes.$setValidity('required', false);
            vm.cfTimespanPicker.minutes.$setTouched();
          } else {
            vm.cfTimespanPicker.days.$setValidity('required', true);
            vm.cfTimespanPicker.hours.$setValidity('required', true);
            vm.cfTimespanPicker.minutes.$setValidity('required', true);
          }
        }
      });
    };
  }

  link(scope, elem) {
    let vm = scope.vm,
      inputElements = elem.find('input'),
      blurFunc = this.onBlur(vm);

    vm.ngModel = vm.ngModel || {};
    vm.invalidText = vm.invalidText || 'Please enter a valid timespan.';

    inputElements.on('keydown', this.onKeydown);
    inputElements.on('blur', blurFunc);
    scope.$on('$destroy', () => {
      inputElements.off('keydown', this.onKeydown);
      inputElements.off('blur', blurFunc);
    });
  }
}

module.exports = CfTimespanPicker;
