require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditPlanFoodProducts', {
    bindings: {
      product: '=',
      foodProducts: '=',
      user: '<',
      isReadOnly: '<'
    },
    template: require('./products.edit.food-products.template.html'),
    controller: require('./products.edit.food-products.controller.js'),
    controllerAs: 'vm'
  });
};
