class Constructor {
  constructor(products, $q, $log, $state, utils, SAMPLE_ORGANIZATION_ID) {

    'ngInject';
    this.products = products;
    this.$q = $q;
    this.$log = $log;
    this.$state = $state;
    this.utils = utils;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
  }

  $onInit() {
    this.templateDetails = this.getTemplateDetails(this.template);
  }

  skip() {
    this.$log.info('onboarding:assignTemplate - skip.');
    this.$state.go('onboarding.editPlan.pickHazards');
  }

  loadTemplate() {
    this.loadingTemplate = true;
    _.set(this.company, 'onboarding.loadedTemplate', this.template.$id);
    return this.company.$save().then(() => this.products.loadTemplate(
      {
        organizationId: this.company.$id,
        $id: this.$product.$id
      }, this.SAMPLE_ORGANIZATION_ID, this.template.$id,
      {
        hazards: true,
        ingredients: true,
        equipment: true,
        planAnalysis: true
      })
    ).then(() => {
      this.$log.info('onboarding:assignTemplate - complete.');
      this.$state.go('products.edit', {productId: this.$state.params.productId});
    }).catch(err => {
      this.utils.defaultErrorHandler(err, 'Unable to load the template.');
      this.skip();
    }).finally(() => {
      this.loadingTemplate = false;
    });
  }

  getTemplateDetails(template) {
    return {
      hazards: _.keys(template.hazards).length,
      steps: template.processSteps ? _.keys(template.processSteps).length : 0,
      controls: _.keys(template.controls).length,
      sops: _.reduce(template.controls, (total, c) => {
        if (c.type !== 'sop') { return total; }

        total += _.keys(c.prerequisites).length;
        return total;
      }, 0)
    };
  }
}

module.exports = Constructor;
