class Constructor {
  constructor(email, $log, $scope, $q, $timeout) {
    'ngInject';

    this.email = email;
    this.$log = $log;
    this.$scope = $scope;
    this.$q = $q;
    this.$timeout = $timeout;

    this.emailStatusEnum = {
      SENT: {
        id: 1,
        text: 'Sent'
      },
      READ: {
        id: 2,
        text: 'Opened'
      },
      FAILED: {
        id: 3,
        text: 'Failed'
      },
      LOADING: {
        id: 4,
        text: 'Loading...'
      }
    };
  }

  $onInit() {
    this.emailStatuses = {};
    this.getFileRequestStatuses(this.requestFilesTokens).then(() => {

      // Watch for added tokens
      this.$scope.$watch('vm.requestFilesTokens', tokens => {
        if (!tokens) { return; }
        this.fileRequestsMarkup = undefined;
        _.each(tokens, token => {
          this.emailStatuses[token.emailId] = this.emailStatuses[token.emailId] || this.emailStatusEnum.SENT;
        });
      });
    }).catch(err => {
      this.$log.error('Unable to get token statuses', this.$log.toString(err));
    });
  }

  getFileRequestsMarkup(requestFilesTokens) {
    if (!this.fileRequestsMarkup) {
      let allLoaded = false;
      let markup = _.reduce(requestFilesTokens, (result, tokenRec) => {
        allLoaded = allLoaded && this.emailStatuses[tokenRec.emailId];
        let status = this.emailStatuses[tokenRec.emailId];
        let textClass;

        if (status) {
          switch (status.id) {
          case this.emailStatusEnum.READ.id:
            textClass = 'class="text-primary"';
            break;
          case this.emailStatusEnum.FAILED.id:
            textClass = 'class="text-danger"';
            break;
          default:
            textClass = '';
          }
        }
        return result + `<li>${tokenRec.email} - <span ${textClass}>${status ? status.text : 'loading...'}</span></li>`;
      }, '<ol>') + '</ol>';

      if (!allLoaded) { return markup; }
      this.fileRequestsMarkup = markup;
    }

    return this.fileRequestsMarkup;
  }

  getFileRequestStatuses(tokens) {
    return this.$q.all(_.map(tokens, (tokenState) => {
      // Mandrill seems to be losing our emails. If we check but fail > 5 times, stop checking.
      if (!tokenState.emailId || tokenState.failedEmailCheckCount > 5) { return; }
      this.emailStatuses[tokenState.emailId] = this.emailStatusEnum.LOADING;

      return this.email.get(tokenState.emailId).then(status => {
        if (status.opens) {
          this.emailStatuses[tokenState.emailId] = this.emailStatusEnum.READ;
        } else if (status.state !== 'sent') {
          this.emailStatuses[tokenState.emailId] = this.emailStatusEnum.FAILED;
        } else {
          this.emailStatuses[tokenState.emailId] = tokenState.fileRequestAttempt > 4 ? this.emailStatusEnum.FAILED :
            this.emailStatusEnum.SENT;
        }
      }).catch(() => {
        if (_.isFunction(this.onEmailStatusFailed)) {
          this.onEmailStatusFailed({$tokenId: tokenState.id});
        }
        this.emailStatuses[tokenState.emailId] = tokenState.fileRequestAttempt > 4 ? this.emailStatusEnum.FAILED :
          this.emailStatusEnum.SENT;
        this.$log.warn(`Error getting email statuses for id ${tokenState.emailId}`);
      });
    }));
  }

  anyFailed(requestTokens) {
    return _.some(requestTokens, token =>
      token.emailId && _.get(this.emailStatuses, `${token.emailId}.id`) === this.emailStatusEnum.FAILED.id);
  };
}

module.exports = Constructor;
