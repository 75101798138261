module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('recall.events', {
        url: '/events',
        abstract: true,
        template: '<div ui-view></div>'
      })
      .state('recall.events.list', {
        url: '',
        template: '<cf-recall-events-list user="$resolve.user" events="$resolve.events" ' +
          'company="$resolve.company"></cf-recall-events-list>',
        resolve: {
          events: ($log, recallEventsService, user) => recallEventsService.queryEvents(user.orgContext.id)
            .catch((err) => {
              $log.error('Unable to query recall events.', $log.toString(err));

              return [];
            })
        }
      })
      .state('recall.events.edit', {
        url: '/edit/:eventId',
        abstract: true,
        template: '<cf-recall-events-edit user="$resolve.user" $event="$resolve.$event" ' +
          'events="$resolve.events"></cf-recall-events-edit>',
        resolve: {
          $event: (recallEventsService, user, $stateParams) => recallEventsService.$getEvent($stateParams.eventId)
        }
      }).state('recall.events.edit.reason', {
        url: '/reason',
        template: '<cf-recall-events-reason user="$resolve.user" $event="$resolve.$event" ' +
          'company="$resolve.company"></cf-recall-events-reason>'
      }).state('recall.events.edit.volume', {
        url: '/volume',
        template: '<cf-recall-events-volume user="$resolve.user" $event="$resolve.$event" ' +
          'company="$resolve.company"></cf-recall-events-volume>'
      }).state('recall.events.edit.letter', {
        url: '/letter',
        template: '<cf-recall-events-letter user="$resolve.user" $letter="$resolve.$letter" ' +
          'recall="$resolve.recall"></cf-recall-events-letter>',
        resolve: {
          $letter: (recallEventsService, $event) => recallEventsService.$getLetter($event),
          recall: (user, recallService) => recallService.$get(user.orgContext.id)
        }
      }).state('recall.events.edit.lots', {
        url: '/lots',
        template: '<cf-recall-events-lots user="$resolve.user" $event="$resolve.$event" ' +
          'company="$resolve.company" batch-runs="$resolve.batchRuns" ></cf-recall-events-lots>',
        resolve: {
          batchRuns: ($log, batchService, user) => batchService.getAllBatchRuns(user.orgContext.id)
            .catch((err) => {
              $log.error('Unable to query batches.', $log.toString(err));
              return [];
            })
        }
      }).state('recall.events.edit.complaint', {
        url: '/complaint',
        template: '<fr-recall-events-complaint user="$resolve.user"' +
        '$event="$resolve.$event" $product-complaints="$resolve.productComplaints"></fr-recall-events-complaint>',
        resolve: {
          productComplaints: (recallService, user) => recallService.$productComplaints(user.orgContext.id),
        }
      }).state('recall.events.edit.classType', {
        url: '/classType',
        template: '<cf-recall-events-class-type user="$resolve.user" ' +
          '$event="$resolve.$event"></cf-recall-events-class-type>'
      }).state('recall.events.edit.notifications', {
        url: '/notifications',
        template: '<cf-recall-events-notifications user="$resolve.user" $notifications="$resolve.$notifications" ' +
          '$event="$resolve.$event"></cf-recall-events-notifications>',
        resolve: {
          $notifications: (recallEventsService, $event) => recallEventsService.$recallNotifications($event)
        }
      }).state('recall.events.edit.consigneeImpact', {
        url: '/consigneeImpact',
        template: '<cf-recall-events-consignee-impact user="$resolve.user" $customers="$resolve.customers" ' +
          '$event="$resolve.$event" customer-types="$resolve.customerTypes" product-customers="$resolve.productCustomers">  ' +
          '</cf-recall-events-consignee-impact>',
        resolve: {
          customers: (company, recallService) => recallService.$customers(company.$id),
          productCustomers: (productCustomersService, user) =>
            productCustomersService.getAllProductCustomers(user.orgContext.id)
        }
      }).state('recall.events.edit.destruction', {
        url: '/destruction',
        template: '<cf-recall-events-destruction user="$resolve.user" ' +
          '$event="$resolve.$event"></cf-recall-events-destruction>'
      });
  });
};
