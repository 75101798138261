'use strict';

module.exports = function($state) {
  'ngInject';

  var vm = this;

  vm.$onInit = function() {
    vm.$state = $state;
  };

  vm.getHeading = function() {
    return vm.supplier.$value === null ? 'New Supplier' : vm.supplier.name;
  };
};
