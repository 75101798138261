class Constructor {
  constructor(users, utils, $state, confirmDeleteModal, growl, operationalChecklistService, $q, checklistLibraryService) {
    'ngInject';

    this.users = users;
    this.utils = utils;
    this.$state = $state;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.operationalChecklistService = operationalChecklistService;
    this.$q = $q;
    this.checklistLibraryService = checklistLibraryService;
    this.PAGE_SIZE = 30;
    this.statusToggle = true;
  }

  $onInit() {
    this.search = _.debounce(this._search, 300);
    this.fetchChecklists().then((checklists)=>{
      this.checklists = checklists;
      // this.loadChecklist();
    });
    this.loadMore = _.debounce(() => {
      this.searching = true;
      let loadMorePromise = this.operationalChecklistService.loadMore(this.searchText);

      if(loadMorePromise) {
        return loadMorePromise.then((result) => {
          this.checklists = _.concat(this.checklists, result);
          // this.loadChecklist();
        });
      }
      this.searching = false;
    }, 300);
  }

  newOperationChecklist() {
    this.checklistLibraryService.promptAddChecklist(this.user, {})
      .then((checklistTemplate)=>{
        if(checklistTemplate) {
          this.$state.go('checklists.operational.edit', {checklistTemplate});
        }
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new checklist.'));
  }

  // loadChecklist() {
  //   _.each(this.checklists, checklist => {
  //     this.getChecklistSummary(checklist);
  //   });
  // }

  // getChecklistSummary(checklist) {
  //   let totalQuestions = checklist.questions ? checklist.questions.length : 0;
  //   let  answeredQuestions = checklist.answers ? checklist.answers.length :  0;
  //
  //   checklist.stats = {
  //     totalQuestions,
  //     answeredQuestions
  //   };
  // }

  editChecklist(checklistId) {
    this.$state.go('checklists.operational.edit',{checklistId});
  }

  deleteChecklist(checklistId) {
    this.operationalChecklistService.deleteChecklist(this.user.orgContext.id, checklistId).then(()=>{
      this.growl.success('Checklist Deleted');
      this.checklists = this.checklists.filter((checklist) => checklist.checklistId !== checklistId);
      this.noOperationalChecklist = this.checklists.length <= 0;
    })
    .catch(()=>{
      this.growl.error('Unable to delete checklist');
    });
  }

  createChecklistTemplate() {
    this.$state.go('checklists.library.list');
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }
      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  _search() {
    return this.fetchChecklists()
      .then((results) => {
        this.checklists = results;
        // this.loadChecklist();
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch SOPs.'))
      .finally(() => { this.searching = false; });
  }

  fetchChecklists() {
    this.searching = true;
    return this.operationalChecklistService.query(this.user, this.searchText,
      {status: this.statusToggle ? 'inprogress' : 'completed'},
      0, this.PAGE_SIZE)
      .then((checklists) => {
        this.noChecklists = !checklists.length;
        return checklists;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred loading Checklists.'))
      .finally(() => { this.searching = false; });
  }

  toggleStatus() {
    this.statusToggle = !this.statusToggle;
    this.fetchChecklists().then((checklists)=>{
      this.checklists = checklists;
      // this.loadChecklist();
    });
  }
}

module.exports = Constructor;
