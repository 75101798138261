'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRecallPlanConsigneeInstructions', {
    bindings: {
      recall: '=',
      $letterTemplate: '=',
      $phoneScript: '=',
      $customerInstructions: '=',
      user: '<'
    },
    template: require('./recall-plan.consignee-instructions.template.html'),
    controller: require('./recall-plan.consignee-instructions.controller.js'),
    controllerAs: 'vm'
  });
};
