module.exports = function(ngModule) {
  ngModule.component('cfMessage', {
    bindings: {
      user: '<'
    },
    template: require('./message.main.template.html'),
    controller: require('./message.main.controller.js'),
    controllerAs: 'vm'
  });
};
