class Controller {
  constructor($uibModal, $log, equipmentService, utils, $q, products, growl, confirmDeleteModal, confirmModal, S3, $window, V2_URL) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$log = $log;
    this.equipmentSvc = equipmentService;
    this.utils = utils;
    this.$q = $q;
    this.products = products;
    this.growl = growl;
    this.confirmDeleteModal = confirmDeleteModal;
    this.confirmModal = confirmModal;
    this.S3 = S3;

    this.page = 1;
    this.maxReached = false;
    this.searching = false;
    this.$window = $window;
    this.V2_URL = V2_URL;
    this.PAGE_SIZE = 100;
  }

  $onInit() {
    this.noEquipment = _.get(this, 'equipment.length') === 0;
    this.maxReached = _.get(this, 'equipment.length', 0) < this.PAGE_SIZE;
    this.search = _.debounce(this._search, 300);
    this.loadMore = _.debounce(() => this.fetchEquipment()
      .then((result) => { this.equipment = _.concat(this.equipment, result); }), 300);
  }

  _search() {
    this.page = 0;
    this.searching = true;
    return this.fetchEquipment()
      .then((result) => { this.equipment = result; })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch equipment.'))
      .finally(() => { this.searching = false; });
  }

  fetchEquipment() {
    this.searching = true;
    return this.equipmentSvc.query(this.user.orgContext.id, this.searchText,
      this.PAGE_SIZE * this.page++, this.PAGE_SIZE)
      .then((equipment) => {
        this.maxReached = equipment.length < this.PAGE_SIZE;

        return equipment;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred loading equipment.'))
      .finally(() => this.loading = false);
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  getMore() {
    _.set(this.equipmentSearch, 'searching', true);
    this.$q.when(this.equipmentSearch.getMore())
      .finally(() => _.set(this.equipmentSearch, 'searching', false));
  };

  openFile(file) {
    this.S3.openSignedUrlInNewTab(this.user.orgContext.id, file.key);
  }

  editEquipment() {
    this.$window.open(`${this.V2_URL}/environmental-monitoring`, '_blank');
    // this.$uibModal.open({
    //   component: 'cfEditEquipment',
    //   backdrop: 'static',
    //   resolve: {
    //     user: () => this.user,
    //     organizationId: () => this.organization.$id,
    //     equipment: () => equipment ? this.equipmentSvc.get(equipment._id || equipment.$id) : null
    //   }
    // }).result
    //   .then(updatedEquipment => {
    //     if (equipment) {
    //       const existing = _.find(this.equipment, {id: updatedEquipment._id || updatedEquipment.$id});
    //
    //       _.assign(existing, updatedEquipment);
    //
    //       return;
    //     }
    //
    //     this.equipment = this.equipment || [];
    //     this.equipment.push(updatedEquipment);
    //     this.noEquipment = false;
    //   })
    //   .catch(reason => {
    //     if (this.utils.isModalDismissalByUser(reason)) { return; }
    //
    //     this.growl.error('Error editing equipment.', {});
    //     this.$log.error(reason);
    //   });
  }

  deleteEquipment(equipment) {
    // First make sure no plans reference this equipment. This routine isn't very efficient, but it's an edge case
    // so we're going with it.
    this.products.getAll(this.user.orgContext.id).then(products => {
      return Promise.all(_.map(products, partialProduct => {
        return this.products.get(partialProduct.$id).then(fullProduct => {
          if (_.get(fullProduct, `equipment[${equipment.$id}]`)) {
            return fullProduct.brandName;
          }
        });
      }));
    }).then(prodReferencesArray => {
      let referencingProduct = _.find(prodReferencesArray, x => !!x);

      if (referencingProduct) {
        return this.confirmModal({
          title: 'Unable to Delete Equipment',
          body: `The plan <b>${referencingProduct}</b> references this equipment. Remove ` +
              'it from the plan before deleting it.',
          okText: 'Ok',
          hideCancelButton: true
        });
      } else {
        return this.confirmDeleteModal('Equipment', {
          body: 'Are you sure you want to delete <strong>' + equipment.name + '</strong>?'
        }).then(() => {
          this.equipmentSvc.delete(equipment.$id).then(() => {
            _.remove(this.equipment, {$id: equipment.$id});
            this.noEquipment = _.get(this, 'equipment.length') === 0;
            this.growl.success('Equipment deleted');
          }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete equipment.'));
        });
      }
    });
  }

}

module.exports = Controller;
