class Controller {
  constructor($scope, hazardsService, productCategories, growl, utils) {
    'ngInject';

    this.$scope = $scope;
    this.hazardsService = hazardsService;
    this.productCategories = productCategories;
    this.growl = growl;
    this.utils = utils;
  }

  $onInit() {
    this.selected = {};

    this.templatesArray = _.map(this.templates, (t, id) => ({name: t.name, value: id}));
    this.$scope.$watch('vm.selected', val => {
      if (this.selectedSub) {
        this.selectedSub.$destroy();
        this.selectedSub = null;
      }
      let key = _.findKey(val, v => !!v);

      if (key) {
        this.selectCat(key);
      }
    }, true);

    this.storageCondtionsArray = [];
    _.each(this.$foodCategories, cat => {
      _.each(cat.subCategories, sub => {
        if (_.findIndex(this.storageCondtionsArray, x => x.name === sub.storageConditions) === -1) {
          this.storageCondtionsArray.push({name: sub.storageConditions, value: sub.storageConditions});
        }
      });
    });
  }

  selectCat(id) {
    this.deselectSub();
    this.productCategories.$getCategory(id).then($cat => {
      this.selectedCategory = $cat;
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to select category'));
  }

  selectSub(id) {
    this.productCategories.$getSubCategory(this.selectedCategory.$id, id).then($sub => {
      this.selectedSub = $sub;
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to select subCategory'));
  }

  newCategory() {
    this.productCategories.$pushCategory().then($cat => {
      this.selectedCategory = $cat;
    });
  }

  newSubCategory() {
    this.productCategories.$pushSubCategory(this.selectedCategory.$id).then($subCat => {
      this.selectedSub = $subCat;
    });
  }

  saveCategory() {
    this.selectedCategory.createdBy = this.selectedCategory.createdBy || this.user.uid;
    this.selectedCategory.createdOn = this.selectedCategory.createdOn || new Date().getTime();
    return this.selectedCategory.$save().then(() => {
      if (this.categoryForm) {
        this.categoryForm.$setPristine();
      }
      this.growl.success('Category saved');
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to save category.'));
  }

  saveSubCategory() {
    this.selectedSub.createdBy = this.selectedSub.createdBy || this.user.uid;
    this.selectedSub.createdOn = this.selectedSub.createdOn || new Date().getTime();
    return this.selectedSub.$save().then(() => {
      if (this.subcategoryForm) {
        this.subcategoryForm.$setPristine();
      }
      this.growl.success('SubCategory saved');
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to save subCategory.'));
  }

  cancel() {
    this.deselectSub();
    this.deselectCat();
    this.selected = null;
  }

  deselectSub() {
    if (!this.selectedSub) { return; }
    this.selectedSub.$destroy();
    this.selectedSub = null;
  }

  deselectCat() {
    if (!this.selectedCategory) { return; }
    this.selectedCategory.$destroy();
    this.selectedCategory = null;
  }
}

module.exports = Controller;
