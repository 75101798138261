class Constructor {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  $onInit() {}
}

module.exports = Constructor;