  class Constructor {
    constructor(users, utils, $state, confirmDeleteModal, growl, $q, productCustomersService) {
      'ngInject';

      this.users = users;
      this.utils = utils;
      this.$state = $state;
      this.confirmDeleteModal = confirmDeleteModal;
      this.growl = growl;
      this.$q = $q;
      this.productCustomersService = productCustomersService;
    }

    $onInit() {
      if(this.productCustomers.length > 0) {
        this.noCustomers = false;
      } else {
        this.noCustomers = true;
      }
    }

    newCustomer() {
      this.$state.go('ingredients.customers.customer.overview', {productCustomersId: 'new'})
      .catch(() => this.growl.error('Unable to create new Customer'));
    }

    editCustomers(productCustomersId) {
      this.$state.go('ingredients.customers.customer.overview',{productCustomersId});
    }

    deleteCustomers(productCustomersId,index) {
      this.productCustomersService.deleteProductCustomer(this.user.orgContext.id, productCustomersId).then(()=>{
        this.productCustomers.splice(index, 1);
        if(!this.productCustomers.length) {
          this.noCustomers = true;
        }
        this.growl.success('Customer Deleted');
      })
      .catch(()=>{
        this.growl.error('Unable to delete Customer');
      });
    }
}

  module.exports = Constructor;
