module.exports = function(ngModule) {
  ngModule.component('cfListUsers', {
    bindings: {
      users: '=',
      $invitations: '<',
      productConstraints: '<',
      user: '<'
    },
    template: require('./users.settings.users.list.template.html'),
    controller: require('./users.settings.users.list.controller.js'),
    controllerAs: 'vm'
  });
};
