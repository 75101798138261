require('./administration.roles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfRoles', {
    bindings: {
      user: '<'
    },
    template: require('./administration.roles.template.html'),
    controller: require('./administration.roles.controller.js'),
    controllerAs: 'vm'
  });
};