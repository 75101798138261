class CfSelectArray {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-select-array.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      label: '@?',           // The label text
      name: '@',             // The name of the form control
      helpText: '@',         // A help text sub-script
      options: '=',          // The select options
      isRequired: '=',       // Is the field required (true / false)
      isReadonly: '=',       // Is the field read-only (true / false)
      onChange: '&',         // Callback onChange
      placeholder: '@',      // Select placeholder
      size: '@',              // Input size (e.g. md, sm, lg)
      containerClass: '=',  // Override class (default to g-mb-20)
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  link(scope, elem, attrs, ngModelCtrl) {
    let vm = scope.vm;
    let $select = elem.find('select');

    let listener = scope.$watch(() => ngModelCtrl.$viewValue, (val) => {
      if (_.isObject(val)) {
        let selectedOption = _.find(vm.options, (option) =>
          _.isEqual(_.omit(option.value, ['$$hashKey']), _.omit(val, ['$$hashKey'])));

        if (!selectedOption) { return; }
        let $selectedOption = _.find($select[0].options, (option) =>
          _.isEqual(option.innerText, selectedOption.name));

        $select.val($selectedOption.value);
      }

      listener();
    });
  }
}

module.exports = CfSelectArray;
