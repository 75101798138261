const requireAuth = require('../../_services/firebase-resolve.service').requireAuth;
const waitForAuth = require('../../_services/firebase-resolve.service').waitForAuth;

module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider.state('company', {
      abstract: true,
      url: '/company',
      template: '<div ui-view></div>',
      resolve: {
        currentAuth: requireAuth,
        user: (currentAuth, authentication) => authentication.user.$loaded(),
        company: (user, organizations) => organizations.$get(user.orgContext.id)
      },
      data: {menuId: 'dashboard'}
    }).state('company.edit', {
      abstract: true,
      url: '/edit',
      template: '<cf-edit-company company="$resolve.company" user="$resolve.user"></cf-edit-company>',
      resolve: {
      }
    }).state('company.edit.upload', {
      url: '/upload',
      template: '<cf-org-upload-files files="$resolve.files " ' +
      'organization="$resolve.company" user="$resolve.user"></cf-org-upload-files>',
      resolve: {
        files: (company, organizations) => organizations.externalFiles(company.$id)
      }
    }).state('company.edit.overview', {
      url: '',
      template: '<cf-company-overview company="$resolve.company" user="$resolve.user"></cf-company-overview>'
    }).state('company.edit.safetyTeam', {
      url: '/safetyTeam',
      template: '<cf-list-safety-team safety-team="$resolve.safetyTeam" company="$resolve.company" ' +
      'members="$resolve.members" user="$resolve.user"></cf-list-safety-team>',
      resolve: {
        safetyTeam: (company, $firebaseArray) =>
          $firebaseArray(company.$ref().child('safetyTeam')).$loaded(),
        members: function(company, $firebaseArray, $q, users) {
          return $firebaseArray(company.$ref().child('members')).$loaded()
            .then(function(members) {
              return $q.all(_.map(members, function(member) {
                return users.getName(member.$id).then(function(name) {
                  return {$id: member.$id, name: name};
                });
              }));
            });
        }
      }
    }).state('company.edit.laboratories', {
      abstract: true,
      url: '/laboratories',
      template: '<div ui-view></div>'
    }).state('company.edit.laboratories.list', {
      url: '/laboratories',
      template: '<cf-laboratories laboratories="$resolve.laboratories" company="$resolve.company" ' +
      'user="$resolve.user"></cf-laboratories>',
      resolve: {
        laboratories: function(fbutil, company) {
          return fbutil.$firebaseArrayExt(company.$ref().child('laboratories')).$loaded();
        }
      },
      data: {
        titleBar: {
          helpModal: require('../_components/laboratories/modals/help/company.laboratories-help.template.html')
        },
        ask: {
          channel: 'Plan Reviews',
          tags: [
            {name: 'Company'},
            {name: 'Laboratories'}
          ]
        }
      }
    }).state('grantOrgAccessRequest', {
      url: '/orgGrant/:requestId',
      template: '<cf-grant-org-read-request user="vm.user"></cf-grant-org-read-request>',
      controller: function($state, $location, user) {
        if (!user) {
          $state.go('authentication.signin', {returnUrl: encodeURIComponent($location.path())});
          return;
        }
        this.user = user;
      },
      resolve: {
        currentAuth: waitForAuth,
        user: function(currentAuth, authentication) {
          return authentication.user && authentication.user.$loaded();
        }
      },
      controllerAs: 'vm'
    }).state('rejectOrgAccessRequest', {
      url: '/orgReject/:requestId',
      template: '<cf-reject-org-read-request user="vm.user"></cf-reject-org-read-request>',
      controller: function(user, $state, $location) {
        if (!user) {
          $state.go('authentication.signin', {returnUrl: encodeURIComponent($location.path())});
          return;
        }
        this.user = user;
      },
      resolve: {
        currentAuth: waitForAuth,
        user: function(currentAuth, authentication) {
          return authentication.user && authentication.user.$loaded();
        }
      },
      controllerAs: 'vm'
    });
  })/*.run((menus) => {
    'ngInject';

/!*
    menus.addMenuItem('dashboard', {
      title: 'Company',
      state: 'company.overview',
      position: 1,
      isPublic: false,
      perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP],
      claims: [authorization.claims.ORGANIZATION_READ]
    });
*!/

  })*/;
};
