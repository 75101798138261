'use strict';

module.exports = function(ngModule) {
  ngModule.factory('productGroups', function(fbutil, User, $q, $firebaseObject, products, recentLettersCache) {
    'ngInject';

    return {
      $get: function(groupId) {
        return $firebaseObject(fbutil.ref('productGroups', groupId)).$loaded();
      },

      get: function(groupId) {
        return fbutil.ref('productGroups', groupId).once('value').then(fbutil.getValueOrDefault);
      },

      addMember: function(groupId, productId) {
        return fbutil.ref('productGroups', groupId, 'members', productId).set('true');
      },

      removeMember: function(groupId, productId) {
        return fbutil.ref('productGroups', groupId, 'members', productId).remove();
      },

      delete: function(groupId) {
        return fbutil.ref('productGroups', groupId).remove();
      },

      /**
       * Do any products in the group have recent letters or pending review requests?
       * @param {string} groupId Group to search
       * @param {string} productId An optional product id to omit (usually the product who is calling the routine)
       * @return {Promise} A promise that resolves to true or false.
       */
      hasRecentOrPendingLetter: function(groupId, productId) {
        let cachedVal = recentLettersCache.get(groupId + '_' + (productId || ''));

        if (cachedVal) { return $q.when(cachedVal); }
        return this.get(groupId).then(group => {
          return $q.all(_.map(group.members, (val, curProdId) => {
            return curProdId === productId ? $q.when(false) : products.hasRecentOrPendingLetter(curProdId);
          })).then(results => {
            let result = _.some(results);

            recentLettersCache.put(groupId, result);
            return result;
          });
        });
      },

      /**
       * Set the group Id for the product. First see if it's a member of another group. If so, remove that group.
       * @param {string} productId Product to assign to a group
       * @param {string} groupId   Product group to assign to (may be null/undefined)
       * @returns {Promise} Promise resolves once all changes are made.
       */
      setGroup: function(productId, groupId) {
        return fbutil.ref('products', productId, 'groupId').once('value').then(prevGroup => {
          if (prevGroup.exists()) {
            return this.removeMember(prevGroup.val(), productId);
          }
        }).then(() => {
          return groupId && this.get(groupId);
        }).then(group => {
          return  $q.all([
            fbutil.ref('products', productId, 'groupId').set(groupId || null),
            fbutil.ref('products', productId, 'groupName').set(_.get(group, 'groupName') || null),
            groupId && this.addMember(groupId, productId)
          ]);
        });
      }
    };
  });
};
