module.exports = function(ngModule) {
  ngModule.component('frChecklistLibraryEdit', {
    bindings: {
      user: '<',
      company: '<',
      checklistTemplate: '<',
      formConstants: '<'
    },
    template: require('./checklists.library.edit.template.html'),
    controller: require('./checklists.library.edit.controller.js'),
    controllerAs: 'vm'
  });
};
