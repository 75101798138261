'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfSafetyTeam', {
    bindings: {
      safetyTeam: '=',
      members: '=?',
      company: '=',
      user: '<'
    },
    template: require('./safety-team.list.template.html'),
    controller: require('./safety-team.list.controller.js'),
    controllerAs: 'vm'
  });
};
