'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRegisterEvents', {
    bindings: {
      user: '<?'
    },
    template: require('./events.register.template.html'),
    controller: require('./events.register.controller'),
    controllerAs: 'vm'
  });
};