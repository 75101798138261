class Constructor {
  constructor(
    $log,
    growl,
    organizations,
    $uibModal,
    $q,
    $stateParams,
    preventDirtyNav,
    $timeout,
    users,
    authorization,
    utils
  ) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.organizations = organizations;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.$stateParams = $stateParams;
    this.preventDirtyNav = preventDirtyNav;
    this.$timeout = $timeout;
    this.users = users;
    this.authorization = authorization;
    this.utils = utils;

    this.admins = [];
    this.orgsToAdd = {};
    this.orgsToDelete = {};
    this.newPrimaryOrgId = null;
    this.loadingRoles = false;
  }

  $onInit() {
    if (this.editUserForm) {
      this.$timeout(() => {
        this.preventDirtyNav(this.editUserForm, 'You have unsaved user changes.', () => this.save());
      });
    }

    this.fullControl = this.$stateParams.full === 'true' || this.user.isCfAdmin();
    this.orgNames = {};

    // Only a CF admin can control users at the root level.
    if (this.user.isCfAdmin()) {
      this.loadingRoles = true;
      this.roles = {};
      this.$q
        .all(
          _.map(this.reviewsAccess.organizations, (val, orgId) =>
            this.organizations.getRoles(orgId).then((roles) => this.roles[orgId] = roles)
          )
        )
        .finally(() => this.loadingRoles = false);
    } else {
      this.organizations
        .getRoles(this.user.orgContext.id)
        .then((roles) => this.roles = _.pickBy(roles, {visible: true}))
        .finally(() => this.loadingRoles = false);
    }

    this.userSearch
      .search()
      .then(
        (result) =>
          this.admins = _.filter(
            result,
            (user) => _.get(_.find(user.reviews.organizations, {id: this.user.orgContext.id}), 'role') === 'admin'
          )
      );
  }

  save($user) {
    let savePromise;

    this.saving = true;
    if (this.fullControl) {
      let savePromises = _.concat(
        _.map(this.orgsToAdd, (org) => this.authorization.setOrganizationRole($user.$id, org.id, org.role)),
        _.map(this.orgsToAdd, (org, key) => this.organizations.addMember(key, $user.$id)),
        _.map(this.orgsToDelete, (org, key) => this.authorization.removeFromOrganization($user.$id, key)),
        _.map(this.orgsToDelete, (org, key) => this.organizations.removeMember(key, this.editUser.$id)),
        _.map(
          _.pickBy(this.reviewsAccess.organizations, (org, key) => !this.orgsToDelete[key]),
          (org, key) => this.authorization.setOrganizationRole($user.$id, key, org.role)
        )
      );

      if (!_.isEmpty(this.newPrimaryOrgId)) {
        savePromises.push(this.users.setOrganizationId(this.editUser.$id, this.newPrimaryOrgId));
        savePromises.push(this.authorization.setOrgContext(this.editUser.$id, this.newPrimaryOrgId));
      }

      savePromises.push($user.$save());
      savePromise = this.$q.all(savePromises);
    } else {
      if (this.orgRole !== 'admin' && this.admins.length <= 1 && $user.$id === _.first(this.admins).$id) {
        this.error = 'A facility must have at least one Administrator. Unable to update user.';
        this.saving = false;

        return;
      }

      savePromise = this.authorization.setOrganizationRole($user.$id, this.user.orgContext.id, this.orgRole);
    }

    savePromise
      .then(() => {
        this.error = null;
        this.growl.success('User saved successfully.');
        this.orgsToAdd = {};
        this.orgsToDelete = {};
        if (this.editUserForm) {
          this.editUserForm.$setPristine();
        }
      })
      .catch((err) => {
        this.$log.error('Error saving user.', this.$log.toString(err));
        this.growl.error('Unable to save user. Please try again or contact customer support.');
      })
      .finally(() => this.$timeout(() => this.saving = false, 1000));
  }

  orgName(orgId) {
    if (this.orgNames[orgId]) {
      return this.orgNames[orgId];
    }

    this.orgNames[orgId] = '';
    this.organizations.getName(orgId).then((name) => this.orgNames[orgId] = name || 'Not Found');
  }

  removeOrg(orgId) {
    if (this.orgsToAdd[orgId]) {
      return delete this.orgsToAdd[orgId];
    }

    this.orgsToDelete[orgId] = true;
  }

  changePrimaryOrg(newOrgId) {
    this.newPrimaryOrgId = newOrgId;
  }

  addOrg() {
    this.$uibModal
      .open({
        component: 'cfUserAddOrg',
        backdrop: 'static',
        resolve: {
          user: () => this.user,
          currentOrgIds: () => this.reviewsAccess.organizations,
        },
      })
      .result.then((org) => {
        if (this.orgsToDelete[org.id]) {
          return delete this.orgsToDelete[org.id];
        }

        return this.organizations
          .getRoles(org.id)
          .then((roles) => this.roles[org.id] = _.pickBy(roles, {visible: true}))
          .then(() => this.orgsToAdd[org.id] = org);
      })
      .catch((err) => {
        if (this.utils.isModalDismissalByUser(err)) {
          return;
        }

        this.growl.error('Unable to add organization. Please try again or contact customer support.');
        this.$log.error('Unable to add organization to user.', this.$log.toString(err));
      });
  }

  getPrimaryOrgOptions() {
    return _.assign(
      {},
      this.orgsToAdd,
      _.pickBy(this.reviewsAccess.organizations, (org, key) => !this.orgsToDelete[key]) || {}
    );
  }

  isEmpty(obj) {
    return _.isEmpty(obj);
  }
}

module.exports = Constructor;
