module.exports = function(ngModule) {
  ngModule.constant('allergens', [
    {id: 'eggs', name: 'Eggs'},
    {id: 'fish', name: 'Fish'},
    {id: 'milk', name: 'Milk'},
    {id: 'peanuts', name: 'Peanuts'},
    {id: 'soy', name: 'Soy'},
    {id: 'treenuts', name: 'Tree Nuts'},
    {id: 'wheat', name: 'Wheat'},
    {id: 'shellfish', name: 'Shellfish'},
    {id: 'sesame', name: 'Sesame'},
    {id: 'other', name: 'Other'}
  ]);
};
