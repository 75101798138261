'use strict';

module.exports = function(ngModule) {
  ngModule.directive('cfResponsiveHeader', function() {
    return {
      restrict: 'A',
      link: function(scope, el) {
        $.HSCore.components.HSHeader.init(el);
      }
    };
  });
};
