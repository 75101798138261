'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfNewPassword', {
    bindings: {
      actionCode: '@'
    },
    template: require('./authentication.new-password.template.html'),
    controller: require('./authentication.new-password.controller'),
    controllerAs: 'vm'
  });
};
