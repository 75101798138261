require('./onboarding.pick-hazards.scss');

module.exports = function(ngModule) {
  ngModule.component('cfOnboardingPickHazards', {
    bindings: {
      user: '<',
      company: '<',
      typicalHazards: '<',
      productCategory: '<?',
      productSubCategory: '<?',
      $product: '<'
    },
    template: require('./onboarding.pick-hazards.template.html'),
    controller: require('./onboarding.pick_hazards.controller'),
    controllerAs: 'vm'
  });
};
