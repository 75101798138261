class Constructor {
  constructor(constantsService, growl, $log, confirmModal, $window) {
    'ngInject';

    this.constantsService = constantsService;
    this.growl = growl;
    this.$log = $log;
    this.confirmModal = confirmModal;
    this.$window = $window;
    this.frequencies = [
      {
        text: 'Weekly',
        runGroupId: 1
      },{
        text: 'Bi-Weekly',
        runGroupId: 2
      },{
        text: 'Monthly',
        runGroupId: 3
      }
    ];
  }

  $onInit() {
    this.requestFilesHelp = '<p>Send a one-time request for files to a supplier. Go to the <b>Suppliers</b> tab, find the supplier in the list, click the menu, and choose <i>Request Files</i>.</p>';
    this.isNew = this.$tracking.$value === null;
    this.selectedCategories = this.isNew || !this.$tracking.fileCategories ? [] :
      _.map(this.$tracking.fileCategories.split(','), _.toNumber);
    this.selectedCompanyCategories = this.isNew || !this.$tracking.companyFileCategories ? [] :
      _.map(this.$tracking.companyFileCategories.split(','), _.toNumber);
    this.runGroupId = this.$tracking.runGroupId;

    this.constantsService.get('fileTypes').then(fileTypes => {
      this.externalFileTypes = _.values(_.omitBy(fileTypes, fc => fc.companyLevel));
      this.externalCompanyFileTypes = _.values(_.pickBy(fileTypes, fc => fc.companyLevel));
    });
  }

  $onDestroy() {
    this.$tracking.$destroy();
  }

  stop() {
    this.confirmModal({
      title: 'Stop Supplier File Tracking Program?',
      body: 'Are you sure you want to stop your supplier tracking program? Your suppliers will no longer ' +
      'receive notifications of missing files.',
      okText: 'Stop Tracking Program',
      cancelText: 'Cancel'
    }).then(() => {
      this.$tracking.runGroupId = null;
      this.$tracking.$save().then(() => {
        this.runGroupId = this.$tracking.runGroupId;
        this.growl.success('Supplier file tracking program removed.');
      }).catch(err => {
        this.growl.error('An error occurred removing your supplier file tracking program. Please contact customer ' +
          'support if this continues');
        this.$log.error('An error occurred removing your supplier file tracking program', this.$log.toString(err));
      });
    });
  }

  saveRequired() {
    this.$tracking.fileCategories = this.selectedCategories.join(',');
    this.$tracking.companyFileCategories = this.selectedCompanyCategories.join(',');
    this.saving = true;
    this.$tracking.$save().then(() => {
      this.growl.success('Required Files Saved.');
    }).catch(err => this.utils.defaultErrorHandler(err))
    .finally(() => { this.saving = false; });
  }

  saveTracker() {
    if (this.selectedCategories.length + this.selectedCompanyCategories.length <= 0) {
      this.confirmModal({
        title: 'No Required Files Identified',
        body: 'You cannot setup a file tracker program until you first identify your required files above.',
        okText: 'Close',
        hideCancelButton: true,
      });
      return;
    }

    this.$tracking.runGroupId = this.runGroupId;
    this.$tracking.$save().then(() => {
      this.$log.info(`Supplier tracking program started by ${this.user.orgContext.companyName} with run group ${this.$tracking.runGroupId}`, null, {notify: true});
      this.growl.success('Supplier file tracking program saved.');
    }).catch(err => {
      this.growl.error('An error occurred saving your supplier file tracking program. Please contact customer ' +
        'support if this continues');
      this.$log.error('An error occurred saving your supplier file tracking program', this.$log.toString(err));
    });
  }
}

module.exports = Constructor;
