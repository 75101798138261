module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceServicesAvailable', {
    bindings: {
      user: '<',
      organization: '<',
      defaultServices: '<'
    },
    template: require('./marketplace.services-available.template.html'),
    controller: require('./marketplace.services-available.controller.js'),
    controllerAs: 'vm'
  });
};
