'use strict';

module.exports = function (ngModule) {
  function controlTypeFilter(steps, controlType) {
    var retSteps = [];
    _.each(steps, function (step) {
      if (step.hazards) {
        var hasHazardType = false;
        _.each(step.hazards, function (hazard) {
          if ((hazard.control && hazard.control[controlType])) {
            hasHazardType = true;
          }
        });

        if (hasHazardType) {
          retSteps.push(step);
        }
      }
    });

    return retSteps;
  }

  ngModule.filter('sanitationControlSteps', function () {
    //noinspection JSLint
    'ngInject';

    return function (steps) {
      return controlTypeFilter(steps, 'typeSanitation');
    };
  });

  ngModule.filter('supplierControlSteps', function () {
    return function (steps) {
      return controlTypeFilter(steps, 'typeSupplyChain');
    };
  });

  ngModule.filter('processControlSteps', function () {
    return function (steps) {
      return controlTypeFilter(steps, 'typeCcp');
    };
  });
};