module.exports = function(ngModule) {
  ngModule.component('cfCustomersList', {
    bindings: {
      user: '<',
      productCustomers: '<',
      customerSearch: '<',
    },
    template: require('./ingredients.product-customers.template.html'),
    controller: require('./ingredients.product-customers.controller.js'),
    controllerAs: 'vm'
  });
};
