require('./_css');

module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.administration',
    ngModule = angular.module(moduleName, []);

  require('./_config')(ngModule);
  require('./_directives')(ngModule);
  require('./_components')(ngModule);
  require('./_services')(ngModule);

  return moduleName;
};
