class Controller {
  constructor(confirmDeleteModal, growl, $state, recallService, utils) {
    'ngInject';

    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.$state = $state;
    this.recallService = recallService;
    this.utils = utils;
  }

  $onInit() {
    this.customerTypesMap = _.keyBy(this.customerTypes, 'value');
  }

  remove(fbArray, entry, name) {
    this.confirmDeleteModal(name).then(() => {
      if (entry) {
        fbArray.$remove(entry).then(() => {
          this.growl.success('Customer removed successfully.');
        }).catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete customer'));
      }
    });
  }

  editCustomer(customer) {
    this.$state.go('recall.consignees.edit',
      {customerId: customer ? customer.$id : this.recallService.pushCustomer(this.user.orgContext.id).key});
  }

}

module.exports = Controller;
