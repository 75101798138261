class Controller {
  constructor($state, authorization) {
    'ngInject';

    this.$state = $state;
    this.authorization = authorization;
  }

  $onInit() {
  }

  getHeading() {
    return this.productCustomer.firstName === null ? 'New Customer' : this.productCustomer.firstName;
  }
}

module.exports = Controller;
