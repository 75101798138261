'use strict';

module.exports = function($log, $firebaseArray, $q, growl, fbutil, S3) {
  'ngInject';

  var vm = this, uploadProgress = {};

  function refreshUploadProgress() {
    if (!vm.newFiles.length) { return 0; }

    var progress = _.reduce(uploadProgress, (sum, n) => {
      return sum + n;
    }, 0);

    vm.uploadProgress = _.round(progress / vm.newFiles.length);
  }

  vm.$onInit = function() {
    vm.member = vm.resolve.member;
    vm.members = vm.resolve.members;
    vm.company = vm.resolve.company;

    vm.uploadProgress = 0;
    vm.newFiles = [];
    vm.removeFiles = [];

    $firebaseArray(fbutil.ref('organizations', vm.company.$id, 'safetyTeam', vm.member.$id, 'media')).$loaded()
      .then((files) => {
        vm.existingFiles = files;
      });
  };

  vm.save = function() {
    var filePromises = [];

    vm.member.$save()
      .then(() => {
        vm.uploading = vm.newFiles.length > 0;
        _.each(vm.newFiles, (file, idx) => {
          filePromises.push(
            S3.uploadOrgFile(vm.company.$id, 'safetyTeam/' + vm.member.$id + '/media/' + file.name, file, (event) => {
              uploadProgress[idx] = parseInt(100.0 * event.loaded / event.total, 10);
              refreshUploadProgress();
            })
              .then((result) => {
                return vm.existingFiles.$add(result);
              })
          );
        });

        _.each(vm.removeFiles, (file) => {
          filePromises.push(
            S3.deleteFile(vm.company.$id, file.key).then(() => {
              return vm.existingFiles.$remove(file);
            })
          );
        });

        return $q.all(filePromises);
      })
      .then(() => {
        vm.modalInstance.close(vm.member);
      })
      .catch((err) => {
        vm.modalInstance.dismiss(err);
      })
      .finally(() => {
        vm.uploading = false;
      });
  };

  vm.cancel = function() {
    vm.modalInstance.dismiss('cancel');
  };

  vm.memberSelected = function($item, $model, $label, $event, member) {
    member.uid = $item.$id;
    member.nonMember = false;
  };
};
