require('./plan-media-list.scss');

module.exports = function(ngModule) {
  ngModule.component('frPlanMediaList', {
    bindings: {
      remoteDirectory: '@',
      organization: '<',
      noFilesText: '@?',
      categories: '<?',
      $branch: '<',
      branchName: '@',
      categoryId: '<',
      hideFileType: '<',
      askInPlan: '<'
    },
    template: require('./plan-media-list.template.html'),
    controller: require('./plan-media-list.controller.js'),
    controllerAs: 'vm'
  });
};
