module.exports = function(ngModule) {
  ngModule.component('cfProductReports', {
    bindings: {
      $product: '<',
      user: '<'
    },
    template: require('./reports.product-reports.template.html'),
    controller: require('./reports.product-reports.controller.js'),
    controllerAs: 'vm'
  });
};
