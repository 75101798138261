'use strict';

module.exports = function (ngModule) {
  ngModule.factory('userGroups', function ($http) {
    'ngInject';

    return {
        getAllUserGroups(organizationId) {
            return $http.get('/group/organization/' + organizationId)
                .then(result => result.data?.data);
        },
        create(organizationId, groupObj) {
            return $http.post('/group/organization/' + organizationId,{...groupObj, organizationId})
                .then(result => result.data?.data);
        },
        update(organizationId, groupId,groupObj) {
            return $http.patch(`/group/${groupId}/organization/` + organizationId,{...groupObj, organizationId})
                .then(result => result.data?.data);
        },
        deleteGroup(organizationId,groupId) {
            return $http.delete(`/group/${groupId}/organization/` + organizationId)
                .then(result => result.data?.data);
        },
    };
  });
};
