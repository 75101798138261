module.exports = function(ngModule) {
  ngModule.config(
    function($stateProvider) {
      'ngInject';

      $stateProvider.state('products.edit.planAnalysis', {
        url: '/planAnalysis',
        abstract: true,
        template: '<cf-product-plan-analysis product="$resolve.product"></cf-product-plan-analysis>'
      }).state('products.edit.planAnalysis.questionnaire', {
        url: '/questionnaire',
        template: '<cf-product-questionnaire product="$resolve.product" facilities="$resolve.facilities" ' +
          'user="$resolve.user" is-read-only="$resolve.isReadOnly" ' +
          'company="$resolve.productOrganization"></cf-product-questionnaire>',
        resolve: {
          facilities: (organizations, productOrganization) =>
            organizations.$getFacilities(productOrganization.$id),
          productLocal: (product, products) => products.$get(product.$id)
        },
        params: {
          newProdFromService: false
        }
      }).state('products.edit.planAnalysis.properties', {
        url: '/properties',
        template: '<cf-product-properties product="$resolve.productLocal" user="$resolve.user" ' +
          'is-read-only="$resolve.isReadOnly"></cf-product-properties>',
        resolve: {
          productLocal: (product, $firebaseObject) => $firebaseObject(product.$ref()).$loaded()
        }
      });
    }
  );
};
