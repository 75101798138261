module.exports = function(ngModule) {
  ngModule.component('cfGmpCategory', {
    bindings: {
      user: '<',
      $gmps: '<',
      category: '<',
      companyCategory: '=',
      companyQuestions: '='
    },
    template: require('./company.gmp-category.template.html'),
    controller: require('./company.gmp-category.controller.js'),
    controllerAs: 'vm'
  });
};
