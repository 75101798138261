module.exports = function(ngModule) {
  ngModule.component('cfPlanChooser', {
    bindings: {
      user: '<',
      stripeConstants: '<',
      company: '<'
    },
    template: require('./users.plan-chooser.template.html'),
    controller: require('./users.plan-chooser.controller.js'),
    controllerAs: 'vm'
  });
};
