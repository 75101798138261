'use strict';

const waitForAuth = require('../../_services/firebase-resolve.service').waitForAuth;

module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider.state('events', {
      abstract: true,
      url: '/events',
      template: '<div ui-view></div>',
      resolve: {
        currentAuth: waitForAuth,
        user: function(currentAuth) {
          return currentAuth && currentAuth.$loaded();
        }
      }
    }).state('events.register', {
      url: '/register',
      template: '<cf-register-events user="$resolve.user"></cf-register-events>'
    });
  });
};