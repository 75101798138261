require('./users.request-files-modal.scss');

module.exports = function(ngModule) {
  ngModule.component('cfRequestFiles', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./users.request-files-modal.template.html'),
    controller: require('./users.request-files-modal.controller.js'),
    controllerAs: 'vm'
  });
};
