module.exports = function(ngModule) {
  ngModule.component('cfGmpAudits', {
    bindings: {
      user: '<',
      $gmps: '<'
    },
    template: require('./gmps.audits.template.html'),
    controller: require('./gmps.audits.controller.js'),
    controllerAs: 'vm'
  });
};
