module.exports = function(ngModule) {
  ngModule.component('cfRadioListModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./radio-list-modal.template.html'),
    controller: require('./radio-list-modal.controller.js'),
    controllerAs: 'vm'
  });
};
