'use strict';

module.exports = function(ngModule) {
  ngModule.factory('verifications', function($q, $firebaseObject, $firebaseArray, fbutil) {
    'ngInject';

    return {
      get: function(verificationId) {
        return $firebaseObject(fbutil.ref('verifications', verificationId)).$loaded();
      },
      getTestResults: function(verificationId) {
        return $firebaseArray(fbutil.ref('verifications', verificationId, 'testResults')).$loaded();
      }
    };
  });
};
