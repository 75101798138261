module.exports = function(ngModule) {
  class CompanyService {
    constructor(orgTypes) {
      'ngInject';

      this.orgTypes = orgTypes;
    }

    /**
     * Does the company have facilities based on it's company type(s)
     * @param {Object} companyTypes The company types (e.g. {regulator: true, manufacturer: true})
     * @return {boolean} Whether or not the company has facilities.
     */
    hasFacilities(companyTypes) {
      return companyTypes && (companyTypes[this.orgTypes.MANUFACTURER.id] || companyTypes[this.orgTypes.RESTAURANT.id]);
    }

    /**
     * Does the company require a GMP survey based on it's company type(s)
     * @param {Object} companyTypes The company types (e.g. {regulator: true, manufacturer: true})
     * @return {boolean} Whether or not the company requires a GMP survey
     */
    requiresGmpSurvey(companyTypes) {
      return companyTypes && (companyTypes[this.orgTypes.MANUFACTURER.id] || companyTypes[this.orgTypes.RESTAURANT.id]);
    }

    /**
     * Does the company have SOPs based on it's company type(s)
     * @param {Object} companyTypes The company types (e.g. {regulator: true, manufacturer: true})
     * @return {boolean} Whether or not the company has SOPs.
     */
    hasSops(companyTypes) {
      return companyTypes && (companyTypes[this.orgTypes.MANUFACTURER.id] || companyTypes[this.orgTypes.RESTAURANT.id]);
    }

    /**
     * Does the company have a safety team based on it's company type(s)
     * @param {Object} companyTypes The company types (e.g. {regulator: true, manufacturer: true})
     * @return {boolean} Whether or not the company has a safety team.
     */
    hasSafetyTeam(companyTypes) {
      return companyTypes && companyTypes[this.orgTypes.MANUFACTURER.id];
    }

    /**
     * Does the company have a laboratory based on it's company type(s)
     * @param {Object} companyTypes The company types (e.g. {regulator: true, manufacturer: true})
     * @return {boolean} Whether or not the company has a laboratory
     */
    hasLaboratory(companyTypes) {
      return companyTypes && companyTypes[this.orgTypes.LABORATORY.id];
    }

    /**
     * Does the company gets reports based on it's company type(s)
     * @param {Object} companyTypes The company types (e.g. {regulator: true, manufacturer: true})
     * @return {boolean} Whether or not the company gets reports
     */
    getsReports(companyTypes) {
      return companyTypes && (companyTypes[this.orgTypes.MANUFACTURER.id] || companyTypes[this.orgTypes.RESTAURANT.id]);
    }

    /**
     * Is the company responsible for a food safety plan.
     * @param {Object} companyTypes The company types (e.g. {regulator: true, manufacturer: true})
     * @return {boolean} Whether or not the company is responsible for a food safety plan
     */
    isResponsibleForSafetyPlan(companyTypes) {
      return companyTypes && (companyTypes[this.orgTypes.MANUFACTURER.id] || companyTypes[this.orgTypes.RESTAURANT.id]);
    }
  }

  ngModule.service('companyService', CompanyService);
};
