class Constructor {
  constructor(utils, $q, $scope, productCustomersService,batchService, InventoryItemsSearch,batchRunsService, moment, $state) {
    'ngInject';

    this.utils = utils;
    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.productCustomersService = productCustomersService;
    this.batchService = batchService;
    this.batchRunsService = batchRunsService;
    this.moment = moment;
    this.InventoryItemsSearch = InventoryItemsSearch;
  }

  $onInit() {
    this.itemsArray = this.resolve.itemsArray;
    this.user = this.resolve.user;
    this.order = this.resolve.order;
    if(!this.order) {
      this.order = {soldDate: null};
    }
    this.soldDate = this.order.soldDate ? new Date(this.order.soldDate) : null;
    this.productCustomer = this.resolve.productCustomer;
    // eslint-disable-next-line new-cap
    const itemSearch = new this.InventoryItemsSearch(this.user.orgContext.id).size(999).inventoryTypes('FP,WIP');

    itemSearch.search()
      .then(results => {
        this.foodProducts = results;
      });
    this.name = this.productCustomer.firstName + ' ' + this.productCustomer.lastName;
    if(this.order && this.order.batchRunId) {
      this.chooseFoodProduct(this.order.foodProductId);
      this.getBatchRun(this.order.batchRunId);
    }
  }

  chooseFoodProduct(foodProductId) {
    this.batchService.getBatchRunsByFoodProduct(foodProductId, this.user.orgContext.id)
      .then((batchRuns)=>{
        this.batchRuns = batchRuns;
      });
  }
  getBatchRun(batchRunId) {
    this.batchRunsService.get(batchRunId)
      .then((batchRun) => {
        this.unit = batchRun.measurementUnit;
        this.order.batchControlNumber = batchRun.batchControlNumber;
      });
  }
  save() {
    this.order.soldDate = this.order.orderStatus === 'sold' ?  this.soldDate.getTime() : null;
    if(!this.order.productCustomerId) {
      this.order.productCustomerId = this.productCustomer.productCustomerId;
    }
    if(this.order.salesOrderId) {
      this.productCustomersService.updateOrder(this.user.orgContext.id,this.order.salesOrderId,this.order);
      this.modalInstance.close();
    }else {
      this.productCustomersService.addOrder(this.user.orgContext.id,this.order);
      this.modalInstance.close();
    }
  }
}

module.exports = Constructor;
