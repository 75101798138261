module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsList', {
    bindings: {
      user: '<',
      events: '<',
      company: '<'
    },
    template: require('./recall.events.list.template.html'),
    controller: require('./recall.events.list.controller'),
    controllerAs: 'vm'
  });
};
