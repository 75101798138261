module.exports = function(ngModule) {
  ngModule.component('cfSopLogEditFormFooter', {
    bindings: {
      user: '<',
      $sopLog: '<',
      $sopLogs: '<',
      $sop: '<',
      logForm: '<',
      onSave: '&?',
      onCancel: '&?',
    },
    template: require('./operations.sop-logs.edit.form.footer.template.html'),
    controller: require('./operations.sop-logs.edit.form.footer.controller'),
    controllerAs: 'vm'
  });
};
