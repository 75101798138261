'use strict';

module.exports = function (ngModule) {
  ngModule.directive('cfCurrencyMask', function () {
    function keepCaretAtEnd() {
      var len = this.value.length;
      this.setSelectionRange(len, len);
    }

    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, element, attr, ngModelCtrl) {
        var currencyParse = function (value) {
            var numberStr = value && value.replace(/[\D]/g, '');
            return Number(numberStr) || undefined;
          },

          currencyFormat = function (value) {
            var numberStr = (value && _.padStart(String(value), 3, 0)),
              retVal = '',
              i,
              pos;

            if (!numberStr) {
              return;
            }

            for (i = numberStr.length - 1; i >= 0; i--) {
              pos = numberStr.length - i;
              if (pos === 3) {
                retVal = '.' + retVal;
              } else if (pos > 3 && pos % 3 === 0) {
                retVal = ',' + retVal;
              }
              retVal = numberStr[i] + retVal;
            }
            return '$' + retVal;
          };
        element.css('text-align', 'right');
        if (!attr.placeholder) {
          element[0].setAttribute("placeholder", "$0.00");
        }

        element.bind('focus', keepCaretAtEnd);
        element.bind('mouseup', keepCaretAtEnd);
        element.bind('keyup', keepCaretAtEnd);
        element.bind('keydown', keepCaretAtEnd);

        element.bind('input', function () {
          ngModelCtrl.$setViewValue(currencyFormat(ngModelCtrl.$modelValue));
          ngModelCtrl.$render();
        });

        //Add these functions to the formatter and parser pipelines
        ngModelCtrl.$parsers.push(currencyParse);
        ngModelCtrl.$formatters.push(currencyFormat);
      }
    };
  });
};
