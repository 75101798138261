module.exports = function(ngModule) {
  ngModule.component('cfOrderPayment', {
    transclude: true,
    bindings: {
      user: '<',
      order: '<',
      payment: '<',
      step: '=',
      achVerbiageDisabled: '=',
      onPaymentComplete: '&',
      onPaymentFailed: '&'
    },
    template: '<div ng-transclude></div>'
  });

  require('./controls')(ngModule);
  require('./content')(ngModule);
};
