module.exports = function(ngModule) {
  ngModule.component('cfMessageBody', {
    bindings: {
      topic: '=',
      user: '<'
    },
    template: require('./message.message-body.template.html'),
    controller: require('./message.message-body.controller.js'),
    controllerAs: 'vm'
  });
};
