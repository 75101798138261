class Constructor {
  constructor($log, $timeout, $state) {
    'ngInject';

    this.$log = $log;
    this.$timeout = $timeout;
    this.$state = $state;
  }

  $onInit() {

  }

  finish() {
    this.company.onboardingSurvey = this.survey || null;
    if (this.company.onboarding.noHelpNeeded) {
      this.company.onboarded = true;
    }
    this.company.$save()
      .then(() => {
        if (this.company.onboarding.noHelpNeeded) {
          if (this.$state.params.productId) {
            this.$state.go('products.edit', {productId: this.$state.params.productId});
          } else {
            this.$state.go('user.dashboard', {welcome: true});
          }
        } else {
          this.$state.go('onboarding.questionnaire');
        }
      })
      .catch(err =>
        this.$log.error('An error occurred saving onboarding needs', err));
  }

  toggleHelpNeeded() {
    this.$timeout(() => {
      this.company.onboarding.noHelpNeeded = !this.company.onboarding.helpNeeded;
    });
  }

  toggleNoHelpNeeded() {
    this.$timeout(() => {
      this.company.onboarding.helpNeeded = !this.company.onboarding.noHelpNeeded;
    });
  }
}

module.exports = Constructor;
