require('./heading.scss');

module.exports = function(ngModule) {
  ngModule.component('cfHeading', {
    bindings: {
      titleHtml: '<',
      subTitleHtml: '<',
      mobileTitleHtml: '<',
      mobileSubTitleHtml: '<',
      titleClass: '<'
    },
    template: require('./heading.template.html'),
    controller: require('./heading.controller.js'),
    controllerAs: 'vm'
  });
};
