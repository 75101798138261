module.exports = function(ngModule) {
  ngModule.service('singleExitForm', function($rootScope, cfpLoadingBar, confirmModal, $state, $window, $log) {
    'ngInject';

    let isIdle = false;

    return function(form, buttonText) {
      if (!form) {
        throw new Error('Unable to establish requiredForm. Form undefined.');
      }
      form.$setDirty();

      $window.onbeforeunload = () => {
        return form.$pristine ? undefined : 'You have unsaved changes.';
      };

      const idleHandle = $rootScope.$on('IdleTimeout', () => {
        $log.warn('Idle timeout with unsaved data');
        isIdle = true;
      });

      const unsubscribeHandle = $rootScope.$on('$stateChangeStart',
        function(event, toState, toParams) {
          if (isIdle || form.$pristine) {
            unsubscribeHandle();
            idleHandle();
            $window.onbeforeunload = null;
            return;
          }

          event.preventDefault();
          cfpLoadingBar.complete();

          confirmModal({
            title: form.$invalid ? 'Required Information Missing' : `Click ${buttonText} to Continue`,
            body: form.$invalid ? 'Please enter all required information before proceeding.' :
              `Click the <b>${buttonText}</b> button below to continue`,
            okText: 'Ok',
            hideCancelButton: true
          }).catch(() => {
            unsubscribeHandle();
            idleHandle();
            $window.onbeforeunload = null;
            $state.go(toState, toParams);
          });
        });

      return unsubscribeHandle;
    };
  });
};
