module.exports = function(ngModule) {
  class Service {
    constructor($http, ChecklistsOperationalSearch) {
      'ngInject';

      this.$http = $http;
      this.ChecklistsOperationalSearch  = ChecklistsOperationalSearch;
    }

    /**
     * Get Checklist from id
     * @param {string} organizationId current Organization id
     * @param {string} checklistId The id of the checklist to retrieve.
     * @return {*} A promise that resolves to the Checklist.
     */
    getChecklist(organizationId, checklistId) {
      return this.$http.get(`operationalChecklists/${checklistId}/organization/${organizationId}`)
        .then((result)=> result.data);
    }

    /**
     * Get the list of checklist
     * @param {string} organizationId current Organization id
     * @return {*} A promise that resolves to the Checklists.
     */
    getChecklists(organizationId) {
      return this.$http.get('v2/operationalChecklists/organization/' + organizationId).then((result)=>
        result.data.checklists);
    }
    /**
     * Query the Checklists List
     * @param {object} user The logged in user
     * @param {string} searchText The query text
     * @param {object} options Additional search options
     * @param {string} options.statuses Only return these statuses in the result set
     * @param {string} options.organizationId An override for the organization owning the library.
     * @param {Array} options.fields Only return these fields in the result set
     * @param {number} from From index
     * @param {number} size Number of results to return
     * @returns {Promise} A promise that resolves to an array of Checklists items
     */
    query(user, searchText, options, from = 0, size = 100) {
      options = options || {};
      searchText = searchText || '';
      let status = options.status || '';

      this.checklistsOperationalSearch = new this.ChecklistsOperationalSearch(user.orgContext.id, status);

      return this.checklistsOperationalSearch.startFrom(from).size(size).search(searchText);
    }

    loadMore(text) {
      return this.checklistsOperationalSearch.getMore(text);
    }

    /**
     * Add checklist
     * @param {string} organizationId current Organization id
     * @param {object} checklist The checklist  to add.
     * @return {*} A promise that resolves to the Checklist.
     */
    addChecklist(organizationId, checklist) {
      return this.$http.post('operationalChecklists/organization/' + organizationId, {checklist})
        .then((result)=> result.data);
    }

    /**
     * Update checklist
     * @param {string} organizationId current Organization id
     * @param {string} checklistId Checklist id
     * @param {object} checklist The checklist  to add.
     * @return {*} A promise that resolves to the Checklist.
     */
    updateChecklist(organizationId,checklistId, checklist) {
      return this.$http
        .patch(`operationalChecklists/${checklistId}/organization/${organizationId}`, {checklist})
        .then((result)=> result.data);
    }
    /**
     * delete checklist
     * @param {string} organizationId current Organization id
     * @param {string} checklistId Checklist id
     * @return {*} A promise that resolves to a message.
     */
    deleteChecklist(organizationId,checklistId) {
      return this.$http
        .delete(`operationalChecklists/${checklistId}/organization/${organizationId}`);
    }
  }

  ngModule.service('operationalChecklistService', Service);
};
