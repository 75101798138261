module.exports = function(ngModule) {
  class Orders {
    constructor($http, $firebaseObject, $firebaseArray, fbutil, CF_ORDER_ITEM_STATUS, $q, constantsService, audit) {
      'ngInject';

      this.$http = $http;
      this.$firebaseObject = $firebaseObject;
      this.$firebaseArray = $firebaseArray;
      this.fbutil = fbutil;
      this.CF_ORDER_ITEM_STATUS = CF_ORDER_ITEM_STATUS;
      this.$q = $q;
      this.constantsService = constantsService;
      this.audit = audit;

      this.baseUri = 'orders';
    }

    /**
     * Gets an array of orders for either a consumer or provider organization
     * @param {string} orgId The organization ID
     * @param {boolean=} isProvider Is the organization a provider (e.g. get orders where orgId is a preferred provider)
     * @param {string=} searchText Optional search text for product brand name or service title
     * @param {number=} from The starting index of the result set
     * @param {number=} size The size of the result set
     * @param {object=} filters Additional filters.
     * @param {boolean=} filters.isActive Include active orders
     * @param {boolean=} filters.isComplete Include complete orders
     * @param {boolean=} filters.client Only include records with this client (isProvider must be true)
     * @param {boolean=} filters.provider Only include records with this provider (isProvider must be false)
     * @param {string=} filters.productId Only include records with this productId
     * @returns {Promise<Array>} A promise resolving to the list of orders
     */
    get(orgId, isProvider = false, searchText, from, size, filters) {
      let url = this.baseUri + '?organizationId=' + orgId + '&isProvider=' + isProvider;

      if (!_.isEmpty(searchText)) { url += '&searchText=' + searchText; }
      if (_.isInteger(from)) { url += '&from=' + from; }
      if (_.isInteger(size)) { url += '&size=' + size; }
      if (filters) {
        if (filters.isActive) { url += '&isActive=true'; }
        if (filters.isComplete) { url += '&isComplete=true'; }
        if (filters.client) { url += '&client=' + filters.client; }
        if (filters.provider) { url += '&provider=' + filters.provider; }
        if (filters.productId) { url += '&productId=' + filters.productId; }
      }

      return this.$http.get(url)
        .then((result) => result.data);
    }

    /**
     * Gets an order
     * @param {string} orderId The order ID
     * @returns {Promise<$firebaseObject>} A promise resolving to the order
     */
    $get(orderId) {
      return this.$firebaseObject(this.fbutil.ref('orders', orderId)).$loaded();
    }

    /**
     * Gets an order item
     * @param {string} orderId The order ID
     * @param {number} itemId The order item ID
     * @returns {Promise<$firebaseObject>} A promise resolving to the order item
     */
    $getItem(orderId, itemId) {
      return this.$firebaseObject(this.fbutil.ref('orders', orderId, 'items', itemId)).$loaded();
    }

    /**
     * Update an order item
     * @param {string} user The logged in user
     * @param {string} orderId The order ID
     * @param {number} itemId The order item ID
     * @param {object} updateObj Order item properties to update
     * @param {object} updateReason The reason for the order item update
     * @returns {Promise<$firebaseObject>} A promise resolving to the order item
     */
    updateItem(user, orderId, itemId, updateObj, updateReason) {
      return this.constantsService.get('auditTypes')
        .then((auditTypes) => this.audit.push(user, _.get(auditTypes, 'ORDER'), orderId, {
          itemId: itemId,
          updateObject: updateObj,
          updateReason: updateReason
        }))
        .then(() => this.fbutil.ref('orders', orderId, 'items', itemId).once('value')
          .then((rfqSnap) => {
            if (!rfqSnap.exists()) { throw new Error('Order item not found'); }

            let val = rfqSnap.val();

            _.assign(val, updateObj);

            return rfqSnap.ref.set(val)
              .then(() => val);
          })
        );
    }

    /**
     * Gets an order item's private media
     * @param {string} orderId The order ID
     * @param {number} itemId The order item ID
     * @returns {Promise<$firebaseObject>} A promise resolving to the private media array
     */
    $getPrivateMedia(orderId, itemId) {
      return this.$firebaseArray(this.fbutil.ref('orders', orderId, 'items', itemId, 'media', 'private')).$loaded();
    }

    /**
     * Gets an order item's shared media
     * @param {string} orderId The order ID
     * @param {number} itemId The order item ID
     * @returns {Promise<$firebaseObject>} A promise resolving to the shared media array
     */
    $getSharedMedia(orderId, itemId) {
      return this.$firebaseArray(this.fbutil.ref('orders', orderId, 'items', itemId, 'media', 'shared')).$loaded();
    }

    create(order) {
      order.createdOn = firebase.database.ServerValue.TIMESTAMP;
      return this.fbutil.ref('orders').push(order);
    }

    addItem(orderId, item) {
      return this.fbutil.ref('orders', orderId, 'items').push(item);
    }

    setChargeId(orderId, itemId, chargeId) {
      return this.fbutil.ref('orders', orderId, 'items', itemId, 'chargeId').set(chargeId)
        .then(() => this.fbutil.ref('orders', orderId, 'items', itemId, 'chargedDate')
          .set(firebase.database.ServerValue.TIMESTAMP));
    }

    cancel($order) {
      return this.$q.all(_.map($order.items, i => this.cancelItem($order.$id, i.id)));
    }

    cancelItem(orderId, itemId) {
      return this.fbutil.ref('orders', orderId, 'items', itemId, 'status')
        .set(this.CF_ORDER_ITEM_STATUS.CANCELLED);
    }
  }

  ngModule.service('orders', Orders);
};
