module.exports = function(ngModule) {
  ngModule.component('cfSignupForm', {
    bindings: {
      tokenId: '<?',
      tokenData: '=?',
      user: '=',
      profileType: '<',
      orgType: '<',
      onOauth: '&?',
      onSignedUp: '&',
      activateCapcha: '<'
    },
    template: require('./auth.signup-form.template.html'),
    controller: require('./auth.signup-form.controller.js'),
    controllerAs: 'vm'
  });

  require('./signup_form_main')(ngModule);
};
