require('./operations.sop-logs.types.transporting-product.scss');

module.exports = function(ngModule) {
  ngModule.component('cfLogTypeTransportingProduct', {
    bindings: {
      user: '<',
      $sop: '<',
      $sopLog: '='
    },
    template: require('./operations.sop-logs.types.transporting-product.template.html'),
    controller: require('./operations.sop-logs.types.transporting-product.controller'),
    controllerAs: 'vm'
  });
};
