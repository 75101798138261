module.exports = function(ngModule) {
  ngModule.component('cfCustomerOverview', {
    bindings: {
      user: '<',
      productCustomer: '<',
      customerTypes: '<'
    },
    template: require('./product-customers.edit.template.html'),
    controller: require('./product-customers.edit.controller.js'),
    controllerAs: 'vm'
  });
};
