class Controller {
  constructor($stateParams, S3, growl, confirmModal) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.S3 = S3;
    this.growl = growl;
    this.confirmModal = confirmModal;
  }

  $onInit() {
    this.templateFileObj = this.recall.letterTemplate ? _.first(_.values(this.recall.letterTemplate)) : null;
  }

  downloadCustom() {
    if (!this.templateFileObj) {
      this.growl.error('No template was uploaded. Visit your recall plan to upload one.');
      return;
    }
    this.S3.openSignedUrlInNewTab(this.user.orgContext.id, this.templateFileObj.key);
  }

  removePreviousLetter() {
    const letter = _.first(this.$letter);

    return this.confirmModal({
      title: '<i class="fas fa-exclamation-triangle text-warning mr-2"></i> Replace Letter',
      okText: 'Replace Letter',
      body: 'Are you sure you want to remove the previous recall letter and upload a new one? This cannot be undone.'
    }).then(() => {
      return this.S3.deleteFile(this.user.orgContext.id, letter.key).then(() => this.$letter.$remove(0));
    });
  }

  uploadDate() {
    return moment(_.first(this.$letter)).format('MMMM Do YYYY');
  }

  openLetter() {
    this.S3.openSignedUrlInNewTab(this.user.orgContext.id, _.first(this.$letter).key);
  }

  removeLetter() {
    alert('uo');
  }
}

module.exports = Controller;
