class Constructor {
  constructor(growl, utils, historyService) {
    'ngInject';

    this.growl = growl;
    this.utils = utils;
    this.historyService = historyService;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.maxVersion = this.resolve.maxVersion;
    this.publish = {
      publishedByName: this.user.name,
    };
  }

  submit() {
    this.modalInstance.close(this.publish);
  }
}

module.exports = Constructor;
