module.exports = function(ngModule) {
  ngModule.factory('productSearch', function(fbutil, Search) {
    'ngInject';

    function defaultSearchOrgs(user) {
      if (user.isCfAdmin() && user.orgContext.id === user.organizationId) {
        return;
      } else {
        return [user.orgContext.id];
      }
    }

    class ProductSearch extends Search {
      constructor(user, organizationIds, options) {
        organizationIds = organizationIds || defaultSearchOrgs(user);
        options = options || {};
        super(user, {
          uri: 'search/product',
          type: 'product',
          max: options.size || 3000, // Maximum number of recs to pull back
          pageLimit: 30,    // Number of recs to show per page
          body: {},
          context: {
            organizationId: user.isCfAdmin() ? undefined : organizationIds
          }
        }, 'products', false);
        this.organizations = organizationIds;
      }

      searchFields(fields) {
        this.fields = fields;
        return this;
      }

      /**
       * Limit the _source result to specific field(s).
       * @param {string|array} sources An array of sources or a single string source (supports wildcards).
       * @returns {ProductSearch} Returns the search object with applied changes.
       */
      limitResultsTo(sources) {
        this.sources = sources;
        return this;
      }

      sortByLastView() {
        this.sort = [
          {lastView: {order: 'desc'}}
        ];
        return this;
      }

      sortByPlanReviews() {
        this.sort = [
          {'pendingPlanReview': {'missing': '_last'}},
          {'lastPlanReview': {order: 'desc', 'missing': '_first'}},
          {'letterRequestDateTime': {order: 'desc', 'missing': '_last'}}
        ];
        return this;
      }

      sortByName() {
        this.sort = [
          {'brandName.keyword': {order: 'asc'}}
        ];
        return this;
      }

      byIds(ids) {
        this.byIdsFilter = ids;
      }

      noPrivate() {
        this.options.noPrivate = true;
        return this;
      }

      search(text) {
        this.body = {
          query: {
            bool: {}
          }
        };

        if (this.organizations) {
          this._addMust({
            terms: {
              organizationId: this.organizations
            }
          });
        }

        if (this.byIdsFilter) {
          this._addMust({
            ids: {
              values: this.byIdsFilter
            }
          });
        }

        if (this.options.noPrivate) {
          this._addMustNot({
            term: {
              private: true
            }
          });
        }

        if (this.sources) {
          this.body._source = this.sources;
        }

        if (text && this.fields) {
          this._addShould({
            'multi_match': {
              query: text.toLowerCase(),
              type: 'phrase_prefix',
              fields: this.fields,
              slop: 3,            // Allow terms to appear out of order by this many positions
              'max_expansions': 20  // To improve performance, limit results of the final search term to this many
            }
          });
        } else {
          this.body.sort = this.sort;
        }

        this.setSearchQuery(this.body);
        return super.search();
      }

      _addShould(query) {
        this.body.query.bool.should = this.body.query.bool.should || [];
        this.body.query.bool['minimum_should_match'] = this.body.query.bool['minimum_should_match'] || 1;
        this.body.query.bool.should.push(query);
      }

      _addMust(query) {
        this.body.query.bool.must = this.body.query.bool.must || [];
        this.body.query.bool.must.push(query);
      }

      _addMustNot(query) {
        this.body.query.bool['must_not'] = this.body.query.bool['must_not'] || [];
        this.body.query.bool['must_not'].push(query);
      }
    }

    return {
      // This is the preferred way to create a product search object.
      ProductSearch: ProductSearch,

      // This is the deprecated way to do a product search. New up a search object using the class above for new work.
      getSearch: function(user, size, organizationIds) {
        var searchParams = {
            uri: 'search/product',
            type: 'product',
            max: size || 300, // Maximum number of recs to pull back
            pageLimit: 30,    // Number of recs to show per page
            body: {
              sort: [
                {lastView: {order: 'desc'}}
              ],
              query: {
                bool: {
                  must: []
                }
              }
            }
          },
          organizationIds = organizationIds ? organizationIds : defaultSearchOrgs(user);

        if (organizationIds) {
          searchParams.body.query.bool.must.push({
            terms: {
              organizationId: organizationIds
            }
          });

          if (!user.isCfAdmin()) {
            searchParams.context = {organizationId: organizationIds};
          }
        }

        return new Search(user, searchParams, 'products');
      },

      productsByUser: function(user, orgId, size) {
        let searchParams = {
          uri: 'search/product',
          type: 'product',
          max: size || 300, // Maximum number of recs to pull back
          pageLimit: 30,    // Number of recs to show per page
          body: {
            sort: [
              {lastView: {order: 'desc'}}
            ],
            query: {
              bool: {
                must: [{
                  term: {
                    contactId: user.uid
                  }
                }]
              }
            }
          }
        };

        if (orgId) {
          searchParams.context = {organizationId: [orgId]};
          searchParams.body.query.bool.must.push({
            term: {
              organizationId: orgId
            }
          });
        }

        return new Search(user, searchParams, 'products');
      }
    };
  });
};
