class Controller {
  constructor($state, $rootScope, marketplaceService) {
    'ngInject';

    this.$state = $state;
    this.$rootScope = $rootScope;
    this.marketplaceService = marketplaceService;
  }

  $onInit() {
    this.unRegister = this.$rootScope.$on('$stateChangeSuccess', (event, toState) =>
      this.checkRedirect(toState));

    this.checkRedirect();
  }

  $onDestroy() {
    if (_.isFunction(this.unRegister)) { this.unRegister(); }
  }

  checkRedirect() {
    if(this.$state.current.name === 'marketplace') {
      this.$state.go(this.isConsumer() ? 'marketplace.servicesAvailable' : 'marketplace.servicesOffered');
    }
  }

  isConsumer() {
    return this.marketplaceService.isConsumer(this.user, this.organization);
  }

  isProvider() {
    return this.marketplaceService.isProvider(this.user, this.organization);
  }
}

module.exports = Controller;
