module.exports = function(ngModule) {
  require('./subscription_order')(ngModule);
  require('./dashboard')(ngModule);
  require('./channel_subscriptions')(ngModule);
  require('./new_password')(ngModule);
  require('./invoices')(ngModule);
  require('./pay_invoice')(ngModule);
  require('./change_password')(ngModule);
  require('./notifications')(ngModule);
  require('./progress')(ngModule);
  require('./assigned_records')(ngModule);
  require('./notify_coworker')(ngModule);
  require('./request_files_modal')(ngModule);
  require('./settings')(ngModule);
};
