class Constructor {
  constructor(users, utils, $state, confirmDeleteModal, growl, $q, productCustomersService) {
    'ngInject';

    this.users = users;
    this.utils = utils;
    this.$state = $state;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.$q = $q;
    this.productCustomersService = productCustomersService;
  }

  $onInit() {
    if(!this.productCustomer) {
      this.productCustomer = {};
    }
  }

  cancel() {
    return this.$state.go('ingredients.customers.list');
  }

  save() {
    if(this.productCustomer.productCustomerId) {
      this.productCustomersService
        .updateProductCustomer(this.user.orgContext.id, this.productCustomer.productCustomerId, this.productCustomer)
        .then(()=> {
          this.growl.success('Customer Updated');
          this.$state.go('ingredients.customers.customer.overview');
        });

    } else {
      this.productCustomersService.addProductCustomer(this.user.orgContext.id, this.productCustomer).then((response)=>{
        this.growl.success('Customer Saved');
        this.$state.go('ingredients.customers.list');
      });
    }
  }
}

module.exports = Constructor;
