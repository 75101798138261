module.exports = function(ngModule) {
  ngModule.component('frMobileApp', {
    bindings: {
      user: '<'
    },
    template: require('./users.settings.mobile-app.template.html'),
    controller: require('./users.settings.mobile-app.controller.js'),
    controllerAs: 'vm'
  });
};
