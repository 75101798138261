'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfSupplierOverview', {
    bindings: {
      supplier: '<',
      supplierOrgIds: '<',
      user: '<',
      company: '<',
      supplierSearch: '<'
    },
    template: require('./ingredients.supplier.overview.template.html'),
    controller: require('./ingredients.supplier.overview.controller.js'),
    controllerAs: 'vm'
  });
};
