'use strict';

module.exports = function(ngModule) {
  ngModule.filter('cfBytes', function() {
    return function(input, decimals) {
      if (input === 0) { return '0 Byte'; }

      let k = 1000,
        dm = _.isNumber(decimals) ? decimals : 3,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(input) / Math.log(k));

      return parseFloat((input / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };
  });
};
