'use strict';

module.exports = function(parentModuleName) {
  var moduleName = parentModuleName + '.filePortal',
    ngModule = angular.module(moduleName, []);

  require('./_components')(ngModule);
  require('./_services')(ngModule);
  require('./_config')(ngModule);

  return moduleName;
};
