module.exports = function(ngModule) {
  require('./main')(ngModule);
  require('./services_available')(ngModule);
  require('./services_offered')(ngModule);
  require('./edit_service_offered')(ngModule);
  require('./clients')(ngModule);
  require('./invite_client')(ngModule);
  require('./choose_service_modal')(ngModule);
  require('./shopping_cart')(ngModule);
  require('./checkout')(ngModule);
  require('./orders')(ngModule);
  require('./order_item')(ngModule);
  require('./providers')(ngModule);
  require('./save_as_document_template')(ngModule);
  require('./load_document_template')(ngModule);
  require('./request_for_quotes')(ngModule);
  require('./letter')(ngModule);
  require('./submit_quote')(ngModule);
  require('./reassign_provider')(ngModule);
  require('./client_info_modal')(ngModule);
  require('./client_files')(ngModule);
  require('./assign_rfq')(ngModule);
  require('./reject_rfq_assignment')(ngModule);
  require('./edit_order_item')(ngModule);
  require('./select_rfq_user')(ngModule);
};
