module.exports = function(ngModule) {
  ngModule.component('cfSopEdit', {
    bindings: {
      user: '<',
      sops: '<',
      $sop: '<',
      //sopTypes: '<',
      logFormArray: '<',
      company: '<',
      onSave: '&?',
      onCancel: '&?', // By default cancelling will return to SOP list view. Implement to override this behavior.
      isTemplate: '<',
      useHeader: '<',
      headerTitle: '<',
      headerSubTitle: '<',
      hideHeader: '<',
      origin: '@?', // Origin can be 'hazard', 'sops' or any ID to indicate the origin of the SOP edit view
      approvalTemplate: '<'
    },
    template: require('./sops.edit.template.html'),
    controller: require('./sops.edit.controller.js'),
    controllerAs: 'vm'
  });
};
