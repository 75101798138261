module.exports = function(ngModule) {
  ngModule.constant('projectChecklist', [
    {
      name: 'SOW Signed',
      value: 'sowSigned'
    },
    {
      name: 'Establish Portal',
      value: 'establishPortal'
    },
    {
      name: 'Download Mobile App',
      value: 'downloadMobileApp'
    },
    {
      name: 'Welcome Sent via Portal',
      value: 'welcomeSent'
    },
    {
      name: 'Access Portal Confirmed',
      value: 'accessPortalConfirmed'
    },
    {
      name: 'Invite for Kickoff Call via Portal',
      value: 'inviteForKickoff'
    },
    {
      name: 'Establish Initial Call Date/Time',
      value: 'establishInitialCall'
    },
    {
      name: 'Kick off Call',
      value: 'kickOffCall'
    },
    {
      name: 'Upload Existing HACCP, GMPS, Other',
      value: 'uploadHaccpGmpsOther',
    },
    {
      name: 'Upload Recipes, Formulations, Instructions',
      value: 'uploadRecipesFormulationsInstructions'
    },
    {
      name: 'Upload Process Schedules',
      value: 'uploadProcessSchedules'
    },
    {
      name: 'Upload 1 Floor Diagram',
      value: 'uploadFloorDiagram'
    },
    {
      name: 'Upload Facility Images (5 MAX)',
      value: 'uploadFacilityImages'
    },
    {
      name: 'Upload Process Workflow and Description(s)',
      value: 'uploadProcessWorkflowDescription'
    },
    {
      name: 'Write Draft Food Safety Plan',
      value: 'writeDraft'
    },
    {
      name: 'Invite to Draft for Plan Review via Portal',
      value: 'inviteToDraft'
    },
    {
      name: 'Establish Review Date/Time for Review',
      value: 'establishReviewTime'
    },
    {
      name: 'Plan Review Call',
      value: 'planReviewCall'
    },
    {
      name: 'Send Additional Information',
      value: 'sendAdditionalInformation'
    },
    {
      name: 'Update Food Safety Plan',
      value: 'updateFoodSafetyPlan'
    },
    {
      name: 'Invite to Final Plan Review via Portal',
      value: 'inviteToFinalPlanReview'
    },
    {
      name: 'Establish Final Date/Time',
      value: 'establishFinalTime'
    },
    {
      name: 'Final Plan Review + Initial Batch Setup',
      value: 'finalPlanReviewInitialBatchSetup'
    },
    {
      name: 'Provide Resources for Batch Setup / Mobile Support',
      value: 'provideResources'
    },
    {
      name: 'Send Final Closing Message via Portal',
      value: 'sendFinalClosingMessage'
    },
  ]);
};
