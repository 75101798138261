class Constructor {
  constructor($state, marketplaceService) {
    'ngInject';

    this.$state = $state;
    this.marketplaceService = marketplaceService;
  }

  $onInit() {
  }

  stop() {
    let orderId = this.marketplaceService.order;
    let orderItem = this.marketplaceService.orderItem;
    let itemId = orderItem && orderItem.$id;

    this.user.setOrgContext().then(() => {
      if (!orderId || !itemId) {
        this.$state.go('marketplace.requestForQuote.list');
      } else {
        this.$state.go('marketplace.orders.item', {orderId, itemId});
      }
    });
  }
}

module.exports = Constructor;
