class Constructor {
  constructor(countryPhoneCodes, $http, utils, growl, $log) {
    'ngInject';
    this.countryPhoneCodes = countryPhoneCodes();
    this.$http = $http;
    this.utils = utils;
    this.growl = growl;
    this.$log = $log;
  }

  $onInit() {
    let countryCodeObj = {};

    this.user = this.resolve.user;
    if (this.user && (!this.user.phone || this.user.phone.slice(0, -10) === '')) {
      countryCodeObj = {name: '+1 United States', code: '+1'};
    } else {
      countryCodeObj = this.countryPhoneCodes.find((countryCode) => countryCode.code === this.user.phone.slice(0, -10));
      this.user.phone = this.user.phone.slice(-10);
    }

    this.countryCode = countryCodeObj;
    this.userCountryCode = countryCodeObj.code;
  }

  countryCodeSelected($item) {
    this.userCountryCode = $item.code;
  }

  sendAppUrl() {
    let phoneWithCode = this.userCountryCode + this.user.phone;

    this.utils.sendMessage(phoneWithCode, 'app_template')
      .then(() => {
        this.growl.success('Message sent');
        this.modalInstance.close();
      })
      .catch(err => {
        this.growl.error('Unable to Message sent');
        this.$log.error(err);
      });
  }
}

module.exports = Constructor;
