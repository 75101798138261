'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfExternalVerification', {
    bindings: {
      verification: '=',
      substituteName: '@?',
      title: '@?',
      product: '<',
      productOrganization: '<',
      $uibModalInstance: '=',
      user: '<'
    },
    template: require('./products.verification.external.template.html'),
    controller: require('./products.verification.external.controller.js'),
    controllerAs: 'vm'
  });
};