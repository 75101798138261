module.exports = function(ngModule) {
  ngModule.component('cfProjectWritingList', {
    bindings: {
      user: '<',
      company: '<',
      projects: '<',
      topic: '<',
      onSave: '&?',
      onCancel: '&?', // By default cancelling will return to SOP list view. Implement to override this behavior.
      origin: '@?' // Origin can be 'hazard', 'sops' or any ID to indicate the origin of the SOP edit view
    },
    template: require('./project_writing.list.template.html'),
    controller: require('./project_writing.list.controller'),
    controllerAs: 'vm'
  });
};
