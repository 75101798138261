module.exports = function (ngModule) {
  ngModule.component('cfOrganizationUsers', {
    bindings: {
      user: '<',
      organization: '<',
    },
    template: require('./administration.organization-users.template.html'),
    controller: require('./administration.organization-users.controller.js'),
    controllerAs: 'vm',
  });
};
