module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseArray, $firebaseObject, $http) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseArray = $firebaseArray;
      this.$firebaseObject = $firebaseObject;
      this.$http = $http;
    }

    /**
     * Get all GMP categories, sections & questions
     * @returns {Promise<Object>} A firebase object containing the GMPs
     */
    getAll() {
      return this.$firebaseArray(this.fbutil.ref('gmps')).$loaded();
    }

    getAuditCategory(user, auditId, categoryId) {
      return this.$firebaseObject(this.fbutil.ref(`gmpAudits/${user.orgContext.id}/${auditId}/${categoryId}`))
        .$loaded();
    }

    getAuditDate(user, auditId) {
      return this.fbutil.ref(`gmpAudits/${user.orgContext.id}/${auditId}/createdOn`).once('value')
        .then(this.fbutil.getValueOrDefault);
    }

    push(user) {
      return this.fbutil.ref(`gmpAudits/${user.orgContext.id}`).push({
        createdOn: new Date().getTime(),
        createdBy: user.uid,
        createdByName: user.fullName()
      }).then(ref => ref.key);
    }

    delete(user, auditId) {
      return this.fbutil.ref(`gmpAudits/${user.orgContext.id}/${auditId}/deleted`).set(true);
    }

    fetchAudits(orgId, count, startAt = null) {
      let url = `/gmpAudits/${orgId}?size=${count}`;

      if (startAt) {
        url += `&from=${startAt}`;
      }
      return this.$http.get(url).then(result => result.data.audits);
    }
  }

  ngModule.service('gmps', Service);
};
