class Controller {
  constructor(growl, $log, ENV) {
    'ngInject';

    this.growl = growl;
    this.$log = $log;
    this.ENV = ENV;
  }

  $onInit() {
    this.isDevMode = this.ENV === 'development';
    this.readOnly = false;
  }

  save() {
    this.saving = true;
    this.$user.name = this.$user.firstName + ' ' + this.$user.lastName;
    this.$user.$save()
      .then(() => {
        if (this.profileForm) { this.profileForm.$setPristine(); }
        this.growl.success('User profile saved successfully.');
      })
      .catch((err) => {
        this.$log.error('Error saving user profile.', this.$log.toString(err));
        this.growl.error('Unable to save user profile.');
      })
      .then(() => this.saving = false);
  };
}

module.exports = Controller;
