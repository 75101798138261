class Constructor {
  constructor() {
    'ngInject';
  }

  $onInit() {
  }
}

module.exports = Constructor;
