module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('user.messages', {
        url: '/messages',
        template: '<cf-message user="$resolve.user"></cf-message>'
      })
      .state('user.messages.message', {
        url: '/:topicId',
        template: '<cf-message-body user="$resolve.user" topic="$resolve.topic"></cf-message-body>',
        resolve: {
          topic: (messageServices, $stateParams) => messageServices.$getTopic($stateParams.topicId),
        }
      });
  });
};
