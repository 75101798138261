require('./view-error.scss');

module.exports = function(ngModule) {
  ngModule.component('viewError', {
    bindings: {
      heading: '@',
      title: '@'
    },
    template: require('./view-error.template.html'),
    controller: require('./view-error.controller.js'),
    controllerAs: 'vm'
  });
};
