module.exports = function(ngModule) {
  require('./list')(ngModule);
  require('./old_records')(ngModule);
  require('./create_product')(ngModule);
  require('./create_from_template')(ngModule);
  require('./upload_files')(ngModule);
  require('./edit')(ngModule);
  require('./copy_product')(ngModule);
  require('./edit_product_group')(ngModule);
  require('./group_select')(ngModule);
  require('./save_as_template_modal')(ngModule);
  require('./select_template_modal')(ngModule);
  require('./edit_required_hazard_fields')(ngModule);
  require('./template_picker')(ngModule);
  require('./log_sheet_preview_modal')(ngModule);
};
