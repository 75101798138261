module.exports = function(ngModule) {
  ngModule.factory('utils', function($q, $window, $document, moment, growl, $log, $firebaseObject, users, $http) {
    'ngInject';

    function ucFirst(str) {
      str += '';

      return str.charAt(0).toUpperCase() + str.substr(1);
    }

    return {
      parseEmailName: function(email) {
        return ucFirst(email.substr(0, email.indexOf('@')) || '');
      },

      testEmail: function(str) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(str).toLowerCase());
      },

      isImage: function(src) {
        return $q(function(resolve, reject) {
          let image = new Image();

          image.onerror = reject;
          image.onload = resolve;
          image.src = src;
        });
      },

      isBenignKeyUp: function(keyCode) {
        switch (keyCode) {
        case 9:
        case 16:
        case 33:
        case 34:
        case 35:
        case 36:
        case 37:
        case 38:
        case 39:
        case 40:
          return true;
        default:
          return false;
        }
      },

      jsonToQueryString: function(obj) {
        return _.reduce(obj, (result, value, key) => {
          return !_.isNull(value) && !_.isUndefined(value) ? result += key + '=' + value + '&' : result;
        }, '?').slice(0, -1);
      },

      storageAvailable: function(type) {
        try {
          let storage = $window[type],
            x = '__storage_test__';

          storage.setItem(x, x);
          storage.removeItem(x);
          return true;
        } catch(e) {
          return false;
        }
      },

      isModalDismissalByUser: function(reason) {
        return reason === 'cancel' || reason === 'backdrop click' || reason === 'escape key press';
      },

      humanizeDate(date) {
        return moment.duration(moment(date).diff(new Date())).humanize(true);
      },

      defaultErrorHandler(err, msg) {
        if (this.isModalDismissalByUser(err)) { return; }

        msg = msg || 'An error occurred.';
        $log.error(msg, $log.toString(err));
        growl.error(`${msg} Please contact customer support if this continues.`);
      },

      toQueryParm(arrayOfQueryParms) {
        if (!arrayOfQueryParms || !arrayOfQueryParms.length) { return ''; }
        let retStr = '';
        let delimiter = '?';

        _.each(arrayOfQueryParms, parm => {
          retStr += delimiter + parm;
          delimiter = '&';
        });
        return retStr;
      },

      /**
       * A convenient handler of a searchbox's keypress event.
       * @param {object} $event The angular event.
       * @param {string} searchText The search text keyed into the searchbox.
       * @param {function} debouncedSearchFn The debounced search function.
       * @returns {void}
       */
      defaultSearchKeyPressHandler($event, searchText, debouncedSearchFn) {
        if (this.isBenignKeyUp($event.keyCode)) {
          return;
        }

        if ($event.keyCode === 13) {
          if (!searchText || searchText.length >= 1) {
            debouncedSearchFn();
          }

          debouncedSearchFn.flush();

          return;
        }

        if (searchText && searchText.length <= 1) {
          return;
        }

        debouncedSearchFn();
      },

      fbToPojo($fbObj) {
        let retObj = _.assign({}, $fbObj);

        return _.omitBy(retObj, (val, key) => key !== '$id' && key[0] === '$' || key === 'forEach');
      },

      resetFbObject($fbObj) {
        return $firebaseObject($fbObj.$ref()).$loaded()
          .then($newFbObj => {
            $fbObj.$destroy();

            return $newFbObj;
          });
      },

      keys(obj) {
        return _.isObject(obj) ? _.keys(obj) : [];
      },

      scrollToTop() {
        if (_.get($document[0], 'body.scrollTop')) {
          $document[0].body.scrollTop = 0;
        } else if (_.get($document[0], 'documentElement.scrollTop')) {
          $document[0].documentElement.scrollTop = 0;
        }
      },

      copyToClipboard (str) {
        const el = document.createElement('textarea');

        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      },

      stringToDownload(csvString, fileName) {
        const blob = new Blob([csvString], {type: 'text/plain'});
        const link = document.createElement('a');

        link.href = $window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      },

      setFormFieldsToTouched(form) {
        _.forEach(form.$error, (field) => {
          _.forEach(field, (errorField) => {
            errorField.$setTouched();
          });
        });
      },

      async usersName(items) {
        let userNamePromises = {};

        await Promise.all(_.map(items, async item => {

          let uid = item.updatedBy || item.createdBy;
          let date = item.updatedOn || item.createdOn;

          if (!uid) { return; }
          if (!userNamePromises[uid]) {
            userNamePromises[uid] = users.getName(uid).catch(() => '');
          }
          if(date) {
            item.date = new Date(date).toLocaleString('en-us',{month: '2-digit',day: '2-digit',year: 'numeric'});
          }
          item.updatedByName = await userNamePromises[uid];
        }));
      },
      sendMessage(phoneNumber, template) {
        return $http.post('messages/sms',{ phoneNumber, template }).then((result) => result.data);
      },
      async pdfGenerator(report) {
        let mainPdfFile = Uint8Array.from(report.data.mainPdfBuffer.data);
        const filesBufferArray = [];

        if (report.data.reportFiles) {
          for (const reportFile of report.data.reportFiles) {
            if (reportFile) {
              for (const singleFile of reportFile.files) {
                filesBufferArray.push(Uint8Array.from(singleFile.Body.data));
              }
              const pdfsToMerge = [mainPdfFile, ...filesBufferArray];

              try {
                const mergedPdf = await PDFLib.PDFDocument.create();

                for (const pdfBytes of pdfsToMerge) {
                  const pdf = await PDFLib.PDFDocument.load(pdfBytes);
                  const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());

                  copiedPages.forEach((page) => {
                    mergedPdf.addPage(page);
                  });
                }
                mainPdfFile = await mergedPdf.save();
              } catch (error) {
                console.error(`Error loading PDF: ${ error }`);
                throw error;
              }
            }
          }
        }
        const blob = new Blob([mainPdfFile], {type: 'application/pdf'});

        return URL.createObjectURL(blob);
      }
    };
  });
};
