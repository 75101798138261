module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider

      .state('questionnaire', {
        url: '/questionnaire?type?key',
        template: '<cf-questionnaire user="$resolve.user" questionnaire="$resolve.questionnaire" ' +
            'type="$resolve.type"></cf-questionnaire>',
        controllerAs: 'vm',
        resolve: {
          type: ($stateParams) => $stateParams.type || 'expertHelp',
          questionnaire: (questionnaireService, type) =>
              questionnaireService.getEmptyQuestionnaire(type)
        }
      });
  });
};
