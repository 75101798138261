'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfListSafetyTeam', {
    bindings: {
      safetyTeam: '=',
      members: '=',
      company: '=',
      user: '<'
    },
    template: require('./company.safety-team.list.template.html'),
    controller: require('./company.safety-team.list.controller.js'),
    controllerAs: 'vm'
  });
};
