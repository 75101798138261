'use strict';

module.exports = function($scope, $log, growl, utils, $q, fbutil, $uibModal, SupplierSearch,
                          supplierIngredientService, supplierService, $timeout) {
  'ngInject';
  var vm = this;

  function filterResults(results) {
    return _.filter(results, entry => !vm.existingSuppliers || !vm.existingSuppliers[entry.$id]);
  }

  function doSearch() {
    delete vm.selectedSupplier;
    vm.searching = vm.loading = true;
    vm.supplierSearch.search(vm.searchText).then(results => {
      vm.supplierResults = filterResults(results);
      vm.noSuppliers = !vm.searchText && _.isEmpty(results);
    }).catch(function(err) {
      vm.loading = false;
      $log.error(err);
      growl.error('Error occurred during search.');
    }).finally(() => {
      vm.searching = vm.loading = false;
    });
  }

  vm.$onInit = function() {
    vm.company = vm.resolve.company;
    vm.ingredient = vm.resolve.ingredient;
    vm.user = vm.resolve.user;

    vm.supplierSearch = new SupplierSearch(vm.user, vm.company.$id);

    supplierIngredientService.getAll(vm.ingredient.$id).then(supplierIngredients => {
      vm.existingSuppliers = _.keyBy(supplierIngredients, si => si.supplierId);
      doSearch();
    });
  };

  vm.searchKeyPress = function($event) {
    if (utils.isBenignKeyUp($event.keyCode)) {
      return;
    }

    if ($event.keyCode === 13) {
      if (!vm.searchText || vm.searchText.length >= 1) {
        vm.search();
      }

      vm.search.flush();

      return;
    }

    if (vm.searchText && vm.searchText.length <= 1) {
      return;
    }

    vm.search();
  };

  vm.search = _.debounce(doSearch, 200);

  vm.getMore = function() {
    vm.loading = true;
    vm.supplierSearch.getMore().then(results => {
      vm.supplierResults = filterResults(results);
      vm.loading = false;
    });
  };

  vm.gotoSuppliers = function() {
    vm.modalInstance.dismiss({redirectTo: 'ingredients.suppliers.list'});
  };

};