module.exports = function(ngModule) {
  ngModule.constant('orgPerspectives', {
    NONE: 'none',
    ADMIN: 'admin',
    CF_ADMIN: 'cfAdmin',
    ANALYST: 'analyst',
    PROVIDER_ANALYST: 'providerAnalyst',  // A provider analyst will not see most menu options
    PO: 'po',
    PENDING_SIGNUP: 'pendingSignup',
    FREE_PLAN: 'freePlan'
  });
};
