class Controller {
  constructor(ProductGroupSearch, $uibModal, $firebaseObject, fbutil, $q, utils) {
    'ngInject';

    this.ProductGroupSearch = ProductGroupSearch;
    this.$uibModal = $uibModal;
    this.$firebaseObject = $firebaseObject;
    this.fbutil = fbutil;
    this.$q = $q;
    this.MAX_RECS = 300;
    this.utils = utils;
  }

  $onInit() {
    this.productGroupSearch = new this.ProductGroupSearch(this.user, this.user.currentOrgContext().id);

    this.productGroupSearch.search().then(results => {
      this.ngModel = _.get(this, 'ngModel.$id') && _.find(results, g => g.$id === this.ngModel.$id);
      this.onGroupSelected({group: this.ngModel});
    });
  }

  createGroup(group) {
    return this.$firebaseObject(this.fbutil.ref('productGroups').push()).$loaded()
      .then((newGroup) => {
        newGroup.createdOn = firebase.database.ServerValue.TIMESTAMP;
        newGroup.groupName = group.groupName;
        newGroup.organizationId = this.user.currentOrgContext().id;

        return newGroup.$save().then(() => newGroup);
      })
      .then((newGroup) => {
        this.onGroupCreated({group: newGroup});
        this.onGroupSelected({group: _.assign(group, newGroup)});

        return newGroup;
      })
      .catch(err => {
        if (!this.utils.isModalDismissalByUser(err)) {
          this.groupCreateFailed({error: err});
        }

        return this.$q.reject(err);
      });
  }

  selectGroup(group) {
    this.onGroupSelected({group: group});
  }
}

module.exports = Controller;
