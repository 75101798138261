'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfChannelSubscriptions', {
    bindings: {
      channels: '=',
      subscriptions: '=',
      user: '='
    },
    template: require('./users.channel-subscriptions.template.html'),
    controller: require('./users.channel-subscriptions.controller'),
    controllerAs: 'vm'
  });
};

