module.exports = function(ngModule) {
  ngModule.component('cfSopLogEdit', {
    bindings: {
      user: '<',
      $sopLog: '<',
      sopLogs: '=',
      $sop: '<',
      onSave: '&?',
      onCancel: '&?'
    },
    template: require('./operations.sop-logs.edit.template.html'),
    controller: require('./operations.sop-logs.edit.controller'),
    controllerAs: 'vm'
  });

  require('./form')(ngModule);
};