class Constructor {
  constructor($log, growl, order, organizations, billing, utils, $q) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.orderService = order;
    this.organizations = organizations;
    this.billing = billing;
    this.utils = utils;
    this.$q = $q;

    this.DAYS_IN_TRIAL = 15;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.stripeConstants = this.resolve.stripeConstants;

    this.stripeProducts = this.stripeConstants.stripeProducts;
    this.productConstraints = this.stripeConstants.constraints;
    this.subscriptionPlans = this.stripeConstants.subscriptionPlans;

    this.step = 'submitOrder';
    this.plan = this.user.subscription.plan;
    this.order = {
      organizationId: this.user.orgContext.id,
      items: [{
        title: this.plan.name,
        comments: 'FoodReady subscription (billed every ' +
          (this.plan.interval_count > 1 ? this.plan.interval_count + ' ' : '') + this.plan.interval +
          (this.plan.interval_count > 1 ? 's' : '') + ')',
        planId: this.plan.id,
        amount: {standard: this.billing.getPriceForTier(this.plan, 1) / 100
        },
        couponType: 'subscription'
      }]
    };

    if (this.user.subscription.status === 'canceled') {
      this.user.subscription.plan.id = null;
    }
  }

  submitOrder(planOrder, payment) {
    this.paymentError = null;

    return this.orderService.submit(planOrder, payment, this.user, planOrder.organizationId)
      .then((processedOrder) => {
        let subscription = _.first(processedOrder.items).subscription;

        this.newSubscription = subscription;
        this.user.subscription = subscription;
        this.step = 'orderSubmitted';
      })
      .catch(error => {
        this.$log.error('Error processing order', this.$log.toString(error));
        this.paymentError = 'An error occurred processing your order. Please try again or contact customer service.';
      });
  }

  onCancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Constructor;
