module.exports = function(ngModule) {
  ngModule.component('cfChooseFromListModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./choose-from-list-modal.template.html'),
    controller: require('./choose-from-list-modal.controller.js'),
    controllerAs: 'vm'
  });
};
