'use strict';

module.exports = function () {
  //noinspection JSLint
  'ngInject';

  var vm = this;
  vm.editMode = false;

  vm.save = function () {
    vm.onUpdate({value: vm.ngModel});
    vm.editMode = false;
  };

  vm.edit = function () {
    vm.ngModelCopy = vm.ngModel;
    vm.editMode = true;
  };

  vm.reset = function () {
    vm.ngModel = vm.ngModelCopy;
    vm.editMode = false;
  };

};
