module.exports = function(ngModule) {
  ngModule.service('planType', function($rootScope) {
    //noinspection JSLint
    'ngInject';

    let productName,
      planType = 'Basic Risk Management Plan';

    // Add new menu object by menu id
    return {
      getProductName: function() {
        return productName;
      },
      setProductName: function(name) {
        productName = name;
      },
      getPlanType: function() {
        return planType;
      },
      setPlanType: function(type) {
        planType = type;
        $rootScope.$broadcast('planTypeUpdated');
      },
      getPlanMode: function() {
        var plan = {};
        switch (planType) {
          case 'Hazard Analysis and Critical Control Points (HACCP) Plan':
            plan.isRecall = false;
            plan.isBasic = false;
            plan.isHACCP = true;
            break;
          case 'Basic Risk Management Plan':
            plan.isRecall = false;
            plan.isBasic = true;
            plan.isHACCP = false;
            break;
          case 'Recall Plan':
            plan.isRecall = true;
            plan.isBasic = false;
            plan.isHACCP = false;
            break;
          default:
            //Basic case is default.
            plan.isRecall = false;
            plan.isBasic = true;
            plan.isHACCP = false;
        }
        return plan;
      }
    };
  });
};
