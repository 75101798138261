'use strict';

module.exports = function ($uibModalInstance, letterVersion) {
  //noinspection JSLint
  'ngInject';

  var vm = this;
  vm.name = letterVersion.timestamp;
  vm.ok = function () {
    $uibModalInstance.close(letterVersion);
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
};