class Constructor {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.user = this.resolve.user;
    this.stripeConstants = this.resolve.stripeConstants;
    this.product = this.resolve.product;
    this.isPlanPrompt = this.resolve.isPlanPrompt;
  }
}

module.exports = Constructor;
