const _ = require('lodash');

module.exports = function(ngModule) {
  ngModule.factory('drift', function(organizations, $window, users) {
    let userSet = false;

    return {
      userLoggedIn: (authUser) => {
        if (!$window.drift) { return; }

        $window.drift.identify(authUser.uid);
        userSet = true;
        $window.drift.on('ready', () => {
          users.getProfile(authUser.uid).then(user => {
            if (!_.get(user, 'organizationId')) { return; }

            organizations.getName(user.organizationId).then(orgName => {
              $window.drift.api.setUserAttributes({
                email: user.email,
                nickname: `${user.firstName} ${user.lastName}`,
                company: orgName
              });
            });
          });
        });
      },
      stateChanged: (stateName) => {
        if (!$window.drift) { return; }

        if (userSet) {
          $window.drift.page(stateName);
        }
      },
      openSidebar: () => {
        if (!$window.drift) { return; }

        $window.drift.api.sidebar.open();
      },
      scheduleMeeting: (driftUserId) => {
        if (!$window.drift) { return; }

        $window.drift.api.scheduleMeeting(driftUserId);
      },
      startInteraction: (interactionId) => {
        if (!$window.drift) { return; }

        $window.drift.api.startInteraction({interactionId: interactionId, goToConversation: true});
      }
    };
  });
};
