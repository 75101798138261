class Controller {
  constructor(utils, authentication, $state) {
    'ngInject';

    this.utils = utils;
    this.authentication = authentication;
    this.$state = $state;
  }

  $onInit() {
    this.storageAvailable = this.utils.storageAvailable('localStorage');
    this.user = {companyName: this.companyName};
    if(this.$state.params.aid && this.$state.params.key) {
      this.hideContent = true;
    }
  }

  onOauth($oauthUser) {
    this.oauthUser = $oauthUser;
  }

  signup(state, params) {
    this.$state.go(state, params);
  }
}

module.exports = Controller;
