'use strict';

module.exports = function(ngModule) {
  ngModule.filter('propsFilter', function() {
    'ngInject';

    return function(items, props) {
      let out = [];

      if (_.isArray(items)) {
        items.forEach(function(item) {
          let itemMatches = false, keys = Object.keys(props), i, prop, text;

          for (i = 0; i < keys.length; i++) {
            prop = keys[i];
            text = props[prop].toLowerCase();
            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  });
};
