class Constructor {
  constructor($state, urlTokens, $log, $stateParams, $q, utils) {
    'ngInject';

    this.$state = $state;
    this.urlTokens = urlTokens;
    this.$log = $log;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.utils = utils;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.numMissing = this.resolve.numMissing;
    this.token = this.resolve.token;

    this.step = this.numMissing ? 1 : 2;

    this.resetNewContact();
    this.contacts = [];
  }

  resetNewContact() {
    this.newContact = {name: '', email: ''};
  }

  addContact() {
    this.contacts.push({
      name: this.newContact.name,
      email: this.newContact.email
    });
    this.resetNewContact();
  }

  removeContact(index) {
    this.contacts.splice(index, 1);
  }

  shareContacts() {
    this.sharing = true;
    this.urlTokens.saveMetadata(this.$stateParams.token, this.$stateParams.accessKey, 'sharedMissingFileContacts',
      this.contacts)
      .catch(err => this.$log.error('File upload portal unable to share contacts', this.$log.toString(err)))
      .finally(() => {
        this.sharing = false;
        this.step++;
      });
  }

  skip() {
    this.step = 2;
  }

  close() {
    let commentsPromise = this.comments ? this.urlTokens.saveMetadata(this.$stateParams.token,
      this.$stateParams.accessKey, 'portalSubmitComments', {text: this.comments}) : null;

    this.$q.when(commentsPromise).then(() => this.modalInstance.close())
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to submit files.'));
  }
}

module.exports = Constructor;
