'use strict';

module.exports = function(ngModule) {
  ngModule.factory('ProductGroupSearch', function($timeout, Search) {
    'ngInject';

    class ProductGroupSearch extends Search {

      constructor(user, orgId, options) {
        options = options || {};
        super(user, {
          uri: 'search/productGroup',
          type: 'productGroup',
          max: options.size || 300, // Maximum number of recs to pull back
          pageLimit: 30,    // Number of recs to show per page
          body: {},
          context: {
            organizationId: [orgId]
          }
        }, 'productGroups', false);
        this.orgId = orgId;
      }

      search(text) {
        var body = {
          query: {
            bool: {
              must: [{
                term: {
                  organizationId: this.orgId
                }
              }]
            }
          }
        };

        if (text) {
          body.query.bool.must.push({
            'match_phrase_prefix': {
              groupName: {
                query: text,
                slop: 3,            // Allow terms to appear out of order by this many positions
                'max_expansions': 20  // To improve performance, limit results of the final search term to this many
              }
            }
          });
        } else {
          body.sort = [
            {'groupName.keyword': {order: 'asc'}}
          ];
        }

        this.setSearchQuery(body);

        return super.search();
      }
    }

    return ProductGroupSearch;
  });
};
