module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceEditOrderItem', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./marketplace.edit-order-item.template.html'),
    controller: require('./marketplace.edit-order-item.controller.js'),
    controllerAs: 'vm'
  });
};
