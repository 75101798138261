'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfOrderPaymentContent', {
    require: {
      orderCtrl: '^^cfOrderPayment'
    },
    bindings: {
      error: '=',
      rushAvailable: '<',
      saveCardDisabled: '='
    },
    template: require('./order-payment.content.template.html'),
    controller: require('./order-payment.content.controller.js'),
    controllerAs: 'vm'
  });
};
