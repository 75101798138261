const ErrorParser = require('error-stack-parser');

module.exports = function(ngModule) {
  ngModule.decorator('$log', function($delegate, $injector, deviceDetector, $window) {
    'ngInject';

    // The error codes in the blacklist array will be skipped. Generally they are noisy logs.
    const loggingBlacklist = [
      {code: 'PERMISSION_DENIED'},
      {code: 'Invalid argument.', browser: 'ie'},
      ],
      maxLogsPerMin = 20;

    let recentLogs = [],
      systemInfo = _.pick(deviceDetector, ['os', 'os_version', 'browser', 'browser_version']);

    systemInfo.device = deviceDetector.isDesktop() ? 'desktop' : deviceDetector.isMobile() ? 'mobile' : 'other';

    // If we get into an infinite logging loop, we don't want to kill the server.
    function velocityExceeded() {
      let curTime = new Date().getTime();

      _.remove(recentLogs, function(logTime) {
        return curTime - logTime > 60000; // remove log occurrences greater than 1 min old
      });

      if (recentLogs.length >= maxLogsPerMin) {
        recentLogs.shift();
        recentLogs.push(curTime);
        return true;
      }

      recentLogs.push(curTime);

      return false;
    }

    function logLevelNum(level) {
      switch (level) {
      case 'error':
        return 1;
      case 'warn':
        return 2;
      case 'info':
        return 3;
      case 'debug':
        return 4;
      default:
        return 4;
      }
    }

    function fromError(error) {
      return {
        message: error.message,
        stack: ErrorParser.parse(error)
      };
    }

    function enhanceDbLogging(fnName, suppressDefault) {
      const origFn = $delegate[fnName],
        $http = $injector.get('$http');

      $delegate[fnName] = function() {
        // If this log is on the blacklist, just run the default handler. Do not send to loggy though.
        let authentication = $injector.get('authentication'),
          args = [].slice.call(arguments);

        if (_.some(loggingBlacklist, (entry) => {
          let codeMatch = entry.code === args[0].code;
          let browserMatch = !entry.browser || entry.browser === systemInfo.browser;

          return codeMatch && browserMatch;
        }) || velocityExceeded()) {
          origFn.apply(null, args);
          return;
        }
        let body;

        try {
          body = _.isString(args[0]) ? {message: args[0]} : fromError(args[0]);
        } catch(err) {
          body = {message: args[0]};
        }
        // if (fnName === 'error' && gtag) {
        //   gtag.event('exception', {
        //     description: body.message,
        //     fatal: false
        //   });
        // }

        body.uid = authentication.user && authentication.user.uid;
        body.metaData = args.length >= 2 ? args[1] : {};

        // If the Error object was passed in the metadata property, we gotta move the stack to the body.
        if (_.isError(body.metaData)) {
          body.metaData.appMessage = body.message;
          try {
            _.assign(body, fromError(body.metaData));
          } catch(error) {
            /* eslint angular/log: 0 */
            console.log('Unable to parse error: ' + error);
          }
        } else if (!_.isObject(body.metaData)) {
          body.metaData = {
            callerMetadata: body.metaData
          };
        }

        body.options = args.length >= 3 ? args[2] : undefined;
        _.assign(body.metaData, systemInfo);

        if (_.isObjectLike(body.metaData) && !_.isString(body.metaData)) {
          body.metaData.url = $window.location.hash;
        }

        if (!suppressDefault) {
          origFn.apply(null, args);
        }

        return $http.post('loggy/' + fnName, body);
      };
    }

    firebase.database().ref('/config/logLevel').once('value').then(function(logLevelSnap) {
      let logLevelObj = logLevelSnap.val();

      if (logLevelObj.consoleLogLevel < logLevelNum('debug')) {
        $delegate.debug = angular.noop;
      }

      if (logLevelObj.consoleLogLevel < logLevelNum('info')) {
        $delegate.info = angular.noop;
      }

      if (logLevelObj.consoleLogLevel < logLevelNum('warn')) {
        $delegate.warn = angular.noop;
      }

      if (logLevelObj.consoleLogLevel < logLevelNum('error')) {
        $delegate.error = angular.noop;
      }

      if (logLevelObj.dbLogLevel >= logLevelNum('debug')) {
        enhanceDbLogging('debug');
      }

      if (logLevelObj.dbLogLevel >= logLevelNum('info')) {
        enhanceDbLogging('info');
      }

      if (logLevelObj.dbLogLevel >= logLevelNum('warn')) {
        enhanceDbLogging('warn');
      }

      if (logLevelObj.dbLogLevel >= logLevelNum('error')) {
        enhanceDbLogging('error');
      }

      enhanceDbLogging('log', true);

      $delegate.metric = (metadata) => {
        // if (gtag) {
        //    gtag.event('timing_complete', {
        //      name: metadata.name,
        //      value: metadata.durationMs,
        //      'event_category': 'App Loading'
        //    });
        // }
        return $delegate.log('Performance metric', metadata);
      };
    });

    $delegate.toString = function(err) {
      return !_.isError(err) && (_.isObject(err) || _.isArray(err)) ? angular.toJson(err) : err;
    };

    return $delegate;
  });
};
