'use strict';

module.exports = function($sce, $uibModal, $firebaseArray, $firebaseObject, growl, confirmDeleteModal, authorization) {
  'ngInject';

  var vm = this;

  vm.showPublished = false;
  vm.showSaved = false;
  vm.showVersions = false;
  vm.clientView = false;
  vm.editorView = true;
  vm.editorOptions = {};

  if (!!vm.recall.noticeLetter) {
    vm.letter = vm.recall.noticeLetter;
  }

  vm.formatDate = function(dateString) {
    var date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  vm.orderByDateStringDesc = function(letterVersion) {
    return -(new Date(letterVersion.timestamp).getTime());
  };

  //Instant Messaging.
  vm.addLetterComment = function() {
    vm.openCommentWindow();

    vm.showPublished = false;
    vm.showSaved = false;
  };

  vm.save = function(versionDescription) {
    var d = new Date(),
      letter = vm.letter,
      letterVersion = {
        fromId: vm.user.uid,
        fromName: vm.user.fullName(),
        content: letter,
        description: versionDescription || null,
        timestamp: vm.formatDate(d),
        productId: vm.product.$id
      };

    vm.recall.noticeLetter = letter;
    vm.recall.$save().then(function() {
      vm.letterVersions.$add(letterVersion).then(function() {
        vm.showSaved = true;
        growl.success('Notice Letter is Saved.', {});
      });
    }).catch(function() {
      growl.error('Unable to save letter.', {});
    });
  };

  vm.showLetterVersions = function() {
    if (vm.showVersions) {
      vm.showVersions = false;
    } else {
      vm.letterVersions = $firebaseArray(vm.recall.$ref().child('/letterVersions/'));
      vm.showVersions = true;
    }
  };

  vm.showLetterClientView = function() {
    if (vm.clientView) {
      vm.clientView = false;
      vm.editorView = true;
    } else {
      vm.clientView = true;
      vm.clientletter = $sce.trustAsHtml(vm.recall.noticeLetter.replace('\n', ''));
    }
  };
  vm.showEditorView = function() {
    vm.clientView = false;
    vm.editorView = true;
  };

  vm.showVersionClient = function(letter) {
    var value = vm.letterVersions.$getRecord(letter.$id);
    if (!!value) {
      vm.letter = letter;
      vm.clientletter = $sce.trustAsHtml(value.content.replace('\n', ''));
      vm.clientView = true;
      vm.editorView = false;
    }
  };

  vm.restoreLetter = function(version) {
    vm.recall.noticeLetter = version.content;
    vm.recall.$save();
    var letterVersion = {
      fromId: vm.user.uid,
      fromName: vm.user.fullName(),
      content: version.content,
      description: 'RESTORED: ' + version.description,
      timestamp: vm.formatDate(new Date()),
      productId: vm.product.$id
    };

    vm.letterVersions.$add(letterVersion).then(function() {
      vm.letter = version.content;

      vm.showEditorView();
      vm.showVersions = false;
      growl.success('Letter is Restored.', {});
    });
  };

  vm.printContent = function(el) {
    var restorePage = document.body.innerHTML;
    document.body.innerHTML = document.getElementById(el).innerHTML;
    window.print();
    document.body.innerHTML = restorePage;
  };

  vm.removeVersionView = function(version) {
    vm.selectedItem = version;
    vm.remove(version);
  };

  vm.restoreVersionView = function(version) {
    if (!version) {
      vm.selectedItem = vm.letter;
    } else {
      vm.selectedItem = version;
    }
    vm.restoreLetterVersionModal();
  };

  //to confirm delete
  vm.restoreLetterVersionModal = function() {
    $uibModal.open({
      template: require('./modals/restore_version/restore-version.template.html'),
      controller: require('./modals/restore_version/restore-version.controller.js'),
      controllerAs: 'vm',
      resolve: {
        letterVersion: function() {
          return vm.selectedItem;
        }
      }
    }).result.then(function(letterVersion) {
      if (letterVersion) {
        vm.restoreLetter(letterVersion);
      }
    });
  };

  vm.remove = function(letterVersion) {
    if (this.user.hasPermission(authorization.claims.PRODUCT_WRITE)) {
      confirmDeleteModal('Letter Version: ' + vm.formatDate(letterVersion.timestamp)).then(function() {
        if (letterVersion) {
          vm.letterVersions.$remove(letterVersion).then(function() {
            growl.success('Letter version removed successfully.', {});
          }).catch(function(err) {
            growl.error('Error deleting letter version: ' + err, {});
          });
        }
      });
    } else {
      growl.error('Insufficient access. Please contact customer support.');
    }

  };

  vm.saveLetterModal = function() {
    $uibModal.open({
      size: 'lg',
      template: require('./modals/save_letter/save-letter.template.html'),
      controller: require('./modals/save_letter/save-letter.controller.js'),
      controllerAs: 'vm',
      resolve: {}
    }).result.then(function(versionDescription) {
      vm.save(versionDescription);
    });
  };

  vm.loadLetterDataModal = function() {
    $uibModal.open({
      template: require('./modals/load_letter_data/load-letter-data.template.html'),
      controller: require('./modals/load_letter_data/load-letter-data.controller.js'),
      controllerAs: 'vm'
    }).result.then(function() {
      vm.loadLetterData();
    });
  };
  vm.loadLetterTemplateModal = function() {
    $uibModal.open({
      template: require('./modals/load_letter_template/load-letter-template.template.html'),
      controller: require('./modals/load_letter_template/load-letter-template.controller.js'),
      controllerAs: 'vm'
    }).result.then(function() {
      vm.loadLetterTemplate();
    });
  };

  vm.loadLetterData = function() {

    vm.letterFormatWithData();
    var letterVersion = {
      fromId: vm.user.uid,
      fromName: vm.user.fullName(),
      description: 'Loaded Data into Letter Template',
      content: vm.letter,
      timestamp: new Date().toDateString() + ' ' + new Date().toTimeString(),
      productId: vm.product.$id
    };
    vm.letterVersions.$add(letterVersion);
  };

  vm.letterFormatWithData = function() {
    var product = vm.product,
      company = vm.company,
      letterContent = '',
      quantity = '',
      reason = '',
      lotNumbers = '',
      upcCodes = '',
      date = vm.formatDate(new Date());

    $firebaseObject(vm.recall.$ref().child('/volume/').child('totalQuantity')).$loaded().then(function(totalQuantity) {
      if (!totalQuantity.$value) {
        quantity = '[insert quantity]';
      } else {
        quantity = totalQuantity.$value;
      }
    });
    $firebaseObject(vm.recall.$ref().child('/reason/').child('whyDefective')).$loaded().then(function(defective) {
      if (!defective.$value) {
        reason = '[Insert reason for recall]';
      } else {
        reason = defective.$value;
      }
    });
    $firebaseObject(vm.recall.$ref().child('/upcCodes')).$loaded().then(function(upcCodeList) {
      if (!upcCodeList.$value) {
        upcCodes = '[Insert UPC Codes]';
      } else {
        upcCodes = upcCodeList.$value;
      }
    });
    $firebaseObject(vm.recall.$ref().child('/lotNumbers')).$loaded().then(function(lot) {
      if (!lot) {
        lotNumbers = '[Insert lot numbers]';
      } else {
        lotNumbers = lot.$value;
      }
      letterContent += '<h3>' + company.companyName + ' Voluntarily Recalls ' + ' + quantity + ' +
        product.brandName + ' ' + reason + '<\/h3>';
      letterContent += '<p>Date: ' + date + '<\/p>';
      letterContent += '<h3>FOR IMMEDIATE RELEASE:</h3>';
      letterContent += '<p>' + company.companyName + ' is voluntarily recalling [x] Lot Codes of ' + product.brandName;
      letterContent += ', ' + product.description + ' representing ' + quantity + '.  ' + reason + '<\/p>';
      letterContent += '<p>This action relates only to ' + company.companyName;
      letterContent += ' products with any of these Lot Numbers or UPC Codes: <\/p>';
      letterContent += '<p>Lot Numbers:<strong>' + lotNumbers + '<\/strong><\/p>';
      letterContent += '<p>UPC Codes:<strong>' + upcCodes + '<\/strong><\/p>';
      letterContent += '<p>No other Lot Numbers, UPC Codes, or any other ' + company.companyName;
      letterContent += ' products, are involved in this action.<\/p>';
      letterContent += '<p>' + company.companyName + ' is conducting this voluntary recall because ' +
        product.brandName + '[modify more: because...]  Customers are asked to remove ' +
       'all product with numbers and codes listed above out of distribution immediately. <\/p>';
      letterContent += '<p>For more information, please contact us at:  XXX-XXX-XXXX ' +
        '(between Monday through Friday 8:00 am to 4:30 pm CST) or email at info@[companyemail].com  <\/p>';
      vm.recall.noticeLetter = vm.letter = letterContent;
    });
  };
};
