module.exports = function(ngModule) {
  ngModule.constant('preventiveControlTypes', [
    {id: 'allergen', name: 'Allergen', shortName: 'Allergen'},
    {id: 'sanitation', name: 'Sanitation', shortName: 'Sanitation'},
    {id: 'process', name: 'Process', shortName: 'Process'},
    {id: 'supplier', name: 'Supply Chain', shortName: 'Supplier'},
    {id: 'sop', name: 'Prerequisite Program (SOP)', shortName: 'SOP'},
    {id: 'other', name: 'Other', shortName: 'Other'}
  ]);
};
