class Controller {
  constructor(units, unitContainers) {
    'ngInject';
    this.unitList = units();
    this.unitContainers = unitContainers();
  }

  $onInit() {
    this.$material = this.resolve.$material;
    this.ingredients = this.resolve.ingredients;
    this.supplierNameMap = this.resolve.supplierNameMap;
  }

  cancel() {
    return this.modalInstance.dismiss('cancel');
  }

  save() {
    this.saving = true;

    return this.$material.$save()
      .then(() => this.modalInstance.close(this.$material))
      .catch((err) => this.modalInstance.dismiss(err));
  }
}

module.exports = Controller;
