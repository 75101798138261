class Controller {
  constructor($state, authorization) {
    'ngInject';

    this.$state = $state;
    this.authorization = authorization;
  }

  getHeading() {
    return this.supplierIngredient.$value === null ? 'New Supplier Ingredient' : this.supplierIngredient.brandName;
  };
}

module.exports = Controller;
