class Controller {
  constructor($log, $stateParams, $location, $window, $http,
              authentication, growl, utils, orgInteractionService, authorization, $state) {
    'ngInject';

    this.$log = $log;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$window = $window;
    this.$http = $http;
    this.authentication = authentication;
    this.growl = growl;
    this.utils = utils;
    this.orgInteractionService = orgInteractionService;
    this.freshDeskSso = $stateParams.freshDeskSso;
    this.zendeskSso = $stateParams.zendeskSso;
    // this.returnTo = $stateParams.return_to;
    this.authorization = authorization;
    this.serviceId = $stateParams.serviceId;
    this.$state = $state;
    this.loggingIn = false;
  }

  $onInit() {
    this.userFields = {};
    if (this.$stateParams.email) {
      this.userFields.email = this.$stateParams.email;
    }
  };

  processLogin(fbUser) {

    let locationUrl = this.$location.absUrl();
    let zendeskSso = locationUrl.match('zendeskSso');

    const zendeskUrlParams = new URLSearchParams(locationUrl);
    let returnTo = zendeskUrlParams.get('return_to');

    if(locationUrl && zendeskSso) {
      this.authentication.zendeskSsoLogin(returnTo, this.onSignedIn);
    }

    let toState = 'user.dashboard', toParams = undefined;

    if (this.$window.localStorage) {
      let savedToState = this.$window.localStorage.getItem('pendingStateName');

      if (savedToState) {
        toState = savedToState;
        toParams = angular.fromJson(this.$window.localStorage.getItem('pendingStateParams') || '{}');
        this.$window.localStorage.removeItem('pendingStateName');
        this.$window.localStorage.removeItem('pendingStateParams');
      }
    }

    if (this.serviceId) {
      toState = 'marketplace.servicesAvailable';
      toParams = {serviceId: this.serviceId};
    }

    if (fbUser.isTemporaryPassword) {
      toState = 'user.settings.changePassword';
      toParams = {};
    }

    this.authentication.waitForAuth()
      .then((user) => {
        return this.orgInteractionService.processUrlToken(user, this.tokenData, user.organizationId, true);
      })
      .then(() => {
        return this.onSignedIn({
          $preferredState: toState,
          $preferredParams: toParams
        });
      });
  }

  loginOAuth(referrer) {
    this.loggingIn = true;
    this.authentication.loginOAuth(referrer)
      .then((fbUser) => this.processLogin(fbUser))
      .catch((err) => {
        this.$log.error('OAuth login error: ', err);

        switch (err.code) {
        case 'auth/user-not-found':
          this.growl.error('User not found. Please register before signing in.');
          if (_.isFunction(this.onUserNotFound)) {
            this.onUserNotFound();
          }
          break;
        case 'auth/user-cancelled':
        case 'auth/internal-error':
        case 'auth/popup-closed-by-user':
          break;
        default:
          this.growl.error('Unknown server error occurred. Please try again.');
        }

        this.loggingIn = false;
      });
  }

  loginLocal() {
    this.loggingIn = true;
    this.authentication.loginLocal(this.userFields.email, this.userFields.password)
      .then((user) => {
        this.authentication.user = user;
        return this.processLogin(user);
      })
      .catch((err) => {
        this.$log.warn('Login error: ', err);

        this.severityClass = 'alert-danger';
        switch (err.code) {
        case 'auth/user-not-found':
          this.loginForm.email.$setValidity('login', false);
          this.severityClass = 'alert-warning';
          this.errorMessage = 'User not found. Need an account? ' +
            `<a href="${this.$state.href('signup.begin')}">Create a FoodReady account.</a>`;
          break;
        case 'auth/wrong-password':
          this.loginForm.password.$setValidity('login', false);
          this.errorMessage = 'The password is invalid.';
          break;
        case 'auth/too-many-requests':
          this.loginForm.password.$setValidity('login', false);
          this.errorMessage = 'Too many attempts. Please try later or contact customer support.';
          break;
        case 'auth/invalid-email':
          this.loginForm.email.$setValidity('login', false);
          this.errorMessage = 'Please enter a valid email address';
          break;
        default:
          this.errorMessage = err.message;
          this.growl.error('Unknown server error occurred. Please try again.');
          throw new Error(err);
        }
      }).finally(() => {
        this.loggingIn = false;
      });
  }
}

module.exports =  Controller;
