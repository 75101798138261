class Controller {
  constructor($timeout, $q, $uibModal, preventDirtyNav, $state, growl, $log, users, utils, organizations) {
    'ngInject';

    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.$state = $state;
    this.growl = growl;
    this.$log = $log;
    this.$q = $q;
    this.$uibModal = $uibModal;
    this.users = users;
    this.utils = utils;
    this.organizations = organizations;
  }

  $onInit() {
    this.$timeout(() => {
      this.unsubscribeHandle = this.preventDirtyNav(this.gmpForm, 'You have unsaved changes to cGMPs.',
        () => this.save());
    }, 0);

    this.setConfirmed(this.companyCategory);
    let currentCategoryIndex = this.$state.params.categoryId ?
      _.findIndex(this.$gmps, c => c.$id === this.$state.params.categoryId) : 0;

    this.lastCategory = this.$gmps.length === currentCategoryIndex + 1;
    this.nextCategory = this.lastCategory ? null : this.$gmps[currentCategoryIndex + 1].$id;
  }

  cancel() {
    if (this.gmpForm) {
      this.gmpForm.$setPristine();
    }

    return this.$state.go('checklists.gmps.audits');
  }

  save() {
    return this.$uibModal.open({
      component: 'cfGmpConfirm',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        category: () => this.companyCategory
      }
    }).result
      .then(() => {
        let now = new Date().getTime();

        if (!_.get(this, 'gmpForm.$pristine')) {
          this.companyCategory.savedBy = this.user.uid;
          this.companyCategory.savedOn = now;
        }

        this.companyCategory.confirmedBy = this.user.uid;
        this.companyCategory.confirmedOn = now;

        return this.$q.all([this.companyCategory.$save(), this.companyQuestions.$save()])
          .then(() => {
            this.unsubscribeHandle();
            this.setConfirmed(this.companyCategory);
            this.organizations.setMilestoneAchieved(this.user.orgContext.id, this.organizations.milestones.GMP);
            this.growl.success((!_.get(this, 'gmpForm.$pristine') ? 'Changes to ' : '') + 'cGMPs ' +
              (!_.get(this, 'gmpForm.$pristine') ? 'saved & ' : '') + 'confirmed successfully.');

            if(this.gmpForm) {
              this.gmpForm.$setPristine();
            }
            this.next();
          })
          .catch(err => {
            this.$log.error('Error saving gmps: ' + err, {categoryFolder: this.category.folder});
            this.growl.error('An error occurred saving changes.');
          });
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to save / confirm cGMPs.'));
  };

  setConfirmed(category) {
    this.confirmed = {
      on: category.confirmedOn && moment(category.confirmedOn).format('MMMM Do YYYY, h:mm:ss a'),
      by: _.get(this, 'confirmed.by')
    };

    if (this.confirmed.on && category.confirmedBy) {
      this.users.getName(category.confirmedBy)
        .then((name) => this.confirmed.by = name);
    }
  };

  next() {
    if (this.nextCategory) {
      this.$state.go(this.$state.current.name, {categoryId: this.nextCategory});
    } else {
      this.$state.go('checklists.gmps.audits');
    }
  }
}

module.exports = Controller;
