module.exports = function(ngModule) {
  ngModule.component('cfCopyProduct', {
    bindings: {
      user: '<',
      sourceProduct: '<',
      onCopy: '&',
      onCancel: '&'
    },
    template: require('./products.copy-product.template.html'),
    controller: require('./products.copy-product.controller'),
    controllerAs: 'vm'
  });
};
