class Constructor {
  constructor(users, utils, $state, confirmDeleteModal, growl, $q, productCustomersService, $uibModal,batchService,foodProductService,) {
    'ngInject';

    this.users = users;
    this.utils = utils;
    this.$state = $state;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.$q = $q;
    this.productCustomersService = productCustomersService;
    this.$uibModal = $uibModal;
    this.batchService = batchService;
    this.foodProductService = foodProductService;
  }

  $onInit() {
    if(this.orders.length > 0) {
      this.noOrders = false;
    } else {
      this.noOrders = true;
    }
  }

  editOrder(order) {
    this.$uibModal.open({
      component: 'cfSalesOrders',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        order: () => order,
        productCustomer: () => this.productCustomer,
      }
    }).result
      .then(() => {
        this.growl.success('Save Orders');
        this.productCustomersService.getAllOrders(this.user.orgContext.id, this.productCustomer.productCustomerId)
          .then(orders =>{
            if(orders.length > 0) {
              this.noOrders = false;
              this.orders = orders;
            }
          });
      })
      .catch(err => {
        return this.utils.defaultErrorHandler(err, 'Error editing Orders.');
      });
  }

  deleteOrder(salesOrderId, index) {
    this.productCustomersService.deleteOrder(this.user.orgContext.id, salesOrderId).then(()=>{
      this.orders.splice(index, 1);
      if(!this.orders.length) {
        this.noOrders = true;
      }
      this.growl.success('Order Deleted');
    })
      .catch(()=>{
        this.growl.error('Unable to delete Order');
      });
  }
}

module.exports = Constructor;
