module.exports = function(ngModule) {
  ngModule.component('cfUsersSettings', {
    bindings: {
      user: '<',
      organization: '<'
    },
    template: require('./users.settings.template.html'),
    controller: require('./users.settings.controller.js'),
    controllerAs: 'vm'
  });

  require('./profile')(ngModule);
  require('./account')(ngModule);
  require('./billing')(ngModule);
  require('./users')(ngModule);
  require('./plan_templates')(ngModule);
  require('./mobile_app')(ngModule);
  require('./user_groups')(ngModule);
};
