'use strict';

module.exports = function(order, notifications, services, $q, $log, billingCodes) {
  'ngInject';

  const vm = this;

  function sendNotification(orderId) {
    notifications.postToOrg({
      from: vm.user.uid,
      to: vm.team.id,
      message: 'Invoices paid by ' + vm.user.currentOrgContext().companyName,
      type: notifications.types.SERVICE,
      metaData: {
        orderId: orderId
      },
      link: {
        state: 'analysis.team.services',
        params: {
          teamId: vm.team.id
        }
      }
    });
  }

  function toOrderItems(invoices) {
    return _.map(invoices, i => {
      return {
        title: i.name,
        amount: {standard: i.amount},
        billingCode: billingCodes.MISC_SERVICE,
        comments: i.description,
        serviceId: i.$id,
        planId: i.planId || null
      };
    });
  }

  vm.$onInit = function() {
    vm.invoices = vm.resolve.invoices;
    vm.team = vm.resolve.team;
    vm.user = vm.resolve.user;
    vm.organization = vm.resolve.organization;

    vm.step = 'submitOrder';
    vm.billingCode = billingCodes.MISC_SERVICE.id;

    vm.order = {
      team: vm.team,
      items: toOrderItems(vm.invoices)
    };
  };

  vm.onPaymentCancel = function() {
    vm.modalInstance.dismiss('cancel');
  };

  vm.submitOrder = function(newOrder, payment) {
    let processedOrder;

    return order.submit(newOrder, payment, vm.user, vm.user.currentOrgContext().id)
      .then(order => {
        processedOrder = order;
        return $q.all(_.map(vm.invoices, i => services.setOrder(i, order.$id)));
      })
      .then(() => {
        // don't charge invoices that activate a monthly subscription.
        let filtered = _.filter(vm.invoices, i => _.isEmpty(i.planId));

        if (!filtered.length) { return; }
        let orderIdsToCharge = _.map(filtered, i => {
          return _.find(processedOrder.items, item => item.serviceId === i.$id).$id;
        });

        return order.charge(processedOrder.$id, orderIdsToCharge);
      })
      .then(() => {
        sendNotification(processedOrder.$id);
        return $q.all(_.map(vm.invoices, i => services.setPaid(i)));
      })
      .then(() => vm.step = 'orderSubmitted')
      .catch((error) => {
        $log.error('Error processing order', $log.toString(error));
        vm.paymentError = 'An error occurred processing your order. Please try again or contact customer service.';
      });
  };
};
