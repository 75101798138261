module.exports = function(ngModule) {
  ngModule.component('cfEditUser', {
    bindings: {
      editUser: '=',
      reviewsAccess: '=',
      user: '<',
      orgRole: '@',
      userSearch: '<'
    },
    template: require('./users.settings.users.edit.template.html'),
    controller: require('./users.settings.users.edit.controller'),
    controllerAs: 'vm'
  });
};
