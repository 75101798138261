class Controller {
  constructor($document, $q) {
    'ngInject';

    this.$document = $document;
    this.$q = $q;
  }

  $onInit() {
    this.$processStep = this.resolve.$processStep;
    this.messageHtml = this.resolve.messageHtml;
    this.disabled = this.resolve.disabled;

    this.newStep = this.$processStep.$value === null;
  }


  saveAndClose() {
    this.saving = true;

    this.$processStep.description = this.$processStep.description || null;
    return this.$processStep.$save()
      .then(() => this.modalInstance.close(this.$processStep));
  }


}

module.exports = Controller;
