module.exports = function (ngModule) {
  ngModule.component('frSendAppUrlModal', {
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
    template: require('./send-app-url-modal.html'),
    controller: require('./send-app-url-modal.controller'),
    controllerAs: 'vm',
  });
};
