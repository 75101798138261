module.exports = function (parentModuleName) {
  let moduleName = parentModuleName + '.checklists',
    ngModule = angular.module(moduleName, [
      require('./gmps')(moduleName),
      require('./operational')(moduleName),
      require('./library')(moduleName),
    ]);

  require('./_config')(ngModule);

  return moduleName;
};
