module.exports = function(ngModule) {
  ngModule.factory('imageCropModal', function($uibModal, $log, utils) {
    'ngInject';

    return function(options) {
      return $uibModal.open({
        template: require('../_modals/image-crop.view.html'),
        controllerAs: 'vm',
        backdrop: 'static',
        controller: function($uibModalInstance) {
          const vm = this;

          _.assign(vm, options);
          vm.done = function() {
            $uibModalInstance.close(vm.croppedImage);
          };

          vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }
      }).result
        .catch((reason) => {
          if (utils.isModalDismissalByUser(reason)) { return; }

          $log.error(reason);
        });
    };
  });
};
