class Constructor {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  $onInit() {
  }

  onSave() {
    let sop = _.find(this.sops, {$id: this.$sop.$id});

    if (sop) {
      sop.lastLog = this.$sop.lastLog;
    }

    this.$state.go('operations.sops.list');
  }

  onCancel() {
    this.$state.go('operations.sops.list');
  }
}

module.exports = Constructor;
