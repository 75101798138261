module.exports = function(ngModule) {
  ngModule.config(
    function($stateProvider) {
      'ngInject';

      $stateProvider.state('recall.problemReports', {
        url: '/problemReports',
        template: '<div ui-view></div>',
        abstract: true,
        resolve: {
          customers: (company, recallService) => recallService.$customers(company.$id)
        },
        controllerAs: 'vm'
      }).state('recall.problemReports.list', {
        url: '/list',
        template: '<cf-problem-reports customers="$resolve.customers" user="$resolve.user" ' +
          '$problem-reports="$resolve.problemReports"></cf-problem-reports>',
        controllerAs: 'vm',
        resolve: {
          problemReports: (recallService, user) => recallService.$problemReports(user.orgContext.id)
        }
      });
    }
  );
};
