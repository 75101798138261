class CfSearchInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-search-input.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',
      name: '@',             // The name of the form control
      placeholder: '@',
      appendDirection: '@?', // Append the icon to the right or the left (options: ['left', 'right'], default = 'left')
      helpText: '@?',        // Optional subscript helper text
      isDisabled: '=',       // Is the field disabled (true / false)
      keyup: '&onKeyup',
      searching: '<',
      inputCss: '@'
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';

    this.appendDirection = this.appendDirection || 'left';
  }

  link(scope, elem) {
    let $formElement = elem.find('input');

    $formElement.focus(() => $formElement.parent().addClass('g-state-focus'));
    $formElement.blur(() => $formElement.parent().removeClass('g-state-focus'));
  }
}

module.exports = CfSearchInput;
