class Constructor {
  constructor($log, productCategories, organizations) {
    'ngInject';

    this.$log = $log;
    this.productCategories = productCategories;
    this.organizations = organizations;
  }

  $onInit() {
    this.onboardingQuestionnaire = this.resolve.onboardingQuestionnaire;
    this.clientUserProfile = this.resolve.clientUserProfile;

    this.productCategories.getName(this.onboardingQuestionnaire.productCategory)
      .then(name => this.productCategory = name);

    if (this.resolve.requesterOrgId) {
      this.organizations.getWebsite(this.resolve.requesterOrgId)
        .then(website => this.website = website);

      this.loadingCouponCode = true;
      this.organizations.getSubscriptionCouponCodeId(this.resolve.requesterOrgId)
        .then((couponCodeId) => this.couponCodeId = couponCodeId)
        .catch((err) => this.$log.error('Could not fetch org coupon code ID.', this.$log.toString(err)))
        .finally(() => this.loadingCouponCode = false);
    }
  }
}

module.exports = Constructor;
