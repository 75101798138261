class Constructor {
  constructor($log, $document, $scope, authentication, orgPerspectives, $state) {
    'ngInject';

    this.$state = $state;
    this.$scope = $scope;
    this.authentication = authentication;
    this.orgPerspectives = orgPerspectives;
    this.$document = $document;
    this.$log = $log;
    this.isIe = !!document.documentMode;
    this.user = {};
  }

  $onInit() {
    if (this.authentication.user) {
      this.authentication.user.$loaded(user => {
        this.user = user;
      });
    }

    this.$scope.$watch('app.authentication.user', (val) => {
      if (!val || !val.$loaded) {
        this.user = undefined;
        return;
      }

      val.$loaded().then(user => {
        if (!user) {
          this.authentication.logout();
          return;
        }

        this.user = user;
      });
    });

/*    this.$scope.$watch('app.user.orgContext', (val) => {
      this.showOrgBanner = false;
      if (val) {
        this.showOrgBanner = !this.user.onPrimaryOrg() && this.user.getPerspective() === this.orgPerspectives.PROVIDER_ANALYST;
      }
    });*/
  }

  showOrgBanner() {
    return !_.isEmpty(this.user) && !this.user.onPrimaryOrg() &&
      this.user.getPerspective() === this.orgPerspectives.PROVIDER_ANALYST;
  }
}

module.exports = Constructor;
