module.exports = function(ngModule) {
  ngModule.factory('ingredients', function($firebaseObject, fbutil, $http) {
    'ngInject';

    return {
      get: function(ingredientId) {
        return $firebaseObject(fbutil.ref('ingredients', ingredientId)).$loaded();
      },

      getSupplierIngredient: function(supplierIngredientId) {
        return $firebaseObject(fbutil.ref('supplierIngredients', supplierIngredientId)).$loaded();
      },

      $pushIngredient: function(data) {
        return $firebaseObject(fbutil.ref('ingredients').push(data)).$loaded();
      },

      pushIngredient: function(data) {
        return  $http.post('inventoryItems', data).then(function(results) {
          return results.data?.data;
        });
      },

      updateIngredient: function(id, data) {
        return  $http.patch(`inventoryItems/${id}`, data).then(function(results) {
          return results.data?.data;
        });
      },

      deleteIngredient: function(id) {
        return  $http.delete(`inventoryItems/${id}`).then(function(results) {
          return results.data?.data;
        });
      },

      $pushSupplierIngredient: function(data) {
        return $firebaseObject(fbutil.ref('supplierIngredients').push(data)).$loaded();
      }
    };
  });
};
