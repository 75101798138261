class Controller {
  constructor(units, $scope, confirmDeleteModal, batchService, IngredientSearch, growl, $uibModal, ingredientService, $q) {
    'ngInject';
    this.$scope = $scope;
    this.unitList = units();
    this.confirmDeleteModal = confirmDeleteModal;
    this.batchService = batchService;
    this.IngredientSearch = IngredientSearch;
    this.growl = growl;
    this.ingredientService = ingredientService;
    this.$uibModal = $uibModal;
    this.$q = $q;
  }

  $onInit() {
    if(this.resolve) {
      this.$batch = this.resolve.$batch;
      this.user = this.resolve.user;
    }
    this.ingredients = new this.IngredientSearch(this.user, this.user.orgContext.id);
    this.selectedMaterials = {};
    this.material = {};
    this.currentMaterialId = '';
  }

  addIngredient(materialId) {
    this.$batch.billOfMaterials[materialId].ingredientId = this.material.$id;
    this.$batch.billOfMaterials[materialId].ingredientName = this.material.name;
  }

  editMaterial(id) {
    this.currentMaterialId = id;
    let material = this.$batch.billOfMaterials[id];

    this.ingredients.searchResults.forEach((ingredient)=>{
      if(ingredient.$id === material.ingredientId) {
        this.material = ingredient;
      }
    });
  }

  addNewIngredient() {
    this.$uibModal.open({
      component: 'cfEditIngredient',
      backdrop: 'static',
      resolve: {
        user: () => this.user,
        ingredient: () => this.ingredientService.$push(this.user.orgContext.id)
      }
    }).result.then(ingredient => {
      this.ingredients.searchResults = this.ingredients.searchResults || [];
      this.ingredients.searchResults.push(ingredient);
    });
  }

  addMultiMaterials() {
    this.$uibModal.open({
      component: 'cfChooseFromListModal',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        itemName: () => 'Ingredient',
        allowSkip: true,
        multiple: true,
        skipButtonClass: () => 'u-btn-primary',
        skipButtonHtml: () => '<i class="far fa-plus mr-2"></i> Add New Ingredient',
        header: () => '<i class="far fa-leaf mr-2"></i> Select Ingredient',
        searchObject: () => this.ingredients,
        columns: () => [
          {title: 'Name', property: 'name'},
          {title: 'Description', property: 'description'},
        ],
        noneFoundHtml: () => 'No ingredients found.'
      }
    }).result
      .then((ingredients) => {
        return ingredients ? ingredients :
          this.$uibModal.open({
            component: 'cfEditIngredient',
            backdrop: 'static',
            resolve: {
              user: () => this.user,
              ingredient: () => this.ingredientService.$push(this.user.orgContext.id),
              company: () => ({$id: this.user.orgContext.id})
            }
          }).result.then(i => [i]);
      })
      .then((ingredients) => {
        return this.$q.all(_.map(ingredients, (ingredient) => {

          return this.batchService.$pushMaterial(this.$batch.$id)
              .then(($material)=>{

                this.$batch.billOfMaterials = this.$batch.billOfMaterials || {};
                this.$batch.billOfMaterials[$material.$id] =
                {ingredientId: ingredient.$id,
                  ingredientName: ingredient.name,
                  quantity: 0,
                  unit: 'lb'};
              })
              .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to add ingredient to batch.'));
        }));
      }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to add new ingredient.'));
  }

  selectAllMaterial() {
    _.mapKeys(this.$batch.billOfMaterials, (value, key)=>{
      this.selectedMaterials[key] = !this.selectMaterials;
    });
  }

  removeMaterial(material,id) {
    this.confirmDeleteModal(material.ingredientName,{body: 'Are you sure you want to delete?'})
      .then(() => {
        this.$batch.billOfMaterials[id] = null;
        this._save();
      });
  }

  removeSelectedMaterials() {
    _.mapKeys(this.selectedMaterials, (flag, key) => {
      if(flag) {
        this.$batch.billOfMaterials[key] = null;
      }
    });
    this.selectMaterials = false;
    this.enableSelect = false;
    this._save();
  }

  _save() {
    this.$batch.$save().then(()=> {
      this.growl.success('Ingredient successfully saved.');
    }).catch((err) => this.utils.defaultErrorHandler(err, 'Unable to remove ingredient.'));
  }
}

module.exports = Controller;
