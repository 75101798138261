module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseObject, $http, utils, $firebaseArray) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseObject = $firebaseObject;
      this.$firebaseArray = $firebaseArray;
      this.$http = $http;
      this.utils = utils;
    }

    /**
     * Query recall events for a given organization.
     * @param {string} orgId The owning org's id
     * @param {object} options Additional search options
     * @param {string} options.searchText Limit to events with this search text.
     * @param {boolean} options.isMock Limit to mock events
     * @param {boolean} options.isReal Limit to real events
     * @param {number} from From index
     * @param {number} size Number of results to return
     * @returns {Promise} A promise that resolves to a recall event array
     */
    queryEvents(orgId, options = {}, from = 0, size = 999) {
      let url = `/recallEvents/${orgId}`;
      let parms = [
        `from=${from}`,
        `size=${size}`
      ];

      if (options.isMock && options.isReal) {
        throw new Error('Invalid search paramters.');
      }

      if (options.isMock) { parms.push('isMock=true'); }
      if (options.isReal) { parms.push('isReal=true'); }
      if (options.searchText) { parms.push(`searchText=${options.searchText}`); }

      return this.$http.get(`${url}${this.utils.toQueryParm(parms)}`)
        .then(result => _.get(result, 'data.events'));
    }

    $getEvent(eventId) {
      return this.$firebaseObject(this.fbutil.ref(`recallEvents/${eventId}`)).$loaded();
    }

    push(event) {
      return this.fbutil.ref('recallEvents').push(event);
    }

    remove(eventId) {
      return this.fbutil.ref(`recallEvents/${eventId}`).remove();
    }

    $recallReasonFiles($event) {
      return this.$firebaseArray($event.$ref().child('reasonFiles'));
    }

    $recallLotFiles($event) {
      return this.$firebaseArray($event.$ref().child('lotFiles'));
    }

    $recallNotifications($event) {
      return this.$firebaseArray($event.$ref().child('notifications')).$loaded();
    }

    $recallNotification($event, notificationId) {
      return this.$firebaseObject($event.$ref().child('notifications').child(notificationId)).$loaded();
    }

    $pushRecallNotification($event) {
      return this.$firebaseObject($event.$ref().child('notifications').push()).$loaded();
    }

    $getConsigneeImpact($event, consigneeId) {
      return this.$firebaseObject($event.$ref().child('consigneeImpact').child(consigneeId)).$loaded();
    }

    $getLetter($event) {
      return this.$firebaseArray($event.$ref().child('recallLetter')).$loaded();
    }
  }

  ngModule.service('recallEventsService', Service);
};
