class Constructor {
  constructor(moment, users, authorization, confirmDeleteModal, $log, growl, messageServices, $state,
              notifications) {
    'ngInject';

    this.moment = moment;
    this.users = users;
    this.authorization = authorization;
    this.confirmDeleteModal = confirmDeleteModal;
    this.$log = $log;
    this.growl = growl;
    this.messageServices = messageServices;
    this.$state = $state;
    this.notifications = notifications;
  }

  $onInit() {
    this.organizations = _.map(this.organizations, (val, id) => {
      val.$id = id;
      return val;
    });

    this.userNames = {};
    _.each(this.organizations, org => {
      if (_.isUndefined(org.createdBy) || this.userNames[org.createdBy]) { return; }
      this.users.getName(org.createdBy).then(name => {
        this.userNames[org.createdBy] = name;
      });
    });
  }

  message(org) {
    this.messageServices.getOrgToOrgTopic(this.user, org.$id).then(topicId => {
      this.$state.go('user.messages.message', {topicId: topicId});
    });
  }

  revoke(org) {
    this.confirmDeleteModal('role assigned to ' + org.name).then(() => {
      this.authorization.removeOrgFromOrganization(org.$id, this.user.orgContext.id)
        .then(() => {
          _.remove(this.organizations, o => o.$id === org.$id);
          return this.messageServices.deleteOrgToOrgTopic(this.user, org.$id);
        })
        .then(() => {
          this.notifications.postToOrg({
            from: this.user.uid,
            to: org.$id,
            message: this.user.orgContext.companyName + ' revoked your access to their organization. Please ' +
            'contact FoodReady support if this was a mistake.'
          });
        })
        .catch(err => {
          this.$log.error('Unable to revoke role', err);
          this.growl.error('Unable to revoke role');
        });
    }).catch(angular.noop);
  }
}

module.exports = Constructor;
