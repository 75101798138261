module.exports = function(ngModule) {

  class FirebaseObjectAPIAdapter {
    constructor(ref, api) {
      Object.defineProperties(this, {
        $remove: {
          value: async () => {
            await api.remove(ref.id);

            return this;
          },
          writable: false,
          enumerable: false,
          configurable: false,
        },

        $save: {
          value: async () => {

            Object.assign(this, ref);
            ref.$set(this);
            if (!ref.id) {
              const data = await api.create(ref);

              this.$resolved = true;

              ref.$set(data);
              Object.assign(this, ref);
            } else {
              await api.update(ref.id, ref);
            }

            return this;
          },
          writable: false,
          enumerable: false,
          configurable: false,
        },


        $loaded: {
          value: async () => {
            if (!ref.id) {
              return this;
            }

            const data = await api.get(ref.id);

            this.$resolved = true;

            ref.$set(data);
            Object.assign(this, ref);

            return this;
          },
          writable: false,
          enumerable: false,
          configurable: false,
        },

        $ref: { value: () => ref, writable: false, enumerable: false, configurable: false },
        $watch: { value: () => this, writable: false, enumerable: false, configurable: false },
        $destroy: { value: () => this, writable: false, enumerable: false, configurable: false },
        $resolved: { value: false, writable: true, enumerable: false, configurable: false },
      });

      Object.assign(this, ref);
    }
  }

  class FirebaseRefAdapter {
    constructor({ id, $id, key, ...data } = {}) {
      const eventListenersMap = new Map();

      Object.defineProperties(this, {
        $set: { value: ({ id, ...rest }) => {
          Object.assign(this, rest);

          this.id = id;
          this.$id = id;
          this.key = id;

          if (eventListenersMap.has('value')) {
            const eventListeners = eventListenersMap.get('value');

            eventListeners.forEach(cb => cb({ val: () => this, exists: () => true }));
          }
        }, writable: false, enumerable: false, configurable: false },

        once: {
          value: (event, cb) => {
            this.on(event, (data) => {
              this.off(event, cb);

              return cb(data);
            });
          },
          writable: false,
          enumerable: false,
          configurable: false,
        },

        on: {
          value: (event, cb) => {
            if (!eventListenersMap.has(event)) {
              eventListenersMap.set(event, new Set());
            }

            const eventListeners = eventListenersMap.get(event);

            eventListeners.add(cb);

            return cb;
          },
          writable: false,
          enumerable: false,
          configurable: false
        },

        off: {
          value: (event, cb) => {
            if (!eventListenersMap.has(event)) {
              return;
            }

            const eventListeners = eventListenersMap.get(event);

            eventListeners.delete(cb);

            if (eventListeners.size === 0) {
              eventListenersMap.delete(event);
            }
          },
          writable: false,
          enumerable: false,
          configurable: false,
        },
      });

      this.id = id || $id || key;
      this.$id = this.id;
      this.key = this.id;

      if (Object.keys(data).length) {
        this.$set(data);
      }
    }
  }

  class SopsAPI {
    constructor($http) {
      this.$http = $http;
    }

    get(id) {
      return this.$http.get(`sops/${id}/organization/mockId`).then(result => {
        const { id, ...data } = result.data.sop;

        return {
          ...data,
          id,
          $id: id,
          key: id,
        };
      });
    }

    // eslint-disable-next-line no-unused-vars
    create({ _id, id, $id, key, ...rec }) {
      return this.$http.post('sops', rec).then(result => {
        return {
          id: result.data.data,
          $id: result.data.data,
          key: result.data.data,
        };
      });
    }

    // eslint-disable-next-line no-unused-vars
    update(id, { _id, id: __id, $id, key, ...rec }) {
      return this.$http.put(`sops/${id}/organization/mockId`, rec).then(result => {
        const { id, ...data } = result.data.data;

        return {
          ...data,
          id,
          $id: id,
          key: id,
        };
      });
    }

    remove(id) {
      return this.$http.delete(`sops/${id}/organization/mockId`);
    }
  }

  class Service {
    constructor($uibModal, $q, $log, SopSearch, utils, constantsService, $http,
      SAMPLE_ORGANIZATION_ID, confirmModal, cfpLoadingBar, growl, sopLibraryService,
      fileBucketService) {
      'ngInject';

      this.sopsApi = new SopsAPI($http);
      this.$firebaseObject = (ref) => new FirebaseObjectAPIAdapter(ref, this.sopsApi);
      this.$uibModal = $uibModal;
      this.$q = $q;
      this.$log = $log;
      this.SopSearch = SopSearch;
      this.utils = utils;
      this.constantsService = constantsService;
      this.$http = $http;
      this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
      this.confirmModal = confirmModal;
      this.cfpLoadingBar  = cfpLoadingBar;
      this.growl = growl;
      this.sopLibraryService = sopLibraryService;
      this.fileBucketService = fileBucketService;
      this.sopTypes = null;
    }

    /**
     * Get a SOP.
     * @param {string} id The SOP ID
     * @return {Promise} A promise that resolves to a $firebaseObject
     */
    $get(id) {
      return this.$firebaseObject(this.getSopRef(id)).$loaded();
    }

    /**
     * Get a SOP.
     * @param {string} id The SOP ID
     * @return {Promise} A promise that resolves to a SOP
     */
    get(id) {
      return this.sopsApi.get(id);
    }

    /**
     * Get a SOP Reference
     * @param {string} id The SOP ID
     * @return {Promise} A promise that resolves to a SOP reference
     */
    getSopRef(id) {
      return new FirebaseRefAdapter({ id });
    }

    /**
     * Soft delete a SOP.
     * @param {string} id The SOP ID
     * @return {Promise} A promise that resolves when soft deleted
     */
    remove(id) {
      return this.sopsApi.remove(id);
    }

    /**
     * Get the SOPs title.
     * @param {string} id  SOP Id
     * @return {*}  A promise that resolves to the SOP title.
     */
    getTitle(id) {
      return this.sopsApi.get(id).then(sop => sop.title);
    }

    /**
     * Get all SOPs (or templates) for a given organization.
     * @param {string|Array<string>} orgIds The owning org's id(s)
     * @param {string} searchText The query text
     * @param {object} options Additional search options
     * @param {string} options.productId Limit to SOPs tied to this productId
     * @param {string} options.type The SOP type
     * @param {Array} options.fields Only return these fields in the result set
     * @param {boolean} options.asFile Return result as a file download
     * @param {boolean} options.isFacilitySop Only return sops not tied to a single plan control
     * @param {number} from From index
     * @param {number} size Number of results to return
     * @returns {Promise} A promise that resolves to an SOP array
     */
    query(orgIds, searchText, options, from = 0, size = 999) {
      orgIds = _.isArray(orgIds) ? orgIds : [orgIds];
      options = options || {};
      searchText = searchText || '';

      let sopSearch = new this.SopSearch(orgIds);

      if (options.productId) { sopSearch = sopSearch.productId(options.productId); }
      if (options.type) { sopSearch = sopSearch.type(options.type); }
      if (options.fields) { sopSearch = sopSearch.fields(options.fields); }
      if (options.asFile) { sopSearch = sopSearch.asFile(); }
      if (!_.isUndefined(options.isFacilitySop)) { sopSearch = sopSearch.isFacilitySop(options.isFacilitySop); }

      return sopSearch.startFrom(from).size(size).search(searchText);
    }

    /**
     * Get all SOPs for a given product.
     * @param {string} orgId The owning org's id
     * @param {string} productId Optionally limit to a single product
     * @returns {object} A promise that resolves to an array of SOPs
     */
    getProductSops(orgId, productId) {
      return this.$http.get(`/organizations/${orgId}/products/${productId}/sops`)
        .then(result => result.data.sops);
    }

    push(rec) {
      const ref = new FirebaseRefAdapter(rec);

      if (rec) {
        return this.sopsApi.create(rec).then(result => {
          ref.$set(result);

          return ref;
        });
      }

      return ref;
    }

    async $push(rec) {
      const ref = await this.push(rec);

      return this.$firebaseObject(ref).$loaded();
    }

    /**
     * Prompt the user and then create a new SOP. Give them the option to create a new SOP from scratch or to
     * copy one from libraries. Returns the id of the newly created SOP.
     * @param {object} user The logged in user.
     * @param {array} types The user's org's list of types. This is used to customize the SOPs eligible for the user.
     * @param {object} additionalOpts Additional options.
     * @param {boolean} additionalOpts.noCopyExisting Don't allow copying an existing SOP.
     * @param {boolean} additionalOpts.copyMultipleLib Allow copying multiple SOPs from SOP Lib.
     * @return {*} A promise that resolves to the newly created SOP.
     */
    promptAddSop(user, types, additionalOpts) {
      const restrictTemplates = user.doesSubscriptionPermit('sopTemplates') !== false;

      additionalOpts = additionalOpts || {};
      let options = [
        {text: 'New Procedure', value: 'new', default: true}
      ];

      if (user.isPartner()) {
        const onPrimaryOrg = user.onPrimaryOrg();

        if (!additionalOpts.noCopyExisting) {
          options.push({text: `Copy From ${onPrimaryOrg ? 'My Facility' : 'Client'} SOPs`, value: 'mine'});
        }
        options.push({text: 'Copy From My SOP Library', value: 'myLib'});
        options.push({text: 'Copy From FoodReady SOP Library', value: 'cf'});
      } else {
        if (!additionalOpts.noCopyExisting) {
          options.push({text: 'Copy From My Facility SOPs', value: 'mine'});
        }
        if(restrictTemplates) {
          options.push({text: 'Copy From FoodReady SOP Library', value: 'cf'});
        }
      }

      return this.$uibModal.open({
        component: 'cfRadioListModal',
        resolve: {
          title: () => 'Create New SOP',
          message: () => 'Would you like to build a SOP from scratch or use an existing one as a starting point?',
          options: () => options
        }
      }).result.then(choice => {
        this.cfpLoadingBar.start();
        let resultPromise;

        switch (choice) {
        case 'mine':
          resultPromise = this.query([user.orgContext.id], '', {
            isFacilitySop: true,
            type: 'passFail'
          }, 0, 10000);
          break;
        case 'cf':
          let chosenTypes = types && _.pickBy(types, t => !!t);

          resultPromise = this.sopLibraryService.query(user, '', {
            suggestedType: 'facility',
            orgTypes: chosenTypes ? _.keys(chosenTypes) : null
          }, 0, 10000);
          break;
        case 'myLib':
          resultPromise = this.sopLibraryService.query(user, '',
            {organizationId: user.organizationId}, 0, 10000);
          break;
        default:
          let newSopRef = this.push();

          this.cfpLoadingBar.complete();
          return newSopRef.key;
        }

        return resultPromise
          .then(results => {
            this.cfpLoadingBar.complete();

            if (_.isEmpty(results)) {
              this.growl.error('No SOPs found');
              return;
            }
            if (additionalOpts.copyMultipleLib) {
              return this.chooseAndCopySop(user, results, choice === 'cf' && additionalOpts.copyMultipleLib);
            }
            return this.chooseAndCopySop(user, results);
          })
          .finally(() => this.cfpLoadingBar.complete());
      });
    }

    /**
     * Create a brand new SOP. Look for existing SOPs to clone to get started.
     * @param {object} user The logged in user
     * @param {Array} orgs An array of orgs - defaults to my org and CF org
     * @param {boolean} templates If true, return template otherwise actual SOPs.
     * @return {*} A promise that resolves when state changes to SOP edit.
     */
    createSop(user, orgs, templates) {
      orgs = orgs || [user.orgContext.id, this.SAMPLE_ORGANIZATION_ID];
      templates = _.isUndefined(templates) ? true : templates;

      const selectedTypeId = 'passFail';

      this.cfpLoadingBar.start();
      return this.query(orgs, '', {
        templates,
        type: selectedTypeId
      }, 0, 10000).finally(() => {
        this.cfpLoadingBar.complete();
      }).then(results => {
        if (_.isEmpty(results)) {
          return;
        }

        return this.$uibModal.open({
          component: 'cfChooseFromListModal',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            itemName: () => 'template',
            allowSkip: () => true,
            instructionsHtml: () => '<p>Procedure templates give you a head start on instructions, critical limits, corrective actions and more.</p>',
            skipButtonHtml: () => 'No Template',
            header: () => '<i class="far fa-drafting-compass fa-fw"></i> Choose a SOP Template (Optional)',
            itemsArray: () => templates,
            columns: () => [{
              title: 'Name',
              property: 'title'
            }]
          }
        }).result.then((item) => item && _.find(templates, {$id: item.$id}));
      }).then((template) => {
        return this.$push()
          .then(($sop) => ({
            $sop,
            typeId: selectedTypeId,
            template
          }));
      });
    }

    /**
     * Allow the user to choose from the list of SOPs to copy. Once chosen, the SOP will be copied and the new SOP
     * Id will be returned.
     * @param {object} user The logged in user.
     * @param {array} sops The array of SOPs to choose from.
     * @param {boolean} copyMultipleLib If true, allow copying multiple SOPs from the SOP Library.
     * @return {*} A promise that resolves to the newly created SOP.
     */
    chooseAndCopySop(user, sops, copyMultipleLib = false) {
      const msg = '<p>Copy an existing procedure to get a head start on instructions, ' +
        'critical limits, corrective actions and more.</p>';
      let columns = [{
        title: 'Name',
        property: 'title'
      }];

      if (user.access && user.access.cfAdmin) {
        _.map(sops, sop => sop.premium = sop.admin ? 'Yes' : 'No');
        columns.push({
          title: 'Premium',
          property: 'premium'
        });
      }
      return this.$uibModal.open({
        component: 'cfChooseFromListModal',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          itemName: () => 'sop',
          instructionsHtml: () => msg,
          header: () => '<i class="far fa-drafting-compass fa-fw"></i> Copy Procedure',
          itemsArray: () => sops,
          multiple: () => copyMultipleLib,
          columns: () => columns
        }
      }).result.then(result => {
        if (copyMultipleLib) {
          return _.map(result, item => {
            return this.findCopyItem(user, sops, item);
          });
        }

        return this.findCopyItem(user, sops, result);
      }).then(newSop => {
        return !copyMultipleLib ? newSop.$id : null;
      });
    }

    findCopyItem(user, sops, result) {
      let foundItem = result && _.find(sops, {$id: result.$id});

      if (!foundItem) {
        throw new Error('SOP missing from selection.');
      }
      return this.copySop(user, foundItem);
    }

    copySop(user, source) {
      const propsToOmit = ['metadata', 'updatedOn', 'updatedBy', 'updatedByName', '$id',
        '$$hashKey', 'createdOn', 'createdBy', 'organizationId', 'id'];

      let newItem = _.assign(_.omit(source, propsToOmit), {
        organizationId: user.orgContext.id,
        createdOn: new Date().getTime(),
        createdBy: user.uid,
        type: 'passFail',
        typeId: 'passFail'
      });

      newItem.metadata = _.omit(source.metadata, ['productId', 'stepId', 'hazardId', 'controlId']);
      return this.$push(newItem);
    }

    /**
     * Choose an SOP template.
     * @param {Array<string>} orgIds The owning org's id
     * @param {Object=} modalOptions Any modal options to use for the selection modal
     * @param {string=} modalOptions.headerHtml An optional modal header to use for the selection modal
     * @param {string=} modalOptions.skipButtonHtml Skip button HTML to use for the skip button
     * @param {string=} modalOptions.skipButtonClass Skip button class to use for the skip button
     * @returns {Promise} A promise that resolves to the template.
     */
    chooseTemplate(orgIds, modalOptions) {
      return this.chooseSop(orgIds, true, null, modalOptions);
    }

    /**
     * Bring up a modal to choose from a list of SOPs.
     * @param {Array<string>} orgIds The owning org's id
     * @param {boolean=} templates Choose from templates. Defaults to false.
     * @param {Object[]=} sops A list of SOPs to use in lieu of the query results
     * @param {Object=} modalOptions Any modal options to use for the selection modal
     * @param {string=} modalOptions.headerHtml An optional modal header to use for the selection modal
     * @param {string=} modalOptions.skipButtonHtml Skip button HTML to use for the skip button
     * @param {string=} modalOptions.skipButtonClass Skip button class to use for the skip button
     * @param {boolean} dropPlanSpecific Don't include SOPs that are tied to a specific plan/control.
     * @returns {Promise} A promise that resolves to the sop.
     */
    chooseSop(orgIds, templates, sops, modalOptions, dropPlanSpecific = false) {
      templates = templates || false;

      return (!_.isEmpty(sops) ? this.$q.resolve(sops) : this.query(orgIds, '', {templates}, 0, 10000))
        .then((result) => {
          // remove SOPs tied to a hazard -> control as they can't be "used" more than once.
          if (dropPlanSpecific && templates === false) {
            _.remove(result, (s) => !_.isEmpty(_.get(s, 'metadata.controlId')));
          }

          return this.$q.all({
            sopTypes: this.getSopTypes(),
            planStepTextMap: this.buildPlanStepMap(result).then((map) => {
              return _.mapValues(map, val => val.productName ?
                `${val.productName} - ${val.stepName || 'Step Name Unknown'}` : 'N/A');
            })
          })
            .then(({sopTypes, planStepTextMap}) => _.map(result, (sop) => ({
              name: sop.title,
              type: _.get(sopTypes, `${sop.typeId}.title`, 'Unknown'),
              description: sop.description,
              planStepText: _.truncate(planStepTextMap[sop.$id]),
              $id: sop.$id
            })))
            .then((sopMap) => sopMap.length ?
              this.$uibModal.open({
                component: 'cfChooseFromListModal',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  itemName: () => templates ? 'template' : 'procedure',
                  allowSkip: () => templates,
                  skipButtonHtml: () => _.get(modalOptions, 'skipButtonHtml') ||
                    '<i class="far fa-file fa-fw"></i> Use Blank Template',
                  skipButtonClass: () => _.get(modalOptions, 'skipButtonClass'),
                  header: () => _.get(modalOptions, 'headerHtml') || (templates ?
                    '<i class="far fa-drafting-compass fa-fw"></i> Use a Procedure Template?' :
                    '<i class="far fa-clipboard-list fa-fw"></i> Choose Procedure'),
                  itemsArray: () => sopMap,
                  columns: () => [
                    {title: 'Name', property: 'name'}
                  ]
                }
              }).result
                .then((item) => item && _.find(result, {$id: item.$id})) : this.$q.resolve());
        });
    }

    /**
     * Build a map of sopIds to the product and step names that they support. Sops can point to different entities,
     * but this method is sometimes necessary to retrieve the titles/names of those entities.
     * @param {Array} sops An array of SOPs
     * @return {*} A promise that resolves to a map of sop Ids to an object with {stepName, productName}.
     */
    buildPlanStepMap(sops) {
      let map = {};

      this.productNamePromises = {};
      this.stepNamePromises = {};
      return this.$q.all(_.map(sops, sop => {
        const productId = _.get(sop, 'metadata.productId');
        const controlId = _.get(sop, 'metadata.controlId');

        this.productNamePromises[productId] = null;

        this.stepNamePromises[productId + controlId] = null;

        return this.$q.all({
          productName: this.productNamePromises[productId],
          stepName: this.stepNamePromises[productId + controlId]
        }).then((obj) => {
          map[sop.$id] = obj;
        });
      })).then(() => map);
    }

    applyTemplate(sop, template) {
      sop = _.assign(sop, _.pick(template, ['typeId', 'title']));
      sop.metadata = _.omit(template.metadata, ['productId', 'productName' ,'stepId', 'hazardId', 'controlId']);
      sop.clonedFromSopId = template.$id;

      return sop;
    }

    getSopTypes() {
      return this.sopTypes ? this.$q.resolve(this.sopTypes) :
        this.constantsService.get('sopTypes')
          .then((sopTypes) => {
            this.sopTypes = sopTypes;

            return sopTypes;
          });
    }

    generateGroupNumber() {
      return new Date().getTime() / 100;
    }

  }

  ngModule.service('sopService', Service);
};
