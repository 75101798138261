module.exports = function(ngModule) {
  ngModule.component('cfCorrectiveActionPanel', {
    transclude: true,
    bindings: {
      onPreFillClicked: '&?',
      $sopLog: '=',
      $sop: '='
    },
    template: require('./operations.sopLogs.corrective-action.panel.template.html'),
    controller: require('./operations.sopLogs.corrective-action.panel.controller'),
    controllerAs: 'vm'
  });
};