module.exports = function(ngModule) {
  ngModule.constant('notificationTypes', function() {
    return {
      DEFAULT: {
        id: 1
      },
      LETTER: {
        id: 2,
        labelClass: 'label rounded label-dark'
      },
      DATA_FORM: {
        id: 3,
        labelClass: 'label rounded label-u'
      },
      ANALYST: {
        id: 4,
        labelClass: 'label rounded label-yellow'
      },
      SERVICE: {
        id: 5,
        labelClass: 'label rounded label-blue'
      },
      ADMIN: {
        id: 6,
        labelClass: 'label rounded label-orange'
      },
      CHAT: {
        id: 7
      },
      PRODUCT_UPDATED: {
        id: 8
      },
      ACCESS_REQUEST: {
        id: 9
      },
      SUPPLIER_REMINDER: {
        id: 10
      }
    };
  });
};