module.exports = function(ngModule) {
  ngModule.component('cfClientFiles', {
    bindings: {
      facilityFiles: '<',
      productFiles: '<',
      $portals: '<',
      clientProfile: '<',
      user: '<'
    },
    template: require('./marketplace.client-files.template.html'),
    controller: require('./marketplace.client-files.controller'),
    controllerAs: 'vm'
  });
};
