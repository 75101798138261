module.exports = function(ngModule) {
  class Service {
    constructor(fbutil, $firebaseObject, $uibModal, $q, $log, BatchSearch, utils, products, $firebaseArray, foodProductService, $http) {
      'ngInject';

      this.fbutil = fbutil;
      this.$firebaseObject = $firebaseObject;
      this.$uibModal = $uibModal;
      this.$q = $q;
      this.$log = $log;
      this.BatchSearch = BatchSearch;
      this.utils = utils;
      this.products = products;
      this.$firebaseArray = $firebaseArray;
      this.foodProductService = foodProductService;
      this.$http = $http;
    }

    /**
     * Get a list of batches associated with an org as a firebase object.
     * @param {string} organizationId an organization ID
     * @returns {*} The batches firebase array
     */
    $getList(organizationId) {
      return this.$firebaseArray(this.fbutil.ref('batches')
        .orderByChild('organizationId').equalTo(organizationId)).$loaded();
    }

    /**
     * Get a Batch.
     * @param {string} id The Batch ID
     * @return {Promise} A promise that resolves to a $firebaseObject
     */
    $get(id) {
      return this.$firebaseObject(this.fbutil.ref('batches', id)).$loaded();
    }

    /**
     * Get a batch bill of materials.
     * @param {string} id The Batch ID
     * @return {Promise} A promise that resolves to a $firebaseArray
     */
    $getBillOfMaterials(id) {
      return this.$firebaseArray(this.fbutil.ref(`batches/${id}/billOfMaterials`)).$loaded();
    }

    /**
     * Get a batch bill of materials.
     * @param {string} id The Batch ID
     * @return {Promise} A promise that resolves to a $firebaseArray
     */
    getBillOfMaterials(id) {
      return this.fbutil.ref(`batches/${id}/billOfMaterials`).once('value').then(this.fbutil.getValueOrDefault);
    }

    $getMaterial(batchId, materialId) {
      return this.$firebaseObject(this.fbutil.ref(`batches/${batchId}/billOfMaterials/${materialId}`)).$loaded();
    }

    $pushMaterial(batchId, material) {
      let materialRef = this.fbutil.ref(`batches/${batchId}/billOfMaterials`);

      if(material) {
        return this.$firebaseObject(materialRef.push(material)).$loaded();
      } else {
        return this.$firebaseObject(materialRef.push()).$loaded();
      }
    }

    $pushProcessSteps(batchId) {
      return this.$firebaseObject(this.fbutil.ref(`batches/${batchId}/processInfos`).push()).$loaded();
    }

    $setProcedureLogMap(batchId, obj) {
      return this.fbutil.ref(`batches/${batchId}`).child('procedureLogMap').set(obj);
    }

    /**
     * Soft delete a Batch.
     * @param {string} id The Batch ID
     * @return {Promise} A promise that resolves when the batch is removed
     */
    delete(id) {
      return this.fbutil.ref('batches', id, 'deleted').set(true);
    }

    /**
     * Soft delete a Batch Run.
     * @param {string} id The Batch Run ID
     * @return {Promise} A promise that resolves when the batch run is removed
     */
    deleteBatchRun(batchRunId) {
      return this.fbutil.ref('batchRuns', batchRunId, 'deleted').set(true);
    }

    /**
     * Soft delete all Batch Runs associated with a Batch.
     * @param {string} id The Batch ID
     * @return {Promise} A promise that resolves when the batch is removed
     */
    deleteAllBatchRuns(batchId) {
      return this.fbutil.ref(`batches/${batchId}/batchRuns`).once('value')
        .then((snap) => {
          let batchRuns = snap.val() || {};

          if (!batchRuns) { return }

          return this.$q.all(_.map(Object.keys(batchRuns), (batchRunId) => this.deleteBatchRun(batchRunId)));
        });
    }

    /**
     * Get the batch's title.
     * @param {string} id  The batch Id
     * @return {*}  A promise that resolves to the batch number.
     */
    getBatchNumber(id) {
      return this.fbutil.ref('batches', id, 'batchNumber').once('value').then(this.fbutil.getValueOrDefault);
    }

    /**
     * Get all batches for a given organization.
     * @param {string} orgId The owning org's id
     * @param {string} searchText The query text
     * @param {object} options Additional search options
     * @param {object} options.lot Restrict to this lot combo
     * @param {string} options.lot.lot Lot number
     * @param {string} options.lot.ingredientId IngredientId
     * @param {string} options.lot.supplierIngredientId supplierIngredientId
     * @param {number} from From index
     * @param {number} size Number of results to return
     * @returns {Promise} A promise that resolves to a batch array
     */
    query(orgId, searchText, options, from = 0, size = 100) {
      options = options || {};
      let batchSearch = new this.BatchSearch(orgId);

      if (options.lot) {
        batchSearch = batchSearch.lot(options.lot);
      }

      batchSearch = batchSearch.startFrom(from).size(size);
      return batchSearch.search(searchText);
    }

    $push() {
      return this.$firebaseObject(this.fbutil.ref('batches').push()).$loaded();
    }

    /**
     * Choose a plan for the batch.
     * @param {string} foodProducts list of Food Products
     * @returns {Promise} A promise that resolves to the planId.
     */
    chooseFoodProduct(foodProducts) {
      return this.$uibModal.open({
        component: 'cfChooseFromListModal',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          allowSkip: ()=> true,
          skipButtonHtml: () => 'Add Food Product',
          itemName: () => 'Food Product',
          header: () => '<i class="far fa-sitemap g-mr-10"></i> Choose a Food Product for Your New Batch',
          itemsArray: () => foodProducts,
          columns: () => [
            {title: 'Food Product Name', property: 'name'},
            {title: 'Plan Name', property: 'brandName'},
            {title: 'Description', property: 'description'}
          ]
        }
      }).result.then(chosen => chosen);
    }


    removeBillOfMaterials(batchId) {
      return this.fbutil.ref('batches', batchId, 'billOfMaterials').remove();
    }

    removeProcessInfos(batchId) {
      return this.fbutil.ref('batches', batchId, 'processInfos').remove();
    }

    getBatchRunsByFoodProduct(foodProductId, organizationId) {
      return this.$http.get(`/batch-runs/foodProduct/${foodProductId}?organizationId=${organizationId}`)
        .then(res=> res.data.batchRuns);
    }
    getAllBatchRuns(organizationId) {
      return this.$http.get(`/batch-runs/organization/${organizationId}`)
        .then(res=> res.data.batchRuns);
    }
    getSalesOrderByBatchRunId(batchRunId,organizationId) {
      return this.$http.get(`/sales-orders/batch-run/${batchRunId}/organization/${organizationId}`)
        .then(res=> res.data.salesOrders);
    }
    getBatchRun(batchRunId,organizationId) {
      return this.$http.get(`/batch-runs/${batchRunId}/organization/${organizationId}`)
        .then(res => res.data.batchRun);
    }
  }

  ngModule.service('batchService', Service);
};
