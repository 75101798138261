module.exports = function(ngModule) {
  ngModule.component('cfEquipmentList', {
    bindings: {
      user: '<',
      equipment: '<',
      organization: '<'
    },
    template: require('./equipment.list.template.html'),
    controller: require('./equipment.list.controller'),
    controllerAs: 'vm'
  });
};
