module.exports = function(ngModule) {
  ngModule.factory('notifications', function(fbutil, Search, NotificationSearch, notificationScopes, $http, $log,
                                             notificationTypes, CompanyNameSearch, orgTypes, $q) {
    'ngInject';
    let scopes = notificationScopes(),
      types = notificationTypes(),
      notificationCounts = {};

    function post(opts, scope) {
      if (!opts || !opts.from) {
        throw new Error('Invalid argument to notifications.post.');
      }

      let newNotification = {
        createdOn: new Date().getTime(),
        type: _.get(opts, 'type.id') || types.DEFAULT.id,
        createdBy: opts.from,
        creatingOrg: opts.fromOrg || null,
        scope: scope,
        message: opts.message,
        link: opts.link || null,
        metaData: opts.metaData || null
      };

      switch (scope) {
      case scopes.USER:
        newNotification.uid = opts.to;
        break;
      case scopes.ORGANIZATION:
        newNotification.organizationId = opts.to;
        break;
      default:
        throw new Error('Invalid scope: ' + scope);
      }

      return fbutil.ref('notifications').push(newNotification).catch(err => {
        $log.error('An error occurred sending a notification', err);
        return $q.reject(err);
      });
    }

    return {
      types: types,

      get: function(notificationId) {
        return fbutil.ref('notifications', notificationId).once('value').then(fbutil.getValueOrDefault);
      },

      getSearch: function(user, options) {
        return new NotificationSearch(user, options);
      },

      getUnreadNotifications: function(user) {
        let notificationSearch = new NotificationSearch(user);

        notificationSearch.setUser(user.uid);
        notificationSearch.setOrganizations([user.currentOrgContext().id]);

        let body = notificationSearch.getSearchQuery(),
          original = body.query;

        delete body.sort;
        body.query = {
          bool: {
            must: original,
            'must_not': {
              term: {
                read: user.uid
              }
            }
          }
        };

        notificationSearch.setSearchQuery(body);
        notificationCounts[user.uid] = notificationSearch.search();

        return notificationCounts[user.uid];
      },

      postToUser: function(opts) {
        return post(opts, scopes.USER);
      },

      postToOrg: function(opts) {
        return post(opts, scopes.ORGANIZATION);
      },

      postToAllLabs: function(user, opts) {
        let nameSearch = new CompanyNameSearch(user, true);

        return nameSearch.filterByTypes([orgTypes.LABORATORY]).search()
          .then(results => {
            return $q.all(_.map(results, result => {
              opts.to = result.$id;
              return post(opts, scopes.ORGANIZATION);
            }));
          }).catch(err => {
            $log.error('Failed to post notification to all labs: ' + err);
          });
      },

      /**
       * Soft delete a notification.
       * @param {object} notification The notification to be deleted.
       * @return {Promise<T[]>} A promise that's resolved when the notification is properly marked.
       */
      delete: function(notification) {
        return this.deleteById(notification.$id);
      },

      /**
       * Soft delete a notification by Id.
       * @param {string} notificationId The notification to be deleted.
       * @return {Promise<T[]>} A promise that's resolved when the notification is properly marked.
       */
      deleteById: function(notificationId) {
        return fbutil.ref('notifications', notificationId, 'deleted').set(new Date().getTime());
      },

      /**
       * Soft delete the notification if it's a user scoped notification. Otherwise hide it (so other members can
       * continue to see the notification),
       * @param {string} userId The user that will see this notification as deleted.
       * @param {object} notification The notification to be smart deleted.
       * @return {Promise<T[]>} A promise that's resolved when the notification is properly marked.
       */
      smartDelete: function(userId, notification) {
        if (notification.scope === scopes.USER) {
          return this.delete(notification);
        } else {
          return this.hide(userId, notification);
        }
      },

      /**
       * Hide a notification for a particular user.
       * @param {string} userId The user that will see this notification as deleted.
       * @param {object} notification The notification to be deleted.
       * @return {Promise<T[]>} A promise that's resolved when the notification is properly marked.
       */
      hide: function(userId, notification) {
        return fbutil.ref('notifications', notification.$id, 'hide').push(userId);
      },

      /**
       * Mark a notification as read for a particular user.
       * @param {string} userId The user id.
       * @param {string} notificationId The notification to be marked read.
       * @return {Promise<T[]>} A promise that's resolved when the notification is properly marked.
       */
      setRead: function(userId, notificationId) {
        return fbutil.ref('notifications', notificationId, 'read').push(userId);
      },

      /**
       * Mark all notifications are read.
       * @return {Promise} A promise that's resolved when all notifications are properly marked.
       */
      markAllRead: function() {
        return $http.post('/notifications/markAllRead');
      },

      /**
       * Delete all notifications for user. User notifications are soft deleted. Org
       * notifications are only soft deleted for the user.
       * @return {Promise} A promise that's resolved when all notifications are properly marked.
       */
      smartDeleteAll: function() {
        return $http.delete('/notifications?smartDelete=true');
      },

      /**
       * Mark a notification as unread for the current logged in user
       * @param {string} notificationId The notification ID to mark unread
       * @return {Promise} A promise that's resolved when all notifications are properly marked.
       */
      markUnread: function(notificationId) {
        return $http.delete(`/notifications/${notificationId}/read`);
      }
    };
  });
};
