require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductProperties', {
    bindings: {
      user: '<',
      product: '=',
      isReadOnly: '<',
      lastSaved: '='
    },
    template: require('./products.edit.properties.template.html'),
    controller: require('./products.edit.properties.controller'),
    controllerAs: 'vm'
  });
};
