class Controller {
  constructor(billing) {
    'ngInject';

    this.billing = billing;
  }

  $onInit() {
    this.updateSubTotal();
    this.shoppingCartItems.$watch(() => {
      this.updateSubTotal();
    });

    if (this.user.isTrialing()) {
      this.firstMarketpaceService = _.first(this.shoppingCartItems, item => item.service);
      if (this.firstMarketpaceService) {
        this.subscriptionPrice = this.billing.getSubscriptionPriceText(_.get(this.user, 'subscription.plan'));
      }
    }
  }

  updateSubTotal() {
    this.subTotal = _.reduce(this.shoppingCartItems, (result, item) =>
      result + (item.isRush ? item.service.price.rush || item.service.price.regular : item.service.price.regular), 0);
  }

  remove(item) {
    this.shoppingCartItems.$remove(item);
  }
}

module.exports = Controller;
