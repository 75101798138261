module.exports = function(ngModule) {
  ngModule.component('cfTemplatePicker', {
    bindings: {
      user: '<',
      ngModel: '=',
      ngDisabled: '<',
      placeholder: '@?',
      ngRequired: '<'
    },
    template: require('./products.template-picker.template.html'),
    controller: require('./products.template-picker.controller'),
    controllerAs: 'vm'
  });
};
