module.exports = function(ngModule) {
  ngModule.component('cfSupplierReminderNotification', {
    bindings: {
      notification: '<',
      user: '<',
      onDelete: '&'
    },
    template: require('./notifications.supplier-reminder.template.html'),
    controller: require('./notifications.supplier-reminder.controller.js'),
    controllerAs: 'vm'
  });
};
