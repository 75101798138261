module.exports = function(ngModule) {
  ngModule.component('cfOnboardingNeeds', {
    bindings: {
      user: '<',
      company: '<'
    },
    template: require('./onboarding.needs.template.html'),
    controller: require('./onboarding.needs.controller'),
    controllerAs: 'vm'
  });
};
