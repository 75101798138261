class CfStepChooser {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-step-chooser.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The chosen step Id if existing
      newStepName: '=',      // The name of the new step if that mode is set
      product: '=',          // The product object from which to show steps
      label: '@?',           // Input label
      popoverHtml: '@?',     // Popover html text
      isRequired: '=?',      // Field required?
      placeholder: '@'       // Input placeholder
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';

    let vm = this;

    vm.setNewStep = function(newStep) {
      vm.newStep = newStep;

      if (newStep) {
        vm.ngModel = null;
      } else {
        vm.newStepName = null;
      }
    };
  }

  link(scope) {
    let vm = scope.vm;

    if (!vm.product) { throw new Error('Product not defined. Cannot display step chooser.'); }

    vm.processSteps = _.map(vm.product.processSteps, (val, id) => ({
      name: val.name,
      value: id
    }));

    vm.setNewStep(!vm.processSteps || !vm.processSteps.length);
  }
}

module.exports = CfStepChooser;
