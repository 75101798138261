module.exports = function(ngModule) {
  class ConstantsService {
    constructor($http, $q, CacheFactory) {
      'ngInject';

      this.$http = $http;
      this.$q = $q;

      this.constantsCache = {};

      if (!CacheFactory.get('constantsCache')) {
        // eslint-disable-next-line new-cap
        this.constantsCache = CacheFactory('constantsCache', {
          maxAge: 60 * 60 * 1000, // 1 hour
          deleteOnExpire: 'aggressive'
        });
      }
    }

    /**
     * Get a constants object.
     * @param {string=} name An optional constants property (e.g. gmps, onboarding, stripe)
     * @return {object} The constants obj
     */
    get(name = '') {
      let url = `constants${name ? '?name=' + name : ''}`;

      if (name && this.constantsCache.get(name)) {
        return this.$q.resolve(this.constantsCache.get(name));
      }

      return this.$http.get(url)
        .then(result => {
          if (name) { this.constantsCache[name] = result; }

          return result.data;
        });
    }

    getConstants(name = '') {
      let url = `${name}`;

      return this.$http.get(url)
        .then(result => {
          return result.data;
        });
    }
  }

  ngModule.service('constantsService', ConstantsService);
};
