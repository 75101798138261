class Constructor {
  constructor(urlTokens, utils, $state, $stateParams, $uibModal, authorization, CF_ROLES, $q, confirmModal,
              $window, growl, $log, supplierIngredientService, orgPerspectives, $timeout, miniTourService) {
    'ngInject';

    this.urlTokens = urlTokens;
    this.utils = utils;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.authorization = authorization;
    this.CF_ROLES = CF_ROLES;
    this.$q = $q;
    this.confirmModal = confirmModal;
    this.$window = $window;
    this.growl = growl;
    this.$log = $log;
    this.supplierIngredientService = supplierIngredientService;
    this.orgPerspectives = orgPerspectives;
    this.$timeout = $timeout;
    this.miniTourService = miniTourService;

    this.portalTokenTypes = {
      SUPPLIER: 'supplierFileRequest',
      GENERAL: 'generalFilesRequest'
    };
  }

  $onInit() {
    this.noIngredients = _.isEmpty(this.token.ingredients);
    this.noProduct = _.isEmpty(this.token.products);

    this.token.state = this.token.state || {};
    this.loadingIngredient = {};
    this.loadingProduct = {};

    switch (this.token.type) {
    case this.portalTokenTypes.SUPPLIER:
      this.token.state.ingredientVisited = this.token.state.ingredientVisited || {};
      let totalSupplierFiles = _.concat([], this.staged['supplier'] || [], this.token.supplierFiles);

      this.missingSupplierFiles = _.difference(this.token.requiredSupplierFiles, totalSupplierFiles);
      this.missingIngredientFiles = _.reduce(this.token.ingredients, (result, ing, id) => {
        let totalFiles = _.concat([], this.staged[ing.$id] || [], ing.files);

        result[ing.$id] = _.difference(this.token.requiredIngredientFiles, totalFiles);
        return result;
      }, {});
      break;
    case this.portalTokenTypes.GENERAL:
      this.token.state.productVisited = this.token.state.productVisited || {};
      let totalOrgFiles = _.concat([], this.staged['org'] || [], this.token.orgFiles);

      this.missingOrgFiles = _.difference(this.token.requiredOrgFiles, totalOrgFiles);
      this.missingProductFiles = _.reduce(this.token.products, (result, product, id) => {
        let totalFiles = _.concat([], this.staged[id] || [], product.files);

        result[id] = _.difference(this.token.requiredProductFiles, totalFiles);
        return result;
      }, {});
      break;
    default:
      throw new Error('Invalid token type: ' + this.token.type);
    }

    this.$timeout(() => {
      this.miniTourService.enqueueTour(this.user, {
        useLocalStorage: !this.user,
        id: 'portalIntro',
        selector: '.upload-portal .start-btn:visible',
        title: 'Welcome to the FoodReady File Upload Portal',
        contentHtml: `This file upload portal was created exclusively for you by <b>${this.token.requesterName}</b>. At your convenience, visit each of the sections and add organization and food product files. You can return to this portal anytime.<br><br>Are you missing files or is another person responsible for them? No problem, just upload as many files as you can! After you finish, you may be asked to provide their contact info to ensure all files are accounted for. <i>If you cannot upload <i>any</i> files, forward the portal email to the responsible party.</i>`
      });
      this.miniTourService.enqueueTour(this.user, {
        useLocalStorage: !this.user,
        id: 'portalUpload',
        selector: '.finish-btn',
        title: 'Click Here to Send Files',
        contentHtml: `Once you've added all of the files you can, click the <b>Finish</b> button. <b> ${this.token.requesterName} won't get your files until this is done.</b> After you click <b>Finish</b> and the files are sent, the email link for this file upload portal will be disabled.`
      });
    }, 600);
  }

  uploadSupplierFiles() {
    this.token.state.supplierVisited = new Date().getTime();
    this.loadingSupplier = true;
    this.urlTokens.saveState(this.$stateParams.token, this.$stateParams.accessKey, this.token).then(() => {
      this.$state.go('filePortal.supplierFiles');
    }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred uploading supplier files.'))
    .finally(() => { this.loadingSupplier = false; });
  }

  uploadIngredientFiles(ingredient) {
    this.token.state.ingredientVisited[ingredient.$id] = new Date().getTime();
    this.loadingIngredient[ingredient.$id] = true;
    this.urlTokens.saveState(this.$stateParams.token, this.$stateParams.accessKey, this.token).then(() => {
      this.loadingIngredient[ingredient.$id] = false;

      // No user or association decision was already made. Permissions were not granted:
      if (!this.user) {
        return this.supplierIngredientService.setAssociationDecisionMade(ingredient.$id);
      } else if (ingredient.productId || ingredient.associationDecisionMade) {
        return;
      }

      // Choose whether to associate the ingredient to a product and grant permissions.
      return this.$uibModal.open({
        component: 'cfUploadPortalIngredientSetupModal',
        resolve: {
          token: () => this.token,
          ingredientId: () => ingredient.$id,
          user: () => this.user
        }
      }).result.then(result => {
        return this.$q.all([
          this.supplierIngredientService.setAssociationDecisionMade(ingredient.$id),
          this.urlTokens.saveState(this.$stateParams.token, this.$stateParams.accessKey, this.token)
        ]).then(() => result);
      });
    }).then(() => {
      this.$state.go('filePortal.ingredientFiles', {ingredientId: ingredient.$id});
    }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred uploading ingredient files.'))
    .finally(() => { this.loadingIngredient[ingredient.$id] = false; });
  }

  uploadOrgFiles() {
    this.token.state.orgVisited = new Date().getTime();
    this.loadingOrg = true;
    this.urlTokens.saveState(this.$stateParams.token, this.$stateParams.accessKey, this.token).then(() => {
      this.$state.go('filePortal.orgFiles');
    }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred uploading organization files.'))
    .finally(() => { this.loadingOrg = false; });
  }

  uploadProductFiles(product) {
    this.token.state.productVisited[product.id] = new Date().getTime();
    this.loadingProduct[product.id] = true;
    this.urlTokens.saveState(this.$stateParams.token, this.$stateParams.accessKey, this.token).then(() => {
      this.loadingProduct[product.id] = false;


      this.$state.go('filePortal.productFiles', {productId: product.id});
    }).then(() => {
    }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred uploading product files.'))
    .finally(() => { this.loadingProduct[product.id] = false; });
  }

  complete() {
    let totalFiles = _.reduce(this.staged, (result, folder) => result + folder.length, 0);

    if (totalFiles <= 0) {
      this.growl.error('There are no files to upload.');
      return;
    }
    let numMissing;

    switch (this.token.type) {
    case this.portalTokenTypes.SUPPLIER:
      numMissing = _.reduce(this.missingIngredientFiles, (result, ing) => result + ing.length,
        this.missingSupplierFiles.length);
      break;
    case this.portalTokenTypes.GENERAL:
      numMissing = _.reduce(this.missingProductFiles, (result, prod) => result + prod.length,
        this.missingOrgFiles.length);
      break;
    default:
      throw new Error('Invalid token type: ' + this.token.type);
    }

    this.$uibModal.open({
      component: 'cfPortalFinish',
      backdrop: 'static',
      resolve: {
        numMissing,
        user: this.user,
        token: this.token
      }
    }).result.then(() => {
      this.saving = true;
      this.urlTokens.submitAndClose(this.token.$id, this.$stateParams.accessKey, this.token.type).then(() => {
        this.growl.success('All files were submitted');
        this.$log.info('Files were submitted in the file upload portal.', null, {notify: true});
        if (!this.user) {
          this.$window.location.href = 'https://foodready.ai';
        } else if (this.user.getPerspective() === this.orgPerspectives.PENDING_SIGNUP) {
          this.confirmModal({
            title: 'Continue Signup',
            body: 'Thank you for uploading your food safety documents. Press <b>Continue</b> to complete the signup process for FoodReady.',
            okText: 'Continue',
            hideCancelButton: true
          }).then(() => this.$state.go('signup.plan'));
        } else {
          this.$state.go('user.dashboard');
        }
      }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred submitting the files.'))
        .finally(() => { this.saving = false; });
    });
  }
}

module.exports = Constructor;
