class Controller {
  constructor($q, $log, growl, products, ingredients, confirmModal) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.growl = growl;
    this.products = products;
    this.ingredients = ingredients;
    this.confirmModal = confirmModal;
    this.saving = false;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.product = this.resolve.product;
    this.defaultTemplate = this.resolve.defaultTemplate;
    this.planTemplates = this.resolve.planTemplates;
    this.template = {};
    let templateOverwrite = this.defaultTemplate && this.planTemplates[this.defaultTemplate];

    if (templateOverwrite) {
      this.template.name = templateOverwrite.name;
    }
    this.templateOptions = {hazards: true, ingredients: true, equipment: true, planAnalysis: true};
  }

  save() {
    let templateToOverwrite =
      _.findKey(this.planTemplates, t => t.name.toLowerCase() === this.template.name.toLowerCase());

    if (!templateToOverwrite) {
      return this._save();
    }
    this.confirmModal({
      title: `Overwrite <b>${this.planTemplates[templateToOverwrite].name}</b>?`,
      body: `Overwrite the "${this.planTemplates[templateToOverwrite].name}" plan template?`,
      okText: 'Yes, Overwrite',
      cancelText: 'Cancel'
    }).then(() => this._save(templateToOverwrite));
  }

  _save(templateToOverwrite) {
    this.saving = true;
    this.products
      .savePlanTemplate(this.product, _.assign({}, this.template, {id: templateToOverwrite}),
        this.templateOptions)
      .then(template => {
        this.growl.success('Template successfully saved.');
        this.modalInstance.close(template);
      })
      .catch((err) => {
        this.growl.error('An error occurred while saving the process flow as template. ' +
          'Please try again or contact customer support for assistance.');
        this.$log.error('Error occurred while saving process flow as template.', this.$log.toString(err));
      })
      .finally(() => {
        this.saving = false;
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
