'use strict';

module.exports = function ($uibModalInstance) {
  //noinspection JSLint
  'ngInject';

  var vm = this;
  vm.ok = function () {
    $uibModalInstance.close();
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
};
