'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUploadPortalIngredientFiles', {
    bindings: {
      token: '<',
      ingredient: '<'
    },
    template: require('./file-portal.ingredient-files.template.html'),
    controller: require('./file-portal.ingredient-files.controller.js'),
    controllerAs: 'vm'
  });
};