class Constructor {
  constructor($scope, $state, growl, $log, authentication, utils) {
    'ngInject';

    this.$scope = $scope;
    this.authentication = authentication;
    this.growl = growl;
    this.$log = $log;
    this.$state = $state;
    this.utils = utils;
  }

  $onInit() {
    this.model = {};
    this.newEmail = _.get(this.user, 'authUser.email');

    this.$scope.$watch('vm.pwStrength', val => {
      switch (_.round(val / 33)) {
      case 0:
      case 1:
        this.pwStrengthExt = {description: 'Weak', css: 'text-danger', icon: 'fa-ban'};
        return;
      case 2:
        this.pwStrengthExt =  {description: 'Ok', css: 'text-warning', icon: 'fa-exclamation-triangle'};
        return;
      case 3:
        this.pwStrengthExt =  {description: 'Good!', css: 'text-success', icon: 'fa-check'};
        return;
      default:
        this.pwStrengthExt = null;
      }
    });
  }

  updatePassword() {
    this.authentication.changePassword(this.user, this.oldPassword, this.newPassword)
      .then(() => {
        this.growl.success('Password updated successfully!');
        this.$state.go('user.settings.profile');
      })
      .catch((err) => {
        this.$log.error('Unable to change password', err);
        delete this.oldPassword;
        delete this.newPassword;
        delete this.confirm;
        this.growl.error('Unable to change password. Please try again or contact customer support.');
      });
  }

  updateEmail() {
    this.authentication.changeEmail(this.newEmail)
      .then(() => this.growl.success('Email successfully updated!'))
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to change email.'));
  };
}

module.exports = Constructor;
