'use strict';

module.exports = function($state, order, organizations, $log) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    vm.title = vm.resolve.title;
    vm.user = vm.resolve.user;
    vm.plan = vm.resolve.plan;

    vm.order = {
      organizationId: vm.user.orgContext.id,
      items: [{
        title: vm.plan.name,
        comments: 'FoodReady subscription (billed every ' +
          (vm.plan.interval_count > 1 ? vm.plan.interval_count + ' ' : '') +
          vm.plan.interval + (vm.plan.interval_count > 1 ? 's' : '') + ')',
        planId: vm.plan.id,
        amount: {standard: vm.plan.amount / 100},
        couponType: 'subscription'}]
    };
  };

  vm.submitOrder = function(planOrder, payment) {
    vm.paymentError = null;

    return order.submit(planOrder, payment, vm.user, planOrder.organizationId)
      .then(() => organizations.removePreSubscription(planOrder.organizationId))
      .then(() => {
        vm.modalInstance.close('cancel');
        $state.go('user.dashboard', {welcome: true});
      })
      .catch((error) => {
        $log.error('Error processing order', $log.toString(error));
        vm.paymentError = 'An error occurred processing your order. Please try again or contact customer service.';
      });
  };

  vm.onPaymentCancel = function() {
    vm.modalInstance.dismiss('cancel');
  };
};
