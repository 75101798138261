'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUploadFiles', {
    bindings: {
      product: '=',
      files: '=',
      organization: '<',
      user: '<',
      isReadOnly: '<'
    },
    template: require('./products.upload-files.template.html'),
    controller: require('./products.upload-files.controller'),
    controllerAs: 'vm'
  });
};