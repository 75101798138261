module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider
      .state('publicProfile', {
        url: '/users/:userId/profile',
        template: '<cf-profile user="$resolve.profile" read-only="true"></cf-profile>',
        resolve: {
          profile: function(users, $stateParams) {
            return users.getPublicProfile($stateParams.userId);
          }
        },
        data: {menuId: 'dashboard'}
      })
      .state('user', {
        abstract: true,
        url: '/user',
        template: '<div class="content-xs profile"><div ui-view class="fade-in"></div></div>',
        resolve: {
          user: (authentication) => authentication.requireAuth()
        },
        data: {menuId: 'dashboard'}
      })
      .state('user.dashboard', {
        url: '/dashboard?welcome?message?activate',
        controllerAs: 'vm',
        template: '<cf-dashboard user="$resolve.user" $user="$resolve.$user" ' +
          'supplier-search="$resolve.supplierSearch" any-assignments="$resolve.anyAssignments" ' +
          '$company="$resolve.$company"></cf-dashboard>',
        resolve: {
          $user: (users, user) => users.$get(user.uid),
          $company: (user, organizations) => organizations.$get(user.orgContext.id),
          supplierSearch: (user, SupplierSearch, $log) =>
            new SupplierSearch(user, user.orgContext.id, {pageLimit: 200}).$initialized().catch(err => {
              $log.error('Failed supplier search on user dashboard.', $log.toString(err));
              return null;
            })
        }
      })
      .state('user.notifications', {
        url: '/notifications?activate',
        template: '<cf-notifications user="$resolve.user" $company="$resolve.$company"></cf-notifications>'
      })
      .state('user.settings', {
        url: '/settings',
        template: '<cf-users-settings user="$resolve.user"></cf-users-settings>'
      })
      .state('user.settings.profile', {
        url: '/profile',
        template: '<cf-profile $user="$resolve.$user" org-name="$resolve.orgName" user="$resolve.user"></cf-profile>',
        resolve: {
          orgName: (user, organizations) => organizations.getName(user.currentOrgContext().id),
          $user: (users, user) => users.$get(user.uid)
        }
      })
      .state('user.settings.billing', {
        url: '/billing',
        template: '<cf-billing user="$resolve.user" $company="$resolve.facility"></cf-billing>',
        resolve: {
          facility: (user, organizations) => organizations.$get(user.orgContext.id)
        }
      })
      .state('user.settings.users', {
        abstract: true,
        url: '/users',
        template: '<ui-view></ui-view>'
      })
      .state('user.settings.users.list', {
        url: '',
        template: '<cf-list-users users="$resolve.users" user="$resolve.user" $invitations="$resolve.$invitations" ' +
          'product-constraints="$resolve.productConstraints"></cf-list-users>',
        resolve: {
          users: function(UsersSearch, user, CF_ROLES) {
            let userSearch = new UsersSearch(user, {});

            if (!user.isCfAdmin()) {
              userSearch.setInverseRoleFilter(CF_ROLES.ANALYST);
            }

            return userSearch;
          },
          $invitations: ($q, user, organizations) =>
            user.isCfAdmin() || user.hasPermission('admin') ?
              organizations.$getInvitations(user.orgContext.id) : $q.resolve([]),
          search: ($q, user, users) =>
            user.isCfAdmin() || user.hasPermission('admin') ? users.search() : $q.resolve([]),
          productConstraints: (constantsService) => constantsService.get('stripe')
            .then(stripeConstants => stripeConstants.constraints)
        }
      })
      .state('user.settings.users.edit', {
        url: '/:userId/edit?full',
        template: '<cf-edit-user edit-user="$resolve.editUser" user="$resolve.user" user-search="$resolve.userSearch"' +
          'products="$resolve.userProductsSearch" reviews-access="$resolve.reviewsAccess" ' +
          'answers-access="$resolve.answersAccess" org-role="{{$resolve.orgRole}}"></cf-edit-user>',
        resolve: {
          editUser: function($stateParams, users, user) {
            return $stateParams.full === 'true' || user.isCfAdmin() ?
              users.$get($stateParams.userId) :
              users.getPublicProfile($stateParams.userId);
          },
          reviewsAccess: function($stateParams, fbutil, $firebaseObject, user) {
            return user.isCfAdmin() &&
              $firebaseObject(fbutil.ref('userAccess', $stateParams.userId, 'reviews')).$loaded();
          },
          orgRole: function($stateParams, fbutil, user) {
            return !user.isCfAdmin() && fbutil.ref('userAccess', $stateParams.userId, 'reviews/organizations',
              user.currentOrgContext().id, 'role').once('value').then(fbutil.getValueOrDefault);
          },
          userSearch: function(fbutil, UsersSearch, user, CF_ROLES) {
            let userSearch = new UsersSearch(user, {});

            if (!user.isCfAdmin()) {
              userSearch.setInverseRoleFilter(CF_ROLES.ANALYST);
            }

            return userSearch;
          }
        }
      })
      .state('user.settings.planTemplates', {
        url: '/planTemplates',
        template: '<cf-plan-templates user="$resolve.user" templates="$resolve.templates">' +
          '</cf-plan-templates>',
        resolve: {
          templates: (user, products) => products.getPlanTemplates(user.orgContext.id)
            .then((templates) => _.map(templates, (template, key) => _.assign({}, template, {$id: key})))
        }
      })
      .state('user.settings.group', {
        url: '/userGroups',
        template: '<fr-user-group user="$resolve.user" members="$resolve.members" groups="$resolve.groups"></fr-user-group>',
        resolve: {
          groups: (userGroups,user) => {
            return userGroups.getAllUserGroups(user.orgContext.id);
          },
          members: (organizations, $q, users, user) => {
            return organizations.$getMembers(user.orgContext.id)
              .then(function(members) {
                return $q.all(_.map(members, function(member) {
                  return users.getName(member.$id).then(function(name) {
                    return {
                      value: member.$id,
                      name: name
                    };
                  });
                }));
              });
          }
        }
      })
      .state('user.settings.mobileApp', {
        url: '/mobileApp',
        template: '<fr-mobile-app user="$resolve.user"></fr-mobile-app>'
      })
      .state('user.settings.account', {
        url: '/account',
        template: '<cf-account user="$resolve.user"></cf-account>'
      })
      .state('user.settings.changePassword', {
        url: '/password',
        template: '<cf-change-password user="$resolve.user"></cf-change-password>'
      })
      .state('user.settings.changeEmail', {
        url: '/email',
        template: '<div cf-change-email></div>'
      })
      .state('user.channelSubscriptions', {
        url: '/channelSubscriptions',
        template: '<cf-channel-subscriptions channels="$resolve.channels" ' +
          'subscriptions="$resolve.subscriptions" user="$resolve.user"></cf-channel-subscriptions>',
        resolve: {
          channels: (fbutil, $firebaseArray) =>
            $firebaseArray(fbutil.ref('channels')).$loaded(),
          subscriptions: (user, fbutil, $firebaseArray) =>
            $firebaseArray(fbutil.ref('users', user.uid, 'subscriptions')).$loaded()
        }
      })
      .state('user.social', {
        url: '/settings/social',
        template: '<div cf-social></div>'
      })
      .state('user.billing', {
        url: '/billing',
        template: '<cf-billing user="$resolve.user" $company="$resolve.company"></cf-billing>',
        resolve: {
          company: (user, organizations) => organizations.$get(user.orgContext.id)
        }
      })
      .state('user.invoices', {
        url: '/invoices',
        template: '<cf-invoices user="$resolve.user" invoices="$resolve.invoices"></cf-invoices>',
        resolve: {
          invoices: (user, services, $log) =>
            services.getOrgsServices(user.orgContext.id).catch(err => $log.error(err))
        }
      });
  });
};
