module.exports = function(ngModule) {
  ngModule
    .config(($stateProvider) => {
      'ngInject';

      $stateProvider.state('marketplace', {
        url: '/marketplace',
        template: '<cf-marketplace user="$resolve.user" organization="$resolve.organization"></cf-marketplace>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          organization: (user, organizations) => organizations.$get(user.currentOrgContext().id),
          clients: (user, organizations) => organizations.getClientsOld(user.currentOrgContext().id)
        },
        data: {menuId: 'dashboard'}
      }).state('marketplace.servicesAvailable', {
        url: '/services?serviceId',
        template: '<cf-marketplace-services-available user="$resolve.user" organization="$resolve.organization" ' +
          'default-services="$resolve.defaultServices"></cf-marketplace-services-available>',
        resolve: {
          defaultServices: (marketplaceServices) => marketplaceServices.getDefaultServices()
        }
      }).state('marketplace.servicesOffered', {
        url: '/offerings',
        template: '<cf-marketplace-services-offered services="$resolve.services" ' +
          'organization="$resolve.organization" user="$resolve.user"></cf-marketplace-services-offered>',
        resolve: {
          services: (organization, marketplaceService) => marketplaceService.getServicesOffered(organization.$id)
            .then((result) => _.map(result, (svc, key) => _.assign(svc, {key: key})))
        }
      }).state('marketplace.clients', {
        url: '/clients',
        template: '<div ui-view></div>'
      }).state('marketplace.clients.list', {
        url: '/',
        template: '<cf-marketplace-clients user="$resolve.user" organization="$resolve.organization" ' +
          'default-services="$resolve.defaultServices" org-services="$resolve.orgServices" ' +
          'clients="$resolve.clients"></cf-marketplace-clients>',
        resolve: {
          defaultServices: (marketplaceServices) => marketplaceServices.getDefaultServices(),
          orgServices: (organization, marketplaceServices) => marketplaceServices.getOrgServices(organization.$id)
        }
      }).state('marketplace.providers', {
        url: '/providers',
        template: '<cf-marketplace-providers user="$resolve.user" organization="$resolve.organization" ' +
          'default-services="$resolve.defaultServices" provider-profiles="$resolve.providerProfiles" ' +
          'clients="$resolve.clients"></cf-marketplace-providers>',
        resolve: {
          defaultServices: (marketplaceServices) => marketplaceServices.getDefaultServices(),
          providerProfiles: (organization, marketplaceService) => marketplaceService.providerProfiles(organization)
        }
      }).state('marketplace.requestForQuote', {
        url: '/rfq',
        abstract: true,
        template: '<ui-view/>'
      }).state('marketplace.requestForQuote.list', {
        url: '',
        template: '<cf-request-for-quotes user="$resolve.user" organization="$resolve.organization">' +
        '</cf-request-for-quotes>',
      }).state('marketplace.requestForQuote.files', {
        url: '/files?productId?orgId?returnState?marketplacePocUid',
        template: '<cf-client-files user="$resolve.user" client-profile="$resolve.clientProfile" ' +
          'facility-files="$resolve.facilityFiles" product-files="$resolve.productFiles" ' +
          '$portals="$resolve.$portals"></cf-client-files>',
        resolve: {
          clientProfile: ($stateParams, organizations) => organizations.getMiniProfile($stateParams.orgId),
          facilityFiles: ($stateParams, organizations) => organizations.externalFiles($stateParams.orgId),
          productFiles: ($stateParams, products) => $stateParams.productId &&
            products.externalFiles($stateParams.productId),
          $portals: (filePortalService, user) => filePortalService.$getPortals(user.orgContext.id)
        }
      }).state('marketplace.orders', {
        url: '/orders',
        abstract: true,
        template: '<ui-view/>'
      }).state('marketplace.orders.list', {
        url: '',
        template: '<cf-marketplace-orders user="$resolve.user" organization="$resolve.organization">' +
        '</cf-marketplace-orders>'
      }).state('marketplace.orders.item', {
        url: '/:orderId/items/:itemId',
        template: '<cf-marketplace-order-item user="$resolve.user" ' +
          '$order-item="$resolve.$orderItem" $organization="$resolve.$organization" ' +
          '$private-media="$resolve.$privateMedia" $shared-media="$resolve.$sharedMedia" service="$resolve.service">' +
          '</cf-marketplace-order-item>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          $organization: (user, organizations) => organizations.$get(user.orgContext.id),
          $orderItem: ($stateParams, orders) => orders.$getItem($stateParams.orderId, $stateParams.itemId),
          $privateMedia: ($stateParams, orders) =>
            orders.$getPrivateMedia($stateParams.orderId, $stateParams.itemId),
          $sharedMedia: ($stateParams, orders) =>
            orders.$getSharedMedia($stateParams.orderId, $stateParams.itemId),
          service: (marketplaceService, $orderItem) =>
            marketplaceService.getService($orderItem.service.key, $orderItem.providerOrgId)
        }
      }).state('marketplace.orders.files', {
        url: '/files?productId?orgId?returnState?marketplacePocUid',
        template: '<cf-client-files user="$resolve.user" client-profile="$resolve.clientProfile" ' +
          'facility-files="$resolve.facilityFiles" product-files="$resolve.productFiles" ' +
          '$portals="$resolve.$portals"></cf-client-files>',
        resolve: {
          clientProfile: ($stateParams, organizations) => organizations.getMiniProfile($stateParams.orgId),
          facilityFiles: ($stateParams, organizations) => organizations.externalFiles($stateParams.orgId),
          productFiles: ($stateParams, products) => $stateParams.productId &&
            products.externalFiles($stateParams.productId),
          $portals: (filePortalService, user) => filePortalService.$getPortals(user.orgContext.id)
        }
      }).state('marketplace.clients.view', {
        url: '/:clientOrgId',
        template: '<div ui-view></div>',
        resolve: {
          clientProfile: (organizations, $stateParams) => organizations.getMiniProfile($stateParams.clientOrgId)
        }
      }).state('shoppingCart', {
        url: '/cart',
        template: '<div ui-view></div>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          shoppingCartItems: (user, users) => users.$getShoppingCartItems(user.uid)
        },
        data: {menuId: 'dashboard'}
      }).state('shoppingCart.review', {
        url: '/review',
        template: '<cf-marketplace-shopping-cart user="$resolve.user" ' +
          'shopping-cart-items="$resolve.shoppingCartItems"></cf-marketplace-shopping-cart>',
        data: {menuId: 'dashboard'}
      }).state('shoppingCart.checkout', {
        url: '/checkout',
        template: '<cf-marketplace-checkout user="$resolve.user" ' +
          'shopping-cart-items="$resolve.shoppingCartItems" ' +
          'organization="$resolve.organization"></cf-marketplace-checkout>',
        resolve: {
          organization: (user, organizations) => organizations.$get(user.orgContext.id)
        },
        data: {menuId: 'dashboard'}
      });
    })
/*    .run((menus, orgPerspectives) => {
      'ngInject';

      menus.addMenuItem('dashboard', {
        title: 'Expert Services',
        state: 'marketplace',
        position: 7,
        isPublic: false,
        perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP, '!reviews.' + orgPerspectives.PROVIDER_ANALYST]
      });
    })*/;
};
