module.exports = function(ngModule) {
  ngModule.constant('unitContainers', function() {
    return [
      {name: 'Bottle', value: 'bottle'},
      {name: 'Jar', value: 'jar'},
      {name: 'Can', value: 'can'},
      {name: 'Case', value: 'case'},
      {name: 'Tub', value: 'tub'},
      {name: 'Bucket', value: 'bucket'},
      {name: 'Tank', value: 'tank'},
      {name: 'Box', value: 'box'},
      {name: 'Package', value: 'package'},
      {name: 'Bag', value: 'bag'},
      {name: 'Pan', value: 'pan'},
      {name: 'Unit', value: 'unit'},
      {name: 'Piece', value: 'piece'},
      {name: 'Bar', value: 'bar'}
    ];
  });
};
