module.exports = function(ngModule) {
  ngModule.directive('cfMobileTab', function($window, $timeout) {
    return {
      restrict: 'A',
      link: function(scope, el, attr) {
        // Save directive users from weird tab issues and ensuring the tab is setup correctly.
        if (!attr.tabsMobileType || !attr.btnClasses || !el.is('[role="tablist"]')) {
          throw new Error('cfMobileTab directive error. You are missing some Unify requirments.');
        }

        const MAX = 100;  // Safety net
        const classPrefix = 'cf-mobile-tab';
        let selector;

        // Determine a unique classname. Unify's tab component requires a string selector.
        for (let i = el.parents().length; i < MAX; i++) {
          let className = classPrefix + i;

          if (!$('.' + className).length) {
            selector = '.' + className;
            el.addClass(className);
            break;
          }
        }

        if (!selector) {
          throw new Error('cfMobileTab directive error. Unable to find unique class.');
        }

        // initialization of tabs
        $timeout(() => $.HSCore.components.HSTabs.init(selector), 200);
        const reInit = _.debounce(() => $.HSCore.components.HSTabs.init(selector), 200);

        angular.element($window).on('resize', () => reInit());
      }
    };
  });
};
