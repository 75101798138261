'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfHelpBanner', {
    bindings: {
      inline: '<'
    },
    template: require('./help-banner.template.html'),
    controller: require('./help-banner.controller.js'),
    controllerAs: 'vm'
  });
};
