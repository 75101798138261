module.exports = function(ngModule) {
  ngModule.component('cfRequestSupplierFiles', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./suppliers.request-files.template.html'),
    controller: require('./suppliers.request-files.controller'),
    controllerAs: 'vm'
  });
};