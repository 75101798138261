// These helper properties weren't loaded as angular services because they are needed in the app.config phase, where
// services are unavailable.
module.exports = {
  requireAuth: function(authentication) {
    'ngInject';

    return authentication.requireAuth();
  },
  waitForAuth: function(authentication) {
    'ngInject';

    return authentication.waitForAuth();
  }
};
