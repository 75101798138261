class Controller {
  constructor(constantsService) {
    'ngInject';

    this.constantsService = constantsService;
  }

  $onInit() {
    this.heading = '<strong><i>External Documents</i></strong> - ' + this.supplier.name;
    this.remoteDirectory = 'suppliers/' + this.supplier.$id + '/files';
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.externalCompanyFileTypes = _.pickBy(fileTypes, fc => fc.companyLevel);
    });
  }

}

module.exports = Controller;
