module.exports = function(ngModule) {
  ngModule.component('cfBatchList', {
    bindings: {
      user: '<',
      batches: '<',
      company: '<',
      foodProducts: '<'
    },
    template: require('./batch.list.template.html'),
    controller: require('./batch.list.controller'),
    controllerAs: 'vm'
  });
};
