module.exports = function(ngModule) {
  ngModule.component('cfProductPlanAnalysis', {
    bindings: {
      product: '<'
    },
    template: require('./products.plan-analysis.main.template.html'),
    controller: require('./products.plan-analysis.main.controller.js'),
    controllerAs: 'vm'
  });
};
