module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('projectwriting', {
        url: '/projectwriting',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          company: (user, organizations) => organizations.$get(user.orgContext.id),
          projects: (user, projectWritingServices) => projectWritingServices.getProjectLists(user)
        },
        data: {menuId: 'dashboard'}
      })
      .state('projectwriting.list', {
        url: '/list',
        template: '<cf-project-writing-list user="$resolve.user" company="$resolve.company" ' +
          'projects="$resolve.projects"></cf-project-writing-list>'
      })
      .state('projectwriting.edit', {
        // url: '/edit/:sopId?templateId?typeId?goBack',
        url: '/edit/:projectId',
        template: '<cf-project-writing-edit user="$resolve.user" company="$resolve.company" project="$resolve.$project">' +
          '</cf-project-writing-edit>',
        resolve: {
          $project: (projectWritingServices, user, $stateParams) => projectWritingServices.$get($stateParams.projectId)
        }
      })
      .state('projectwriting.messages', {
        url: '/messages/:projectId',
        template: '<cf-project-writing-message user="$resolve.user" company="$resolve.company" project="$resolve.project"' +
          ' files="$resolve.files" topic="$resolve.topic"></cf-project-writing-message>',
        resolve: {
          project: (projectWritingServices, $stateParams) => projectWritingServices.$get($stateParams.projectId),
          topic: (messageServices, project) => messageServices.$getTopic(project.messageTopicId),
          files: (project, projectWritingServices) => projectWritingServices.externalFiles(project.$id),
        }
      });
  });
};
