class Controller {
  constructor($q, $uibModal, equipmentService, products, confirmDeleteModal, utils, $window, V2_URL) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.equipmentSvc = equipmentService;
    this.productsSvc = products;
    this.confirmDeleteModal = confirmDeleteModal;
    this.utils = utils;
    this.$window = $window;
    this.V2_URL = V2_URL;
  }

  $onInit() {
  }

  addEquipmentToStep(equipment) {
    if (_.keys(this.product.processSteps).length) {
      return this.$uibModal
        .open({
          component: 'cfChooseFromListModal',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            itemName: () => 'Process Step',
            header: () => '<i class="far fa-leaf mr-2"></i> Select Process Step',
            itemsArray: () => _.map(this.product.processSteps, (step, id) => _.assign(step, {$id: id})),
            columns: () => [{title: 'Name', property: 'name'}, {title: 'Description', property: 'description'}]
          }
        })
        .result.then(step =>
          this.productsSvc.addEquipmentProcessStep(this.product.$id,  equipment.id || equipment.$id, step.$id).then(() => {
            equipment.processSteps = equipment.processSteps || {};
            equipment.processSteps[step.$id] = true;
          })
        );
    }

    return this.$uibModal
      .open({
        component: 'cfProcessStep',
        backdrop: 'static',
        size: 'md',
        resolve: {
          $processStep: () => this.productsSvc.$pushStep(this.product.$id),
          messageHtml: () =>
            '<strong>Note: </strong>It appears you haven\'t added any process steps to your process flow ' +
            'diagram. Enter new step information below and add your equipment to a new process step.'
        }
      })
      .result
      .then($step =>
        this.productsSvc.addEquipmentProcessStep(this.product.$id, equipment.id || equipment.$id, $step.$id)
          .then(() => {
            equipment.processSteps = equipment.processSteps || {};
            equipment.processSteps[$step.$id] = true;
          })
      );
  }

  removeEquipment(equipment) {
    this.confirmDeleteModal('Equipment', {
      body: `Are you sure you want to remove equipment <strong>${equipment.name}</strong>?`
    }).then(() => {
      this.productsSvc
        .removeEquipment(this.product.$id,  equipment.id || equipment.$id)
        .then(() => _.remove(this.equipment, {$id: equipment.$id}))
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove equipment.'));
    });
  }

  removeEquipmentFromStep(equipment, stepId) {
    this.confirmDeleteModal('Equipment', {
      headerHtml: '<span class="text-danger">Remove from <strong>Process Step</strong>?</span>',
      confirmText: 'Remove',
      body:
        `Are you sure you want to remove equipment <strong>${equipment.name}</strong> from process step ` +
        `<strong>${this.product.processSteps[stepId].name}</strong>?`
    }).then(() => {
      this.productsSvc
        .removeEquipmentProcessStep(this.product.$id,  equipment.id || equipment.$id, stepId)
        .then(() => delete equipment.processSteps[stepId])
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove equipment from process step.'));
    });
  }

  editEquipment(equipment) {
    this.$window.open(`${this.V2_URL}/environmental-monitoring`, '_blank');

    // this.$uibModal.open({
    //   component: 'cfEditEquipment',
    //   backdrop: 'static',
    //   resolve: {
    //     user: () => this.user,
    //     organizationId: () => equipment.organizationId,
    //     equipment: () => this.equipmentSvc.get(equipment._id || equipment.$id)
    //   }
    // }).result
    //   .then(updatedEquipment => {
    //     if (equipment) {
    //       const existing = _.find(this.equipment, {id: updatedEquipment._id || updatedEquipment.$id});
    //
    //       _.assign(existing, updatedEquipment);
    //
    //       return;
    //     }
    //
    //     this.equipment = this.equipment || [];
    //     this.equipment.push(updatedEquipment);
    //     this.noEquipment = false;
    //   })
    //   .catch(reason => {
    //     if (this.utils.isModalDismissalByUser(reason)) { return; }
    //
    //     this.growl.error('Error editing equipment.', {});
    //     this.$log.error(reason);
    //   });
  }
}

module.exports = Controller;
