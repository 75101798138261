'use strict';

module.exports = function($log, $timeout, preventDirtyNav, growl, $state) {
  'ngInject';

  let vm = this;

  vm.$onInit = function() {
    $timeout(() => {
      preventDirtyNav(vm.supplierContactForm, 'You have unsaved contact changes.', vm.save);
    });
  };

  vm.save = function() {
    vm.supplier.$save()
      .then(() => {
        if (vm.supplierContactForm) { vm.supplierContactForm.$setPristine(); }
        growl.success('Supplier contact saved successfully!');
      })
      .catch((err) => {
        $log.error(err);
        growl.error('Unable to save supplier contact. Please try again or contact FoodReady customer support.');
      });
  };

  vm.cancel = function() {
    if (vm.supplierContactForm) {
      vm.supplierContactForm.$setPristine();
    }

    vm.close();
  };

  vm.close = function() {
    $state.go('ingredients.suppliers.list');
  };
};
