module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsClassType', {
    bindings: {
      $event: '=',
      user: '<'
    },
    template: require('./recall.events.class-type.template.html'),
    controller: require('./recall.events.class-type.controller'),
    controllerAs: 'vm'
  });
};
