class Constructor {
  constructor($log, orders, growl) {
    'ngInject';

    this.$log = $log;
    this.ordersService = orders;
    this.growl = growl;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.order = this.resolve.order;
    this.item = this.resolve.item;
    this.facilityName = this.resolve.facilityName;
  }

  save(order, item, reason) {
    this.saving = true;

    this.ordersService.updateItem(this.user, order.$id, item.id, _.pick(item, ['service', 'isRush']), reason)
      .then(() => this.modalInstance.close(item))
      .catch((err) => {
        this.saving = false;
        this.growl.error('Unable to update order item. Please try again or contact ' +
          'customer support for assistance');
        this.$log.error('Unable to update order item.', this.$log.toString(err));
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Constructor;
