class Constructor {
  constructor($log, orgPerspectives, growl, $q, users) {
    'ngInject';

    this.$log = $log;
    this.orgPerspectives = orgPerspectives;
    this.growl = growl;
    this.$q = $q;
    this.users = users;
  }

  $onInit() {
    this.userNames = {};
    this.getUserNames(this.organization.members);
  }

  getUserNames(members) {
    return this.$q.all(
      _.map(members, (val, uid) => {
        if (this.userNames[uid]) {
          return;
        }
        this.userNames[uid] = '';
        return this.users.getName(uid).then((name) => {
          this.userNames[uid] = name;
        });
      })
    );
  }
}

module.exports = Constructor;
