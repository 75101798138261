module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceServicesOffered', {
    bindings: {
      organization: '<',
      services: '<',
      user: '<'
    },
    template: require('./marketplace.services-offered.template.html'),
    controller: require('./marketplace.services-offered.controller'),
    controllerAs: 'vm'
  });
};
