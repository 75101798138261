class Controller {
  constructor(requestForQuotes) {
    'ngInject';

    this.requestForQuotesService = requestForQuotes;
    this.reason = '';
  }

  $onInit() {
    this.rfq = this.resolve.rfq;
    this.user = this.resolve.user;

    this.saving = false;
  }

  ok(reason) {
    this.saving = true;
    this.requestForQuotesService.rejectAssignment(this.rfq.$id, reason, this.user)
      .then((rfq) => this.modalInstance.close(rfq))
      .catch((err) => {
        this.growl.error('Unable to reject RFQ. Please try again or contact ' +
          'customer support for assistance');
        this.$log.error('Unable to reject RFQ.', this.$log.toString(err));
        this.saving = false;
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
