module.exports = function($state, $window) {
  'ngInject';
  let vm = this;

  vm.$onInit = function() {
    vm.$state = $state;
    vm.sop = vm.resolve.sop;
    vm.$window = $window;
  };
  vm.editSop = function(sop) {
    vm.modalInstance.dismiss();
    return vm.$window.open(`app/operations/sops/${sop.$id}/edit`, '_self');
  };
};
