'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfEditVerificationModal', {
    bindings: {
      verification: '=',
      product: '=',
      productOrganization: '=',
      substituteName: '@?',
      title: '@?',
      $uibModalInstance: '=',
      user: '<'
    },
    template: require('./products.verification-main.template.html'),
    controller: require('./products.verification-main.controller'),
    controllerAs: 'vm'
  });
};