module.exports = function(ngModule) {
  ngModule.component('cfSigninForm', {
    bindings: {
      tokenData: '<?',
      onSignedIn: '&',
      onUserNotFound: '&?'
    },
    template: require('./auth.signin-form.template.html'),
    controller: require('./auth.signin-form.controller.js'),
    controllerAs: 'vm'
  });
};
