class Controller {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.$consigneeImpact = this.resolve.$consigneeImpact;
    this.customer = this.resolve.customer;

    this.isNew = this.$consigneeImpact.$value === null;
  }

  save() {
    this.$consigneeImpact.$save().then(() => {
      this.modalInstance.close(this.$consigneeImpact);
    }).catch((err) => this.modalInstance.dismiss(err));
  }
}

module.exports = Controller;
