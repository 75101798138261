'use strict';

module.exports = function(ngModule) {
  ngModule.factory('Search', function(ES_INDICES, $http, ENV, $q) {
    'ngInject';

    class Search {

      constructor(user, options, indexKey) {
        this.uid = user && user.uid;
        this.options = options;
        this.queryData = {
          index: ES_INDICES[indexKey],
          type: options.type
        };

        if (options.body) {
          this.setSearchQuery(options.body);
          delete options.body;
        }
      }

      setSearchQuery(query) {
        this.pageNumber = 1;
        this.searchResults = [];
        this.queryData.body = query;
      }

      getSearchQuery() {
        return angular.copy(this.queryData.body || {});
      }

      reset() {
        this.pageNumber = 1;
        this.searchResults = [];
        this.total = 0;
      }

      getMore() {
        var that = this;

        // We can't allow more results until the initial result is in (and we therefore know the limit)
        if (!this.searchResults || !this.searchResults.length) {
          return $q.when();
        }

        if (!this.options.pageLimit) {
          throw new Error('Page limit not set');
        }

        if (this.maxReached()) {
          return $q.when(this.searchResults);
        }

        this.pageNumber++;
        this.searchPromise = this._doSearch().then(searchResults => {
          that.searchResults = that.searchResults ?
            this.searchResults.concat(this._processResults(searchResults)) :
            this._processResults(searchResults);
          return that.searchResults;
        });
        return this.searchPromise;
      }

      maxReached() {
        if (!this.options.max || !this.options.pageLimit) {
          return false;
        }
        var max = Math.min(this.options.max, this.total || 0);

        return this.options.pageLimit * this.pageNumber >= max;
      }

      search() {
        this.pageNumber = 1;
        this.searchResults = [];
        this.searchPromise = this._doSearch().then(searchResults => {
          this.searchResults = this._processResults(searchResults);
          return this.searchResults;
        });
        return this.searchPromise;
      }

      $loaded() {
        return this.searchPromise || $q.when(null);
      }

      count() {
        if (this.direct) {
          throw new Error('Count not available in direct mode');
        }
        this.searchPromise = $http.post('search/count', this.queryData).then(function(results) {
          return results.data.count;
        });
        return this.searchPromise;
      }

      makeTerm(term, matchWholeWords) {
        if (!matchWholeWords) {
          if (!term.match(/^\*/)) {
            term = '*' + term;
          }
          if (!term.match(/\*$/)) {
            term += '*';
          }
        }
        return term;
      }

      _doSearch() {
        var queryCopy = angular.copy(this.queryData),
          payload = {
            method: 'search',
            uid: this.uid,
            params: queryCopy
          };

        if (this.options.pageLimit) {
          queryCopy.body.size = this.options.pageLimit;
          queryCopy.body.from = this.options.pageLimit * (this.pageNumber - 1);
        } else {
          queryCopy.body.size = this.options.max;
        }

        _.assign(payload, _.pick(this.options, ['context', 'noPrivate']));
        if (ENV === 'development') {
          console.log('Searching with ', JSON.stringify(payload));
        }

        this.searching = true;
        return $http.post(this.options.uri, payload).then(results => results.data).finally(() => {
          this.searching = false;
        });
      }

      _processResults(searchResults) {
        this.total = searchResults.hits.total?.value;
        if (!searchResults.hits.hits || !searchResults.hits.hits.length) {
          return null;
        }

        return _.map(searchResults.hits.hits, function(hit) {
          return _.assign({$id: hit._id}, hit._source);
        });
      }


    }

    return Search;
  });
};
