class Controller {
  constructor($q, productSearch, products, growl, utils, UsersSearch) {
    'ngInject';

    this.$q = $q;
    this.productSearch = productSearch;
    this.products = products;
    this.growl = growl;
    this.utils = utils;
    this.UsersSearch = UsersSearch;
    this.MAX_RECS = 300;
    this.orgUsers = [];
  }

  $onInit() {
    this.user = this.resolve.user;
    this.service = this.resolve.service;

    this.selectedProducts = [];
    this.specialInstructions = '';
    this.isRush = false;
    this.newProduct = {};
    this.productSearch = this.productSearch.getSearch(this.user, this.MAX_RECS, [this.user.currentOrgContext().id]);
    this.newOldProd = 'old';

    if (this.user.isCfAdmin()) {
      this.userSearch = new this.UsersSearch(this.user, {});
      this.userSearch.setOrg(this.user.currentOrgContext().id);

      this.userSearch.search()
        .then((results) => {
          this.userSearchResults = results;
          this.orgUsers = _.map(this.userSearchResults, (result) => {
            return {
              name: `${result.firstName} ${result.lastName}`,
              value: result.$id
            };
          });
        });
    }
  }

  search(searchText) {
    let body = this.productSearch.getSearchQuery();

    _.remove(body.query.bool.must, e => e.match_phrase_prefix);
    if (searchText) {
      body.query.bool.must.push({
        'match_phrase_prefix': {
          brandName: searchText.toLowerCase()
        }
      });
    }

    this.productSearch.setSearchQuery(body);
    this.searching = this.loading = true;
    this.$q.when(this.productSearch.search())
      .then(results => {
        this.productResults = results;
        this.maxReached = this.productSearch.searchResults && this.productSearch.searchResults.length >= this.MAX_RECS;
      })
      .catch(err => {
        this.$log.error(err);
        this.growl.error('Error occurred during search.');
      })
      .finally(() => this.searching = this.loading = false);
  }

  getMore() {
    this.loading = true;
    this.productSearch.getMore()
      .then(() => {
        this.productResults = this.productSearch.searchResults;
        this.maxReached = this.productSearch.searchResults.length >= this.MAX_RECS;
      })
      .finally(() => this.loading = false);
  }

  createNew() {
    let newProd = _.assign(this.products.newProduct(this.user), {
      brandName: this.newProduct.name,
      category: this.newProduct.category || null,
      description: this.newProduct.description || null
    });

    return this.products.create(newProd).then($id => [_.assign(newProd, {$id})]);
  }

  onOldNewSwitch() {
    this.newProducts = '';
    this.selectedProducts = [];
  }

  ok() {
    const isNewProduct = !this.service.orgLevel && this.newOldProd === 'new';
    let retObj = {
      specialInstructions: this.specialInstructions,
      isRush: this.isRush,
      isNewProduct
    };

    if (this.selectedUserId) {
      retObj.user = _.assign(_.find(this.userSearchResults, {$id: this.selectedUserId}), {uid: this.selectedUserId});
    }

    this.submitting = true;
    if (this.service.orgLevel) {
      this.modalInstance.close(retObj);
      return;
    }

    let prodsPromise = isNewProduct ? this.createNew() : this.selectedProducts;

    this.$q.when(prodsPromise).then(prods => {
      if (isNewProduct) {
        this.growl.success('New Plan Created.');
      }

      retObj.products = _.map(prods, (product) => (
        {
          key: product.$id,
          brandName: product.brandName,
          externalId: product.externalId || null
        }));

      this.modalInstance.close(retObj);
    }).catch(err => {
      this.utils.defaultErrorHandler(err, 'An error occurred creating the new product/plan.');
      this.submitting = false;
    });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

module.exports = Controller;
