/* eslint-disable new-cap */
module.exports = function(ngModule) {
  ngModule.factory('organizations', function($q, $firebaseObject, $firebaseArray, $log, $state,S3, fbutil, CacheFactory,
                                             urlTokens, CompanyNameSearch, orgTypes, $http, orgPerspectives, growl,
                                             billing, utils) {
    'ngInject';

    const baseUri = 'organizations';
    let miniProfileCache, milestonesCache;

    // Check to make sure the cache doesn't already exist
    if (!CacheFactory.get('miniProfileCache')) {
      miniProfileCache = CacheFactory('miniProfileCache', {
        maxAge: 30 * 60 * 1000, // 30 minutes
        deleteOnExpire: 'aggressive'
      });
    }

    // Check to make sure the cache doesn't already exist
    if (!CacheFactory.get('milestonesCache')) {
      milestonesCache = CacheFactory('milestonesCache', {
        maxAge: 30 * 60 * 1000, // 30 minutes
        deleteOnExpire: 'aggressive'
      });
    }

    return {
      /**
       * Create a new organization
       * @param {object} params Parameters
       * @param {string} params.companyName The company name
       * @param {Object} params.members A hash of member uid's (e.g. {myuid_123: true})
       * @param {string} params.pointOfContactEmail The point of contact email address
       * @param {string} params.pointOfContactName The point of contact full name
       * @param {string} params.createdByUid The uid of the user creating the organization
       * @param {string} params.salespersonId The salesperson id who sold this org.
       * @param {string} [params.supplierMgmtOrg] The org who initiated a supplier file request that resulted in
       * this signup.
       * @param {object} options Additional create options
       * @param {boolean} options.assumeOwnership If true, make the current user an admin
       * @returns {HttpPromise<Object>} A firebase reference to the new organization
       */
      create: function(params, options) {
        return $http.post(`/organizations${utils.jsonToQueryString(options)}`, params)
            .then(response => response.data);
      },

      $push(org) {
        return $firebaseObject(fbutil.ref('organizations').push(org));
      },

      /**
       * Get an organization as FirebaseObject
       * @param {string} orgId The organization ID
       * @returns {Promise<Object>} A firebase object containing the organization
       */
      $get: function(orgId) {
        return $firebaseObject(fbutil.ref('organizations', orgId)).$loaded();
      },

      /**
       * Get an organization
       * @param {string} orgId The organization ID
       * @returns {Promise<Object>} The organization object or null
       */
      get: function(orgId) {
        return fbutil.ref('organizations', orgId).once('value').then(snap => fbutil.getValueOrDefault(snap, true));
      },

      $pushFacility: function(orgId) {
        return $firebaseObject(fbutil.ref('organizations', orgId, 'facilities').push()).$loaded();
      },

      /**
       * Get an organizations facilities.
       * @param {string} orgId The organization ID
       * @returns {Promise<$firebaseArray>} A firebase array of the facilities
       */
      $getFacilities: function(orgId) {
        return $firebaseArray(fbutil.ref('organizations', orgId, 'facilities')).$loaded();
      },

      /**
       * Get an organizations facilities.
       * @param {string} orgId The organization ID
       * @returns {Promise<$firebaseArray>} A firebase array of the facilities
       */
      $getMembers: function(orgId) {
        return $firebaseArray(fbutil.ref('organizations', orgId, 'members')).$loaded();
      },

      /**
       * Get an organization's outstanding user invitations.
       * @param {string} orgId The organization ID
       * @returns {Promise<$firebaseArray>} A firebase array of the outstanding invitations
       */
      $getInvitations: function(orgId) {
        return $firebaseArray(fbutil.ref('organizations', orgId, 'userInvitations')).$loaded();
      },

      remove: function(user, organizationId, hardRemove) {
        return hardRemove ?
          fbutil.ref('organizations', organizationId).remove() : $q.when([
            fbutil.ref('organizations', organizationId, 'deletedBy').set(user.uid),
            fbutil.ref('organizations', organizationId, 'deleted').set(new Date().getTime()),
            fbutil.ref('organizations', organizationId, 'deletedOn').set(new Date().getTime())
          ]);
      },

      /**
       * Get the pre-set subscription ID for an organization
       * @param {string} orgId The organization ID
       * @returns {string} The pre-set subscription ID or null if none exists.
       */
      getPreSubscriptionId: function(orgId) {
        return fbutil.ref('organizations', orgId, 'preSubscription').once('value')
          .then(fbutil.getValueOrDefault);
      },

      /**
       * Set the subscription property for an organization.
       * @param {string} orgId The organization ID for which subscription is being applied
       * @param {object | null} subscription The subscription to set. Pass null to remove the subscription.
       * @param {string} subscription.id The subscription ID.
       * @param {object} subscription.plan The subscription plan.
       * @param {string} subscription.status The subscription status.
       * @param {string=} couponCodeId Optional coupon code ID on the subscription.
       * @returns {Promise} The resulting promise for the set subscription.
       */
      setSubscription: function(orgId, subscription, couponCodeId) {
        let updates = !subscription ?
        [
          fbutil.ref('organizations', orgId, 'subscriptionId').remove(),
          fbutil.ref('organizations', orgId, 'subscriptionPlanId').remove()
        ] :
        [
          fbutil.ref('organizations', orgId, 'pendingSubscription').remove(),
          fbutil.ref('organizations', orgId, 'subscriptionId').set(subscription.id),
          fbutil.ref('organizations', orgId, 'subscriptionPlanId').set(subscription.plan.id)
        ];

        if (couponCodeId) {
          updates.push(fbutil.ref('organizations', orgId, 'subscriptionCouponCodeId').set(couponCodeId));
        }

        if (subscription && subscription.status === billing.subscriptionStatuses.TRIALING) {
          updates.push(fbutil.ref('organizations', orgId, 'trialUsed').set(true));
        }

        return $q.all(updates)
          .then(() => subscription);
      },


      removePreSubscription: function(orgId) {
        return fbutil.ref('organizations', orgId, 'preSubscription').remove();
      },

      /**
       * Did the organization use a subscription trial yet?
       * @param {string} orgId Organization Id
       * @return {Promise} A promise that resolves to true if already used.
       */
      wasTrialUsed: function(orgId) {
        return fbutil.ref('organizations', orgId, 'trialUsed').once('value').then(fbutil.getValueOrDefault);
      },

      /**
       * Get the organization name.
       * @param {string} orgId The organization ID
       * @returns {Promise<string>} A promise returning the organization name
       */
      getName: function(orgId) {
        return fbutil.ref('organizations', orgId, 'companyName').once('value')
          .then(fbutil.getValueOrDefault);
      },

      /**
       * Get the organization phone number.
       * @param {string} orgId The organization ID
       * @returns {Promise<string>} A promise returning the organization phone number
       */
      getPhone: function(orgId) {
        return fbutil.ref('organizations', orgId, 'phone').once('value')
          .then(fbutil.getValueOrDefault);
      },
      /**
       * Get the organization website.
       * @param {string} orgId The organization ID
       * @returns {Promise<string>} A promise returning the organization website
       */
      getWebsite: function(orgId) {
        return fbutil.ref('organizations', orgId, 'website').once('value')
          .then(fbutil.getValueOrDefault);
      },

      /**
       * Update the company's product category (which is important for marketing).
       * @param {string} orgId Organization Id
       * @param {string} category Product category to set
       * @return {Promise} A promise that is resolved when the operation is complete.
       */
      setProductCategory: function(orgId, category) {
        return fbutil.ref('organizations', orgId, 'productCategory').set(category);
      },

      /**
       * Get the publicly accessible organization profile.
       * @param {string} orgId The organization ID
       * @returns {Promise<Object>} A promise returning the public profile
       */
      getProfile: function(orgId) {
        return $q.all({
          id: orgId,
          $id: orgId,
          name: this.getName(orgId),
          logo: this.getLogo(orgId),
          website: this.getWebsite(orgId),
          physicalAddress: fbutil.ref('organizations', orgId, 'physicalAddress').once('value')
            .then(fbutil.getValueOrDefault),
          city: fbutil.ref('organizations', orgId, 'city').once('value').then(fbutil.getValueOrDefault),
          state: fbutil.ref('organizations', orgId, 'state').once('value').then(fbutil.getValueOrDefault),
          postalCode: fbutil.ref('organizations', orgId, 'postalCode').once('value').then(fbutil.getValueOrDefault),
          mailingAddress: fbutil.ref('organizations', orgId, 'mailingAddress').once('value')
            .then(fbutil.getValueOrDefault),
          mailingCity: fbutil.ref('organizations', orgId, 'mailingCity').once('value').then(fbutil.getValueOrDefault),
          mailingState: fbutil.ref('organizations', orgId, 'mailingState').once('value').then(fbutil.getValueOrDefault),
          mailingPostalCode: fbutil.ref('organizations', orgId, 'mailingPostalCode').once('value')
            .then(fbutil.getValueOrDefault),
          pointOfContactEmail: fbutil.ref('organizations', orgId, 'pointOfContactEmail').once('value')
            .then(fbutil.getValueOrDefault),
          pointOfContactName: fbutil.ref('organizations', orgId, 'pointOfContactName').once('value')
            .then(fbutil.getValueOrDefault),
          types: fbutil.ref('organizations', orgId, 'types').once('value')
            .then(fbutil.getValueOrDefault),

          // Properties moved over from the teams collection:
          description: fbutil.ref('organizations', orgId, 'description').once('value').then(fbutil.getValueOrDefault)
        });
      },

      /**
       * Returns a mini-profile for an organization.
       * @param {string} id Organization Id
       * @returns {object} A profile containing a name, id, and logo.
       */
      getMiniProfile: function(id) {
        if (miniProfileCache.get(id)) {
          return $q.resolve(miniProfileCache.get(id));
        }

        return $q.all({
          name: this.getName(id),
          id: id,
          logo: this.getLogo(id),
          website: this.getWebsite(id)
        }).then(miniProfile => {
          miniProfileCache.put(id, miniProfile);

          return miniProfile;
        });
      },

      updateLogo: function(orgId, fileMetadata) {
        return fbutil.ref('organizations', orgId, 'logo').set(fileMetadata);
      },

      /**
       * Returns a link to an organization's logo
       * @param {string} id OrgId
       * @returns {string} A logo URL.
       */
      getLogo: function(id) {
        return fbutil.ref('organizations', id, 'logo/key').once('value').then(snap => {
          return this.keyToLogoUrl(snap.exists() ? snap.val() : null);
        });
      },

      /**
       * Convert a S3 key to a temporary url.
       * @param {string} key S3 key
       * @returns {{url: *}} A linkable URL that points to the S3 resource.
       */
      keyToLogoUrl: function(key) {
        if (key) {
          return {
            isGeneric: false,
            url: S3.getPublicFileUrl(key)
          };
        }

        return {
          isGeneric: true,
          url: require('../../_img/logo_placeholder.png')
        };
      },

      getPrimaryContact: function(orgId) {
        return $q.all({
          pointOfContactEmail: fbutil.ref('organizations', orgId, 'pointOfContactEmail').once('value')
            .then(fbutil.getValueOrDefault),
          pointOfContactName: fbutil.ref('organizations', orgId, 'pointOfContactName').once('value')
            .then(fbutil.getValueOrDefault)
        });
      },

      getSubscriptionCouponCodeId: function(orgId) {
        return fbutil.ref('organizations', orgId, 'subscriptionCouponCodeId').once('value')
          .then(fbutil.getValueOrDefault);
      },

      getAnalystTeam: (orgId) => fbutil.ref('organizations', orgId, 'analystTeamId').once('value')
        .then(fbutil.getValueOrDefault),

      setAnalystTeam: (orgId, teamId) => fbutil.ref('organizations', orgId, 'analystTeamId').set(teamId),

      getAnalystView: function(orgId) {
        return $q.all({
          facilities: fbutil.ref('organizations', orgId, 'facilities').once('value').then(fbutil.getValueOrDefault),
          safetyTeam: fbutil.ref('organizations', orgId, 'safetyTeam').once('value').then(fbutil.getValueOrDefault),
          analystTeamId: this.getAnalystTeam(orgId)
        });
      },

      getSupplierRefs: function(orgId) {
        return fbutil.ref('organizations', orgId, 'suppliers').once('value')
          .then(suppliers => {
            if (!suppliers.exists()) {
              return;
            }

            return _.mapValues(suppliers.val(), (val, supplierId) => {
              if (val !== true) {
                return;
              }
              return fbutil.ref('suppliers', supplierId);
            });
          });
      },

      getSuppliers: function(orgId) {
        return this.getSupplierRefs(orgId)
          .then(supplierRefs => {
            return $q.all(_.mapValues(supplierRefs, ref => {
              return $firebaseObject(ref).$loaded();
            }));
          });
      },

      /**
       * Returns a mini-profile of all organizations that have type 'analyst' selected.
       * @param {object} user The logged in user object
       * @returns {Promise} A promise that resolves to an array of org mini-profiles.
       */
      getAnalystOrgs(user) {
        let nameSearch = new CompanyNameSearch(user, true);

        return nameSearch.filterByTypes([orgTypes.ANALYST]).search()
          .then(results => {
            if (!results) {
              return [];
            }
            return $q.all(_.map(results, result => this.getProfile(result.$id)));
          });
      },

      /**
       * Get a list of analyst members for an organization.
       * @param {string} organizationId  Return analysts assigned to this organization Id.
       * @returns {array} An array of user profiles.
       */
      getAnalystMembers(organizationId) {
        return $http.get('/organizations/' + organizationId + '/members?role=analyst').then(result => result.data);
      },

      /**
       * Get a list of members with certain roles in an organization.
       * @param {string} organizationId  Return analysts assigned to this organization Id.
       * @param {array} role an array of roles.
       * @returns {array} An array of user profiles.
       */
      getMembersWithRoles(organizationId, role) {
        return $http.get('/organizations/' + organizationId + '/members?role='+ role).then(result => result.data);
      },

      /**
       * Get a list of organization members with a given claim.
       * @param {string} organizationId - Organization Id.
       * @param {string} claim - The claim name.
       * @returns {array} - An array of user profiles.
       */
      getMembersWithClaim(organizationId, claim) {
        return $http.get('/organizations/' + organizationId + '/members?claim=' + claim)
          .then(result => result.data);
      },

      getRoles: function(orgId) {
        return fbutil.ref('roles', orgId).once('value')
          .then(snap => {
            if (snap.exists()) {
              return snap.val();
            }
            return fbutil.ref('roles/default').once('value').then(fbutil.getValueOrDefault);
          });
      },

      /**
       * Was this org created as a supplier org by another org?
       * @param {string} orgId The org to check
       * @returns {Promise<any>} A promise that resolves to the orgId who created this org or null
       * if not a supplier org.
       */
      isSupplierOrg(orgId) {
        return fbutil.ref('organizations', orgId, 'supplierOrgFor').once('value').then(fbutil.getValueOrDefault);
      },

      /**
       * Get the default perspective for an organization (based on org types)
       * @param {string|Object} orgId Either the org record or an org ID
       * @returns {Promise<string>} A promise resolving to the default perspective
       */
      getDefaultPerspective: function(orgId) {
        let orgTypePromise = _.get(orgId, 'types') ? $q.resolve(orgId.types) :
          fbutil.ref('organizations', orgId, 'types').once('value')
            .then(fbutil.getValueOrDefault);
        let orgPerspectivePromise = fbutil.ref('organizations', orgId, 'defaultPerspective').once('value')
          .then(fbutil.getValueOrDefault);

        return $q.all({types: orgTypePromise, perspective: orgPerspectivePromise})
          .then((types, perspective) => {
            if (perspective) { return perspective; }
            if (_.isEmpty(types)) {
              return orgPerspectives.NONE;
            }

            let t = _.find(_.orderBy(orgTypes, 'priority'), (orgType) => types[orgType.id]);

            return t ? t.defaultPerspective : orgPerspectives.NONE;
          });
      },

      uploadLogo: function(orgId, file) {
        const filename = file.name,
          key = 'organizations/' + orgId + '/logo' + filename.substr(_.lastIndexOf(filename, '.'));

        return S3.uploadPublicFile(key, file)
          .then((resp) => fbutil.ref('organizations', orgId, 'logo').set(resp));
      },

      /**
       * Add a member to an organization.
       * @param {string} orgId The organization ID
       * @param {string} uid The user ID to add to the organization
       * @returns {Promise} A promise resolving to result of the add operation
       */
      addMember: (orgId, uid) => fbutil.ref('organizations', orgId, 'members', uid).set(true),

      /**
       * Remove a member from an organization.
       * @param {string} orgId The organization ID
       * @param {string} uid The user ID to remove from an organization
       * @returns {Promise} A promise resolving to result of the remove operation
       */
      removeMember: (orgId, uid) => fbutil.ref('organizations', orgId, 'members', uid).remove(),

      /**
       * Get array of misc org files.
       * @param {string} organizationId Organizations identifier.
       * @return {*}  Promise that resolves to a FirebaseArray.
       */
      externalFiles: function(organizationId) {
        return $firebaseArray(fbutil.ref('organizations', organizationId, 'externalFiles')).$loaded();
      },

      /**
       * Retrieve the message topic Id for an org-to-org message topic.
       * @param {object} user - The logged in user.
       * @param {string} orgId - The other org whom the message is with.
       * @returns {Promise} A promise that resolves to the message topic Id or null if no topic exists.
       */
      getOrgMessageTopic: (user, orgId) => fbutil.ref('organizations', user.orgContext.id, 'orgMessages', orgId)
        .once('value')
        .then(fbutil.getValueOrDefault),

      /**
       * Retrieve the message topic Ids for all org-to-x message topic.
       * @param {object} user - The logged in user.
       * @param {string} type - 'org' or 'user'.
       * @returns {Promise} A promise that resolves to an FB array of topics
       */
      getAllMessageTopics: (user, type) =>
        $firebaseArray(fbutil.ref('organizations', user.orgContext.id,
          type === 'org' ? 'orgMessages' : 'userMessages')).$loaded(),

      /**
       * Returns the number of open message topics between [orgId] and other orgs
       * @param {object} orgId - Org to check.
       * @returns {Promise} A promise that resolves to the number of topics
       */
      messageOrgTopicCount: (orgId) =>
        fbutil.ref(`organizations/${orgId}/orgMessages`).once('value')
          .then(snap => snap.exists() ? _.keys(snap.val()).length : 0),

      /**
       * Save a message topic Id for a org-to-org message topic.
       * @param {object} user - The logged in user.
       * @param {string} orgId The other org whom the message is with.
       * @param {string} topicId The message topic Id.
       * @returns {Promise} A promise that resolves when the message Id is saved.
       */
      saveOrgMessageTopic(user, orgId, topicId) {
        return $q.all([
          fbutil.ref('organizations', user.orgContext.id, 'orgMessages', orgId).set(topicId),
          fbutil.ref('organizations', orgId, 'orgMessages', user.orgContext.id).set(topicId)
        ]);
      },

      /**
       * Delete the message topic Id for a org-to-org message topic.
       * @param {object} user The logged in user.
       * @param {string} orgId The other org whom the message is with.
       * @returns {Promise} A promise that resolves when the message Id is saved.
       */
      deleteOrgMessageTopic(user, orgId) {
        return $q.all([
          fbutil.ref('organizations', user.orgContext.id, 'orgMessages', orgId).remove(),
          fbutil.ref('organizations', orgId, 'orgMessages', user.orgContext.id).remove()
        ]);
      },

      /**
       * Set an org's preferred provider for one or more service IDs.
       * @param {string} orgId The organization ID for which the preferred providers will be set
       * @param {string} providerOrgId The organization ID of the provider
       * @param {Array<string>} serviceIds An array of marketplace service IDs
       * @returns {Promise<Array>} A promise that resolves when the preferred provider(s) are set.
       */
      setPreferredProvider(orgId, providerOrgId, serviceIds) {
        return fbutil.ref('organizations', orgId, 'preferredProviders').once('value')
          .then((result) => {
            let dbProviders = result.val() || {};

            return $q.all(_.map(serviceIds, (id) => {
              let existingKey = _.findKey(dbProviders, ['serviceId', id]);

              if (existingKey) {
                return fbutil.ref('organizations', orgId, 'preferredProviders', existingKey, 'providerOrgId')
                  .set(providerOrgId);
              } else {
                return fbutil.ref('organizations', orgId, 'preferredProviders')
                  .push({serviceId: id, providerOrgId: providerOrgId});
              }
            }));
          });
      },

      /**
       * Removes all preferredProvider service records where a given organization is the preferred provider.
       * @param {string} orgId The organization ID for which the preferred provider will be removed
       * @param {string} providerOrgId The organization ID of the provider
       * @returns {HttpPromise} A promise that resolves when the preferred provider is removed.
       */
      removePreferredProvider(orgId, providerOrgId) {
        return $http.delete('/organizations/' + orgId + '/preferredProviders/' + providerOrgId);
      },

      /**
       * Get an organization's clients
       * @param {string} orgId The organization ID
       * @returns {Promise<Array>} A promise resolving to the list of clients
       */
      getClientsOld(orgId) {
        return $http.get(baseUri + '/' + orgId + '/clientsOld')
          .then((result) => result.data);
      },

      /**
       * Get an organization's document templates
       * @param {string} orgId The organization ID
       * @returns {Promise<$firebaseArray>} A promise resolving to the list of document templates
       */
      $getDocumentTemplates(orgId) {
        return $firebaseArray(fbutil.ref('organizations', orgId, 'documentTemplates')).$loaded();
      },

      /**
       * Sets an organization's org types.
       * @param {string} orgId The organization ID
       * @param {string | Array<string>} types The types to add (orgTypes enum).
       * @return {Promise} type A promise that is resolved when the type is added.
       */
      setOrganizationTypes(orgId, types) {
        types = _.isArray(types) ? types : [types];
        let typesObject = {};

        _.each(types, t => {
          typesObject[t] = true;
        });

        return fbutil.ref('organizations', orgId, 'types').set(typesObject);
      },

      /**
       * Get all GMP categories, sections & questions for an organization.
       * @param {string} orgId The organization ID
       * @returns {Promise<Object>} A firebase object containing the GMPs
       */
      getOrganizationGmps: (orgId) => $firebaseObject(fbutil.ref('organizations', orgId, 'gmps')).$loaded(),

      /**
       * An enumeration of milestones that are tracked per organization. They are used to guide the user with a
       * checklist and to quickly determine the extent to which their food safety plan has been created.
       */
      milestones: {
        FIRST_FACILITY: {
          id: 'facility',
          name: 'First Facility Added'
        },
        GMP: {
          id: 'gmp',
          name: 'Added Good Manufacturing Practices'
        },
        SAFETY_TEAM: {
          id: 'safetyTeam',
          name: 'Started Safety Team'
        },
        PLAN_CREATED: {
          id: 'plan',
          name: 'Started First Plan'
        },
        DIAGRAM_STARTED: {
          id: 'diagram',
          name: 'Started Process Step Diagram'
        },
        PLAN_ANALYSIS_STARTED: {
          id: 'planAnalysis',
          name: 'Started Plan Analysis'
        },
        HAZARDS_STARTED: {
          id: 'hazards',
          name: 'Identified First Hazard'
        },
        CONTROLS_STARTED: {
          id: 'controls',
          name: 'Entered First Control'
        },
        HAS_SUPPLIERS: {
          id: 'suppliers',
          name: 'Added First Supplier'
        },
        RECALL_PLAN: {
          id: 'recallPlan',
          name: 'Started Recall Plan'
        },
        REC_MGMT: {
          id: 'recMgmt',
          name: 'Begin Ongoing Record Keeping'
        }
      },

      /**
       * Returns an organization's milestones.
       * @param {string} id Organization Id
       * @returns {Promise<object>} A promise that resolves to the org's milestones.
       */
      getMilestones: function(id) {
        if (milestonesCache.get(id)) {
          return $q.resolve(milestonesCache.get(id));
        }

        return fbutil.ref('organizations', id, 'milestones').once('value').then(milestonesSnap => {
          let val = milestonesSnap.exists() ? milestonesSnap.val() : {};

          milestonesCache.put(id, val);
          return val;
        });
      },

      /**
       * Has the organization achieved the given milestone.
       * @param {string} orgId Organization Id
       * @param {object} milestone milestone entry (from this.milestones)
       * @param {string} milestone.id milestone entry id
       * @param {string} [milestone.name] milestone entry name
       * @return {Promise<boolean>} A promise that resolves to true if the milestone was achieved.
       */
      isMilestoneAchieved(orgId, milestone) {
        return this.getMilestones(orgId).then(milestones => {
          return milestones[milestone.id];
        });
      },

      /**
       * Set the milestone to 'achieved' for the given organization.
       * @param {string} orgId Organization Id
       * @param {object} milestone milestone entry (from this.milestones)
       * @param {string} milestone.id milestone entry id
       * @param {string} [milestone.name] milestone entry name
       * @param {boolean} [milestone.suppressGrowl] Suppress the growl
       * @return {Promise<*>} A promise that resolves when the milestone is updated.
       */
      setMilestoneAchieved(orgId, milestone) {
        return this.isMilestoneAchieved(orgId, milestone)
          .then(isAchieved => {
            if (isAchieved) { return; }

            milestonesCache.remove(orgId);
            return fbutil.ref('organizations', orgId, 'milestones', milestone.id).set(true)
              .then(() => {
                $log.info('User completed checklist item', {item: milestone.id});
/*
                This is the old checklist logic. Maybe add it back in some other form (e.g. if we have a checklist
                style report).
                if (!milestone.suppressGrowl) {
                  // Don't show the growl if the org turned off their checklist campaign.
                  return this.isMilestoneAchieved(orgId, {id: 'endCampaign'}).then(campaignEnded => {
                    if (!campaignEnded) {
                      growl.success('<i class="fa fa-trophy"></i> Congratulations, you completed an item in ' +
                        'your <a href="https://app.foodready.ai/#!/user/dashboard/progress">' +
                        'Food Safety Checklist</a>: <b>' +
                        milestone.name + '</b>!', {ttl: 10000});
                    }
                  });
                }
*/
              })
              .catch(err => $log.error('An error occurred setting a companies achievement', $log.toString(err)));
          });
      },

      /**
       * End the organization milestone campaign. This campaign is designed for new users. Once over, the checklist view
       * will be hidden.
       * @param {string} orgId Organization Id.
       * @return {Promise.<*>} Resolves when the campaign ends.
       */
      endMilestoneCampaign(orgId) {
        return this.setMilestoneAchieved(orgId, {id: 'endCampaign', suppressGrowl: true});
      },

      getOnboardingQuestionnaire(orgId) {
        return $http.get('/organizations/' + orgId + '/onboardingQuestionnaire').then(result => result.data);
      },

      getClients(orgId) {
        return $http.get('/organizations/' + orgId + '/clients').then(result => _.get(result, 'data.clients'));
      },

      /**
       * Search for similar hazards.
       * @param {string} orgId The orgId of the requester
       * @param {object} options Search options
       * @param {string} options.stepText The text of the step (to help narrow down similar hazards)
       * @param {string} options.hazardText Text that describes the hazard we want to find.
       * @param {string} options.hazardType The hazard type - bio, chem, phy
       * @param {string} options.omitHazardId If present, omit this hazard
       * @param {string} options.productCategoryId Limit to hazards belonging to a product category
       * @return {Q.Promise<any> | IRoute | T | TRequest} A promise resolves to an array of similar hazards.
       */
      searchHazards(orgId, options) {
        if (!options.hazardText) { throw new Error('Missing required hazard search parameters.'); }
        let url = `/organizations/${orgId}/hazards/search?includeCfHazards=true&hazardText=${encodeURIComponent(options.hazardText)}`;

        if (options.hazardType) {
          url += `&hazardType=${options.hazardType}`;
        }
        if (options.productCategoryId) {
          url += `&productCategoryId=${options.productCategoryId}`;
        }
        if (options.omitHazardId) {
          url += `&omitHazardId=${options.omitHazardId}`;
        }

        return $http.get(url).then(result => result.data.hazards);
      },

      removeProvider(orgId) {
        return fbutil.ref(`organizations/${orgId}/provider`).set(null);
      },

      setProvider(orgId, providerId) {
        return $q.all([
          fbutil.ref(`organizations/${orgId}/provider`).set(providerId),
          fbutil.ref(`organizations/${orgId}/transferDate`).set(new Date().getTime())
        ]);
      },

      $pushSafetyTeamMember($safetyTeam) {
        return $firebaseObject($safetyTeam.$ref().push()).$loaded();
      },

      /**
       * Get the organization name.
       * @param {string} orgId The organization ID
       * @returns {Promise<string>} A promise returning the organization type
       */
      getType: function(orgId) {
        return fbutil.ref('organizations', orgId, 'type').once('value')
          .then(fbutil.getValueOrDefault);
      },
    };
  });
};
