class Controller {
  constructor(recallService, $uibModal, utils, authorization, confirmDeleteModal, growl) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.recallService = recallService;
    this.utils = utils;
    this.authorization = authorization;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
  }

  $onInit() {
  }

  edit(report) {
    this.$uibModal.open({
      component: 'cfProblemReportsEdit',
      controllerAs: 'vm',
      resolve: {
        $report: () => report ? this.recallService.$getProblemReport(this.user.orgContext.id, report.$id) :
          this.recallService.$pushProblemReport(this.user.orgContext.id)
      }
    }).result
      .then(() => this.growl.success('Problem Report saved successfully.'))
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to save problem report.'));
  }

  remove(report) {
    if (this.user.hasPermission(this.authorization.claims.PRODUCT_WRITE)) {
      this.confirmDeleteModal(report.initialAction).then(() => {
        if (report) {
          this.$problemReports.$remove(report)
            .then(() => this.growl.success('Problem report removed successfully.'))
            .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to remove problem report.'));
        }
      });
    } else {
      this.growl.error('Insufficient access. Please contact customer support.');
    }
  }
}

module.exports = Controller;
