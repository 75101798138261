module.exports = function(ngModule) {
  ngModule.component('cfAddAch', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./products.add-ach.template.html'),
    controller: require('./products.add-ach.controller.js'),
    controllerAs: 'vm'
  });
};
