'use strict';

module.exports = function(parentModuleName) {
  var moduleName = parentModuleName + '.reports',
    ngModule = angular.module(moduleName, []);

  require('./_config')(ngModule);

  return moduleName;
};
