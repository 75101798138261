module.exports = function(ngModule) {
  class QuestionnaireService {
    constructor(fbutil, constantsService, $uibModal, $q, $http) {
      'ngInject';

      this.fbutil = fbutil;
      this.$uibModal = $uibModal;
      this.$q = $q;
      this.$http = $http;
      this.questionnairesPromise = constantsService.get('questionnaires');
    }

    $onInit() {
      this.isComplete = true;
    }

    /**
     * Save a questionnaire to the organization data collection.
     * @param {string} type The type of questionnaire
     * @param {string} organizationId The org to which the answers are saved
     * @param {object} answers An object properties and answers.
     * @return {*} A promise that resolves when the questionnaire is saved
     */
    saveQuestionnaire(type, organizationId, answers) {
      return this.$http.post(`organizations/${organizationId}/questionnaires/${type}`, answers);
    }

    /**
     * Return a questionnaire object.
     * @param {string} orgId The organization who completed the questionnaire.
     * @param {string} type The questionnaire type
     * @return {*} A promise that resolves to a questionnaire object.
     */
    getQuestionnaire(orgId, type) {
      return this.questionnairesPromise.then(q => {
        const questionnaire = q[type];

        return this.$http.get(`organizations/${orgId}/questionnaires/${type}`).then(result => {
          let entry = result.data;

          if (!entry) { return questionnaire; }

          _.each(entry.answers, (val, prop) => {
            const item = _.find(questionnaire.items, (item, itemId) => itemId === prop);

            if (item) {
              item.answer = val;
            }
          });
          questionnaire.additionalComments = entry.answers.additionalComments;
          return questionnaire;
        });
      });
    }

    /**
     * Return an empty questionnaire object.
     * @param {string} type The questionnaire type
     * @return {*} A promise that resolves to a questionnaire object.
     */
    getEmptyQuestionnaire(type) {
      return this.questionnairesPromise.then(q => q[type]);
    }

    /**
     * Return all questionnaire types.
     * @return {*} A promise that resolves to an array of questionnaire types.
     */
    getTypes() {
      return this.questionnairesPromise.then(q => _.keys(q));
    }

    /**
     * Display a modal for the user to select a questionnaire type.
     * @param {string} msg The message to display to the user.
     * @param {string} completedByOrg If set, only display types that have a recorded entry for the org.
     * @return {*}  A promise that resolves to the chosen questionnaire type.
     */
    chooseType(msg, completedByOrg) {
      return (completedByOrg ? this.completedTypes(completedByOrg) : this.questionnairesPromise).then(types => {
        if (!types) {
          return this.$q.reject('None found');
        }
        return this.$uibModal.open({
          component: 'cfChooseFromListModal',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            itemName: () => 'questionnaire',
            instructionsHtml: () => msg,
            header: () => '<i class="far fa-list-alt fa-fw"></i> Choose Questionnaire',
            itemsArray: () => _.map(types, (type, id) => ({id, description: type.description})),
            columns: () => [{
              title: 'Type',
              property: 'id'
            },
            {
              title: 'Description',
              property: 'description'
            }]
          }
        }).result.then(({id}) => id);
      });
    }

    /**
     * Return the completed questionnaire types for a given org.
     * @param {string} orgId The org to lookup.
     * @return {*} A promise that resolves to an array of questionnaire types.
     */
    completedTypes(orgId) {
      return this.$q.all({
        types: this.questionnairesPromise,
        entriesSnap: this.fbutil.ref(`questionnaires/${orgId}`).once('value')
      }).then(({types, entriesSnap}) => {
        if (!entriesSnap.exists()) { return null; }
        let entries = entriesSnap.val();

        return _.pickBy(types, (type, id) => entries[id]);
      });
    }
  }

  ngModule.service('questionnaireService', QuestionnaireService);
};
