module.exports = function(ngModule) {
  ngModule.component('cfRejectRfqAssignment', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./marketplace.reject-rfq-assignment.template.html'),
    controller: require('./marketplace.reject-rfq-assignment.controller'),
    controllerAs: 'vm'
  });
};
