module.exports = function(ngModule) {
  ngModule.component('frFiles', {
    bindings: {
      company: '<',
      files: '<',
      user: '<'
    },
    template: require('./file-manager.list.template.html'),
    controller: require('./file-manager.list.controller'),
    controllerAs: 'vm'
  });
};
