module.exports = function(ngModule) {
  class Service {
    constructor($log, $http, $uibModal, growl, ChecklistsLibrarySearch) {
      'ngInject';

      this.$log = $log;
      this.$http = $http;
      this.$uibModal = $uibModal;
      this.growl = growl;
      this.ChecklistsLibrarySearch = ChecklistsLibrarySearch;
    }

    /**
     * Get Checklist template from id
     * @param {string} organizationId current Organization id
     * @param {string} checklistTemplateId The id of the checklist to retrieve.
     * @return {*} A promise that resolves to the Checklist.
     */
    getChecklistTemplate(organizationId, checklistTemplateId) {
      return this.$http.get(`checklistTemplates/${checklistTemplateId}/organization/${organizationId}`)
        .then((result) => result.data);
    }

    /**
     * Get Checklist template list
     * @param {string} organizationId current Organization id
     * @param {string} type checklist type
     * @return {*} A promise that resolves to the Checklist templates.
     */
    getAllChecklistTemplate(organizationId, type) {
      let url = 'v2/checklists/templates/search?organizationId=' + organizationId;

      if(type) { url += '&type=' + type + '&size=999'; }
      return this.$http.get(url)
        .then((result) => result.data.data);
    }

    /**
     * Add Checklist Template
     * @param {string} organizationId current Organization id
     * @param {string} checklistTemplate The  checklistTemplate to be saved.
     * @return {*} A promise that resolves to the Checklist.
     */
    addChecklistTemplate(organizationId, checklistTemplate) {
      return this.$http.post('checklistTemplates/organization/' + organizationId, {template: checklistTemplate})
        .then((result) => result.data);
    }

    /**
     * Update Checklist from id
     * @param {string} organizationId current Organization id
     * @param {object} checklistTemplateId The checklistTemplate Id to be updated.
     * @param {object} checklistTemplate The checklistTemplate object.
     * @return {*} A promise that resolves to the Checklist.
     */
    updateChecklistTemplate(organizationId, checklistTemplateId, checklistTemplate) {
      return this.$http.patch(`checklistTemplates/${checklistTemplateId}/organization/${organizationId}`,
        {template: checklistTemplate})
        .then((result) => result.data);
    }

    /**
     * Update Checklist from id
     * @param {string} organizationId current Organization id
     * @param {object} checklistTemplateId The checklistTemplate Id to be updated.
     * @return {*} A promise that resolves to the Checklist.
     */
    deleteChecklistTemplate(organizationId, checklistTemplateId) {
      return this.$http.delete(`checklistTemplates/${checklistTemplateId}/organization/${organizationId}`)
        .then((result) => result.data);
    }

    /**
     * Get the list of checklist template to copy.
     * @param {string} organizationId Current Organization id
     * @param {string} type The source type of Checklists.
     * @return {*} A promise that resolves to the Checklists templates.
     */
    getChecklistTemplates(organizationId, type) {
      let orgId = '';
      let checklistType = '';
      let adminTemplates = false;

      switch (type) {
      case 'fr':
        orgId = organizationId;
        adminTemplates = true;
        break;

      case 'myLib':
        orgId = organizationId;
        break;

      case 'frAudit':
        orgId = 'admin';
        checklistType = 'audit';
        break;
      default:
        orgId = 'admin';
      }
      this.checklistsLibrarySearch = new this.ChecklistsLibrarySearch(orgId);
      return this.query(orgId, this.searchText, {type: checklistType, adminTemplates}, 0);
    }

    query(organizationId, searchText, options, from = 0, size = 30) {
      options = options || {};
      searchText = searchText || '';
      let type = options.type || '';
      let adminTemplates = options.adminTemplates || false;

      this.checklistsLibrarySearch = new this.ChecklistsLibrarySearch(organizationId, type, undefined, adminTemplates);
      return this.checklistsLibrarySearch.startFrom(from).size(size).search(searchText);
    }

    copyChecklist(user, source) {
      const propsToOmit = ['updatedOn', 'updatedBy', 'updatedByName', '$id',
        '$$hashKey', 'createdOn', 'createdBy', 'organizationId', 'answers','templateId'];

      return _.assign(_.omit(source, propsToOmit), {copiedTemplateId: source.$id || source.id});
    }

    findCopyItem(user, checklists, result) {
      let foundItem = result;

      if (!foundItem) {
        throw new Error('Checklists missing from selection.');
      }
      return this.copyChecklist(user, foundItem);
    }

    /**
     * Allow the user to choose from the list of s to copy. Once chosen, the Checklist will be copied and the new Checklist
     * Id will be returned.
     * @param {object} user The logged in user.
     * @param {array} checklists The array of Checklists to choose from.
     * @param {boolean} copyMultipleLib copy multiple items from library
     * @return {*} A promise that resolves to the newly created Checklist.
     */
    chooseAndCopyChecklist(user, checklists, copyMultipleLib = false) {
      const msg =
        '<p>Select from the below checklist templates</p>';

      // const noneFoundHtml = `You haven't create any checklist template
      //   <a href="/#!/checklists/library/list">click here</a> to create one`;

      return this.$uibModal.open({
        component: 'cfChooseFromListModal',
        size: 'lg',
        resolve: {
          itemName: () => 'checklist',
          instructionsHtml: () => msg,
          // noneFoundHtml: () => noneFoundHtml,
          header: () => '<i class="far fa-drafting-compass fa-fw"></i> Select Checklist',
          // itemsArray: () => checklists,
          multiple: () => copyMultipleLib,
          searchObject: () => this.checklistsLibrarySearch,
          columns: () => [{
            title: 'Name',
            property: 'title'
          }]
        }
      }).result.then(result => {
        if (copyMultipleLib) {
          return _.map(result, item => {
            return this.findCopyItem(user, checklists, item);
          });
        }

        return this.findCopyItem(user, checklists, result);
      }).then(newChecklist => {
        return  newChecklist;
      });
    }

    /**
     * Prompt the user and then create a new Checklist. Give them the option to create a new Checklist from scratch or to
     * copy one from libraries. Returns the id of the newly created Checklist.
     * @param {object} user The logged in user.
     * @param {{noCopyExisting: boolean, copyMultipleLib: boolean}|{}} additionalOpts Additional options.
     * @param {boolean} additionalOpts.noCopyExisting Don't allow copying an existing Checklist.
     * @param {boolean} additionalOpts.copyMultipleLib Copying multiple checklist from library Checklist.
     * @param {boolean} additionalOpts.cloneTemplate Copying multiple checklist from library Checklist.
     * @return {*} A promise that resolves to the newly created Checklist.
     */
    promptAddChecklist(user, additionalOpts) {
      additionalOpts = additionalOpts || {};
      // let options = [
      //   {text: 'Select From FoodReady Checklist Library', value: 'fr'},
      //   {text: 'Select From My Checklist Library', value: 'myLib'},
      //   {text: 'Select Audit Checklist', value: 'frAudit'}
      // ];
      let resultPromise = this.getChecklistTemplates(user.orgContext.id , 'myLib');

      return resultPromise
        .then(results => {
          if (_.isEmpty(results)) {
            this.growl.error('No Checklists found');
            return;
          }
          if (additionalOpts.copyMultipleLib) {
            return this.chooseAndCopyChecklist(user, results, additionalOpts.copyMultipleLib);
          }
          return this.chooseAndCopyChecklist(user, results);
        });
    }
  }

  ngModule.service('checklistLibraryService', Service);
};
