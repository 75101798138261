module.exports = function (ngModule) {
  require('./order_payment')(ngModule);
  require('./change_default_source')(ngModule);
  require('./upgrade_subscription')(ngModule);
  require('./activate_subscription')(ngModule);
  require('./change_subscription')(ngModule);
  require('./change_subscription_modal')(ngModule);
  require('./purchase_plan')(ngModule);
  require('./add_cc')(ngModule);
  require('./plan_options')(ngModule);
  require('./pricing_card')(ngModule);
  require('./pricing_page')(ngModule);
};
