class Constructor {
  constructor(growl, batchService, utils, moment, foodProductService) {
    'ngInject';

    this.growl = growl;
    this.batchService = batchService;
    this.utils = utils;
    this.moment = moment;
    this.foodProductService = foodProductService;

    this.steps = {
      PICK_ITEM: 1,
      PICK_REPORT: 2
    };
  }

  $onInit() {
    this.user = this.resolve.user;
    this.step = this.steps.PICK_ITEM;
    this.searching = true;
    this.foodProductService.getAllFoodProducts(this.user.orgContext.id)
      .then(results => {
        this.foodProducts = results.foodProducts;
      })
      .finally(()=> this.searching = false);
  }

  chooseFoodProduct() {
    this.step = this.steps.PICK_REPORT;
    this.searching = true;
    this.batchService.getBatchRunsByFoodProduct(this.selectedFoodProduct.$id, this.user.orgContext.id)
      .then((batchRuns)=>{
        this.batchRuns = batchRuns;
      })
      .finally(()=> this.searching = false);
  }

  submit(allBatchRuns) {
    let returnObj = {
      reportType: 'batchReports',
      params: {
        foodProductId: this.selectedFoodProduct.$id
      }
    };

    if(!allBatchRuns) {
      let batchRunId = [];

      this.batchRuns.forEach((batchRun)=>{
        if(batchRun.checked) {
          batchRunId.push(batchRun.batchRunId);
        }
      });
      returnObj.params.batchRunId = batchRunId;
    }

    this.modalInstance.close(returnObj);
  }

  previousStep() {
    this.batchRuns = [];
    this.step -= 1;
  }

  isValid() {
    return _.findIndex(this.batchRuns, (item) => item.checked) > -1;
  }
}

module.exports = Constructor;
