const Draggabilly = require('draggabilly');
const FUDGE_FACTOR = 2;
let draggie;

/**
 * This directive sets up a draggable resizer like code pen has. The caller needs to have flex box setup in a
 * particular way before this can work.
 */
class CfDraggable {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-draggable.template.html');
    this.replace = true;
    this.bindToController = {
      target: '=',         // A selector that points to an element that will be resized based on the resizer
      gutter: '<',         // The gutter where the resizer can't pass
      onMove: '&'          // An optional function to call when the dragger moves
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  link(scope, el) {
    const resizerEl = _.first(el.find('.resizer'));

    draggie = new Draggabilly(resizerEl, {
      axis: 'x',
      containment: true
    });

    const targetEl = document.querySelector(scope.vm.target);

    if (!scope.vm.target || !targetEl) {
      throw new Error('Missing required cfDraggable parameters');
    }

    const gutter = _.get(scope.vm, 'gutter', 0);
    const computedStyle = window.getComputedStyle(targetEl, null);
    let flexBasis = parseInt(computedStyle.width);

    draggie.setPosition(flexBasis - gutter, 0);
    targetEl.style.flex = `0 1 ${flexBasis + FUDGE_FACTOR}px`;

    const onMove = () => {
      let newBasis = resizerEl.offsetLeft;
      let transform = resizerEl.style.transform;
      let transformX = transform.substring(transform.indexOf('(') + 1, transform.indexOf('px'));

      if (transformX) {
        newBasis += Number(transformX);
      }
      targetEl.style.flex = `0 1 ${newBasis}px`;
      if (_.isFunction(scope.vm.onMove)) {
        scope.vm.onMove({newBasis});
      }
    };

    draggie.on('dragMove', onMove);
    draggie.on('pointerUp', () => {
      onMove();
      setTimeout(onMove);
    });
  }

  controller() {
    'ngInject';

    var vm = this;

    vm.$onInit = function() {
    };
  }
}

module.exports = CfDraggable;
