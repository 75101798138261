class Controller {
  constructor(authorization, organizations, $timeout, companyService, confirmModal, $state, miniTourService,
              $document, growl, $uibModal, subscriptionService) {
    'ngInject';

    this.authorization = authorization;
    this.organizations = organizations;
    this.milestonesEnum = organizations.milestones;
    this.$timeout = $timeout;
    this.companyService = companyService;
    this.confirmModal = confirmModal;
    this.$state = $state;
    this.miniTourService = miniTourService;
    this.$document = $document;
    this.growl = growl;
    this.$uibModal = $uibModal;
    this.subscriptionService = subscriptionService;

    this.planAnalysisTour = {
      selector: '.my-progress #item0',
      placement: 'left-bottom',
      id: 'introPlanAnalysis',
      title: 'Continue Your Plan',
      contentHtml: 'Great, you\'ve created your first plan! Next, work through each step of the plan, ' +
      'beginning with <b>Plan Analysis</b>.'
    };
  }

  $onInit() {
    this.firstProduct = this.$company.products && _.first(_.keys(this.$company.products));
    this.$timeout(() => {
      this.miniTourService.enqueueTour(this.user, {
        type: this.miniTourService.type.CENTERED,
        id: 'checklistWelcome',
        title: '<i class="fas fa-list g-mr-10"></i>Welcome to Your Food Safety Plan Checklist',
        width: 750,
        contentHtml: 'A food safety plan is much more than a single document. It starts with documented good ' +
        'manufacturing practices. It includes detailed information about your product\'s processing steps along ' +
        'with the potential hazards and controls. And it ends with regular, ongoing ' +
        'monitoring of hazard controls to ensure each and every run is safe.<br><br> ' +
        'We created a checklist to help you build a thorough and comprehensive food safety plan. You don\'t have to ' +
        'use it, but our recommendation is to go down the list and take your time with each step. Most items ' +
        'offer helpful video tutorials to educate you about important food safety concepts along the way.<br><br>' +
        '<b><i>Click the FoodReady logo anytime to return to your checklist!</i></b>'
      });
    });

    // Normally we just assume the milestones collection is accurate. With 'plan created' it's more important that we
    // get it right (so sub-items become active). If it's not set and should be, then set it.
    if (this.firstProduct && !this.$company.milestones[this.milestonesEnum.PLAN_CREATED.id]) {
      this.$company.milestones[this.milestonesEnum.PLAN_CREATED.id] = true;
      this.organizations.setMilestoneAchieved(this.$company.$id,
        _.assign({suppressGrowl: true}, this.milestonesEnum.PLAN_CREATED));
    }

    if (_.keys(this.$company.milestones).length >= _.keys(this.milestonesEnum).length &&
      _.each(this.$company.milestones, m => m.true)) {
      this.confirmModal({
        title: '<i class="fa fa-trophy"></i> Congratulations!',
        okText: 'Close Checklist',
        hideCancelButton: true,
        body: 'You completed the Food Safety Checklist! Once each component of your food safety plan is finished, ' +
        'visit the <b>Expert Services</b> tab to request an expert review.'
      }).then(() => {
        this.organizations.endMilestoneCampaign(this.user.orgContext.id);
        this.$log.info('Org completed checklist campaign.', {orgId: this.user.orgContext.id});
        this.$state.go('user.dashboard');
      });
    }
    this.setItems();
  }

  setItems() {
    const onPremium = !this.user.onPayAsGoPlan();

    this.items = [];
    if (this.companyService.hasFacilities(this.$company.types)) {
      this.items.push({
        title: 'Add My First Facility',
        isComplete: () => this.$company.milestones[this.milestonesEnum.FIRST_FACILITY.id],
        toState: 'company.edit.facilities',
        helpTitle: 'Add Your First Facility',
        helpText: 'The first step of your Food Safety Plan is to add a facility. Click the ' +
        '<b>Add My First Facility</b> button below. When you\'re done, come back here to start ' +
        'step 2: <b>Good Manufacturing Practices (GMPs) & Prerequisite Programs</b>.',
        helpOkText: 'Add My First Facility'
      });
    }

    const gmpText = 'Good manufacturing practices (GMPs) are the foundation of any food safety plan. They describe ' +
      'the conditions and practices that  are necessary for the manufacturing, processing, packing or ' +
      'storage of food to ensure its safety. Watch the video below to learn more.';

    if (this.companyService.requiresGmpSurvey(this.$company.types)) {
      this.items.push({
        title: 'Good Manufacturing Practices (GMPs) & Prerequisite Programs',
        isComplete: () => this.$company.milestones[this.milestonesEnum.GMP.id],
        toState: onPremium ? 'company.edit.facility.gmps.edit.category' : 'company.upload',
        helpTitle: 'GMPs & Prerequisite Programs',
        helpText: onPremium ? gmpText : gmpText + '<br>When you\'re ready, upload your GMPs into ' +
          'the <b>Company <i>Docs & Images</i> Library</b>. Or, upgrade to <b>premium</b> and use' +
          ' FoodReady\'s <b>GMP Manager</b>, which provides access to FDA compliant GMPs and ' +
          'includes tools to ensure they are periodically reviewed per FDA regulation.',
        helpOkText: onPremium ? 'Add GMPs' : 'Upload GMPs',
        // helpVideoUrl: ''
      });
    }

    if (this.companyService.hasSafetyTeam(this.$company.types)) {
      this.items.push({
        title: 'Safety Team',
        isComplete: () => this.$company.milestones[this.milestonesEnum.SAFETY_TEAM.id],
        toState: 'company.safetyTeam',
        helpTitle: 'Add Your Food Safety Team',
        helpOkText: 'Add My Safety Team',
        helpText: 'Manufacturers should create a food safety team to put together the Food Safety Plan. ' +
        'This team should be managed by people in production, but can also include employees in ' +
        'maintenance, sanitation, quality assurance, purchasing, even marketing and logistics. A team ' +
        'approach will reduce the risk of missing key components of the plan, and also encourages ' +
        'ownership across the company. Top management commitment sends a strong message to all.<br><br>' +
        'However, there should be one person responsible for the creation, implementation, and oversight ' +
        'of the Food Safety Plan. And that person must be a <b><i>Preventive Controls Qualified Individual</i></b>. ' +
        'This individual will <ul>' +
        '<li>Oversee the food safety team.</li>' +
        '<li>Perform or oversee preparation of the Food Safety Plan.</li>' +
        '<li>Validate preventive controls.</li>' +
        '<li>Records Review.</li>' +
        '<li>Re-analysis of the Food Safety Plan.</li>' +
        '</ul>'
      });
    }

    const recallText = 'Despite a proper food safety plan, you may find yourself swept into a recall. ' +
      'Advance planning for recalls will save lives and will make the difference in the survival of your company.';

    this.items.push({
      title: 'Start My First HACCP/PC Plan',
      isComplete: () => this.$company.milestones[this.milestonesEnum.PLAN_CREATED.id],
      toModalComponent: 'cfCreateProduct',
      toModalResolve: {
        user: () => this.user,
        noProducts: () => true
      },
      onModalClosed: () => this.onModalClosed(this.planAnalysisTour),
      helpTitle: 'Start Your First HACCP/PC Plan',
      helpOkText: 'Start Plan',
      helpText: 'Watch the video below to learn the difference between a <b>HACCP</b> plan and the ' +
      '<b>Preventive Controls (PC)</b> plan. This food safety risk based PC plan was introduced ' +
      'with the Food Safety Modernization Act (FSMA). When you\'re ready, start your first plan!',
      // helpVideoUrl: '',
      subItems: [{
        title: 'Start the Plan Analysis',
        isComplete: () => this.$company.milestones[this.milestonesEnum.PLAN_ANALYSIS_STARTED.id],
        toState: 'products.edit.planAnalysis.questionnaire',
        toStateParams: {
          productId: this.firstProduct
        },
        disabled: !this.firstProduct,
        helpTitle: 'Begin filling Out the Plan Analysis',
        helpOkText: 'Start Plan Analysis',
        helpText: 'The plan analysis is where you enter basic information about the product or products covered ' +
        'under the plan. You will enter product details (e.g. brand name, description), identify which ' +
        'facility it\'s produced in, describe it\'s intended use, etc.<br><br>' +
        'Additionally, if applicable, you will need to know your specific ' +
        'product properties like pH level. Not sure what those values are? No problem! Go to the ' +
        '<b>Expert Services</b> tab and request a <b>Lab Analysis</b>.'
      }, {
        title: 'Begin Filling out the Process Flow Diagram',
        isComplete: () => this.$company.milestones[this.milestonesEnum.DIAGRAM_STARTED.id],
        toState: 'products.edit.processSteps.diagram',
        toStateParams: {
          productId: this.firstProduct
        },
        disabled: !this.firstProduct,
        helpTitle: 'Begin Filling out a Process Flow Diagram',
        helpOkText: 'Start Process Flow',
        helpText: 'A process flow chart is a pictorial representation of a process. Within the food industry, ' +
        'it generally represents the story of how you manufacture your product. ' +
        'Steps may include (but are not limited to) receiving, ingredient storage, preparation, cooking, ' +
        'cooling, packing, labelling, bulk storage and distribution. Watch the video to learn more.' +
        '<br><br><i>Note: The video below explains the next two checklist items (hazards and hazard controls) along ' +
        'with the process flow chart.',
        // helpVideoUrl: ''
      }, {
        title: 'Identify Hazards',
        toState: 'products.edit.processSteps.diagram',
        toStateParams: {
          productId: this.firstProduct
        },
        disabled: !this.firstProduct,
        isComplete: () => this.$company.milestones[this.milestonesEnum.HAZARDS_STARTED.id],
        helpTitle: 'Identify Hazards',
        helpOkText: 'Identify Hazards',
        helpText: 'A hazard analysis is the process of collecting and evaluating information on hazards and ' +
        'conditions leading to their	presence to decide which are significant for food safety and therefore ' +
        'must be addressed in the HACCP or Food Safety Plan.' +
        '<br><br>Now that you laid out your process steps, you can use FoodReady\'s ' +
        'interactive flowchart to identify hazards. Hazards can be of three types: <ul>' +
        '<li><b>Physical Hazards</b> - A physical hazard is a foreign body getting into the product, ' +
        'making it unsafe for the consumer</li>' +
        '<li><b>Chemical Hazards</b> - e.g. Chemicals, if used or stored incorrectly, can ' +
        'cause a hazard.</li>' +
        '<li><b>Biological Hazards</b> - Potential introduction of harmful bacteria.</li>' +
        '</ul>'
      }, {
        title: 'Add Hazard Controls',
        toState: 'products.edit.processSteps.diagram',
        toStateParams: {
          productId: this.firstProduct
        },
        disabled: !this.firstProduct,
        isComplete: () => this.$company.milestones[this.milestonesEnum.CONTROLS_STARTED.id],
        helpOkText: 'Add Controls',
        helpTitle: 'Add Preventive Controls',
        helpText: 'Preventive controls are those risk‐based, ' +
        'reasonably appropriate procedures, practices and processes that a person ' +
        'knowledgeable about the safe manufacturing, processing, packing or holding of food would employ to ' +
        'significantly minimize or prevent the hazards identified under the hazard analysis that are consistent ' +
        'with the current scientific understanding of safe food manufacturing, processing, packaging or holding ' +
        'at the time of the analysis.' +
        '<br><br>An example of a biological hazard when producing peanut butter is Salmonella. There is a history of ' +
        'peanut contamination with vegetative pathogens from the environment or harvesting. The process control ' +
        'that minimizes or prevents the Salmonella risk is a roasting step, where the critical control point is ' +
        '300 degrees F. for 25 minutes.'
      }, {
        title: 'Begin a Recall Plan',
        isComplete: () => this.$company.milestones[this.milestonesEnum.RECALL_PLAN.id],
        toState: onPremium ? 'recallPlan.team' : 'products.edit.upload',
        toStateParams: {
          productId: this.firstProduct
        },
        disabled: !this.firstProduct,
        helpTitle: 'Add a Recall Plan',
        helpOkText: onPremium ? 'Add a Recall Plan' : 'Upload Recall Plan',
        helpText: onPremium ? recallText + ' Watch the video to better understand how to use ' +
          'FoodReady\'s Premium level Recall Plan.' :
          recallText + ' Watch the video to better understand what should be in your Recall Plan. When ' +
          'you\'re ready, upload your recall plan into the <b>Product\'s <i>Docs & Images</i> Library</b>. ' +
          'Or, upgrade to the <b>premium</b> level to use the <b>FoodReady Recall Plan</b>.',
        // helpVideoUrl: ''
      }]
    });

    this.items.push({
      title: 'Add My Suppliers',
      isComplete: () => this.$company.milestones[this.milestonesEnum.HAS_SUPPLIERS.id],
      toState: 'ingredients.suppliers.list',
      helpTitle: 'Add Suppliers',
      helpOkText: 'Add Suppliers',
      helpText: ' Supplier verification is required when a supplier controls a hazard that can materially affect ' +
      'the food’s safety (as determined through a Hazard Analysis).' +
      '<br><br>Enter your suppliers and their products in the <b>Ingredients</b> tab. Afterward, visit the <b>Supplier Mgmt</b> tab to start a <i>Supplier File Tracking Program</i>. We will periodically send your suppliers a link to the <b>File Upload Portal</b>, an easy-to-use site where they can upload all of the documents belonging to your ingredients.',
      // helpVideoUrl: ''
    });

    let recordsTxt = 'Much of the focus up to now has been on the <b><i>design</i></b> of a rock solid plan. However if your team doesn\'t <b><i>execute</i></b> them every day, they will be meaningless. The FSMA records requirements can be divided into three sets: ' +
      '<ul class="g-my-10" style="font-size: small"><li>Records to demonstrate effective application of food safety preventive controls to minimize or prevent a hazard being controlled by the supplier, prior to the “receiving facility” (e.g., the plant, the manufacturer/processor) receiving such raw material or ingredient.</li><li>Records to demonstrate the implementation of the receiving facility’s food safety plan (i.e., records of every preventive control identified as necessary to prevent or minimize known or reasonable foreseeable hazards).</li><li>Records to demonstrate that an identified hazard is being controlled by the customer of the receiving facility, or by entities beyond the first customer.</li></ul>';

    if (onPremium) {
      this.items.push({
        title: 'Begin Record Keeping',
        isComplete: () => this.$company.milestones[this.milestonesEnum.REC_MGMT.id],
        toState: 'procedure.listRecords',
        toParams: {scope: 'organization'},
        helpTitle: 'Begin Record Keeping',
        helpOkText: 'Begin Records',
        helpText: recordsTxt + 'Start collecting records with the mobile-friendly record entry page to' +
        ' ensure your food safety plan is executed properly.'
        //helpVideoUrl: ''
      });
    } else {
      this.items.push({
        title: 'Upgrade to Begin Record Keeping',
        isComplete: () => this.$company.milestones[this.milestonesEnum.REC_MGMT.id],
        onSubmit: () => this.subscriptionService.promptForUpgrade(this.user).then(() => {
          this.$state.go('procedure.listRecords', {scope: 'organization'}, {reload: true});
        }),
        onModalClosed: () => this.onModalClosed(this.planAnalysisTour),
        helpTitle: 'Begin Record Keeping',
        helpOkText: 'Upgrade to Premium',
        helpText: recordsTxt + 'Ensure your food safety plan is executed properly. Either keep paper records' +
        ' on your own or upgrade to premium to start collecting records with the mobile-friendly record entry page.'
        //helpVideoUrl: ''
      });
    }
  }

  toAlpha(index) {
    return String.fromCharCode(65 + index);
  }

  onModalClosed(introTour) {
    this.$timeout(() => {
      this.firstProduct = this.firstProduct || this.$company.products && _.first(_.keys(this.$company.products));
      this.setItems();
      if (introTour) {
        this.$timeout(() => this.miniTourService.enqueueTour(this.user, introTour), 300);
      }
    });
  }

  hideMilestoneCampaign() {
    this.confirmModal({
      title: 'Hide Checklist?',
      okText: 'Hide Checklist',
      cancelText: 'Cancel',
      body: 'Are you sure you want to hide the Food Safety Checklist? This cannot be undone.'
    }).then(() => {
      this.organizations.endMilestoneCampaign(this.user.orgContext.id);
      this.growl.success('"My Progress" tab successfully hidden.');
      this.$state.go('user.dashboard');
    });
  }
}

module.exports = Controller;
