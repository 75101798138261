module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.operations',
    ngModule = angular.module(moduleName, [
      require('./sops')(moduleName),
      require('./sop_library')(moduleName),
      require('./sopLogs')(moduleName),
      require('./batches')(moduleName),
      require('./equipment')(moduleName),
      require('./logs')(moduleName),
      require('./facility')(moduleName),
      require('./safety_team')(moduleName),
      require('./tests')(moduleName),
      require('./files')(moduleName),
    ]);

  require('./_config')(ngModule);
  require('./_constants')(ngModule);

  return moduleName;
};
