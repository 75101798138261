class Constructor {
  constructor(growl, $log, accessRequests, $q, supplierService, ingredientService, ingredients) {
    'ngInject';

    this.growl = growl;
    this.$log = $log;
    this.accessRequests = accessRequests;
    this.$q = $q;
    this.supplierService = supplierService;
    this.ingredientService = ingredientService;
    this.ingredients = ingredients;
  }

  $onInit() {
    this.records = this.resolve.records;
    this.user = this.resolve.user;
    this.company = this.resolve.company;

    this.mapPromise = this.supplierService.getSupplierMap(this.company.$id);
  }

  import() {
    let errorCount = 0;

    this.importing = true;

    this.$log.info(`Starting an ingredient import with ${this.records.length} records.`, {
      orgId: this.company.$id
    });

    return this.mapPromise.then(externalIdMap => {
      let supplierNumbers = {};
      let newRefs = [];

      externalIdMap = externalIdMap || {};
      return this.$q.all(_.map(this.records, rec => {
        delete rec.index;
        let supplierPromise;

        // Try to assign a temporary supplier number for supplier consolidation
        if (!rec.supplierNumber) {
          rec.supplierNumber = rec.supplierName ?
            rec.supplierName.toLowerCase().replace(/\s/g, '') : null;
        }

        // First check for a cached or existing supplier record (if they included the unique identifier)
        if (rec.supplierNumber) {
          supplierPromise = supplierNumbers[rec.supplierNumber] && this.$q.when(supplierNumbers[rec.supplierNumber]);
          if (!supplierPromise && externalIdMap[rec.supplierNumber]) {
            supplierPromise = this.supplierService.$get(externalIdMap[rec.supplierNumber]);
          }
        }

        // If no supplier info then don't create supplier or supplier ingredient.
        if (!rec.supplierName) {
          return this.ingredientService.$push().then($ingredient => {
            newRefs.push($ingredient.$ref());
            this.addIngredientProperties($ingredient, rec);

            $ingredient.organizationId = this.company.$id;
            return $ingredient.$save();
          });
        }

        // If no existing, add the supplier.
        supplierPromise = supplierPromise ||
          this.supplierService.$push().then($supplier => {
            newRefs.push($supplier.$ref());
            return $supplier;
          }).then($supplier => {
            this.addSupplierProperties($supplier, rec);

            return this.supplierService.saveSupplier(this.user, $supplier, this.company).then(() => $supplier);
          });

        if (rec.supplierNumber) { supplierNumbers[rec.supplierNumber] = supplierPromise; }

        return supplierPromise.then($supplier => {
          if ($supplier.$value === null) {
            return this.$q.reject(`Invalid supplier Id encountered during import: ${$supplier.$id}`);
          }
          // Second add the ingredient
          return this.ingredientService.$push().then($ingredient => {
            newRefs.push($ingredient.$ref());
            this.addIngredientProperties($ingredient, rec);

            $ingredient.organizationId = this.company.$id;
            return $ingredient.$save().then(() => ({$supplier, $ingredient}));
          });
        }).then(saveObj => {
          // Lastly add the supplierIngredient
          return this.ingredients.$pushSupplierIngredient().then($supplierIngredient => {
            newRefs.push($supplierIngredient.$ref());
            this.addSupplierIngredientProperties($supplierIngredient, rec);

            $supplierIngredient.organizationId = this.company.$id;
            $supplierIngredient.supplierId = saveObj.$supplier.$id;
            $supplierIngredient.ingredientId = saveObj.$ingredient.$id;
            return $supplierIngredient.$save()
              .then(() => {
                // Point ingredient and supplier records to this new supplierIngredient.
                saveObj.$ingredient.supplierIngredients = saveObj.$ingredient.supplierIngredients || {};
                saveObj.$ingredient.supplierIngredients[$supplierIngredient.$id] = true;

                saveObj.$supplier.ingredients = saveObj.$supplier.ingredients || {};
                saveObj.$supplier.ingredients[$supplierIngredient.$id] = true;
                return this.$q.all([
                  saveObj.$ingredient.$save(),
                  saveObj.$supplier.$save()
                ]);
              }).then(() => $supplierIngredient);
          });
        }).catch(err => {
          errorCount++;
          this.$log.error('Error occurred during ingredient import.', {
            errStr: this.$log.toString(err),
            recStr: angular.toJson(rec)
          });
        });
      })).then(newIngredients => {
        this.$log.info(`Completed an ingredient import with ${this.records.length} records.`, {
          orgId: this.company.$id,
          errorCount
        });

        this.modalInstance.close({
          newIngredients,
          total: this.records.length,
          errors: errorCount
        });
      }).finally(() => {
        this.importing = false;
        if (newRefs.length) {
          this.$log.info('Refs created from ingredient import', {
            refs: newRefs.join(',')
          });
        }
      });
    });
  }

  addSupplierProperties($supplier, importedRec) {
    _.assign($supplier, this.noUndefined({
      name: importedRec.supplierName,
      externalNumber: importedRec.supplierNumber,
      contactEmail: importedRec.contactEmail,
      contactName: importedRec.contactName,
      contactTitle: importedRec.contactTitle,
      contactPhone: importedRec.phone,
      physicalAddress: importedRec.address,
      physicalAddress2: importedRec.address2,
      city: importedRec.city,
      state: importedRec.state,
      postalCode: importedRec.postalCode,
      country: importedRec.country,
      mailingAddressSameAsPhysical: importedRec.mailingAddressSameAsPhysical,
      mailingAddress: importedRec.mailingAddress,
      mailingAddress2: importedRec.mailingAddress2,
      mailingCity: importedRec.mailingCity,
      mailingState: importedRec.mailingState,
      mailingPostalCode: importedRec.mailingPostalCode,
      mailingCountry: importedRec.mailingCountry
    }));
  }

  addIngredientProperties($ingredient, importedRec) {
    _.assign($ingredient, this.noUndefined({
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      externalNumber: importedRec.ingredientNumber,
      name: importedRec.ingredientName,
      description: importedRec.description,
      ingredientType: 'sourced',
    }));
  }

  addSupplierIngredientProperties($supplierIngredient, importedRec) {
    _.assign($supplierIngredient, this.noUndefined({
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      name: importedRec.supplierName,
      externalNumber: importedRec.productNumber,
      brandName: importedRec.ingredientName,
      ingredientName: importedRec.ingredientName,
      ingredientRisk: importedRec.ingredientRisk,
    }));
  }

  noUndefined(obj) {
    _.each(obj, (val, key) => {
      if (_.isUndefined(val)) {
        obj[key] = null;
      }
    });

    return obj;
  }
}

module.exports = Constructor;
