'use strict';

module.exports = function($log, labTestStatus, notifications, email) {
  'ngInject';

  const vm = this;

  vm.submit = function() {
    let plateCountTest = false, chemicalTest = false, productCharacteristicTest = false;

    if (!vm.isProperties) {
      if (vm.verification.plateCountTest) { plateCountTest = true; }
      if (vm.verification.chemicalTest) { chemicalTest = true; }
    } else {
      productCharacteristicTest = true;
    }

    email.sendTemplate({
      email: 'william@foodready.ai',
      name: 'William',
      subject: 'Laboratory Test Requested'
    }, 'lab_test_request', [
      {'name': 'titleSection', 'content': 'Lab Request for: ' + vm.product.brandName},
      {'name': 'companyName', 'content': vm.productOrganization.companyName},
      {'name': 'clientName', 'content': vm.user.fullName()},
      {'name': 'productDescription', 'content': vm.product.description},
      {'name': 'plateCountTest', 'content': plateCountTest},
      {'name': 'chemicalTest', 'content': chemicalTest},
      {'name': 'plateCountTest', 'content': plateCountTest},
      {'name': 'productCharacteristicTest', 'content': productCharacteristicTest},
      {'name': 'lotNumber', 'content': vm.verification.lotNumber},
      {'name': 'sampleDescription', 'content': vm.verification.sample},
      {'name': 'specialInstructions', 'content': vm.verification.comments}
    ]).catch($log.error);

    vm.verification.labTestStatusId = labTestStatus.TEST_REQUESTED.id;
    vm.verification.bidsRequested = true;
    vm.verification.bidRequestDate = new Date().getTime();

    notifications.postToAllLabs(vm.user, {
      from: vm.user.uid,
      message: 'Lab bid request placed by ' +
      vm.productOrganization.companyName + ' for "' + vm.product.brandName + '".',
      link: {state: 'analysis.lab.tests'}
    });

    vm.verification.$save()
      .then(() => vm.$uibModalInstance.close(vm.verification));
  };

  vm.cancel = function() {
    vm.$uibModalInstance.dismiss('cancel');
  };
};
