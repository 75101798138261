module.exports = function(ngModule) {
  ngModule.component('cfMarketplaceOrderItem', {
    bindings: {
      user: '<',
      $organization: '<',
      $orderItem: '=',
      $sharedMedia: '=',
      $privateMedia: '=',
      service: '<'
    },
    template: require('./marketplace.order-item.template.html'),
    controller: require('./marketplace.order-item.controller.js'),
    controllerAs: 'vm'
  });
};
