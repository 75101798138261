require('./logs.main.template.scss');

module.exports = function(ngModule) {
  ngModule.component('frLogs', {
    bindings: {
      isModal: '<?',
      onClose: '&'
    },
    template: require('./logs.main.template.html'),
    controller: require('./logs.main.controller'),
    controllerAs: 'vm'
  });
};
