'use strict';

module.exports = function(ngModule) {
  ngModule.config(function($stateProvider) {
    'ngInject';

    $stateProvider.state('ingredients.suppliers', {
      url: '/suppliers',
      abstract: true,
      template: '<div ui-view></div>',
      resolve: {
        supplierSearch: (user, SupplierSearch) => new SupplierSearch(user, user.orgContext.id).$initialized()
      }
    }).state('ingredients.suppliers.list', {
      url: '/list',
      template: '<cf-list-suppliers user="$resolve.user" company="$resolve.company" ' +
      'supplier-search="$resolve.supplierSearch" product-constraints="$resolve.productConstraints">' +
        '</cf-list-suppliers>',
      resolve: {
        // For performance reasons we don't want to enter the state with a bunch of suppliers in memory.
        resetSearch: (supplierSearch) => {
          if (supplierSearch.searchResults < 30) { return; }
          supplierSearch.reset();
          return supplierSearch.search(supplierSearch.searchText);
        },
        productConstraints: (constantsService) => constantsService.get('stripe')
          .then(stripeConstants => stripeConstants.constraints),
      },
      data: {
        titleBar: {
          helpModal: require('../_modals/help/suppliers.help.template.html')
        }
      }
    }).state('ingredients.suppliers.supplier', {
      abstract: true,
      url: '/supplier/:supplierId',
      template: '<cf-supplier supplier="$resolve.supplier"></cf-supplier>',
      resolve: {
        supplier: ($stateParams, suppliers, $firebaseObject, fbutil) => {
          if ($stateParams.supplierId === 'new') {
            return $firebaseObject(fbutil.ref('suppliers').push()).$loaded();
          }
          return suppliers.$get($stateParams.supplierId);
        }
      }
    }).state('ingredients.suppliers.supplier.overview', {
      url: '/overview',
      template: '<cf-supplier-overview supplier="$resolve.supplier" supplier-org-ids="$resolve.supplierOrgIds" ' +
      'user="$resolve.user" company="$resolve.company" supplier-search="$resolve.supplierSearch">' +
      '</cf-supplier-overview>',
      resolve: {
        supplierOrgIds: (user, SupplierSearch) => new SupplierSearch(user, user.currentOrgContext().id).search()
          .then(results => {
            return _.mapValues(_.keyBy(results, r => r.organizationId), () => true);
          })
      }
    }).state('ingredients.suppliers.supplier.address', {
      url: '/address',
      template: '<cf-supplier-address supplier="$resolve.supplier"></cf-supplier-overview>'
    }).state('ingredients.suppliers.supplier.contact', {
      url: '/contact',
      template: '<cf-supplier-contact supplier="$resolve.supplier"></cf-supplier-contact>'
    }).state('ingredients.suppliers.supplier.audit', {
      url: '/audit',
      template: '<cf-supplier-audit supplier="$resolve.supplier" company="$resolve.company"></cf-supplier-audit>'
    }).state('ingredients.suppliers.supplier.files', {
      url: '/files',
      template: '<cf-supplier-files supplier="$resolve.supplier" organization="$resolve.company" ' +
      'files="$resolve.files" user="$resolve.user" ></cf-supplier-files>',
      resolve: {
        files: (supplier, suppliers) => suppliers.$files(supplier.$id)
      }
    }).state('ingredients.suppliers.supplier.ingredients', {
      url: '/ingredients',
      template: '<cf-list-supplier-ingredients supplier="$resolve.supplier" company="$resolve.company" ' +
      'ingredients="$resolve.ingredients" user="$resolve.user" ></cf-list-supplier-ingredients>',
      resolve: {
        ingredients: (supplierService, supplier) =>
          supplierService.getIngredients(supplier.owningOrg, supplier.$id).then(result => _.values(result))
      }
    });
  });
};