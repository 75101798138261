class Constructor {
  constructor($timeout, preventDirtyNav, growl, $log, sopService, $state, confirmModal, $scope,
              $q, $uibModal, utils, $stateParams, organizations, batchService,
              users, moment, CF_CLAIMS, products) {
    'ngInject';

    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.growl = growl;
    this.$log = $log;
    this.sopService = sopService;
    this.$state = $state;
    this.confirmModal = confirmModal;
    this.$scope = $scope;
    this.$q = $q;
    this.$uibModal = $uibModal;
    this.utils = utils;
    this.$stateParams = $stateParams;
    this.organizations = organizations;
    this.batchService = batchService;
    this.users = users;
    this.moment = moment;
    this.CF_CLAIMS = CF_CLAIMS;
    this.products = products;
  }

  $onInit() {
    this.instructionsOpen = true;

    this.heading = `${this.$sopLog.$value === null ? 'New' : 'Edit'} SOP Log`;
    this.subHeading = this.$sop.title;

    if (this.$sopLog.batchId) {
      this.batchService.getBatchNumber(this.$sopLog.batchId).then(batchNumber => {
        this.batchNumber = batchNumber;
      });
    }
    if (_.get(this.$sop, 'metadata.productId')) {
      this.productSop = true;
      this.products.getBrandName(this.$sop.metadata.productId).then(name => {
        this.subHeading = `${this.subHeading} (${name})`;
      });
    }
  }
}

module.exports = Constructor;
