module.exports = function(ngModule) {
  ngModule.factory('confirmDeleteModal', function($uibModal) {
    'ngInject';

    return function(itemName, opts) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('../_modals/item-delete.template.html'),
        controller: function($scope, $uibModalInstance, itemName) {
          $scope.name = itemName;
          $scope.titleVerb = opts && opts.titleVerb || 'Delete';
          $scope.confirmText = opts && opts.confirmText || 'Delete Item';
          $scope.rejectText = opts && opts.rejectText || 'Cancel';
          $scope.headerHtml = opts && opts.headerHtml || '';
          $scope.body = opts && opts.body || 'Are you sure you want to permanently delete "' +
            itemName + '"? This cannot be undone.';

          $scope.ok = function() {
            $uibModalInstance.close();
          };

          $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
        },
        resolve: {
          itemName: function() { return itemName; }
        }
      }).result;
    };
  });

  ngModule.factory('confirmModal', function($uibModal, $state) {
    'ngInject'

    return function(options) {
      return $uibModal.open({
        backdrop: 'static',
        controllerAs: 'vm',
        bindToController: true,
        size: options.size || 'md',
        template: require('../_modals/confirm.template.html'),
        controller: function($uibModalInstance, drift) {
          let vm = this;

          _.extend(vm, options);
          vm.remember = false;

          vm.drift = drift;

          vm.ok = function() {
            $uibModalInstance.close(vm.remember);
          };

          vm.go = function(stateName, parms) {
            $uibModalInstance.close('cancel');
            $state.go(stateName, parms);
          };

          vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };

          vm.dismiss = function() {
            $uibModalInstance.dismiss();
          };
        }
      }).result;
    };
  });

  ngModule.factory('choiceModal', function($uibModal) {
    'ngInject'

    return function(options) {
      return $uibModal.open({
        backdrop: 'static',
        controllerAs: 'vm',
        bindToController: true,
        template: require('../_modals/choice.template.html'),
        controller: function($uibModalInstance) {
          let vm = this;

          _.extend(vm, options);
          vm.remember = false;

          vm.ok = function(chosen) {
            $uibModalInstance.close(chosen);
          };

          vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }
      }).result;
    };
  });

  ngModule.factory('cfInputModal', function($uibModal) {
    'ngInject'

    return function(options) {
      return $uibModal.open({
        backdrop: 'static',
        controllerAs: 'vm',
        bindToController: true,
        template: require('../_modals/cf-input.template.html'),
        controller: function($uibModalInstance) {
          let vm = this;

          vm.$onInit = function() {
            vm.ngModel = vm.initial;
          };

          _.extend(vm, options);

          vm.ok = function(chosen) {
            $uibModalInstance.close(chosen);
          };

          vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }
      }).result;
    };
  });

  ngModule.factory('selectModal', function($uibModal) {
    'ngInject'

    return function(options) {
      return $uibModal.open({
        backdrop: 'static',
        controllerAs: 'vm',
        bindToController: true,
        template: require('../_modals/select.template.html'),
        controller: function($uibModalInstance) {
          let vm = this;

          _.extend(vm, options);

          vm.ok = function(chosen) {
            $uibModalInstance.close(chosen);
          };

          vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }
      }).result;
    };
  });

  ngModule.factory('datePickerModal', function($uibModal) {
    'ngInject'

    return function(options) {
      return $uibModal.open({
        backdrop: 'static',
        controllerAs: 'vm',
        bindToController: true,
        template: require('../_modals/date-picker.template.html'),
        controller: function($uibModalInstance) {
          let vm = this;

          _.extend(vm, options);

          vm.ok = function(chosen) {
            $uibModalInstance.close(chosen);
          };

          vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }
      }).result;
    };
  });

  ngModule.factory('displayNotifyCoworkerModal', function($uibModal, organizations) {
    'ngInject'

    return function(options) {
      return organizations.getMembersWithClaim(options.user.orgContext.id, options.claim)
        .then(members => {
          members = _.filter(members, member => member.$id !== options.user.uid);
          if (!members || !members.length) { return; }
          return $uibModal.open({
            component: 'cfNotifyCoworkerModal',
            resolve: {
              user: () => options.user,
              title: () => options.title,
              message: () => options.message,
              notification: () => options.notification,
              members: () => _.map(members, member => ({name: member.name, value: member.$id}))
            }
          }).result.result;
        });
    };
  });

  ngModule.factory('featureIntroModal', function($uibModal, $q, utils) {
    'ngInject'

    /**
     * Show a feature introduction modal.
     * @param {object} user The logged in user
     * @param {object} options Modal options
     * @param {string} options.helpTitle The title for the modal
     * @param {string} options.helpText The help text displayed to the user in the modal.
     * @param {string} options.helpVideoUrl If present, the video will be embedded in the help.
     * @param {string} options.tipName The tip name used to control whether the user already saw the intro.
     * @return {Promise} A promise that is resolved when the modal is closed
     */
    return function(user, options) {
      if (user.isTipHidden(options.tipName)) { return $q.resolve(); }

      return $uibModal.open({
        component: 'cfProgressIntroModal',
        resolve: {
          title: () => options.helpTitle,
          text: () => options.helpText,
          videoUrl: () => options.helpVideoUrl
        }
      }).result
      .then(noShowAgain => {
        if (noShowAgain) {
          user.setTipHidden(options.tipName);
        }
      })
      .catch(err => utils.defaultErrorHandler(err, 'An error occurred showing the feature introduction.'));
    };
  });

  ngModule.factory('createLinkModal', function($uibModal) {
    'ngInject'

    /**
     * Display a modal to present a url to the user (for sharing with someone) and give option to email.
     * @param {object} user The logged in user
     * @param {object} options Modal options
     * @param {string} options.title The title for the modal
     * @param {string} options.instructionsHtml The help text displayed to the user in the modal.
     * @param {object} options.contact A optional contact to prefill.
     * @param {function} options.generateLinkFn A function to generate the link.
     * @param {function} options.emailLinkFn A optional function to email the link.
     * @return {Promise} A promise that is resolved when the modal is closed
     */
    return function(user, options) {
      return $uibModal.open({
        component: 'cfCreateLinkModal',
        resolve: {
          title: () => options.title,
          contact: () => options.contact,
          instructionsHtml: () => options.instructionsHtml,
          link: () => options.generateLinkFn(),
          emailLinkFn: () => options.emailLinkFn,
        }
      }).result;
    };
  });
};
