module.exports = function(ngModule) {
  ngModule.config(
    function($stateProvider) {
      'ngInject';

      $stateProvider.state('recall.consignees', {
        url: '/consigneeList',
        template: '<div ui-view></div>',
        abstract: true,
        resolve: {
          customers: (company, recallService) => recallService.$customers(company.$id)
        },
        controllerAs: 'vm'
      }).state('recall.consignees.list', {
        url: '/list',
        template: '<cf-recall-plan-consignee-list $customers="$resolve.customers" user="$resolve.user" ' +
          'customer-types="$resolve.customerTypes"></cf-recall-plan-consignee-list>',
        controllerAs: 'vm',
        data: {
          titleBar: {
            helpModal: require('../_modals/help/consignee-list-help.view.html')
          }
        }
      }).state('recall.consignees.edit', {
        url: '/:customerId',
        template: '<cf-recall-plan-edit-customer $customer="$resolve.$customer" user="$resolve.user" ' +
          'customer-types="$resolve.customerTypes">' +
          '</cf-recall-plan-edit-customer>',
        resolve: {
          $customer: (company, recallService, $stateParams) =>
            recallService.$customer(company.$id, $stateParams.customerId)
        },
        controllerAs: 'vm'
      });
    }
  );
};
