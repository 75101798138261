class Constructor {
  constructor($uibModal, $rootScope, $state, featureIntroModal) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.featureIntroModal = featureIntroModal;
  }

  $onInit() {
    this.$rootScope.$on('$stateChangeSuccess', (event, toState) => {
      this.options = toState.data && toState.data.titleBar;
    });

    this.featureIntroModal(this.user, {
      title: 'Begin a Recall Plan',
      helpTitle: 'Add a Recall Plan',
      tipName: 'featureIntro_recallPlan',
      helpText: 'Despite a proper food safety plan, you may find yourself swept into a recall. ' +
        'Advance planning for recalls will save lives and will make the difference in the survival of your company.',
      helpVideoUrl: ''
    });
  }

  help() {
    this.$uibModal.open({
      template: this.options.helpModal,
      controller: function($scope, $uibModalInstance) {
        $scope.ok = function() {
          $uibModalInstance.close();
        };

        $scope.cancel = function() {
          $uibModalInstance.close();
        };
      }
    });
  };
}

module.exports = Constructor;
