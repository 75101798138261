class Controller {
  constructor($log, marketplaceService, orgInteractionService, growl) {
    'ngInject';

    this.$log = $log;
    this.marketplaceService = marketplaceService;
    this.orgInteractionService = orgInteractionService;
    this.growl = growl;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.services = this.resolve.services;
    this.organizationId = this.resolve.organizationId;
    this.clientContact = {};
    this.servicesArray = _.map(this.services, (value, key) =>
      _.assign({}, value, {key: key}));

    _.remove(this.servicesArray, {enabled: false});
    this.chunkedServices = _.chunk(this.servicesArray, 2);
  }

  ok() {
    let services = _.flatten(this.chunkedServices);

    _.remove(services, (svc) => !svc.selected);
    this.sending = true;
    this.orgInteractionService.sendClientInvite(this.user, this.organizationId,
      _.keyBy(services, 'key'), this.clientContact)
      .then(newTokenId => this.modalInstance.close(newTokenId))
      .catch((err) => {
        this.sending = false;
        this.$log.error(err);
        this.growl.error('Unable to send the new client invitation. Please try ' +
          'again or contact customer support for assistance');
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  atLeastOneSvcSelected() {
    return _.some(this.servicesArray, {selected: true});
  }
}

module.exports = Controller;
