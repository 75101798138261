class Constructor {
  constructor($state, $uibModal, $log, growl, utils) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.growl = growl;
    this.utils = utils;
  }

  $onInit() {
  }

  onClick() {
    this.$log.info('User clicked on checklist item.');
    this.$uibModal.open({
      component: 'cfProgressIntroModal',
      resolve: {
        okText: () => this.item.helpOkText,
        title: () => this.item.helpTitle,
        text: () => this.item.helpText,
        videoUrl: () => this.item.helpVideoUrl
      }
    }).result.then(() => {
      if (this.item.toState) {
        this.$state.go(this.item.toState, this.item.toStateParams);
      } else if (this.item.toModalComponent) {
        return this.$uibModal.open({
          component: this.item.toModalComponent,
          resolve: this.item.toModalResolve
        }).result.then(() => {
          if (_.isFunction(this.item.onModalClosed)) { this.item.onModalClosed(); }
        });
      } else if (_.isFunction(this.item.onSubmit)) {
        return this.item.onSubmit();
      }
    }).catch(err => {
      if (this.utils.isModalDismissalByUser(err)) { return; }
      this.$log.error('An error occurred navigating to a user checklist item', this.$log.toString(err));
      this.growl.error('An error occurred. Please try again or contact customer support.');
    });
  }
}

module.exports = Constructor;
