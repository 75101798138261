module.exports = function(authorization, CF_ROLES) {
  'ngInject';

  const vm = this;

  vm.addOrg = function(org) {
    authorization.assignOrgToOrganization(vm.user.uid, org.$id, vm.team.$id, CF_ROLES.ANALYST);
  };

  vm.removeOrg = function(org) {
    authorization.removeOrgFromOrganization(org.$id, vm.team.$id);
  };
};
