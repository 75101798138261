'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfQuickAddSupplierIngredient', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./ingredients.quick-add-supplier-ingredient.template.html'),
    controller: require('./ingredients.quick-add-supplier-ingredient.controller.js'),
    controllerAs: 'vm'
  });
};
