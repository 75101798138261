module.exports = function(ngModule) {
  ngModule.factory('UsersSearch', function(Search) {
    'ngInject';

    class UserSearch extends Search {
      constructor(user, options) {
        options = options || {};
        super(user, {
          uri: 'search/user',
          max: options.max || 300, // Maximum number of recs to pull back
          context: user.isCfAdmin() ? undefined : {organizationId: user.currentOrgContext().id},
          type: 'user',
          pageLimit: 30,    // Number of recs to show per page
          body: {
            sort: [
              {'lastName.keyword': {order: 'asc'}}
            ],
            query: {}
          }
        }, 'users', false);
        this.user = user;
        this.setOrg();
      }

      getOrgsFilterObject() {
        return {
          nested: {
            path: 'reviews',
            query: {
              nested: {
                path: 'reviews.organizations',
                query: {
                  bool: {
                    must: []
                  }
                }
              }
            }
          }
        };
      }

      setOrg(orgId) {
        if (!this.user.isCfAdmin()) {
          this.orgFilter = this.user.currentOrgContext().id;
        } else if (this.user.isCfAdmin() && orgId) {
          this.orgFilter = orgId;
        }
      }

      setRoleFilter(role) {
        this.roleFilter = role;
      }

      setInverseRoleFilter(role) {
        this.inverseRoleFilter = role;
      }

      // the main Org is set on the organizationId property (vs. the organizations array)
      setMainOrgFilter(orgId) {
        this.orgFilter = undefined;
        this.mainOrgFilter = orgId;
        this.options.context.organizationId = orgId;
      }

      searchNameEmail(nameText) {
        this.nameSearch = !nameText ? null : {
          bool: {
            should: [{
              'multi_match': {
                fields: ['firstName', 'lastName'],
                type: 'phrase_prefix',
                query: nameText.toLowerCase()
              }
            }, {
              'term': {
                email: nameText
              }
            }],
            'minimum_should_match': 1
          }
        };
      }

      search() {
        this.queryData.body.query = {
          bool: {
            must: []
          }
        };
        if (this.mainOrgFilter) {
          this.queryData.body.query.bool.must.push({
            nested: {
              path: 'reviews',
              query: {
                term: {
                  'reviews.organizationId': this.mainOrgFilter
                }
              }
            }
          });
        }
        if (this.orgFilter || this.roleFilter || this.inverseRoleFilter) {
          let filter = this.getOrgsFilterObject();

          if (this.orgFilter) {
            filter.nested.query.nested.query.bool.must.push({
              term: {
                'reviews.organizations.id': this.orgFilter
              }
            });
          }

          if (this.roleFilter) {
            filter.nested.query.nested.query.bool.must.push({
              term: {
                'reviews.organizations.role': this.roleFilter
              }
            });
          }

          if (this.inverseRoleFilter) {
            filter.nested.query.nested.query.bool.mustNot = [{
              term: {
                'reviews.organizations.role': this.inverseRoleFilter
              }
            }];
          }

          this.queryData.body.query.bool.must.push(filter);
        }

        if (this.nameSearch) {
          this.queryData.body.query.bool.must.push(this.nameSearch);
        }

        return super.search();
      }
    }

    return UserSearch;
  });
};
