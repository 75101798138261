module.exports = function(ngModule) {
  require('./cf_input')(ngModule);
  require('./cf_input_group')(ngModule);
  require('./cf_select_array')(ngModule);
  require('./cf_cc_number_input')(ngModule);
  require('./cf_cc_cvv_input')(ngModule);
  require('./cf_cc_expiry_input')(ngModule);
  require('./cf_save_btn')(ngModule);
  require('./cf_search_input')(ngModule);
  require('./cf_textarea')(ngModule);
  require('./cf_checkbox')(ngModule);
  require('./cf_yes_no')(ngModule);
  require('./cf_currency_input')(ngModule);
  require('./cf_wysiwyg_compressed')(ngModule);
  require('./cf_barcode_input')(ngModule);
  require('./cf_product_chooser')(ngModule);
  require('./cf_timespan_picker')(ngModule);
  require('./cf_date')(ngModule);
  require('./cf_info_icon')(ngModule);
  require('./cf_draggable')(ngModule);
  require('./cf_step_chooser')(ngModule);
  require('./cf_upload_file_btn')(ngModule);
  require('./cf_input_with_unit')(ngModule);
};
