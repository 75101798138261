'use strict';

module.exports = function(S3, organizations) {
  'ngInject';

  var vm = this;

  vm.$onInit = function() {
    vm.profiles = {};
    _.each(vm.channels, channel => {
      if (vm.subscriptions.$indexFor(channel.$id) > -1) {
        channel.subscribed = true;
      }
      if (vm.profiles[channel.teamId] || !channel.teamId) { return; }

      vm.profiles[channel.teamId] = {};
      organizations.getProfile(channel.teamId).then(result => {
        vm.profiles[channel.teamId] = result;
      });
    });
  };

  vm.search = {
    disabled: '!true'
  };

  vm.toggleSubscribed = function(channel) {
    if(channel.subscribed) {
      vm.subscriptions.$ref().child(channel.$id).set(true);
      vm.user.subscriptions = vm.user.subscriptions || {};
      vm.user.subscriptions[channel.$id] = true;
    } else {
      var index = vm.subscriptions.$indexFor(channel.$id);

      vm.subscriptions.$remove(index);
      delete vm.user.subscriptions[channel.$id];
    }
  };

  vm.logoUrl = function(team) {
    if (team.logo) {
      return S3.getPublicFileUrl(team.logo.key);
    }
  };
};
