class Constructor {
  constructor(billing, growl, $log, states, $q, marketing) {
    'ngInject';

    this.billing = billing;
    this.growl = growl;
    this.$log = $log;
    this.states = states();
    this.$q = $q;
    this.marketing = marketing;
    this.updating = false;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.activating = this.resolve.activating;
    this.intro = this.resolve.intro;

    if (!this.user.customerRec.sources.data.length) {
      this.newCard = {};
    } else {
      _.each(this.user.customerRec.sources.data, (source) => {
        source.description = source.description || this.billing.getSourceDescription(source);
      });
    }
    this.title = this.activating ? 'Activate <b>' + this.user.subscription.plan.name + '</b>' :
      'Change Default Payment Method';
    this.currentSource = this.user.customerRec.default_source;
    this.sourceId = this.currentSource;
  }

  updateDefaultSource(sourceId) {
    return this.billing.updateCustomer(this.user.orgContext.id, this.user.customerRec.id, {
      // eslint-disable-next-line camelcase
      default_source: sourceId
    }).then(result => {
      this.user.customerRec = result.data;
    });
  }

  submit(newCard) {
    if (this.activating && this.currentSource && this.sourceId === this.currentSource) {
      return this.activateSameSource();
    }

    this.updating = true;
    let newSourcePromise = this.$q.when(newCard ?
      this.billing.addCard(this.user.organizationId, this.user.customerId,
        this.billing.dehydrateCard(this.newCard), {nickname: newCard.nickname})
      .then(source => source.id) : this.sourceId);

    newSourcePromise
      .then(sourceId => this.updateDefaultSource(sourceId))
      .then(() => {
        if (!this.activating) { return; }
        return this.billing.updateSubscription(this.user.orgContext.id, this.user.subscription.id, {
          // eslint-disable-next-line camelcase
          trial_end: 'now'
        }).then(subscription => {
          this.$log.info('User activated subscription: ' + _.get(this.user, 'subscription.plan.name'), null,
            {notify: true});
          this.user.subscription = subscription;
        });
      })
      .then(() => this.modalInstance.close())
      .catch(err => {
        this.$log.error('Error occurred changing the default source', err);
        this.growl.error('An error occurred ' +
          (this.activating ? 'activating your subscription' : 'changing the default source') +
          '. If this continues, please contact customer support.');
      }).finally(() => {
        this.updating = false;
      });
  }

  activateSameSource() {
    this.updating = true;
    return this.billing.updateSubscription(this.user.orgContext.id, this.user.subscription.id, {
      // eslint-disable-next-line camelcase
      trial_end: 'now'
    })
    .then(subscription => {
      this.$log.info('User activated subscription: ' + _.get(this.user, 'subscription.plan.name'), null,
        {notify: true});
      this.user.subscription = subscription;
      this.modalInstance.close();
    })
    .catch(err => {
      this.$log.error('Error occurred activating a subscription.', err);
      this.growl.error('An error occurred activating your subscription. If this continues, ' +
        'please contact customer support.');
    })
    .finally(() => {
      this.updating = false;
    });
  }
}

module.exports = Constructor;
