class Controller {
  constructor($log, growl, planTypes, ai, $state) {
    'ngInject';

    this.$log = $log;
    this.growl = growl;
    this.planTypes = planTypes;

    this.selectedCategory = null;
    this.selectedSubCategory = null;
    this.ai = ai;
    this.$state = $state;
  }

  $onInit() {
    this.foodCategories = _.cloneDeep(this.foodCategories);
    this.save = _.debounce(this._save, 2000);
    const onPayAsGo = this.user.onPayAsGoPlan() && !this.user.isCfAdmin();

    // Prevent gaming the system by unlocking a plan and then reusing that plan record for multiple plans.
    this.disablePlanNameChange = onPayAsGo && (this.product.chargeId || this.product.unlockedBy);
    _.each(this.foodCategories, category => {
      category.subCategories = _.map(
        category.subCategories,
        (subCategory, id) => _.assign(subCategory, {id: id})
      );
    });

    this.selectedCategory = _.find(this.foodCategories, {
      id: this.product.categoryId
    });

    this.selectedSubCategory = _.find(
      _.get(this.selectedCategory, 'subCategories', []),
      {id: this.product.subCategoryId}
    );
  }

  categorySelected($item) {
    this.product.categoryId = $item.id;
    this.selectedSubCategory = null;
    this.infoChanged();
  }

  subCategorySelected($item) {
    this.product.subCategoryId = $item.id;
    this.infoChanged();
  }

  infoChanged() {
   this.save();
  }

  _save() {
    this.saving = true;
    this.product.updatedOn = firebase.database.ServerValue.TIMESTAMP;
    this.product.updatedBy = this.user.uid;
    if(!this.product.brandName) {
      return this.growl.error('Brand name is required.');
    }
    return this.product.$save()
      .then(() => this.lastSaved = this.product.updatedOn)
      .catch(err => {
        this.growl.error('Unable to save product information.');
        this.$log.error(err);
      })
      .finally(() => this.saving = false);
  }

  async generatePlan() {
    function toCamelCase(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+|\W+)/g, function(match, index) {
        if (+match === 0) return ""; // Remove non-alphanumeric characters
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    }

    function extractHACCPTable(text) {
      // Regex to match the table
      const tableRegex = /\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|/g;

      // Find table header and rows
      const tableHeaderMatch = text.match(/^\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|([^|]+)\|$/m);
      const tableRowsMatch = [...text.matchAll(tableRegex)].slice(1); // Remove the first match which is the header

      if (!tableHeaderMatch || tableRowsMatch.length === 0) {
        console.error("No valid table found in the text.");
        return null;
      }
      // Extract header columns and convert to camel case
      const headers = tableHeaderMatch.slice(1).map(header => toCamelCase(header.trim()));

      // Extract rows data
      const rows = [];
      tableRowsMatch.forEach((match, i) => {
        if(i !== 0) {
          const row = {};
          headers.forEach((header, index) => {
            row[header] = match[index + 1].trim();
          });
          rows.push(row);
        }
      });

      return JSON.stringify(rows, null, 2);
    }
    // declare all characters
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    function generateString(length) {
      let result = '';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    }

    function convertHACCPJsonToGoJS(haccpJson) {

      const processSteps = {};
      const hazards = {};
      const controls = {};
      const processLinks = {};

      haccpJson.forEach((step, i) => {
        const processStepId = generateString(5);
        const hazardId = generateString(5);
        const controlId = generateString(5);
        processSteps[processStepId] = {
          name: step.processStep,
          loc: `50 ${i * 100}`,
        };

        hazards[hazardId] = {
          introductionStep: processStepId,
          isSignificant: step.cCP?.toLowerCase() === 'yes',
          type: step.hazardType?.toLowerCase(),
          name: step.hazard,
        };

        controls[controlId] = {
          stepId: processStepId,
          measures: step.controlMeasure,
          type: step.cCP?.toLowerCase() === 'yes'? 'process' : 'other',
          hazards: {
            [hazardId]: true,
          }
          // procedure:  {
          //   correctiveAction: step.correctiveAction,
          //   instructions: step.monitoring + '\n' + step.criticalLimits,
          //   verification: step.verification,
          // }
        };
      });

      const processStepIds = Object.keys(processSteps);
      processStepIds.forEach((processStepId, i) => {
        const processLinkId = generateString(5);
        if (i < processStepIds.length - 1) {
          processLinks[processLinkId] = {
            from: processStepId,
            to: processStepIds[i + 1],
          };
        }
      });

      return {
        processSteps,
        processLinks,
        controls,
        hazards
      };
    }

    const planDetails = `${this.selectedCategory?.name? 'Category: ' + this.selectedCategory.name : ''} 
    ${this.selectedSubCategory?.name? 'Sub Category: ' + this.selectedSubCategory.name : ''} 
    ${this.product.intendeduse? 'Intended use: ' + this.product.intendeduse : ''} 
    ${this.product.packagingtype? 'Packaging type: ' + this.product.packagingtype : ''} 
    ${this.product.processDescription? 'Process Description: ' + this.product.processDescription : ''} 
    ${this.product.specialdistribution? 'Special Distribution: ' + this.product.specialdistribution : ''} 
    ${this.product.storageconditions? 'Storage Conditions: ' + this.product.storageconditions : ''}`;
    const { data } = await this.ai.answer(`write a HACCP plan for ${ this.product.brandName } ${ planDetails }. 
    Only respond with one table in following format:\n
     Table headings: Process Step,Hazard, Hazard type,Control Measure,CCP,Critical Limits,Monitoring,Corrective Action,Verification.\n
     note: CCP is YES or NO only. Only use short for hazard type is Biological (bio), Chemical(chem) or Physical(phys)` );

    const haccpPlan = extractHACCPTable(data);
    const haccpJson = convertHACCPJsonToGoJS(JSON.parse(haccpPlan));
    this.product.processSteps = haccpJson.processSteps;
    this.product.processLinks = haccpJson.processLinks;
    this.product.controls = haccpJson.controls;
    this.product.hazards = haccpJson.hazards;
    this.product.isAIPlan = true;
    await this._save();
    this.$state.go('products.edit', {productId: this.product.$id}, {reload: true});
  }
}

module.exports = Controller;
