class CfInput {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-wysiwyg-compressed.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',          // The form control model reference
      label: '@',            // The label text
      helpText: '@',         // Optional subscript helper text
      popoverPlacement: '@', // The placement of the popover help text
      popoverHelpText: '@',  // Optional question mark icon to right of label with popover helper text
      isRequired: '=',       // Is the field required (true / false)
      isReadonly: '=',       // Is the field readonly (true / false)
      isInvalid: '=',        // Is the field invalid (true / false)
      includeIndent: '=',    // Include indent tools
      includeHtml: '=',      // Include html menu
      invalidText: '@',      // The text to display if the field is invalid.
      placeholder: '@',      // Input placeholder
      textAreaClass: '@',     //  Set class for text-area in wysiwyg,
      organization: '<',
      remoteDirectory: '@',
      minHeight: '<',
      maxHeight: '<',
      hideToolbar: '=',
      approvalTemplate: '<',
      sop: '<',
      user: '<'
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller(S3) {
    'ngInject';
    let vm = this;

    vm.$onInit = function() {
      let minHeight = vm.minHeight || 400, maxHeight = vm.maxHeight || 500;
      let toolbar;
      if(vm.hideToolbar) toolbar = false;
      else {
        toolbar = `
    undo redo bold italic underline styleselect fontsizeselect forecolor backcolor alignleft aligncenter 
    alignright alignjustify table numlist bullist removeformat searchreplace fullscreen preview`;
      }

      let templates = vm.approvalTemplate || null;
      let organizationLogo = vm.organization && vm.organization.logo ?
        `${vm.organization.logo.url}/${vm.organization.logo.key}?${new Date().getTime()}` : '';
      let organizationName = vm.organization && vm.organization.companyName ?
        vm.organization.companyName : '';
      let title = vm.sop && vm.sop.title ? vm.sop.title : 'TITLE';
      let authorName = vm.user && vm.user.name ? vm.user.name : '';

      vm.tinymceOptions = {
        plugins: 'link code lists autoresize powerpaste fullscreen table template advlist quickbars ' +
          'insertdatetime searchreplace preview',
        contextmenu: 'link paste',
        toolbar,
        branding: false,
        min_height: minHeight,
        max_height: maxHeight,
        menubar: true,
        insertdatetime_dateformat: '%D',
        fullscreen_native: true,
        quickbars_insert_toolbar: false,
        content_style: `body { font-family: Lato,sans-serif; font-size: 14px; fontWeight: 400; line-height: 20px; }`,
        quickbars_selection_toolbar: 'bold italic underline | formatselect | bullist numlist | blockquote quicklink',
        template_replace_values: {
          page: '01',
          version: '1.0',
          title,
          organizationLogo,
          organizationName,
          authorName
        },
        template_preview_replace_values: {
          page: '01',
          version: '1.0',
          title,
          organizationLogo,
          organizationName,
          authorName
        },
        templates
        // paste_retain_style_properties: 'color text-transform font-weight',
        // paste_webkit_styles: 'color text-transform font-weight'
        // image_file_types: 'jpg,svg,webp,png',
        // images_upload_handler: (blobInfo, success, failure) => {
        //   vm.uploadImageToS3(blobInfo).then((uploadResult)=>{
        //     let key = vm.escapeKey(uploadResult.key);
        //
        //     success(`/api/s3/organization/${vm.organization.$id}/${key}/file`);
        //   }).catch(()=>{
        //     failure('Error while adding image');
        //   });
        // }
        //
      };

      if(vm.organization && vm.remoteDirectory) {
        vm.tinymceOptions.plugins += ' image';
        vm.tinymceOptions.toolbar += ' image ';
      }
      if(templates) {
        vm.tinymceOptions.toolbar += ' template ';
      }
      if (vm.includeHtml) {
        vm.tinymceOptions.toolbar += '| code';
      }
    };

    // vm.uploadImageToS3 = function(file) {
    //   let fileName = file.name() + '-' + Date.now().toString();
    //
    //   return S3
    //     .uploadOrgFile(vm.organization.$id, vm.remoteDirectory + '/' + fileName,
    //       file.blob());
    // };

    vm.escapeKey = function(key) {
      return key.replace(/\//g, '%2F');
    };
  }

  link(scope) {
    let vm = scope.vm;

    vm.invalidText = vm.invalidText || 'Please enter a valid value.';
  }
}

module.exports = CfInput;
