class Constructor {
  constructor(accessRequests, $log, growl, $timeout, miniTourService, supplierIngredientService, utils,
              supplierService, choiceModal) {
    'ngInject';
    
    this.accessRequests = this.accessRequests;
    this.$log = $log;
    this.growl = growl;
    this.$timeout = $timeout;
    this.miniTourService = miniTourService;
    this.supplierIngredientService = supplierIngredientService;
    this.utils = utils;
    this.supplierService = supplierService;
    this.choiceModal = choiceModal;
  }

  $onInit() {
    this.supplierId = this.notification.metaData.supplierId;
    this.supplierIngredientId = this.notification.metaData.supplierIngredientId;
    this.requestorName = this.notification.metaData.requestorName;
    this.productBrandName = this.notification.metaData.productBrandName;

    this.$timeout(() => {
      this.$timeout(() => this.miniTourService.enqueueTour(this.user, {
        id: 'supRemind',
        selector: '#supRemind',
        placement: 'left',
        title: `${this.requestorName} Sent a File Reminder`,
        contentHtml: this.requestorName + ' sent you a reminder to upload files that they need from you for their ' +
        'food safety plan. Click the notification to be taken to the file upload page for ' + this.productName + '. ' +
        'These reminders may be sent periodically until you ' +
        '<ul>' +
        '<li>Upload all requested files.</li>' +
        '<li>Click <b>Mark Complete for Product</b> when you finished uploading all files that you ' +
        'intend to upload for the product, even if ' + this.requestorName + ' requested more.</li>' +
        '<li>Click <b>Stop ALL Reminders</b> if you want to opt out of these reminders from ' + this.requestorName +
        '.</li>' +
        '</ul>'
      }), 400);
    });

  }

  stopForProduct() {
    this.supplierIngredientService.stopReminders(this.supplierIngredientId)
      .then(() => this.onDelete())
      .then(() => {
        this.growl.success('File requirements completed successfully.');
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred completing the file requirements.'));
  }

  stop(event) {
    event.stopPropagation();
    this.choiceModal({
      title: 'Turn Off File Reminders?',
      body: `Would you like to turn off reminders from ${this.requestorName} for ALL products or just ${this.productBrandName}?`,
      choice1Text: 'ALL Products',
      choice2Text: 'Single Product'
    }).then(choice => {
      let stopPromise = choice === 1 ? this.supplierService.stopReminders(this.supplierId) :
        this.supplierIngredientService.stopReminders(this.supplierIngredientId);

      stopPromise
        .then(() => this.onDelete())
        .then(() => {
          this.growl.success(`You successfully opted out of reminders from ${this.requestorName}.`);
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred opting out of file reminders.'));
    });
  }
}

module.exports = Constructor;
