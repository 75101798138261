class Controller {
  constructor($state, $uibModal, $log, growl, confirmDeleteModal, authorization, utils, recallEventsService) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.growl = growl;
    this.confirmDeleteModal = confirmDeleteModal;
    this.authorization = authorization;
    this.utils = utils;
    this.recallEventsService = recallEventsService;
  }

  $onInit() {
  }

  edit(notification) {
    this.$uibModal.open({
      component: 'cfRecallEventsNotification',
      controllerAs: 'vm',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        recallNotification: () => notification ?
          this.recallEventsService.$recallNotification(this.$event, notification.$id) :
          this.recallEventsService.$pushRecallNotification(this.$event)
      }
    }).result.then((newNotification) => {
      this.growl.success('Recall notification ' + (newNotification.$id ? 'updated' : 'created') + ' successfully.', {});
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to edit notification.'));
  }

  remove(notification) {
    if (this.user.hasPermission(this.authorization.claims.PRODUCT_WRITE)) {
      this.confirmDeleteModal(notification.date).then(() => {
        if (notification) {
          this.$notifications.$remove(notification)
            .then(() => this.growl.success('Recall notification removed successfully.'))
            .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to remove notification.'));
        }
      });
    } else {
      this.growl.error('Insufficient access. Please contact customer support.');
    }
  }
}

module.exports = Controller;
