module.exports = function(ngModule) {
  ngModule.constant('sopLogFormTypes', [
    {name: 'Employee training', value: 'employeeTrainId'},
    {name: 'Equipment cleaning',value: 'equipCleanId'},
    {name: 'Metal Detection', value: 'metalDetection'},
    {name: 'Thermometer Calibration', value: 'thermoCalibId'},
    {name: 'Thermometer Validation', value: 'thermoValidId'},
    {name: 'Fridge/Freezer Temperature',value: 'FFTempId'},
    {name: 'Grinding Blade', value: 'grindBladeId'},
    {name: 'Illness/Injury', value: 'IllInjId'},
    {name: 'Sanitation',value: 'sanitationId'},
    {name: 'pH calibration', value: 'pHCalId'},
    {name: 'Transporting Product', value: 'transportProductId'},
    {name: 'Cold Chain', value: 'coldChainId'},
    {name: 'Histamine Fish Receiving/ Refrigeration', value: 'fishReceivingId'},
    {name: 'Daily Anti-Microbial Treatment Check', value: 'antiMicrobialId'},
    {name: 'Default Log - Yes/No', value: 'otherId'},
    {name: 'Receiving log', value: 'receivingLog'},
    {name: 'Visual inspection',value: 'visualInspectionLog'},
    {name: 'Sanitizer strength log',value: 'sanitizerLog'},
    {name: 'Cooking/Cooling Temperature Log',value: 'cookingCoolingTemperature'},
    {name: 'Cooking and Reheating Temperature Log',value: 'cookingReheatingTemp'}
  ]);
};
