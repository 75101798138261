class Controller {
  constructor($log, $q, $window, states, billing, orders, users, growl, organizations, marketplaceService,
              orgInteractionService, authorization, CF_ORDER_ITEM_STATUS, notifications, CF_ROLES, $state,
              MARKETPLACE_SUBSCRIPTION_PROMO, products) {
    'ngInject';

    this.$log = $log;
    this.$q = $q;
    this.states = states();
    this.billing = billing;
    this.orders = orders;
    this.users = users;
    this.growl = growl;
    this.organizations = organizations;
    this.marketplaceService = marketplaceService;
    this.angular = $window.angular;
    this.orgInteractionService = orgInteractionService;
    this.authorization = authorization;
    this.CF_ORDER_ITEM_STATUS = CF_ORDER_ITEM_STATUS;
    this.notifications = notifications;
    this.$state = $state;
    this.defaultOrgRole = CF_ROLES.PROVIDER;
    this.MARKETPLACE_SUBSCRIPTION_PROMO = MARKETPLACE_SUBSCRIPTION_PROMO;
    this.products = products;
    this.defaultProductClaims = [this.authorization.claims.PRODUCT_READ,
      this.authorization.claims.LETTER_WRITE, this.authorization.claims.LETTER_READ];
  }

  $onInit() {
    this.submittingOrder = false;
    this.orderSubmitted = false;
    this.sourceId = null;
    this.updateSubTotal();
    this.updateServiceProviders();
    this.setSourceIcon();
    this.addingCard = false;
    this.shoppingCartItems.$watch(() => {
      this.updateSubTotal();
      this.updateServiceProviders();
    });

    if (!this.user.customerRec || this.user.customerRec.deleted) {
      this.$log.error('Customer rec not usable for order payment in marketplace.checkout.controller.');
      this.growl.error('An error occurred loading your payment data. ' +
        'Please contact customer support if this continues.');
      this.$state.go('user.dashboard');
    }

    this.customer = this.user.customerRec;
    let sources = _.get(this.customer, 'sources.data', []);

    _.remove(sources, s => this.billing.isAch(s) && s.status !== 'verified');
    this.enterNewCard = !sources.length;
    this.newCard = {};
    if (sources.length) {
      _.each(sources, (source) => source.description = this.billing.getSourceDescription(source));
    }

    if (this.user.isTrialing()) {
      this.firstMarketpaceService = _.first(this.shoppingCartItems, item => item.service);
      this.isActivateSubscription = !!this.firstMarketpaceService;
      if (this.isActivateSubscription) {
        this.subscriptionPrice = this.billing.getSubscriptionPriceText(_.get(this.user, 'subscription.plan'));
      }
    }
  }

  $onDestroy() {
    this.organization.$destroy();
  }

  updateSubTotal() {
    this.subTotal = _.reduce(this.shoppingCartItems, (result, item) =>
      result + (item.isRush ? item.service.price.rush || item.service.price.regular : item.service.price.regular), 0);
  }

  updateServiceProviders() {
    let providerOrgIds = _.uniq(_.map(this.shoppingCartItems, (i) => i.providerOrgId));

    this.$q.all(_.map(providerOrgIds, (orgId) => this.organizations.getMiniProfile(orgId)))
      .then((miniProfiles) => this.providerProfiles = _.keyBy(miniProfiles, 'id'));
  }

  setSourceIcon(type) {
    switch(type ? type.toLowerCase() : '') {
    case 'mastercard' :
      this.sourceIcon = 'fa-cc-mastercard';
      break;
    case 'visa' :
      this.sourceIcon = 'fa-cc-visa';
      break;
    case 'amex' :
      this.sourceIcon = 'fa-cc-amex';
      break;
    case 'ach' :
      this.sourceIcon = 'fa-university';
      break;
    default :
      this.sourceIcon = 'fa-credit-card';
      break;
    }
  }

  addCard(user, card) {
    this.addingCard = true;

    return this.billing.addCard(this.user.organizationId, user.customerId,
      this.billing.dehydrateCard(card), {nickname: card.nickname})
      .then((source) => {
        source.description = this.billing.getSourceDescription(source);
        this.customer.sources.data = this.customer.sources.data || [];
        this.customer.sources.data.push(source);
        this.sourceId = source.id;
        this.growl.success('Payment type successfully added.');
        this.newCard = null;
        this.enterNewCard = false;
      })
      .finally(() => this.addingCard = false);
  }

  activateSubscription() {
    return this.billing.updateSubscription(this.user.orgContext.id, this.user.subscription.id, {
      // eslint-disable-next-line camelcase
      trial_end: 'now',
      coupon: this.MARKETPLACE_SUBSCRIPTION_PROMO
    }).then(subscription => {
      this.$log.info('User activated subscription: ' + _.get(this.user, 'subscription.plan.name'), null, {
        notify: true,
        source: 'marketplacePromo'
      });
      this.user.subscription = subscription;
      this.growl.success('Subscription Activated!');
    });
  }

  submitOrder(user, shoppingCartItems, sourceId) {
    this.submittingOrder = true;
    let activatePromise = this.isActivateSubscription ? this.activateSubscription() : this.$q.resolve();

    activatePromise.then(() => this.orders.create({
      userId: user.uid,
      organizationId: user.orgContext.id
    }))
    .then((order) => {
      return this.$q.all(_.map(shoppingCartItems, (item) =>
        this.orders.addItem(order.key,
          _.assign(_.pick(item, ['isRush', 'product', 'service', 'specialInstructions', 'providerOrgId']),
            {
              sourceId: sourceId,
              contactEmail: this.receiptEmail || null,
              customerId: user.customerId,
              clientOrgId: user.orgContext.id,
              status: this.CF_ORDER_ITEM_STATUS.ORDERED
            }
          )
        )
        .then(() => item.product && this.products.setChargeId(item.product.key, 'mktOrder_' + order.key))
        .then(() => this.marketplaceService.getService(item.service.key, item.providerOrgId))
        .then((service) => this.$log.info(`Order submitted - Service: ${service.title}, Organization: ` +
          `${_.get(this.user, 'orgContext.companyName')}, Product: ${item.product ? item.product.brandName : 'N/A'}`,
          null, {notify: true, type: 'marketplace'})
        ).then(() => this.notifications.postToOrg({
          from: this.user.uid,
          to: item.providerOrgId,
          message: (item.isRush ? 'A RUSH' : 'An') + ' order was placed for : <i>"' + item.service.title + '"</i>',
          link: {state: 'marketplace.orders.list'}
        }))
      ));
    })
    .then(() => {
      this.growl.success('Order successfully submitted!');
      this.shoppingCartItems.$ref().remove();
      this.orderSubmitted = true;
    })
    .catch(err => {
      this.growl.error('An error occurred submitting your order. Please contact customer support.');
      this.submittingOrder = false;
      this.$log.error('An error occurred submitting an order', this.$log.toString(err));
    });
  }
}

module.exports = Controller;
