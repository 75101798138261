'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUploadPortalMediaList', {
    bindings: {
      token: '<',
      key: '@',  // A unique identifier to distinguish the different sections for a given key
      categories: '<',
      user: '<?',
      noFilesText: '@?',
      existingFiles: '<',
      requiredFiles: '<',
      expiryDate: '<'
    },
    template: require('./file-portal.media-list.template.html'),
    controller: require('./file-portal.media-list.controller.js'),
    controllerAs: 'vm'
  });
};
