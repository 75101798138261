module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.safetyteam', {
        url: '/safetyteam',
        template: '<cf-list-safety-team safety-team="$resolve.safetyTeam" ' +
          'company="$resolve.facility" ' +
          'members="$resolve.members" user="$resolve.user"></cf-list-safety-team>',
        resolve: {
          facility: (user, organizations) => organizations.$get(user.orgContext.id),
          safetyTeam: (facility, $firebaseArray) => $firebaseArray(facility.$ref().child('safetyTeam')).$loaded(),
          members: function(facility, $firebaseArray, $q, users) {
            return $firebaseArray(facility.$ref().child('members')).$loaded()
              .then(function(members) {
                return $q.all(_.map(members, function(member) {
                  return users.getName(member.$id).then(function(name) {
                    return {
                      $id: member.$id,
                      name: name
                    };
                  });
                }));
              });
          }
        }
      });
  });
};
