module.exports = function(ngModule) {
  ngModule.factory('User', function(fbutil, $q, S3_URL, S3, $log, SAMPLE_ORGANIZATION_ID, $window,
                                    organizations, authorization, orgPerspectives, $firebaseArray, moment,
                                    billing, $http, $state, growl, constantsService, $firebaseObject) {
    'ngInject';

    const localStorageKeys = {
      ORG_CONTEXT_KEY: 'orgContext',
      ID_TOKEN: 'idToken',
      LAST_LOGGED_USER: 'lastLoggedUser',
      REFRESH_TOKEN: 'refreshToken',
      SHOW_ALL_TIPS: 'showAllTips'
    };

    class User {

      constructor(authUser) {
        this.authUser = authUser;
        this.provider = _.get(authUser, 'providerData[0].providerId');
        this.uid = authUser.uid;
        this.isTemporaryPassword = this.authUser.password && this.authUser.password.isTemporaryPassword;
        this.localStorage = $window.localStorage;
        this.showAllTips = this.localStorage &&
          _.lowerCase(localStorage.getItem(localStorageKeys.SHOW_ALL_TIPS)) === 'true';

        this._startRemoteWatch();
        this._loadUser();
        this.$firebaseObject = $firebaseObject;
      }

      static logout() {
        if (localStorage) {
          localStorage.removeItem(localStorageKeys.ORG_CONTEXT_KEY);
          localStorage.removeItem(localStorageKeys.ID_TOKEN);
          localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);
          localStorage.removeItem(localStorageKeys.LAST_LOGGED_USER);
        }
      }

      get subscription() {
        return this._subscription;
      }

      set subscription(subscription) {
        this._subscription = subscription;
        if (!subscription || !subscription.plan) { return; }
        billing.getProductName(subscription.plan.product).then(productName => {
          this._subscription.plan.name = productName;
        });
      }

      /**
       * Register a remote watch handler for this user.
       * @param {name} collection - The collection name to register a watch for.
       * @param {function} handler - The handler to process the changed collection record.
       * @return {void}
       */
      onRemoteWatchReceived(collection, handler) {
        this.remoteWatchHandlers = this.remoteWatchHandlers || {};
        this.remoteWatchHandlers[collection] = this.remoteWatchHandlers[collection] || [];
        this.remoteWatchHandlers[collection].push(handler);
      }

      $loaded() {
        return this.loadedPromise;
      }

      currentOrgContext() {
        if (!this.orgContext) {
          throw new Error('orgContext not set');
        }

        return this.orgContext;
      }

      /**
       * Set the current organization context. For users who have a membership to > 1 org, this is the org that is
       * being displayed.
       * @param {string} orgId The organizationId to switch to.
       * @param {object} [opts] Additional options
       * @param {boolean} [opts.noPersist] Don't persist this change (i.e. hitting F5 will reset to original org
       *   context)
       * @param {string} [opts.perspective] Set the perspective for when you switch
       * @return {*} A promise that is resolved when the org context is set.
       */
      setOrgContext(orgId, opts) {
        orgId = orgId || this.organizationId;
        opts = opts || {};
        let mainOrg = this.organizationId === orgId,
          orgAccess = _.get(this.access, 'reviews.organizations.' + orgId, {}),
          orgPrefs = _.get(this, 'orgPrefs.' + orgId) || {},
          role = orgAccess.role || (this.isCfAdmin() ? 'admin' : null);
          //userRole = orgAccess.role || (mainOrg ? this.access.reviews.role : this.isCfAdmin() ? 'admin' : null),

        if (!role) {
          // Temporary extra logging
          $log.error('user.service.js: User does not have a role defined on organization', {
            orgId: orgId,
            mainOrg: mainOrg,
            accessObj: _.get(this.access, 'reviews.organizations', null)
          });

          throw new Error('Invalid organization Id: ' + orgId);
        }

        if (!opts.noPersist && localStorage) {
          localStorage.setItem(localStorageKeys.ORG_CONTEXT_KEY, orgId);
        }

        return $q.all({
          companyName: organizations.getName(orgId),
          companyType: organizations.getType(orgId),
          companyPhone: organizations.getPhone(orgId),
          orgRole: fbutil.ref('roles', orgId, role).once('value'),
          defaultRole: fbutil.ref('roles/default', role).once('value'),
          perspective: $q.when(orgPrefs.perspective || organizations.getDefaultPerspective(orgId))
        })
          .then(result => {
            this.roleDef = result.orgRole.exists() ? result.orgRole.val() : result.defaultRole.val();

            if (!this.roleDef) {
              throw new Error('Role not found: ' + role);
            }

            this.orgContext = {
              mainOrg: mainOrg,
              id: orgId,
              companyName: result.companyName,
              role: role,
              claims: this.roleDef.claims || {},
              companyType: result.companyType
            };

            if (result.companyPhone) {
              this.orgContext['phone'] = result.companyPhone;
            }
            // use any perspective overrides 1st, then the roleDef, then the pref perspective
            this.setPerspective(opts.perspective || orgAccess.perspective || this.roleDef.perspective ||
              result.perspective);

            if (this.isCfAdmin()) {
              this.orgContext.claims[authorization.claims.CF_ADMIN] = true;
            }

            return authorization.setOrgContext(this.uid, orgId);
          })
          .catch(err =>
            $log.error('Error occurred looking up the stripe subscription in users.service.js', $log.toString(err)));
      }

      getPerspective() {
        return _.get(this, 'orgContext.perspective');
      }

      setPerspective(perspective) {
        this.orgContext.perspective = perspective || orgPerspectives.NONE;
      }

      overridePerspective(newPerspective) {
        return fbutil.ref('userAccess', this.uid, 'reviews/organizations', this.orgContext.id, 'perspective')
          .set(newPerspective).then(() => {
            this.orgContext.perspective = newPerspective;
            return newPerspective;
          });
      }

      deletePerspectiveOverride() {
        let mainOrg = this.organizationId === this.orgContext.id,
          orgAccess = _.get(this.access, 'reviews.organizations.' + this.orgContext.id),
          orgPrefs = _.get(this, 'orgPrefs.' + this.orgContext.id) || {},
          role = _.get(orgAccess, 'role') ? orgAccess.role : mainOrg ? this.access.reviews.role : null;

        return fbutil.ref('userAccess', this.uid, 'reviews/organizations', this.orgContext.id, 'perspective')
          .remove()
          .then(() => $q.all({
            orgRole: fbutil.ref('roles', this.orgContext.id, role).once('value'),
            defaultRole: fbutil.ref('roles/default', role).once('value'),
            perspective: $q.when(orgPrefs.perspective || organizations.getDefaultPerspective(this.orgContext.id))
          }))
          .then((result) => {
            let newPerspective;

            this.roleDef = result.orgRole.exists() ? result.orgRole.val() : result.defaultRole.val();
            newPerspective = this.roleDef.perspective || result.perspective;
            this.setPerspective(newPerspective);
            if (orgAccess) { delete orgAccess.perspective; }

            return newPerspective;
          });
      }

      isReviewsUser() {
        return !!this.access.reviews;
      }

      fullName() {
        return this.firstName + ' ' + this.lastName;
      }

      isCfAdmin() {
        return !!this.access.cfAdmin;
      }

      isFrSopLibAccess() {
        return !!this.access.frSopLibAccess;
      }

      isAnswersAdmin() {
        return _.get(this, 'access.answers.role') === 'admin';
      }

      hasPermission(claimName) {
        if (claimName === authorization.claims.CF_ADMIN) { return this.isCfAdmin(); }

        return this.orgContext && this.orgContext.claims[claimName];
      }

      hasFeatureFlag(featureFlag) {
        return _.get(this, 'featureFlags.' + featureFlag);
      }

      organizations() {
        return this.access.reviews.organizations || {};
      }

      /**
       * Retrieve the message topic Id for a one-to-one user message.
       * @param {string} uid The other user whom the message is with.
       * @returns {Promise} A promise that resolves to the message topic Id or null if no topic exists.
       */
      getUserMessageTopic(uid) {
        return fbutil.ref('users', this.uid, 'userMessages', uid).once('value').then(fbutil.getValueOrDefault);
      }

      /**
       * Save a message topic Id for a one-to-one user message.
       * @param {string} uid The other user whom the message is with.
       * @param {string} topicId The message topic Id.
       * @returns {string} A promise that resolves when the message Id is saved.
       */
      saveUserMessageTopic(uid, topicId) {
        return $q.all([
          fbutil.ref('users', this.uid, 'userMessages', uid).set(topicId),
          fbutil.ref('users', uid, 'userMessages', this.uid).set(topicId)
        ]);
      }

      /**
       * Retrieve the message topic Id for a user-to-org message topic.
       * @param {string} orgId The other org whom the message is with.
       * @returns {Promise} A promise that resolves to the message topic Id or null if no topic exists.
       */
      getOrgMessageTopic(orgId) {
        return fbutil.ref('users', this.uid, 'orgMessages', orgId).once('value').then(fbutil.getValueOrDefault);
      }

      /**
       * Save a message topic Id for a one-to-one user message.
       * @param {string} orgId The other org whom the message is with.
       * @param {string} topicId The message topic Id.
       * @returns {string} A promise that resolves when the message Id is saved.
       */
      saveOrgMessageTopic(orgId, topicId) {
        return $q.all([
          fbutil.ref('users', this.uid, 'orgMessages', orgId).set(topicId),
          fbutil.ref('organizations', orgId, 'userMessages', this.uid).set(topicId)
        ]);
      }

      /**
       * Return ALL organizations the user belongs to, including the organization level assignments.
       * @returns {Promise} A promise that resolves to an array of organization Ids.
       */
      getAllOrganizationRoles() {
        let userOrgs = this.access.reviews.organizations;

        return fbutil.ref('organizationAccess', this.orgContext.id, 'reviews/organizations').once('value')
          .then(orgsSnap => {
            return orgsSnap.exists() ? _.assign(userOrgs, orgsSnap.val()) : userOrgs;
          });
      }

      teams() {
        return _.keys(this.access.reviews.teams) || [];
      }

      /**
       * Return the user's role for an organization, or the currentOrgContext if none is passed.
       * @param {string} orgId Optional org to check.
       * @returns {string} The user's role
       */
      getRole(orgId) {
        orgId = orgId || this.orgContext.id;

        return _.get(this, `access.reviews.organizations.${orgId}.role`);
      }

      doesSubscriptionPermit(constraintName) {
        if (this.allowAllFeatures) { return true; }
        if (!constraintName) { throw new Error('Constraint missing'); }

        // If constraints aren't set yet, then they are not permissed
        if (!this.constraints) {
          return false;
        }

        return this.constraints.noConstraints || this.constraints[constraintName];
      }

      onPayAsGoPlan() {
        return !this.subscription || !this.constraints || this.constraints.payPerPlan;
      }

      isTrialing() {
        return this.subscription.status === billing.subscriptionStatuses.TRIALING;
      }

      isActiveSubscription() {
        return this.subscription.status === billing.subscriptionStatuses.ACTIVE;
      }

      isTipHidden(tip) {
        return !this.showAllTips && this.hiddenTips && this.hiddenTips[tip];
      }

      showPeanutButterSample() {
        this.showSample = true;
        return fbutil.ref('users', this.uid, 'showSample').set(true);
      }

      hidePeanutButterSample() {
        this.showSample = false;
        return fbutil.ref('users', this.uid, 'showSample').remove();
      }

      isPartner() {
        return !this.isCfAdmin() && this.doesSubscriptionPermit('clientAdmin');
      }

      setTipHidden(tip) {
        this.hiddenTips = this.hiddenTips || {};
        if (this.hiddenTips[tip]) { return; }
        this.hiddenTips[tip] = true;
        fbutil.ref('users', this.uid, 'hiddenTips', tip).set(true);
      }

      unsetTipHidden(tip) {
        this.hiddenTips[tip] = false;
        fbutil.ref('users', this.uid, 'hiddenTips', tip).remove();
      }

      $getHiddenTips() {
        return this.$firebaseObject(fbutil.ref('users', this.uid, 'hiddenTips')).$loaded();
      }

      unsetMultipleTipHidden(tips) {
        return this.$getHiddenTips().then((hiddenTips)=>{
          tips.forEach((tip)=>{
            tip = 'minitour_' + tip;
            this.hiddenTips[tip] = false;
            hiddenTips[tip] = false;
          });
          return hiddenTips.$save();
        });
      }

      setMultipleTipHidden(tips) {
        this.$getHiddenTips().then((hiddenTips)=>{
          tips.forEach((tip)=>{
            tip = 'minitour_' + tip;
            this.hiddenTips[tip] = true;
            hiddenTips[tip] = true;
          });
          hiddenTips.$save();
        });
      }

      paymentDelinquentDays() {
        const delinquentDate = _.get(this.orgContext, 'subscription.delinquentDate');

        if (!delinquentDate) { return; }
        return moment().diff(moment(delinquentDate), 'days');
      }

      /**
       * Is the user signed into his/her primary organization.
       * @return {boolean}  True if they are on their primary org.
       */
      onPrimaryOrg() {
        return this.organizationId === this.orgContext.id;
      }

      /**
       * Give the user a day for the attempt to resolve payment to work through the payment processor.
       * @return {Promise} A promise that is resolved when the notice is postponed.
       */
      postponePastDueNotice() {
        let tomorrow = new Date();

        tomorrow.setDate(tomorrow.getDate() + 1);
        return fbutil.ref('users', this.uid, 'postponePastDueUntil').set(tomorrow.getTime());
      }

      /**
       * Has past due notices been postponed?
       * @return {Promise} Resolves to true if it was postponed.
       */
      pastDuePostponed() {
        return fbutil.ref('users', this.uid, 'postponePastDueUntil').once('value').then(fbutil.getValueOrDefault)
          .then(postponedUntil => postponedUntil && postponedUntil > new Date().getTime());
      }

      hardDelete() {
        return $http.delete(`/users/${this.uid}?hard=true`).then(() => {
          return fbutil.auth.currentUser.delete();
        }).then(() => {
          growl.success('User deleted');
          $state.go('signup.begin');
        }).catch(err => {
          $log.error(err);
        });
      }

      /**
       * Setup the remote watch listener for the user. Sets the remoteWatchOn property and listens for remotely
       * detected changes. When a change occurs, it looks for registered handlers to process the change.
       * @return {Promise} - A promise that resolves when the listener is setup.
       */
      _startRemoteWatch() {
        const remoteOnRef = fbutil.ref('users', this.uid, 'remoteWatchOn');

        return remoteOnRef.set(true).then(() => {
          // Turn off the watcher when we disconnect.
          return remoteOnRef.onDisconnect().remove();
        }).then(() => {
          // Remove all previous watch recs.
          return fbutil.ref('users', this.uid, 'remoteWatchRecs').remove();
        }).then(() => $firebaseArray(fbutil.ref('users', this.uid, 'remoteWatchRecs')))
          .then($remoteWatchRecs => {
            $remoteWatchRecs.$watch(args => {
              if (args.event !== 'child_added') { return; }
              let rec = $remoteWatchRecs.$getRecord(args.key);
              let remoteWatchRec = _.clone(rec);

              $remoteWatchRecs.$remove(rec).catch(err => {
                $log.error('An error occurred removing a processed remoteWatchRec: ' + err,
                  {ref: $remoteWatchRecs.$ref().toString(), rec: angular.toJson(rec)});
              });
              if (remoteWatchRec && this.remoteWatchHandlers[remoteWatchRec.collection]) {
                _.each(this.remoteWatchHandlers[remoteWatchRec.collection], handler => handler(remoteWatchRec.key));
              }
            });
          });
      }

      /**
       * Create a customer record in the payment processing system. Store the new customer id in the FB user record.
       * @return {Promise<object>} A promise that resolves to the new customer record.
       * @private
       */
      _createCustomerRec() {
        $log.info('user.service.js:_createCustomerRec: Creating payment customer record.',
          {orgId: this.organizationId, email: this.email});
        return billing.createCustomer(this.organizationId, {
          email: this.email,
          metadata: {userId: this.uid}
        }).then((customer) => {
          $log.info('user.service.js:_createCustomerRec: Payment customer created.', {customerId: customer.id});
          this.customerId = customer.id;
          return fbutil.ref('users', this.uid, 'customerId').set(customer.id).then(() => customer);
        });
      }

      _loadUser() {
        let startLoad = new Date().getTime();

        this.loadedPromise = $q.all([this.loadAccess(), this._loadUserObject()])
          .then(() => this._ensureSampleOrgSet())
          .then(() => {
            // Lookup the organization's subscription in the payment processor system
            return fbutil.ref('organizations', this.organizationId, 'subscriptionId').once('value')
              .then(subscriptionIdSnap => {
                if (!subscriptionIdSnap.exists()) {
                  // No subscription exists on the org. See if it's a new org waiting for an assigned subscription.
                  return fbutil.ref('organizations', this.organizationId, 'pendingSubscription').once('value')
                    .then(pending => {
                      if (!pending.exists() || !pending.val()) {
                        return {status: billing.subscriptionStatuses.MISSING};
                      }
                    });
                }

                this.subscriptionId = subscriptionIdSnap.val();
                if (subscriptionIdSnap.val() === 'notChosen') {
                  return;
                }

                return this.fetchSubscription();
              });
          })
          .then(() => {
            let orgId = _.get(this, 'access.reviews.currentOrgContext') || this.organizationId;

            return this.setOrgContext(orgId);
          }).then(() => {
            let loadPromises = [];
            // Lookup the customer record in the payment processor system
            let customerPromise = this.customerId ?
              billing.getCustomer(this.orgContext.id, this.customerId) :
              this._createCustomerRec();

            loadPromises.push(customerPromise.then(customerRec => { this.customerRec = customerRec; }).catch(err =>
              $log.error('An error occurred looking up the stripe customer in users.service.js', err)));


            if (this.subscription) {
              loadPromises.push(this._setSubscriptionConstraints().then(() => {
                if (this.orgContext && !this.constraints || this.constraints.payPerPlan) {
                  this.setPerspective(orgPerspectives.FREE_PLAN);
                }
              }));
            }
            loadPromises.push(fbutil.ref(`organizations/${this.organizationId}/allowAllFeatures`).once('value')
                .then(allowAllFeaturesSnap => {
                  this.allowAllFeatures = allowAllFeaturesSnap.exists() && allowAllFeaturesSnap.val();
                }));
            return $q.all(loadPromises);
          })
          .then(() => {
            $log.metric({name: 'userLoad', durationMs: new Date().getTime() - startLoad});
            return this;
          })
          .catch(err => {
            $log.error('Could not establish user: ', $log.toString(err));
            // todo: route them to an error page. There's not much we can do if the user can't load.
          });
      }

      loadAccess() {
        return authorization.getUserAccess(this.uid).then(accessObject => {
          if (!accessObject) {
            throw new Error('User access object does not exist for ' + this.uid);
          }

          this.access = accessObject;
          return accessObject;
        });
      }

      fetchSubscription() {
        return $q.when(this.subscriptionId || fbutil.ref('organizations', this.organizationId, 'subscriptionId')
          .once('value').then(snap => snap.exists() ? snap.val() : null))
          .then(subscriptionId => subscriptionId ?
            billing.fetchSubscription(this.organizationId, subscriptionId) : null)
          .then(subscription => { this.subscription = subscription; })
          .then(() => this._setSubscriptionConstraints());
      }

      _setSubscriptionConstraints() {
        this.constraints = null;
        if (!this.subscription) { return; }
        return constantsService.get('stripe').then(constants => {
          this.constraints = constants.constraints[this.subscription.plan.product] || {noConstraints: true};
        });
      }

      _loadUserObject() {
        return fbutil.ref('users', this.authUser.uid).once('value')
          .then(snap => {
            if (!snap.exists()) {
              throw new Error('User object does not exist for ' + this.authUser.uid);
            }

            let val = snap.val();

            // if (val.disableGaTracking) { gtag.disable(); }  // Disable Google Analytics for user (e.g. CF members)
            delete val.access;  // make sure bogus data doesn't screw up our access object.
            snap.ref.child('lastLogin').set(firebase.database.ServerValue.TIMESTAMP);
            _.assign(this, val);
          });
      }

      _getUserRef() {
        return fbutil.ref('users', this.uid);
      }

      _ensureSampleOrgSet() {
        if (this.access.reviews.organizations && this.access.reviews.organizations[SAMPLE_ORGANIZATION_ID]) {
          return $q.resolve();
        }

        return fbutil.ref('userAccess', this.uid, 'reviews/organizations', SAMPLE_ORGANIZATION_ID, 'role')
          .set('read_only_user');
      }
    }

    return User;
  });
};
