module.exports = function(ngModule) {
  ngModule.component('cfEditGmp', {
    bindings: {
      user: '<',
      company: '=',
      facility: '=',
      $gmps: '='
    },
    template: require('./company.gmp.template.html'),
    controller: require('./company.gmp.controller.js'),
    controllerAs: 'vm'
  });

  require('./category')(ngModule);
  require('./confirm')(ngModule);
};
