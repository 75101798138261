module.exports = function(ngModule) {
  ngModule.directive('sizeWatcher', function() {
    return {
      restrict: 'A',
      scope: {},
      controller: function($scope) {
        let vm = this;

        $scope.$watch('size', val => {
          vm.size = val;
        });
      },
      controllerAs: 'vm',
      link: function(scope, element) {
        let initialized = false;

        scope.$watch(() => {
          return {w: element.width(), h: element.height()};
        }, (newVal, oldVal) => {
          if (!initialized && newVal || newVal !== oldVal) {
            scope.size = newVal;
            initialized = true;
          }
        }, true);
      }
    };
  });
};