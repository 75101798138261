module.exports = function(ngModule) {
  ngModule.component('cfProblemReports', {
    bindings: {
      $problemReports: '<',
      recall: '=',
      user: '<'
    },
    template: require('./recall.problem-reports.template.html'),
    controller: require('./recall.problem-reports.controller'),
    controllerAs: 'vm'
  });
};
