class Controller {
  constructor($scope, $log, growl, signup, authentication, $q, confirmModal, ENV, $state, $timeout,
    billing, $uibModal, $http, organizations, orgInteractionService, cfpLoadingBar, CAPTCHA_KEY, vcRecaptchaService, V2_URL, LOCAL_DEVELOPMENT) {
    'ngInject';
    this.$scope = $scope;
    this.$log = $log;
    this.growl = growl;
    this.signup = signup;
    this.authentication = authentication;
    this.$q = $q;
    this.confirmModal = confirmModal;
    this.billing = billing;
    this.$uibModal = $uibModal;
    this.siteKey = CAPTCHA_KEY;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$http = $http;
    this.organizations = organizations;
    this.orgInteractionService = orgInteractionService;
    this.cfpLoadingBar = cfpLoadingBar;
    this.vcRecaptchaService = vcRecaptchaService;
    this.V2_URL = V2_URL;
    this.LOCAL_DEVELOPMENT = LOCAL_DEVELOPMENT;
    //this.isDevMode = ENV === 'development';
  }
  $onInit() {
    if (this.tokenId && !this.tokenData) {
      this.growl.error('Invalid or expired signup link.');
      delete this.tokenId;
    }
    this.user = this.user || {};
  }
  signUp() {
    this.reviewsSignUp()
      .catch((err) => {
        this.$log.error(err);
        this.growl.error(err.message || 'Unable to complete signup.');
      })
      .finally(() => this.signingUp = false);
  }
  reviewsSignUp() {
    let tokenData = this.tokenData && _.assign(this.tokenData, {token: this.tokenId});
    // if the invitation has expired, notify the user and return a resolved promise as rejected.

    if (this.isTokenExpired()) { return this.$q.reject(); }
    this.$log.info('Beginning user signup', {
      tokenDataStr: angular.toJson(tokenData)
    }, {type: 'registration'});
    this.user.isOAuthUser = !!this.oauthUser;
    this.user.source = this.$state.params.src;
    let salespersonId = _.get(this.$state, 'params.spid', null);

    return this.$q.when(this.oauthUser || this.signUpLocal(this.user.email, this.password))
      .then((authUser) => this.signup.reviews(this.user, authUser, tokenData, {salespersonId}))
      .then(async (user) => {
        let orgId = user.orgContext.id;

        this.authentication.user = user;
        this.$log.info(`User account request for ${user.email}`, {orgId}, {notify: true, type: 'registration'});

        await this.$http.get('/create_custom_token');

        if (!(tokenData && tokenData.assumeOwnership) && user.subscription) {
          return user.deletePerspectiveOverride()
            .then(() => {
              this.onSignedUp({$preferredState: 'onboarding.firstPlan',
                $preferredParams: this.commonOnboardingStateParams()});
              return user;
            });
        } else {
          return this.organizations.getPreSubscriptionId(orgId)
            .then(subscriptionId => {
              if (subscriptionId) {
                return this.processPreSubscriptionOrder(subscriptionId, orgId, user);
              } else {
                this.onSignedUp({
                  $preferredState: 'signup.plan',
                  $preferredParams: {
                    transfer: tokenData && tokenData.assumeOwnership ? true : undefined,
                    planId: tokenData && tokenData.assumeOwnership && tokenData.planId ? tokenData.planId : undefined,
                  }
                });
              }
            });
        }
      });
  }
  signUpLocal(email, pw) {
    email = email.toLowerCase();
    return this.authentication.signUpLocal(email, pw)
      .catch(err => {
        if (err.code === 'auth/email-already-in-use') {
          this.$timeout(() => {
            this.growl.warning(`"${email}" is already registered. Please try to sign in below.`);
          });

          if (this.LOCAL_DEVELOPMENT) {
            this.$state.go('authentication.signin', { email });
          } else {
            this.$window.open(`${this.V2_URL}/signin?email=${ email }`, '_self');
          }
        }
        return this.$q.reject(err);
      });
  }
  processPreSubscriptionOrder(subscriptionId, orgId, user) {
    return this.billing.getPlans(orgId)
      .then(result => {
        let billingEntry = _.find(result.data, {id: subscriptionId});

        if (!billingEntry) {
          throw new Error('Invalid preSubscription plan: ' + subscriptionId);
        }
        return this.$uibModal.open({
          component: 'cfSubscriptionOrder',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            user: () => user,
            title: () => billingEntry.name,
            plan: () => billingEntry
          }
        }).result;
      })
      .then(() => this.user.deletePerspectiveOverride())
      .then(() => {
        this.$log.info('User auto-set for subscription plan: ' + subscriptionId);
        this.onSignedUp({$preferredState: 'onboarding.firstPlan', $preferredParams: this.commonOnboardingStateParams()});
        return user;
      })
      .catch(reason => {
        if (reason !== 'cancel') {
          this.growl.error('Unable to process order. Please contact customer support.');
          this.$log.error('Unable to auto-set for subscription plan. User: ' + user.uid);
        }
        this.onSignedUp({$preferredState: 'onboarding.firstPlan', $preferredParams: this.commonOnboardingStateParams()});
        return user;
      });
  }
  commonOnboardingStateParams() {
    return _.pick(this.$state.params, ['productCategory', 'template', 'serviceId']);
  }
  isTokenExpired() {
    let now = new Date();

    if (this.tokenId && _.get(this, 'tokenData.expires') < now.getTime()) {
      let sender = _.get(this, 'tokenData.createdByName') || 'the sender';
      let message = this.tokenData ?
        'Your invitation from <b>' + this.companyName + '</b> has expired. Please contact <b>' +
        sender + '</b> to get a new invitation.' :
        'Your invitation to join FoodReady could not be found. Please contact the sender to get a new invitation.';

      this.confirmModal({
        title: 'Uh Oh! Your Invitation Expired!',
        body: message,
        okText: 'Ok',
        hideCancelButton: true
      });
      return true;
    }
  }
  loginOAuth(referrer) {
    this.authentication.signUpOAuth(referrer)
      .then((oauthUser) => {
        this.oauthUser = oauthUser;
        let nameArray = oauthUser.displayName && oauthUser.displayName.split(' ');
        let providerData = _.get(oauthUser, 'providerData[0]', {});

        if (_.isFunction(this.onOauth)) {
          this.onOauth({$oauthUser: this.oauthUser});
        }
        if (nameArray) {
          this.user = {
            firstName: nameArray[0],
            lastName: nameArray[nameArray.length - 1],
            email: oauthUser.email || providerData.email
          };
        }
        return this.reviewsSignUp();
      })
      .catch((err) => {
        this.$log.error('OAuth login error: ', err);
        switch (err.code) {
        case 'auth/user-already-exists':
          this.growl.info('User already exists. Signing in...');
          this.authentication.waitForAuth()
              .then((user) => this.orgInteractionService.processUrlToken(user, this.tokenData, user.organizationId, true))
              .then(() => this.onSignedUp({$preferredState: 'user.dashboard'}));
          break;
        case 'auth/user-cancelled':
        case 'auth/internal-error':
        case 'auth/popup-closed-by-user':
          break;
        default:
          this.growl.error('Unknown server error occurred. Please try again.');
        }
      });
  }
  executeCaptcha() {
    this.signingUp = true;
    this.user.email = this.user.email.toLowerCase();
    this.$http.get('validate_email_domain?email=' + this.user.email).then((result)=>{
      if(result.data.validEmail) {
        if(this.activateCapcha) {
          this.vcRecaptchaService.execute();
        } else {
          this.signUp();
        }
      }
    })
    .catch(() => {
      this.growl.error('Error validating email. Please try again.');
      this.signingUp = false;
    });
  }
  verifyCaptcha(response) {
    this.cfpLoadingBar.start();
    this.$http.post('verifyCaptcha', {response: response})
      .then(() =>{
        this.verifiedCaptcha = true;
        this.signUp();
      })
      .catch(() => {
        this.growl.error('Error validating captcha. Please try again.');
        this.signingUp = false;
      })
      .finally(() => this.cfpLoadingBar.complete());
  }
  expireCaptcha() {
    delete this.verifiedCaptcha;
  }
}
module.exports = Controller;
