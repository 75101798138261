class Constructor {
  constructor(utils, $q, $scope, constantsService, S3, confirmDeleteModal, growl) {
    'ngInject';

    this.utils = utils;
    this.$q = $q;
    this.$scope = $scope;
    this.steps = {
      PICK_TYPE: 1,
      VIEW_ITEM: 2
    };
    this.S3 = S3;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
  }

  $onInit() {
    this.header = this.resolve.header;
    this.step = this.steps.PICK_TYPE;
    this.itemsArray = this.resolve.itemsArray;
    this.searchObject = this.resolve.searchObject;
    this.itemName = this.resolve.itemName;
    this.instructionsHtml = this.resolve.instructionsHtml;
    this.htmlColumns = this.resolve.htmlColumns;
    this.columns = this.resolve.columns;
    this.multiple = this.resolve.multiple;
    this.noneFoundHtml = this.resolve.noneFoundHtml || `No ${this.itemName}s found.`;
    this.clonedItemsArray = _.compact(_.cloneDeep(this.itemsArray));
    this.url = null;
    this.organization = this.resolve.organization;
    _.each(this.itemsArray,item => {
      this.S3.getSignedUrl(this.organization.id, item.key)
        .then(result => item.imgUrl = result)
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to open image'));
    });
    // If searchObject was sent, this will be an async search.
    if (this.searchObject) {
      if (this.itemsArray) {
        throw new Error('Invalid arguments to chooseFromList component.');
      }

      this.search = _.debounce(this._search, 300);
      this._search();
    }
  }

  openImageView(index) {
    this.index = index;
    this.step = this.steps.VIEW_ITEM;
    this.imageName = this.itemsArray[index].name;
    this.url = this.itemsArray[index].imgUrl;
  }
  imageIndex(n) {
    this.openImageView(this.index += n);
  }
  remove(item) {
    this.confirmDeleteModal(_.truncate(item.name, {length: 30}))
      .then(() => this.S3.deleteFile(this.organization.id,item.key))
      .then(() => {
        _.remove(this.itemsArray,{key: item.key});
        this.modalInstance.close(this.itemsArray);
        this.growl.success('File successfully removed.');
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete file.'));
  }

  previousStep() {
    this.step = this.steps.PICK_TYPE;
  }
}
module.exports = Constructor;
