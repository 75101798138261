'use strict';

module.exports = function(ngModule) {
  ngModule.component('frPricingCard', {
    bindings: {
      payPeriod: '=',
      annualMonthlyPrice: '=',
      monthlyPrice: '=',
      discountAmount: '=',
      annualPrice: '=',
      noOfUsers: '='
    },
    template: require('./pricing-card.template.html'),
    controller: require('./pricing-card.controller.js'),
    controllerAs: 'vm'
  });
};
