class Controller {
  constructor($state, $log, $filter, growl, marketplaceService, letters, confirmModal, $q, CF_ORDER_ITEM_STATUS,
              billing, notifications, utils, orgPerspectives) {
    'ngInject';

    this.$state = $state;
    this.$log = $log;
    this.$filter = $filter;
    this.growl = growl;
    this.marketplaceService = marketplaceService;
    this.letters = letters;
    this.confirmModal = confirmModal;
    this.$q = $q;
    this.CF_ORDER_ITEM_STATUS = CF_ORDER_ITEM_STATUS;
    this.notifications = notifications;
    this.billing = billing;
    this.$letter = {};
    this.saving = false;
    this.savingTimeout = null;
    this.utils = utils;
    this.orgPerspectives = orgPerspectives;
  }

  $onInit() {
    this.isProvider = this.$orderItem.providerOrgId === this.user.orgContext.id;
    this.orderId = this.$orderItem.$ref().ref.parent.parent.key;

    this.remoteSharedMediaDirectory =
      'orders/' + this.orderId + '/items/' + this.$orderItem.$id + '/media';
    this.remotePrivateMediaDirectory =
      'orders/' + this.orderId + '/items/' + this.$orderItem.$id + '/media/private';

    if (!_.isEmpty(this.$orderItem.letterId)) {
      this.letters.$get(this.$orderItem.letterId)
        .then(($letter) => this.$letter = $letter);
    } else {
      this.$letter = {};

      // Push an empty letter and prefill.
      this.letters.$push(
        this.$orderItem.clientOrgId,
        this.$orderItem.providerOrgId,
        this.orderId,
        this.$orderItem.$id,
        _.get(this.$orderItem, 'product.key', null),
        this.$letter.markup
      ).then($letter => { this.$letter = $letter; })
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to create the letter.'));
    }
  }

  $onDestroy() {
    if (_.isFunction(this.$orderItem.$destroy)) {
      this.$orderItem.$destroy();
    }

    if (_.isFunction(this.$organization.$destroy)) {
      this.$organization.$destroy();
    }
  }

  viewClient(orderId, orderItem) {
    let savePromise = this.$q.resolve();

    if (this.$letter && this.$letter.$value !== null) {
      this.$letter.savedOn = firebase.database.ServerValue.TIMESTAMP;
      savePromise = this.$letter.$save();
    }

    savePromise.then(() => this.user.setOrgContext(orderItem.clientOrgId, {
      noPersist: true,
      perspective: this.orgPerspectives.PROVIDER_ANALYST
    })).then(() => {
      if (orderItem.product) {
        this.$state.go('products.edit.planAnalysis.questionnaire', {productId: orderItem.product.key});
      } else {
        this.$state.go('checklists.gmps.edit.category');
      }
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to view client.'));
  }

  /**
   * Charge the customer & complete the order item.
   * @param {string} orderId The order ID
   * @param {object} $orderItem The order item
   * @return {Promise} A promise resolving upon completion of the update
   */
  completeOrderItem(orderId, $orderItem) {
    const msg = $orderItem.chargeId ? 'Click "Submit" to republish all private media. The customer was already ' +
      'charged, so there will be no charge.' :
      'Click "Submit" to charge your client the amount of this order item (' +
      this.$filter('currency')($orderItem.isRush ? + $orderItem.service.price.rush : $orderItem.service.price.regular) +
      ') and share all private media.';

    this.confirmModal({
      title: '<i class="fas fa-upload" aria-hidden="true"></i> Submit Order Item',
      body: msg,
      okText: 'Submit',
      cancelText: 'Cancel'
    })
      .then(() => {
        let alreadyCharged = !!$orderItem.chargeId;

        if (alreadyCharged) {
          this.$log.warn('Republishing an already-charged order.', {orderId: orderId});
        }
        let completed = false, charged = false;

        this.completing = true;
        return this.marketplaceService.completeOrderItem(orderId, $orderItem).then(() => {
          completed = true;
          this.completing = false;
          this.charging = true;
          return this.$q.when($orderItem.chargeId || this.marketplaceService.chargeCustomer(orderId, $orderItem))
            .then(() => {
              charged = true;
            })
            .catch(resp => {
              charged = resp.wasCharged;
              return this.$q.reject(resp.error);
            });
        }).then(() => {
          this.charging = false;
          this.growl.success('Order item successfully submitted!');
          this.$log.info('An order item was completed', {
            orderId: orderId,
            orderItemId: $orderItem.$id
          }, {
            notify: true,
            type: 'marketplace'
          });
          this.notifications.postToOrg({
            from: this.user.uid,
            to: $orderItem.clientOrgId,
            message: 'Your order was completed: "' + $orderItem.service.title + '"!',
            link: {
              state: 'marketplace.orders.item',
              params: {
                orderId: orderId,
                itemId: $orderItem.$id,
              }
            }
          });
        }).catch((err) => {
          this.$log.error('An error occurred completing the order.', err);
          this.growl.error('Unable to complete order. Please contact customer support for assistance');
          let rollBackPromises = [];

          // If not charged, roll back completed item.
          if (completed && !charged) {
            $orderItem.status = this.CF_ORDER_ITEM_STATUS.PROCESSING;
            rollBackPromises.push($orderItem.$save());
          }

          if (charged && !$orderItem.chargeId) {
            this.$log.error(`Important: An order item was charged but the chargeId was not set on the order item. 
            Place it manually or risk double-charging the customer. 
            OrderId: ${orderId}. OrderItemId: ${$orderItem.$id}`);
          }

          this.$q.all(rollBackPromises)
            .catch(err => {
              this.$log.error('An error occurred rolling back an order submission.', err);
            });
        })
          .finally(() => this.charging = this.completing = false);
      })
      .catch((err) => {
        if (!this.utils.isModalDismissalByUser(err)) { throw err; }
      });
  }

  truncate(text, length) {
    return _.truncate(text, {'length': length});
  }
}

module.exports = Controller;
