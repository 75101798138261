'use strict';

module.exports = function(ngModule) {
  require('./suppliers')(ngModule);
  require('./supplier')(ngModule);
  require('./onboard_supplier_modal')(ngModule);
  require('./import_suppliers_modal')(ngModule);
  require('./request_files')(ngModule);
  require('./file_status_indicator')(ngModule);
};
