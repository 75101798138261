class Controller {
  constructor(authentication, $state) {
    'ngInject';

    this.authentication = authentication;
    this.$state = $state;
  }

  signin(state, params) {
    this.$state.go(state, params);
  }

  signup() {
    this.$state.go('signup.begin', this.$state.params);
  }
}

module.exports =  Controller;
