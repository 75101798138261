module.exports = function(ngModule) {
  class OrgTypes {
    constructor(orgPerspectives) {
      'ngInject';

      this.MANUFACTURER = {
        id: 'manufacturer',
        name: 'Manufacturer / Co-packer / Distributor',
        priority: 1,
        defaultPerspective: orgPerspectives.PO,
        allowablePerspectives: [
          orgPerspectives.ADMIN,
          orgPerspectives.PO
        ]
      };

      this.RESTAURANT = {
        id: 'restaurant',
        name: 'Restaurant',
        priority: 2,
        defaultPerspective: orgPerspectives.PO,
        allowablePerspectives: [
          orgPerspectives.ADMIN,
          orgPerspectives.PO
        ]
      };

      this.ANALYST = {
        id: 'analyst',
        name: 'Food Safety Consultant / Scientist',
        priority: 3,
        defaultPerspective: orgPerspectives.ANALYST,
        allowablePerspectives: [
          orgPerspectives.ADMIN,
          orgPerspectives.ANALYST
        ]
      };

      this.LABORATORY = {
        id: 'laboratory',
        name: 'Laboratory',
        priority: 4,
        defaultPerspective: orgPerspectives.ANALYST,
        allowablePerspectives: [
          orgPerspectives.ADMIN,
          orgPerspectives.ANALYST
        ]
      };

      // this.LEGAL = {
      //   id: 'legal',
      //   name: 'Legal Firm',
      //   priority: 5,
      //   defaultPerspective: orgPerspectives.ANALYST,
      //   allowablePerspectives: [
      //     orgPerspectives.ADMIN,
      //     orgPerspectives.ANALYST
      //   ]
      // };
      //
      // this.REGULATOR = {
      //   id: 'regulator',
      //   name: 'Regulator',
      //   priority: 6,
      //   defaultPerspective: orgPerspectives.ANALYST,
      //   allowablePerspectives: [
      //     orgPerspectives.ADMIN,
      //     orgPerspectives.ANALYST
      //   ]
      // };
    }
  }

  ngModule.service('orgTypes', OrgTypes);
};
