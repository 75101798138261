class Constructor {
  constructor($state, suppliers, $q, supplierService, growl) {
    'ngInject';

    this.$state = $state;
    this.suppliers = suppliers;
    this.$q = $q;
    this.supplierService = supplierService;
    this.growl = growl;
  }

  $onInit() {
    this.supplierId = this.resolve.supplierId;
    this.numIngredients = this.resolve.numIngredients;
    this.passedContacts = this.resolve.contacts;

    this.$q.when(this.resolve.$supplier || this.suppliers.$get(this.supplierId)).then($supplier => {
      this.supplier = $supplier;
      if (!this.passedContacts && this.supplier.contactEmail) {
        this.contacts.unshift({
          name: this.supplier.contactName,
          email: this.supplier.contactEmail
        });
      }
    }).catch(err => this.modalInstance.dismiss(err));

    this.resetNewContact();
    if (this.passedContacts) {
      this.contacts = this.passedContacts;
    } else {
      this.contacts = [];
    }
  }

  resetNewContact() {
    this.newContact = {name: '', email: ''};
  }

  addContact() {
    this.contacts.push({
      name: this.newContact.name,
      email: this.newContact.email
    });
    this.resetNewContact();
  }

  removeContact(index) {
    this.contacts.splice(index, 1);
  }

  goAddIngredients() {
    this.modalInstance.dismiss('cancel');
    this.$state.go('ingredients.list');
  }

  ok() {
    let existingRequests = _.filter(this.supplier.requestFilesTokens,
        existing => _.some(this.contacts, c => c.email === existing.email));

    if (existingRequests.length) {
      this.growl.error(`A request for files is already outstanding for <b>${existingRequests[0].email}</b>. 
      Please remove that contact and try again.`);
      return;
    }
    this.submitting = true;
    this.supplierService.sendFilesRequest(this.supplier, this.contacts).then(tokens => {
      this.growl.success('Files request(s) sent.');
      this.modalInstance.close(tokens);
    }).catch(err => this.utils.defaultErrorHandler(err, 'An error occurred sending the file requests.'))
      .finally(() => {
        this.submitting = false;
      });
  }
}

module.exports = Constructor;
