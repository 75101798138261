module.exports = function (ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('checklists.library', {
        url: '/library',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          user: (authentication) => authentication.requireAuth(),
          company: (user, organizations) => organizations.$get(user.orgContext.id),
        },
        data: {menuId: 'dashboard'},
      })
      .state('checklists.library.list', {
        url: '/list',
        template:
          '<fr-checklist-library-list company="$resolve.company" user="$resolve.user"' +
          'checklist-templates="$resolve.checklistTemplates"></fr-checklist-library-list>',
        resolve: {
          checklistTemplates: (checklistLibraryService, user) =>
            checklistLibraryService.getAllChecklistTemplate(user.orgContext.id),
        },
      })
      .state('checklists.library.edit', {
        url: '/edit?checklistTemplateId?grid',
        params: {checklistTemplate: null},
        template:
          '<fr-checklist-library-edit company="$resolve.company" user="$resolve.user" ' +
          'checklist-template="$resolve.checklistTemplate" form-constants="$resolve.formConstants">' +
          '</fr-checklist-library-edit>',
        resolve: {
          checklistTemplate: (user, checklistLibraryService, $stateParams) => {
            if ($stateParams.checklistTemplateId) {
              return checklistLibraryService.getChecklistTemplate(user.orgContext.id, $stateParams.checklistTemplateId);
            } else if ($stateParams.checklistTemplate) {
              return $stateParams.checklistTemplate;
            } else {
              return null;
            }
          },
          formConstants: (constantsService) => constantsService.get('forms'),
        },
      });
  });
};
