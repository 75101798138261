module.exports = function(ngModule) {
  ngModule.component('cfGroupSelect', {
    bindings: {
      user: '<',
      ngModel: '=',
      ngDisabled: '=',
      onGroupSelected: '&',
      onGroupCreated: '&?',
      groupCreateFailed: '&?'
    },
    template: require('./products.group-select.template.html'),
    controller: require('./products.group-select.controller'),
    controllerAs: 'vm'
  });
};
