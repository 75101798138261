'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfRecallEventsConsigneeImpact', {
    bindings: {
      user: '<',
      customerTypes: '<',
      $customers: '=',
      $event: '=',
      productCustomers: '<'
    },
    template: require('./recall.events.consignee-impact.template.html'),
    controller: require('./recall.events.consignee-impact.controller.js'),
    controllerAs: 'vm'
  });
};
