'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfIngredientOverview', {
    bindings: {
      supplierIngredient: '<',
      ingredient: '<',
      hasReportAccess: '<',
      company: '<',
      user: '<',
      supplier: '<'
    },
    template: require('./ingredients.supplier-ingredient.overview.template.html'),
    controller: require('./ingredients.supplier-ingredient.overview.controller.js'),
    controllerAs: 'vm'
  });
};
