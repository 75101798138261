class Constructor {
  constructor(notifications, growl) {
    'ngInject';

    this.notifications = notifications;
    this.growl = growl;
  }

  $onInit() {
    this.title = this.resolve.title;
    this.user = this.resolve.user;
    this.message = this.resolve.message;
    this.notification = this.resolve.notification;
    this.members = this.resolve.members;
  }

  notify(uid) {
    this.notifications.postToUser(_.assign(this.notification, {
      from: this.user.uid,
      to: uid
    })).then(() => {
      this.growl.success('Notification Sent');
      this.modalInstance.close();
    });
  }
}

module.exports = Constructor;
