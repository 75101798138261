module.exports = function(ngModule) {
  ngModule.component('cfEditFoodProduct', {
    bindings: {
      modalInstance: '<',
      resolve: '<'
    },
    template: require('./food-products.edit-food-product.template.html'),
    controller: require('./food-products.edit-food-product.controller'),
    controllerAs: 'vm'
  });
};
