'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfListSupplierIngredients', {
    bindings: {
      user: '<',
      company: '<',
      ingredients: '<'
    },
    template: require('./ingredients.supplier-ingredients.list.template.html'),
    controller: require('./ingredients.supplier-ingredients.list.controller.js'),
    controllerAs: 'vm'
  });
};