module.exports = function($uibModal, $log, $q, S3, confirmDeleteModal, growl, utils, organizations) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
  };

  vm.editMember = function(memberId) {
    $uibModal.open({
      component: 'cfEditSafetyTeam',
      backdrop: 'static',
      resolve: {
        company: () => vm.company,
        member: () => organizations.$pushSafetyTeamMember(vm.safetyTeam),
        members: () => vm.members
      }
    }).result.then(function() {
      organizations.setMilestoneAchieved(vm.company.$id, organizations.milestones.SAFETY_TEAM);
      growl.success('Member ' + (memberId ? 'updated' : 'added') + ' successfully.', {});
    }).catch(function(reason) {
      if (utils.isModalDismissalByUser(reason)) { return; }

      growl.error('Error ' + (memberId ? 'updating' : 'adding') + ' the team member. Please ' +
        'try again or contact FoodReady customer support.', {});
      $log.error(reason);
    });
  };

  vm.remove = function(member) {
    confirmDeleteModal(member.name).then(function() {
      let filePromises = [];

      _.each(member.media, (file) => {
        filePromises.push(S3.deleteFile(vm.company.$id, file.key));
      });

      vm.safetyTeam.$remove(member)
        .catch((err) => {
          $log.error(err);
          growl.error('Unable to remove the team member. Please ' +
            'try again or contact FoodReady customer support.');
        });

      $q.all(filePromises).catch($log.error);
    });
  };

  vm.save = _.debounce(function(rec) {
    vm.safetyTeam.$save(rec).catch(function(err) {
      $log.error(err);
      growl.error('Unable to save changes.');
    });
  }, 200);
};
