class Controller {
  constructor(states) {
    'ngInject';

    this.authorityType = 'Federal';
    this.states = _.map(states(), (state) => ({
      value: state,
      name: state.abbreviation
    }));
  }

  $onInit() {
    this.user = this.resolve.user;
    this.sops = this.resolve.sops;
    this.planSopIds = this.resolve.planSopIds;
    this.planTypeText = this.resolve.planTypeText;
    let defaultState = _.find(this.states, {name: this.resolve.defaultState});

    this.hasLogPermissions = this.user.doesSubscriptionPermit('logs');
    this.sopsSelection = 'plan';
    this.recordsSelection = 'none';

    this.state = !defaultState ? undefined : defaultState.value;
    this.planStatusPage = 'true';
  }

  submit() {
    let sops;

    if (this.sopsSelection === 'some') {
      sops = _.map(this.chosenSops, chosen => chosen.$id);
    } else if (this.sopsSelection === 'plan') {
      sops = this.planSopIds;
      if (_.isEmpty(sops)) {
        this.sopsSelection = 'none';
      }
    }

    this.modalInstance.close({
      authorityType: this.authorityType,
      state: this.state ? this.state.abbreviation : undefined,
      noSops: this.sopsSelection === 'none' ? true : undefined,
      sops,
      noLogs: this.recordsSelection === 'none' ? true : undefined,
      equipmentSpecs: true,
      sampleLogForms: this.recordsSelection === 'sample' ? true : undefined,
      planStatusPage: this.planStatusPage === 'true'
    });
  }
}

module.exports = Controller;
