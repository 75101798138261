class Controller {
  constructor($stateParams, notifications, $state, $timeout, growl, $log, notificationScopes, confirmDeleteModal,
              $uibModal, utils, orgPerspectives, $q, organizations, accessRequests, notificationTypes,
              companySubscription) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.notifications = notifications;
    this.$state = $state;
    this.$timeout = $timeout;
    this.growl = growl;
    this.$log = $log;
    this.notificationScopes = notificationScopes;
    this.confirmDeleteModal = confirmDeleteModal;
    this.$uibModal = $uibModal;
    this.utils = utils;
    this.orgPerspectives = orgPerspectives;
    this.organizations = organizations;
    this.$q = $q;
    this.accessRequests = accessRequests;
    this.notificationTypes = notificationTypes();
    this.companySubscription = companySubscription;
    this.notificationFilter = 'all';
  }

  $onInit() {
    if (this.$stateParams.activate && this.user.isTrialing()) {
      this.companySubscription.activate(this.user);
    }

    this.notificationSearch = this.notifications.getSearch(this.user);
    this.getMoreNotifications = _.debounce(this.notificationSearch.getMore.bind(this.notificationSearch), 200,
      {leading: true, trailing: false});

    this.notificationSearch.setUser(this.user.uid);
    this.notificationSearch.setOrganizations([this.user.orgContext.id]);
    this.notificationSearch.search();
  }

  /**
   * This user's organization was created to back a supplier record by another CF org. Display an onboarding modal to
   * ensure the access request is addressed immediately and to see whether they would prefer to upload their docs.
   * @param {string} createdByOrg The org Id who created an invitation for this user's organization
   * @returns {Promise} A promise that is resolved when the supplier completes onboarding
   */
  supplierOnboarding(createdByOrg) {
    let notificationSearch = this.notifications.getSearch(this.user);

    notificationSearch.setUser(this.user.uid);
    notificationSearch.setOrganizations([this.user.orgContext.id]);
    notificationSearch.setType(this.notificationTypes.ACCESS_REQUEST.id);

    return notificationSearch.search().then(result => {
      let firstNotification = _.first(result);

      if (result.length > 1) {
        this.$log.warn('More than one access requests exist for an invited supplier.');
      }

      return firstNotification ?
        this.accessRequests.$get(firstNotification.metaData.accessRequestId)
          .then($accessRequest => ({
            $accessRequest: $accessRequest,
            accessRequestNotification: firstNotification
          })) : null;
    }).then(result => {
      let noAccessRequest = !result || !result.$accessRequest ||
        result.$accessRequest.organizationId !== createdByOrg || result.$accessRequest.status !== 'pending';
      let notification = noAccessRequest ? null : result.accessRequestNotification;

      return this.$uibModal.open({
        component: 'cfSupplierOnboardingModal',
        backdrop: 'static',
        resolve: {
          user: () => this.user,
          accessRequestNotification: () => notification
        }
      }).result;
    });
  }

  filterNotifications() {
    let body = this.notificationSearch.getSearchQuery();

    if (!_.isArray(_.get(body, 'query.bool.must_not'))) {
      body.query.bool['must_not'] = [body.query.bool.must_not];
    }

    if (this.notificationFilter === 'all') {
      delete body.query.bool.must;
      _.remove(_.get(body, 'query.bool.must_not', []), (item) => item.term.read);
    } else if (this.notificationFilter === 'unread') {
      delete body.query.bool.must;
      body.query.bool.must_not.push({term: {read: this.user.uid}});
    } else if (this.notificationFilter === 'read') {
      _.remove(_.get(body, 'query.bool.must_not', []), (item) => item.term.read);
      body.query.bool.must = body.query.bool.must || [];
      body.query.bool.must.push({
        term: {read: this.user.uid}
      });
    }

    this.notificationSearch.setSearchQuery(body);
    this.notificationSearch.search();
  }

  markNotificationsRead() {
    this.notifications.markAllRead()
      .then(() => {
        _.each(this.notificationSearch.searchResults, (n) => {
          n.read = n.read || [];
          n.read.push(this.user.uid);
        });
      }).catch(err => {
        this.$log.error('Error deleting all notifications: ' + err);
        this.growl.error('An error occurred deleting your notifications.');
      });
  }

  deleteAllNotifications() {
    this.confirmDeleteModal('all notifications', {confirmText: 'Delete'})
      .then(() =>{
        this.notifications.smartDeleteAll().then(() => {
          delete this.notificationSearch.searchResults;
        }).catch(err => {
          this.$log.error('Error deleting all notifications: ' + err);
          this.growl.error('An error occurred deleting your notifications.');
        });
      });
  }
}

module.exports = Controller;
