require('./_css');

module.exports = function(parentModuleName) {
  let moduleName = parentModuleName + '.users',
    ngModule = angular.module(moduleName, []);
  require('./_config')(ngModule);
  require('./_services')(ngModule);
  require('./_directives')(ngModule);
  require('./_components')(ngModule);
  require('./_validators')(ngModule);

  return moduleName;
};
