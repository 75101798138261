class Constructor {
  constructor(
    products,
    $q,
    organizations,
    $log,
    productCategories,
    $state,
    utils,
    $timeout,
    companyTypes,
    countryPhoneCodes
  ) {
    'ngInject';
    this.products = products;
    this.$q = $q;
    this.organizations = organizations;
    this.$log = $log;
    this.productCategories = productCategories;
    this.$state = $state;
    this.utils = utils;
    this.$timeout = $timeout;
    this.companyTypes = companyTypes();
    this.countryPhoneCodes = countryPhoneCodes();
    this.sources = {
      TEMPLATE: 1,
      BLANK: 2,
    };
  }

  $onInit() {
    this.product = this.products.newProduct(this.user);
    this.product.categoryId = this.product.categoryId || this.$state.params.productCategory;
    this.product.brandName = 'New Plan';
    this.source = this.sources.TEMPLATE;
    this.companyOnboarding = {
      companyName: this.company.companyName,
      type: this.company.type || 'manufacturer',
      location: this.company.location || 'single',
    };

    if (this.company.location === 'multi' && this.company.locationCount) {
      this.companyOnboarding.locationCount = this.company.locationCount;
    }

    _.assign(this.user, _.pick(this.$user, ['email', 'firstName', 'lastName', 'phone']));
    this.noEmail = !this.user.email;

    let countryCodeObj = {};

    if (!this.user.phone || this.user.phone.slice(0, -10) === '') {
      countryCodeObj = {name: '+1 United States', code: '+1'};
    } else {
      countryCodeObj = this.countryPhoneCodes.find((countryCode) => countryCode.code === this.user.phone.slice(0, -10));
      this.user.phone = this.user.phone.slice(-10);
    }

    this.countryCode = countryCodeObj;
    this.user.countryCode = countryCodeObj.code;
  }

  skip() {
    this.saveCompanyDetails().then(() => {
      this.$log.info('onboarding:firstPlan - skip.');
      return this.$state.go('user.dashboard');
    });
  }

  saveCompanyDetails() {
    this.company.companyName = this.companyOnboarding.companyName;
    this.company.type = this.companyOnboarding.type;
    this.company.location = this.companyOnboarding.location;

    if (this.companyOnboarding.location === 'multi') {
      this.company.locationCount = this.companyOnboarding.locationCount;
    }

    let phoneWithCode = this.user.countryCode + this.user.phone.toString();

    this.$user.email = this.user.email;
    this.$user.firstName = this.user.firstName;
    this.$user.lastName = this.user.lastName;
    this.$user.phone = phoneWithCode;
    this.company.pointOfContactName = this.company.pointOfContactName || this.user.firstName + ' ' + this.user.lastName;
    this.company.pointOfContactEmail = this.company.pointOfContactEmail || this.user.email;
    this.company.phone = this.company.phone || phoneWithCode || null;
    return this.$q
      .all([this.$user.$save(), this.company.$save()])
      .then(() => {
        this.user.setOrgContext(this.user.orgContext.id);
        if (this.companyInfoForm) {
          this.companyInfoForm.$setPristine();
        }
      })
      .catch((err) => this.$log.error('An error occurred in onboarding company info', this.$log.toString(err)));
  }

  startPlan() {
    this.saving = true;
    this.product.categoryId = this.product.categoryId || null;
    const rec = this.product.categoryId && _.find(this.foodCategories, (c) => c.id === this.product.categoryId);

    if(this.company.restrictTemplates) {
      this.source = this.sources.BLANK;
    }
    this.products
      .create(this.product)
      .then((productId) => {
        const templatePromise =
          this.source === this.sources.TEMPLATE ?
            this.products.loadTemplate(
              {
                organizationId: this.company.$id,
                $id: productId,
              },
              this.template.orgId,
              this.template.value,
              {
                hazards: true,
                ingredients: true,
                equipment: true,
                planAnalysis: true,
              }
            ) :
            null;

        _.set(this.company, 'onboarding.questionnaire', {});
        this.company.onboarding.productName = this.product.brandName;
        this.company.onboarding.loadedTemplate = _.get(this.template, 'value', null);
        this.company.onboarding.productCategory = this.product.categoryId || null;
        this.company.onboarding.productSubCategory = this.product.subCategoryId || null;
        this.company.onboarding.productId = productId;

        return this.$q
          .all([
            templatePromise,
            rec && this.organizations.setProductCategory(this.product.organizationId, rec.group + ': ' + rec.name),
            this.saveCompanyDetails(),
          ])
          .then(() => {
            this.$log.info('onboarding:firstPlan - completed.');
            this.$state.go('products.edit', {productId});
          });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to start plan.'))
      .finally(() => {
        this.saving = false;
      });
  }

  categorySelected($item) {
    this.product.categoryId = $item.id;
    this.subCategories = _.map($item.subCategories, (sub, id) => _.assign({id}, sub));
    this.selectedSubCategory = null;
  }

  subCategorySelected($item) {
    this.product.subCategoryId = $item.id;
  }

  countryCodeSelected($item) {
    this.user.countryCode = $item.code;
  }
}

module.exports = Constructor;
