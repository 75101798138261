module.exports = function(ngModule) {
  ngModule.factory('orgFileManager', function($uibModal, $q, $log, S3, growl, confirmDeleteModal, $firebaseObject, fileBucketService) {
    return {
      upload: function(orgId, existingFiles, uploadPath, includeFilenameInUploadKey,
        title, fileRef, alertHtml, alertClass) {

        return $firebaseObject(fileRef).$loaded().then(loadedFile => {
          return $uibModal.open({
            component: 'cfMediaUploadModal',
            backdrop: 'static',
            resolve: {
              title: () => title,
              orgId: () => orgId,
              file: () => loadedFile,
              existingFiles: () => existingFiles,
              uploadKey: () => uploadPath,
              includeFilenameInUploadKey: () => includeFilenameInUploadKey,
              alertHtml: () => alertHtml,
              alertClass: () => alertClass
            }
          }).result;
        });
      },
      remove: function(file, orgId, existingFiles, showGrowlMessages) {
        confirmDeleteModal(_.truncate(file.name, {length: 30}))
          .then(() => {
            return existingFiles.$remove(file);
          })
          .then(() => {
            return S3.deleteFile(orgId, file.key)
              .catch((err) => {
                existingFiles.$add(_.pick(file, ['url', 'key', 'name', 'size', 'type']));
                return $q.reject(err);
              });
          })
          .then((result) => {
            if(showGrowlMessages) { growl.success('File successfully removed.'); }
            return result;
          })
          .catch((err) => {
            if(showGrowlMessages) {
              growl.error('Unable to delete file. Please try again or contact customer support for assistance.');
            }

            $log.error(err);

            return $q.reject(err);
          });
      },
      getAll: function (orgId) {
        return fileBucketService.getAllFiles(orgId).then((files) => Object.values(files));
      }
    };
  });
};
