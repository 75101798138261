class Constructor {
  constructor(utils, productSearch, products, supplierIngredientService, authorization, messageServices, CF_ROLES,
              $q, growl) {
    'ngInject';

    this.productSearchService = productSearch;
    this.products = products;
    this.utils = utils;
    this.supplierIngredientService = supplierIngredientService;
    this.authorization = authorization;
    this.messageServices = messageServices;
    this.CF_ROLES = CF_ROLES;
    this.$q = $q;
    this.growl = growl;
    this.options = {
      EXISTING: 'existing',
      NEW: 'new',
      NONE: 'none'
    };

    this.associateHelp = 'When you associate an ingredient with your product, you establish a ' +
      'link between your partner\'s ingredient and your product record on FoodReady. You don\'t have ' +
      'to re-upload files that were already uploaded to your product. Also, when you add a file to your ' +
      'product plan, your partner may elect to be notified, keeping them up-to-date.';
    this.permissionsHelp = 'Granting <b>File/Report Read</b> permission means your partner will be able to read ' +
      'the files and reports associated with this product plan only.';
  }

  $onInit() {
    this.token = this.resolve.token;
    this.ingredientId = this.resolve.ingredientId;
    this.user = this.resolve.user;

    this.newProduct = this.products.newProduct(this.user);
    this.step = 'associate';
    this.ingredientRec = _.find(this.token.ingredients, i => i.id === this.ingredientId);
    if (!this.ingredientRec) {
      throw new Error('Invalid ingredient Id');
    }
    this.newProduct.brandName = this.ingredientRec.brandName;
    this.productSearch = new this.productSearchService.ProductSearch(this.user, [this.user.orgContext.id]);
    this.productSearch.searchFields(['brandName']);
  }

  associateDecision() {
    let prodIdPromise;

    switch(this.associationDecision) {
    case this.options.EXISTING:
      if (!this.associatedProduct) {
        this.showNoProdWarning = true;
        return;
      }
      prodIdPromise = this.associatedProduct.$id;
      break;
    case this.options.NEW:
      if (!this.newProduct.brandName) {
        this.showNoProdWarning = true;
        return;
      }
      prodIdPromise = this.products.create(this.newProduct);
      break;
    case this.options.NONE:
      this.modalInstance.close();
      return;
    default:
      throw new Error('Invalid option');
    }

    this.$q.when(prodIdPromise).then(prodId => {
      this.associatedProductId = prodId;
      return this.supplierIngredientService.createAssociation(this.ingredientId, this.user.orgContext.id, prodId);
    }).then(() => { this.step = 'permission'; })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred creating a product/ingredient association'));
  }

  authorize() {
    this.authorization.assignOrgToOrganization(this.user.uid, this.token.organizationId,
      this.user.orgContext.id, this.CF_ROLES.LINKED_ORG)
      .then(() => this.authorization.addOrgProductExceptions(this.token.organizationId, this.user.orgContext.id,
        this.associatedProductId, [this.authorization.claims.REPORTS_READ, this.authorization.claims.FILES_READ]))
      .then(() => this.messageServices.getOrgToOrgTopic(this.user, this.token.organizationId))
      .then(() => {
        this.growl.success('Permissions granted.');
        this.modalInstance.close();
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred granting permissions.'));
  }
}

module.exports = Constructor;
