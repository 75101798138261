class Constructor {
  constructor(growl, filePortalService, utils, products, constantsService, organizations) {
    'ngInject';

    this.growl = growl;
    this.filePortalService = filePortalService;
    this.utils = utils;
    this.products = products;
    this.constantsService = constantsService;
    this.organizations = organizations;
  }

  $onInit() {
    this.user = this.resolve.user;
    this.requesteeOrg = this.resolve.requesteeOrg;
    this.contacts = this.resolve.contacts;
    this.productId = this.resolve.productId;

    this.organizations.getMiniProfile(this.requesteeOrg).then(profile => {
      this.requesteeOrgProfile = profile;
    });

    this.loadingFileTypes = true;
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.fileTypes = fileTypes;
      this.companyFileTypes = _.pickBy(this.fileTypes, f => f.companyLevel);
      if (this.productId) {
        this.productFileTypes = _.omitBy(this.fileTypes, f => f.companyLevel);
      }
    }).finally(() => {
      this.loadingFileTypes = false;
    });

    this.products.getBrandName(this.productId).then(name => {
      this.productName = name;
    });
    this.step = 1;

    this.resetNewContact();
  }

  chooseFiles() {
    let selectedCompany = _.pickBy(this.companyFileTypes, f => f.selected);
    let selectedProduct = _.pickBy(this.productFileTypes, f => f.selected);

    if (_.isEmpty(selectedCompany) && _.isEmpty(selectedProduct)) {
      this.growl.error('You must select at least one file.');
      return;
    }

    this.selectedCompanyFileTypes = _.isEmpty(selectedCompany) ? null :
      _.map(selectedCompany, x => x.id).join(',');
    this.selectedProductFileTypes = _.isEmpty(selectedProduct) ? null :
      _.map(selectedProduct, x => x.id).join(',');
    this.step = 2;
  }

  addContact() {
    this.contacts.push({
      name: this.newContact.name,
      email: this.newContact.email
    });

    this.resetNewContact();
  }

  removeContact(index) {
    this.contacts.splice(index, 1);
  }

  resetNewContact() {
    this.newContact = {name: '', email: ''};
  }

  requestFiles() {
    this.requesting = true;

    this.filePortalService.createPortals(this.requesteeOrgProfile.id, this.contacts, {
      requiredOrgFiles: this.selectedCompanyFileTypes,
      requiredProductFiles: this.productId ? this.selectedProductFileTypes : null,
      productId: this.productId || null,
    }).then(result => this.modalInstance.close(result))
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred submitting your request for files.'))
      .finally(() => { this.requesting = false; });
  }
}

module.exports = Constructor;
