module.exports = function(ngModule) {
  ngModule.component('cfListNotifications', {
    bindings: {
      notificationSearch: '<',
      user: '<'
    },
    template: require('./notifications.list.template.html'),
    controller: require('./notifications.list.controller'),
    controllerAs: 'vm'
  });
};
