module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('operations.sops', {
        url: '/sops',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          sopTypes: (constantsService) => constantsService.get('sopTypes'),
          sops: ($log, sopService, user) => sopService.query(user.orgContext.id, null, {isFacilitySop: true})
            .catch((err) => {
              $log.error('Unable to query SOPs.', $log.toString(err));

              return [];
            })
        }
      })
      .state('operations.sops.list', {
        url: '',
        template: '<cf-sop-list user="$resolve.user" sops="$resolve.sops" company="$resolve.company"' +
          'sop-types="$resolve.sopTypes" are-templates="false"></cf-sop-list>'
      })
      .state('operations.sops.addLog', {
        url: '/newLog/:sopLogId?sopId',
        controllerAs: 'vm',
        controller: function($scope, $state, sops, $sop) {
          $scope.backState = {name: 'operations.sops.list'};

          $scope.onSave = function() {
            let sop = _.find(sops, {$id: $sop.$id});

            if (sop) {
              sop.lastLog = $sop.lastLog;
            }
            $state.go('operations.sops.list');
          };
          $scope.onCancel = function() {
            $state.go('operations.sops.list');
          };
        },
        templateProvider: ($sop) => {
          let temp = '<cf-headline heading="\'New SOP Log Form\'" sub-heading="$resolve.$sop.title"' +
            'back-text="\'back to list of SOPs\'" back-state="backState"></cf-headline>';

          switch ($sop.metadata.logFormType) {
          case 'employeeTrainId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-employee-train user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-employee-train>';
          case 'equipCleanId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-equipment-clean user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-equipment-clean>';
          case 'pHCalId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-ph-calibration user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-ph-calibration>';
          case 'FFTempId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-fridge-freezer-temp user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-fridge-freezer-temp>';
          case 'thermoCalibId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-thermometer-calibration user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-thermometer-calibration>';
          case 'thermoValidId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-thermometer-validation user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-thermometer-validation>';
          case 'grindBladeId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-grinding-blade user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-grinding-blade>';
          case 'transportProductId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-transporting-product user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-transporting-product>';
          case 'coldChainId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-cold-chain user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-cold-chain>';
          case 'fishReceivingId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-fish-receiving user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-fish-receiving>';
          case 'coldChainId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-cold-chain user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-cold-chain>';
          case 'antiMicrobialId':
            return temp + '' +
                '<cf-sop-log-edit-form-type-anti-microbial user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-anti-microbial>';
          default:
            return temp + '' +
                '<cf-sop-log-edit-form-type-pass-fail user="$resolve.user" $sop-log="$resolve.$sopLog" ' +
                'sop-logs="$resolve.sopLogs" $sop="$resolve.$sop" on-save="onSave()" on-cancel="onCancel()">' +
                '</cf-sop-log-edit-form-type-pass-fail>';
          }
        },
        resolve: {
          $sop: (sopService, $stateParams) => sopService.$get($stateParams.sopId),
          $sopLog: (sopLogsService, user, $stateParams, $sop) => sopLogsService.$get($stateParams.sopLogId)
            .then($sopLog => {
              _.assign($sopLog, {
                sopId: $sop.$id
              });
              return $sopLog;
            })
        }
      })
      .state('operations.sops.edit', {
        url: '/edit/:sopId?templateId?typeId?goBack?close',
        template: '<cf-sop-edit user="$resolve.user" $sop="$resolve.$sop" origin="procedures"' +
          'sop-types="$resolve.sopTypes" sops="$resolve.sops" is-template="false" ' +
          'type-id="$resolve.typeId" company="$resolve.company"  approval-template="$resolve.approvalTemplate"></cf-sop-edit>',
        resolve: {
          $sop: (sopService, user, $stateParams) => sopService.$get($stateParams.sopId)
            .then(($sop) => {
              if (!_.isEmpty($stateParams.templateId)) {
                return sopService.$get($stateParams.templateId)
                  .then(($template) => sopService.applyTemplate($sop, $template));
              } else if (!_.isEmpty($stateParams.typeId)) {
                $sop.typeId = $stateParams.typeId;
                return $sop;
              }
              return $sop;
            }),
          typeId: ($stateParams) => $stateParams.typeId,
          approvalTemplate: (constantsService) => constantsService.get('template'),
        }
      });
  });
};
