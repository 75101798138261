'use strict';

module.exports = function($state, $firebaseObject, $timeout, confirmDeleteModal, $log, growl, $uibModal,
                          moment, fbutil, labTestStatus, verificationTypes, S3, $window, $stateParams) {
  'ngInject';

  const vm = this;

  function refreshTeamNames() {
    _.each(vm.verifications, (verification) => {
      if (vm.teamNames[verification.laboratory]) { return; }

      if (verification.type === verificationTypes.INTERNAL) {
        vm.teamNames[verification.laboratory] = 'Internal';
      } else if (!verification.laboratory) {
        vm.teamNames[verification.laboratory] = 'FoodReady Partner';
      } else if (!verification.bidsRequested) {
        vm.teamNames[verification.laboratory] =
          vm.productOrganization.laboratories[verification.laboratory].name;
      } else {
        fbutil.ref('teams', verification.laboratory, 'name')
          .once('value')
          .then((snap) => {
            $timeout(() => {
              vm.teamNames[verification.laboratory] = snap.val();
            });
          });
      }
    });
  }

  vm.$state = $state;

  vm.$onInit = function() {
    vm.teamNames = {};
    vm.vvName = vm.substituteName || 'verification';
    vm.vvNameTitle = vm.vvName.charAt(0).toUpperCase() + vm.vvName.slice(1);
    vm.externalOnly = vm.substituteName === 'properties';
    vm.labTestStatus = labTestStatus;
    vm.verificationTypes = verificationTypes;

    vm.searching = true;
    vm.verificationSearch.executeSearch()
      .then((verifications) => {
        vm.verifications = verifications;
        _.forEach(vm.verifications, verification => {
          if (!verification.labTestStatus) {
            verification.labTestStatus = labTestStatus.INIT.id;
          }
        });
      })
      .finally(() => {
        vm.searching = false;
        refreshTeamNames();
      });
  };

  vm.editMode = function() {
    return _.last($state.current.name.split('.')) === 'edit';
  };

  vm.formatDate = function(date) {
    return moment(date).format('M/D/YYYY h:mm A');
  };

  vm.getLabName = function(verification) {
    if (verification.type === verificationTypes.INTERNAL) { return 'Internal'; }
    if (!verification.laboratory) { return 'Not Chosen'; }

    if (!verification.bidsRequested) {
      return vm.productOrganization.laboratories[verification.laboratory].name;
    }

    vm.teamNames = vm.teamNames || {};
    fbutil.ref('teams', verification.laboratory, 'name')
      .once('value')
      .then((snap) => {
        $timeout(() => {
          vm.teamNames[verification.laboratory] = snap.val();
        });
      });

    return vm.teamNames[verification.laboratory];
  };

  vm.remove = function(verification) {
    confirmDeleteModal(vm.vvName + ' from ' + moment(verification.createdOn).format('M/D/YYYY h:mm A'))
      .then(() => fbutil.ref('verifications', verification.$id).remove())
      .then(() => {
        _.remove(vm.verifications, (v) => {
          return v.$id === verification.$id;
        });
      })
      .catch((err) => {
        if (err === 'cancel') { return; }

        $log.error('Failed to delete verification: ' + err);
        growl.error('Failed to delete ' + vm.vvName);
      });
  };

  vm.edit = function(verRec) {
    $uibModal.open({
      template: '<cf-edit-verification-modal verification="vm.verification" product="vm.product" ' +
        'product-organization="vm.productOrganization" external-only="vm.externalOnly" user="vm.user" ' +
        'substitute-name="{{vm.substituteName}}" title="{{vm.title}}" $uib-modal-instance="vm.$uibModalInstance">' +
        '</cf-edit-verification-modal>',
      controller: function(verification, $uibModalInstance) {
        this.verification = verification;
        this.product = vm.product;
        this.productOrganization = vm.productOrganization;
        this.substituteName = vm.substituteName;
        this.externalOnly = vm.externalOnly;
        this.title = vm.title;
        this.isProperties = vm.target === 'properties';
        this.$uibModalInstance = $uibModalInstance;
        this.user = vm.user;

        if (verification.$value === null) {
          _.assign(verification, {
            productId: vm.product.$id,
            productName: vm.product.brandName,
            stepId: $stateParams.processStepId || null,
            criticalLimitId: $stateParams.criticalLimitId || null,
            hazardId: $stateParams.hazardId || null,
            organizationId: vm.product.organizationId,
            target: vm.target,
            type: vm.externalOnly ? verificationTypes.EXTERNAL : undefined,
            createdOn: firebase.database.ServerValue.TIMESTAMP,
            state: $state.current.name,
            stateParams: $state.params
          });
        }
      },
      bindToController: true,
      controllerAs: 'vm',
      backdrop: 'static',
      resolve: {
        verification: function($firebaseObject) {
          let verRef = verRec ? fbutil.ref('verifications', verRec.$id) :
            fbutil.ref('verifications').push();

          return $firebaseObject(verRef).$loaded();
        }
      }
    })
      .result
      .then(updatedVerification => {
        if (updatedVerification.$value === null) { return; }

        vm.verifications = vm.verifications || [];

        if (!verRec) {
          vm.verifications.unshift(updatedVerification);
        } else {
          delete updatedVerification.bids;
          _.assign(verRec, updatedVerification);
        }

        refreshTeamNames();
      });
  };

  vm.viewBids = function(verification) {
    $uibModal.open({
      component: 'cfVerificationBids',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        verification: () => $firebaseObject(fbutil.ref('verifications', verification.$id)).$loaded(),
        companyName: () => vm.productOrganization.companyName,
        brandName: () => vm.product.brandName,
        user: () => vm.user,
        title: () => vm.substituteName
      }
    })
      .result
      .then((result) => _.assign(verification, result));
  };

  vm.getStatus = function(statusId) {
    vm.statuses = vm.statuses || {};
    if (!vm.statuses[statusId]) {
      vm.statuses[statusId] = _.find(labTestStatus, {id: statusId});
    }

    return vm.statuses[statusId];
  };

  vm.openTestResults = function(verification) {
    if (!_.get(verification, 'testResults.key')) { return; }

    S3.openSignedUrlInNewTab(verification.organizationId, verification.testResults.key);
  };

  vm.makePlural = function(name) {
    if (name === 'properties') { return name; }

    return name + 's';
  };
};
