'use strict';

require('./radial-menu.scss');

module.exports = function(ngModule) {
  ngModule.component('cfRadialMenu', {
    transclude: true,
    bindings: {
      isOpen: '=',
      radius: '<',
      circumference: '<', // A number from 0 to 1 (1 being a whole circle)
      initialAngle: '<',
      itemSelector: '@'
    },
    template: require('./radial-menu.template.html'),
    controller: require('./radial-menu.controller'),
    controllerAs: 'vm'
  });
};