'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfUploadPortalIngredientSetupModal', {
    bindings: {
      resolve: '<',
      modalInstance: '<'
    },
    template: require('./file-portal.ingredient-setup.template.html'),
    controller: require('./file-portal.ingredient-setup.controller.js'),
    controllerAs: 'vm'
  });
};