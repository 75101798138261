module.exports = function(ngModule) {
  ngModule.factory('email', function($http) {
    'ngInject';

    const baseUri = 'emails';

    return {
      sendTemplate: function(recipient, templateName, templateFields) {
        return $http.post(baseUri, {
          recipient: recipient,
          templateName: templateName,
          templateContent: templateFields
        });
      },

      get: function(id) {
        return $http.get(`${baseUri}/${id}`).then(result => result.data);
      }
    };
  });
};
