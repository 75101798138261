class CfInputWithUnit {
  constructor() {
    this.restrict = 'E';
    this.template = require('./cf-input-with-unit.template.html');
    this.require = 'ngModel';
    this.replace = true;
    this.bindToController = {
      ngModel: '=',     // The form control model reference
      selectNgModel: '=',    // The form control selector model reference
      type: '@?',            // The type of input group (options: ['input'], default = 'input)
      label: '@?',           // The label text
      class: '@?',           // The container class
      icon: '@',             // The icon to append
      name: '@',             // The name of the form control
      min: '@',               // Min value of input (if input type = number)
      max: '@',               // Max value of input (if input type = number)
      appendDirection: '@?', // Append the icon to the right or the left (options: ['left', 'right'], default = 'left')
      helpText: '@?',        // Optional subscript helper text
      isRequired: '=',       // Is the field required (true / false)
      isDisabled: '=',       // Is the field disabled (true / false)
      isReadonly: '=',       // Is the field read-only (true / false)
      appendSelector: '=',   // Append a selector
      size: '@',             // Size of input/selector (default to 'md')
      selectorOptions: '=',    // The select options
    };
    this.controllerAs = 'vm';
    this.scope = {};
  }

  controller() {
    'ngInject';
  }

  link(scope, elem, attrs, ngModelCtrl) {
    let vm = scope.vm;

    vm.containerClass = vm.class || 'g-mb-20';
    vm.type = vm.type || 'text';
    vm.appendDirection = vm.appendDirection || 'left';
    vm.size = vm.size || 'md ';

    let $formElement = elem.find('input');

    $formElement.focus(() => $formElement.parent().addClass('g-state-focus'));
    $formElement.blur(() => $formElement.parent().removeClass('g-state-focus'));

    let $select = elem.find('select');

    let listener = scope.$watch(() => ngModelCtrl.$viewValue, (val) => {
      if (_.isObject(val)) {
        let selectedOption = _.find(vm.selectorOptions, (option) =>
          _.isEqual(_.omit(option.value, ['$$hashKey']), _.omit(val, ['$$hashKey'])));

        if (!selectedOption) { return; }
        let $selectedOption = _.find($select[0].options, (option) =>
          _.isEqual(option.innerText, selectedOption.name));

        $select.val($selectedOption.value);
      }

      listener();
    });
  }
}

module.exports = CfInputWithUnit;
