'use strict';

module.exports = function(ngModule) {
  ngModule.directive('bindHtmlCompile', function($compile) {
    'ngInject';

    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        scope.$watch(function() {
          return scope.$eval(attrs.bindHtmlCompile);
        }, function(value) {
          element.html(value);
          $compile(element.contents())(scope);
        });
      }
    };
  });
};
