class Controller {
  constructor($state, $log, $q, recallEventsService, utils, confirmDeleteModal, growl, users) {
    'ngInject';

    this.$state = $state;
    this.$log = $log;
    this.$q = $q;
    this.recallEventsService = recallEventsService;
    this.utils = utils;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.users = users;
    this._ = _;

    this.page = 1;
    this.maxReached = false;
    this.searching = false;

    this.PAGE_SIZE = 100;
  }

  $onInit() {
    this.noEvents = _.get(this, 'events.length') === 0;
    this.maxReached = _.get(this, 'events.length', 0) < this.PAGE_SIZE;
    this.search = _.debounce(this._search, 300);
    this.usersMap = {};
    this.onSearchComplete(this.events);
    this.loadMore = _.debounce(() => this.fetchEvents()
      .then((result) => { this.events = _.concat(this.events, result); }), 300);
  }

  _search() {
    this.page = 0;
    this.events = [];
    this.searching = true;
    return this.fetchEvents()
      .then((results) => {
        this.events = results;
        this.onSearchComplete(this.events);
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to fetch recall events.'))
      .finally(() => { this.searching = false; });
  }

  onSearchComplete(events) {
    _.each(events, (event) => {
      if (_.isUndefined(this.usersMap[event.createdBy])) {
        this.usersMap[event.createdBy] = '';
        this.users.getName(event.createdBy)
          .then((name) => this.usersMap[event.createdBy] = name || 'Unknown');
      }
    });
  }

  fetchEvents() {
    this.searching = true;
    let options = {};

    if (this.searchText) {
      options.searchText = this.searchText;
    }

    return this.recallEventsService.queryEvents(this.user.orgContext.id, options,
      this.PAGE_SIZE * this.page++, this.PAGE_SIZE)
      .then((events) => {
        events = events || [];
        this.maxReached = events.length < this.PAGE_SIZE;

        return events;
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred loading recall events.'))
      .finally(() => this.loading = false);
  }

  searchKeyPress($event) {
    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) { this.search(); }

      return;
    }

    if (this.utils.isBenignKeyUp($event.keyCode) ||
      this.searchText && this.searchText.length <= 3) { return; }

    this.search();
  }

  editEvent(event) {
    this.$state.go('recall.events.edit.reason', {eventId: event ? event.$id : this.recallEventsService.push().key});
  }

  deleteEvent(event) {
    return this.confirmDeleteModal('Recall Event', {
      body: 'Are you sure you want to delete the recall event: ' +
        `<strong>${event.title}</strong>?`
    }).then(() => {
      this.recallEventsService.remove(event.$id)
        .then(() => {
          _.remove(this.events, {$id: event.$id});
          this.noEvents = !this.events.length;
          this.growl.success('Recall event deleted');
        })
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete recall event.'));
    });
  }
}

module.exports = Controller;
