module.exports = function(ngModule) {
  ngModule.factory('order', ($log, $q, $firebaseArray, users, billing, orders, companySubscription) => {
    'ngInject';

    // Note: if the `angular-payment` directive(s) are used for collecting payment information, we need to transform
    // a few of the card properties into fields compatible with Stripe.
    function dehydrateCard(card) {
      const result = _.pick(card, ['number', 'cvc', 'name', 'address_line1', 'address_line2', 'city', 'zip']);

      result['address_state'] = card.address_state || card.state.abbreviation || card.state;
      result['exp_month'] = card.exp_month || card.expiry.month;
      result['exp_year'] = card.exp_year || card.expiry.year;

      return result;
    }

    function createCustomer(order, payment, user) {
      return billing.createCustomer(order.organizationId, {email: user.email, metadata: {userId: user.uid}})
        .then((customer) => users.setCustomerId(user, customer.id).then(() => customer))
        .then((customer) => payment.customer = customer);
    }

    function addCard(order, payment, customer) {
      return billing.addCard(order.organizationId, customer.id,
        dehydrateCard(payment.source), {nickname: payment.nickname})
        .then((source) => {
          payment.sourceId = source.id;
          order.removeCard = payment.removeCard;

          return source;
        });
    }

    function checkForSubscription(order, user) {
      let itemWithPlan = _.findLast(order.items, (item) => !_.isEmpty(item.planId));

      // if there is no subscription plan in the order return null
      if (!itemWithPlan) { return $q.when(); }

      return companySubscription.changePlan(user, itemWithPlan.planId, {
        coupon: itemWithPlan.coupon,
        // eslint-disable-next-line camelcase
        trial_end: 'now',  // Don't do a trial
        quantity: itemWithPlan.quantity
      })
      .then(subscription => {
        itemWithPlan.subscription = subscription;

        return subscription;
      });
    }

    function isComplete(order) {
      return !_.find(order.items, function(item) { return _.isEmpty(item.chargeId); });
    }

    return {
      // note: each order item needs a productId (et. al.) if it needs to be tied to a product or other entity
      submit: function(order, payment, user, orgId) {
        let _order = _.clone(order);

        // orgId can be passed in explicitly or as a property on the order
        _order.organizationId = order.organizationId || orgId;

        // create the payment record (customer) if necessary
        return $q.when(payment.customer ? payment.customer : createCustomer(_order, payment, user))

          // add the credit card to the customer if it's a new card
          .then(() => $q.when(payment.newCard ? addCard(_order, payment, payment.customer) : payment.sourceId))

          // if the order has a subscription item create a new subscription
          .then(() => checkForSubscription(_order, user))

          // create the order record
          .then(() => {
            _order.customerId = payment.customer.id;
            _order.sourceId = payment.sourceId;
            delete _order.items;
            return orders.create(_order);
          })

          // add the order items to the db; assign $ids to the local order items
          .then((newOrder) => {
            $log.info('New order created', {orderId: newOrder.key});

            return $q.all(_.map(order.items, (item) => orders.addItem(newOrder.key, item)
              .then((newItem) => item.$id = newItem.key)))

              // update and return the local order object
              .then(() => _.assign(order, _order, {$id: newOrder.key}));
          });
      },
      charge: function(orderId, itemIds, amount) {
        let $order, chargeId;

        itemIds = _.isArray(itemIds) ? itemIds : [itemIds];

        return orders.$get(orderId)
          .then((result) => {
            if (!result) { throw Error('Unable to find order for orderId: ' + orderId); }
            $order = result;

            return billing.chargeCustomer($order.organizationId, $order.customerId, {
              amount: billing.toStripeAmountFormat(amount),
              source: $order.sourceId,
              currency: 'usd',
              'receipt_email': $order.receiptEmail,
              metadata: {orderId: orderId, itemIds: angular.toJson(itemIds)}
            });
          })
          .then((charge) => {
            chargeId = charge.id;
            return $q.all(_.map(itemIds, (itemId) => {
              return orders.setChargeId(orderId, itemId, chargeId);
            }));
          })
          .then(() => $order.removeCard === true && isComplete($order) ?
            billing.deleteCard($order.organizationId, $order.customerId, $order.sourceId) : null)
          .then(() => $order && $order.$destroy())
          .then(() => chargeId);
      },
      tokenize: function(source) {
        return source.object === 'bank_account' ? billing.createAchToken(source) :
          billing.createToken(dehydrateCard(source));
      }
    };
  });
};
