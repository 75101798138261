'use strict';

module.exports = function (ngModule) {
  ngModule.directive('cfBreadcrumbs', function () {
    return {
      scope: {},
      restrict: 'EA',
      replace: true,
      template: require('./breadcrumbs.template.html'),
      controller: require('./breadcrumbs.controller.js'),
      controllerAs: 'vm'
    };
  });
};
