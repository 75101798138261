module.exports = function(ngModule) {
  ngModule.config(($stateProvider) => {
    'ngInject';

    $stateProvider.state('checklists.gmps', {
      url: '/gmps',
      template: '<div ui-view></div>',
      resolve: {
        user: (authentication) => authentication.requireAuth(),
        company: (user, organizations) => organizations.$get(user.orgContext.id),
        $gmps: (gmps) => gmps.getAll()
      },
      data: {menuId: 'dashboard'}
    }).state('checklists.gmps.audits', {
      url: '/audits?new',
      onEnter: function(user, gmps, $state, $stateParams, utils) {
        if ($stateParams.new) {
          gmps.push(user).then(auditId => $state.go('checklists.gmps.edit.category', {auditId}))
            .catch(err => utils.defaultErrorHandler(err, 'Unable to create new gmp audit.'));
        }
      },
      template: '<cf-gmp-audits user="$resolve.user" $gmps="$resolve.$gmps"></cf-gmp-audits>'
    }).state('checklists.gmps.edit', {
      url: '/:auditId/edit',
      abstract: true,
      template: '<cf-edit-gmp company="$resolve.company" audit-date="$resolve.auditDate"' +
        '$gmps="$resolve.$gmps" user="$resolve.user"></cf-edit-gmp>',
      resolve: {
        user: (authentication) => authentication.requireAuth(),
        /*ensurePayPlan: (user, $q) => {
          if (user.onPayAsGoPlan()) {
            return $q.reject('User not authorized to view GMPs');
          }
        },*/
        company: (user, organizations) => organizations.$get(user.orgContext.id),
        auditDate: ($stateParams, gmps, user) => gmps.getAuditDate(user, $stateParams.auditId)
      },
      data: {menuId: 'dashboard'}
    }).state('checklists.gmps.edit.category', {
      url: '?categoryId',
      template: '<cf-gmp-category company-category="$resolve.companyCategory" $gmps="$resolve.$gmps" ' +
        'company-questions="$resolve.companyQuestions" user="$resolve.user" category="$resolve.category">' +
        '</cf-gmp-category>',
      resolve: {
        category: ($stateParams, $gmps) =>
          $stateParams.categoryId ? _.find($gmps, {$id: $stateParams.categoryId}) : $gmps[0],
        companyCategory: ($stateParams, user, category, gmps) =>
          gmps.getAuditCategory(user, $stateParams.auditId, category.$id),
        companyQuestions: ($firebaseObject, companyCategory) =>
          $firebaseObject(companyCategory.$ref().child('questions')).$loaded()
      }
    });
  });
  //   .run((menus, authorization, orgPerspectives) => {
  //   'ngInject';
  //
  //   menus.addMenuItem('dashboard', {
  //     title: 'GMPs',
  //     state: 'checklists.gmps.audits',
  //     position: 2,
  //     isPublic: false,
  //     perspectives: ['!reviews.' + orgPerspectives.PENDING_SIGNUP],
  //     claims: [authorization.claims.ORGANIZATION_READ]
  //   });
  // });
};
