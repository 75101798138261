require('./_css');

module.exports = function(ngModule) {
  ngModule.component('cfHeadline', {
    bindings: {
      heading: '<',
      subHeading: '<',
      hideHelp: '<',
      backText: '<',
      backState: '<'
    },
    template: require('./headline.template.html'),
    controller: require('./headline.controller.js'),
    controllerAs: 'vm'
  });
};
