module.exports = function(ngModule) {
  ngModule.constant('units', function() {
    return [
        {name: 'Litres (lt)', value: 'lt'},
        {name: 'Kilograms (kg)', value: 'kg'},
        {name: 'Pounds (lb)', value: 'lb'},
        {name: 'Gallons (gal)', value: 'gal'},
        {name: 'Grams (g)', value: 'g'},
        {name: 'Ounces (oz)', value: 'oz'},
        {name: 'Fluid Ounces (fl oz)', value: 'floz'}
    ];
  });
};
