class Controller {
  constructor($state, $uibModal, $filter, growl, moment, $q, confirmDeleteModal, orgInteractionService, $log,
              messageServices, orders, CF_ORGANIZATION_ID) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$filter = $filter;
    this.growl = growl;
    this.moment = moment;
    this.$q = $q;
    this.confirmDeleteModal = confirmDeleteModal;
    this.orgInteractionService = orgInteractionService;
    this.$log = $log;
    this.messageServices = messageServices;
    this.ordersService = orders;
    this.CF_ORGANIZATION_ID = CF_ORGANIZATION_ID;
  }

  $onInit() {
    this.providerProfiles[this.CF_ORGANIZATION_ID].isCf = true;
    this.providerProfiles[this.CF_ORGANIZATION_ID].profile.name += ' (default)';
    this.providerProfiles = _.map(this.providerProfiles, (p, id) => _.assign(p, {$id: id}));
  }

  message(provider) {
    this.messageServices.getOrgToOrgTopic(this.user, provider.$id).then(topicId => {
      this.$state.go('user.messages.message', {topicId: topicId});
    });
  }

  getAnalystsMarkup(members) {
    return _.reduce(members, (result, member) => {
      return result + `<div>${member.name}</div>`;
    }, '<div class="analyst-members">') + '</div>';
  }

  getServicesMarkup(provider) {
    return _.reduce(provider.services, (result, service) => {
      const priceVerbiage = service.price ? this.$filter('currency')(service.price.regular) : '<i>Variable Price</i>';

      return result + '<li>' + service.title + ' (' + priceVerbiage + ') </li>';
    }, '<ol>') + '</ol>';
  }

  terminate(provider) {
    this.confirmDeleteModal('Preferred Provider ' + provider.profile.name)
      .then(() => {
        return this.ordersService.get(this.user.orgContext.id, false, null, 0, 1,
          {isActive: true, provider: provider.$id})
          .then(orders => {
            if (!_.isEmpty(orders)) {
              this.growl.warning('Unable to terminate provider relationship. First cancel all orders with provider.');
              return this.$q.when(false);
            }

            return this.orgInteractionService.terminateClientProviderRelationship(provider.$id,
              this.user.orgContext.id).then(() => true);
          })
          .then(terminated => {
            if (terminated) {
              _.remove(this.providerProfiles, p => p.$id === provider.$id);
            }
          })
          .catch(err => {
            this.$log.error('An error occurred terminating a client/provider relationship.', err);
            this.growl.error('Unable to terminate relationship. Please contact customer support.');
          });
      })
      .catch(() => angular.noop);
  }

  humanizeDate(date) {
    return this.moment.duration(moment(date).diff(new Date())).humanize(true);
  }
}

module.exports = Controller;
