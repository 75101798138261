class Constructor {
  constructor($q, organizations, users, $uibModal, growl, $state, confirmDeleteModal, utils, CompanySearch,
              UsersSearchCflib, billing, selectModal, constantsService, cfpLoadingBar, $timeout, confirmModal,
              datePickerModal, authorization, CF_ROLES) {
    'ngInject';

    this.$q = $q;
    this.organizations = organizations;
    this.users = users;
    this.$uibModal = $uibModal;
    this.growl = growl;
    this.$state = $state;
    this.confirmDeleteModal = confirmDeleteModal;
    this.utils = utils;
    this.billing = billing;
    this.selectModal = selectModal;
    this.cfpLoadingBar = cfpLoadingBar;
    this.confirmModal = confirmModal;
    this.$timeout = $timeout;
    this.datePickerModal = datePickerModal;
    this.authorization = authorization;
    this.CF_ROLES = CF_ROLES;
    this.stripeConstantsPromise = constantsService.get('stripe');

    this.companySearch = new CompanySearch();
    this.usersSearchCflib = new UsersSearchCflib();
  }

  $onInit() {
    this.providerNames = {};
    this.search = _.debounce(this._search, 300);
    this._search();
    this.plans = _.keyBy(_.filter(this.plans.data,
      p => p.nickname && !p.nickname.toLowerCase().includes('(legacy)')), 'id');
  }

  planNickName(planId) {
    if (!planId) { return ''; }
    let plan = this.plans[planId];

    return plan ? plan.nickname : '';
  }

  addOrganization() {
    this.$uibModal.open({
      component: 'cfCreateOrg',
      backdrop: 'static',
      resolve: {
        organization: () => this.organizations.$push()
      }
    }).result.then(org => {
      this.growl.success('Organization created successfully.', {});
      this.$state.go('administration.organizations.edit.overview', {organizationId: org.$id});
    }).catch((err) => this.utils.defaultErrorHandler(err, 'Error creating the new organization.'));
  }

  remove(organization) {
    this.confirmDeleteModal(organization.companyName).then(() => {
      this.organizations.remove(this.user, organization.$id).then(() => {
        _.remove(this.organizations, org => org.$id === organization.$id);
        this.growl.success('Organization deleted');
      }).catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete organization.'));
    });
  }

  _search() {
    this.loading = this.searching = true;
    this.standardSearch = true;
    this.searchResults = [];
    let searchPromise;

    if (this.searchText && this.utils.testEmail(this.searchText)) {
      this.standardSearch = false;
      searchPromise = this.usersSearchCflib.search(this.searchText)
        .then(users => this.$q.all(_.map(_.uniqBy(_.filter(users, u => !!u.organizationId), 'organizationId'),
          (user) => this.organizations.get(user.organizationId))));
    } else if (!_.isEmpty(this.searchText) && this.searchText[0] === '-') {
      this.standardSearch = false;
      searchPromise = this.organizations.get(this.searchText).then(org => org ? [org] : null);
    } else {
      searchPromise = this.companySearch.search(this.searchText);
    }

    searchPromise
      .then(results => {
        this.searchResults = this.standardSearch ? this.companySearch.searchResults : results;
        _.each(this.searchResults, rec => {
          if (rec.provider && !this.providerNames[rec.provider]) {
            this.setProviderName(rec.provider);
          }
        });
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Error occurred during search.'))
      .finally(() => {
        this.searching = this.loading = false;
      });
  }

  setProviderName(providerId) {
    if (this.providerNames[providerId]) { return; }
    this.providerNames[providerId] = {promise: this.organizations.getName(providerId).then(name => {
      this.providerNames[providerId].value = name;
    })};
  }

  searchKeyPress($event) {
    if (this.utils.isBenignKeyUp($event.keyCode)) {
      return;
    }

    if ($event.keyCode === 13) {
      if (!this.searchText || this.searchText.length <= 3) {
        this.search();
      }

      this.search.flush();

      return;
    }

    if (this.searchText && this.searchText.length <= 3) {
      return;
    }

    this.search();
  }

  getMore() {
    this.loading = true;

    this.$q.when(this.companySearch.getMore())
      .then(() => {
        this.searchResults = this.companySearch.searchResults;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changeSubscription(org, newPlanId) {
    return this.$q.when(newPlanId || this.selectModal({
      options: _.map(_.filter(this.plans, p => p.id !== org.subscriptionPlanId), plan => ({
        name: plan.nickname || plan.id,
        value: plan
      })),
      okText: 'Next',
      title: 'Change Organization\'s Subscription?',
      intro: `Are you sure you want to change the subscription for <b>${_.upperCase(org.companyName)}</b>?` +
        ' If so choose the new subscription plan.<br><br>' +
        `Current Plan: <b>${this.planNickName(org.subscriptionPlanId)}</b>`
    }))
      .then(chosenPlan => {
        if(newPlanId) {
          chosenPlan = this.plans[newPlanId];
          return {chosenPlan, skipTrial: true};
        } else {
          return this.selectModal({
            options: [{
              name: 'Skip Trial',
              value: true
            },
            {
              name: 'Keep Trial',
              value: false
            }],
            okText: 'Change Subscription',
            title: 'Trail Options',
            intro: 'Choose one option for initial trial period'
          }).then((skipTrial)=> {
            return {chosenPlan, skipTrial};
          });
        }
      })
      .then((result) => {
        let {chosenPlan, skipTrial} = result;

        this.cfpLoadingBar.start();
        return this.billing.fetchSubscription(org.$id, org.subscriptionId)
          .then(subscription => this.billing.getCustomer(org.$id, subscription.customer))
          .then(customer => {
            // If the current subscription doesn't have a default payment source add a trial so it doesn't fail
            let updateTrialPromise = customer.sources.total_count > 0 || skipTrial ? null :
              this.billing.updateSubscription(org.$id, org.subscriptionId, {
                // eslint-disable-next-line camelcase
                trial_end: Math.round(moment().add(15, 'days').valueOf() / 1000)
              });

            return this.$q.when(updateTrialPromise)
              .then(() => this.billing.changeSubscription(org.$id, org.subscriptionId, chosenPlan.id));
          });
      })
      .then(() => {
        this.growl.success('Subscription changed!');
        return this.$timeout(() => this.search(), 1000);
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to change the subscription'))
      .finally(() => this.cfpLoadingBar.complete());
  }

  cancelSubscription(org) {
    return this.confirmModal({
      title: 'Change Subscription to Free Plan?',
      body: `Are you sure you want to change the subscription for <b>${_.upperCase(org.companyName)}</b> ` +
        ' to the free plan?<br><br>' +
        `Current Plan: <b>${this.planNickName(org.subscriptionPlanId)}</b>`,
      okText: 'Yes, Cancel Subscription',
      cancelText: 'No'
    }).then(() => {
      return this.stripeConstantsPromise.then(stripeConstants => {
        this.changeSubscription(org, stripeConstants.defaultFreePlan);
      });
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to cancel the subscription'));
  }

  updateTrial(org) {
    return this.billing.fetchSubscription(org.$id, org.subscriptionId).then(subscription => {
      if (subscription.status !== this.billing.subscriptionStatuses.TRIALING) {
        const msg = `Cannot extend trial. Subscription status is "${subscription.status}".`;

        this.growl.error(msg);
        return this.$q.reject(msg);
      }
      return this.datePickerModal({
        title: 'Choose New Trial End Date?',
        ngModel: new Date(subscription['trial_end'] * 1000),
        okText: 'Update Trial End',
        label: 'Trial End Date:',
        cancelText: 'Cancel'
      });
    }).then(newDate => this.billing.updateSubscription(org.$id, org.subscriptionId, {
      // eslint-disable-next-line camelcase
      trial_end: newDate.getTime() / 1000
    }))
      .then(() => this.growl.success('Trial date updated'))
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to extend the trial.'));
  }

  setProvider(org) {
    let providerId;

    this.$uibModal.open({
      component: 'cfChooseOrganization',
      resolve: {
        user: () => this.user,
        titleHtml: () => '<i class="far fa-handshake fa-fw" aria-hidden="true"></i> Choose Provider',
        okButtonText: () => 'Choose Provider'
      }
    }).result
      .then(id => {
        providerId = id;
        return this.organizations.setProvider(org.$id, providerId);
      })
      .then(() => this.organizations.getMembersWithClaim(providerId, 'admin'))
      .then(members => {
        if (!members || !members.length) {
          return this.$q.reject('No admin members of org ' + providerId);
        }
        return this.authorization.setOrganizationRole(_.first(members).$id, org.$id, this.CF_ROLES.PROVIDER_WRITE);
      })
      .then(() => {
        org.provider = providerId;
        this.setProviderName(providerId);
        this.growl.success('Provider set');
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to set the provider'));
  }
}

module.exports = Constructor;
