require('./styles.scss');

module.exports = function(ngModule) {
  ngModule.component('cfEditProductIngredients', {
    bindings: {
      product: '=',
      ingredients: '=',
      user: '<',
      isReadOnly: '<',
      onAddIngredient: '&'
    },
    template: require('./products.edit.ingredients.template.html'),
    controller: require('./products.edit.ingredients.controller'),
    controllerAs: 'vm'
  });
};
