module.exports = function(ngModule) {
  ngModule.constant('frequencyConstants', function() {
    return [
      {name: 'Continuous', value: 'continuous'},
      {name: 'Every Batch', value: 'batch'},
      {name: 'Everyday', value: 'everyday'},
      {name: 'Every 1 hour', value: 'every1h'},
      {name: 'Every 2 hours', value: 'every2h'},
      {name: 'Every 4 hours', value: 'every4h'},
      {name: 'Weekly', value: 'weekly'},
    ];
  });
};
