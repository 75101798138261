'use strict';

module.exports = function (ngModule) {
  ngModule.directive('cfListUsersCard', function () {
    return {
      scope: {
        users: '='
      },
      restrict: 'EA',
      template: require('./administration.list-users-card.template.html'),
      controller: require('./administration.list-users-card.controller.js')
    };
  });
};
