'use strict';

module.exports = function(ngModule) {
  ngModule.component('cfInternalVerification', {
    bindings: {
      verification: '=',
      substituteName: '@?',
      title: '@?',
      product: '<',
      productOrganization: '<',
      $uibModalInstance: '='
    },
    template: require('./products.verification.internal.template.html'),
    controller: require('./products.verification.internal.controller.js'),
    controllerAs: 'vm'
  });
};