module.exports = function(Upload, $timeout, $q, growl) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    Upload.imageDimensions(vm.file)
      .then((dimensions) => {
        let maxElements = vm.maxRatio.split(':');

        return maxElements[0] / maxElements[1] < dimensions.width / dimensions.height;
      })
      .then((exceedsMax) => {
        if (exceedsMax) {
          growl.warning('The chosen images exceeds the maximum width to height ratio of ' + vm.maxRatio);
        }

        return $q.when(exceedsMax ?
          Upload.resize(vm.file, null, null, null, null, vm.maxRatio, true) :
          vm.file);
      })
      .then(Upload.dataUrl)
      .then((img) => {
        vm.image = img;
      });
  };
};
