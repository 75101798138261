'use strict';

module.exports = function ($uibModal, $rootScope) {
  //noinspection JSLint
  'ngInject';

  var vm = this;

  vm.options = false;

  //noinspection JSLint
  $rootScope.$on('$stateChangeSuccess', function (event, toState) {
    //vm.options = toState.data && toState.data.titleBar;
  });

  vm.help = function () {
    $uibModal.open({
      template: vm.options.helpModal,
      controller: function ($scope, $uibModalInstance) {
        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.close();
        };
      }
    });
  };
};